import { useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { useDownloadPresignedUrl } from '../../../../hooks/useDownloadPresignedUrl';
import { uploadStyles as useStyles } from '../styles/uploadStyles';
import { MyDocumentsController } from './MyDocumentsController';
import type { MyDocumentsFilter } from './MyDocumentsListRequest';
import { MyDocumentsListRequest } from './MyDocumentsListRequest';

const viewFileTypes: CollectApi.File['dataFormat'][] = ['EET', 'EMT'];

type MyDocumentsProps = { 'data-testid': string };

export const MyDocuments: React.FC<MyDocumentsProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({} as MyDocumentsFilter);
  const createTestid = useTestid(testid);
  const downloadPresignedUrl = useDownloadPresignedUrl();

  const makeHandleDownload = useCallbackImmutable(function useHandleDownload(
    fileType: CollectApi.FileType,
    fileTypeLabel: string,
  ) {
    return useCallbackImmutable((fileData: CollectApi.File) => {
      downloadPresignedUrl({
        fileDownloadType: fileType,
        fileDownloadTypeLabel: fileTypeLabel,
        fileName: fileData.name,
        processId: fileData.processId,
      });
    });
  });

  const handleDownload = makeHandleDownload('input', 'Input File');
  const handleDownloadErrorLog = makeHandleDownload('error_log', 'Error Log');

  return (
    <div className={classes.container} data-testid={testid}>
      <div className={classes.head}>
        <MyDocumentsController
          viewFileTypes={viewFileTypes}
          setFilters={setFilters}
          data-testid={createTestid('controller')}
        />
      </div>
      <MyDocumentsListRequest
        viewFileTypes={viewFileTypes}
        filters={filters}
        data-testid={testid}
        onDownload={handleDownload}
        onDownloadErrorLog={handleDownloadErrorLog}
      />
    </div>
  );
};
