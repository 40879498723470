import { Stack } from '@mui/material';
import type { OptionType, SimpleTableProps } from 'mns-components';
import {
  EmptyStateV2,
  Button,
  Icon,
  List,
  LoadingCircle,
  SimpleTable,
  groupBy,
  objectKeys,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useUser } from '../../../../hooks/useAuth';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import { appNotification } from '../../../../store/apis';
import useStyles from '../styles/ChaseUpAccessPermission.styles';

const NOTIF_CODE = 'chase_up';

const filterEmptyAndDuplicates = (list: string[]) =>
  list.filter((text, index) => text.length && list.findIndex((seek) => seek === text) === index);

const assetTableColumns: SimpleTableProps<CollectApi.FundPositionToCollect>['columns'] = [
  { field: 'fundPosition', head: 'Shareclass ID', cell: ({ fundPosition }) => fundPosition.externalId },
  { field: 'fundPosition', head: 'Shareclass name', cell: ({ fundPosition }) => fundPosition.name },
];

export type ChaseUpAssetManagerProps = {
  isSaving?: boolean;
  owners: CollectApi.FundPositionToCollect[];
  emailList: string[];
  updateEmailList(organisationOwner: string, value: string): void;
  'data-testid': string;
};

export const ChaseUpAssetManager: React.FC<ChaseUpAssetManagerProps> = ({
  isSaving,
  owners,
  emailList,
  updateEmailList,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { disseminatorPortfolio } = owners[0];
  const orgId = disseminatorPortfolio?.organisationId;
  const organisationOwnerName = disseminatorPortfolio?.organisationName;

  const { data: availableList, isLoading } = useQuery(
    ['appNotification.getContactList', orgId, NOTIF_CODE],
    () => appNotification.getContactList(orgId!, NOTIF_CODE),
    {
      enabled: !!orgId,
      onError: async () => {
        // TODO Check/refactor this behaviour.
        await appNotification.createContactList({
          contactList: [],
          notificationCode: NOTIF_CODE,
          organizationId: orgId!,
          visibility: 'public',
        });
      },
    },
  );

  const handleCheck = useCallbackImmutable((value) => orgId && updateEmailList(orgId, value));

  const emailOptions: OptionType[] =
    useMemo(() => availableList?.map((email): OptionType => ({ label: email, value: email })), [availableList]) ?? [];

  if (isLoading || !orgId) {
    return <LoadingCircle data-testid={createTestid('loading')} />;
  }

  if (availableList === null || availableList?.length === 0) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title={`There is no email contact found for organisation ${organisationOwnerName} yet!`}
          subTitle="Please contact the organisation to set up an email contact list."
          data-testid={createTestid('empty-mails')}
        />
      </Stack>
    );
  }

  return (
    <div>
      <h3>
        Asset Manager: <span data-testid={createTestid('organisationOwner')}>{organisationOwnerName}</span>
      </h3>
      <SimpleTable rows={owners} columns={assetTableColumns} data-testid={createTestid('fund-list')} />
      <h3>Email address to chase up:</h3>
      <List
        items={emailOptions}
        checked={emailList}
        onCheck={handleCheck}
        disabled={isSaving}
        data-testid={createTestid('emailList')}
      />
      <p>You don&apos;t find the expected emails? Please contact your Asset Manager</p>
    </div>
  );
};

interface AccessRequestProps {
  selectedItems: CollectApi.FundPositionToCollect[];
  closeModal: () => void;
  saveChaseUpInvitationAccess: (list: CollectApi.ChaseUpMailRequest[]) => Promise<void>;
  chaseUpKind: CollectApi.ChaseUpMailRequest['kind'];
  'data-testid': string;
}

export const ChaseUpAccessPermission: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  saveChaseUpInvitationAccess,
  chaseUpKind,
  'data-testid': testid,
}: AccessRequestProps) => {
  const [[, valuationDate]] = usePtfAndDate();
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { email: userEmail } = useUser();
  // Validation

  const groupedByOwner = useMemo(
    () => groupBy(selectedItems, ['disseminatorPortfolio', 'organisationId']),
    [selectedItems],
  );
  const [emailsByOwner, setEmailsByOwner] = useState(
    objectKeys(groupedByOwner).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {} as Record<string, string[]>),
  );

  const updateEmailList = useCallbackImmutable((organisationOwner: string, value: string) => {
    setEmailsByOwner((current) => {
      const list = current[organisationOwner];
      const index = list.indexOf(value);
      let newList = list.slice();
      if (index >= 0) {
        newList.splice(index, 1);
      } else {
        newList = [value, ...list];
      }
      return { ...current, [organisationOwner]: newList };
    });
  });

  const handleSave = useCallbackImmutable(async () => {
    if (valuationDate) {
      setIsSaving(true);
      const data = objectKeys(groupedByOwner).map(
        (organisationOwner): CollectApi.ChaseUpMailRequest => ({
          emailInfos: { to: filterEmptyAndDuplicates(emailsByOwner[organisationOwner]), cc: [userEmail] },
          ids:
            groupedByOwner[organisationOwner]?.map((fund) => ({
              fundPositionId: fund.fundPosition.id,
              dataAccessAuthorizationId: fund.accessRequest?.id ?? '',
            })) ?? [],
          valuationDate: valuationDate,
          kind: chaseUpKind,
        }),
      );
      try {
        await saveChaseUpInvitationAccess(data);
      } catch {
        // do nothing
      }
      setIsSaving(false);
    }
  });

  const hasEmailInEachOwner = !Object.values(emailsByOwner).find((list) => !list.find((email) => email.length));

  const { undefined: emptyGroupOwner, ...restGroupOwners } = groupedByOwner;

  return (
    <>
      <span className={classes.description}>
        <Icon.Info data-testid={createTestid('icon-info')} />
        {chaseUpKind == 'UPLOAD' &&
          'You are about to chase up your Asset Manager which has not yet disseminated the following fund inventory:'}

        {chaseUpKind == 'QUALITIES' &&
          'You are about to chase up the Asset Manager to obtain the quality expected by your organization:'}
      </span>

      <div className={classes.listOrganisation}>
        {emptyGroupOwner?.length && (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <EmptyStateV2
              variant="info"
              useCase="noActions"
              title="Some access request are not sent to a defined organisation!"
              subTitle={`Please find the list of impacted inventories: ${emptyGroupOwner
                ?.map((owner) => `${owner.fundPosition.name} (${owner.fundPosition.externalId})`)
                .join(', ')}.`}
              data-testid={createTestid('empty-groupowner')}
            />
          </Stack>
        )}
      </div>

      <div className={classes.listOrganisation}>
        {objectKeys(restGroupOwners).map((organisationOwner) => (
          <ChaseUpAssetManager
            key={organisationOwner}
            isSaving={isSaving}
            emailList={emailsByOwner[organisationOwner]}
            owners={groupedByOwner[organisationOwner] ?? []}
            updateEmailList={updateEmailList}
            data-testid={createTestid('chaseUpAM')}
          />
        ))}
      </div>

      <div className={classes.formFooter}>
        <hr />
        <Button color="primary" outlined onClick={closeModal} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          loading={isSaving}
          disabled={!hasEmailInEachOwner}
          data-testid={createTestid('button-submit')}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};
