import { Button, Icon, Link, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useMemo } from 'react';
import { getEsgScoreOverviewPage } from '../../../applications/esgApp/routes';
import { useApplicationsRequest } from '../../../applications/marketplace/hooks';
import { getApplicationByRole } from '../../../common/getApplicationConfig';
import { useUser } from '../../../hooks/useAuth';
import { usePtfAndDate } from '../../../hooks/useGlobalState';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';
import type { AppCode } from '../appDescriptions';

/**
 * Get the appCode of the last subscribed application which runs a widget.
 * The last role in useUser().apps is the last subscribed application.
 * @returns {AppCode | undefined}
 */
export const useLastSubscribedApplicationWidget = (): AppCode | undefined => {
  const { apps: userRoles } = useUser();
  const { data: applications } = useApplicationsRequest();
  const { data: portfolios } = useFetchAllPortfolios();
  const hasPortfolio = !!portfolios?.length;
  return useMemo(
    () =>
      applications && hasPortfolio
        ? userRoles.reduce((acc, role) => {
            const config = getApplicationByRole(role);
            if (
              config &&
              applications.find((app) => app.codeApplication === config.appCode) &&
              config.config.portfolioViewType
            ) {
              return config.appCode;
            }
            return acc;
          }, undefined as AppCode | undefined)
        : undefined,
    [applications, userRoles, hasPortfolio],
  );
};

interface Props {
  portfolioId: string;
  latestVersionValuationDate: string;
  'data-testid': string;
}

export const PortfoliosListViewButton = React.memo<Props>(function PortfoliosListViewButtonComp({
  portfolioId,
  latestVersionValuationDate,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const [, setStateAndCache] = usePtfAndDate();

  const onClick = useCallbackImmutable(() => {
    setStateAndCache([portfolioId, latestVersionValuationDate]);
  });

  const appCode = useLastSubscribedApplicationWidget();
  if (!appCode) return null;

  return (
    <Link to={getEsgScoreOverviewPage(portfolioId, latestVersionValuationDate, appCode)} data-testid={testid}>
      <Button
        color="primary"
        outlined
        size="small"
        startIcon={<Icon size={'small'} name="esgsolutions" data-testid={createTestid('icon-details')} />}
        data-testid={createTestid('button')}
        onClick={onClick}
      >
        ESG Scores
      </Button>
    </Link>
  );
});
