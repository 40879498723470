import type { FormStepProps, OptionType } from 'mns-components';
import { useTestid } from 'mns-components';
import type { ProviderApi, MarketplaceApi } from 'mns-sdk-collect';
import { useState, useMemo, useEffect } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import type { ConsumeRequestSteps } from './createRequest.types';
import { formStyles as useStyles } from './formStyles';
import { OrderDetails } from './OrderDetails';
import type { ReplyPortfolioPickerForm } from './PortfolioPickerForm';
import { PortfolioPickerForm } from './PortfolioPickerForm';
import { SubscriptionDetails } from './SubscriptionDetails';

type PortfolioPickerStepProps = FormStepProps<ConsumeRequestSteps[0], ConsumeRequestSteps> & {
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  appCode: AppCode;
  disablePtfAndDate?: boolean;
  'data-testid': string;
};

export const PortfolioPickerStep: React.FC<PortfolioPickerStepProps> = ({
  appCode,
  portfolios,
  applicationDetails,
  stepValue,
  setStepValue,
  setSubmit,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const {
    config: { analysisRequestType },
  } = useApplicationConfig(appCode);
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [[defaultPortfolioId, defaultValuationDate]] = usePtfAndDate();
  const [portfolioId, setPortfolioId] = useState<string | undefined>(stepValue.portfolioId ?? defaultPortfolioId);
  const [valuationDate, setValuationDate] = useState<string | undefined>(
    stepValue.valuationDate ?? defaultValuationDate,
  );

  const portfolio = useMemo(
    () =>
      portfolios?.find(
        (ptf) => ptf.portfolioId === portfolioId && (!valuationDate || ptf.valuationDate === valuationDate),
      ) ?? portfolios[0],
    [portfolioId, portfolios, valuationDate],
  );

  const valuationDateOptions = useMemo(() => {
    return portfolios
      ?.filter((ptf) => ptf.portfolioId === portfolioId)
      .map(({ valuationDate: navDate }): OptionType => ({ label: navDate, value: navDate }));
  }, [portfolioId, portfolios]);

  const defaultPtfAndDateValues = useMemo((): Partial<ReplyPortfolioPickerForm> => {
    if (portfolio) {
      return {
        portfolioId: portfolio.portfolioId,
        valuationDate: portfolio.valuationDate,
        organisationId: portfolio.organisationId,
      };
    }
    return {
      portfolioId: defaultPortfolioId ?? portfolios?.[0]?.portfolioId,
      valuationDate: defaultValuationDate ?? valuationDateOptions?.[0]?.value,
    };
  }, [defaultPortfolioId, defaultValuationDate, portfolio, portfolios, valuationDateOptions]);

  useEffect(() => {
    if (portfolio) {
      setStepValue({ portfolioId: portfolio.portfolioId, valuationDate: portfolio.valuationDate });
    }
  }, [portfolio, setStepValue]);

  useEffect(() => {
    setSubmit((current) => ({ ...current, disabled: applicationDetails.subscriptionsDto?.remainingAnalysis === 0 }));
  }, [applicationDetails.subscriptionsDto?.remainingAnalysis, setSubmit]);

  if (!applicationDetails.subscriptionsDto) {
    throw new Error('User did not subscribe to application');
  }

  return (
    <div className={classes.formsContainer} data-testid={testid}>
      <SubscriptionDetails
        subscription={applicationDetails.subscriptionsDto}
        data-testid={createTestid('subscriptionDetails')}
      />
      <PortfolioPickerForm
        portfolios={portfolios}
        valuationDateOptions={valuationDateOptions}
        portfolio={portfolio}
        defaultValues={defaultPtfAndDateValues}
        onPortfolioChange={setPortfolioId}
        onValuationDateChange={setValuationDate}
        disablePtfAndDate={disablePtfAndDate}
        data-testid={createTestid('portfolioPicker')}
      />
      {analysisRequestType !== 'enrich' && (
        <OrderDetails applicationDetails={applicationDetails} data-testid={createTestid('orderDetails')} />
      )}
    </div>
  );
};
