import { makeOpenModal, makeOpenModalBoolean } from 'mns-components';
import { useQuery } from 'react-query';
import { useUser } from '../../hooks/useAuth';
import { accountApi } from '../../store/apis';
import { getRoute } from './routes';

export const [useOpenCreateExtractionModal, useManageOpenCreateExtractionModal] = makeOpenModalBoolean(
  () => getRoute('extractor-extractions-generated').link,
  'openCreateExtractionModal',
);

export const [useOpenCreateTemplateModal, useManageOpenCreateTemplateModal] = makeOpenModalBoolean(
  () => getRoute('extractor-templates').link,
  'openCreateTemplateModal',
);

export const [useOpenAutomatedExtractionDetailsModal, useManageOpenAutomatedExtractionDetailsModal] = makeOpenModal(
  () => getRoute('extractor-automated-extractions-settings').link,
  'openAutomatedExtractionDetailsModal',
);

export const [useOpenAutomatedExtractionDeleteModal, useManageOpenAutomatedExtractionDeleteModal] = makeOpenModal(
  () => getRoute('extractor-automated-extractions-settings').link,
  'openAutomatedExtractionDeleteModal',
);

export const [useOpenTemplateDetailsModal, useManageOpenTemplateDetailsModal] = makeOpenModal(
  () => getRoute('extractor-templates').link,
  'openTemplateDetailsModal',
);

export const useFetchOrganisationDetails = () => {
  const { organisationId: orgaId } = useUser();
  return useQuery(['accountApi.getOrganisation'], () => accountApi.getOrganisation(orgaId));
};
