import { colors, makeStyles } from 'mns-components';

const detailsPermissionsDialogStyles = makeStyles({
  selectedItemKeys: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '.75rem',
    lineHeight: '.75rem',
    color: colors.blueColor,
  },
  selectedItemValues: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '.75rem',
    lineHeight: '.75rem',
    color: colors.blueColor,
    paddingRight: '1rem',
  },
  inlineFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0',
    margin: 0,
    borderBottom: 'solid 1px lightgrey',
  },
  paragraph: {
    fontSize: '.75rem',
    lineHeight: '.75rem',
  },
  commentArea: {
    borderRadius: '5px',
    background: '#f4f4f4',
    border: 'solid 1px lightgrey',
    padding: '.75rem',
    fontSize: '.75rem',
    lineHeight: '.75rem',
  },
  footerDialog: {
    width: '95%',
    display: 'block',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    padding: '1rem 0',
  },
});

export default detailsPermissionsDialogStyles;
