import { useCallbackImmutable, useTestid } from 'mns-components';
import type { IsinListApi } from 'mns-sdk-collect';
import { CreateIsinListModal } from '../modal/CreateIsinListModal';
import { DeleteIsinListModal } from '../modal/DeleteIsinListModal';
import { EditIsinListModal } from '../modal/EditIsinListModal';

type IsinListModalsProps = {
  isInListsData: IsinListApi.List[] | undefined;
  isOpenCreateModal: boolean;
  onCloseCreateModal(): void;
  selectedDelete: undefined | IsinListApi.List;
  setSelectedDelete(selected: undefined | IsinListApi.List): void;
  selectedEditId: string | undefined;
  closeEditModal(): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

export const IsinListModals: React.FC<IsinListModalsProps> = ({
  isInListsData,
  isOpenCreateModal,
  onCloseCreateModal,
  selectedDelete,
  setSelectedDelete,
  selectedEditId,
  closeEditModal,
  variant,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  // DELETE LIST
  const handleCancelDelete = useCallbackImmutable(() => setSelectedDelete(undefined));

  return (
    <>
      <CreateIsinListModal
        open={isOpenCreateModal}
        onClose={onCloseCreateModal}
        variant={variant}
        data-testid={createTestid('create')}
      />
      <EditIsinListModal
        isInListsData={isInListsData}
        onClose={closeEditModal}
        selectedEditId={selectedEditId}
        variant={variant}
        data-testid={createTestid('edit')}
      />
      <DeleteIsinListModal
        isinList={selectedDelete}
        onClose={handleCancelDelete}
        variant={variant}
        data-testid={createTestid('delete')}
      />
    </>
  );
};
