import { colors, makeStyles } from 'mns-components';

export const tableKpisAppStyles = makeStyles({
  appTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.4rem',
    fontWeight: 500,
  },
  scoreContainer: {
    flexDirection: 'row',
    margin: '2rem 0',
    gap: '1rem',
  },
  coverageScoring: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '1rem',
    border: `solid 1px ${colors.blueGreyColor}`,
    borderRadius: '6px',
  },
  coverageWrapper: {
    width: '25%',
  },
  coverageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.75rem',
    border: `solid 1px ${colors.blueGreyColor}`,
    borderRadius: '6px',
    marginBottom: '1.5rem',
  },
  coverageTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: '1.25rem',
    paddingLeft: '1rem',
    borderLeft: `solid 3px ${colors.primaryColor}`,
  },
  coverage: {
    marginLeft: '1.2rem',
    lineHeight: '100%',
    fontWeight: 600,
  },
  greatScoreTitle: {
    maxWidth: 'fit-content',
    marginBottom: '.5rem',
    fontWeight: 500,
  },
  portfolioName: {
    color: colors.darkGreyColor,
    maxWidth: 'fit-content',
    marginBottom: '1rem',
  },
  greatScore: {
    marginTop: 'auto',
    marginBottom: 0,
    fontSize: '3rem',
    fontWeight: 500,
    lineHeight: '100%',
    textAlign: 'center',
  },
  chartsWrapper: {
    flexDirection: 'row',
    flexGrow: 1,
    position: 'relative',
    border: `solid 1px ${colors.blueGreyColor}`,
    borderRadius: '6px',
  },
  scoringPillarsLabelBox: {
    margin: '.5rem 2rem 0',
  },
  legendContainer: {
    justifyContent: 'center',
    width: '50%',
  },
  legendWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: '.5rem',
  },
  colorBox: {
    display: 'inline-block',
    width: '1rem',
    minWidth: '1rem',
    height: '1rem',
    borderRadius: '3.5px',
    marginRight: '.625rem',
  },
  chartCardContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    gap: '2rem',
    marginBottom: '2rem',
  },
  infoIcon: {
    marginLeft: '.625rem',
  },
  arrow: {
    color: 'white',
  },
  tooltip: {
    cursor: 'pointer',
    backgroundColor: colors.whiteColor,
    color: colors.defaultFontColor,
  },
  barChartTitle: {
    fontWeight: 500,
    marginLeft: '1rem',
  },
  tableTitle: {
    marginBottom: '.1rem',
  },
  tableSubtitle: {
    color: colors.darkGreyColor,
    marginBottom: '1.25rem',
  },
  chartTooltipContent: {
    position: 'relative',
    left: '1rem',
    minWidth: '6rem',
    border: `solid 1px ${colors.blueGreyColor}`,
    borderRadius: '.2rem',
    boxShadow: 'none',
  },
  tooltipLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '2rem',
    padding: '0 .5rem',
    borderBottom: `solid 1px ${colors.blueGreyColor}`,
  },
  tooltipValue: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '2rem',
    padding: '0 .5rem',
  },
});
