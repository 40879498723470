import { useCallbackImmutable, useTestid, Switch } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useUser } from '../../../hooks/useAuth';

type UserInformationSwitchsProps = {
  data: AccountApi.User;
  disabled?: boolean;
  checked: boolean;
  onChange(user: AccountApi.UserRequest, value: boolean): void;
  'data-testid': string;
};

export const UserInformationSwitchs: React.FC<UserInformationSwitchsProps> = ({
  data,
  disabled,
  checked,
  onChange,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { username } = useUser();
  const disabledSwitch = username === data.username || disabled;

  const handleChange = useCallbackImmutable((value: boolean) => {
    onChange(data, value);
  });

  return (
    <Switch
      uncontrolled
      onChange={handleChange}
      isIconVisible
      value={checked}
      disabled={disabledSwitch}
      variant="secondary"
      data-testid={createTestid(data.username)}
    />
  );
};
