import { validate } from 'jsonschema';
import type { JsonSchemaObject } from 'mns-components';
import {
  objectEntries,
  useTestid,
  useStateRef,
  Button,
  FormCreator,
  makeForm,
  useState,
  Icon,
  useCallbackImmutable,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useEffect, useMemo } from 'react';
import { scheduledDeliveryModalStyles as useStyles } from './scheduledDeliveryModalStyles';

const getKeyFromObjectValue = <T extends AnyObject, V extends ValueOf<T>>(object: T, value: V): keyof T | undefined =>
  objectEntries(object).find(([, val]) => val === value)?.[0];

const mapFrequenciesRaw = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quaterly',
} as const;

const frequenciesLabels = Object.values(mapFrequenciesRaw);

const mapScopeTypeRaw = {
  ALL: 'All',
  CUSTOM: 'Custom',
} as const;

type TriggerDay = DataDeliveryApi.CreateDataDeliveryScheduled['triggerDay'];

type ValueOf<T> = T[keyof T];

type JsonSchemaType = {
  name: string;
  scope: ValueOf<typeof mapScopeTypeRaw>;
  isinListName: string;
  emailListName: string;
  sharingFrequency: ValueOf<typeof mapFrequenciesRaw>;
  triggerDay: `${TriggerDay}`;
};

export type JsonSchemaValue = {
  name: string;
  scope: keyof typeof mapScopeTypeRaw;
  isinListName: string;
  emailListName: string;
  sharingFrequency: keyof typeof mapFrequenciesRaw;
  triggerDay: TriggerDay;
};

type UseJsonSchemaProps = {
  isinListNames?: string[];
  emailListNames: string[];
  sharingFrequencies: string[];
  appCode?: string;
};

const useJsonSchema = ({ isinListNames, emailListNames, sharingFrequencies }: UseJsonSchemaProps) =>
  useMemo(
    (): JsonSchemaObject => ({
      variant: 'fields',
      properties: {
        name: { title: 'Name', type: 'string' },
        scope: { title: 'Scope', type: 'string', enum: ['All', 'Custom'] },
        isinListName: isinListNames
          ? {
              title: 'List of ISINs',
              type: 'string',
              enum: isinListNames,
              link: { title: 'Manage ISIN lists', link: `/portfolios/lists` },
            }
          : undefined,
        emailListName: {
          title: 'Recipients',
          type: 'string',
          enum: emailListNames,
          link: { title: 'Manage email lists', link: `/settings/organisation` },
        },
        sharingFrequency: { title: 'Frequency', type: 'string', enum: sharingFrequencies },
        triggerDay: {
          title: 'Business day',
          type: 'string',
          enum: Array.from({ length: 20 }, (_, index) => (index + 1).toString()),
          tooltip: 'In business days at the start of month',
        },
      },
    }),
    [isinListNames, emailListNames, sharingFrequencies],
  );

export type MakeScheduledDeliveryInputs = {
  buttonLabel: string;
};

export type ScheduledDeliveryInputsProps<InputType extends JsonSchemaValue | void> = {
  emailListNames: string[];
  isinListNames: string[];
  disabled?: boolean;
  initState: InputType;
  onSubmit(data: JsonSchemaValue): void;
  onClose(): void;
  'data-testid': string;
};

const makeScheduledDeliveryInputs = <InputType extends JsonSchemaValue | void>({
  buttonLabel,
}: MakeScheduledDeliveryInputs) => {
  const ScheduledDeliveryInputs: React.FC<ScheduledDeliveryInputsProps<InputType>> = ({
    emailListNames,
    isinListNames,
    disabled,
    initState,
    onSubmit,
    onClose,
    'data-testid': testid,
  }) => {
    const createTestid = useTestid(testid);
    const classes = useStyles();

    const stateRef = useState<JsonSchemaType>(
      initState
        ? {
            name: initState.name,
            scope: mapScopeTypeRaw[initState.scope],
            emailListName: initState.emailListName,
            isinListName: initState.isinListName,
            sharingFrequency: mapFrequenciesRaw[initState.sharingFrequency],
            triggerDay: `${initState.triggerDay}`,
          }
        : {
            name: '',
            scope: 'Custom',
            emailListName: emailListNames[0],
            isinListName: isinListNames[0],
            sharingFrequency: frequenciesLabels[0],
            triggerDay: '1',
          },
    );
    const [state, setState] = stateRef;

    const { scope } = stateRef.current;
    useEffect(() => {
      if (!initState) {
        stateRef.setState((current) => ({ ...current, triggerDay: '5' }));
      }
    }, [stateRef, initState]);

    const jsonSchema = useJsonSchema({
      emailListNames,
      isinListNames: scope === 'All' ? undefined : isinListNames,
      sharingFrequencies: frequenciesLabels,
    });

    const jsonSchemaRef = useStateRef(jsonSchema);

    useEffect(() => {
      if (initState) return;

      if (scope === 'All') {
        stateRef.setState((current) => ({ ...current, isinListName: '' }));
      } else {
        stateRef.setState((current) => ({ ...current, isinListName: isinListNames[0] }));
      }
    }, [isinListNames, stateRef, scope, initState]);

    const handleSubmit = useCallbackImmutable(() => {
      const raw = stateRef.state;
      const { errors } = validate(raw, jsonSchemaRef.current);

      if (!errors.length) {
        const data: JsonSchemaValue = {
          emailListName: raw.emailListName,
          isinListName: raw.isinListName,
          name: raw.name,
          scope: getKeyFromObjectValue(mapScopeTypeRaw, raw.scope)!,
          sharingFrequency: getKeyFromObjectValue(mapFrequenciesRaw, raw.sharingFrequency)!,
          triggerDay: +raw.triggerDay as TriggerDay,
        };
        onSubmit(data);
      }
    });

    return (
      <>
        <div className={classes.content}>
          <FormCreator
            state={state}
            setState={setState}
            jsonSchema={useJsonSchema({
              emailListNames,
              isinListNames: state.scope === 'All' ? undefined : isinListNames,
              sharingFrequencies: frequenciesLabels,
            })}
            data-testid={testid}
          />
        </div>
        <div className={classes.buttonList}>
          <Button
            color="primary"
            outlined
            onClick={onClose}
            startIcon={<Icon.Close data-testid={createTestid('icon-cancel')} />}
            data-testid={createTestid('button-cancel')}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={state.name.trim().length === 0}
            loading={disabled}
            data-testid={createTestid('button-submit')}
          >
            {buttonLabel}
          </Button>
        </div>
      </>
    );
  };

  return ScheduledDeliveryInputs;
};

export const CreateScheduledDeliveryForm = makeForm(
  makeScheduledDeliveryInputs<void>({
    buttonLabel: 'Create',
  }),
);

export const EditScheduledDeliveryForm = makeForm(
  makeScheduledDeliveryInputs<JsonSchemaValue>({
    buttonLabel: 'Edit',
  }),
);
