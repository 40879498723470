import { colors, makeStyles } from 'mns-components';

export const qualitiesRendererStyles = makeStyles({
  qualities: {
    display: 'inline-flex',
    gap: '0.25rem',
  },
  quality: {
    padding: '.18rem .30rem',
    fontSize: '.70rem',
    whiteSpace: 'nowrap',
    color: colors.whiteColor,
    backgroundColor: colors.primaryBackColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    height: '1rem',
    borderRadius: '5px',

    gap: '0.25rem',
  },
  tooltip: {
    cursor: 'pointer',
  },
  infoIcon: {
    color: colors.whiteColor,
    height: '12px',
    width: '12px',
  },
  qualityHeader: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    display: 'flex',
  },
  questionIcon: {
    color: colors.infoColor,
    height: '15px',
    width: '15px',
    marginLeft: '10px',
    position: 'relative',
  },
});
