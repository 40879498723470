import { StatusTag } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React from 'react';

export type ExtractionStatus = 'FAILED' | 'PROCESSING' | 'PARTIALLY_COMPLETED' | 'COMPLETED' | 'GENERATING';

export const getExtractionStatusByReports = (
  extractionId: string,
  reports: DataExtractorApi.Report.ReportLight[],
): ExtractionStatus => {
  let countCompleted = 0;
  let countFailed = 0;
  for (const report of reports) {
    if (report.idExtraction === extractionId) {
      if (report.status === 'IN_PROGRESS' || report.status === 'REQUESTED') return 'PROCESSING';
      if (report.status === 'COMPLETE') countCompleted++;
      if (report.status === 'FAILED') countFailed++;
    }
  }

  if (countCompleted && countFailed) return 'PARTIALLY_COMPLETED';
  if (countCompleted) return 'COMPLETED';
  if (countFailed) return 'FAILED';
  return 'GENERATING';
};

interface ExtractionGeneratedStatusProps {
  status: ExtractionStatus;
  'data-testid': string;
}

export const ExtractionGeneratedStatus: React.FC<ExtractionGeneratedStatusProps> = ({
  status,
  'data-testid': testid,
}) => {
  switch (status) {
    case 'PARTIALLY_COMPLETED':
      return <StatusTag variant="warning" label="Partially-Completed" data-testid={testid} />;

    case 'COMPLETED':
      return <StatusTag variant="success" label="Completed" data-testid={testid} />;

    case 'FAILED':
      return <StatusTag variant="error" label="Failed" data-testid={testid} />;

    case 'GENERATING':
    case 'PROCESSING':
      return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

    default:
      return null;
  }
};
