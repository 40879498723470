import { Stack } from '@mui/material';
import type {
  CheckboxItemType,
  DatapointsPickerDatapointOptionGroups,
  DatapointsPickerViewOption,
} from 'mns-components';
import {
  EmptyStateV2,
  DatapointsPicker,
  LoadingCircle,
  Typo,
  colors,
  filterTruthy,
  lowerCase,
  objectKeys,
  objectReduce,
  uniqueBy,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueriesMemo } from '../../../../common/useDownloadFile';
import { useFetchMetadataSources, useFetchMetadataSourcesDatapoints } from '../../hooks';
import type { TemplateFormData } from './SelectTemplate';

/**
 * Get datapoints indexed by templateId.
 * It is helpful for reducing component refresh by comparing new templates just loaded (useQueries returns a changing list).
 * @param rawSourceList the template Ids that should be loaded.
 * @returns
 */
const useManaosSourcesDatapoints = (rawSourceList: string[], manaosSources?: DataExtractorApi.Metadata.Source[]) => {
  const sourceList = filterTruthy(
    rawSourceList.map((sourceName) => manaosSources?.find(({ name }) => name === sourceName)?.code),
  );

  const sourcesDatapointsResults = useQueriesMemo(useFetchMetadataSourcesDatapoints(sourceList));
  const [sourcesDatapoints, setSourcesDatapoints] = useState<AnyObject<string, DataExtractorApi.Metadata.Datapoint[]>>(
    {},
  );

  const findSourceNameBySourceCode = useCallbackImmutable((sourceCode: string) => {
    return manaosSources?.find(({ code }) => code === sourceCode)?.name;
  });

  useEffect(() => {
    setSourcesDatapoints((current) => {
      const currentSourceCodes = objectKeys(current);
      const newSourceCodes = filterTruthy(sourcesDatapointsResults.data.map((result) => result?.[0]?.sourceCode));
      if (newSourceCodes.find((code) => !currentSourceCodes.includes(code))) {
        return sourcesDatapointsResults.data.reduce((acc, result) => {
          const sourceCode = result?.[0]?.sourceCode;
          const sourceName = findSourceNameBySourceCode(sourceCode);
          if (sourceName && result) acc[sourceName] = result;
          return acc;
        }, {} as AnyObject<string, DataExtractorApi.Metadata.Datapoint[]>);
      }
      return current;
    });
  }, [findSourceNameBySourceCode, manaosSources, sourcesDatapointsResults]);

  return sourcesDatapoints;
};

type TemplateEsgDatapointsProps = {
  disabled?: boolean;
  'data-testid': string;
};

export const TemplateManaosDatapoints: React.FC<TemplateEsgDatapointsProps> = ({ disabled, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const methods = useFormContext<TemplateFormData>();
  const { datapointsRaw, view, search } = methods.watch();
  const { setValue } = methods;
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState(true);
  const shouldCheckAllOnLoad = useRef<string>();

  const { data: manaosSources } = useFetchMetadataSources({
    select: (raw) => {
      const list = raw.filter((source) => source.type == 'MART');
      return list;
    },
  });
  const manaosSourcesDatapoints = useManaosSourcesDatapoints(sourceList, manaosSources);

  const viewOptions = useMemo(
    (): DatapointsPickerViewOption[] | undefined =>
      manaosSources
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((source) => ({
          title: source.name,
          value: source.name,
          createdBy: 'Manaos',
          createdAt: source.createdAt,
        })),
    [manaosSources],
  );

  const datapointOptionGroups = useMemo(
    () =>
      objectReduce(
        manaosSourcesDatapoints,
        (acc, result, sourceName) => {
          const [first] = result;
          if (first) {
            const source = manaosSources?.find(({ name }) => name === sourceName);
            if (source) {
              acc[sourceName] = uniqueBy(result, 'name').map((row): CheckboxItemType => {
                return {
                  label: row.name,
                  value: row.name,
                  required: row.required,
                  description: row.description,
                  iconName: 'portfolios',
                };
              });
            }
          }
          return acc;
        },
        {} as DatapointsPickerDatapointOptionGroups,
      ),
    [manaosSources, manaosSourcesDatapoints],
  );

  // get only views which are matching with search
  const viewOptionsFiltered = useMemo(() => {
    const lowerSearch = lowerCase(search);
    return viewOptions?.filter(({ title }) => lowerCase(title).includes(lowerSearch));
  }, [viewOptions, search]);

  // when view changes, load its datapoints
  useEffect(() => {
    if (view) {
      setSourceList((list) => {
        if (list.includes(view)) return list;
        const current = list.slice();
        current.push(view);
        return current;
      });
    }
  }, [view]);

  // at first sight, select the first source, but do not select its datapoints
  // then, checkboxes are available
  useEffect(() => {
    if (datapointsRaw && objectReduce(datapointsRaw, (acc, list) => acc + (list?.length ?? 0), 0) >= 0) {
      setDisabledCheckboxes(false);
    }
  }, [datapointsRaw]);

  // if view did not changed after loading, should check all datapoints
  useEffect(() => {
    if (view && view === shouldCheckAllOnLoad.current) {
      const source = manaosSourcesDatapoints[view];
      if (source) {
        setValue('datapoints', { [view]: source.map(({ name }) => name) });
        shouldCheckAllOnLoad.current = undefined;
      }
    }
  }, [setValue, manaosSourcesDatapoints, view]);

  const handleFocus = useCallbackImmutable((_, sourceName: string) => {
    const source = manaosSourcesDatapoints[sourceName];
    if (source) {
      setValue('datapoints', { [sourceName]: source.map(({ name }) => name) });
    } else {
      shouldCheckAllOnLoad.current = sourceName;
    }
  });

  if (!viewOptionsFiltered?.length) {
    return (
      <EmptyStateV2
        variant="info"
        useCase="noSearchResults"
        title="There is no match with your research!"
        subTitle="You can search again with new criteria."
        illustrationVariant="iconType"
        data-testid={createTestid('empty-searchResult')}
      />
    );
  }

  return (
    <Stack minHeight="200px" height="50vh">
      <DatapointsPicker
        variant="radio"
        datapointName="datapoints"
        datapointOptionGroups={datapointOptionGroups}
        viewName="view"
        viewOptions={viewOptionsFiltered}
        datapointPlaceholder={
          <Stack justifyContent="center" alignItems="center" height="100%" color={colors.primaryColor} gap="1rem">
            <LoadingCircle size="medium" data-testid={createTestid('datapoints-backdrop')} />
            <Typo variant="body1medium" color={colors.defaultFontColor}>
              Loading in progress
            </Typo>
          </Stack>
        }
        disabled={disabled}
        disabledCheckboxes={disabledCheckboxes}
        onFocus={handleFocus}
        data-testid={testid}
      />
    </Stack>
  );
};
