import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import type { FormStepErrors, OptionType, StepProps } from 'mns-components';
import {
  Icon,
  Select,
  TextField,
  makeFieldChange,
  useImmutable,
  useTestid,
  Typography,
  useCallbackImmutable,
} from 'mns-components';
import { useEffect, useState } from 'react';
import { usePrefetchTemplateDetails } from '../../../../api/templateData';
import { SelectTemplate } from '../components/SelectTemplate';
import { CreateTemplateButtons } from '../CreateTemplateButtons';
import { createTemplateStyles as useStyles } from '../createTemplateStyles';
import type { CreateTemplateSteps, TemplateFrom, TemplateSetUp } from '../types';

const templateFromOptions: OptionType<TemplateFrom>[] = [
  {
    label: 'Using an existing template',
    value: 'EXISTING_TEMPLATE',
  },
  {
    label: 'From scratch',
    value: 'NEW_TEMPLATE',
  },
];

export const SetUpStep: React.FC<StepProps<CreateTemplateSteps, 0>> = ({
  stepValue: { templateName, templateFrom, templateId, accordionPanel },
  goNext,
  setStepValue,
  setStepValues,
  Buttons,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const useAccordionChange = (newAccordionPanel: 'template') =>
    useCallbackImmutable((_: unknown, expanded: boolean) =>
      setStepValue((current) => ({ ...current, accordionPanel: expanded ? newAccordionPanel : null })),
    );

  const [, setErrors] = useState<FormStepErrors<TemplateSetUp>>([]);
  const useFieldChange = makeFieldChange(setErrors, setStepValue);

  const isNextAvailable = !!(
    templateName &&
    (templateFrom === 'NEW_TEMPLATE' || (templateFrom === 'EXISTING_TEMPLATE' && templateId))
  );

  // when another template is selected, please reset next steps
  const onTemplateIdChange = useCallbackImmutable((newValue: string) => {
    setStepValues(([setUp]) => [
      { ...setUp, templateId: newValue },
      { portfolioDataPoints: [], selectedCategory: null },
      { applicationsDataPoints: {}, selectedAppCode: null },
      undefined as never,
    ]);
  });

  // when template origin change, reset next fields
  const handleTemplateFromChange = useImmutable(() => (value?: TemplateFrom) => {
    setStepValue((current) => ({
      templateName: current.templateName,
      templateFrom: value,
      templateId: '',
      accordionPanel: current.accordionPanel,
    }));
  });

  // when a template is selected, prefetch the template for next step
  const prefetchTemplate = usePrefetchTemplateDetails();
  useEffect(() => {
    if (templateId) {
      prefetchTemplate(templateId);
    }
  }, [templateId, prefetchTemplate, setStepValues]);

  useEffect(
    () => setStepValue((current) => (current.accordionPanel ? current : { ...current, accordionPanel: 'template' })),
    [setStepValue],
  );

  return (
    <>
      <Accordion
        expanded={accordionPanel === 'template'}
        onChange={useAccordionChange('template')}
        data-testid={createTestid('accordion-template')}
      >
        <AccordionSummary expandIcon={<Icon name="expandless" data-testid={createTestid('icon-accordion')} />}>
          Template settings
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" component="p" margin=".5rem 0">
            A template brings together the ESG data you want and the columns that form a portfolio. Initialise template
            creation by defining these parameters.
          </Typography>
          <TextField
            uncontrolled
            className={classes.setUpInput}
            value={templateName}
            label="Template name"
            name="templateName"
            onFieldChange={useFieldChange('templateName')}
            data-testid={createTestid('input-templateName')}
          />
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="1rem">
            <div className={classes.setUpInput}>
              <Select<TemplateFrom>
                uncontrolled
                disableMargin
                value={templateFrom}
                variant="labeled"
                label="This new template is created"
                name="templateFrom"
                options={templateFromOptions}
                onFieldChange={handleTemplateFromChange}
                data-testid={createTestid('input-templateFrom')}
              />
            </div>
          </Stack>
          {templateFrom === 'EXISTING_TEMPLATE' && (
            <SelectTemplate
              onTemplateIdChange={onTemplateIdChange}
              templateId={templateId}
              data-testid={createTestid('selectTemplate')}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Buttons>
        <CreateTemplateButtons onNext={goNext} nextDisabled={!isNextAvailable} data-testid={createTestid('buttons')} />
      </Buttons>
    </>
  );
};
