import type { ClaritySfdrApi } from 'mns-sdk-collect';
import React from 'react';
import useStyles from './ValueSfdrRendererStyles';

interface Props {
  value?: ClaritySfdrApi.Metric['value'];
}

const ValueSfdrRenderer: React.FC<Props> = ({ value }: Props) => {
  const classes = useStyles();

  if (typeof value === 'number') {
    return <div className={classes.valueRenderer}>{value.toFixed(2)}</div>;
  }
  return null;
};

export default React.memo(ValueSfdrRenderer);
