import { makeStyles, colors } from 'mns-components';

const unauthorizedStyles = makeStyles({
  mainComponent: {
    paddingTop: '.625rem',
    marginTop: '6.25rem',
    '& h1': {
      color: colors.defaultFontColor,
    },
  },
  mainContent: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& svg': {
      width: '12.5rem',
      height: '12.5rem',
      color: colors.primaryPinkColor,
    },
  },
  textContent: {
    textAlign: 'left',
    maxWidth: '31.25rem',
    marginLeft: '1.875rem',
    fontSize: '1.5rem',
    color: colors.defaultFontColor,
  },
  iconSpacer: {
    paddingRight: '.625rem',
  },
});

export default unauthorizedStyles;
