import MatomoTracker from '@datapunt/matomo-tracker-js';
import { MatomoProvider as Matomo, createInstance } from '@datapunt/matomo-tracker-react';
import { matomoID } from './app.constants';

if (!matomoID) {
  throw new Error('Env should set integer "REACT_APP_matomoID"');
}

const matomoConfig = {
  urlBase: 'https://analytics.ifsalpha.com/',
  siteId: parseInt(matomoID, 10),
};

export const matomoTracker = new MatomoTracker(matomoConfig);
matomoTracker.trackPageView();

export const MatomoProvider: React.FC = () => <Matomo value={createInstance(matomoConfig)} />;
