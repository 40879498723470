import type { FormProps } from 'mns-components';
import {
  Checkbox,
  SearchField,
  SelectFiltersMulti,
  TagsFilters,
  filterTruthy,
  lowerCase,
  makeForm,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { useCases as globalUseCases, isUseCase } from 'mns-sdk-collect';
import type { MarketplaceApi, UseCase } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getApplicationConfig } from '../../../common/getApplicationConfig';
import type { AppCode } from '../../../components/views/appDescriptions';
import { marketplaceFiltersStyles as useStyles } from '../styles/marketplaceFiltersStyles';

export type FormType = {
  'data-only': boolean;
  'portfolio-analysis': boolean;
  'select-partners-marketplace': string[];
  'select-usecases-marketplace': UseCase[];
  search: string;
};

type FilterMap = { appDeliverables: string[]; partners: string[]; useCases: UseCase[] };

const useCasesOptions = globalUseCases.map((usecase: UseCase) => ({ value: usecase, label: usecase }));

export type MarketplaceFiltersProps = Omit<FormProps<FormType>, 'children'> & {
  applications: MarketplaceApi.Application[];
  onFilter?(applications: MarketplaceApi.Application[]): void;
  isAllApps?: boolean;
  'data-testid': string;
};

const MarketplaceFiltersComp: React.FC<MarketplaceFiltersProps> = ({
  applications,
  onFilter,
  isAllApps,
  'data-testid': testid,
}) => {
  const [filtersTag, setFiltersTag] = useState<FilterMap>();
  const [searchValue, setSearchValue] = useState<string>('');
  const { getValues, setValue } = useFormContext<FormType>();

  const {
    'data-only': isDataOnly,
    'portfolio-analysis': isPortfolioAnalysis,
    'select-partners-marketplace': partnersFilter,
    'select-usecases-marketplace': useCasesFilter,
  } = useWatch<FormType>();

  const createTestid = useTestid(testid);
  const classes = useStyles();

  const partners = useMemo(
    () => Array.from(new Set(applications.map((app) => app.provider.providerName))).sort(),
    [applications],
  );
  const partnersOptions = useMemo(() => partners.map((partner) => ({ value: partner, label: partner })), [partners]);

  const isPartner = useCallbackImmutable((str: string): boolean => {
    return !!partners.find((partner) => partner === str);
  });

  const filteredApplications = useMemo(() => {
    const deliverablesFilter: string[] = filterTruthy([
      isDataOnly && 'Data only',
      isPortfolioAnalysis && 'Portfolio analysis',
    ]);
    return applications.filter(({ codeApplication, provider: { providerName } }) => {
      const {
        content: { title, cardDescription, appDeliverable, useCases },
        company: { name },
      } = getApplicationConfig(codeApplication as AppCode);

      const matchSearch = () => {
        if (!searchValue) return true;
        const lowerSearchWords = lowerCase(searchValue).split(/\s+/);
        const lowerTitle = lowerCase(title);
        const lowerDescription = lowerCase(cardDescription ?? '');
        const lowerCompanyName = lowerCase(name);
        return (
          lowerSearchWords.every((word) => lowerTitle.includes(word)) ||
          lowerSearchWords.every((word) => lowerDescription.includes(word)) ||
          lowerSearchWords.every((word) => lowerCompanyName.includes(word))
        );
      };

      const matchDeliverables = () => !deliverablesFilter.length || deliverablesFilter.includes(appDeliverable);
      const matchPartners = () => !partnersFilter?.length || partnersFilter.includes(providerName);
      const matchUseCases = () =>
        !useCasesFilter?.length || useCasesFilter.find((useCase) => useCases.includes(useCase));

      return matchSearch() && matchDeliverables() && matchPartners() && matchUseCases();
    });
  }, [applications, isPortfolioAnalysis, isDataOnly, partnersFilter, useCasesFilter, searchValue]);

  useEffect(() => {
    setFiltersTag({
      appDeliverables: filterTruthy([isDataOnly && 'Data only', isPortfolioAnalysis && 'Portfolio analysis']),
      partners: partnersFilter ?? [],
      useCases: useCasesFilter ?? [],
    });
  }, [isDataOnly, isPortfolioAnalysis, partnersFilter, useCasesFilter]);

  useEffect(() => {
    onFilter?.(filteredApplications);
  }, [onFilter, filteredApplications]);

  const handleRemove = useCallbackImmutable((tagToRemove: string) => {
    switch (true) {
      case tagToRemove === 'Data only':
        setValue('data-only', false);
        break;
      case tagToRemove === 'Portfolio analysis':
        setValue('portfolio-analysis', false);
        break;
      case isUseCase(tagToRemove): {
        const selectedUseCases = getValues()[`select-usecases-marketplace`] ?? [];
        const filteredUseCases = selectedUseCases.filter((useCase) => useCase !== tagToRemove);
        setValue(`select-usecases-marketplace`, filteredUseCases);
        break;
      }
      case isPartner(tagToRemove): {
        const selectedPartners = getValues()[`select-partners-marketplace`] ?? [];
        const filteredPartners = selectedPartners.filter((partner) => partner !== tagToRemove);
        setValue('select-partners-marketplace', filteredPartners);
        break;
      }
    }
  });

  return (
    <div>
      <div className={classes.myAppsFiltersBlock} data-testid={testid}>
        <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} />
        <div className={classes.allFilters}>
          {isAllApps && (
            <div className={classes.selectFilters}>
              <SelectFiltersMulti
                onClear
                label="Use cases"
                name="select-usecases-marketplace"
                options={useCasesOptions}
                data-testid={createTestid('usecases')}
              />
            </div>
          )}
          <div className={classes.selectFilters}>
            <SelectFiltersMulti
              onClear
              color="secondary"
              label="Partners"
              name="select-partners-marketplace"
              options={partnersOptions}
              data-testid={createTestid('partners')}
            />
          </div>
          <div className={classes.appDeliverable}>
            <Checkbox label="Data only" name="data-only" data-testid={createTestid('filter-dataonly-appDeliverable')} />
            <Checkbox
              label="Portfolio analysis"
              name="portfolio-analysis"
              data-testid={createTestid('filter-portfolioanalysis-appDeliverable')}
            />
          </div>
        </div>
      </div>
      <div className={classes.tagsContainer}>
        <TagsFilters onRemove={handleRemove} tags={filtersTag} data-testid={createTestid('tagsFilter')} />
      </div>
    </div>
  );
};

export const MarketplaceFiltersAndSearch = makeForm<FormType, MarketplaceFiltersProps>(MarketplaceFiltersComp);
