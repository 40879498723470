import { colors, makeStyles } from 'mns-components';

const inviteAccessPermissionStyles = makeStyles({
  content: {
    overflowY: 'auto',
    marginBottom: '6rem',
  },
  emailListSelect: {
    marginTop: '2.5rem',
    '& > :first-child': {
      margin: '8px',
    },
  },
  emailText: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: 'fit-content',
    '& > :first-child': {
      flexGrow: 1,
      marginTop: 0,
    },
  },
  sectionTitle: {
    display: 'flex',
    paddingTop: '2.5rem',
    fontWeight: 'bold !important',
    color: colors.blackColor,
  },
  formFooter: {
    textAlign: 'center',
    bottom: 0,
    position: 'absolute',
    width: 'calc(100% - 3rem)',
    backgroundColor: `${colors.whiteColor} !important`,
    zIndex: 6666,
    '& > *': {
      margin: '1rem 0.25rem',
    },
    '& hr': {
      margin: 0,
      backgroundColor: 'rgba(95,122,241, 0.4)',
      height: '1px',
      border: 'none',
    },
  },
  email: {
    margin: '0.625rem',
  },
  emailList: {
    display: 'flex',
    paddingTop: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `solid 1px ${colors.greyColor}`,
  },
  btn: {
    '& > *': {
      boxShadow: 'none',
    },
  },
});

export default inviteAccessPermissionStyles;
