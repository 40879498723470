import { makeOpenModal } from 'mns-components';
import { api } from '../../store/api';
import { getViewsRoute } from '../views/routes';

export const useIsinListsRequest = (type?: 'ISIN' | 'USER_SCOPE') => api.inventories.isinList.getLists.useQuery(type);

export const useDeleteIsinListRequest = () => {
  const invIsinLists = api.inventories.isinList.getLists.useInvalidateRootQuery();
  return api.inventories.isinList.removeList.useMutation({ onSuccess: () => invIsinLists() });
};

export const useCreateIsinListRequest = () => {
  const invIsinLists = api.inventories.isinList.getLists.useInvalidateRootQuery();
  return api.inventories.isinList.createList.useMutation({ onSuccess: () => invIsinLists() });
};

export const useEditIsinListRequest = () => {
  const invIsinLists = api.inventories.isinList.getLists.useInvalidateRootQuery();
  return api.inventories.isinList.updateList.useMutation({ onSuccess: () => invIsinLists() });
};

export const [useOpenEditIsinList, useManageOpenedEditIsinList] = makeOpenModal(
  () => getViewsRoute('isin-list').path,
  'openEditIsinListId',
);
