import type { GridApi, RowSelectedEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  TextField,
  useDefaultColDef,
  useAutoSize,
  useImmutable,
  useRowSelection,
  useTestid,
  useCallbackImmutable,
} from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { AppCode } from '../../../views/appDescriptions';
import { createEmailDeliveryModalStyles as useStyles } from '../createEmailDeliveryModalStyles';

const plurial = (count: number | string) => (+count === 1 ? '' : 'es');

const columns: AggridTableColumn<EetApi.EetFile<AppCode>>[] = [
  {
    field: 'fileName',
    headerName: 'Name',
  },
  {
    field: 'scope',
    headerName: 'Scope',
    cellRenderer: ({ value }) => `${value} share class${plurial(value)}`,
  },
];

type SelectScopeEetStepProps = {
  rows: EetApi.EetFile<AppCode>[];
  onRowsSelected(rows: EetApi.EetFile<AppCode>[]): void;
  getGridApiRef?: React.MutableRefObject<GridApi | null> | ((api: GridApi | null) => void);
  'data-testid': string;
};

export const SelectScopeEetList: React.FC<SelectScopeEetStepProps> = ({
  rows,
  onRowsSelected,
  getGridApiRef,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [searchValue, setSearchValue] = useState('');
  const methods = useForm({ defaultValues: { search: '' } });

  const handleSearchChange = useImmutable(() => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  });

  const onRowsSelectedFct = useCallbackImmutable((ev: RowSelectedEvent) =>
    onRowsSelected(
      (ev.api.getSelectedRows() as EetApi.EetFile<AppCode>[]).reduce((acc, { id: eetId }) => {
        const found = rows.find((row) => row.id === eetId);
        if (found) acc.push(found);
        return acc;
      }, [] as EetApi.EetFile<AppCode>[]),
    ),
  );

  const [aggridProps, colSelection] = useRowSelection<EetApi.EetFile<AppCode>>(onRowsSelectedFct, true);

  return (
    <FormProvider {...methods}>
      <TextField
        name="search"
        label="Search"
        type="text"
        onChange={handleSearchChange}
        search={true}
        className={classes.searchInput}
        data-testid={createTestid('searchInput')}
      />
      <AggridTable
        {...aggridProps}
        rowData={rows}
        columnDefs={[colSelection, ...columns]}
        getRowNodeId={useImmutable(() => (row: EetApi.EetFile<AppCode>) => row.id)}
        getGridApiRef={getGridApiRef}
        quickFilterText={searchValue}
        defaultColDef={useDefaultColDef<EetApi.EetFile<AppCode>>()}
        onGridReady={useAutoSize('fit')}
        pagination
        paginationAutoPageSize
        data-testid={testid}
      />
    </FormProvider>
  );
};
