import { colors, makeStyles } from 'mns-components';
import bannerDataOnly from '../../../assets/images/banner-categories/banner_data_only.svg';
import bannerManaos from '../../../assets/images/banner-categories/banner_manaos.svg';
import bannerPortfolioAnalysis from '../../../assets/images/banner-categories/banner_portfolio_analysis.svg';
import dataOnly from '../../../assets/images/iconCategoriesCards/data-only.svg';
import portfolioAnalysis from '../../../assets/images/iconCategoriesCards/portfolio.svg';

export const cardModalStyles = makeStyles({
  iconProvider: {
    flex: '0 0 auto',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    minHeight: '10rem',
    minWidth: '20rem',
    alignSelf: 'center',
  },
  banner: {
    backgroundColor: colors.blueColor,
    borderRadius: '1rem',
    margin: '.7rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bannerText: {
    color: colors.whiteColor,
    margin: '0 0 0 1.5rem',
    display: 'grid',
    alignContent: 'center',
  },
  bannerTitle: {
    fontSize: '22px',
  },
  bannerSubText: {
    fontSize: '14px',
    marginTop: '.5rem',
  },
  iconAppDeliverable: {
    width: '1rem',
    height: '1rem',
    marginRight: '.25rem',
    backgroundSize: 'cover',
  },
  bannerInfo: {
    margin: '8px 0 0',
    color: colors.blueColor,
    backgroundColor: colors.blueGreyColor,
    borderRadius: '1rem',
    fontSize: '12px',
    display: 'flex',
    width: 'fit-content',
    padding: '.2rem .4rem',
  },
  bannerInfoTxt: {
    paddingTop: '1px',
  },
  cardModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  scroll: {
    flex: '1 1 auto',
    overflow: 'clip auto',
  },
  header: {
    position: 'relative',
  },
  compContainer: {
    padding: '1rem 2.5rem',
    flexGrow: '1',
  },
  subText: {
    fontSize: '.75rem',
    lineHeight: '1rem',
    textAlign: 'justify',
    color: colors.blackColor,
  },
  buttons: {
    flex: '0 0 auto',
    margin: '1rem 2rem',
    justifyContent: 'flex-end',
    display: 'flex',
    borderTop: `1px solid ${colors.lightGreyColor}`,
    position: 'sticky',
    bottom: '0',
    '> div': {
      marginLeft: '1rem',
      display: 'flex',
      '> *:first-child': {
        marginRight: '1rem',
      },
    },
  },
});

export const appDeliverableStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${dataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysis})`,
  },
  Other: {},
});

export const bannerBgStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${bannerDataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${bannerPortfolioAnalysis})`,
  },
  Other: {
    backgroundImage: `url(${bannerManaos})`,
  },
});

export const navButtonsStyles = makeStyles({
  tabsContainer: {
    display: 'flex',
    margin: '0 2rem',
    borderBottom: `1px solid ${colors.lightGreyColor}`,
  },
  tab: {
    borderRadius: '2px',
    cursor: 'pointer',
    lineHeight: '1rem',
    padding: '1rem',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    marginRight: '1rem',
    color: colors.darkGreyColor,
    borderBottom: `solid 3px ${colors.whiteColor}`,
  },
  activeTab: {
    borderBottom: `solid 3px ${colors.orangeColor}`,
    color: colors.blackColor,
    fontWeight: 'bold',
  },
});
