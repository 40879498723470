import type React from 'react';
import { getApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../views/appDescriptions';
import { CollectApplicationSettings } from './dataCollect/CollectApplicationSettings';
import { DisseminationApplicationSettings } from './dataDissemination/DisseminationApplicationSettings';
import { EmailApplicationSettings } from './dataDissemination/EmailApplicationSettings';

export type AppSettingsView<A extends AppCode, T extends { 'data-testid': string } = { 'data-testid': string }> = {
  appCode: A;
  label: string;
  component: React.FC<T>;
};

const applicationsSettingsRaw = [
  {
    appCode: 'data_dissemination',
    label: getApplicationConfig('data_dissemination').content.title,
    component: DisseminationApplicationSettings,
  },
  {
    appCode: 'data_collect',
    label: getApplicationConfig('data_collect').content.title,
    component: CollectApplicationSettings,
  },
  {
    appCode: 'manaos-eet',
    label: getApplicationConfig('manaos-eet').content.title,
    component: EmailApplicationSettings,
  },
  {
    appCode: 'manaos-productrangedissemination',
    label: getApplicationConfig('manaos-productrangedissemination').content.title,
    component: EmailApplicationSettings,
  },
] as const;

export type AppSettingsViewCode = typeof applicationsSettingsRaw[number]['appCode'];

export const applicationsSettings = applicationsSettingsRaw as readonly AppSettingsView<AppSettingsViewCode>[];
