import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Link,
  convertToDateLocal,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { getPortfolioViewRoute } from '../../../applications/myPortfoliosApp/routes';
import { QualitiesRenderer } from '../../cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../headerRender/QualitiesHeaderRenderer';
import { DownloadPortfolioRenderer } from './DownloadPortfolioRenderer';
import { myPortfoliosStyles as useStyles } from './myPortfoliosStyles';
import { PortfoliosListViewButton } from './PortfoliosListViewButton';

const getActionColDef = makeActionColDef<CollectApi.Portfolio>();

const useColumns = (selectCol: AggridTableColumn<CollectApi.Portfolio>, testid: string) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return useMemo(
    (): AggridTableColumn<CollectApi.Portfolio>[] => [
      selectCol,
      {
        field: 'externalId.value',
        headerName: 'ID',
        tooltipField: 'externalId.value',
        minWidth: 150,
        maxWidth: 150,
        cellRendererFramework: ({
          data: {
            externalId: { value },
            portfolioId,
            latestVersion: { valuationDate },
          },
        }) =>
          portfolioId ? (
            <Link.Underlined
              to={generatePath(getPortfolioViewRoute('portfolios-lists-composition').link, {
                id: portfolioId,
                date: valuationDate,
              })}
              data-testid={createTestid(`${value}-view`)}
            >
              {value}
            </Link.Underlined>
          ) : (
            value
          ),
      },
      {
        field: 'name',
        headerName: 'Name',
        tooltipField: 'name',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'latestVersion.valuationDate',
        headerName: 'Nav date',
        width: 180,
        cellRenderer: ({ value }) => convertToDateLocal(value),
      },
      {
        field: 'organisationName',
        headerName: 'Provided by',
        tooltipField: 'organisationName',
      },
      {
        field: 'latestVersion.uploadOn',
        headerName: 'Received on',
        width: 180,
        cellRenderer: ({ value }) => convertToDateLocal(value),
      },
      {
        field: 'latestVersion.qualities',
        headerName: 'Qualities',
        cellRendererFramework: ({
          data: {
            latestVersion: { qualities },
            externalId: { value },
          },
        }) => <QualitiesRenderer qualities={qualities} data-testid={createTestid(`${value}-quality`)} />,
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={createTestid('quality')} />,
        width: 110,
      },
      getActionColDef(
        'latestVersion',
        180,
        ({
          data: {
            portfolioId,
            externalId: { value, type },
            latestVersion: { valuationDate, processId },
            name,
          },
        }) => (
          <Stack className={classes.portfoliosListActions}>
            <PortfoliosListViewButton
              portfolioId={portfolioId}
              latestVersionValuationDate={valuationDate}
              data-testid={createTestid(`${value}-quality`)}
            />
            <DownloadPortfolioRenderer
              externalId={value}
              externalIdType={type}
              valuationDate={valuationDate}
              processId={processId}
              name={name}
              isCollectedList
              data-testid={createTestid(`${value}-download`)}
            />
          </Stack>
        ),
      ),
    ],
    [classes, selectCol, createTestid],
  );
};

interface PortfoliosCollectedListProps {
  rowData: CollectApi.Portfolio[];
  searchValue: string;
  onRowSelected: (rows: CollectApi.Portfolio[]) => void;
  getGridApiref: (api: GridApi | null) => void;
  'data-testid': string;
}

export const PortfoliosCollectedList = React.memo<PortfoliosCollectedListProps>(function PortfoliosCollectedList({
  rowData,
  searchValue,
  onRowSelected,
  getGridApiref: setGridApi,
  'data-testid': testid,
}) {
  const [gridProps, selectCol] = useRowSelection<CollectApi.Portfolio>(
    ({ api }) => onRowSelected(api.getSelectedRows()),
    true,
  );
  return (
    <AggridTable
      quickFilterText={searchValue}
      rowData={rowData}
      {...gridProps}
      defaultColDef={useDefaultColDef<CollectApi.Portfolio>()}
      columnDefs={useColumns(selectCol, testid)}
      getRowNodeId={useImmutable(
        () =>
          ({ latestVersion: { id } }: CollectApi.Portfolio) =>
            id,
      )}
      getGridApiRef={setGridApi}
      pagination
      paginationAutoPageSize
      onGridReady={useAutoSize('fit')}
      data-testid={testid}
    />
  );
});
