import { IconButton, Menu } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useState } from 'react';

interface Props<T> {
  data: T;
  setSelected(selectedItem: T[]): void;
  openPermissionsDetailsModal(isOpen: boolean): void;
  'data-testid': string;
}

export const MoreDetailsPermissionsRenderer = <T extends AnyObject>({
  data,
  setSelected,
  openPermissionsDetailsModal,
  'data-testid': testid,
}: Props<T>) => {
  const createTestid = useTestid(testid);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  const handleClickPermissionsDetails = useCallbackImmutable(() => {
    setSelected([data]);
    openPermissionsDetailsModal(true);
    handleClose();
  });

  return (
    <>
      <IconButton color="secondary" onClick={handleClick} data-testid={createTestid('button-openmenu')}>
        <Icon.More data-testid={createTestid('icon-openmenu')} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleClickPermissionsDetails} data-testid={createTestid('menu-button-details')}>
          Access Request details
        </MenuItem>
      </Menu>
    </>
  );
};
