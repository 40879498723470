import type { SimpleTableProps } from 'mns-components';
import { Button, colors, SimpleTable, Typography, useTestid } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { automatedExtractionsSettingsStyles as useStyles } from './styles/automatedExtractionsSettingsStyles';

const columns: SimpleTableProps<DataExtractorApi.PortfolioLight & DataExtractorApi.PortfolioLightAndDate>['columns'] = [
  {
    field: 'externalIdValue',
    head: 'Portfolio ID',
  },
  {
    field: 'name',
    head: 'Portfolio name',
  },
];

type AutomatedExtractionDetailsModalProps = {
  extractionDetails: DataExtractorApi.Extraction.Extraction;
  onClose(): void;
  'data-testid': string;
};

export const AutomatedExtractionDetailsModal: React.FC<AutomatedExtractionDetailsModalProps> = ({
  extractionDetails,
  onClose,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.fullHeight}>
      <div>
        <div className={classes.detailsDialogSection}>
          <Typography component="p" variant="body2" color={colors.darkGreyColor}>
            Template used:
          </Typography>
          <Typography component="p" variant="body2" color={colors.blueColor} data-testid={createTestid('templateName')}>
            {extractionDetails.templateName}
          </Typography>
        </div>
        <div className={classes.detailsDialogSection}>
          <Typography
            className={classes.detailsDialogTableTitle}
            component="p"
            variant="body1"
            color={colors.blueColor}
          >
            Scope details
          </Typography>
          <div className={classes.scroll}>
            <SimpleTable
              rows={extractionDetails.extractionScope}
              columns={columns}
              disableLeftPadding
              data-testid={createTestid('list')}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttonList}>
        <Button color="primary" outlined onClick={onClose} data-testid={createTestid('button-close')}>
          Close
        </Button>
      </div>
    </div>
  );
};
