import { colors, makeStyles } from 'mns-components';

const widgetAppDownloadPdfReportStyles = makeStyles({
  tooltip: {
    cursor: 'pointer',
    background: 'rgba(95,122,241,0.1)',
    color: colors.infoColor,
    borderRadius: '50%',
  },
});

export default widgetAppDownloadPdfReportStyles;
