import { Stack } from '@mui/material';
import {
  Dialogv2,
  EmptyStateV2,
  Icon,
  LoadingCircle,
  SearchField,
  SelectFilter,
  SplitButton,
  colors,
  groupByMany,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import type { BlobTransform } from '../../../../common/utils';
import { downloadBlobAsCsv, getDownloadBlobAsXlsxWithSeparator } from '../../../../common/utils';
import { useDownloadPresignedUrl } from '../../../../hooks/useDownloadPresignedUrl';
import { useGetFileErrorLog } from '../hooks';
import { DialogAuditErrorLogDetails } from './DialogAuditErrorLogDetails';
import { DialogAuditErrorLogList } from './DialogAuditErrorLogList';

type DialogAddPortfolioProps = {
  isOpenModal: boolean;
  onClose?(): void;
  errorLogFileData: { fileName: string; processID: string };
  'data-testid': string;
};
type DialogAddPortfolioInnerProps = Pick<DialogAddPortfolioProps, 'errorLogFileData' | 'data-testid'>;

export enum ErrorStatus {
  'Rejected',
  'Blocking regulatory',
  'Non-blocking',
}

export type ErrorLogDataRework = {
  errorStatus: ErrorStatus;
  errorType: string;
  errorMessage: string;
  columnName: string;
  lineNumbers: number[];
};

const transformErrorLogData = (data: CollectApi.FileErrorType[], errorStatus: ErrorStatus): ErrorLogDataRework[] => {
  const finalData: ErrorLogDataRework[] = [];
  const groupedData = groupByMany(data, ['columnName', 'errorType', 'errorMessage']);
  groupedData.forEach((item) => {
    const allLines: number[] = [];
    item.items.forEach((error) => allLines.push(error.lineNumber));
    const reworkedItem: ErrorLogDataRework = {
      errorType: item.match.errorType,
      errorMessage: item.match.errorMessage,
      columnName: item.match.columnName,
      errorStatus: errorStatus,
      lineNumbers: allLines,
    };
    finalData.push(reworkedItem);
  });
  return finalData;
};

const DialogAuditErrorLogInner = ({ errorLogFileData, 'data-testid': testId }: DialogAddPortfolioInnerProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [errorDetailsOpen, setErrorDetailsOpen] = useState<ErrorLogDataRework | false>(false);
  const createTestid = useTestid(testId);

  const onClickDetailsOpen = useCallbackImmutable((errorDetails: ErrorLogDataRework) => {
    if (errorDetails === errorDetailsOpen) setErrorDetailsOpen(false);
    else setErrorDetailsOpen(errorDetails);
  });
  const onClickDetailsClose = useCallbackImmutable(() => {
    setErrorDetailsOpen(false);
  });

  const { data: fileErrorLogData, isLoading, isError } = useGetFileErrorLog(errorLogFileData.processID);

  const filteredRejectedData = useCallbackImmutable(() => {
    const data = fileErrorLogData?.data.filter(
      (item: CollectApi.FileErrorType) => item.isBlocking === 'Y' && item.quality === 'N/A',
    );
    return data ? transformErrorLogData(data, ErrorStatus.Rejected) : [];
  });
  const filteredBlockingData = useCallbackImmutable(() => {
    const data = fileErrorLogData?.data.filter(
      (item: CollectApi.FileErrorType) => item.isBlocking === 'Y' && item.quality === 'REGULATORY',
    );
    return data ? transformErrorLogData(data, ErrorStatus['Blocking regulatory']) : [];
  });
  const filteredNonBlockingData = useCallbackImmutable(() => {
    const data = fileErrorLogData?.data.filter((item: CollectApi.FileErrorType) => item.isBlocking === 'N');
    return data ? transformErrorLogData(data, ErrorStatus['Non-blocking']) : [];
  });

  const filteredData: ErrorLogDataRework[] = useMemo(() => {
    if (!fileErrorLogData?.data) return [];
    if (filterStatus === 'rejected') return filteredRejectedData();
    if (filterStatus === 'blocking') return filteredBlockingData();
    if (filterStatus === 'nonblocking') return filteredNonBlockingData();
    else return [...filteredRejectedData(), ...filteredBlockingData(), ...filteredNonBlockingData()];
  }, [filterStatus, filteredRejectedData, filteredBlockingData, filteredNonBlockingData, fileErrorLogData]);

  const filterOptions: { value: string; label: string; color: string; count: number }[] = [
    {
      value: 'rejected',
      label: 'Rejected',
      color: colors.dangerColor,
      count: filteredRejectedData().length,
    },
    {
      value: 'blocking',
      label: 'Blocking reg.',
      color: colors.warningColor,
      count: filteredBlockingData().length,
    },
    {
      value: 'nonblocking',
      label: 'Non-blocking',
      color: colors.primaryColor,
      count: filteredNonBlockingData().length,
    },
  ];

  if (isLoading)
    return (
      <Stack alignItems="center" justifyContent="center" flex="1" height="100%" data-testid={createTestid('loading')}>
        <LoadingCircle size="medium" data-testid="backdrop-error-log" />
      </Stack>
    );

  if (isError)
    return (
      <Stack alignItems="center" justifyContent="center" flex="1" height="100%">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          illustrationVariant="iconType"
          title="Oops, something went wrong!"
          subTitle={'Error log could not be loaded. \n Please download the error log by clicking on the button below.'}
          data-testid={createTestid('error')}
        />
      </Stack>
    );

  return (
    <Stack gap="1rem" position="relative" height="100%">
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <SearchField
          value={searchValue}
          disabled={filteredData.length === 0}
          onChange={setSearchValue}
          disableMargin
          data-testid={createTestid('search')}
        />
        <SelectFilter
          label="Level"
          size="fullHeight"
          items={filterOptions}
          onClick={setFilterStatus}
          activeItem={filterStatus}
          data-testid={createTestid('select')}
        />
      </Stack>
      <Stack height="92%">
        <DialogAuditErrorLogList
          onDetailsClick={onClickDetailsOpen}
          errorlogData={filteredData}
          currentErrorDetailsOpen={typeof errorDetailsOpen === 'boolean' ? undefined : errorDetailsOpen}
          searchValue={searchValue}
          data-testid={createTestid('list')}
        />
        {errorDetailsOpen && (
          <DialogAuditErrorLogDetails
            errorDetails={errorDetailsOpen}
            onClickDetailsClose={onClickDetailsClose}
            data-testid={createTestid('details')}
          />
        )}
      </Stack>
    </Stack>
  );
};

type DownloadKey = 'error_log_csv' | 'error_log_excel';

const downloadOptions: { value: DownloadKey; label: string }[] = [
  {
    value: 'error_log_csv',
    label: 'Download (.csv)',
  },
  {
    value: 'error_log_excel',
    label: 'Download (.xlsx)',
  },
];

const downloadFilesMapping: {
  key: DownloadKey;
  dlType: CollectApi.FileType;
  label: string;
  transformBlob?: BlobTransform;
}[] = [
  {
    key: 'error_log_csv',
    dlType: 'error_log',
    label: 'Error log (.csv)',
    transformBlob: downloadBlobAsCsv,
  },
  {
    key: 'error_log_excel',
    dlType: 'error_log',
    label: 'Error log (.xslx)',
    transformBlob: getDownloadBlobAsXlsxWithSeparator(';'),
  },
];

export const DialogAuditErrorLog: React.FC<DialogAddPortfolioProps> = ({
  isOpenModal,
  onClose,
  errorLogFileData,
  'data-testid': testid,
}: DialogAddPortfolioProps) => {
  const downloadPresignedUrl = useDownloadPresignedUrl();
  const createTestid = useTestid(testid);

  const handleDownloadFile = useCallbackImmutable(async (_, downloadKey: DownloadKey) => {
    const { dlType, label, transformBlob } = downloadFilesMapping.find((assoc) => assoc.key === downloadKey)!;
    await downloadPresignedUrl(
      {
        fileDownloadType: dlType,
        fileDownloadTypeLabel: label,
        processId: errorLogFileData.processID,
        fileName: errorLogFileData.fileName,
      },
      true,
      transformBlob,
    );
  });

  const handleClickDownload = useCallbackImmutable(() => handleDownloadFile(null, 'error_log_excel'));

  return (
    <Dialogv2
      DialogTitleIcon={Icon.Error}
      dialogTitle={`Error log - ${errorLogFileData.fileName}`}
      open={isOpenModal}
      onClose={onClose}
      sizeVariant="md"
      data-testid={createTestid('dialog')}
      otherButtons={
        <SplitButton
          label="Download Error Log"
          options={downloadOptions}
          color="primary"
          icon="download"
          onClickButton={handleClickDownload}
          onClickDropdown={handleDownloadFile}
          data-testid={'download'}
        />
      }
    >
      <DialogAuditErrorLogInner errorLogFileData={errorLogFileData} data-testid={createTestid('dialog-inner')} />
    </Dialogv2>
  );
};
