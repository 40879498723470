import { makeStyles } from 'mns-components';

export const settingsStyle = makeStyles({
  disseminationApplicationSettingsModal: {
    padding: '2rem',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.5rem',
  },
  field: {
    flex: '0 0 40%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    width: '11rem',
  },
  toggler: {
    width: '5rem',
    marginLeft: '.5rem',
  },
  buttonList: {
    paddingTop: '2rem',
  },
  orgsContainer: {
    paddingRight: '1rem',
  },
  descrColumn: {
    flex: '0 1 auto',
    minWidth: 0,
    marginRight: '2rem',
  },
  wideColumn: {
    flex: '0 0 30rem',
  },
  info: {
    opacity: 0.6,
  },

  settingsCategory: {
    marginTop: '3rem',
    marginBottom: '1.5rem',
  },

  settingsField: {
    display: 'flex',
    gap: '4rem',
  },

  settingsLabel: {
    flexBasis: '40%',
  },

  settingsName: {
    fontSize: '1.2rem',
    fontWeight: 500,
    marginBottom: '0.5rem',
  },

  settingsDescription: {
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '1rem',
    opacity: 0.5,
    paddingLeft: '1rem',
  },

  settingsInput: {
    width: '12rem',
    marginBottom: '1rem',
    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },
  },

  settingsTextField: {
    margin: 0,
  },

  settingSection: {
    width: '50rem',
  },
});
