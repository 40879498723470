import { IconButton, Menu, Tooltip } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useState } from 'react';

type ExtractionGeneratedHistoryBulkProps = {
  disabled?: boolean;
  onDownloadLogs(): void;
  'data-testid': string;
};

export const ExtractionGeneratedHistoryBulk: React.FC<ExtractionGeneratedHistoryBulkProps> = ({
  disabled,
  onDownloadLogs,
  'data-testid': testid,
}: ExtractionGeneratedHistoryBulkProps) => {
  const createTestid = useTestid(testid);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = useCallbackImmutable(({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(currentTarget),
  );

  const handleClose = useCallbackImmutable(() => setAnchorEl(null));

  return (
    <>
      <Tooltip title="More actions">
        <IconButton
          onClick={handleOpen}
          disabled={disabled}
          size="small"
          color="secondary"
          data-testid={createTestid('button-more')}
        >
          <Icon.More data-testid={createTestid('icon-more')} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        <MenuItem onClick={onDownloadLogs} data-testid={createTestid('button-downloadLogs')}>
          Execution Log
        </MenuItem>
      </Menu>
    </>
  );
};
