import { makeStyles, colors } from 'mns-components';

const errorPageStyles = makeStyles({
  mainComponent: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: '#b6d5d8',
    overflow: 'hidden',
    top: '0',
  },
  title: {
    color: colors.whiteColor,
  },
  textContent: {
    color: colors.whiteColor,
    marginBottom: '1.25rem',
  },
  imgJetpackBusinessman: {
    left: '0',
    bottom: 0,
    position: 'absolute',
    width: '30%',
  },
  imgRopeBusinessman: {
    right: '0',
    top: '10rem',
    position: 'absolute',
    width: '23%',
    minWidth: '35%',
    maxWidth: '46.875rem',
  },
  videoWrapper: {
    margin: '3.125rem auto 0',
    zIndex: 10,
    position: 'relative',
  },
});

export default errorPageStyles;
