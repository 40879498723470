import { makeStyles } from 'mns-components';

const selectInventoryDateStyles = makeStyles({
  mainDiv: {
    textAlign: 'left',
  },
  mainLabel: {
    color: '#11113C',
    margin: 0,
    opacity: 0.6,
    fontSize: '.75rem !important',
    textAlign: 'left',
    lineHeight: '.875rem !important',
    paddingRight: '.625rem',
    letterSpacing: '1px !important',
  },
  subLabel: {
    color: '#11113C',
    margin: 0,
    opacity: 0.6,
    fontSize: '.625rem !important',
    textAlign: 'left',
    lineHeight: '.875rem !important',
    paddingRight: '.625rem',
    letterSpacing: '1px !important',
  },
  dateContainerInventoriesContainer: {
    maxWidth: '100% !important',
    display: 'flex',
  },
  datepickers: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default selectInventoryDateStyles;
