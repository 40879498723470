import { Stack } from '@mui/material';
import type { AggridTableProps } from 'mns-components';
import { Backdrop, EmptyStateV2, useTestid } from 'mns-components';
import type { AnalysisApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useUnderlyingData } from '../hooks';
import { getRoute } from '../routes';
import type { FormData } from './AnalysisDetailsController';
import { AnalysisDetailsList } from './AnalysisDetailsList';

type UnderlyingDetailsRequesterProps = {
  analysisList: AnalysisApi.AnalysisPortfolio<AppCode>[];
  getGridApiRef?: AggridTableProps<AnyObject<string, string>>['getGridApiRef'];
  'data-testid': string;
};

export const UnderlyingDetailsRequester: React.FC<UnderlyingDetailsRequesterProps> = ({
  analysisList,
  getGridApiRef,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  // form
  const state = useWatch<FormData>();

  // item selection
  const analysisStatus = useMemo(() => {
    if (analysisList) {
      const found = analysisList?.filter((analysis) => {
        if (analysis.applicationCode !== state.appCode) return false;
        if (analysis.externalId !== state.externalId) return false;
        if (analysis.valuationDate !== state.navDate) return false;
        return true;
      });
      if (found.length >= 1) return found.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];
    }
    return undefined;
  }, [analysisList, state]);

  // analysis
  const {
    data: analysisData,
    isLoading: isLoadingAnalysisData,
    isError: isErrorAnalysisData,
  } = useUnderlyingData(state.appCode, analysisStatus?.analysisId);

  if (isLoadingAnalysisData) {
    return <Backdrop data-testid={createTestid('loading')} />;
  }

  if (isErrorAnalysisData) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact support team."
          data-testid={createTestid('error-paiList')}
        />
      </Stack>
    );
  }

  if (!analysisData?.length) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="actions"
          variant="info"
          title="Get started with your EET!"
          subTitle="Start creating your EET to analyse your portfolio(s)."
          buttonVariant="secondary"
          buttonIcon="add"
          buttonHref={generatePath(getRoute('generate-eet').link)}
          buttonText="Create EET"
          data-testid={createTestid('error-paiList')}
        />
      </Stack>
    );
  }

  return (
    <AnalysisDetailsList
      uniqueKey="id"
      rows={analysisData}
      getGridApiRef={getGridApiRef}
      data-testid={createTestid('list')}
    />
  );
};
