import type { StepProps } from 'mns-components';
import { objectEntries, objectKeys, useCallbackImmutable, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { getApplicationPipeTitle } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { AccordionFinalize } from '../components/AccordionFinalize';
import type { CreateTemplateSteps } from '../types';

export const ApplicationsDataPointsTab: React.FC<StepProps<CreateTemplateSteps, 3>> = ({
  stepValues: [, , { applicationsDataPoints }],
  setCurrentStep,
  setStepValues,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [accordionPanel, setAccordionPanel] = useState<AppCode | null>(null);
  const makeAccordionChange = useCallbackImmutable(
    (newAccordionPanel: AppCode) => (_: unknown, expanded: boolean) =>
      setAccordionPanel(expanded ? newAccordionPanel : null),
  );

  const onClickModify = useCallbackImmutable((ev: React.MouseEvent, code: AppCode) => {
    ev.preventDefault();
    ev.stopPropagation();
    setStepValues(([setUp, ptfDps, appsDps, ...steps]) => [
      setUp,
      ptfDps,
      { applicationsDataPoints: appsDps.applicationsDataPoints, selectedAppCode: code },
      ...steps,
    ]);
    setCurrentStep(2);
  });

  // when columns are loaded, select its first category
  useEffect(() => {
    const [name] = objectKeys(applicationsDataPoints);
    if (name) setAccordionPanel(name);
  }, [applicationsDataPoints]);

  return (
    <>
      {objectEntries(applicationsDataPoints).map(([appCode, list]) =>
        !list?.length ? null : (
          <AccordionFinalize
            key={appCode}
            accordionPanel={accordionPanel}
            category={appCode}
            categoryName={getApplicationPipeTitle(appCode)}
            list={list}
            onClickModify={onClickModify}
            useAccordionChange={makeAccordionChange}
            data-testid={createTestid(appCode)}
          />
        ),
      )}
    </>
  );
};
