import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useQuery } from 'react-query';
import { api } from '../../store/api';
import { eetApi } from '../../store/apis';

export const useFetchEetFiles = () => useQuery(['eetApi.getEetFiles'], () => eetApi.getEetFiles());

export const useDeliveriesRequest = (params: DataDeliveryApi.DataDeliveriesRequest) =>
  api.inventories.dataDelivery.getDeliveries.useQuery(params, {
    enabled: !!params.start && !!params.end,
    refetchIntervalInBackground: true,
  });
