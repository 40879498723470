import type { ToastOptions, UpdateOptions, Id } from 'react-toastify';
import { toast } from 'react-toastify';

export const toastUpdateOrCreate = (toastId: Id, options: ToastOptions & UpdateOptions) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, options);
  } else {
    toast(options.render, options);
  }
};
