import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import { colors, gradient } from 'mns-components';

export const Layout: React.FC<{ children: React.ReactNode } & StackProps> = ({ children, ...stackProps }) => (
  <Stack flexGrow={1} padding="1.5rem 2rem" minHeight={0} sx={{ background: gradient.gradientWhiteBackground }}>
    <Stack
      flexGrow={1}
      minHeight={0}
      position="relative"
      bgcolor={colors.whiteColor}
      boxShadow="rgba(0, 0, 0, 0.08) 0px 0px 14px 0px"
      borderRadius="5px"
      overflow="hidden"
      {...stackProps}
    >
      {children}
    </Stack>
  </Stack>
);
