import { makeStyles } from 'mns-components';

export const modalStyles = makeStyles({
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
    '& > *:not(:first-of-type)': {
      marginLeft: '1em',
    },
  },
  body: {
    padding: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
