import { makeStyles } from 'mns-components';

const EmitterPermissionsStyle = makeStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    right: '3.125rem',
    top: '15.625rem',
  },
  searchContainer: {
    position: 'static',
    padding: '0.5rem 0.5rem 0 0.5rem',
    width: '15.625rem',
    display: 'block',
    margin: '0 auto auto 0',
  },
});

export default EmitterPermissionsStyle;
