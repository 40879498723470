import { Box, Stack } from '@mui/material';
import { MenuItem, SideNavBar, SideNavBarItem, SideNavBarSection, colors, useTestid, Divider } from 'mns-components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getMarketplaceRoute } from '../../applications/marketplace/routes';
import { isCognitoUser, useAuthUser } from '../../hooks/useAuth';
import { getAuthRoute } from '../auth/routes';
import { getSettingsRoute } from '../settings/routes';
import { getViewsRoute } from '../views/routes';
import { appSideNavBarStyles as useStyles } from './styles/appSideNavBarStyles';

type AppSideNavBarProps = {
  organisationName: string | undefined;
  'data-testid': string;
};

export const AppSideNavBar: React.FC<AppSideNavBarProps> = ({ organisationName, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { pathname } = useLocation();

  const [authUser] = useAuthUser();
  const currentUser = isCognitoUser(authUser) ? authUser.getUsername() : undefined;

  const getCurrentView = (): 'portfolios' | 'esg' | 'uploads' | 'settings' | 'marketplace' => {
    if (pathname.startsWith('/portfolios')) return 'portfolios';
    if (pathname.startsWith(getViewsRoute('esg').path)) return 'esg';
    if (pathname.startsWith(getViewsRoute('uploads').path)) return 'uploads';
    if (pathname.startsWith(getViewsRoute('settings').path)) return 'settings';
    return 'marketplace';
  };

  return (
    <SideNavBar defaultSelected="marketplace">
      <SideNavBarSection position="header">
        <SideNavBarItem.Header value="logo-manaos" title="manaos" iconName="manaos" data-testid="logo-manaos" />
      </SideNavBarSection>
      <SideNavBarSection position="top">
        <SideNavBarItem.Link
          to={getViewsRoute('portfolios-lists').path}
          value="portfolios"
          title="Portfolios"
          iconName="portfolios"
          selected={getCurrentView() === 'portfolios'}
          data-testid={createTestid('myPortfoliosAndList')}
          data-stepid="appSideNavBar-myPortfoliosAndList"
        />
        <SideNavBarItem.Link
          to={getViewsRoute('esg').path}
          value="esg"
          title="ESG solutions"
          iconName="esgsolutions"
          selected={getCurrentView() === 'esg'}
          data-testid={createTestid('esg')}
        />
        <SideNavBarItem.Link
          to={getViewsRoute('uploads').path}
          value="uploads"
          title="My files"
          iconName="uploadfiles"
          selected={getCurrentView() === 'uploads'}
          data-testid={createTestid('uploads')}
          data-stepid="appSideNavBar-uploads"
        />
        <SideNavBarItem.Link
          to={getMarketplaceRoute('marketplace-subscribed').link}
          value="marketplace"
          title="Marketplace"
          iconName="marketplaceesg"
          selected={getCurrentView() === 'marketplace'}
          data-testid={createTestid('allApps')}
        />
      </SideNavBarSection>
      <Stack className={classes.sectionSettings}>
        <SideNavBarSection position="bottom">
          <SideNavBarItem.Link
            to={getSettingsRoute('settings-users').link}
            value="settings"
            title="Settings"
            iconName="settings"
            selected={getCurrentView() === 'settings'}
            data-testid={createTestid('account-settings')}
            data-stepid="appSideNavBar-settingsAccount"
          />
          <SideNavBarItem.CollapseManager data-testid={createTestid('button-collapse')} />
          <Box className={classes.divider}>
            <Divider variant="secondary" />
          </Box>
          <SideNavBarItem.Menu
            value="profile"
            title={organisationName}
            subtitle={currentUser}
            subtitleProps={{ color: colors.primaryColor }}
            iconName="profile"
            iconProps={{ gradient: 'orange' }}
            data-testid={createTestid('profile')}
          >
            <MenuItem.Link to={getAuthRoute('logout').path} data-testid={createTestid('menuItem-logout')}>
              Logout
            </MenuItem.Link>
          </SideNavBarItem.Menu>
        </SideNavBarSection>
      </Stack>
    </SideNavBar>
  );
};
