import { Stack, Tooltip } from '@mui/material';
import { HeaderComponentFramework, Icon, useCallbackImmutable } from 'mns-components';
import { qualities } from 'mns-sdk-collect';
import React from 'react';
import { qualityLabelMapper, qualityTooltipMapper } from '../cellRender/QualitiesRenderer';
import { qualitiesRendererStyles as useStyles } from '../cellRender/qualitiesRendererStyles';

export const QualitiesHeaderRenderer = React.memo(function QualitiesHeaderRenderer({
  'data-testid': testid,
}: {
  'data-testid': string;
}) {
  const classes = useStyles();

  return (
    <HeaderComponentFramework
      render={useCallbackImmutable(({ getLabelNode }) => (
        <Stack direction="row" spacing=".5rem" alignItems="center">
          {getLabelNode('Qualities')}
          <Tooltip
            arrow
            title={
              <>
                {qualities
                  .filter((item) => ['BASIC', 'REGULATORY'].includes(item))
                  .map((quality) => (
                    <p key={quality}>
                      <strong>{qualityLabelMapper[quality]}:</strong> {qualityTooltipMapper[quality]}
                    </p>
                  ))}
              </>
            }
          >
            <Icon.Info size="small" className={classes.questionIcon} data-testid={`${testid}-icon-info`} />
          </Tooltip>
        </Stack>
      ))}
      data-testid={testid}
    />
  );
});
