import { makeOpenModal } from 'mns-components';
import { useQueries } from 'react-query';
import { api } from '../../../store/api';
import { appNotification } from '../../../store/apis';
import { getSettingsRoute } from '../routes';

export const useEmailListsData = () => api.inventories.emailList.getEmailLists.useQuery();
export const useEmailListsDataInvalidate = () => api.inventories.emailList.getEmailLists.useInvalidateQuery();

export const useCreateEmailList = () => {
  return api.inventories.emailList.createEmailList.useMutation({ onSuccess: useEmailListsDataInvalidate() });
};
export const useDeleteEmailList = () => {
  return api.inventories.emailList.deleteEmailList.useMutation({ onSuccess: useEmailListsDataInvalidate() });
};
export const useUpdateEmailList = () => {
  return api.inventories.emailList.updateEmailList.useMutation({ onSuccess: useEmailListsDataInvalidate() });
};

export const useMultipleContactList = (orgIdList: string[]) => {
  const notifCode = 'chase_up';
  return useQueries(
    orgIdList.map((id) => {
      return {
        queryKey: ['appNotification.getContactList', id],
        queryFn: () => appNotification.getContactList(id, notifCode),
      };
    }),
  );
};

export const [useOpenEditEmailList, useManageOpenedEmailList] = makeOpenModal(
  () => getSettingsRoute('settings-organisation').link,
  'openEditEmailListId',
);
