import type { AggridTableColumn, StepProps } from 'mns-components';
import {
  AggridTable,
  Backdrop,
  Button,
  ButtonList,
  EmptyState,
  Frame,
  Icon,
  convertToDateLocal,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useNavigate,
  useStepperButtons,
  useTestid,
} from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CompilingStepStatus } from '../../../components/cellRender/CompilingFileStep';
import { useGenerateEetAnalysis, useGenerateEetFile, useGenerationPortfoliosStatus } from '../hooks';
import { getRoute } from '../routes';
import { useCompilingFileStyles } from './styles/useCompilingFileStyles';
import type { GenerateEetFormSteps } from './types';

const buildColumns: AggridTableColumn<EetApi.PortfolioStatus>[] = [
  {
    field: 'externalId',
    headerName: 'Financial instrument ID',
  },
  {
    field: 'valuationDate',
    headerName: 'Nav Date',
    cellRendererFramework: ({ value }) => convertToDateLocal(value),
    sort: 'desc',
  },
  {
    field: 'sfdrApp',
    headerName: 'SFDR PAI',
    cellRendererFramework: ({ value, data: { externalId } }) => (
      <CompilingStepStatus status={value} data-testid={externalId + '-sfdr-status'} />
    ),
  },
  {
    field: 'taxonomyApp',
    headerName: 'EU Taxonomy',
    cellRendererFramework: ({ value, data: { externalId } }) => (
      <CompilingStepStatus status={value} data-testid={externalId + '-taxonomy-status'} />
    ),
  },
  {
    field: 'shareSustainableInvestmentApp',
    headerName: 'SSI',
    cellRendererFramework: ({ value, data: { externalId } }) => (
      <CompilingStepStatus status={value} data-testid={externalId + '-ssi-status'} />
    ),
  },
];

type CompilingFileListProps = {
  rowData: EetApi.PortfolioStatus[];
  'data-testid': string;
};

const CompilingFileList: React.FC<CompilingFileListProps> = ({ rowData, 'data-testid': testid }) => (
  <AggridTable
    columnDefs={buildColumns}
    defaultColDef={useDefaultColDef<EetApi.PortfolioStatus>()}
    rowData={rowData}
    getRowNodeId={useImmutable(() => (row: EetApi.PortfolioStatus) => row.externalId)}
    data-testid={testid}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
  />
);

type CompilingFileStepProps = StepProps<GenerateEetFormSteps, 4>;

export const CompilingFileStep: React.FC<CompilingFileStepProps> = ({
  stepValues: [, , { portfolios }],
  Buttons,
  setCurrentStep,
  'data-testid': testid,
}) => {
  const classes = useCompilingFileStyles();
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const { eetId } = useParams<{ eetId: string }>();
  const { mutate: retriggerAnalysis, isLoading: isRetriggering } = useGenerateEetAnalysis(eetId);
  const {
    data: portfoliosStatus,
    isRunning: isRunningPortfoliosStatus,
    error: errorPortfoliosStatus,
  } = useGenerationPortfoliosStatus(
    eetId,
    portfolios.map((ptf) => ptf.externalId),
  );

  const {
    mutate: generate,
    isLoading: isGenerating,
    error: errorGenerate,
    isSuccess: isGenerated,
  } = useGenerateEetFile(eetId);

  useEffect(() => {
    if (isGenerated) {
      navigate(getRoute('eet-files').link);
    }
  }, [isGenerated, navigate]);

  const reload = useCallbackImmutable(() => retriggerAnalysis());

  const handlePrevious = useCallbackImmutable(() => setCurrentStep((s) => s - 1));

  const isLoading = isRetriggering || isRunningPortfoliosStatus || isGenerating;

  const buttons = useStepperButtons({
    onNext: generate,
    onPrevious: handlePrevious,
    previousLabel: 'Back',
    nextDisabled: isLoading,
    nextLoading: isGenerating,
    nextLabel: 'Submit',
    nextColor: 'secondary',
    'data-testid': createTestid('button'),
  });

  if (errorPortfoliosStatus) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="warning"
          title="Unable to load portfolios statuses"
          firstParagraph="The request ended with error, please try to load it again or contact administrator"
          data-testid={createTestid('error-load-status')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  if (errorGenerate) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="warning"
          title="Unable to generate portfolio"
          firstParagraph="The request ended with error, please try to load it again or contact administrator"
          data-testid={createTestid('error-generate')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  if (!portfoliosStatus) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!portfolios.length) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="info"
          title="Empty EET"
          firstParagraph="Expected portfolios are not uploaded yet. You can generate an empty EET by submitting."
          data-testid={createTestid('info-empty')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  return (
    <Frame variant="margined" data-testid={testid}>
      <div className={classes.btnContainer}>
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Retry data-testid={createTestid('icon-retry')} />}
          onClick={reload}
          disabled={isLoading}
          loading={isRetriggering}
          data-testid={createTestid('button-relaunch')}
        >
          RE-LAUNCH ANALYSIS
        </Button>
      </div>
      <div className={classes.compilingFileStep}>
        <CompilingFileList rowData={portfoliosStatus} data-testid={createTestid('status-list')} />
      </div>
      <Buttons>
        <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
      </Buttons>
    </Frame>
  );
};
