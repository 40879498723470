import { convertToDateLocal } from 'mns-components';
import { api } from '../../../store/api';

export const useUnderlyingDataProviders = () => {
  const { data, isLoading, isError } = api.provider.portfolio.getUnderlyingDataProvider.useQuery();
  return { data, isLoading, isError };
};

export const useUnderlyingDataForProvider = (providerName: string, startDate: Date, endDate: Date) => {
  const formattedStartDate = convertToDateLocal(startDate);
  const formattedEndDate = convertToDateLocal(endDate);

  const { data, isLoading } = api.provider.portfolio.getUnderlyingDataForProvider.useQuery(
    providerName,
    formattedStartDate,
    formattedEndDate,
    {
      refetchInterval: 5000,
    },
  );

  return { data, isLoading };
};
