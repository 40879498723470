import { IconButton, Tooltip } from '@mui/material';
import type { AggridTableColumn, AggridTableProps } from 'mns-components';
import {
  AggridTable,
  Icon,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { templateStyles as useStyles } from './TemplatesStyles';

const getActionColDef = makeActionColDef<DataExtractorApi.Template.TemplateLight>();

const useColumns = (
  testid: string,
  onDetailsTemplate?: (row: DataExtractorApi.Template.TemplateLight) => void,
): AggridTableColumn<DataExtractorApi.Template.TemplateLight>[] => {
  const createTestid = useTestid(testid);

  return [
    {
      field: 'name',
      headerName: 'Template name',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      flex: 1,
      sort: 'desc',
      sortable: true,
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 1,
    },
    getActionColDef('id', 70, function ActionCol({ data }) {
      return (
        <Tooltip title="details">
          <IconButton
            size="small"
            color="secondary"
            data-testid={createTestid('details-button-' + data.id)}
            onClick={useCallbackImmutable(() => onDetailsTemplate?.(data))}
          >
            <Icon name="details" data-testid={createTestid('details-' + data.id)} />
          </IconButton>
        </Tooltip>
      );
    }),
  ];
};

export type TemplatesListProps = {
  onDetailsTemplate?(row: DataExtractorApi.Template.TemplateLight): void;
  'data-testid': string;
} & Omit<AggridTableProps<DataExtractorApi.Template.TemplateLight>, 'defaultColDef' | 'columnDefs'>;

export const TemplatesList: React.FC<TemplatesListProps> = ({
  onDetailsTemplate,
  'data-testid': testid,
  ...props
}: TemplatesListProps) => {
  const classes = useStyles();
  return (
    <AggridTable
      {...props}
      columnDefs={useColumns(testid, onDetailsTemplate)}
      getRowNodeId={useImmutable(() => (row: DataExtractorApi.Template.TemplateLight) => row.id)}
      data-testid={testid}
      defaultColDef={useDefaultColDef<DataExtractorApi.Template.TemplateLight>()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      className={classes.aggrid}
    />
  );
};
