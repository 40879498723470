import { Button, Dialogv2, Icon } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdateUser } from '../../hooks';
import { UpdateUserForm } from './UserModalInputs';

type EditUserModalProps = {
  user: AccountApi.User | undefined;
  modalOpen?: boolean;
  onCloseModal(): void;
  'data-testid': string;
};

export const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  modalOpen,
  onCloseModal,
  'data-testid': testid,
}) => {
  const {
    mutate: updateUser,
    error: errorUpdateUser,
    isSuccess: isUserUpdated,
    isLoading: isLoadingUser,
  } = useUpdateUser();
  const formName = 'form-user-update';

  useEffect(() => {
    if (isUserUpdated) {
      onCloseModal();
      toast.success('User successfuly updated');
    }
    if (errorUpdateUser) {
      onCloseModal();
      toast.error('Failed to update user');
    }
  }, [isUserUpdated, errorUpdateUser, onCloseModal]);

  return (
    <Dialogv2
      open={!!modalOpen}
      onClose={onCloseModal}
      dialogTitle={`Edit user - ${user?.username}`}
      DialogTitleIcon={Icon.Edit}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoadingUser}
          type="submit"
          form={formName}
          data-testid={`${testid}-confirm`}
        >
          Update user
        </Button>
      }
      data-testid={`${testid}-dialog`}
    >
      <UpdateUserForm userData={user} data-testid={testid} onSave={updateUser} formId={formName} />
    </Dialogv2>
  );
};
