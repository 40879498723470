import { Stack } from '@mui/material';
import { Backdrop, EmptyState, useTestid, useCallbackImmutable, stringCompareDate, EmptyStateV2 } from 'mns-components';
import type { AnalysisApi, CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { downloadFile, downloadJsonAsCsv, formatAnalysisFileName } from '../../../../common/utils';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { api } from '../../../../store/api';
import { CreateRequestModal } from '../../../analysisDemands/modals/createRequest/CreateRequestModal';
import { useAnalysisAggridData, useAnalysisPortfolios } from '../../hooks';
import { StateAppList } from './tableComponents/StateAppList';

const getLastRequest = (
  portfolio: CollectApi.Portfolio,
  date: string,
  list?: AnalysisApi.AnalysisPortfolio<AppCode>[],
) =>
  list
    ?.filter(
      (analysis) =>
        analysis.externalId === portfolio.externalId.value &&
        analysis.valuationDate === date &&
        analysis.status !== 'failed',
    )
    .sort((analysisA, analysisB) => stringCompareDate(analysisB.createdAt, analysisA.createdAt))
    .at(0) ?? null;

type StateAppProps = {
  portfolio: CollectApi.Portfolio;
  reloadDelay?: number;
  'data-testid': string;
};

export const StateApp: React.FC<StateAppProps> = ({ portfolio, reloadDelay = 5000, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [openCreateAnalysis, setOpenCreateAnalysis] = useState(false);
  const { appCode, date } = useParams<{ id: string; date: string; appCode: AppCode }>();
  const {
    company: { providerName },
    config: { activatePortfolioViewPolling, analysisRequestType },
  } = useApplicationConfig(appCode);

  const handleOpenCreateAnalysis = useCallbackImmutable(() => setOpenCreateAnalysis(true));
  const handleCloseCreateAnalysis = useCallbackImmutable(() => setOpenCreateAnalysis(false));

  const { data: lastRequest, isLoading: isLoadingRequest } = useAnalysisPortfolios(appCode, {
    select: (list) => getLastRequest(portfolio, date, list),
    refetchIntervalInBackground: true,
    refetchInterval: (last) => (last?.status !== 'complete' && activatePortfolioViewPolling ? reloadDelay : false),
  });

  const handleDownload = useCallbackImmutable(async () => {
    if (lastRequest?.analysisId) {
      const currentAnalysedFile = await api.provider.download.downloadRequest.raw({
        appCode,
        requestId: lastRequest.analysisId,
      });
      if (!currentAnalysedFile || currentAnalysedFile.length === 0) return;
      await Promise.all(
        currentAnalysedFile.map(async ({ presignedUrl, fileName, metadata }) => {
          const { formattedFileName } = formatAnalysisFileName(fileName, metadata);
          await downloadFile(presignedUrl, formattedFileName, downloadJsonAsCsv);
        }),
      );
    }
  });

  const emptyButtonLabel = useMemo(
    () => (analysisRequestType === 'enrich' ? 'Enrich portfolio' : 'Order Analysis'),
    [analysisRequestType],
  );

  const {
    isLoading: listLoading,
    data: enrichedData,
    error: listError,
  } = useAnalysisAggridData(appCode, lastRequest?.analysisId);

  if (isLoadingRequest) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!lastRequest) {
    return (
      <>
        <Stack height="100%" justifyContent="center" flexGrow="1">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title="Get started with an analyse!"
            subTitle="Start analysing your portfolio."
            buttonText={emptyButtonLabel}
            buttonOnClick={handleOpenCreateAnalysis}
            buttonVariant="secondary"
            data-testid={createTestid('warning-empty')}
          />
        </Stack>

        <CreateRequestModal
          isOpen={openCreateAnalysis}
          onClose={handleCloseCreateAnalysis}
          appCode={appCode}
          data-testid={createTestid('modal-createAnalysis')}
        />
      </>
    );
  }

  if (lastRequest.status === 'failed' || listError) {
    return (
      <Stack height="100%" justifyContent="center" flexGrow={1}>
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error-failed')}
        />
      </Stack>
    );
  }

  if (lastRequest.status === 'pending' || listLoading || (lastRequest.status === 'complete' && !enrichedData)) {
    return (
      <EmptyState
        iconEnable
        iconName="processing"
        firstParagraph="Processing"
        secondParagraph="Your portfolio is currently being enriched. Please wait a few seconds to get your portfolio scores"
        data-testid={createTestid('warning-processing')}
      />
    );
  }

  if (lastRequest.status === 'complete' && enrichedData) {
    return (
      <StateAppList
        key={`${providerName}-${lastRequest.analysisId}`}
        columnDefs={enrichedData.columnDefs}
        rowData={enrichedData.rowData}
        handleDownload={handleDownload}
        data-testid={createTestid('list')}
      />
    );
  }

  return null;
};
