import { Box } from '@mui/material';
import type { FormProps } from 'mns-components';
import {
  Button,
  Typography,
  colors,
  convertToDateTimeLocal,
  makeForm,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCognitoAuthError, useAuthUser, useSendConfirmationCodeToUserEmail } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';
import {
  CodeField,
  ConfirmPasswordField,
  ErrorBox,
  NewPasswordField,
  SuccessRedirectLayout,
  UsernameField,
} from './Forms';
import { useHandleResetPassword } from './hooks';
import { routes } from './routes';
import type { ResetPasswordFormData } from './types';
import { getResetFormErrors } from './utils';

type ForgotPasswordFormData = ResetPasswordFormData;

type ForgotPasswordInputsProps = Omit<FormProps<ForgotPasswordFormData>, 'children'> & { error?: Error };

const ForgotPasswordInputs: React.FC<ForgotPasswordInputsProps> = ({ error, 'data-testid': testid }) => {
  const [isSendingConfirmation, setSendingConfirmation] = useState(false);
  const [lastTryDate, setLastTryDate] = useState<Date | undefined>();
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const sendConfirmationCodeToUserEmail = useSendConfirmationCodeToUserEmail();

  const {
    getValues,
    watch,
    clearErrors,
    formState: {
      isSubmitting,
      errors: { '': defaultError },
    },
  } = useFormContext<ForgotPasswordFormData>();

  const handleSendConfirmationCodeToUserEmail = useCallbackImmutable<React.MouseEventHandler<HTMLButtonElement>>(
    async (ev) => {
      ev.preventDefault();

      setLastTryDate(undefined);
      setSendingConfirmation(true);
      await sendConfirmationCodeToUserEmail(getValues('username'));
      setSendingConfirmation(false);
      setLastTryDate(new Date());

      clearErrors();
    },
  );

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    const msg = defaultError?.message;
    if (msg) {
      setErrorMessage(msg);
    }
  }, [defaultError]);

  watch(() => setErrorMessage(''));

  if (!lastTryDate) {
    return (
      <>
        <UsernameField data-testid={createTestid('username')}></UsernameField>
        <Box margin="0.5rem">
          <Button
            color="secondary"
            onClick={handleSendConfirmationCodeToUserEmail}
            loading={isSendingConfirmation}
            disabled={isSubmitting}
            data-testid={createTestid('button-send-code')}
          >
            Send email for reset password
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <ErrorBox error={errorMessage} data-testid={createTestid('error-verification-code')} />
      <UsernameField data-testid={createTestid('username')} disabled />
      <CodeField data-testid={createTestid('code')} />
      <NewPasswordField data-testid={createTestid('newPassword')} />
      <ConfirmPasswordField data-testid={createTestid('confirmPassword')} />
      <Box margin="0.5rem">
        <Button
          color="secondary"
          type="submit"
          loading={isSubmitting}
          disabled={isSendingConfirmation}
          data-testid={createTestid('submit')}
        >
          Change your password
        </Button>
        <p className={classes.info}>Or</p>
        <Button
          color="primary"
          onClick={handleSendConfirmationCodeToUserEmail}
          loading={isSendingConfirmation}
          disabled={isSubmitting}
          data-testid={createTestid('button-resend-code')}
        >
          Resend email for reset password
        </Button>
      </Box>

      <p className={classes.info}>
        Last confirmation sent at{' '}
        <span data-testid={createTestid('last-code-date')}>{convertToDateTimeLocal(lastTryDate)}</span>
      </p>
    </>
  );
};

const ForgotPasswordForm = makeForm<ForgotPasswordFormData, ForgotPasswordInputsProps>(ForgotPasswordInputs);

const defaultValues: ForgotPasswordFormData = {
  username: '',
  confirmPassword: '',
  newPassword: '',
  code: '',
};

export const ForgotPassword: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [cognitoUser] = useAuthUser();
  const [hasSucceded, handleResetPassword] = useHandleResetPassword(routes.loginUri.path);
  const handleValidation = useCallbackImmutable(getResetFormErrors);

  if (!isCognitoAuthError(cognitoUser) && hasSucceded) {
    return <SuccessRedirectLayout data-testid={testid}></SuccessRedirectLayout>;
  }

  return (
    <AuthPageLayout data-testid={createTestid('authPageLayout')}>
      <Box margin="0.5rem">
        <Typography variant="h1">Forgot password</Typography>
        <Typography variant="body1" margin="1rem 0" color={colors.disabledColor}>
          Enter the username associated with your account and we’ll send an email with instructions to reset password
        </Typography>
      </Box>
      <ForgotPasswordForm
        error={isCognitoAuthError(cognitoUser) ? cognitoUser : undefined}
        defaultValues={defaultValues}
        onSubmit={handleResetPassword}
        onValidate={handleValidation}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
