import type { ToggleButtonGroupItem } from 'mns-components';
import type { AppCode } from '../views/appDescriptions';
import { PastDeliveries } from './pastDeliveries/PastDeliveries';
import { ScheduledDeliveries } from './scheduledDeliveries/ScheduledDeliveries';

const rawRoutes = [
  { label: 'Past Deliveries', view: 'past-deliveries', component: PastDeliveries },
  { label: 'Scheduled Deliveries', view: 'scheduled-deliveries', component: ScheduledDeliveries },
] as const;

type RouterInterface = {
  label: string;
  view: EmailDeliveryView;
  component: React.FC<{ appCode: AppCode; dateFieldName: string; navElement?: React.ReactNode; 'data-testid': string }>;
};
export type EmailDeliveryView = typeof rawRoutes[number]['view'];
export const routes = rawRoutes as readonly RouterInterface[];
export const getEmailDeliveryRoute = (key: EmailDeliveryView): RouterInterface | undefined =>
  routes.find((route) => route.view === key);

export const routesOptions = routes.map(
  ({ view, label }): ToggleButtonGroupItem<EmailDeliveryView> => ({
    value: view,
    children: label,
  }),
);
