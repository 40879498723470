import { isObject, makeGlobalState, objectHas } from 'mns-components';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import type { RouterInterface } from '../../../components/routing/RouterInterface';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useUser } from '../../../hooks/useAuth';
import { useEmailDelivery } from '../../../hooks/useUserSettings';
import { dataDeliveryApi } from '../../../store/apis';
import { routes } from './routes';

const useUploadModalOpenRaw = makeGlobalState<boolean>(false);

export const useUploadModalOpen = (): readonly [() => void, () => void, boolean] => {
  const [isOpenModal, setOpenModal] = useUploadModalOpenRaw();
  const unmutable = useState((): [() => void, () => void, boolean] => [
    () => setOpenModal(true),
    () => setOpenModal(false),
    isOpenModal,
  ])[0];
  unmutable[2] = isOpenModal;
  return unmutable;
};

export const useProductRangeRoutes = (appCode: AppCode) => {
  const { apps: roles } = useUser();
  const emailDeliveryActive = useEmailDelivery(appCode);
  return useMemo(() => {
    const rules = {
      emailDeliveryActive,
    };

    return routes.reduce((acc, rawRoute) => {
      const role = rawRoute.expectRole;
      if (!role) {
        acc.push(rawRoute);
      } else {
        if (objectHas(rules, role)) {
          const value = rules[role];
          if (isObject(value)) {
            acc.push({
              ...rawRoute,
              linkDefaultArgs: value,
            });
          } else if (value) {
            acc.push(rawRoute);
          }
        } else if (roles.includes(role)) {
          acc.push(rawRoute);
        }
      }
      return acc;
    }, [] as RouterInterface[]);
  }, [emailDeliveryActive, roles]);
};

export const useDataDelivery = (deliveryId: string) =>
  useQuery(['dataDeliveryApi.getDelivery'], () => dataDeliveryApi.getDelivery(deliveryId));
