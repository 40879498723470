import type { FormGenerationProps } from 'mns-components';
import { useTestid, FormGeneration, makeForm } from 'mns-components';
import { formStyles as useStyles } from './formStyles';

type OfferOptionsInputsProps = {
  inputList: FormGenerationProps[];
  'data-testid': string;
};

const OfferOptionsInputs: React.FC<OfferOptionsInputsProps> = ({ inputList, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.form} data-testid={testid}>
      <h3 className={classes.formTitle}>
        <span>Choose your options</span>
        <hr />
      </h3>
      {inputList.map((data) => (
        <FormGeneration {...data} key={data.optionId} data-testid={createTestid(data.optionId)} />
      ))}
    </div>
  );
};

export const OfferOptionsForm = makeForm(OfferOptionsInputs);
