import { Dialog, isObject } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import type { JsonSchemaValue } from './ScheduledDeliveryForm';
import type { ScheduledDeliveryRequestProps } from './ScheduledDeliveryRequest';
import { CreateScheduledDeliveryRequest, EditScheduledDeliveryRequest } from './ScheduledDeliveryRequest';

type MakeScheduledDeliveryModal<InputType extends JsonSchemaValue | void> = {
  modalTitle: string;
  requestComponent: React.FC<ScheduledDeliveryRequestProps<InputType>>;
};

type ScheduledDeliveryModalProps<InputType extends JsonSchemaValue | void> = {
  open: InputType extends void ? boolean : DataDeliveryApi.DataDeliveryScheduled | null;
  'data-testid': string;
} & Omit<ScheduledDeliveryRequestProps<InputType>, 'initState'>;

const makeScheduledDeliveryModal = <InputType extends JsonSchemaValue | void>({
  modalTitle,
  requestComponent: RequestComp,
}: MakeScheduledDeliveryModal<InputType>) => {
  const ScheduledDeliveryModal: React.FC<ScheduledDeliveryModalProps<InputType>> = ({
    open,
    'data-testid': testid,
    ...props
  }) => {
    const initState = (
      isObject(open)
        ? ({
            emailListName: open.emailingListName,
            isinListName: open.scope.mode === 'ALL' ? undefined : open.scope.isinListName,
            name: open.name,
            scope: open.scope.mode,
            sharingFrequency: open.sharingFrequency,
            triggerDay: open.triggerDay,
          } as JsonSchemaValue)
        : undefined
    ) as InputType;
    return (
      <Dialog open={!!open} onClose={props.onClose} dialogTitle={modalTitle} data-testid={`${testid}-dialog`}>
        <RequestComp initState={initState} data-testid={testid} {...props} />
      </Dialog>
    );
  };

  return ScheduledDeliveryModal;
};

export const CreateScheduledDeliveryModal = makeScheduledDeliveryModal({
  modalTitle: 'Create Scheduling',
  requestComponent: CreateScheduledDeliveryRequest,
});
export const EditScheduledDeliveryModal = makeScheduledDeliveryModal({
  modalTitle: 'Edit Scheduling',
  requestComponent: EditScheduledDeliveryRequest,
});
