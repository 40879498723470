import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';

interface UploadedByRendererProps {
  data: CollectApi.File;
}

export const UploadedByRenderer: React.FC<UploadedByRendererProps> = ({ data }: UploadedByRendererProps) => {
  switch (true) {
    case data.uploadSource === 'EMAIL' && !!data.senderEmail:
      return <span title={data.senderEmail}>{data.senderEmail}</span>;
    case data.uploadSource === 'EMAIL' && !data.senderEmail:
      return <span>Mail</span>;
    case !!data.reUpload:
      return <span>Manaos</span>;
    case data.uploadSource === 'WEB':
      return <span>{data.uploadedBy}</span>;
    default:
      return null;
  }
};

export const uploadByRendererPlain = ({ data }: UploadedByRendererProps) => {
  switch (true) {
    case data.uploadSource === 'EMAIL' && !!data.senderEmail:
      return data.senderEmail;
    case data.uploadSource === 'EMAIL' && !data.senderEmail:
      return 'Mail';
    case !!data.reUpload:
      return 'Manaos';
    case data.uploadSource === 'WEB':
      return data.uploadedBy;
    default:
      return '';
  }
};
