import { Stack } from '@mui/material';
import {
  useTestid,
  AlertMessage,
  colors,
  convertToDateLocal,
  Typo,
  AppContent,
  EmptyStateV2,
  LoadingCircle,
} from 'mns-components';
import { useEffect, useMemo, useState } from 'react';
import { isFundAllowedToInviteBis } from '../../../components/cellRender/CollectionStatusButtonRenderer';
import { useAllFundPostion } from '../hooks';
import { CollectStatus } from './components/CollectStatus';
import { LatestCollect } from './components/LatestCollect';
import { MyTask } from './components/myTask';
import { useDashboardPortfolioSelected, useDashboardValuationDate } from './hooks';

type DashboardProps = { 'data-testid': string };

export const Dashboard: React.FC<DashboardProps> = ({ 'data-testid': testid }: DashboardProps) => {
  const createTestid = useTestid(testid);
  const [valuationDate] = useDashboardValuationDate();

  const currentMonthLastDate = useMemo(() => {
    // we want to get the last day of the valuationDate month
    return new Date(valuationDate.getFullYear(), valuationDate.getMonth() + 1, 0);
  }, [valuationDate]);

  const nextMonthDayBeforeLastDate = useMemo(() => {
    // We want to day before the last day of the next month of the valuationDate month
    return new Date(valuationDate.getFullYear(), valuationDate.getMonth() + 2, -1);
  }, [valuationDate]);

  // Select Portfolios
  const [portfolio] = useDashboardPortfolioSelected();
  const [portfolioIdList, setPortfolioIdList] = useState<string[]>(portfolio == 'all' ? [] : [portfolio]);

  useEffect(() => {
    setPortfolioIdList(portfolio == 'all' ? [] : [portfolio]);
  }, [portfolio]);

  const { data, isError } = useAllFundPostion(portfolioIdList, convertToDateLocal(currentMonthLastDate));

  const inventoriesInvite = useMemo(() => {
    return data?.fundToCollectDetailsDtos.filter((fund) => isFundAllowedToInviteBis(fund));
  }, [data?.fundToCollectDetailsDtos]);

  if (isError) {
    return (
      <AppContent>
        <Stack margin="auto">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-emptyState')}
          />
        </Stack>
      </AppContent>
    );
  }

  return (
    <>
      {data && !data.fundToCollectDetailsDtos.length && portfolio == 'all' && (
        <AlertMessage variant="warning" marginBottom="2.5rem" data-testid={createTestid('alertMessage')}>
          <Typo variant="body2">
            No portfolio loaded as of{' '}
            <span color={colors.orangeColor}>
              {valuationDate.toLocaleString('en-us', { month: 'long' })} {valuationDate.getFullYear()}
            </span>{' '}
            . Click on <strong>Upload portfolio</strong> to add one.
          </Typo>
        </AlertMessage>
      )}

      <Stack direction="row" flex="1" gap="1.5rem" minHeight={0} data-testid={testid}>
        {!data ? (
          <AppContent data-testid={createTestid('myTask')}>
            <Typo variant="h3">My tasks</Typo>
            <Stack alignItems="center" justifyContent="center" flex="1" width="100%">
              <LoadingCircle size="medium" data-testid={createTestid('emptyState')} />
            </Stack>
          </AppContent>
        ) : (
          <MyTask
            data-testid={createTestid('myTask')}
            allInventories={data.fundToCollectDetailsDtos}
            inventoriesInvite={inventoriesInvite}
          />
        )}

        {/* fixed width for chart */}
        <Stack gap="1.5rem" width="33rem" maxHeight="800px">
          <CollectStatus
            allInventories={data?.fundToCollectDetailsDtos}
            inventoriesNotFound={inventoriesInvite?.length}
            data-testid={createTestid('collectStatus')}
          />
          <LatestCollect
            startDate={currentMonthLastDate}
            endDate={nextMonthDayBeforeLastDate}
            data-testid={createTestid('latestCollect')}
          />
        </Stack>
      </Stack>
    </>
  );
};
