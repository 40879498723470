import {
  fileExtension,
  generateKey,
  isString,
  objectEntries,
  objectKeys,
  objectMap,
  objectValues,
  shadowEquals,
  useCallbackImmutable,
  useMemoList,
  useMemoRecord,
} from 'mns-components';
import { useState, useMemo, useEffect } from 'react';
import type { FileWithPath } from 'react-dropzone';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQueries, useQuery } from 'react-query';
import { zipBuild } from '../store/upload';
import { defaultDownloadTransform, transformBlobAsCsv, transformBlobAsXlsx } from './utils';

/**
 * This hook fetch a file blob from its `url`
 */
export const useFetchBlob = <TSelect = Blob>(
  url: string | null | undefined,
  options: UseQueryOptions<Blob, unknown, TSelect, AnyArray> = {},
) =>
  useQuery({
    ...options,
    queryKey: ['downloadBlob', url],
    queryFn: async () => (await fetch(url!, { method: 'GET' })).blob(),
    enabled: !!url && isString(url),
  });

/**
 * This hook fetch a file text from its `url`
 */
export const useFetchText = <TSelect = string>(
  url: string | null | undefined,
  options: UseQueryOptions<string, unknown, TSelect, AnyArray> = {},
) =>
  useQuery({
    ...options,
    queryKey: ['downloadText', url],
    queryFn: async () => (await fetch(url!, { method: 'GET' })).text(),
    enabled: !!url && isString(url),
  });

/**
 * This hook fetch JSON from a file `url`
 */
export const useFetchJson = <TSelect>(
  url: string | null | undefined,
  options: UseQueryOptions<unknown, unknown, TSelect, AnyArray> = {},
) =>
  useQuery({
    ...options,
    queryKey: ['downloadJson', url],
    queryFn: async () => (await fetch(url!, { method: 'GET' })).json(),
    enabled: !!url && isString(url),
  });

type GetQueryOrdered<Args extends AnyArray, TData> = { request: Args; response: TData };

/**
 * Give back a list of a blob matching list of targets `urls`.
 * Please note a falsy target returns a useQuery not enabled.
 */
const useQueriesFileBlob = <TSelect = GetQueryOrdered<[url: string], Blob>>(
  urls: (string | null | undefined)[],
  options: UseQueryOptions<GetQueryOrdered<[url: string], Blob>, unknown, TSelect, AnyArray> = {},
) =>
  useQueries(
    urls.map((url) => ({
      ...options,
      queryKey: ['download', url],
      queryFn: async (): Promise<GetQueryOrdered<[url: string], Blob>> => ({
        request: [url!],
        response: await (await fetch(url!, { method: 'GET' })).blob(),
      }),
      enabled: !!url && isString(url),
    })),
  );

const fileFormats = ['csv', 'xlsx'] as const;
type FileFormat = typeof fileFormats[number];
const isFileFormatAllowed = (format: string | undefined): format is FileFormat =>
  fileFormats.includes(format as FileFormat);

type DownloadFormat<Args extends AnyArray> = {
  fileName?: string;
  fileFormat?: FileFormat;
  args?: Args;
  done: boolean;
};

type DownloadFile = {
  blob: Blob;
  fileName: string;
  fileFormat: FileFormat | undefined;
};

type DownloadBatch<Args extends AnyArray> = {
  fileName: string;
  fileFormat?: FileFormat;
  map: AnyObject<string, Args>;
  done: boolean;
};

/**
 * Transform `query` in expected format.
 * @param downloadedFiles
 * @param batch
 */
const transformFormat = async (query: DownloadFile, fileFormat: FileFormat) => {
  if (fileFormat === 'xlsx') {
    return transformBlobAsXlsx(query.fileName, query.blob, query.fileFormat);
  }
  return transformBlobAsCsv(query.fileName, query.blob, query.fileFormat);
};

/**
 * Download `query` transformed in expected format.
 * @param downloadedFiles
 * @param batch
 */
const downloadFormat = async (
  query: DownloadFile,
  { fileName: rawFileName, fileFormat = 'csv' }: DownloadFormat<AnyArray>,
) => {
  const dico = await transformFormat(query, fileFormat);
  const tempFileName = rawFileName ?? query.fileName;
  const fileName = fileExtension(tempFileName) !== fileFormat ? `${tempFileName}.${fileFormat}` : tempFileName;

  return Promise.all(
    objectEntries(dico).map(async ([fileKey, blob]) => {
      const file = new File([blob], fileKey);
      await defaultDownloadTransform(fileName, file);
    }),
  );
};

/**
 * Every files in `downloadedFiles` found from `batch` are downloaded in a single ZIP file.
 * @param downloadedFiles
 * @param batch
 */
const downloadZip = async (
  downloadedFiles: AnyObject<string, DownloadFile[]>,
  { fileName, fileFormat = 'csv', map }: DownloadBatch<AnyArray>,
) => {
  const files: Record<string, FileWithPath> = {};

  await Promise.all(
    objectKeys(map).map(async (id) => {
      const queryList = downloadedFiles[id];
      for (const query of queryList) {
        const dico = await transformFormat(query, fileFormat);
        for (const fileKey in dico) {
          files[fileKey] = new File([dico[fileKey]], fileKey);
        }
      }
    }),
  );

  const zipFile = await zipBuild(files);
  await defaultDownloadTransform(fileName, zipFile);
};

/**
 * This hook watchs queries from an `import('react-query').useQueries` and returns their data.
 * It reduces re-render risk.
 */
const useQueriesData = <TData>(queries: UseQueryResult<TData, unknown>[]) =>
  useMemoList<TData | undefined>(queries.map(({ data }) => data));

/**
 * This hook watch queries from an `import('react-query').useQueries`,
 * transform its data rows into a signle value, and returns their status.
 */
export const useQueriesMemo = <TData, TSelect = TData[]>(
  queries: UseQueryResult<TData, unknown>[],
  cb?: (data: (TData | undefined)[]) => TSelect,
) => {
  const results = useQueriesData(queries);

  const error = queries.find((q) => q.error)?.error;
  const isLoading = !error && queries.some((q) => q.isLoading);
  const isSuccess = !error && !queries.some((q) => !q.isSuccess);

  return useMemo(
    () => ({ data: (cb ? cb(results) : results) as TSelect, isLoading, isSuccess, isError: !!error, error }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, isLoading, isSuccess, error],
  );
};

export type UseDownloadQueryResult = { fileName: string; fileFormat?: string; url: string };

export type UseQueriesPresignedUrls<Args extends AnyArray> = (
  args: Args[],
) => UseQueryResult<UseDownloadQueryResult[], unknown>[];

/**
 * Download files when available for each identifier added by the returned callback.
 * The download system accepts only CSV and XLSX files format.
 * @param useQueriesPresignedUrls is an `import('react-query').useQueries` hook.
 * @returns a callback for adding an identifier in order to download matching file.
 * @warn success, count, errors are not managed
 */
const useFetchFiles = <Args extends AnyArray>(useQueriesPresignedUrls: UseQueriesPresignedUrls<Args>) => {
  const [downloadMap, setDownloadMap] = useState<AnyObject<string, Args>>({});
  const downloadIds = objectKeys(downloadMap);
  const downloadList = objectValues(downloadMap);
  const queries = useQueriesData(useQueriesPresignedUrls(downloadList));
  const blobs = useQueriesData(useQueriesFileBlob(queries.map((data) => data?.map((row) => row.url)).flat()));

  const downloadedFiles = useMemoRecord(
    downloadIds.reduce((acc, id, index) => {
      const queryList = queries[index];
      if (queryList) {
        const files: DownloadFile[] = [];
        for (const query of queryList) {
          const blob = blobs.find((item) => item?.request[0] === query.url)?.response;
          if (blob) {
            files.push({
              blob,
              fileName: query.fileName,
              fileFormat: isFileFormatAllowed(query.fileFormat) ? query.fileFormat : undefined,
            });
          }
        }

        if (files.length === queryList.length) {
          acc[id] = files;
        }
      }
      return acc;
    }, {} as AnyObject<string, DownloadFile[]>),
    (a, b) => {
      if (!a || !b) return false;
      if (a.length !== b.length) return false;
      if (!a.some((item, index) => !shadowEquals(item, b[index]))) return false;
      return true;
    },
  );

  const addDownloadFiles = useCallbackImmutable((map: AnyObject<string, Args>) => {
    setDownloadMap((current) => {
      if (objectKeys(map).some((id) => !current[id])) {
        return { ...current, ...map };
      }
      return current;
    });
  });

  return [downloadedFiles, addDownloadFiles] as const;
};

/**
 * Download file when available for identifier added by the returned callback.
 * The download system accepts only CSV and XLSX files format.
 * @param useQueriesPresignedUrls is an `import('react-query').useQueries` hook.
 * @returns a list with:
 * * a callback for adding a download,
 * * list of downloads not ended.
 * @warn errors are not managed
 */
export const useDownloadFile = <Args extends AnyArray = [string]>(
  useQueriesPresignedUrls: UseQueriesPresignedUrls<Args>,
) => {
  const [batches, setBatches] = useState<AnyObject<string, DownloadFormat<Args>>>({});
  const [downloadedFiles, addDownloadFiles] = useFetchFiles(useQueriesPresignedUrls);

  useEffect(() => {
    const readyBatches: AnyObject<string, DownloadFormat<Args>> = {};
    const holdOnBatches: AnyObject<string, DownloadFormat<Args>> = {};

    for (const batchId in batches) {
      const batch = batches[batchId];
      const queryList = downloadedFiles[batchId];
      if (!batch.done && queryList) {
        for (const query of queryList) {
          downloadFormat(query, batch);
        }
        readyBatches[batchId] = batch;
      } else {
        holdOnBatches[batchId] = batch;
      }
    }

    if (objectKeys(holdOnBatches).length !== objectKeys(batches).length) {
      setBatches({ ...holdOnBatches, ...objectMap(readyBatches, (batch) => ({ ...batch, done: true })) });
    }
  }, [batches, downloadedFiles]);

  const addDownloadFile = useCallbackImmutable((args: Args, forceFileName?: string, fileFormat?: FileFormat) => {
    const batchId = generateKey(objectKeys(batches));
    setBatches((current) => ({ ...current, [batchId]: { fileName: forceFileName, fileFormat, args, done: false } }));
    addDownloadFiles({ [batchId]: args });
    return batchId;
  });

  return [addDownloadFile, batches] as const;
};

/**
 * Download files when available for each identifier added by the returned callback.
 * The download system accepts only CSV and XLSX files format.
 * @param useQueriesPresignedUrls is an `import('react-query').useQueries` hook.
 * @returns a list with:
 * * a callback for adding a batch of downloads,
 * * list of batches not ended.
 * @warn errors are not managed
 */
export const useDownloadZip = <Args extends AnyArray = [string]>(
  useQueriesPresignedUrls: UseQueriesPresignedUrls<Args>,
) => {
  const [batches, setBatches] = useState<AnyObject<string, DownloadBatch<Args>>>({});
  const [downloadedFiles, addDownloadFiles] = useFetchFiles(useQueriesPresignedUrls);

  useEffect(() => {
    const readyBatches: AnyObject<string, DownloadBatch<Args>> = {};
    const holdOnBatches: AnyObject<string, DownloadBatch<Args>> = {};

    for (const batchId in batches) {
      const batch = batches[batchId];
      if (batch.done || objectKeys(batch.map).some((id) => !downloadedFiles[id])) {
        holdOnBatches[batchId] = batch;
      } else {
        readyBatches[batchId] = batch;
      }
    }

    if (objectKeys(readyBatches).length) {
      setBatches({ ...holdOnBatches, ...objectMap(readyBatches, (batch) => ({ ...batch, done: true })) });
      for (const batch of objectValues(readyBatches)) {
        downloadZip(downloadedFiles, batch);
      }
    }
  }, [batches, downloadedFiles]);

  const addDownloadBatch = useCallbackImmutable((argsList: Args[], fileName: string, fileFormat?: FileFormat) => {
    const batchId = generateKey(objectKeys(batches));
    const map = argsList.reduce((acc, args) => {
      acc[generateKey(objectKeys(acc))] = args;
      return acc;
    }, {} as AnyObject<string, Args>);
    setBatches((current) => ({ ...current, [batchId]: { fileName, fileFormat, map, done: false } }));
    addDownloadFiles(map);
    return batchId;
  });

  return useMemo(
    () =>
      [
        addDownloadBatch,
        objectMap(batches, (batch): DownloadBatch<Args> & { readyCount: number } => ({
          ...batch,
          readyCount: objectKeys(batch.map).reduce((acc, id) => (downloadedFiles[id] ? acc + 1 : acc), 0),
        })),
      ] as const,
    [addDownloadBatch, batches, downloadedFiles],
  );
};
