import { convertToDateUTC, Dialog, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import { isAxiosError, type CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  isFundAllowedToRequestAccess,
  isFundAllowedToChaseUp,
  isFundAllowedToInvite,
  isFundAllowedToRequestQuality,
} from '../../../../components/cellRender/CollectionStatusButtonRenderer';
import type { EmitterPermissionFormData } from '../../../../components/forms/types';
import { appCollectApi } from '../../../../store/apis';
import { ChaseUpAccessPermission } from './ChaseUpAccessPermission';
import { InviteAccessPermission } from './InviteAccessPermission';
import { RequestAccessDetailsPermission } from './RequestAccessDetailsPermission';

const errorCodes: Record<number, string> = {
  409: 'A pending request already exists on this instrument. Please cancel it from the Access requests view before sending a new request.',
};

const getErrorMessage = (code: number): string => {
  if (errorCodes[code]) {
    return errorCodes[code];
  }

  return 'An unexpected error happened.';
};

interface Props {
  selectedItems: CollectApi.FundPositionToCollect[];
  onClose: () => void;
  'data-testid': string;
}

export const ManageInventoriesPermissions: React.FC<Props> = ({
  selectedItems,
  onClose,
  'data-testid': testid,
}: Props) => {
  const createTestid = useTestid(testid);
  const [selectedShareclass, setSelectedShareclass] = useState<string[]>([]);
  useMemo(() => setSelectedShareclass(selectedItems.map((item) => item.fundPosition.id)), [selectedItems]);

  const handleNewRequestAccess = useCallbackImmutable(
    async (formData: EmitterPermissionFormData, items: CollectApi.FundPositionToCollect[]) => {
      // Merge data with formdata
      const formattedData = items.map(
        (item): CollectApi.AccessDemandRequest => ({
          // item Data
          portfolioId: item.disseminatorPortfolio!.portfolioId,
          fundName: item.fundPosition.name,
          externalId: item.fundPosition.externalId,
          externalIdType: item.fundPosition.externalIdType,
          // Form Data
          acceptedDelayDayCount: parseInt(formData.acceptedDelayDayCount, 10),
          expectedAsOf: convertToDateUTC(formData.expectedAsOf),
          lookThroughLevel: parseInt(
            formData.lookThroughLevel,
            10,
          ) as CollectApi.AccessDemandRequest['lookThroughLevel'],
          sharingFrequency: formData.sharingFrequency,
        }),
      );

      const nbItemPerBatch = 25;
      const batches = [];

      for (let i = 0; i < formattedData.length; i += nbItemPerBatch) {
        const batch = formattedData.slice(i, i + nbItemPerBatch);
        batches.push(batch);
      }

      try {
        await Promise.all(batches.map((batch) => appCollectApi.saveMultipleDataAuthorizationAccess(batch)));
      } catch (error) {
        if (isAxiosError(error) && error?.response?.status) {
          toast(getErrorMessage(error.response.status), { type: 'error' });
        }
      }

      onClose();
    },
  );

  const handleInviteOwner = useCallbackImmutable(async (emails: string[]) => {
    const formatedData: CollectApi.InvitationMailRequest = {
      fundsIds: selectedShareclass,
      destination: emails,
    };
    await appCollectApi.sendInvitationByMail(formatedData);
    onClose();
  });

  const handleChaseUpAccessRequest = useCallbackImmutable(async (list: CollectApi.ChaseUpMailRequest[]) => {
    await appCollectApi.sendChaseUpByMail(list);
    onClose();
  });

  switch (true as boolean) {
    case !selectedItems?.[0]:
      return null;
    case isFundAllowedToRequestAccess(selectedItems[0]):
      return (
        <Dialog dialogTitle="Request access" open onClose={onClose} data-testid={createTestid('dialog-request')}>
          <RequestAccessDetailsPermission
            savePermissionsFormHandler={handleNewRequestAccess}
            closeModal={onClose}
            selectedItems={selectedItems}
            data-testid={createTestid('modal-request')}
          />
        </Dialog>
      );
    case isFundAllowedToInvite(selectedItems[0]):
      return (
        <Dialog
          dialogTitle="Invite Access"
          StartIcon={Icon.Invite}
          open
          onClose={onClose}
          data-testid={createTestid('dialog-invite')}
        >
          <InviteAccessPermission
            saveInvitationAccess={handleInviteOwner}
            closeModal={onClose}
            selectedItems={selectedItems}
            selectedShareclass={selectedShareclass}
            setSelectedShareclass={setSelectedShareclass}
            data-testid={createTestid('modal-invite')}
          />
        </Dialog>
      );
    case isFundAllowedToChaseUp(selectedItems[0]):
      return (
        <Dialog
          dialogTitle="Collect chase up"
          open
          onClose={onClose}
          data-testid={createTestid('dialog-chaseUp-upload')}
        >
          <ChaseUpAccessPermission
            saveChaseUpInvitationAccess={handleChaseUpAccessRequest}
            closeModal={onClose}
            selectedItems={selectedItems}
            data-testid={createTestid('modal-chaseUp-upload')}
            chaseUpKind={'UPLOAD'}
          />
        </Dialog>
      );
    case isFundAllowedToRequestQuality(selectedItems[0]):
      return (
        <Dialog
          dialogTitle="Collect chase up"
          open
          onClose={onClose}
          data-testid={createTestid('dialog-chaseUp-qualities')}
        >
          <ChaseUpAccessPermission
            saveChaseUpInvitationAccess={handleChaseUpAccessRequest}
            closeModal={onClose}
            selectedItems={selectedItems}
            data-testid={createTestid('modal-chaseUp-qualities')}
            chaseUpKind={'QUALITIES'}
          />
        </Dialog>
      );
    default:
      return null;
  }
};
