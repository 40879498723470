import { convertToDateLocal, useTestid } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { now } from '../../../../common/date';
import { formStyles as useStyles } from './formStyles';

type OrderDetailsProps = {
  applicationDetails: MarketplaceApi.Application;
  offer?: MarketplaceApi.ApplicationPricing;
  'data-testid': string;
};

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  applicationDetails,
  offer: selectedOffer,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const offer = selectedOffer ?? applicationDetails.subscriptionsDto?.offerDto;

  const estimation = useMemo(() => {
    if (offer?.sla) {
      if (offer.sla === 1) return '1 day';
      return `${offer.sla} days`;
    }
    return null;
  }, [offer?.sla]);

  return (
    <div className={classes.form} data-testid={testid}>
      <h3 className={classes.formTitle}>
        <span>Order details</span>
        <hr />
      </h3>
      <label>
        <span>Order date:</span>
        <span data-testid={createTestid('orderDate')}>{convertToDateLocal(now)}</span>
      </label>
      {estimation && (
        <label>
          <span>Estimated delivery delay:</span>
          <span data-testid={createTestid('estimation')}>{estimation}</span>
        </label>
      )}
    </div>
  );
};
