import { makeStyles } from 'mns-components';

export const modalStyles = makeStyles({
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem',
    borderTop: `1px solid rgba(0, 0, 0, .23)`,
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  scroll: {
    overflow: 'clip auto',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    flex: '1 1 auto',
    padding: '0 1rem',

    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
  inputWidth: {
    display: 'block',
    width: 'auto',
  },
  iconSize: {
    fontSize: '6rem',
    margin: 'auto',
    opacity: 0.5,
  },
  fundList: {
    height: '50vh',
    minHeight: '20rem',
    overflow: 'clip auto',
  },
  addForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiButton-textSizeMedium': {
      marginLeft: '2rem',
    },

    '& .MuiOutlinedInput-root span': {
      maxWidth: '12rem',
    },
  },
  addFormInput: {
    flex: '1 1 auto',
  },
  labelPad: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    '& > *': {
      margin: '0 .5rem',
    },
  },
  downloadBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '2.5rem',
    '& > button': {
      margin: '.5rem 0',
    },
  },
  controllerDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',

    '& .MuiFormControl-root': {
      margin: '0',
      minWidth: '20rem',
    },
  },
});
