import { makeStyles } from 'mns-components';

export const stateAppListStyle = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    '& > *': {
      marginLeft: '0 !important',
    },
  },
  aggrid: {
    flex: '1 1 0',
    minHeight: 0,
  },
});
