import { Stack, Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  appendUTCToken,
  convertToDateLocal,
  makeActionColDef,
  Typography,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { AuthAccessStatus } from '../../../components/cellRender/AuthAccessStatus';
import { CollectionStatusButtonRenderer } from '../../../components/cellRender/CollectionStatusButtonRenderer';
import {
  CollectStatusRenderer,
  collectStatusRendererPlain,
} from '../../../components/cellRender/CollectStatusRenderer';
import { QualitiesRenderer } from '../../../components/cellRender/QualitiesRenderer';
import { ShareclassTooltip, shareclassPlain } from '../../../components/cellRender/ShareclassTooltip';
import { QualitiesHeaderRenderer } from '../../../components/headerRender/QualitiesHeaderRenderer';
import { DownloadShareclassRenderer } from './DownloadShareclassRenderer';
import { HeaderRenderer } from './HeaderRenderer';
import { InventoriesListStyles as useStyles } from './styles/InventoriesList.styles';

const getActionColDef = makeActionColDef<CollectApi.FundPositionToCollect>();

const useColumnDefs = ({
  colSelect,
  onDisplayItem,
  onDisplayHistory,
  onDisplayDetails,
  onDownload,
  'data-testid': testid,
}: { colSelect: AggridTableColumn<CollectApi.FundPositionToCollect> } & Pick<
  CollectInventoriesListProps,
  'onDisplayItem' | 'onDisplayHistory' | 'onDisplayDetails' | 'onDownload' | 'data-testid'
>) => {
  const orgId = localStorage.getItem('organization-id');
  const classes = useStyles();

  return useMemo(
    (): AggridTableColumn<CollectApi.FundPositionToCollect>[] => [
      colSelect,
      getActionColDef('accessRequest', 50, (props) => (
        <AuthAccessStatus
          {...props}
          data-testid={`${testid}-${props.data.fundPosition.externalId}-authorisationStatus`}
        />
      )),
      {
        field: 'fundPosition',
        headerName: 'Shareclass ID',
        minWidth: 140,
        maxWidth: 140,
        cellRendererFramework: ({ data }) =>
          ShareclassTooltip({
            shareclassId: data.fundPosition.externalId,
            fundId:
              data.fundPosition?.externalId !== data.disseminatorPortfolio?.externalId
                ? data.disseminatorPortfolio?.externalId
                : undefined,
            'data-testid': `${testid}-${data.fundPosition.externalId}-externalId`,
          }),
        valueGetter: ({ data }) =>
          shareclassPlain({
            shareclassId: data.fundPosition.externalId,
            fundId:
              data.fundPosition?.externalId !== data.disseminatorPortfolio?.externalId
                ? data.disseminatorPortfolio?.externalId
                : undefined,
          }),
      },
      {
        field: 'fundPosition.name',
        headerName: 'Shareclass name',
        tooltipField: 'fundName',
        minWidth: 80,
        sort: 'asc',
      },
      {
        field: 'fundPosition.issuerName',
        headerName: 'Asset manager',
        tooltipField: 'issuerName',
        minWidth: 80,
      },
      {
        field: 'disseminatorPortfolio',
        minWidth: 150,
        maxWidth: 150,
        headerComponentFramework: () => (
          <HeaderRenderer
            tooltip="Corresponds to the latest net asset valuation date already collected with the quality regulatory."
            data-testid={`${testid}-lastRegulatoryNavDate`}
          >
            Last regulatory
          </HeaderRenderer>
        ),
        cellRendererFramework: ({ value }) => convertToDateLocal(value?.lastRegulatoryNavDate ?? ''),
      },
      {
        field: 'disseminatorPortfolio',
        headerName: 'Current version received on',
        minWidth: 100,
        valueGetter: ({ data }) =>
          convertToDateLocal(
            appendUTCToken(data.disseminatorPortfolio?.updateDate ?? data.disseminatorPortfolio?.receptionDate ?? ''),
          ),
        cellRendererFramework: ({ data }) =>
          convertToDateLocal(
            appendUTCToken(data.disseminatorPortfolio?.updateDate ?? data.disseminatorPortfolio?.receptionDate ?? ''),
          ),
      },
      {
        field: 'disseminatorPortfolio',
        headerName: 'Qualities',
        minWidth: 110,
        cellRendererFramework: ({ value: disseminatorPortfolio, data }) =>
          QualitiesRenderer({
            qualities: disseminatorPortfolio?.ingestionInfos?.qualities ?? [],
            'data-testid': `${testid}-${data.fundPosition.externalId}-qualities`,
          }),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
      },
      {
        field: 'disseminatorPortfolio',
        headerName: 'Nb. chase up',
        maxWidth: 130,
        valueGetter: ({ data }) => {
          return data.disseminatorPortfolio?.chaseUpCount ?? 0;
        },
        cellRendererFramework: ({ data }) =>
          data.disseminatorPortfolio?.lastChaseUpDate ? (
            <Tooltip title={`Last chase up on ${data.disseminatorPortfolio?.lastChaseUpDate}`} placement="top">
              <Typography variant={'body2'}>{data.disseminatorPortfolio?.chaseUpCount ?? 0}</Typography>
            </Tooltip>
          ) : (
            <Typography variant={'body2'}>{data.disseminatorPortfolio?.chaseUpCount ?? 0}</Typography>
          ),
      },
      {
        field: 'disseminatorPortfolio',
        headerName: 'Status',
        minWidth: 100,
        maxWidth: 130,
        valueGetter: ({ data }) =>
          collectStatusRendererPlain({
            receptionDate: data.receptionDate,
            disseminationStatus: data.disseminatorPortfolio?.disseminationStatus,
          }),
        cellRendererFramework: ({ data }) => (
          <CollectStatusRenderer
            receptionDate={data?.receptionDate}
            disseminationStatus={data?.disseminatorPortfolio?.disseminationStatus}
            data-testid={`${testid}-${data.fundPosition.externalId}-status`}
          />
        ),
      },
      getActionColDef(
        'accessRequest',
        230,
        function ActionCol({ data }) {
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
              <CollectionStatusButtonRenderer
                data={data}
                setSelected={onDisplayItem}
                orgId={orgId}
                data-testid={`${testid}-${data.fundPosition.externalId}`}
              />
              <DownloadShareclassRenderer
                processId={data.disseminatorPortfolio?.ingestionInfos?.processId}
                name={data.fundPosition.externalId}
                sharedScope={data?.accessRequest?.scopeToShare}
                onDisplayHistory={useCallbackImmutable(() => onDisplayHistory(data))}
                onDisplayDetails={useCallbackImmutable(() => onDisplayDetails(data))}
                onDownload={useCallbackImmutable((_, dlType: CollectApi.ShareclassFileType) =>
                  onDownload(data, dlType),
                )}
                data-testid={`${testid}-${data.fundPosition.externalId}-download`}
              />
            </Stack>
          );
        },
        { cellClass: classes.fullWidth },
      ),
    ],
    [colSelect, onDisplayItem, orgId, testid, onDisplayHistory, onDisplayDetails, onDownload, classes],
  );
};

interface CollectInventoriesListProps {
  rowData: CollectApi.FundPositionToCollect[];
  searchValue: string;
  onDisplayItem(item: CollectApi.FundPositionToCollect): void;
  onDisplayHistory(item: CollectApi.FundPositionToCollect): void;
  onDisplayDetails(item: CollectApi.FundPositionToCollect): void;
  onDownload(data: CollectApi.FundPositionToCollect, dlType: CollectApi.ShareclassFileType): void;
  onRowsSelected(rows: CollectApi.FundPositionToCollect[]): void;
  getGridApiRef?(api: GridApi | null): void;
  'data-testid': string;
}

export const CollectInventoriesList: React.FC<CollectInventoriesListProps> = ({
  rowData,
  searchValue,
  onDisplayItem,
  onDisplayHistory,
  onDisplayDetails,
  onDownload,
  onRowsSelected,
  getGridApiRef,
  'data-testid': testid,
}: CollectInventoriesListProps) => {
  const classes = useStyles();
  const orgId = localStorage.getItem('organization-id') ?? '';

  const isRowWhite = useCallbackImmutable(
    (authorisationStatus?: CollectApi.AccessRequest['status'], organisationOwner?: string) => {
      return authorisationStatus === 'ACCEPTED' || organisationOwner === orgId;
    },
  );

  const [gridProps, columnProps] = useRowSelection<CollectApi.FundPositionToCollect>(
    ({ api }) => onRowsSelected(api.getSelectedRows()),
    true,
  );

  return (
    <AggridTable
      quickFilterText={searchValue}
      getRowNodeId={useImmutable(() => (row: CollectApi.FundPositionToCollect) => row.fundPosition.externalId)}
      rowData={rowData}
      defaultColDef={useDefaultColDef<CollectApi.FundPositionToCollect>()}
      columnDefs={useColumnDefs({
        colSelect: columnProps,
        onDisplayItem,
        onDisplayHistory,
        onDisplayDetails,
        onDownload,
        'data-testid': testid,
      })}
      {...gridProps}
      getGridApiRef={getGridApiRef}
      rowClassRules={{
        [classes.rowBackWhite]: ({ data }: { data: CollectApi.FundPositionToCollect }) =>
          isRowWhite(data.accessRequest?.status, data.disseminatorPortfolio?.organisationId),
        [classes.rowBackGrey]: ({ data }: { data: CollectApi.FundPositionToCollect }) =>
          !isRowWhite(data.accessRequest?.status, data.disseminatorPortfolio?.organisationId),
      }}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      suppressCsvExport
      suppressExcelExport
      data-testid={testid}
    />
  );
};
