import { Dialog } from 'mns-components';
import type { IsinListApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useEditIsinListRequest } from '../hooks';
import { UpdateIsinListForm } from './IsinListInputs';
import { modalStyles as useStyles } from './modalStyles';

type EditIsinListModalProps = {
  isInListsData: IsinListApi.List[] | undefined;
  selectedEditId: string | undefined;
  onClose(): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

export const EditIsinListModal: React.FC<EditIsinListModalProps> = ({
  isInListsData,
  selectedEditId,
  onClose,
  variant,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const selectedEdit = (selectedEditId && isInListsData?.find((isinList) => isinList.id === selectedEditId)) || null;

  const { mutate: editList, isSuccess: isListEdited, isError } = useEditIsinListRequest();

  useEffect(() => {
    if (isListEdited) {
      onClose();
      toast.success(`List successfuly modified`);
    }
    if (isError) {
      onClose();
      toast.error(`Failed to modify list`);
    }
  }, [isListEdited, isError, onClose]);

  return (
    <Dialog
      open={!!selectedEdit}
      onClose={onClose}
      dialogTitle={variant === 'ISIN' ? 'Edit portfolio list' : 'Edit portfolio scope'}
      data-testid={`${testid}-dialog`}
    >
      {selectedEdit && (
        <UpdateIsinListForm
          submitLabel="Edit"
          isinList={selectedEdit}
          onClose={onClose}
          onSave={editList}
          className={classes.fullHeight}
          data-testid={testid}
        />
      )}
    </Dialog>
  );
};
