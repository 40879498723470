import type { GridApi } from 'ag-grid-community';
import { Backdrop, EmptyState, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useGetPreferences, useUpdatePreferences } from '../../../api/extractionsData';
import { ExtractorPreferencesController } from './ExtractorPreferencesController';
import { ExtractorPreferencesList } from './ExtractorPreferencesList';

export type ExtractorPreferencesProps = {
  'data-testid': string;
};

export const ExtractorPreferences: React.FC<ExtractorPreferencesProps> = ({
  'data-testid': testid = 'extractor-preferences',
}) => {
  const createTestid = useTestid(testid);
  const [search, setSearch] = useState('');
  const [buttonsDisabled, disableButtons] = useState(false);
  const gridApi = useRef<GridApi | null>(null);
  const selectedRows = useRef<DataExtractorApi.Preference.Preference[]>([]);

  const { data: preferences, status: preferencesStatus } = useGetPreferences();
  const { mutateAsync: updatePreferences } = useUpdatePreferences();

  const handleToggleAutomatedRequestStatus = useCallbackImmutable(async (appCode: string) => {
    disableButtons(true);
    const status = preferences?.find(({ applicationCode }) => appCode === applicationCode)?.automatedRequestStatus;

    toast.info(`The automated request ${status ? 'deactivation' : 'activation'} is being proccessed`);

    try {
      await updatePreferences(appCode, !status);
      toast.success(`The automated request ${status ? 'deactivation' : 'activation'} succeed`);
    } catch {
      toast.error("The automated request couldn't be activated");
    }
    disableButtons(false);
  });

  const handleExportAll = useCallbackImmutable(() => gridApi.current?.exportDataAsExcel());

  if (preferencesStatus === 'error') {
    return (
      <EmptyState
        iconEnable
        iconName="info"
        title="No application available yet"
        firstParagraph="Please activate an application before editing preferences on it"
        data-testid={createTestid('failed')}
      />
    );
  }

  if (preferencesStatus !== 'success' || !preferences) {
    return <Backdrop data-testid={createTestid('error')} />;
  }

  return (
    <>
      <ExtractorPreferencesController
        onExportAll={handleExportAll}
        searchValue={search}
        setSearch={setSearch}
        data-testid={createTestid('controller')}
      />

      <ExtractorPreferencesList
        searchValue={search}
        rows={preferences}
        onToggleAutomatedRequestStatus={handleToggleAutomatedRequestStatus}
        rowsSelectedRef={selectedRows}
        getGridApiRef={gridApi}
        buttonsDisabled={buttonsDisabled}
        data-testid={createTestid('list')}
      />
    </>
  );
};
