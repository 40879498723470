import { useCallbackImmutable, useNavigate } from 'mns-components';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { isCognitoAuthError, useAuthUser, useHandleChangePassword, useHandleForgotPassword } from '../../hooks/useAuth';

export const useRedirectOnSuccess = (redirectUri: string): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [cognitoUser] = useAuthUser();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!isCognitoAuthError(cognitoUser) && success) {
      const id = setTimeout(() => navigate(redirectUri), 5000);
      return () => clearTimeout(id);
    }
    return undefined;
  }, [cognitoUser, success, navigate, redirectUri]);

  return [success, setSuccess];
};

type HandleResetPasswordArgs = { newPassword: string; code?: string; username?: string };

export const useHandleResetPassword = (redirectUri: string): [boolean, (args: HandleResetPasswordArgs) => void] => {
  const [hasSucceded, setSucceded] = useRedirectOnSuccess(redirectUri);
  const forgotPassword = useHandleForgotPassword();
  const changePassword = useHandleChangePassword();

  return [
    hasSucceded,
    useCallbackImmutable(async ({ username, code, newPassword }: HandleResetPasswordArgs) => {
      if (username && code && newPassword) {
        await forgotPassword(username, code, newPassword);
        setSucceded(true);
      } else if (newPassword) {
        await changePassword(newPassword);
        setSucceded(true);
      }
    }),
  ];
};
