import { CircularProgress, Stack } from '@mui/material';
import type { OptionType, StepProps } from 'mns-components';
import {
  AlertMessage,
  ApplicationCard,
  Checkbox,
  EmptyState,
  Link,
  SearchField,
  Select,
  Typography,
  filterTruthy,
  groupBy,
  lowerCase,
  objectFilter,
  objectHas,
  objectKeys,
  objectMap,
  objectReduce,
  objectValues,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useGetAppsColumns } from '../../../../api/extractionsData';
import { useTemplateDetails } from '../../../../api/templateData';
import {
  getApplicationByRole,
  getApplicationConfig,
  getApplicationPipeTitle,
  useApplicationConfig,
} from '../../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../../common/getImageContent';
import { isAppCode, type AppCode, type ProviderName } from '../../../../components/views/appDescriptions';
import { useUser } from '../../../../hooks/useAuth';
import { useApplicationsRequest } from '../../../marketplace/hooks';
import type { SelectCategoryCard } from '../components/SelectCategoriesList';
import { SelectCategoriesList } from '../components/SelectCategoriesList';
import type { SelectCategoryProps } from '../components/SelectCategory';
import { SelectDatapointsList } from '../components/SelectDatapointsList';
import { CreateTemplateButtons } from '../CreateTemplateButtons';
import {
  appDeliverableStyles as useAppDeliverableStyles,
  createTemplateStyles as useStyles,
} from '../createTemplateStyles';
import type { CreateTemplateSteps } from '../types';

const getCheckedStatus = (selected = 0, all = 0) => {
  if (selected >= all) {
    return true;
  } else if (selected === 0) {
    return false;
  }
  return 'intermediate';
};

const ItemComponent: React.FC<SelectCategoryProps<AppCode>> = ({
  category: appCode,
  isSelected,
  countDatapoints,
  countSelectedDatapoints,
  onCheckAll,
  onSelect,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const appBackgroundClassName = useAppBackgroundClassName(appCode);
  const {
    content: { title, appDeliverable },
    company: { name: providerName },
  } = useApplicationConfig(appCode);
  const appDelivClasses = useAppDeliverableStyles();
  const appDeliverableBackgroundClassName = useMemo(
    () => (objectHas(appDelivClasses, appDeliverable) ? appDelivClasses[appDeliverable] : ''),
    [appDelivClasses, appDeliverable],
  );
  const { data: applications } = useApplicationsRequest();
  const app = applications?.find(({ codeApplication }) => codeApplication === appCode);
  const remainingNumber = app?.subscriptionsDto?.remainingAnalysis ?? null;

  return (
    <Stack
      component="button"
      tabIndex={0}
      textAlign="left"
      height="5rem"
      alignItems="stretch"
      className={classes.button}
      data-category={appCode}
    >
      <ApplicationCard.DataPoint
        appBackgroundClassName={appBackgroundClassName}
        applicationCode={appCode}
        applicationName={title}
        providerName={providerName}
        iconDataClassName={appDeliverableBackgroundClassName}
        isFocusedSource={isSelected}
        checked={getCheckedStatus(countSelectedDatapoints, countDatapoints)}
        countSelected={countSelectedDatapoints}
        onCheck={onCheckAll}
        onFocusSource={onSelect}
        remainingNumber={remainingNumber}
        data-testid={testid}
      />
    </Stack>
  );
};

export const ApplicationsDataPointsStep: React.FC<StepProps<CreateTemplateSteps, 2>> = ({
  stepValue: { applicationsDataPoints, selectedAppCode },
  stepValues: [{ templateId }],
  setStepValue,
  goPrevious,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const [search, setSearch] = useState('');
  const [providers, setProviders] = useState<ProviderName[]>([]);
  const { data: metadatas, isLoading: isLoadingMetadatas, isError: isErrorMetadatas } = useGetAppsColumns();
  const { data: templateDetails, isLoading: isLoadingTemplateDetails } = useTemplateDetails(templateId, {
    staleTime: Infinity,
  });

  const { apps: userRoles } = useUser();

  const appsAvailable = useMemo(
    () =>
      userRoles?.reduce((acc, role) => {
        const appCode = getApplicationByRole(role)?.appCode;
        if (appCode) acc.push(appCode);
        return acc;
      }, [] as AppCode[]) ?? [],
    [userRoles],
  );

  const providerOptions = useMemo(
    () =>
      !metadatas?.length
        ? []
        : Array.from(
            metadatas
              .reduce((acc, { source }) => {
                if (isAppCode(source) && appsAvailable.includes(source)) {
                  const {
                    company: { name, providerName },
                  } = getApplicationConfig(source);
                  acc.set(providerName, { label: name, value: providerName });
                }
                return acc;
              }, new Map<ProviderName, OptionType<ProviderName>>())
              .values(),
          ).sort((a, b) => a.label.localeCompare(b.label)),
    [metadatas, appsAvailable],
  );

  const grouped = useMemo(() => {
    if (metadatas) {
      const lowerSearch = lowerCase(search);
      const metadatasSearch = !search
        ? metadatas
        : metadatas.filter(
            (col) =>
              lowerCase(col.name).includes(lowerSearch) ||
              lowerCase(getApplicationPipeTitle(col.source)).includes(lowerSearch),
          );
      const metadatasFiltered = !providers.length
        ? metadatasSearch
        : metadatasSearch.filter((col) => providers.includes(getApplicationConfig(col.source).company.providerName));
      return objectFilter(groupBy(metadatasFiltered, 'source'), (item, appCode) => isAppCode(appCode));
    }
    return {};
  }, [metadatas, providers, search]) as AnyObject<AppCode, DataExtractorApi.Metadata.ColumnApp[]>;

  const categories = useMemo(
    () =>
      objectKeys(grouped).map(
        (appCode): SelectCategoryCard<AppCode> => ({
          name: appCode,
          countDatapoints: grouped[appCode]?.length ?? 0,
          countSelectedDatapoints: applicationsDataPoints[appCode]?.length ?? 0,
        }),
      ),
    [grouped, applicationsDataPoints],
  );

  const datapoints = useMemo(
    () => (selectedAppCode && Array.isArray(grouped[selectedAppCode]) ? grouped[selectedAppCode]! : []),
    [grouped, selectedAppCode],
  );

  const selectedDatapoints = useMemo(
    () =>
      selectedAppCode && Array.isArray(grouped[selectedAppCode])
        ? grouped[selectedAppCode]!.filter(
            (dp) => dp.required || applicationsDataPoints[selectedAppCode]?.includes(dp.code),
          ).map((dp) => dp.code)
        : [],
    [grouped, selectedAppCode, applicationsDataPoints],
  );

  const allFields = useMemo(() => objectMap(grouped, (list) => list!.map(({ code }) => code)), [grouped]);
  const countSelectedApps = objectReduce(applicationsDataPoints, (acc, list) => (list?.length ? acc + 1 : acc), 0);
  const countSelectedFields = filterTruthy(objectValues(applicationsDataPoints).flat()).length;
  const countAllFields = metadatas?.length ?? 0;

  const onCheckAll = useCallbackImmutable(() => {
    setStepValue(({ selectedAppCode: code }) =>
      countSelectedFields >= countAllFields
        ? { applicationsDataPoints: {}, selectedAppCode: code }
        : { applicationsDataPoints: allFields, selectedAppCode: code },
    );
  });

  const onAppCheckAll = useCallbackImmutable((appCode: AppCode) => {
    const appColumns = grouped[appCode];
    setStepValue(({ applicationsDataPoints: { [appCode]: current = [], ...others }, selectedAppCode: code }) => ({
      applicationsDataPoints: {
        [appCode]:
          !appColumns?.length || !appColumns.find((col) => !current.includes(col.code))
            ? []
            : appColumns.map((md) => md.code),
        ...others,
      },
      selectedAppCode: code,
    }));
  });

  const onDatapointCheck = useCallbackImmutable((datapoint: string) => {
    const appCode = selectedAppCode;
    if (appCode) {
      setStepValue(({ applicationsDataPoints: { [appCode]: current = [], ...others }, selectedAppCode: code }) => {
        const list = current.slice();
        const index = list.indexOf(datapoint);
        if (index < 0) {
          list.push(datapoint);
        } else {
          list.splice(index, 1);
        }
        return { applicationsDataPoints: { [appCode]: list, ...others }, selectedAppCode: code };
      });
    }
  });

  const selectProvidersRender = useCallbackImmutable((providerNames: ProviderName[]) =>
    providerNames.map((appCode) => providerOptions.find((opt) => opt.value === appCode)?.label).join(', '),
  );

  const setSelectedAppCode = useCallbackImmutable((code: AppCode) =>
    setStepValue((current) => ({
      applicationsDataPoints: current.applicationsDataPoints,
      selectedAppCode: code,
    })),
  );

  // when columns & selected template columns are loaded and empty, initialize preselection
  useEffect(() => {
    if (metadatas) {
      setStepValue((current) => {
        if (filterTruthy(objectValues(current.applicationsDataPoints).flat()).length > 0) return current;

        const data = metadatas.reduce((acc, { code, source }) => {
          if (
            isAppCode(source) &&
            templateDetails?.columns.find((col) => col.formula.source === source && col.formula.column === code)
          ) {
            if (!acc[source]) {
              acc[source] = [];
            }
            acc[source]?.push(code);
          }
          return acc;
        }, {} as Partial<AnyObject<AppCode, string[]>>);

        return {
          applicationsDataPoints: data,
          selectedAppCode: current.selectedAppCode,
        };
      });
    }
  }, [templateId, templateDetails, metadatas, setStepValue]);

  // when columns are loaded, select its first category
  useEffect(() => {
    setStepValue((current) => {
      const [code] = objectKeys(grouped);
      return current.selectedAppCode || !code
        ? current
        : {
            applicationsDataPoints: current.applicationsDataPoints,
            selectedAppCode: code,
          };
    });
  }, [grouped, setStepValue]);

  const render = useImmutable(
    () => (node: HTMLDivElement | null) =>
      node?.querySelector(`button[data-category=${JSON.stringify(selectedAppCode)}]` as 'button')?.focus(),
  );

  if (isErrorMetadatas) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to load portfolio data points"
        firstParagraph="Please try again later or contact us"
        data-testid={createTestid('error-columns')}
      />
    );
  }

  if (isLoadingMetadatas || isLoadingTemplateDetails) {
    return <CircularProgress />;
  }

  if (countAllFields === 0) {
    return (
      <>
        <Stack height="100%" data-testid={testid}>
          <Typography variant="body2" component="p" margin="1rem 0">
            Select the ESG data points to extract. If you have chosen an existing template, the data points are already
            checked.
          </Typography>
          <AlertMessage variant="warning" data-testid={createTestid('error-empty')}>
            <Typography variant="body2">
              It seems you haven&apos;t subscribed to any of our ESG providers. If you wish to subscribe, you need to
              contact us{' '}
              <Link.Underlined extern to="mailto:support@manaos.com" data-testid={createTestid('email-support')}>
                support@manaos.com
              </Link.Underlined>
            </Typography>
            <Typography variant="body2" fontWeight={600}>
              You can continue your template creation by clicking on the &quot;Continue&quot; below.
            </Typography>
          </AlertMessage>
        </Stack>
        <Buttons>
          <CreateTemplateButtons onPrevious={goPrevious} onNext={goNext} data-testid={createTestid('buttons')} />
        </Buttons>
      </>
    );
  }

  return (
    <>
      <Stack height="100%" data-testid={testid} ref={render}>
        <Typography variant="body2" component="p" margin="1rem 0">
          Select the ESG data points to extract. If you have chosen an existing template, the data points are already
          checked.
        </Typography>
        <SearchField
          uncontrolled
          fullWidth
          value={search}
          placeholder="Search app data points (application name and data points of provider)"
          onChange={setSearch}
          className={classes.search}
          data-testid={createTestid('search')}
        />
        <Stack direction="row" width="calc(50% - .5rem)" whiteSpace="nowrap" alignItems="center" spacing=".5rem">
          <Checkbox
            uncontrolled
            name="select-all"
            label="Select all"
            className={classes.checkbox}
            labelClassName={classes.checkboxLabel}
            variant="rose"
            onFieldChange={onCheckAll}
            indeterminate={countSelectedFields > 0}
            checked={countAllFields === countSelectedFields}
            data-testid={createTestid('checkbox-all')}
          />
          <Select<ProviderName[]>
            uncontrolled
            multiple
            fullWidth
            renderValue={selectProvidersRender}
            variant="labeled"
            label="Providers"
            name="providers"
            options={providerOptions}
            value={providers}
            onFieldChange={setProviders}
            data-testid={createTestid('providers')}
          />
        </Stack>
        {selectedAppCode && categories.length ? (
          <Stack direction="row" flexGrow={1} minHeight={0} spacing="1rem">
            <SelectCategoriesList<AppCode>
              categories={categories}
              selectedCategory={selectedAppCode}
              ItemComponent={ItemComponent}
              onSelect={setSelectedAppCode}
              onCheckAll={onAppCheckAll}
              data-testid={createTestid('categories')}
            />
            <SelectDatapointsList
              category={selectedAppCode}
              categoryName={getApplicationPipeTitle(selectedAppCode)}
              datapoints={datapoints}
              onCheck={onDatapointCheck}
              onCheckAll={onAppCheckAll}
              selectedDatapoints={selectedDatapoints}
              data-testid={createTestid('datapoints')}
            />
          </Stack>
        ) : (
          <EmptyState
            iconEnable
            iconName="info"
            title="The search does not match with a data point"
            data-testid={createTestid('error-notFound')}
          />
        )}
      </Stack>
      <Buttons>
        <CreateTemplateButtons
          onPrevious={goPrevious}
          onNext={goNext}
          labels={[
            { count: countSelectedApps, text: 'applications selected' },
            { count: countSelectedFields, text: 'data points selected' },
          ]}
          data-testid={createTestid('buttons')}
        />
      </Buttons>
    </>
  );
};
