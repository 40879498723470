import { Chip, Stack } from '@mui/material';
import type { ToggleButtonGroupItem } from 'mns-components';
import {
  Button,
  DialogPopup,
  EmptyStateV2,
  Icon,
  LoadingCircle,
  SearchField,
  ToggleButtonGroup,
  Typo,
  colors,
  filterTruthy,
  isString,
  objectReduce,
  objectValues,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useFetchMetadataSources, useFetchTemplates } from '../../hooks';
import { SelectTemplateDatapoints } from './SelectTemplateDatapoints';

const templateTypes = ['MY_TEMPLATES', 'MANAOS_TEMPLATES', 'ESG_APPS'] as const;
export type TemplateType = typeof templateTypes[number];

const ToggleButtonLabel: React.FC<{
  label: string;
  datapoints?: AnyObject<string, AnyArray | undefined>;
}> = ({ label, datapoints }) => (
  <Stack direction="row" alignItems="center" gap=".5rem">
    <Typo variant={datapoints ? 'body2medium' : 'body2'}>{label}</Typo>
    <Chip
      variant="badge"
      label={datapoints ? objectValues(datapoints).reduce((acc, list) => acc + (list?.length ?? 0), 0) : 0}
    />
  </Stack>
);

const useTemplateTypeOptions = (
  templateType: TemplateType | '',
  {
    MY_TEMPLATES: hasMyTemplates,
    MANAOS_TEMPLATES: hasManaosTemplates,
    ESG_APPS: hasEsgApps,
  }: AnyObject<TemplateType, boolean>,
  datapoints?: AnyObject<string, AnyArray | undefined>,
) =>
  useMemo(
    (): ToggleButtonGroupItem<TemplateType>[] =>
      filterTruthy([
        hasMyTemplates && {
          value: 'MY_TEMPLATES',
          children: (
            <ToggleButtonLabel
              label="My templates"
              datapoints={templateType === 'MY_TEMPLATES' ? datapoints : undefined}
            />
          ),
        },
        hasManaosTemplates && {
          value: 'MANAOS_TEMPLATES',
          children: (
            <ToggleButtonLabel
              label="Manaos templates"
              datapoints={templateType === 'MANAOS_TEMPLATES' ? datapoints : undefined}
            />
          ),
        },
        hasEsgApps && {
          value: 'ESG_APPS',
          children: (
            <ToggleButtonLabel label="ESG apps" datapoints={templateType === 'ESG_APPS' ? datapoints : undefined} />
          ),
        },
      ]),
    [templateType, datapoints, hasMyTemplates, hasManaosTemplates, hasEsgApps],
  );

export type TemplateFormData = {
  templateType: TemplateType | '';
  search: string;
  view?: string;
  datapoints: AnyObject<string, string[] | undefined>;
  datapointsRaw: AnyObject<string, string[] | undefined>;
  matchTemplateId: string | null;
};

export type TemplateData = {
  templateType: TemplateType | '';
  selected: AnyObject<string, string[] | undefined>;
  matchTemplateId: string | null;
};

type SelectTemplateProps = {
  disabled?: boolean;
  onChange(formData: TemplateData): void;
  'data-testid': string;
};

export const SelectTemplate = React.memo<SelectTemplateProps>(function SelectTemplateSection({
  disabled,
  onChange,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const [confirmChangeType, setConfirmChangeType] = useState<TemplateType>();
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [requiredDatapoints, setRequiredDatapoints] = useState<TemplateFormData['datapoints']>({});

  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
  } = useFetchTemplates({
    select: (list) => list.filter((tmp) => !tmp.isHidden),
  });
  const { data: esgSources, isLoading: isLoadingEsgSources, isError: isErrorEsgSources } = useFetchMetadataSources();

  const methods = useForm<TemplateFormData>({
    defaultValues: {
      templateType: '',
      search: '',
      datapoints: {},
      view: undefined,
      matchTemplateId: null,
    },
  });
  const { templateType, datapoints, matchTemplateId, search } = methods.watch();

  // when datapoints change, call onChange with new values
  useEffect(() => {
    onChange({
      templateType,
      selected: datapoints,
      matchTemplateId,
    });
  }, [onChange, templateType, datapoints, matchTemplateId]);

  useEffect(() => {
    if (templateType === '') {
      if (templates?.find((tpl) => !tpl.generic)) methods.setValue('templateType', 'MY_TEMPLATES');
      else if (esgSources?.find((source) => source.type == 'MART'))
        methods.setValue('templateType', 'MANAOS_TEMPLATES');
      else if (esgSources?.find((source) => ['LOOKTHROUGH', 'ESG_ANALYSIS'].includes(source.type)))
        methods.setValue('templateType', 'ESG_APPS');
    }
  }, [esgSources, methods, templateType, templates]);

  const handleChangePopupClose = useCallbackImmutable(() => setConfirmChangeType(undefined));

  const handleTemplateTypeChange = useCallbackImmutable(() => {
    if (confirmChangeType) {
      methods.setValue('search', '');
      methods.setValue('view', undefined);
      methods.setValue('datapoints', {});
      methods.setValue('datapointsRaw', {});
      methods.setValue('templateType', confirmChangeType);
    }
  });

  const handleTemplateTypeChangeConfirm = useCallbackImmutable((_, value: TemplateType) => {
    const count = objectReduce(
      datapoints,
      (acc, list, source) => acc + (list?.length ?? 0) - (requiredDatapoints[source]?.length ?? 0),
      0,
    );
    if (isString(value)) {
      if (count) {
        setConfirmChangeType(value);
      } else {
        methods.setValue('search', '');
        methods.setValue('view', undefined);
        methods.setValue('datapoints', {});
        methods.setValue('datapointsRaw', {});
        methods.setValue('templateType', value);
      }
    }
  });

  const handleResetPopupClose = useCallbackImmutable(() => setConfirmResetOpen(false));

  const handleClear = useCallbackImmutable(() => {
    methods.setValue('datapoints', requiredDatapoints);
    methods.setValue('datapointsRaw', {});
  });

  const handleClearConfirm = useCallbackImmutable(() => {
    const count = objectReduce(
      datapoints,
      (acc, list, source) => acc + (list?.length ?? 0) - (requiredDatapoints[source]?.length ?? 0),
      0,
    );
    if (count) {
      setConfirmResetOpen(true);
    } else {
      handleClear();
    }
  });

  const templateTypeOptions = useTemplateTypeOptions(
    templateType,
    {
      MY_TEMPLATES: !!templates?.find((tpl) => !tpl.generic),
      MANAOS_TEMPLATES: !!esgSources?.find((source) => source.type == 'MART'),
      ESG_APPS: !!esgSources?.find((source) => ['LOOKTHROUGH', 'ESG_ANALYSIS'].includes(source.type)),
    },
    datapoints,
  );

  if (isErrorTemplates || isErrorEsgSources) {
    return (
      <EmptyStateV2
        variant="error"
        useCase="dataErrors"
        title="Oops, something went wrong!"
        subTitle="Please refresh your page or contact our support team."
        illustrationVariant="iconType"
        data-testid={createTestid('error-templates')}
      />
    );
  }

  const isLoadingViews = isLoadingEsgSources || isLoadingTemplates;

  return (
    <>
      <FormProvider {...methods}>
        <Stack gap="1rem">
          {isLoadingViews ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              height="65vh"
              minHeight="345px"
              color={colors.primaryColor}
              gap="1rem"
            >
              <LoadingCircle size="medium" data-testid={createTestid('datapoints-backdrop')} />
              <Typo variant="body1medium" color={colors.defaultFontColor}>
                Loading in progress
              </Typo>
            </Stack>
          ) : (
            <Stack gap="1rem">
              <ToggleButtonGroup.Uncontrolled
                list={templateTypeOptions}
                exclusive
                disabled={disabled}
                value={templateType}
                onChange={handleTemplateTypeChangeConfirm}
                data-testid={createTestid('templateType-input')}
              />
              <Stack gap="2.5rem" direction="row">
                <Stack flexGrow={1} width="100%">
                  <SearchField
                    uncontrolled={false}
                    name="search"
                    value={search}
                    disableMargin
                    fullWidth
                    disabled={disabled}
                    data-testid={createTestid('search')}
                  />
                </Stack>
                <Stack flexGrow={1} width="100%" justifyContent="flex-end" alignItems="flex-end">
                  {templateType === 'ESG_APPS' && (
                    <Button
                      onClick={handleClearConfirm}
                      outlined
                      color="danger"
                      disabled={disabled}
                      startIcon={<Icon.Delete data-testid={createTestid('icon-clear')} />}
                      data-testid={createTestid('button-clear')}
                    >
                      Clear data points selection
                    </Button>
                  )}
                </Stack>
              </Stack>
              <Stack minHeight={0}>
                <SelectTemplateDatapoints
                  disabled={disabled}
                  requiredDatapoints={requiredDatapoints}
                  setRequiredDatapoints={setRequiredDatapoints}
                  data-testid={createTestid('picker')}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </FormProvider>
      <DialogPopup
        open={!!confirmChangeType}
        title="Warning"
        titleColor={colors.warningColor}
        mainButtonText="Clear and continue"
        closeButtonText="Keep my selection"
        onClose={handleChangePopupClose}
        onMainActionClick={handleTemplateTypeChange}
        data-testid={createTestid('popup-change')}
      >
        <Typo variant="body2">You cannot select datapoints from different tabs.</Typo>
        <Typo variant="body2">If you change tabs, you&apos;ll loose your selection.</Typo>
      </DialogPopup>
      <DialogPopup
        open={confirmResetOpen}
        title="Warning"
        titleColor={colors.warningColor}
        mainButtonText="Clear and continue"
        closeButtonText="Keep my selection"
        mainButtonColor="danger"
        onClose={handleResetPopupClose}
        onMainActionClick={handleClear}
        data-testid={createTestid('popup-reset')}
      >
        <Typo variant="body2">You are about to clear your data points selection.</Typo>
      </DialogPopup>
    </>
  );
});
