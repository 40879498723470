import { colors, makeStyles } from 'mns-components';
import dataOnly from '../../../assets/images/iconCategoriesCards/data-only.svg';
import portfolioAnalysis from '../../../assets/images/iconCategoriesCards/portfolio.svg';

export const createTemplateStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    padding: '2rem',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: colors.whiteColor,
    maxWidth: '1136px',
    padding: '2rem 2rem 0 2rem',
    border: 'none',
    outline: 'none',
    overflow: 'hidden',
  },
  setUpInput: {
    '&&': {
      width: '30rem',
      margin: '.5rem 0',
    },
  },
  setUpFrom: {
    '&&': {
      width: '30rem',
      margin: '.5rem 0',
    },
  },
  search: {
    '&&': {
      margin: 0,
      width: 'auto',
    },
  },
  checkbox: {
    margin: '1rem 0 1rem 1rem',
  },
  checkboxLabel: {
    alignSelf: 'flex-start',
    '& span.MuiTypography-root': {
      fontSize: '.875rem',
      fontWeight: 500,
    },
  },
  button: {
    border: 'none',
    background: 'none',
    padding: 'none',
  },
});

export const appDeliverableStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${dataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysis})`,
  },
});
