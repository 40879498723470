import { colors, makeStyles } from 'mns-components';

export const scheduledDeliveryModalStyles = makeStyles({
  form: {
    margin: '2rem auto',
    width: '20rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'clip auto',
  },
  buttonList: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1rem',
    borderTop: `1px solid ${colors.greyColor}`,

    '& > *:not(first-child)': {
      marginLeft: '1rem',
    },
  },
});
