import { Stack } from '@mui/material';
import { Icon, Typo, colors, useTestid } from 'mns-components';
import React from 'react';
import { ErrorStatus, type ErrorLogDataRework } from './DialogAuditErrorLog';

type DialogAuditErrorLogListLevelRendererProps = {
  fileError: ErrorLogDataRework;
  'data-testid': string;
};

export const DialogAuditErrorLogListLevelRenderer: React.FC<DialogAuditErrorLogListLevelRendererProps> = React.memo(
  function DialogAuditErrorLogListLevelRenderer({ fileError, 'data-testid': testid }) {
    const createTestid = useTestid(testid);

    if (fileError.errorStatus === ErrorStatus.Rejected)
      return (
        <Stack flexDirection="row" alignItems="center" gap="0.75rem" data-testid={createTestid('rejected')}>
          <Icon.Error size="small" color="danger" data-testid={testid} />
          <Typo variant="body2" color={colors.dangerColor}>
            Rejected
          </Typo>
        </Stack>
      );
    if (fileError.errorStatus === ErrorStatus['Blocking regulatory'])
      return (
        <Stack flexDirection="row" alignItems="center" gap="0.75rem" data-testid={createTestid('blocking')}>
          <Icon.Warning size="small" color="warning" data-testid={testid} />
          <Typo variant="body2" color={colors.warningColor}>
            Blocking regulatory
          </Typo>
        </Stack>
      );
    if (fileError.errorStatus === ErrorStatus['Non-blocking'])
      return (
        <Stack flexDirection="row" alignItems="center" gap="0.75rem" data-testid={createTestid('nonblocking')}>
          <Icon.Info size="small" color="secondary" data-testid={testid} />
          <Typo variant="body2" color={colors.primaryColor}>
            Non-blocking
          </Typo>
        </Stack>
      );
    else return <></>;
  },
);
