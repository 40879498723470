import { Stack } from '@mui/material';
import { useTestid, CardSelector, useNavigate, useCallbackImmutable, EmptyStateV2 } from 'mns-components';
import type { CardSelectorOptions } from 'mns-components';
import { useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { getMarketplaceRoute } from '../../../applications/marketplace/routes';
import { getApplicationConfig } from '../../../common/getApplicationConfig';
import { useProvidersIconBackgroundStyles } from '../../../common/getImageContent';
import { useUser } from '../../../hooks/useAuth';
import type { AppCode } from '../../views/appDescriptions';
import { getRoute } from '../routes';
import type { AppSettingsViewCode } from './applicationView';
import { applicationsSettings } from './applicationView';
import { applicationsSettingsStyles as useStyles } from './styles/applicationsSettingsStyles';

const useAppOptions = () => {
  const appBackgroundClasses = useProvidersIconBackgroundStyles();
  const { apps } = useUser();

  return useMemo(
    () =>
      applicationsSettings.reduce((acc, view) => {
        const {
          company: { providerName },
          config: { role },
        } = getApplicationConfig(view.appCode);
        if (role === 'MANAOS_PLATFORM' || apps.includes(role)) {
          const app: CardSelectorOptions<AppCode> = {
            label: view.label,
            value: view.appCode,
            appBackgroundClassName: appBackgroundClasses[providerName],
          };
          acc.push(app);
        }
        return acc;
      }, [] as CardSelectorOptions<AppCode>[]),
    [appBackgroundClasses, apps],
  );
};

export const ApplicationsSettingsPage: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const base = getRoute('settings-applications').link;
  const classes = useStyles();
  const { appCode } = useParams<{ appCode: AppSettingsViewCode }>();
  const createTestid = useTestid(testid);
  const appOptions = useAppOptions();
  const navigate = useNavigate();

  const handleNavigation = useCallbackImmutable((value: AppCode) => {
    navigate(generatePath(base, { appCode: value }));
  });

  if (!appCode || appCode.includes(':appCode')) {
    const firstAppCode = appOptions?.[0]?.value;
    handleNavigation(firstAppCode);
  }

  const Comp = applicationsSettings.find((view) => view.appCode === appCode)?.component;
  const defaultApp = useMemo(() => appOptions.find((app) => appCode === app.value), [appOptions, appCode]);

  if (!appOptions.length || !defaultApp) {
    const emptyStateTestid = !appOptions.length ? createTestid('empty-options') : createTestid('empty-default-app');

    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="noNeededApps"
          title="There is no app subsciption yet!"
          subTitle="For this use case, you need to subscribe to additional apps first."
          buttonText="Discover our app catalog"
          buttonHref={getMarketplaceRoute('marketplace-all').link}
          buttonVariant="secondary"
          data-testid={emptyStateTestid}
        />
      </Stack>
    );
  }

  return (
    <div className={classes.applicationsSettings} data-testid={testid}>
      <CardSelector
        options={appOptions}
        onChange={handleNavigation}
        defaultValue={defaultApp.value}
        data-testid={createTestid('app-selector')}
        name="choose-app"
        label="Choose app"
      />
      {Comp && <Comp data-testid={createTestid(appCode)} />}
    </div>
  );
};
