import { AppContent, AppLayout } from 'mns-components';
import { Audit } from '../../components/views/uploads/Audit';
import useStyles from './AppLayout.styles';
import { Dashboard } from './dashboard/Dashboard';
import { Inventories } from './inventories/Inventories';
import { EmitterPermissions } from './permissions/EmitterPermissions';

type DashboardLayoutProps = {
  'data-testid': string;
};

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  return (
    <div className={classes.gridContainerDashboard}>
      <Dashboard data-testid={testid} />
    </div>
  );
};

type InventoriesLayoutProps = {
  'data-testid': string;
};

export const InventoriesLayout: React.FC<InventoriesLayoutProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <Inventories data-testid={testid} />
    </div>
  );
};

type EmitterPermissionsLayoutProps = {
  filter?: string;
  'data-testid': string;
};

export const EmitterPermissionsLayout: React.FC<EmitterPermissionsLayoutProps> = ({
  filter,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <EmitterPermissions filter={filter} data-testid={testid} />
    </div>
  );
};

type MyfilesProps = {
  refreshDelay?: number;
  onOpenModal(): void;
  'data-testid': string;
};

export const MyfilesLayout: React.FC<MyfilesProps> = ({ refreshDelay, onOpenModal, 'data-testid': testid }) => {
  return (
    <AppLayout>
      <AppContent variant="expanded-noscroll">
        <Audit refreshDelay={refreshDelay} buttonVariant="secondary" onOpenModal={onOpenModal} data-testid={testid} />
      </AppContent>
    </AppLayout>
  );
};
