import { Button, Icon, Modal, useTestid } from 'mns-components';
import { modalStyles as useModalStyles } from './styles/modalStyles';

type ConfirmNoProviderModalProps = {
  isOpen?: boolean;
  isTaxonomyEmpty: boolean;
  isSfdrEmpty: boolean;
  isSsiEmpty: boolean;
  onClose?(): void;
  onConfirm?(): void;
  'data-testid': string;
};

export const ConfirmNoProviderModal: React.FC<ConfirmNoProviderModalProps> = ({
  isOpen,
  isTaxonomyEmpty,
  isSfdrEmpty,
  isSsiEmpty,
  onClose,
  onConfirm,
  'data-testid': testid,
}) => {
  const classes = useModalStyles();
  const createTestid = useTestid(testid);

  const phrase = (() => {
    const names = [];
    if (isSfdrEmpty) names.push('SFDR PAI');
    if (isTaxonomyEmpty) names.push('EU taxonomy');
    if (isSsiEmpty) names.push('Share of sustainable investment');

    return `You have not selected a source for the ${names.join(' and ')}. Are you sure you want to continue ?`;
  })();

  return (
    <Modal modalTitle="Warning" open={!!isOpen} onClose={onClose} color="warning">
      <div className={classes.body}>
        <p>{phrase}</p>
      </div>
      <div className={classes.buttonList}>
        <Button
          color="primary"
          outlined
          startIcon={<Icon.Close data-testid={createTestid('icon-close')} />}
          onClick={onClose}
          data-testid={createTestid('button-close')}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm} data-testid={createTestid('button-submit')}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
