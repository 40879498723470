import { IconButton } from '@mui/material';
import { useTestid, Icon } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';

type FundMappingListActionsProps = {
  mapping: CollectApi.FundMapping;
  onUpdate?(mapping: CollectApi.FundMapping): void;
  onDelete?(mapping: CollectApi.FundMapping): void;
  'data-testid': string;
};

export const FundMappingListActions: React.FC<FundMappingListActionsProps> = ({
  mapping,
  onUpdate,
  onDelete,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const handleUpdate = useMemo(() => onUpdate && (() => onUpdate(mapping)), [onUpdate, mapping]);
  const handleDelete = useMemo(() => onDelete && (() => onDelete(mapping)), [onDelete, mapping]);

  return (
    <>
      {handleUpdate && (
        <IconButton color="secondary" onClick={handleUpdate} data-testid={createTestid('button-edit')}>
          <Icon.Edit data-testid={createTestid('icon-edit')} />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton onClick={handleDelete} data-testid={createTestid('button-delete')}>
          <Icon.Delete color="danger" data-testid={createTestid('icon-delete')} />
        </IconButton>
      )}
    </>
  );
};
