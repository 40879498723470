import { Stack } from '@mui/material';
import { EmptyStateV2 } from 'mns-components';
import React from 'react';
// Material Ui
import { getMarketplaceRoute } from '../../../marketplace/routes';

type ErrorActivationProps = { 'data-testid': string };

export const ErrorActivation: React.FC<ErrorActivationProps> = ({ 'data-testid': testid }) => (
  <Stack height="100%" justifyContent="center" alignItems="center">
    <EmptyStateV2
      variant="info"
      useCase="noApps"
      title="There is no app subscription yet!"
      subTitle="Discover all the services available on our catalog and subscribe to your first app."
      buttonText="Discover our app catalog"
      buttonVariant="secondary"
      buttonHref={getMarketplaceRoute('marketplace-all').link}
      data-testid={testid}
    />
  </Stack>
);
