import type { FormProps } from 'mns-components';
import { Button, Link, TextField, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCognitoAuthError, useAuthUser, useHandleLogin } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';
import { ErrorBox } from './Forms';
import { routes } from './routes';

type LoginFormData = {
  username: string;
  password: string;
};

type LoginInputsProps = Omit<FormProps<LoginFormData>, 'children'> & { error?: Error };

const LoginInputs: React.FC<LoginInputsProps> = ({ error, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();

  const {
    formState: { isSubmitting },
    watch,
  } = useFormContext<LoginFormData>();

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  watch(() => setErrorMessage(''));

  return (
    <>
      <ErrorBox error={errorMessage} data-testid={createTestid('error-login')} />
      <div>
        <TextField name="username" type="text" label="username" fullWidth data-testid={createTestid('username')} />
      </div>
      <div>
        <TextField name="password" type="password" label="password" fullWidth data-testid={createTestid('password')} />
      </div>
      <Link
        to={routes.forgotPasswordUri.path}
        className={classes.link}
        data-testid={createTestid('link-forgot-password')}
      >
        Forgot your password?
      </Link>
      <Link
        to={routes.forgotUsernameUri.path}
        className={classes.link}
        data-testid={createTestid('link-forgot-password')}
      >
        Forgot your username?
      </Link>
      <div>
        <Button color="secondary" type="submit" loading={isSubmitting} data-testid={createTestid('submit')}>
          Log in
        </Button>
      </div>
    </>
  );
};

const LoginForm = makeForm<LoginFormData, LoginInputsProps>(LoginInputs);

export const Login: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const [cognitoUser] = useAuthUser();
  const login = useHandleLogin();

  const handleLogin = useCallbackImmutable(async ({ username, password }: LoginFormData) => login(username, password));

  const handleValidation = useCallbackImmutable(({ username, password }: LoginFormData) => {
    const errors: Partial<AnyObject<keyof LoginFormData, string>> = {};
    if (!username.length) errors.username = 'Username should be filled';
    if (!password.length) errors.password = 'Password should be filled';
    if (!/^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u.test(username)) {
      errors.username =
        'Please verify the username that you have typed. If you are unsure, you can use the forgot your username button.';
    }

    return errors;
  });

  return (
    <AuthPageLayout data-testid={testid}>
      <h1 className={classes.title}>Log in</h1>
      <LoginForm
        error={isCognitoAuthError(cognitoUser) ? cognitoUser : undefined}
        onSubmit={handleLogin}
        onValidate={handleValidation}
        className={classes.authForm}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
