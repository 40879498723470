import { Button, Dialog, useTestid } from 'mns-components';
import type { CollectApi, Quality } from 'mns-sdk-collect';
import { useState } from 'react';
import { collectHistoryStyles as useStyles } from './CollectHistory.styles';
import { CollectHistoryController } from './CollectHistoryController';
import { CollectHistoryListRequest } from './CollectHistoryListRequest';

type CollectHistoryProps = {
  portfolio: Required<CollectApi.FundPositionToCollect>;
  onClose?(): void;
  'data-testid': string;
};

const CollectHistory: React.FC<CollectHistoryProps> = ({ portfolio, onClose, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const [filterQualities, setFilterQualities] = useState<Quality[]>([]);

  return (
    <div className={classes.modalWrapper} data-testid={testid}>
      <div className={classes.modalHeader}>
        <h2 data-testid={createTestid('externalId')}>{portfolio.fundPosition.externalId}</h2>
        <h2 className={classes.grey} data-testid={createTestid('funName')}>
          {portfolio.fundPosition.name}
        </h2>
      </div>
      <div className={classes.modalContent}>
        <CollectHistoryController onQualityChange={setFilterQualities} data-testid={createTestid('controller')} />
        <CollectHistoryListRequest
          portfolio={portfolio}
          filterQualities={filterQualities}
          data-testid={createTestid('list')}
        />
      </div>
      <div className={classes.modalFooter}>
        <Button color="primary" type="submit" onClick={onClose} data-testid={createTestid('button-close')}>
          Close
        </Button>
      </div>
    </div>
  );
};

type CollectHistoryModalProps = {
  portfolio: Required<CollectApi.FundPositionToCollect> | null;
  onClose?(): void;
  'data-testid': string;
};

export const CollectHistoryModal: React.FC<CollectHistoryModalProps> = ({
  portfolio,
  onClose,
  'data-testid': testid,
}) => (
  <Dialog open={!!portfolio} onClose={onClose} data-testid={`${testid}-dialog`}>
    {portfolio && <CollectHistory portfolio={portfolio} onClose={onClose} data-testid={testid} />}
  </Dialog>
);
