import { filterTruthy, useCallbackImmutable, useLocalStorage } from 'mns-components';
import type { AccountApi, CollectApi } from 'mns-sdk-collect';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import type { AppCode } from '../components/views/appDescriptions';
import { accountApi, appCollectApi } from '../store/apis';
import { getOrganisationIdByCognitoUser, useUserSession } from './useAuth';
import { useFetchAllPortfolios } from './usePortfoliosRequest';

export const getAvailableDates = (ptf: CollectApi.Portfolio) =>
  filterTruthy([ptf.latestVersion.valuationDate, ...(ptf.valuationDates ?? [])]);

export const usePtfAndDate = () => {
  const [portfolioId, setPortfolioId] = useLocalStorage<string>('portfolioId');
  const [valuationDate, setValuationDate] = useLocalStorage<string>('valuationDate');

  const setStateAndCache = useCallbackImmutable(([pId, vDate]: [string, string]) => {
    setPortfolioId(pId);
    setValuationDate(vDate);
  });

  useFetchAllPortfolios({
    select: (ptfs) => {
      if (ptfs) {
        const found = ptfs?.find((ptf) => ptf.portfolioId === portfolioId) ?? ptfs[0];
        const pId = found.portfolioId;
        const allDates = getAvailableDates(found);
        const vDate =
          valuationDate && allDates.includes(valuationDate) ? valuationDate : found.latestVersion.valuationDate;
        if (portfolioId !== pId) setPortfolioId(pId);
        if (valuationDate !== vDate) setValuationDate(vDate);
      }
    },
  });

  return [
    [portfolioId ? portfolioId : undefined, valuationDate ? valuationDate : undefined],
    setStateAndCache,
  ] as const;
};

export const useLastApp = () => useLocalStorage<AppCode>('lastApp');

export const useOrganisation = () => {
  const session = useUserSession();
  const orgId = session ? getOrganisationIdByCognitoUser(session) : undefined;
  return useQuery(['accountApi.getOrganisation', orgId], () => accountApi.getOrganisation(orgId!), {
    enabled: !!orgId,
  });
};

export const useEmitterAccessRequests = <TSelect extends CollectApi.AccessDemand[]>(
  options?: UseQueryOptions<CollectApi.AccessDemand[], unknown, TSelect, string[]>,
) =>
  useQuery(
    ['appCollectApi.getListDataAuthorizationAccess', 'emitter'],
    () => appCollectApi.getListDataAuthorizationAccess('emitter'),
    options,
  );

export const useRecipentAccessRequests = () =>
  useQuery(['appCollectApi.getListDataAuthorizationAccess', 'recipient'], () =>
    appCollectApi.getListDataAuthorizationAccess('recipient'),
  );

export const useHubspot = <TSelect = AccountApi.HubspotToken>(
  options: UseQueryOptions<AccountApi.HubspotToken, unknown, TSelect, string[]>,
) => useQuery(['accountApi.getHubspotToken'], accountApi.getHubspotToken, options);
