import { Stack } from '@mui/material';
import { Checkbox, colors, joinClass, Typography, useImmutable, useTestid } from 'mns-components';
import { selectDatapointsStyles as useStyles } from './selectDatapointsStyles';

export type SelectCategoryProps<Cat extends string = string> = {
  category: Cat;
  isSelected: boolean;
  countDatapoints: number;
  countSelectedDatapoints: number;
  onSelect(category: string): void;
  onCheckAll(category: string): void;
  'data-testid': string;
};

export const SelectCategory: React.FC<SelectCategoryProps> = ({
  category,
  isSelected,
  countDatapoints,
  countSelectedDatapoints,
  onSelect,
  onCheckAll,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <Stack
      component="button"
      tabIndex={0}
      textAlign="left"
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={joinClass(classes.categoryCard, isSelected && 'selected')}
      data-testid={testid}
      onClick={useImmutable(() => () => onSelect(category))}
      data-category={category}
    >
      <Checkbox
        uncontrolled
        variant="rose"
        className={classes.checkboxCard}
        name={category}
        onFieldChange={useImmutable(() => () => onCheckAll(category))}
        checked={countSelectedDatapoints >= countDatapoints}
        indeterminate={countSelectedDatapoints > 0}
        data-testid={createTestid('checkbox')}
      />
      <Stack direction="column" margin="1rem 1rem 1rem 0" flexGrow={1} minWidth={0}>
        <Typography.Ellipsis variant="body2" fontWeight={500} title={category}>
          {category}
        </Typography.Ellipsis>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing=".25rem">
            <Typography variant="caption" color={colors.disabledColor}>
              Data points:
            </Typography>
            <Typography variant="caption" data-testid={createTestid('countDatapoints')}>
              {countDatapoints}
            </Typography>
          </Stack>
          <Stack direction="row" spacing=".25rem">
            <Typography
              variant="caption"
              fontWeight={600}
              color={colors.pinkColor}
              data-testid={createTestid('countSelectedDatapoints')}
            >
              {countSelectedDatapoints}
            </Typography>
            <Typography variant="caption">selected</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
