import { Stack } from '@mui/material';
import {
  groupBy,
  useCallbackImmutable,
  AppContent,
  Typo,
  Switch,
  Divider,
  useTestid,
  EmptyState,
  EmptyStateV2,
} from 'mns-components';
import type { NotificationApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useNotifications, usePutNotifications } from './hooks';
import { NotificationsGroupList } from './NotificationsGroupList';

const mapNotificationsStatusToGroups = (notificationsStatus: NotificationApi.NotificationStatus[]) => {
  const grouped = groupBy(notificationsStatus, 'group');
  return Object.keys(grouped).map((title) => ({
    title,
    items:
      grouped[title]?.map((item) => ({
        key: item.notificationCode,
        id: item.notificationCode,
        label: item.label,
        value: item.activated,
      })) ?? [],
  }));
};

type NotificationsSettingsPageProps = { 'data-testid': string };

export const NotificationsSettingsPage: React.FC<NotificationsSettingsPageProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { data, isLoading, isError } = useNotifications();
  const { mutate: updateNotifs, isLoading: loadingNotifs, error: errorUpdateNotif } = usePutNotifications();

  const groups = useMemo(() => (data ? mapNotificationsStatusToGroups(data) : undefined), [data]);

  const valueAll = useMemo(() => !data?.find((status) => !status.activated), [data]);

  const onClickAll = useCallbackImmutable(() => {
    if (data) {
      updateNotifs(
        data.reduce((acc, notif) => {
          acc[notif.notificationCode] = !valueAll;
          return acc;
        }, {} as Record<string, boolean>),
      );
    }
  });

  const onClickItem = useCallbackImmutable((notificationCode: string) => {
    const notification = data?.find((notif) => notif.notificationCode === notificationCode);
    if (notification) {
      updateNotifs({ [notification.notificationCode]: !notification.activated });
    }
  });

  if (isLoading) {
    return (
      <AppContent>
        <EmptyState iconEnable iconName="processing" data-testid={createTestid('emptyState-loading')} />
      </AppContent>
    );
  }

  if (!groups || isError || errorUpdateNotif) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('empty')}
        />
      </Stack>
    );
  }

  return (
    <AppContent>
      <Stack gap={'1rem'}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typo data-testid={createTestid('text-all')} variant="body1medium">
            Email Notifications
          </Typo>
          <Switch
            uncontrolled
            disabled={loadingNotifs}
            data-testid={createTestid('toggler-all')}
            value={valueAll}
            onChange={onClickAll}
            label="Allow all notifications"
            labelPlacement="start"
          />
        </Stack>
        <Divider variant="default" />
      </Stack>
      <NotificationsGroupList onClick={onClickItem} disabled={loadingNotifs} data-testid={testid} groups={groups} />
    </AppContent>
  );
};
