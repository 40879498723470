import { IconButton, Stack } from '@mui/material';
import { Icon, Typo, colors, useTestid } from 'mns-components';
import type { ErrorLogDataRework } from './DialogAuditErrorLog';

type DialogAuditErrorLogDetailsProps = {
  errorDetails: ErrorLogDataRework;
  onClickDetailsClose(): void;
  'data-testid': string;
};
export const DialogAuditErrorLogDetails = ({
  errorDetails,
  onClickDetailsClose,
  'data-testid': testid,
}: DialogAuditErrorLogDetailsProps) => {
  const createTestid = useTestid(testid);

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        height: 'auto',
        maxHeight: '60%',
        padding: '1rem 1rem 0 1rem',
        borderTop: `1px solid ${colors.primaryColor}`,
        gap: '1rem',
      }}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typo variant="body1medium">Log details</Typo>
        <IconButton onClick={onClickDetailsClose} data-testid={createTestid('button')}>
          <Icon.Close color="secondary" data-testid={createTestid('button-close')} />
        </IconButton>
      </Stack>
      <Stack gap="0.75rem" overflow="scroll">
        <Stack gap="0.25rem">
          <Typo variant="body2medium" color={colors.primaryColor}>
            Level
          </Typo>
          <Typo variant="body2" data-testid={createTestid('level')}>
            {errorDetails.errorStatus}
          </Typo>
        </Stack>
        <Stack gap="0.25rem">
          <Typo variant="body2medium" color={colors.primaryColor}>
            Error message
          </Typo>
          <Typo variant="body2" data-testid={createTestid('errorMessage')}>
            {errorDetails.errorMessage}
          </Typo>
        </Stack>
        <Stack gap="0.25rem">
          <Typo variant="body2medium" color={colors.primaryColor}>
            Column(s) impacted
          </Typo>
          <Typo variant="body2" data-testid={createTestid('columnName')}>
            {errorDetails.columnName}
          </Typo>
        </Stack>
        <Stack gap="0.25rem">
          <Typo variant="body2medium" color={colors.primaryColor}>
            Line(s) impacted
          </Typo>
          <Typo variant="body2" data-testid={createTestid('lines')}>
            {errorDetails.lineNumbers.join(', ')}
          </Typo>
        </Stack>
      </Stack>
    </Stack>
  );
};
