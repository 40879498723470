import { useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { RenderWhenLoggedIn } from '../../../components/routing/RenderWhenLoggedIn';
import { useUser } from '../../../hooks/useAuth';
import { MarketplaceTCsInfoModal } from '../components/MarketplaceTCsInfoModal';
import { MarketplaceTCsModal } from '../components/MarketplaceTCsModal';
import { useTermsAndConditionsRequest } from '../hooks';

type TermsAndConditionsContentProps = { 'data-testid': string };

const TermsAndConditionsContent: React.FC<TermsAndConditionsContentProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [isTCsModalOpenConfirm, setTCsModalOpenConfirm] = useState(false);
  const [isTCsInfoModalOpenConfirm, setTCsInfoModalOpenConfirm] = useState(false);

  const { isAdmin } = useUser();
  const { data: resultTerms, error: errorTerms } = useTermsAndConditionsRequest();
  const resultTermsStatus = resultTerms?.status;

  useEffect(() => {
    const shouldOpen = !!errorTerms || resultTermsStatus === 'NOT_ACCEPTED';
    if (isAdmin) {
      setTCsModalOpenConfirm(shouldOpen);
    } else {
      setTCsInfoModalOpenConfirm(shouldOpen);
    }
  }, [isAdmin, resultTermsStatus, errorTerms]);

  return (
    <>
      <MarketplaceTCsModal open={isTCsModalOpenConfirm} data-testid={createTestid('modal-admin')!} />
      <MarketplaceTCsInfoModal open={isTCsInfoModalOpenConfirm} data-testid={createTestid('modal-info')!} />
    </>
  );
};

export const TermsAndConditions: React.FC<TermsAndConditionsContentProps> = (props) => (
  <RenderWhenLoggedIn>
    <TermsAndConditionsContent {...props} />
  </RenderWhenLoggedIn>
);
