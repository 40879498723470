import { IconButton, Stack, Tooltip } from '@mui/material';
import type { AggridTableColumn, AggridTableProps, BulkItemProps } from 'mns-components';
import {
  AggridTable,
  Bulk,
  convertToDateTimeLocal,
  filterTruthy,
  useDefaultColDef,
  makeActionColDef,
  useAutoSize,
  useImmutable,
  useTestid,
  useCallbackImmutable,
  convertToDateTimeUTCWithTZ,
  Icon,
  Switch,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { extractionsAutomatedStyles as useStyles } from './ExtractionsAutomatedStyles';

const getActionColDef = makeActionColDef<DataExtractorApi.Extraction.Extraction>();

export type BulkClickHandler = (row: DataExtractorApi.Extraction.Extraction) => void;

const bulkOptions = (
  row: DataExtractorApi.Extraction.Extraction,
  onDisplayDetails?: ExtractionsSettingsListProps['onDisplayDetails'],
) =>
  filterTruthy<BulkItemProps>([
    onDisplayDetails && {
      key: 'details',
      label: 'View Scope Details',
      onClick: () => onDisplayDetails(row),
    },
  ]);

const useColumns = (
  testid: string,
  onDeleteExtraction: (row: DataExtractorApi.Extraction.Extraction) => void,
  onToggleStatus?: ExtractionsSettingsListProps['onToggleStatus'],
  onDisplayDetails?: ExtractionsSettingsListProps['onDisplayDetails'],
): AggridTableColumn<DataExtractorApi.Extraction.Extraction>[] => {
  const createTestid = useTestid(testid);

  return [
    {
      field: 'extractionName',
      headerName: 'Extraction name',
    },
    {
      field: 'templateName',
      headerName: 'Template used',
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      sort: 'desc',
      sortable: true,
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
    },
    {
      field: 'extractionScope',
      headerName: 'Scope',
      valueGetter: ({ data: { extractionScope: value } }) => value.map(({ name }) => name).join('; '),
      cellRendererFramework: ({ data }) => data.extractionScope.length,
    },
    {
      field: 'extractionFeed',
      headerName: 'Extraction feed',
    },
    getActionColDef(
      'extractionStatus',
      120,
      function ActionCol({ data }) {
        return (
          <Switch
            uncontrolled
            isIconVisible
            value={data.extractionStatus === 'ACTIVATED'}
            onChange={useCallbackImmutable(() => onToggleStatus?.(data))}
            data-testid={createTestid('switch-activation-' + data.id)}
          />
        );
      },
      { headerName: 'Activation' },
    ),
    getActionColDef('extractionStatus', 130, function ActionCol({ data }) {
      return (
        <Stack direction="row" alignItems="center">
          <Tooltip title="delete">
            <IconButton
              size="small"
              data-testid={createTestid('delete-buton-' + data.id)}
              onClick={useCallbackImmutable(() => onDeleteExtraction(data))}
            >
              <Icon name="delete" color="danger" data-testid={createTestid('delete-' + data.id)} />
            </IconButton>
          </Tooltip>
          <Bulk items={bulkOptions(data, onDisplayDetails)} data-testid={createTestid('bulk' + data.id)} />
        </Stack>
      );
    }),
  ];
};

type ExtractionsSettingsListProps = {
  onToggleStatus?: BulkClickHandler;
  onDisplayDetails?: BulkClickHandler;
  onDeleteExtraction: (row: DataExtractorApi.Extraction.Extraction) => void;
  'data-testid'?: string;
} & Omit<AggridTableProps<DataExtractorApi.Extraction.Extraction>, 'defaultColDef' | 'columnDefs'>;

export const ExtractionsAutomatedList: React.FC<ExtractionsSettingsListProps> = ({
  onToggleStatus,
  onDisplayDetails,
  onDeleteExtraction,
  'data-testid': testid,
  ...props
}: ExtractionsSettingsListProps) => {
  const classes = useStyles();
  return (
    <AggridTable
      {...props}
      columnDefs={useColumns(testid, onDeleteExtraction, onToggleStatus, onDisplayDetails)}
      getRowNodeId={useImmutable(() => (row: DataExtractorApi.Extraction.Extraction) => row.id)}
      data-testid={testid}
      defaultColDef={useDefaultColDef<DataExtractorApi.Extraction.Extraction>()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      className={classes.aggrid}
    />
  );
};
