import { Collapse, Stack } from '@mui/material';
import { Icon, clsx, useCallbackImmutable, useTestid, Typo } from 'mns-components';
import { CoverageApi } from 'mns-sdk-collect/dist';
import React, { useMemo, useState } from 'react';
import { useApplicationConfig } from '../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../common/getImageContent';
import type { AppCode } from '../../../components/views/appDescriptions';
import CoverageRenderer from './CoverageRenderer';
import { coverageCollapsibleStyles as useStyles } from './styles/coverageCollapsibleStyles';
import CoverageDetail = CoverageApi.CoverageDetail;

export type CollapsibleAppHeaderProps = {
  appCode: AppCode;
  isCollapsible?: boolean;
  /** percent, between 0 and 1 */
  coverage?: number | null;
  coverageDetails?: CoverageDetail[];
  error?: string | null;
  status?: string;
  matchingLevel?: string | null;
  'data-testid': string;
};

export const getMatchingLevelWording = (
  providerCode: string,
  coverage?: number | null,
  coverageDetails?: CoverageDetail[] | null,
  matchingLevel?: string | null,
  errorMessage?: string | null,
) => {
  if (coverage === null || coverage === undefined || errorMessage) return '';

  if (providerCode === 'clarity') return 'Average';

  if (matchingLevel !== 'INSTRUMENT' && coverageDetails && coverageDetails?.length > 0) return 'At issuer level';

  return 'At instrument level';
};

export const shouldDisplayCoverageDetails = (
  coverageDetails?: CoverageDetail[] | null,
  matchingLevel?: string | null,
  errorMessage?: string | null,
) => {
  return !errorMessage && matchingLevel !== 'INSTRUMENT' && coverageDetails && coverageDetails?.length > 0;
};

export const CollapsibleAppHeader: React.FC<CollapsibleAppHeaderProps> = ({
  appCode,
  coverage,
  coverageDetails,
  error,
  status,
  matchingLevel,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { content } = useApplicationConfig(appCode);

  const logo = useAppBackgroundClassName(appCode);
  const iconClasses = useMemo(() => clsx([classes.descLogo, logo]), [classes.descLogo, logo]);
  const providerCode = useApplicationConfig(appCode)?.company?.providerName;
  const coverageLevelWording = getMatchingLevelWording(providerCode, coverage, coverageDetails, matchingLevel, error);

  const appDeliverableIcon = useMemo(() => {
    return content.appDeliverable !== 'Other' ? classes[content.appDeliverable] : null;
  }, [content.appDeliverable, classes]);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.flex}>
        {logo && <div className={iconClasses} data-testid={createTestid('logo')} />}
        <div className={classes.headerDetails}>
          <div>{content.title}</div>
          <div className={classes.flex}>
            {appDeliverableIcon && <div className={clsx(classes.iconAppDeliverable, appDeliverableIcon)} />}
            <div className={classes.headerDetailsTxt}>{content.appDeliverable}</div>
          </div>
        </div>
      </div>
      <div className={classes.coverageRenderer} data-testid={createTestid('coverage')}>
        <Stack marginRight="0.75rem">
          <Typo variant="caption">{coverageLevelWording}</Typo>
        </Stack>
        <CoverageRenderer
          coverage={coverage}
          error={error}
          status={status}
          isClarityDetails={false}
          data-testid={createTestid('coverageRenderer')}
        />
      </div>
    </div>
  );
};

export type CollapsibleIconProps = {
  children: React.ReactNode;
  open?: boolean;
  appCode: AppCode;
  /** percent, between 0 and 1 */
  coverage?: number | null;
  coverageDetails?: CoverageDetail[];
  error?: string | null;
  status?: string;
  matchingLevel?: string | null;
  'data-testid': string;
};

export const CollapsibleApp: React.FC<CollapsibleIconProps> = ({
  children,
  open = false,
  appCode,
  coverage,
  coverageDetails,
  error,
  status,
  matchingLevel,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [expanded, setExpanded] = useState(open);
  const classes = useStyles();

  const handleExpand = useCallbackImmutable(() => {
    setExpanded(!expanded);
  });
  return (
    <div className={classes.appdetails}>
      <div className={classes.header}>
        <div onClick={handleExpand} className={classes.expandIcon}>
          {expanded ? (
            <Icon.Expandless data-testid={createTestid('icon-expand')} />
          ) : (
            <Icon.Expandmore data-testid={createTestid('icon-expand')} />
          )}
        </div>
        <div className={classes.space}>
          <CollapsibleAppHeader
            appCode={appCode}
            coverage={coverage}
            coverageDetails={coverageDetails}
            error={error}
            status={status}
            matchingLevel={matchingLevel}
            data-testid={createTestid('collapsibleAppHeader')}
          />
        </div>
      </div>
      <div className={expanded ? classes.collapseContainer : undefined}>
        <Collapse in={expanded}>{children}</Collapse>
      </div>
    </div>
  );
};

export type CollapsibleProviderProps = {
  children: React.ReactNode;
  open?: boolean;
  title: string;
  'data-testid': string;
};

export const CollapsibleProvider: React.FC<CollapsibleProviderProps> = ({
  children,
  open = false,
  title,
  'data-testid': testid,
}) => {
  const [expanded, setExpanded] = useState(open);
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const handleExpand = useCallbackImmutable(() => {
    setExpanded(!expanded);
  });

  return (
    <div className={classes.mainContainer} data-testid={testid}>
      <div className={classes.providers}>
        <div onClick={handleExpand} className={classes.expandIcon}>
          {expanded ? (
            <Icon.Expandless data-testid={createTestid('icon-expand')} />
          ) : (
            <Icon.Expandmore data-testid={createTestid('icon-expand')} />
          )}
        </div>
        <div className={classes.providerTitle}>{title}</div>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
};
