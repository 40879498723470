import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { CLOUDFRONT_DNSNAME } from '../../../../app.constants';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { getImageContent } from '../../../../common/getImageContent';
import { DownloadSampleBulk } from '../../../../components/subscriptions/ButtonList/DownloadSampleBulk';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { cardPreviewStyle as useStyles } from './styles/cardPreviewStyles';

export type CardPreviewProps = {
  appCode: AppCode;
  'data-testid': string;
};

type PDFDocumentProxy = {
  numPages: number;
};

export const LoadingComponent = ({
  loadingState,
  'data-testid': testid,
}: {
  loadingState: string | null;
  'data-testid': string;
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.loadingContainer}>
      <Backdrop data-testid={createTestid('backdrop')} />
      <p>{`Loading … ${loadingState}`}</p>
    </div>
  );
};

export const CardPreview: React.FC<CardPreviewProps> = ({ appCode, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { content, company } = useApplicationConfig(appCode);

  const screenshots = useMemo(
    () => content.screenshots?.map((screen) => getImageContent(screen)),
    [content.screenshots],
  );

  /* PDF */
  const [loadingState, setLoadingState] = useState<string | null>('0 %');
  const [totalPages, setTotalPages] = useState<number>(0);

  const onDocumentSuccess = useCallbackImmutable(({ numPages }: PDFDocumentProxy): void => {
    setTotalPages(numPages);
  });

  const pdfUrl =
    content.docFactsheet && content.docFactsheet.includes('.pdf')
      ? `//${CLOUDFRONT_DNSNAME}/providers/${company.providerName}/legal/${content.docFactsheet}`
      : null;

  const onLoadProgress = useCallbackImmutable(({ loaded, total }) =>
    setLoadingState(`${Math.trunc((loaded / total) * 100)} %`),
  );

  if (!content) {
    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There is no preview yet!"
          data-testid={createTestid('empty')}
        />
      </Stack>
    );
  }

  return (
    <div className={classes.cardModal}>
      {/* Download dropdown */}
      <div className={classes.dropdownContainer}>
        <div className={classes.dropdown}>
          <DownloadSampleBulk
            providerName={company.providerName}
            docFactsheet={content.docFactsheet ?? undefined}
            docAnalytics={content.docAnalytics ?? undefined}
            docMethodology={content.docMethodology ?? undefined}
            color="primary"
            outlined
            data-testid={createTestid('download')}
          />
        </div>
      </div>
      {/* SCREENSHOTS */}
      {screenshots &&
        screenshots.length > 0 &&
        screenshots.map((screenshot, i) => (
          <img key={i} src={screenshot} alt={`screen ${i}`} className={classes.screenshot} /> // NOSONAR
        ))}
      {/* PDF */}
      {!screenshots && pdfUrl && (
        <div data-testid={createTestid('reader')} className={classes.center}>
          <Document
            file={pdfUrl}
            onLoadProgress={onLoadProgress}
            loading={<LoadingComponent loadingState={loadingState} data-testid={createTestid('loading')} />}
            onLoadSuccess={onDocumentSuccess}
          >
            {Array.from({ length: totalPages }, (_, i: number) => (
              <div className={classes.page} key={i}>
                <Page pageNumber={i + 1} loading={<Backdrop data-testid={createTestid(`backdrop-${i}`)} />} />
              </div>
            ))}
          </Document>
        </div>
      )}
      {!pdfUrl && (!screenshots || screenshots.length == 0) && (
        <Stack margin="auto">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There is no preview yet!"
            data-testid={createTestid('empty')}
          />
        </Stack>
      )}
    </div>
  );
};
