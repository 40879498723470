import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { Button, Icon, NotificationDot, Typography, colors, useCallbackImmutable } from 'mns-components';

type AccordionFinalizeProps<T extends string> = {
  category: T;
  categoryName: string;
  accordionPanel: T | null;
  list: string[];
  useAccordionChange(newAccordionPanel: T | null): (_: unknown, expanded: boolean) => void;
  onClickModify(ev: React.MouseEvent, category: T): void;
  'data-testid': string;
};

export const AccordionFinalize = <T extends string>({
  category,
  categoryName,
  accordionPanel,
  list,
  useAccordionChange,
  onClickModify,
  'data-testid': testid,
}: AccordionFinalizeProps<T>) => (
  <Accordion
    key={category}
    expanded={accordionPanel === category}
    onChange={useCallbackImmutable(useAccordionChange(category))}
    data-testid={testid}
  >
    <AccordionSummary expandIcon={<Icon name="expandless" data-testid={`${testid}-icon-accordion`} />}>
      <Stack
        flexGrow={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginRight="1rem"
        minWidth={0}
      >
        <Stack direction="row" alignItems="center" marginRight="1rem" minWidth={0}>
          <Typography.Ellipsis variant="body1" fontWeight={500} title={categoryName}>
            {categoryName}
          </Typography.Ellipsis>
          <NotificationDot notifValue={list.length} isInTab />
        </Stack>
        <Button
          text
          color="primary"
          startIcon={<Icon name="edit" data-testid={`${testid}-icon-modify`} />}
          onClick={useCallbackImmutable((ev: React.MouseEvent) => onClickModify(ev, category))}
          data-testid={`${testid}-button-modify`}
        >
          Modify
        </Button>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="caption" color={colors.disabledColor}>
        {list.map((code, index) => (index ? ` ; ${code}` : code))}
      </Typography>
    </AccordionDetails>
  </Accordion>
);
