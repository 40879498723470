import { useCallbackImmutable, useNavigate, useTestid } from 'mns-components';
import React from 'react';
import { CLOUDFRONT_DNSNAME } from '../../../app.constants';
import { getCollectRoute } from '../../../applications/collectionApp/routes';
import { getDisseminationRoute } from '../../../applications/disseminationApp/routes';
import { UploadModalContent } from '../../../applications/productRange/dissemination/uploadModal/UploadModalContent';
import { getUploadsRoute } from '../../../applications/uploads/routes';
import useStyles from './styles/importCreatePortfolioStyles';

const docPortfolioTemplate = 'portfolio-template.xlsx';
const urlManaosPortfolioTemplate = `//${CLOUDFRONT_DNSNAME}/providers/manaos/templates/${docPortfolioTemplate}`;

export type CurrentApp = 'collect' | 'dissemination' | 'myPortfolios' | 'other';

export type ImportCreatePortfolioProps = {
  closeDialog?(): void;
  currentApp?: CurrentApp;
  'data-testid': string;
};

export const ImportCreatePortfolio: React.FC<ImportCreatePortfolioProps> = ({
  closeDialog,
  currentApp = 'other',
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const navigate = useNavigate();

  const closeDialogModal = useCallbackImmutable(() => {
    closeDialog?.();
    switch (currentApp) {
      case 'collect':
        navigate(getCollectRoute('collection-uploads').link);
        break;
      case 'dissemination':
        navigate(getDisseminationRoute('dissemination-uploads').link);
        break;
      case 'myPortfolios':
        navigate(getUploadsRoute('uploads').link);
        break;
      default:
        break;
    }
  });

  const onSuccessUpload = useCallbackImmutable(() => {
    setTimeout(() => closeDialogModal(), 500);
  });

  return (
    <div className={classes.rootMainTabs} data-testid={testid}>
      <div className={classes.tabsPanelContainer}>
        <div>
          <UploadModalContent
            onClose={closeDialogModal}
            onSuccess={onSuccessUpload}
            templateUrl={urlManaosPortfolioTemplate}
            data-testid={createTestid('panel-upload')}
          />
        </div>
      </div>
    </div>
  );
};
