import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import { DialogPopup, Typo, useCallbackImmutable } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDeleteMapping } from '../hooks';

type FundMappingDeleteModalProps = {
  fundMapping: CollectApi.FundMapping | null;
  onClose(): void;
  gridApi: MutableRefObject<GridApi | null>;
  'data-testid': string;
};

export const FundMappingDeleteModal: React.FC<FundMappingDeleteModalProps> = ({
  fundMapping,
  onClose,
  gridApi,
  'data-testid': testid,
}) => {
  const { mutate: deleteFundMapping, isSuccess, isLoading, isError } = useDeleteMapping();

  const onSubmit = useCallbackImmutable(() => {
    if (fundMapping) deleteFundMapping(fundMapping.id);
  });

  useEffect(() => {
    if (isSuccess) {
      gridApi.current?.refreshServerSideStore({ purge: true });
      onClose();
      toast.success('Mapping successfuly deleted');
    }
    if (isError) {
      onClose();
      toast.error('Failed to delete mapping');
    }
  }, [isSuccess, onClose, isError, gridApi]);

  if (!fundMapping) return null;

  return (
    <DialogPopup
      title="Delete mapping"
      open={!!fundMapping}
      onClose={onClose}
      onMainActionClick={onSubmit}
      mainButtonText="Delete mapping"
      mainButtonColor="danger"
      mainButtonDisabled={isLoading}
      data-testid={testid}
    >
      <Stack gap="1rem">
        <Stack>
          <Typo variant="body2">
            You are about to delete the mapping{' '}
            <Typo component="span" variant="body2medium">
              &quot;{fundMapping.internalCode}&quot;
            </Typo>{' '}
            from your organisation.
          </Typo>
          <Typo variant="body2">The mapping will no longer be used to collect your inventory data.</Typo>
        </Stack>
        <Typo variant="body2medium">Do you confirm ?</Typo>
      </Stack>
    </DialogPopup>
  );
};
