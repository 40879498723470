import { Tooltip } from '@mui/material';
import { Icon, useTestid } from 'mns-components';

type ShareclassTooltipProps = { shareclassId?: string; fundId?: string; 'data-testid': string };

export const ShareclassTooltip: React.FC<ShareclassTooltipProps> = ({
  shareclassId,
  fundId,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  if (fundId && shareclassId && fundId !== shareclassId) {
    return (
      <>
        <span style={{ paddingRight: '8px' }}>{shareclassId}</span>
        <Tooltip
          arrow
          title={
            <>
              <p>Shareclass Id: {shareclassId}</p>
              <p>Fund Id: {fundId}</p>
            </>
          }
        >
          <Icon.Info color="secondary" fontSize="small" data-testid={createTestid('icon-info')} />
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <Tooltip placement="right" title={<p>Shareclass Id: {shareclassId || fundId}</p>}>
        <span>{shareclassId || fundId}</span>
      </Tooltip>
    </>
  );
};

export const shareclassPlain = ({ shareclassId, fundId }: Pick<ShareclassTooltipProps, 'shareclassId' | 'fundId'>) =>
  fundId && shareclassId && fundId !== shareclassId ? `${shareclassId} (${fundId})` : shareclassId || fundId || '';
