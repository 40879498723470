import { isObject, makeGlobalState, makeOpenModalBoolean, makePortal, objectHas } from 'mns-components';
import { useMemo } from 'react';
import type { RouterInterface } from '../../components/routing/RouterInterface';
import type { AppCode } from '../../components/views/appDescriptions';
import { useUser } from '../../hooks/useAuth';
import { useEmailDelivery } from '../../hooks/useUserSettings';
import { getDisseminationRoute, routes } from './routes';

export const usePeriodPicker = makeGlobalState<[Date | null, Date | null]>([null, null]);
export const [KpiContainerPortal, KpiContainer] = makePortal('kpi-container');
export const useDisseminationRoutes = (appCode: AppCode) => {
  const { apps: roles } = useUser();
  const emailDeliveryActive = useEmailDelivery(appCode);

  return useMemo(() => {
    const rules = {
      emailDeliveryActive,
    };

    return routes.reduce((acc, rawRoute) => {
      const role = rawRoute.expectRole;
      if (!role) {
        acc.push(rawRoute);
      } else {
        if (objectHas(rules, role)) {
          const value = rules[role];
          if (isObject(value)) {
            acc.push({
              ...rawRoute,
              linkDefaultArgs: value,
            });
          } else if (value) {
            acc.push(rawRoute);
          }
        } else if (roles.includes(role)) {
          acc.push(rawRoute);
        }
      }
      return acc;
    }, [] as RouterInterface[]);
  }, [emailDeliveryActive, roles]);
};

export const [useUploadModalOpen, useManageUploadModalOpen] = makeOpenModalBoolean(
  () => getDisseminationRoute('dissemination-uploads').link,
  'openUploadModal',
);
