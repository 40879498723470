import { makeStyles } from 'mns-components';

export const InventoriesListStyles = makeStyles({
  buttons: {
    height: '3rem',
    position: 'static',
    margin: '0 1rem 0.5rem 0',
  },
  exportButton: {
    top: '-2.875rem',
    width: '10rem',
    position: 'relative',
    margin: 'auto 0 auto auto',
    textAlign: 'right',
    '& svg': {
      color: '#5F7AF1',
      padding: '.25rem',
      borderRadius: '50%',
      transition: 'all 0.4s ease',
    },
    '& svg:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  exportButtonTodo: {
    top: '20.375rem',
    left: '22.75rem',
    width: '3.125rem',
    position: 'absolute',
    '& svg': {
      color: '#5F7AF1',
      padding: '.25rem',
      borderRadius: '50%',
      transition: 'all 0.4s ease',
    },
    '& svg:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  dlMultipleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '0',
    position: 'relative',
    top: '-4.2rem',
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  dlRequestButton: {
    margin: 'auto 0 auto auto',
    position: 'relative',
    width: '10.625rem',
    display: 'block',
    right: '10rem',
    top: '-5rem',
    textAlign: 'right',
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  rowBackWhite: { background: 'white !important' },
  rowBackGrey: { background: '#f0f0f0 !important' },
  '* > .ag-center-cols-container': {
    margin: '0 !important',
  },
  fullWidth: { '& > *': { width: '100%' } },
});
