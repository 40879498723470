import { colors, makeStyles } from 'mns-components';

export const dataExtractorAppStyles = makeStyles({
  templateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: colors.primaryColor,
  },
  iconDisabled: {
    color: colors.darkGreyColor,
  },
  textLink: {
    fontSize: '12px',
  },
  download: {
    marginLeft: 'auto',
  },
});
