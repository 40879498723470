import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import type { AggridTableColumn, SelectFilterItem } from 'mns-components';
import {
  Accordion,
  AggridTable,
  Button,
  Dialogv2,
  Icon,
  SearchField,
  SelectFilter,
  Typo,
  joinCase,
  makeActionColDef,
  spinalCase,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../../common/utils';
import { dataExtractorApi } from '../../../../store/apis';
import { ReportStatus } from './ReportStatus';

//
// ExtractionGeneratedDetailsList

const getActionColDef = makeActionColDef<DataExtractorApi.Report.ReportLight>();

type UseColumnsProps = {
  cellSelectProps: AggridTableColumn<DataExtractorApi.Report.ReportLight>;
  onDownload(row: DataExtractorApi.Report.ReportLight): void;
  'data-testid': string;
};

const useColumns = ({ cellSelectProps, onDownload, 'data-testid': testid }: UseColumnsProps) =>
  useMemo(
    (): AggridTableColumn<DataExtractorApi.Report.ReportLight>[] => [
      cellSelectProps,
      {
        field: 'portfolio.externalIdValue',
        headerName: 'Portfolio ID',
      },
      {
        field: 'portfolio.name',
        headerName: 'Portfolio Name',
      },
      {
        field: 'portfolio.valuationDate',
        headerName: 'NAV Date',
      },
      {
        field: 'status',
        headerName: 'Status',
        cellRendererFramework: ({ value, data }) => (
          <ReportStatus status={value} data-testid={`${testid}-${data.id}-status`} />
        ),
      },
      getActionColDef('id', 80, function ActionCell({ data }) {
        const createTestid = useTestid(testid);

        return (
          <Tooltip title="Download">
            <IconButton
              size="small"
              color="secondary"
              disabled={data.status !== 'COMPLETE'}
              onClick={useCallbackImmutable(() => onDownload(data))}
              data-testid={createTestid(`${data.id}-button-download`)}
            >
              <Icon.Download data-testid={createTestid(`${data.id}-icon-download`)} />
            </IconButton>
          </Tooltip>
        );
      }),
    ],
    [cellSelectProps, onDownload, testid],
  );

type ExtractionGeneratedDetailsListProps = {
  rows?: DataExtractorApi.Report.ReportLight[];
  search: string;
  onRowsSelection(rows: DataExtractorApi.Report.ReportLight[]): void;
  onDownload(row: DataExtractorApi.Report.ReportLight): void;
  'data-testid': string;
};

const getRowNodeId = (row: DataExtractorApi.Report.ReportLight) => row.id;

const ExtractionGeneratedDetailsList: React.FC<ExtractionGeneratedDetailsListProps> = ({
  rows,
  search,
  onRowsSelection,
  onDownload,
  'data-testid': testid,
}) => {
  const [gridSelectProps, cellSelectProps] = useRowSelection<DataExtractorApi.Report.ReportLight>(
    (ev) => onRowsSelection(ev.api.getSelectedRows()),
    true,
  );
  return (
    <AggridTable
      {...gridSelectProps}
      quickFilterText={search}
      rowData={rows}
      columnDefs={useColumns({
        cellSelectProps,
        onDownload,
        'data-testid': testid,
      })}
      getRowNodeId={getRowNodeId}
      defaultColDef={useDefaultColDef<DataExtractorApi.Report.ReportLight>()}
      onGridReady={useAutoSize('fit')}
      data-testid={testid}
    />
  );
};

//
// ExtractionGeneratedDetails

const mapStatus: AnyObject<DataExtractorApi.Report.Report['status'], string> = {
  COMPLETE: 'Complete',
  FAILED: 'Failed',
  IN_PROGRESS: 'Processing',
  REQUESTED: 'Requested',
};

const onDownloadExtraction = async (row: DataExtractorApi.Report.Report) => {
  try {
    const { reportPresignedUrl } = await dataExtractorApi.report.getReport(row.id);
    await downloadFile(
      reportPresignedUrl,
      `${spinalCase(row.portfolio.name)}_${joinCase(row.portfolio.externalIdValue)}_${spinalCase(
        row.portfolio.valuationDate,
      )}.csv`,
    );
    toast.success(`The extraction file download succeed`);
  } catch (err) {
    console.error(err);
    toast.error("The extraction file couldn't be downloaded");
  }
};

interface ExtractionGeneratedDetailsProps {
  extraction: DataExtractorApi.Extraction.Extraction;
  reports: DataExtractorApi.Report.ReportLight[];
  'data-testid': string;
}

export const ExtractionGeneratedDetails: React.FC<ExtractionGeneratedDetailsProps> = ({
  extraction,
  reports,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [reportRows, setReportRows] = useState<DataExtractorApi.Report.Report[]>([]);

  const scopeReports = useMemo(
    () => reports.filter((rep) => rep.idExtraction === extraction.id),
    [reports, extraction.id],
  );

  const reportsFiltered = useMemo(
    () => (status !== 'all' ? scopeReports.filter((report) => report.status === status) : scopeReports),
    [scopeReports, status],
  );

  const statusOptions = useMemo(
    () =>
      scopeReports
        ? Array.from(
            scopeReports
              .reduce((acc, report) => {
                const option = acc.get(report.status);
                if (option) {
                  acc.set(report.status, { ...option, count: option.count + 1 });
                } else {
                  acc.set(report.status, { label: mapStatus[report.status], value: report.status, count: 1 });
                }
                return acc;
              }, new Map<DataExtractorApi.Report.Report['status'], SelectFilterItem>())
              .values(),
          )
        : [],
    [scopeReports],
  );

  const handleRowsSelection = useCallbackImmutable((rows: DataExtractorApi.Report.Report[]) => {
    setReportRows(rows);
  });

  const handleDownload = useCallbackImmutable(async () => {
    const promList: Promise<void>[] = [];
    for (const report of reportRows) {
      if (report.status === 'COMPLETE') {
        promList.push(onDownloadExtraction(report));
      }
    }
    await Promise.all(promList);
  });

  const isCompleteRowSelected = reportRows.some(({ status: s }) => s === 'COMPLETE');

  return (
    <Accordion
      title={
        <Stack direction="row" alignItems="center" gap="1rem">
          <Typo variant="body2medium">Extraction per portfolios</Typo>
          <Chip variant="badge" color="secondary" label={extraction.extractionScope.length} />
        </Stack>
      }
      data-testid={testid}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap="1rem" marginBottom="1rem">
        <Stack direction="row" alignItems="center" gap="1rem">
          <SearchField disableMargin value={search} onChange={setSearch} data-testid={createTestid('search')} />
        </Stack>
        <Stack direction="row" alignItems="center" gap="1rem">
          <SelectFilter
            label="Status"
            activeItem={status}
            onClick={setStatus}
            dropdownItems={statusOptions}
            size="medium"
            data-testid={createTestid('select-status')}
          />
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
            color="primary"
            size="medium"
            disabled={!isCompleteRowSelected}
            onClick={handleDownload}
            data-testid={createTestid('button-download')}
          >
            Download
          </Button>
        </Stack>
      </Stack>
      <ExtractionGeneratedDetailsList
        search={search}
        rows={reportsFiltered}
        onDownload={onDownloadExtraction}
        onRowsSelection={handleRowsSelection}
        data-testid={createTestid('list')}
      />
    </Accordion>
  );
};

//
// ExtractionGeneratedDetailsModal

export const ExtractionGeneratedDetailsModal: React.FC<{
  extraction?: DataExtractorApi.Extraction.Extraction;
  reports: DataExtractorApi.Report.ReportLight[];
  'data-testid': string;
  onClose(): void;
}> = ({ reports, extraction, onClose, 'data-testid': testid }) => (
  <Dialogv2
    sizeVariant="md"
    dialogTitle={`Scope Details — ${extraction?.extractionName}`}
    DialogTitleIcon={Icon.Details}
    open={!!extraction}
    onClose={onClose}
    data-testid={`${testid}-modal`}
  >
    {!!extraction && <ExtractionGeneratedDetails reports={reports} extraction={extraction} data-testid={testid} />}
  </Dialogv2>
);
