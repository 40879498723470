import { AppContent, AppLayout } from 'mns-components';
import React from 'react';
import { PastDeliveries } from '../../../components/emailDelivery/pastDeliveries/PastDeliveries';

export const EmailDelivery: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <AppLayout>
    <AppContent variant="expanded-noscroll">
      <PastDeliveries
        dateFieldName="General reference date"
        appCode="manaos-productrangedissemination"
        data-testid={testid}
      />
    </AppContent>
  </AppLayout>
);
