import type { GuidedTourContextType } from 'mns-components';
import { useCallbackImmutable, useGuidedTourContext } from 'mns-components';
import { type AccountApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, type UseQueryOptions } from 'react-query';
import type { GuidedTourScenario } from '../components/guidedTour';
import { useGuidedTourScenario } from '../components/guidedTour';
import { accountApi } from '../store/apis';

export type GuidedTourType = {
  scenarioName: GuidedTourScenario;
  version: number;
  completedAt: string;
};

export type GuidedTourData = GuidedTourType & {
  userName: string;
};

const queryKey = 'accountApi.getGuidedTourScenariosCompleted';

export const useGetGuidedTourScenariosCompleted = <TData = AccountApi.GuidedTourScenariosCompleted[]>(
  options?: UseQueryOptions<AccountApi.GuidedTourScenariosCompleted[], Error, TData, string[]>,
) => useQuery([queryKey], accountApi.getGuidedTourScenariosCompleted, options);

export const useUpdateGuidedTourScenarioComplete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (props: AccountApi.GuidedTourScenarioComplete) => accountApi.postGuidedTourScenarioComplete(props),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
      },
    },
  );
};

type UseGuidedTourType = GuidedTourContextType & {
  completed: boolean;
  complete(): void;
  start(): void;
  hide(): void;
  proceed(): void;
  goNext(): void;
  goBack(): void;
  goLast(): void;
};

export const useGuidedTour = (scenarioName: GuidedTourScenario): UseGuidedTourType => {
  const {
    data: scenariosCompleted,
    isLoading: isLoadingScenarioCompleted,
    isError: isErrorScenarioCompleted,
  } = useGetGuidedTourScenariosCompleted({ staleTime: Infinity });
  const {
    mutate: completeScenarioRequest,
    isLoading: isLoadingCompleteScenarioRequest,
    isError: isErrorCompleteScenarioRequest,
  } = useUpdateGuidedTourScenarioComplete();

  const context = useGuidedTourContext();
  const { setState, setRunning, setStepIndex } = context;
  const version = context.getScenarioVersion() ?? 0;
  const steps = useGuidedTourScenario(scenarioName);

  const start = useCallbackImmutable(() => {
    setState(({ options }) => ({
      steps,
      isRunning: true,
      currentStep: undefined,
      currentTour: undefined,
      stepIndex: 0,
      options,
    }));
  });

  if (
    !isLoadingScenarioCompleted &&
    !isLoadingCompleteScenarioRequest &&
    !isErrorCompleteScenarioRequest &&
    !scenariosCompleted?.some(({ scenarioCode }) => scenarioCode === scenarioName)
  ) {
    completeScenarioRequest({ scenarioCode: scenarioName, version });
  }

  const completed = useMemo(() => {
    if (isLoadingScenarioCompleted || isErrorScenarioCompleted) return true;

    return !!scenariosCompleted?.some(({ scenarioCode }) => scenarioCode === scenarioName);
  }, [scenariosCompleted, scenarioName, isLoadingScenarioCompleted, isErrorScenarioCompleted]);

  const complete = useCallbackImmutable(async () => {
    completeScenarioRequest({ scenarioCode: scenarioName, version });
    setStepIndex(() => -1);
  });

  const hide = useCallbackImmutable(() => setRunning(false));
  const proceed = useCallbackImmutable(() => setRunning(true));

  const goNext = useCallbackImmutable(() => setStepIndex((current) => current + 1));
  const goBack = useCallbackImmutable(() => setStepIndex((current) => current - 1));
  const goLast = useCallbackImmutable(() => setStepIndex(() => steps.length - 1));

  return { ...context, completed, complete, start, hide, proceed, goNext, goBack, goLast };
};
