import { colors, gradient, makeStyles } from 'mns-components';
import dataOnlyLight from '../../../assets/images/iconCategoriesCards/data-only-light.svg';
import dataOnly from '../../../assets/images/iconCategoriesCards/data-only.svg';
import portfolioAnalysisLight from '../../../assets/images/iconCategoriesCards/portfolio-light.svg';
import portfolioAnalysis from '../../../assets/images/iconCategoriesCards/portfolio.svg';

export const marketplaceListStyles = makeStyles({
  mainContainer: {
    marginLeft: '12px',
    marginTop: '8px',
  },
  appIcon: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    flex: '0 0 3.75rem',
    width: '3.75rem',
    height: '3.75rem',

    borderRadius: '10px',
    boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.07)',
    '@media (max-width: 1440px)': {
      flex: '0 0 3.5rem',
      width: '3.5rem',
      height: '3.5rem',
    },
    '@media (max-width: 1280px)': {
      flex: '0 0 3.125rem',
      width: '3.125rem',
      height: '3.125rem',
    },
  },
  titles: {
    paddingLeft: '1rem',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
  sectionsTitles: {
    display: 'flex',
  },
  sectionContainer: {
    marginTop: '1rem',
    marginLeft: '12px',
  },
  cardContainer: {
    boxShadow: '0 0 1.5rem 0 rgba(95,122,241,0.05)',
    cursor: 'pointer',

    minWidth: '16rem',
    maxWidth: '24rem',
    flex: '0 0 calc(33.33% - 2rem)',
    marginRight: '2rem',

    '&:hover': {
      boxShadow: '0 0 1.25rem 0 rgba(0,0,0,0.1)',
    },
  },
  cardBodyContainer: {
    padding: '.25rem 1rem',
    height: '9.375rem',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    background: 'white',
    '@media (max-width: 1280px)': {
      height: '10rem',
    },
    '& p': {
      fontSize: '0.8rem',
      lineHeight: '1rem',
      textAlign: 'justify',
      color: colors.defaultFontColor,
      opacity: '60%',
      padding: '0',
    },
  },
  tags: {
    display: 'flex',
    margin: 'auto auto 0',
    paddingBottom: '1.25rem',
    alignSelf: 'flex-end',
    width: '100%',
    justifyContent: 'space-evenly',
    '& span': {
      padding: '.25rem 1rem',
      borderRadius: '1.25rem',
      fontSize: '.75rem',
      LineHeight: '1rem',
      textAlign: 'center',
      color: colors.infoColor,
      backgroundColor: 'rgba(95,122,241,0.1)',
    },
  },
  cardFooterDefaultContainer: {
    padding: '.625rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
    display: 'block',
    color: colors.darkGreyColor,
    fontWeight: 900,
    background: 'rgba(151, 148, 148, 0.2)',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (max-width: 1280px)': {
      fontSize: '.75rem',
      padding: '.75rem',
    },
  },
  cardFooterActiveContainer: {
    padding: '.625rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
    display: 'block',
    color: colors.successColor,
    fontWeight: 900,
    background: '#daeedf',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (max-width: 1280px)': {
      fontSize: '.75rem',
      padding: '.75rem',
    },
  },
  cardFooterInfoContainer: {
    padding: '.625rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
    display: 'block',
    color: `${colors.whiteColor}`,
    fontWeight: 900,
    background: `${colors.infoColor}`,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (max-width: 1280px)': {
      fontSize: '.75rem',
      padding: '.75rem',
    },
  },
  cardFooterPendingContainer: {
    padding: '.625rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
    display: 'block',
    color: colors.warningColor,
    background: 'rgba(249,122,20,0.2)',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (max-width: 1280px)': {
      fontSize: '.75rem',
      padding: '.75rem',
    },
  },
  cardFooterExpiredContainer: {
    padding: '.625rem',
    justifyContent: 'center',
    textTransform: 'uppercase',
    display: 'block',
    color: '#969494',
    background: '#eaeaea',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (max-width: 1280px)': {
      fontSize: '.75rem',
      padding: '.75rem',
    },
  },
  cardHeaderContainer: {
    margin: 0,
    background: '#f5f6f8',
    justifyContent: 'left',
    alignItems: 'center',
    height: '8.5rem',
    '@media (max-width: 1440px)': {
      height: '6.875rem',
    },
    '@media (max-width: 1280px)': {
      fontSize: '1rem',
      height: '5rem',
    },

    '& img': {
      width: '3.75rem',
      height: '3.75rem',
      borderRadius: '10px',
      boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.07)',
      '@media (max-width: 1440px)': {
        width: '3.5rem',
        height: '3.5rem',
      },
      '@media (max-width: 1280px)': {
        width: '3.125rem',
        height: '3.125rem',
      },
    },
    '& h5': {
      color: colors.defaultFontColor,
      fontSize: '1.125rem',
      fontWeight: 'bold',
      textAlign: 'left',
      lineHeight: '1rem',
      margin: 0,
      wordBreak: 'break-word',
      '@media (max-width: 1440px)': {
        fontSize: '1.25rem',
      },
      '@media (max-width: 1280px)': {
        fontSize: '1rem',
      },
    },
    '& h6': {
      lineHeight: '.875rem',
      fontSize: '.875rem',
      textAlign: 'left',
      color: colors.defaultFontColor,
      opacity: '60%',
      margin: 0,
      paddingTop: '.375rem',
      '& span': {
        fontWeight: 'bold',
      },
      '@media (max-width: 1280px)': {
        fontSize: '.75rem',
      },
    },
  },
  newApp: {
    background: gradient.gradientPink,
    color: 'white',
    padding: '2px .625rem',
    position: 'absolute',
    right: '.75rem',
    top: '.75rem',
    borderRadius: '.625rem',
    '@media (max-width: 1440px)': {
      right: '.625rem',
      top: '.625rem',
      fontSize: '.75rem',
    },
    '@media (max-width: 1280px)': {
      right: '.5rem',
      top: '.5rem',
      fontSize: '.625rem',
    },
  },
  appsContainer: {
    paddingTop: '.5rem',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});

export const marketplaceByUseCasesStyles = makeStyles({
  useCaseContainer: {
    width: '88vw',
    height: '10rem',
  },
  useCaseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
  },
  useCaseTitle: {
    fontWeight: 'bold',
    display: 'flex',
    alignSelf: 'center',
  },
  headerRight: {
    alignSelf: 'center',
  },
  notification: {
    display: 'flex',
    alignSelf: 'center',
  },
  scrollContainer: {
    height: '13rem',
    position: 'relative',
    overflow: 'hidden',
    transform: 'translate(-2rem, -2rem)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  useCaseApps: {
    position: 'absolute',
    display: 'flex',
    height: '14rem',
    gap: '1rem',
    width: '100%',
    overflow: 'scroll hidden',
    padding: '2rem',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const appDeliverableStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${dataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysis})`,
  },
});

export const appDeliverableLightStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${dataOnlyLight})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysisLight})`,
  },
});
