import { AppContent, AppLayout } from 'mns-components';
import { Portfolios as PortfolioList } from '../../../components/views/portfolios/Portfolios';
import { useUploadModalOpen } from '../hooks';

export const Portfolios: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <AppLayout>
    <AppContent variant="expanded-noscroll">
      <PortfolioList portfolioOriginType="ALL" onOpenModal={useUploadModalOpen()} data-testid={testid} />
    </AppContent>
  </AppLayout>
);
