import { AppContent, AppLayout, ToggleButtonGroup, useCallbackImmutable, useNavigate, useTestid } from 'mns-components';
import { generatePath, useParams } from 'react-router-dom';
import type { AppCode } from '../views/appDescriptions';
import type { EmailDeliveryView } from './routes';
import { getEmailDeliveryRoute, routesOptions } from './routes';

type EmailDeliveryLayoutProps = { appCode: AppCode; baseRoute: string; dateFieldName: string; 'data-testid': string };

export const EmailDeliveryLayout: React.FC<EmailDeliveryLayoutProps> = ({
  appCode,
  baseRoute,
  dateFieldName,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { view } = useParams<{ view: EmailDeliveryView }>();
  const Comp = getEmailDeliveryRoute(view)?.component;
  const navigate = useNavigate();

  const handleNav = useCallbackImmutable((_, value: EmailDeliveryView) => {
    navigate(generatePath(baseRoute, { view: value }));
  });

  if (!Comp) {
    return null;
  }

  return (
    <AppLayout>
      <AppContent variant="expanded-noscroll">
        <Comp
          appCode={appCode}
          dateFieldName={dateFieldName}
          navElement={
            <ToggleButtonGroup.Uncontrolled
              exclusive
              list={routesOptions}
              onChange={handleNav}
              value={view}
              size="xsmall"
              data-testid={createTestid('nav')}
            />
          }
          data-testid={createTestid(view)}
        />
      </AppContent>
    </AppLayout>
  );
};
