import { convertToDateLocal, fileExtension, lowerCase, useImmutable } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { isAxiosError } from 'mns-sdk-collect';
import { toast } from 'react-toastify';
import type { BlobTransform } from '../common/utils';
import { downloadFile } from '../common/utils';
import { appCollectApi } from '../store/apis';

const dateRegexp = /(20\d\d-\d\d-\d\d).csv/;

const getDateFromUrl = (url: string) => url.match(dateRegexp)?.[1];

type UseDownloadPresignedUrlProps = CollectApi.DownloadUrlRequest & {
  fileDownloadTypeLabel: string;
  fileName: string;
  fileDate?: string;
};

// TODO: Quickfix until Back sends error code
const ERRCODE_MULTIPLEFUNDSINFILE =
  'You are unable to download this input file as the asset manager has uploaded multiple funds in one file. Please ask the asset manager to upload this fund in a separate file to download the input file. ';
const mapErrCode: Record<string, string | undefined> = {
  [ERRCODE_MULTIPLEFUNDSINFILE]:
    'You are unable to download this input file as the AM has uploaded multiple funds in one file. Please ask the AM to upload this fund in a separate file to download the input file.',
};

export const dlTypeMapping: Record<CollectApi.ShareclassFileType | CollectApi.FileType, string> = {
  input: 'ORIGINAL',
  error_log: 'ERRORLOG',
  shared_error_log: 'ERRORLOG',
  output: 'TPT',
  look_through: 'LOOKTHROUGH',
};

const getDownloadFileName = (
  fileName: string,
  dlType: CollectApi.ShareclassFileType | CollectApi.FileType,
  extensionType: string,
  fileDate?: string | null,
) => {
  const nameDlType = dlTypeMapping[dlType];
  if (!nameDlType) throw new Error('Download type does not exist');

  if (fileDate) {
    //case : portfolio (insert navDate)
    const newFileDate = convertToDateLocal(fileDate);
    return `${fileName}_${newFileDate}_${nameDlType}.${extensionType}`;
  } else {
    //case : file (remove file extension)
    const dotExt = `.${extensionType}`;
    const prefixName = fileName.toLowerCase().endsWith(dotExt)
      ? fileName.substring(0, fileName.length - dotExt.length)
      : fileName;
    return `${prefixName}_${nameDlType}.${extensionType}`;
  }
};

const getPresignedUrlFileName = (
  { url, fileFormat }: CollectApi.PresignedUrl,
  { fileDownloadType, fileName, fileDate }: UseDownloadPresignedUrlProps,
) => {
  const extensionType = fileFormat?.toLowerCase() ?? fileExtension(new URL(url).pathname)?.toLowerCase() ?? 'csv';
  const fileDateString = fileDate ? fileDate : getDateFromUrl(url);
  const formatedName = getDownloadFileName(fileName, fileDownloadType, extensionType, fileDateString);
  return formatedName;
};

export const useDownloadPresignedUrl = () =>
  useImmutable(
    () =>
      async (
        queryParams: UseDownloadPresignedUrlProps,
        raiseError = true,
        downloadCb?: BlobTransform,
      ): Promise<boolean> => {
        const dlLabel = queryParams.fileDownloadTypeLabel;
        try {
          const files = await appCollectApi.getDownloadUrl(queryParams);

          for (const file of files) {
            const downloadRename = file.fileName || getPresignedUrlFileName(file, queryParams);
            try {
              await downloadFile(file.url, downloadRename, downloadCb);
            } catch (err) {
              console.error(err);

              if (raiseError) {
                toast(`An error has occured while downloading ${downloadRename}`, { type: 'error' });
              }

              return false;
            }
          }
        } catch (error) {
          if (raiseError) {
            if (isAxiosError(error)) {
              const errCode = error.response?.data.message;
              const errMessage = mapErrCode[errCode];
              if (errMessage) {
                toast(
                  `An error has occured while downloading ${queryParams.fileName} ${lowerCase(dlLabel)}: ${errMessage}`,
                  {
                    type: 'error',
                  },
                );
                return false;
              }
            }

            if (queryParams) {
              toast(`An error has occured while downloading ${queryParams.fileName} ${lowerCase(dlLabel)}`, {
                type: 'error',
              });
            } else {
              toast('An error occured', { type: 'error' });
            }
          }

          return false;
        }

        return true;
      },
  );
