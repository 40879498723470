import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import { AggridTable, useDefaultColDef, useAutoSize, useImmutable, useCallbackImmutable } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import type { MutableRefObject } from 'react';
import { useMemo } from 'react';
import { extractorPreferencesStyles as useStyles } from '../styles/extractorPreferencesStyles';
import {
  ExtractorPreferencesListAutomatedToggler,
  isAutomatedTogglerClickable,
} from './ExtractorPreferencesListAutomatedToggler';

type UseColumnsProps = {
  buttonsDisabled?: boolean;
  onToggleAutomatedRequestStatus: (appCode: string) => void;
  'data-testid': string;
};

const useColumns = ({ buttonsDisabled, onToggleAutomatedRequestStatus, 'data-testid': testid }: UseColumnsProps) =>
  useMemo(
    (): AggridTableColumn<DataExtractorApi.Preference.Preference>[] => [
      { field: 'providerName', headerName: 'Provider' },
      { field: 'applicationName', headerName: 'Application' },
      { field: 'offerName', headerName: 'Subscription details' },
      {
        field: 'remainingNumber',
        headerName: 'Nb underlying data requests authorized',
        comparator: (first, second) => {
          if (first === null) return 1;
          if (second === null) return -1;
          if (first < 0) return -1;
          if (second < 0) return 1;
          return second - first;
        },
        cellRendererFramework: ({ value }) => {
          if (value === null) return '';
          if (value < 0) return 'Unlimited';
          return value;
        },
        sort: 'asc',
      },
      {
        field: 'automatedRequestStatus',
        headerName: 'Automated Request Status',
        cellRendererFramework: function ActionCell({
          data: { automatedRequestStatus, applicationCode, remainingNumber },
        }) {
          return (
            <ExtractorPreferencesListAutomatedToggler
              automatedRequestStatus={automatedRequestStatus}
              remainingNumber={remainingNumber}
              buttonsDisabled={buttonsDisabled}
              onClick={useCallbackImmutable(() => onToggleAutomatedRequestStatus(applicationCode))}
              data-testid={`${testid}-${applicationCode}-toggler-automatedRequestStatus-`}
            />
          );
        },
      },
    ],
    [buttonsDisabled, onToggleAutomatedRequestStatus, testid],
  );

export type ExtractorPreferencesListProps = {
  rows: DataExtractorApi.Preference.Preference[];
  rowsSelectedRef?: MutableRefObject<DataExtractorApi.Preference.Preference[]>;
  getGridApiRef?: MutableRefObject<GridApi | null> | ((api: GridApi | null) => void);
  searchValue?: string;
  buttonsDisabled?: boolean;
  onToggleAutomatedRequestStatus(appCode: string): void;
  'data-testid': string;
};

export const ExtractorPreferencesList: React.FC<ExtractorPreferencesListProps> = ({
  rows,
  getGridApiRef,
  searchValue,
  buttonsDisabled,
  onToggleAutomatedRequestStatus,
  'data-testid': testid,
}) => {
  const classes = useStyles();

  return (
    <AggridTable
      rowData={rows}
      defaultColDef={useDefaultColDef<DataExtractorApi.Preference.Preference>()}
      columnDefs={useColumns({
        onToggleAutomatedRequestStatus,
        buttonsDisabled,
        'data-testid': testid,
      })}
      getRowNodeId={useImmutable(() => (row: DataExtractorApi.Preference.Preference) => row.applicationCode)}
      quickFilterText={searchValue}
      getGridApiRef={getGridApiRef}
      isRowSelectable={useCallbackImmutable(
        ({ data: { remainingNumber } }: { data: DataExtractorApi.Preference.Preference }) =>
          isAutomatedTogglerClickable(remainingNumber, buttonsDisabled),
      )}
      rowClassRules={{
        [classes.unavailable]: ({ data: { remainingNumber } }) => !isAutomatedTogglerClickable(remainingNumber),
      }}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      data-testid={testid}
    />
  );
};
