import { colors, gradient, makeStyles } from 'mns-components';

export const marketplaceTCsModalStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
  },
  title: {
    textAlign: 'center',
    margin: '0',
    padding: '2rem',
    backgroundColor: colors.primaryColor,
    color: colors.whiteColor,
    borderTopLeftRadius: '1.5rem',
    borderTopRightRadius: '1.5rem',
  },
  container: {
    maxHeight: '100%',
    overflowY: 'scroll',
    width: '37.5rem',
    border: 'none',
    borderRadius: '1.75rem 1.75rem 1.5rem 1.5rem',
    outline: 'none',
    background: colors.whiteColor,
    zIndex: 1,
  },
  titlePrimary: { backgroundColor: colors.primaryColor },
  titleSecondary: { background: gradient.gradientPink },
  titleSuccess: { backgroundColor: colors.successColor },
  titleInfo: { backgroundColor: colors.infoColor },
  titleWarning: { backgroundColor: colors.warningColor },
  titleDanger: { backgroundColor: colors.dangerColor },
  termsContainer: {
    maxHeight: '25rem',
    overflowY: 'scroll',
    padding: '1rem 1.5rem',
  },
  link: {
    color: 'black',
    fontWeight: 'bold',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '1rem auto',
    '& > button': {
      margin: '0 1rem',
    },
  },
  checkbox: {
    margin: '0.2rem 1rem 0 1rem',
  },
  infoParagraph: {
    fontWeight: 'bold',
    color: colors.dangerColor,
    margin: '1rem',
  },
  paragraphs: {
    padding: '0 1rem .5rem 1rem',
  },
  hrStyle: {
    background: 'black',
    opacity: 0.3,
  },
  infoWarningLogout: {
    padding: '0 0.7rem',
    '& > svg': {
      color: colors.warningColor,
      height: '1.2rem',
      position: 'relative',
      top: '0.2rem',
      paddingRight: '0.2rem',
    },
    '& > span': {
      fontSize: '.8rem',
    },
  },
});
