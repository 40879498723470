import type { CollectApi } from 'mns-sdk-collect';
import type { RouterInterface } from '../../components/routing/RouterInterface';
import { Composition } from './composition/Composition';

const rawRoutes = [
  {
    label: 'Portfolio composition',
    link: '/portfolios/uploaded/:id/:date/composition',
    component: Composition,
    key: 'portfolios-lists-composition',
  },
] as const;

export const getRoute = (key: typeof rawRoutes[number]['key']) =>
  rawRoutes.find((route) => route.key === key) as RouterInterface<{
    portfolio: CollectApi.Portfolio;
    ptfList: CollectApi.Portfolio[];
    'data-testid': string;
  }>;

export const getPortfolioViewRoute = getRoute;

export const routes = Object.values(rawRoutes) as RouterInterface<{
  portfolio: CollectApi.Portfolio;
  ptfList: CollectApi.Portfolio[];
  'data-testid': string;
}>[];
