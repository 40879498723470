import { Box } from '@mui/material';
import type { FormProps } from 'mns-components';
import { Button, Typography, colors, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCognitoAuthError, isCognitoUser, useAuthUser } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { ConfirmPasswordField, ErrorBox, NewPasswordField, SuccessRedirectLayout, UsernameField } from './Forms';
import { useHandleResetPassword } from './hooks';
import { routes } from './routes';
import type { ChangePasswordFormData } from './types';
import { getPasswordFormErrors } from './utils';

type ChangePasswordInputsProps = Omit<FormProps<ChangePasswordFormData>, 'children'> & { error?: Error };

const ChangePasswordInputs: React.FC<ChangePasswordInputsProps> = ({
  error,
  defaultValues: { username } = {},
  'data-testid': testid,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const createTestid = useTestid(testid);

  const {
    setValue,
    formState: { isSubmitting },
  } = useFormContext<ChangePasswordFormData>();

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (username) {
      setValue('username', username);
    }
  }, [setValue, username]);

  return (
    <>
      <ErrorBox error={errorMessage} data-testid={createTestid('error-verification-code')} />
      <UsernameField data-testid={createTestid('username')} disabled />
      <NewPasswordField data-testid={createTestid('newPassword')} />
      <ConfirmPasswordField data-testid={createTestid('confirmPassword')} />
      <Box margin="0.5rem">
        <Button color="secondary" type="submit" loading={isSubmitting} data-testid={createTestid('submit')}>
          Change your password
        </Button>
      </Box>
    </>
  );
};

const ChangePasswordForm = makeForm<ChangePasswordFormData, ChangePasswordInputsProps>(ChangePasswordInputs);

export const ChangePassword: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [cognitoUser] = useAuthUser();
  const [hasSucceded, handleResetPassword] = useHandleResetPassword(routes.appEntryUri.path);
  const handleValidation = useCallbackImmutable(getPasswordFormErrors);
  if (!isCognitoAuthError(cognitoUser) && hasSucceded) {
    return <SuccessRedirectLayout data-testid={testid}></SuccessRedirectLayout>;
  }

  return (
    <AuthPageLayout data-testid={testid}>
      <Box margin="0.5rem">
        <Typography variant="h1">Change your password</Typography>
        <Typography variant="body1" margin="1rem 0" color={colors.disabledColor}>
          It is your first sign in attempt, please change your password.
        </Typography>
      </Box>
      <ChangePasswordForm
        error={isCognitoAuthError(cognitoUser) ? cognitoUser : undefined}
        defaultValues={
          isCognitoUser(cognitoUser)
            ? { username: cognitoUser.getUsername(), confirmPassword: '', newPassword: '' }
            : undefined
        }
        onSubmit={handleResetPassword}
        onValidate={handleValidation}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
