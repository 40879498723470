import { StatusTag } from 'mns-components';
import React from 'react';

interface Props {
  value: string | undefined;
  'data-testid': string;
}

export const AnalysisListStatusRenderer: React.FC<Props> = React.memo(function AnalysisListStatusRenderer({
  value,
  'data-testid': testid,
}: Props) {
  switch (value?.toLowerCase()) {
    case 'loading':
    case 'upload_in_progress':
      return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

    case 'canceled':
    case 'failed':
      return <StatusTag variant="error" label="Failed" data-testid={testid} />;

    case 'rejected':
      return <StatusTag variant="error" label="Rejected" data-testid={testid} />;

    case 'on_going':
    case 'processing':
      return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

    case 'pending':
      return <StatusTag variant="warning" label="Pending" data-testid={testid} />;

    case 'complete':
      return <StatusTag variant="success" label="Completed" data-testid={testid} />;

    case 'ok':
    case 'warning':
    case 'accepted':
      return <StatusTag variant="success" label="Accepted" data-testid={testid} />;

    default:
      return <StatusTag variant="error" label="Failed" data-testid={testid} />;
  }
});
