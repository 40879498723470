import { IconButton, Menu, Stack, Tooltip } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { downloadFilesRendererStyles as useStyles } from './styles/DownloadFilesRenderer.styles';

const downloadShareclassMapping: {
  dlType: CollectApi.ShareclassFileType;
  label: string;
  onlyFullTPT?: boolean;
}[] = [
  {
    dlType: 'input',
    label: 'Input File',
    onlyFullTPT: true,
  },
  {
    dlType: 'shared_error_log',
    label: 'View Error Log',
    onlyFullTPT: true,
  },
  {
    dlType: 'output',
    label: 'Processed File',
  },
];

const onlyFullTPTTooltip =
  'When answering the access request the Asset Manager has selected a specific template that is not the full TPT therefore you can only access the processed file for this fund.';

const DownloadShareclassMenu: React.FC<{
  buttonData: typeof downloadShareclassMapping[number];
  disabled: boolean;
  tooltip: string;
  onDownload: DownloadShareclassRendererProps['onDownload'];
  'data-testid': string;
}> = ({ buttonData, disabled, tooltip, onDownload, 'data-testid': testid }) => (
  <Tooltip title={tooltip} placement="left">
    <Stack>
      <MenuItem disabled={disabled} value={buttonData.dlType} onClick={onDownload} data-testid={testid}>
        {buttonData.label}{' '}
      </MenuItem>
    </Stack>
  </Tooltip>
);

type DownloadShareclassRendererProps = {
  processId?: CollectApi.Portfolio['latestVersion']['processId'];
  name: CollectApi.Portfolio['name'];
  sharedScope?: CollectApi.ScopeToShare;
  onDisplayHistory(): void;
  onDisplayDetails(): void;
  onDownload(ev: AnyObject, dlType: CollectApi.ShareclassFileType): void;
  'data-testid': string;
};

export const DownloadShareclassRenderer: React.FC<DownloadShareclassRendererProps> = ({
  processId,
  name,
  sharedScope,
  onDisplayHistory,
  onDisplayDetails,
  onDownload,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  return (
    <>
      <div
        className={classes.bulkContainer}
        data-filename={name}
        data-proccessid={processId}
        data-testid={createTestid(`menubutton`)}
      >
        <IconButton color="secondary" onClick={handleClick} data-testid={createTestid(`button-menubutton`)}>
          <Icon.More data-testid={createTestid(`icon-menubutton`)} />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-filename={name}
        data-proccessid={processId}
        data-testid={createTestid('menu')}
      >
        {downloadShareclassMapping.map((buttonData) => {
          const isNotFullTPT = !!buttonData?.onlyFullTPT && !!sharedScope && sharedScope !== 'FULL_TPT';
          return (
            <DownloadShareclassMenu
              key={buttonData.dlType}
              buttonData={buttonData}
              disabled={!processId || isNotFullTPT}
              onDownload={onDownload}
              tooltip={isNotFullTPT ? onlyFullTPTTooltip : ''}
              data-testid={createTestid(`download-${buttonData.dlType}`)}
            />
          );
        })}
        <hr className={classes.separator} />
        <MenuItem onClick={onDisplayHistory} data-testid={createTestid('display-history')}>
          View History
        </MenuItem>
        <MenuItem onClick={onDisplayDetails} data-testid={createTestid('display-details')}>
          Show details
        </MenuItem>
      </Menu>
    </>
  );
};
