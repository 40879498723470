import {
  isObject,
  objectHas,
  convertToDateLocal,
  makeOpenModalBoolean,
  makeQueryPrefetch,
  parallel,
  objectMerge,
  fileExtension,
  readCsv,
  filterTruthy,
} from 'mns-components';
import type { AnalysisApi, EetApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import type { QueryObserverOptions, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { currentMonthLastDate, firstDateAvailable, wait } from '../../common/date';
import type { UseDownloadQueryResult } from '../../common/useDownloadFile';
import { useQueriesMemo } from '../../common/useDownloadFile';
import type { RouterInterface } from '../../components/routing/RouterInterface';
import type { AppCode } from '../../components/views/appDescriptions';
import { useUser } from '../../hooks/useAuth';
import { useEmailDelivery } from '../../hooks/useUserSettings';
import { api } from '../../store/api';
import { eetApi, appCollectApi, analysisApi } from '../../store/apis';
import { getRoute, routes } from './routes';

export const [useFetchEet, usePrefetchEet] = makeQueryPrefetch((eetId?: string) => [
  ['eetApi.getEet', eetId],
  () => eetApi.getEet(eetId!),
  {
    enabled: !!eetId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  },
]);

export const useCreateEet = () => {
  const queryClient = useQueryClient();
  return useMutation(['eetApi.createEet'], (props: EetApi.UpdateEetDateRequest) => eetApi.createEet(props), {
    onSuccess: (data) => {
      queryClient.setQueryData(['eetApi.getEet', data.id], data);
      queryClient.invalidateQueries(['eetApi.getEetFiles']);
      api.eet.getEetFiles.invalidateQuery(queryClient);
    },
  });
};

export const useUpdateEetDate = (eetId?: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['eetApi.updateEetGeneralReferenceDate', eetId],
    (props: EetApi.UpdateEetDateRequest) => {
      if (eetId) return eetApi.updateEetGeneralReferenceDate(eetId, { ...props });
      throw new Error('Please set eetId');
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['eetApi.getEet', eetId], data);
      },
    },
  );
};

export const useUpdateEetLink = (eetId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['eetApi.updateEetLinkedFile', eetId],
    (props: Pick<EetApi.UpdateEetLinkRequest, 'linkedFileId' | 'type'>) =>
      eetApi.updateEetLinkedFile(eetId, { ...props }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['eetApi.getEet', eetId], data);
      },
    },
  );
};

export const useUpdateEetPortfolios = (eetId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['eetApi.updateEetPortfoliosSelected', eetId],
    (portfolios: EetApi.Portfolio[]) => eetApi.updateEetPortfoliosSelected(eetId, portfolios),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['eetApi.getEet', eetId], data);
      },
    },
  );
};

export const useUpdateEetProviders = (eetId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['eetApi.updateEetProviders', eetId],
    async (props: EetApi.UpdateEetProvidersRequest<AppCode>) => {
      await eetApi.updateEetProviders(eetId, props);
      return eetApi.triggerEetAnalysis(eetId);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['eetApi.getEet', eetId], data);
        queryClient.invalidateQueries(['eetApi.getGenerationPortfoliosStatus', eetId]);
      },
    },
  );
};

export const useGenerateEetAnalysis = (eetId: string) => {
  const queryClient = useQueryClient();
  return useMutation(['eetApi.triggerEetAnalysis', eetId], () => eetApi.triggerEetAnalysis(eetId), {
    onSuccess: parallel(
      (data: EetApi.Eet<AppCode>) => queryClient.setQueryData(['eetApi.getEet', eetId], data),
      () => queryClient.invalidateQueries(['eetApi.getGenerationPortfoliosStatus', eetId]),
    ),
  });
};

export const useGenerateEetFile = (eetId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['eetApi.generateEetFile', eetId],
    () => Promise.any([eetApi.generateEetFile(eetId), wait(1000)]),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['eetApi.getEetFiles']);
        api.eet.getEetFiles.invalidateQuery(queryClient);
      },
    },
  );
};

export const useEmtFilesRequest = ({
  status,
  referenceDate,
  qualities,
}: Pick<Parameters<typeof appCollectApi.getFiles>[0], 'referenceDate' | 'status' | 'qualities'> = {}) =>
  useQuery(['appCollectApi.getEmtFilesFiltered', status, referenceDate, qualities], async () => {
    const response = await appCollectApi.getFiles({
      startDate: convertToDateLocal(firstDateAvailable),
      endDate: convertToDateLocal(currentMonthLastDate),
      dataFormats: ['EMT'],
      status,
      referenceDate,
      qualities,
    });
    return response.filter(
      (emtFile) =>
        (!status || emtFile.status === status) &&
        (!referenceDate || emtFile.referenceDate === referenceDate) &&
        (!qualities ||
          (emtFile.qualities as AnyArray<'BASIC' | 'REGULATORY'>).find((quality) => qualities.includes(quality))),
    );
  });

export const useEetLightFilesRequest = ({
  status,
  referenceDate,
  qualities,
}: Pick<Parameters<typeof appCollectApi.getFiles>[0], 'referenceDate' | 'status' | 'qualities'> = {}) =>
  useQuery(['appCollectApi.getEetFilesFiltered', status, referenceDate, qualities], async () => {
    const response = await appCollectApi.getFiles({
      startDate: convertToDateLocal(firstDateAvailable),
      endDate: convertToDateLocal(currentMonthLastDate),
      dataFormats: ['EET'],
      status,
      referenceDate,
      qualities,
    });
    return response.filter((eetFile) => {
      return (
        (!status || eetFile.status === status) &&
        (!referenceDate || eetFile.referenceDate === referenceDate) &&
        (!qualities ||
          (eetFile.qualities as AnyArray<'BASIC' | 'REGULATORY'>).find((quality) => qualities.includes(quality)))
      );
    });
  });

export const useEetEsgAppsRequest = (eetId: string) =>
  useQuery(['eetApi.getEetEsgApps', eetId], () => eetApi.getEetEsgApps(eetId));

export const useEetPortfoliosRequest = (eetId: string) =>
  useQuery(['eetApi.getPortfolios', eetId], () => eetApi.getPortfolios(eetId));

export const useGenerationPortfoliosStatus = (eetId: string, expectedExternalIds: string[]) => {
  // this hack helps to know if request is still refetching or retrying. ReactQuery recommends to
  // use `isIdle` to know if a refetch is happening, but the current code of ReactQuery set request
  // as `"idle"` only if request did not happened yet, and no other attribute is available.
  // https://tanstack.com/query/latest/docs/react/reference/useQuery
  const [isRunning, setIsRunning] = useState(false);

  const response = useQuery(
    ['eetApi.getGenerationPortfoliosStatus', eetId],
    () => {
      setIsRunning(true);
      return eetApi.getGenerationPortfoliosStatus(eetId);
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: (portfoliosStatus) => {
        // if empty, please refetch
        if (!portfoliosStatus) {
          return 3000;
        }

        // if response does not contains every expected ptfs, please refetch
        const loaded = portfoliosStatus.map(({ externalId }) => externalId);
        if (expectedExternalIds?.find((extId) => !loaded.includes(extId))) {
          return 3000;
        }

        // if response contains a pending analysis, please refetch
        if (
          portfoliosStatus?.find(
            (status) =>
              status.sfdrApp === 'pending' ||
              status.taxonomyApp === 'pending' ||
              status.shareSustainableInvestmentApp === 'pending',
          )
        ) {
          return 3000;
        }

        setIsRunning(false);
        return false;
      },
      retry: (failureCount) => {
        if (failureCount < 3) return true;
        else {
          setIsRunning(false);
          return false;
        }
      },
    },
  );
  return objectMerge<UseQueryResult<EetApi.PortfolioStatus[], unknown> & { isRunning: boolean }>({}, response, {
    isRunning,
  });
};

export const useEetRoutes = (appCode: AppCode) => {
  const { apps: roles } = useUser();
  const emailDeliveryActive = useEmailDelivery(appCode);
  return useMemo(() => {
    const rules = {
      emailDeliveryActive,
    };

    return routes.reduce((acc, rawRoute) => {
      const role = rawRoute.expectRole;
      if (!role) {
        acc.push(rawRoute);
      } else if (objectHas(rules, role)) {
        const value = rules[role];
        if (isObject(value)) {
          acc.push({
            ...rawRoute,
            linkDefaultArgs: value,
          });
        } else if (value) {
          acc.push(rawRoute);
        }
      } else if (roles.includes(role)) {
        acc.push(rawRoute);
      }
      return acc;
    }, [] as RouterInterface[]);
  }, [emailDeliveryActive, roles]);
};

export const [useUploadModalOpen, useManageUploadModalOpen] = makeOpenModalBoolean(
  () => getRoute('eet-uploads').link,
  'openUploadModal',
);

export const useFetchEetFilesOld = <TSelect extends EetApi.EetFile<AppCode>[]>(
  options: UseQueryOptions<EetApi.EetFile<AppCode>[], unknown, TSelect, AnyArray> = {},
) =>
  useQuery(['eetApi.getEetFiles'], eetApi.getEetFiles, {
    refetchIntervalInBackground: true,
    refetchInterval: (data) => (data?.find((eet) => eet.status === 'PROCESSING') ? 3000 : false),
    ...options,
  });

export const useFetchEetFiles = <TSelect extends EetApi.EetFile<AppCode>[]>(
  options: UseQueryOptions<EetApi.EetFile<AppCode>[], unknown, TSelect> = {},
) =>
  api.eet.getEetFiles.useQuery({
    refetchIntervalInBackground: true,
    refetchInterval: (data) => (data?.find((eet) => eet.status === 'PROCESSING') ? 3000 : false),
    ...options,
  });

export const usePortfoliosAnalysis = <TSelect = AnalysisApi.AnalysisPortfolio<AppCode>[]>(
  appCode?: AppCode,
  options?: UseQueryOptions<AnalysisApi.AnalysisPortfolio<AppCode>[], unknown, TSelect, AnyArray>,
) =>
  useQuery(
    ['analysisApi.getAnalysisPortfolios', appCode, 'ALL'],
    () => analysisApi.getAnalysisPortfolios(appCode!, 'ALL'),
    { enabled: !!appCode, ...options },
  );

export const useAggregatedData = <TSelect = AnyObject<'id'>[]>(
  appCode?: AppCode,
  analysis?: AnalysisApi.AnalysisPortfolio<AppCode>,
  options?: UseQueryOptions<AnyObject<'id'>[], unknown, TSelect, AnyArray>,
) => {
  return useQuery(
    ['analysisApi.getEetAnalysisDocuments', appCode, analysis?.analysisId],
    async () => {
      const docs = await analysisApi.getEetAnalysisDocuments(
        [
          {
            externalId: analysis!.externalId,
            externalIdType: analysis!.externalIdType,
            valuationDate: analysis!.valuationDate,
          },
        ],
        [appCode!],
      );
      const url = docs.find((doc) => fileExtension(doc.key) === 'json')?.preSignedUrl;
      if (!url) throw new Error('Could not fetch csv file');
      const response = await fetch(url);
      if (!response.ok) throw new Error('Could not fetch file');
      return response.json();
    },
    { enabled: !!(appCode && analysis), ...options },
  );
};

export const useUnderlyingData = <TSelect = AnyObject<'id'>[]>(
  appCode?: AppCode,
  analysisId?: string,
  options?: UseQueryOptions<AnyObject<'id'>[], unknown, TSelect, AnyArray>,
) => {
  return useQuery(
    ['appProviderApi.downloadAnalysis', appCode, analysisId],
    async () => {
      const docs = await api.provider.download.downloadRequest.raw({
        appCode: appCode ?? 'data_collect',
        requestId: analysisId!,
      });
      const url = docs.find((doc) => fileExtension(doc.fileName) === 'csv')?.presignedUrl;
      if (!url) throw new Error('Could not fetch csv file');
      const response = await fetch(url);
      if (!response.ok) throw new Error('Could not fetch file');
      const [headers, ...rows] = readCsv(await response.text());
      return rows
        .map((cells) =>
          headers.reduce((acc, field, index) => {
            acc[field] = cells[index];
            return acc;
          }, {} as AnyObject),
        )
        .filter((row) => row.id);
    },
    { enabled: !!(appCode && analysisId), ...options },
  );
};

export const useAnalysisByAppCodes = (
  appCodes: AppCode[],
  options?: QueryObserverOptions<AnalysisApi.AnalysisPortfolio<AppCode>[]>,
) =>
  useQueriesMemo(
    api.provider.analysis.getAnalysisPortfolios.useQueries(appCodes.map((appCode) => [appCode, 'ALL'], options)),
    (results) =>
      filterTruthy(results).reduce((acc, query) => {
        const completeData = query?.filter((row) => row.status === 'complete');
        if (completeData?.length) {
          acc.push(...completeData);
        }
        return acc;
      }, [] as AnalysisApi.AnalysisPortfolio<AppCode>[]),
  );

export const useQueriesDownloadEet = (argsList: [string][]) =>
  api.eet.getDownloadUrl.useQueries(
    argsList.map(([id]) => ['eet', id]),
    { select: (data): UseDownloadQueryResult[] => [data] },
  );
