import { colors, makeStyles } from 'mns-components';

export const chaseUpSettingsStyles = makeStyles({
  twoColumns: {
    display: 'flex',
  },
  addLine: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    flex: '1 1 auto',
  },
  descrColumn: {
    flex: '0 1 auto',
    minWidth: 0,
    marginRight: '2rem',
  },
  wideColumn: {
    flex: '0 0 30rem',
  },
  info: {
    opacity: 0.6,
    paddingLeft: '1rem',
  },
  warn: {
    color: colors.dangerColor,
    paddingLeft: '1rem',
  },
  list: {
    margin: '.5rem',
  },

  settingsCategory: {
    marginTop: '3rem',
    marginBottom: '1.5rem',
  },
});
