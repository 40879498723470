import { EmailDeliveryLayout } from '../../../components/emailDelivery/EmailDeliveryLayout';
import { getDisseminationRoute } from '../routes';

export const EmailDelivery: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <EmailDeliveryLayout
    appCode="data_dissemination"
    dateFieldName="NAV Date"
    baseRoute={getDisseminationRoute('dissemination-email-delivery').link}
    data-testid={testid}
  />
);
