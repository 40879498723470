import { StatusTag } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect/dist';
import React from 'react';

interface Props {
  value: string;
  'data-testid': string;
}

const statusMap: Record<CollectApi.AccessDemandStatus, string> = {
  ACCEPTED: 'Accepted',
  REFUSED: 'Refused',
  REVOKED: 'Closed',
  CANCEL: 'Canceled',
  PENDING: 'Pending',
  INVITATION_SENT: 'Invitation sent',
};

export const EmitterPermissionsStatusRenderer: React.FC<Props> = ({ value, 'data-testid': testid }: Props) => {
  switch (value) {
    case 'ACCEPTED':
      return <StatusTag variant="success" label="Accepted" data-testid={testid} />;
    case 'REFUSED':
      return <StatusTag variant="error" label="Refused" data-testid={testid} />;
    case 'CLOSED':
      return <StatusTag variant="neutral" label="Closed" data-testid={testid} />;
    case 'CANCEL':
      return <StatusTag variant="error" label="Canceled" data-testid={testid} />;
    case 'PENDING':
      return <StatusTag variant="warning" label="Pending" data-testid={testid} />;
    case 'INVITATION_SENT':
      return <StatusTag variant="warning" label="Invitation sent" data-testid={testid} />;
    default:
      return <></>;
  }
};

export const emitterPermissionsStatusRenderPlain = ({ status }: CollectApi.AccessDemand) => {
  return statusMap[status] || '';
};
