import { Stack } from '@mui/material';
import { EmptyStateV2, useTestid } from 'mns-components';
import type { AppCode } from '../../views/appDescriptions';
import { CreateEmailStepperDissemination } from './CreateEmailStepperDissemination';
import { CreateEmailStepperEet } from './eet/CreateEmailStepperEet';
import { CreateEmailStepperFile } from './file/CreateEmailStepperFile';

type CreateEmailDeliveryStepperProps = {
  onClose(): void;
  appCode: AppCode;
  'data-testid': string;
};

const emailDeliveryByApps: Partial<Record<AppCode, React.FC<CreateEmailDeliveryStepperProps>>> = {
  data_dissemination: CreateEmailStepperDissemination,
  'manaos-eet': CreateEmailStepperEet,
  'manaos-productrangedissemination': CreateEmailStepperFile,
};

export const CreateEmailDeliveryStepper: React.FC<CreateEmailDeliveryStepperProps> = ({
  onClose,
  appCode,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const Component = emailDeliveryByApps[appCode];

  if (!Component) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          illustrationVariant="iconType"
          data-testid={createTestid('error-server')}
        />
      </Stack>
    );
  }

  return <Component onClose={onClose} appCode={appCode} data-testid={testid} />;
};
