import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn, LoadPaginatedEvent } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  snakeCase,
  useAutoSize,
  useDefaultColDef,
  makeActionColDef,
  useImmutable,
  Typo,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { MutableRefObject } from 'react';
import { useMemo } from 'react';
import { FundMappingListActions } from './FundMappingListActions';

type UseColumnsProps = Pick<FundMappingListProps, 'onUpdate' | 'onDelete' | 'data-testid'>;
const getActionColDef = makeActionColDef<CollectApi.FundMapping>();

const useColumns = ({ onUpdate, onDelete, 'data-testid': testid }: UseColumnsProps) => {
  return useMemo(
    (): AggridTableColumn<CollectApi.FundMapping>[] => [
      {
        field: 'internalCode',
        headerName: 'Internal code',
        flex: 1,
      },
      {
        field: 'fundId',
        headerName: 'Fund ID',
        cellRenderer: ({ value }) => value ?? <Typo variant="caption">No mapping</Typo>,
        flex: 1,
      },
      {
        field: 'updatedBy',
        headerName: 'Updated by',
        cellRenderer: ({ value, data: { createdBy } }) => value ?? createdBy,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: 'Last update',
        valueGetter: ({ data: { updatedAt, createdAt } }) =>
          updatedAt ? convertToDateTimeUTCWithTZ(updatedAt) : convertToDateTimeUTCWithTZ(createdAt),
        cellRendererFramework: ({ data: { updatedAt, createdAt } }) =>
          updatedAt ? convertToDateTimeLocal(updatedAt) : convertToDateTimeLocal(createdAt),
        flex: 1,
      },
      getActionColDef('fundId', 100, ({ data: mapping }) =>
        FundMappingListActions({
          mapping,
          onUpdate,
          onDelete,
          'data-testid': `${testid}-${snakeCase(mapping.internalCode)}`,
        }),
      ),
    ],
    [onUpdate, onDelete, testid],
  );
};

type FundMappingListProps = {
  onLoadPaginated: (param: LoadPaginatedEvent<CollectApi.FundMapping>) => void;
  search: string;
  gridApi: MutableRefObject<GridApi | null>;
  onUpdate(mapping: CollectApi.FundMapping): void;
  onDelete(mapping: CollectApi.FundMapping): void;
  'data-testid': string;
};

export const FundMappingList: React.FC<FundMappingListProps> = ({
  onLoadPaginated,
  search,
  onUpdate,
  onDelete,
  gridApi,
  'data-testid': testid,
}) => {
  const columns = useColumns({ onUpdate, onDelete, 'data-testid': testid });
  return (
    <AggridTable.ServerPaginated<CollectApi.FundMapping>
      onLoadPaginated={onLoadPaginated}
      quickFilterText={search}
      getRowNodeId={useImmutable(() => (row: CollectApi.FundMapping) => row.internalCode)}
      columnDefs={columns}
      defaultColDef={useDefaultColDef()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationPageSize={20}
      rowHeight={50}
      getGridApiRef={gridApi}
      data-testid={testid}
    />
  );
};
