import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useMemoBase,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import type { MoreDetailScheduledDeliveriesRendererProps } from './MoreDetailScheduledDeliveriesRenderer';
import { MoreDetailScheduledDeliveriesRenderer } from './MoreDetailScheduledDeliveriesRenderer';

const getActionColDef = makeActionColDef<ScheduledDeliveriesListData>();

type ScheduledDeliveriesListData = {
  deliveryName: string;
  isinListName?: string;
  isinListId?: string;
  emailListName: string;
  emailListId: string;
  createdAt: string;
  createdBy: string;
  enabled: boolean;
  id: string;
};

const map = (row: DataDeliveryApi.DataDeliveryScheduled): ScheduledDeliveriesListData => ({
  deliveryName: row.name,
  isinListName: row.scope.mode === 'CUSTOM' ? row.scope.isinListName : undefined,
  isinListId: row.scope.mode === 'CUSTOM' ? row.scope.isinListId : undefined,
  emailListName: row.emailingListName,
  emailListId: row.emailingListId,
  createdAt: row.createdAt,
  createdBy: row.createdBy,
  enabled: row.enabled,
  id: row.id,
});

type UseColumnsProps = Pick<
  MoreDetailScheduledDeliveriesRendererProps,
  'disabled' | 'onToggleEnable' | 'onEdit' | 'onDelete' | 'data-testid'
>;

const useColumns = ({ disabled, ...entry }: UseColumnsProps) =>
  useMemoBase(
    entry,
    (base): AggridTableColumn<ScheduledDeliveriesListData>[] => [
      { field: 'deliveryName', headerName: 'Schedule name' },
      {
        field: 'isinListId',
        headerName: 'Scope',
        cellRenderer: ({ data: { isinListName } }) => isinListName ?? 'All',
      },
      { field: 'emailListId', headerName: 'Recipients', cellRenderer: ({ data: { emailListName } }) => emailListName },
      {
        field: 'createdAt',
        sort: 'desc',
        headerName: 'Created on',
        valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
        cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
      },
      { field: 'createdBy', headerName: 'Created by' },
      getActionColDef('enabled', 180, ({ data: { id, enabled, isinListId, emailListId } }) => (
        <MoreDetailScheduledDeliveriesRenderer
          onToggleEnable={base.onToggleEnable}
          onEdit={base.onEdit}
          onDelete={base.onDelete}
          emailDeliveryId={id}
          isinListId={isinListId}
          emailListId={emailListId}
          enabled={enabled}
          disabled={disabled}
          data-testid={base['data-testid']}
        />
      )),
    ],
    [disabled],
  );

type ScheduledDeliveriesListProps = {
  search: string;
  rows: DataDeliveryApi.DataDeliveryScheduled[];
} & Pick<
  MoreDetailScheduledDeliveriesRendererProps,
  'disabled' | 'onToggleEnable' | 'onEdit' | 'onDelete' | 'data-testid'
>;

export const ScheduledDeliveriesList: React.FC<ScheduledDeliveriesListProps> = ({
  search,
  rows,
  'data-testid': testid,
  ...columnProps
}) => (
  <AggridTable
    quickFilterText={search}
    columnDefs={useColumns({ ...columnProps, 'data-testid': testid })}
    rowData={useMemo(() => rows.map(map), [rows])}
    getRowNodeId={useImmutable(() => (row: DataDeliveryApi.DataDeliveryScheduled) => row.id)}
    defaultColDef={useDefaultColDef<DataDeliveryApi.DataDeliveryScheduled>()}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
