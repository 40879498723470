import { Tooltip } from '@mui/material';
import { Icon, convertToDateLocal } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import useStyles from './chaseUpCountRendererStyles';

interface Props {
  value?: number;
  data: CollectApi.Dissemination;
  'data-testid': string;
}

const ChaseUpCountRendererComp: React.FC<Props> = ({ value, data, 'data-testid': testid }: Props) => {
  const classes = useStyles();

  if (value && value !== 0 && data.lastChaseUpDate) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <span className={classes.spanStyle}>{value}</span>
        <Tooltip
          title={`Last chase up ${convertToDateLocal(data.lastChaseUpDate)}`}
          arrow
          placement="top"
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <Icon.Info color="secondary" size="small" className={classes.infoIcon} data-testid={testid} />
        </Tooltip>
      </div>
    );
  }
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <span className={classes.spanStyle}>{value}</span>
    </div>
  );
};

export const ChaseUpCountRenderer = React.memo(ChaseUpCountRendererComp);
