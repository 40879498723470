import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import { Backdrop, EmptyStateV2, lowerCase, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { PortfoliosList } from './PortfoliosList';

type PortfoliosRequestProps = {
  ptfList: CollectApi.Portfolio[] | undefined;
  searchValue: string;
  organisationName: string;
  onRowSelected: React.Dispatch<React.SetStateAction<CollectApi.Portfolio[]>>;
  onOpenModal(): void;
  getGridApiref(api: GridApi | null): void;
  'data-testid': string;
};

export const PortfoliosRequest: React.FC<PortfoliosRequestProps> = ({
  ptfList,
  searchValue,
  organisationName,
  onRowSelected,
  onOpenModal,
  getGridApiref,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const filteredPtf = useMemo(
    () =>
      organisationName
        ? ptfList?.filter((ptf) => lowerCase(ptf.organisationName).includes(lowerCase(organisationName)))
        : ptfList,
    [organisationName, ptfList],
  );

  if (!ptfList) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (ptfList?.length === 0 || !filteredPtf || filteredPtf?.length === 0) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="noSearchResults"
          title="There is no portfolio in this nav date period yet!"
          subTitle="You can search again with a new range period or upload a new one."
          buttonText="Upload portfolio"
          buttonVariant="secondary"
          buttonIcon="upload"
          buttonOnClick={onOpenModal}
          data-testid={createTestid('empty-portfolio')}
        />
      </Stack>
    );
  }

  return (
    <PortfoliosList
      searchValue={searchValue}
      rowData={filteredPtf}
      onRowSelected={onRowSelected}
      getGridApiref={getGridApiref}
      data-testid={createTestid('list')}
    />
  );
};
