import { Stack } from '@mui/material';
import {
  AppContent,
  AppLayout,
  Backdrop,
  CardSelector,
  Divider,
  EmptyStateV2,
  useCallbackImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { ApplicationConfig } from '../../../common/getApplicationConfig';
import { getApplicationConfig } from '../../../common/getApplicationConfig';
import type { AppCode } from '../../../components/views/appDescriptions';
import { isAppCode } from '../../../components/views/appDescriptions';
import { useUserApps } from '../../../hooks/useAuth';
import { getAvailableDates, useLastApp, usePtfAndDate } from '../../../hooks/useGlobalState';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';
import { SelectorPtfAndDate } from '../../coverageApp/components/SelectorPtfAndDate';
import { getRoute as getMarketplaceRoute } from '../../marketplace/routes';
import { useUploadModalOpen } from '../../uploads/hooks';
import { useAppOptions } from '../hooks';
import { getEsgScoreOverviewPage } from '../routes';
import { DataExtractorApp } from './portfolioViewType/DataExtractorApp';
import { ErrorActivation } from './portfolioViewType/ErrorActivation';
import { PdfReaderApp } from './portfolioViewType/PdfReaderApp';
import { StateApp } from './portfolioViewType/StateApp';
import { TableKpisApp } from './portfolioViewType/TableKpisApp';
import { WidgetApp } from './portfolioViewType/WidgetApp';

export type ESGScoresOverviewType = {
  'data-testid': string;
};

export type PortfolioViewProps = {
  portfolio: CollectApi.Portfolio;
  ptfList: CollectApi.Portfolio[];
  pollingDelay?: number;
  'data-testid': string;
};

const providerComponents: Record<
  NonNullable<ApplicationConfig['config']['portfolioViewType']>,
  React.FC<PortfolioViewProps>
> = {
  widget: WidgetApp,
  pdf: PdfReaderApp,
  state: StateApp,
  tableWithKpis: TableKpisApp,
  extractor: DataExtractorApp,
};

export const ESGScoresOverview: React.FC<ESGScoresOverviewType> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const uploadModalOpen = useUploadModalOpen();

  // fetch data
  const roles = useUserApps();
  const { data: ptfList, isLoading: isLoadingPtfList } = useFetchAllPortfolios();

  // compute
  const widgetApps = useAppOptions(roles);
  const appCodes = widgetApps?.map(({ value }) => value);
  const { id, date, appCode } = useParams<{ id?: string; date?: string; appCode?: AppCode }>();
  const portfolio = ptfList?.find((ptf) => ptf.portfolioId === id);

  const [[portfolioId, nav], setPtfAndDate] = usePtfAndDate();
  const [lastApp, setLastApp] = useLastApp();

  const handleChangeApp = useCallbackImmutable((newAppCode: AppCode) => {
    if (id && date) {
      setLastApp(newAppCode);
      navigate(getEsgScoreOverviewPage(id, date, newAppCode));
    }
  });

  useEffect(() => {
    // URL not set
    if ((!id || !date || !appCode) && ptfList?.length && appCodes?.length) {
      if (portfolioId && nav) {
        // use local storage if set
        const firstApp = lastApp && appCodes.includes(lastApp) ? lastApp : appCodes[0];
        navigate(getEsgScoreOverviewPage(portfolioId, nav, firstApp));
      } else {
        // Cache not set, take the first portfolio and the first date
        const firstPtf = ptfList[0];
        const allDates = getAvailableDates(firstPtf);
        const firstApp = lastApp && appCodes.includes(lastApp) ? lastApp : appCodes[0];
        setPtfAndDate([firstPtf.portfolioId, allDates[0]]);
        navigate(getEsgScoreOverviewPage(firstPtf.portfolioId, allDates[0], firstApp));
      }
    }

    if (portfolioId && nav && ((portfolioId && portfolioId != id) || (nav && nav != date))) {
      navigate(getEsgScoreOverviewPage(portfolioId, nav, appCode!));
    }
  }, [appCode, appCodes, date, id, lastApp, nav, navigate, portfolioId, ptfList, setPtfAndDate]);

  if (isLoadingPtfList) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!ptfList?.length) {
    return (
      <AppLayout data-testid={testid}>
        <AppContent>
          <Stack flexGrow={1} alignItems="center" justifyContent="center">
            <EmptyStateV2
              useCase="upload"
              variant="info"
              title="Get started with your upload!"
              subTitle="To be able to trigger an ESG analysis, you need to upload a portfolio."
              buttonIcon="upload"
              buttonText="Upload"
              buttonVariant="secondary"
              buttonOnClick={uploadModalOpen}
              data-testid={createTestid('empty-portfolios')}
            />
          </Stack>
        </AppContent>
      </AppLayout>
    );
  }

  if (!appCode?.length || !widgetApps?.[0]) {
    return (
      <AppLayout data-testid={testid}>
        <AppContent>
          <Stack flexGrow={1} alignItems="center" justifyContent="center">
            <EmptyStateV2
              variant="info"
              useCase="noApps"
              title="There is no app subscription yet!"
              subTitle="Discover all the services available on our catalog and subscribe to your first app."
              buttonText="Discover our app catalog"
              buttonVariant="secondary"
              buttonHref={getMarketplaceRoute('marketplace-all').link}
              data-testid={createTestid('error-no-activation')}
            />
          </Stack>
        </AppContent>
      </AppLayout>
    );
  }

  if (!isAppCode(appCode) || !appCodes?.includes(appCode)) {
    return (
      <AppLayout data-testid={testid}>
        <AppContent>
          <Stack direction="row" gap="1rem" justifyContent="space-between" alignItems="center">
            <CardSelector
              options={widgetApps}
              onChange={handleChangeApp}
              data-testid={createTestid('appSelector')}
              label="Choose app"
              name="choose-app"
            />
            <SelectorPtfAndDate portfolios={ptfList} data-testid={createTestid('scopeSelector')} />
          </Stack>

          <Divider variant="default" />

          <Stack flexGrow={1} alignItems="center" justifyContent="center">
            {!isAppCode(appCode) ? (
              <EmptyStateV2
                variant="info"
                useCase="noActions"
                title="There are no apps with this name!"
                subTitle="Select another app from the dropdown list above."
                data-testid={createTestid('error-app-no-exist')}
              />
            ) : (
              <EmptyStateV2
                variant="info"
                useCase="noNeededApps"
                title="There is no app subsciption yet!"
                subTitle="For this use case, you need to subscribe to additional apps first."
                buttonText="Discover our app catalog"
                buttonHref={getMarketplaceRoute('marketplace-all').link}
                buttonVariant="secondary"
                data-testid={createTestid('error-no-app-subscription')}
              />
            )}
          </Stack>
        </AppContent>
      </AppLayout>
    );
  }

  const {
    config: { portfolioViewType },
  } = getApplicationConfig(appCode);

  if (portfolioViewType === undefined) {
    throw new Error('This app is not authorized to have a portfolio view');
  }

  const Comp = providerComponents[portfolioViewType] ?? ErrorActivation;

  return (
    <AppLayout data-testid={testid}>
      <AppContent>
        <Stack direction="row" gap="1rem" justifyContent="space-between" alignItems="center">
          <CardSelector
            options={widgetApps}
            onChange={handleChangeApp}
            defaultValue={appCode}
            data-testid={createTestid('appSelector')}
            label="Choose app"
            name="choose-app"
          />
          <SelectorPtfAndDate portfolios={ptfList} data-testid={createTestid('scopeSelector')} />
        </Stack>

        <Divider variant="default" />

        {portfolio && (
          <Comp
            key={`${id}-${date}-${appCode}`}
            portfolio={portfolio}
            ptfList={ptfList}
            data-testid={createTestid(appCode)}
          />
        )}
      </AppContent>
    </AppLayout>
  );
};
