import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import React from 'react';

type Props<T> = {
  value: string;
  data: T;
  setSelected: (selectedItems: T[]) => void;
  openManagePermissionsModal: (isOpen: boolean) => void;
  'data-testid': string;
};

export const ReceiverPermissionsActionsRenderer = <T extends AnyObject>({
  value,
  setSelected,
  openManagePermissionsModal,
  data,
  'data-testid': testid,
}: Props<T>) => {
  const createTestid = useTestid(testid);
  const handleClick = useCallbackImmutable(() => {
    setSelected([data]);
    openManagePermissionsModal(true);
  });

  switch (value) {
    case 'ACCEPTED':
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Close data-testid={createTestid('icon-revoke')} />}
          onClick={handleClick}
          data-testid={createTestid('btn-revoke')}
        >
          Revoke
        </Button>
      );
    case 'PENDING':
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Reply data-testid={createTestid('icon-reply')} />}
          onClick={handleClick}
          data-testid={createTestid('btn-reply')}
        >
          Reply
        </Button>
      );
    default:
      return <i />;
  }
};
