import { Stack, Tooltip } from '@mui/material';
import { HeaderComponentFramework, Icon, Typo, useCallbackImmutable } from 'mns-components';
import React from 'react';

const statusExplanation = [
  {
    status: 'Non-blocking',
    explanation: 'The file is accepted with non-blocking errors.',
  },
  {
    status: 'Blocking regulatory',
    explanation: 'The file is accepted with errors that prevent regulatory quality from being achieved.',
  },
  {
    status: 'Rejected',
    explanation:
      'The file is rejected due to blocking errors. Please check the error details by selecting the line in error or by downloading the Excel error log.',
  },
];

export const DialogAuditErrorLogListHeaderRenderer: React.FC<{ 'data-testid': string }> = React.memo(
  function DialogAuditErrorLogListHeaderRenderer({ 'data-testid': testid }) {
    return (
      <HeaderComponentFramework
        render={useCallbackImmutable(({ getLabelNode }) => (
          <Stack direction="row" gap="0.5rem" alignItems="center">
            {getLabelNode('Status')}
            <Tooltip
              placement="right-start"
              title={
                <Stack gap="0.5rem" padding="0.5rem">
                  {statusExplanation.map((item) => (
                    <Typo key={item.status} component="p" variant={'caption'}>
                      <Typo variant="captionmedium">{item.status}:</Typo> {item.explanation}
                    </Typo>
                  ))}
                </Stack>
              }
              arrow
            >
              <Icon.Info size="small" color="secondary" data-testid={`${testid}-icon-info`} />
            </Tooltip>
          </Stack>
        ))}
        data-testid={testid}
      />
    );
  },
);
