import { lowerCase, StatusTag } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React from 'react';

interface Props {
  value: DataExtractorApi.Report.Report['status'] | undefined;
  'data-testid': string;
}

export const ExtractionsGeneratedStatusRenderer: React.FC<Props> = React.memo(
  function ExtractionsGeneratedStatusRenderer({ value, 'data-testid': testid }: Props) {
    switch (value && lowerCase(value)) {
      case 'in_progress':
      case 'requested':
        return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

      case 'complete':
        return <StatusTag variant="success" label="Completed" data-testid={testid} />;

      default:
        return <StatusTag variant="error" label="Failed" data-testid={testid} />;
    }
  },
);
