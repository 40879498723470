import { Stack } from '@mui/material';
import { GuidedStep, Typography, Video, colors, makeStyles, useCallbackImmutable, useTestid } from 'mns-components';
import { getMarketplaceRoute } from '../../../applications/marketplace/routes';
import { useUploadModalOpen } from '../../../applications/uploads/hooks';
import { useGuidedTour } from '../../../hooks/useGuidedTour';
import { getSettingsRoute } from '../../settings/routes';
import { getViewsRoute } from '../../views/routes';
import type { GuidedTourScenarioBuild } from '../types';
import { getAsset, waitForNode } from '../utils';
import manaos from './../../../assets/images/application-catalog/manaos-logo-head.svg';

const logoStyles = makeStyles({
  manaos: {
    backgroundImage: `url(${manaos})`,
  },
});

type SubStepProps = {
  children: React.ReactNode;
  progressionValue: number;
  title: string;
  video: {
    title: string;
    width?: string;
    height?: string;
  };
  'data-testid': string;
};

function SubStep({ children, progressionValue, title, video, 'data-testid': testid }: Readonly<SubStepProps>) {
  const {
    goBack,
    goNext,
    goLast,
    state: { stepIndex },
  } = useGuidedTour('welcome');
  const createTestid = useTestid(testid);
  return (
    <GuidedStep.Normal
      title={title}
      progressionValue={progressionValue}
      progressionCount={4}
      onClose={goLast}
      onBack={goBack}
      onNext={goNext}
      data-testid={testid}
      data-stepindex={stepIndex}
    >
      <Stack spacing=".5rem">
        {children}
        <Video
          autoPlay
          loop
          muted
          height={video?.height}
          width={video?.width}
          sources={[{ src: getAsset('welcome', video.title), type: 'video/mp4' }]}
          alt="(your browser can not display the video)"
          data-testid={createTestid('video')}
        />
      </Stack>
    </GuidedStep.Normal>
  );
}

export const welcomeScenario: GuidedTourScenarioBuild = ({ navigate }) => [
  // Step 1
  {
    element: function Step({ 'data-testid': testid }) {
      const {
        goLast,
        goNext,
        state: { stepIndex },
      } = useGuidedTour('welcome');
      return (
        <GuidedStep.Center
          title="Welcome to Manaos"
          backLabel="Skip the tour"
          nextLabel="Start the tour"
          logoClassname={logoStyles().manaos}
          onBack={goLast}
          onNext={goNext}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Stack margin="1rem 0" spacing="1rem" alignItems="center">
            <Typography>
              Manaos is evolving day by day thanks{' '}
              <Typography variant="body1" component="span" fontWeight={600} color={colors.pinkColor}>
                to your feedback! 🎉
              </Typography>
            </Typography>
            <Typography>
              Discover our{' '}
              <Typography variant="body1" component="span" fontWeight={600}>
                guided tour.
              </Typography>
            </Typography>
            <Typography style={{ marginBottom: '1rem', marginTop: '0' }}>
              Thanks to it, you can review the main actions depending on where you are on the platform! In the coming
              months, these guided tours will be made available across the entire platform.
            </Typography>
          </Stack>
        </GuidedStep.Center>
      );
    },
    options: {
      beforeShowPromise: async () => {
        navigate(getViewsRoute('marketplace').path);
      },
      version: 2401,
    },
  },
  // Step 2
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={0}
        title="Your portfolios in one place (1/2)"
        video={{
          title: 'welcome-allYourPortfoliosInOnePlace-bulle1.mp4',
          width: '390px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Find the inventories you have{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            collected or uploaded
          </Typography>{' '}
          to Manaos. Your portfolios are organised in a table,{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            one portfolio per line.
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="appSideNavBar-myPortfoliosAndList"]', on: 'right-start' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('portfolios-lists').path);
        await waitForNode('[data-stepid="appSideNavBar-myPortfoliosAndList"]');
      },
    },
  },
  // Step 3
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={0}
        title="Your portfolios in one place (2/2)"
        video={{
          title: 'welcome-allYourPortfoliosInOnePlace-bulle2.mp4',
          width: '480px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          To find a portfolio on an earlier nav date, the date picker will help you find it and view{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            {"your portfolio's history."}
          </Typography>{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            The portfolio displayed will always be the latest available in the NAV date period selected.
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="portfolios-navDatePeriod"]', on: 'bottom' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('portfolios-lists').path);
        await waitForNode('[data-stepid="portfolios-navDatePeriod"]');
      },
    },
  },
  // Step 4
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={1}
        title="All your uploads in one section (1/3)"
        video={{
          title: 'welcome-allYourUploadsInOneSection-bulle1.mp4',
          width: '410px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Find all the files you have uploaded to Manaos and check their{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            status and qualities.
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="appSideNavBar-uploads"]', on: 'right-start' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('uploads').path);
        await waitForNode('[data-stepid="appSideNavBar-uploads"]');
      },
    },
  },
  // Step 5
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={1}
        title="All your uploads in one section (2/3)"
        video={{
          title: 'welcome-allYourUploadsInOneSection-bulle2.mp4',
          width: '530px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          For each file uploaded, you can download{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            the original file
          </Typography>{' '}
          by clicking on{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            {'the input file. (Bulk and single download)'}
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="uploads-dropdown-download"]', on: 'bottom-end' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('uploads').path);
        await waitForNode('[data-stepid="uploads-dropdown-download"]');
      },
    },
  },
  // Step 6
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={1}
        title="All your uploads in one section (3/3)"
        video={{
          title: 'welcome-allYourUploadsInOneSection-bulle3.mp4',
          width: '370px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          If your file has been rejected, the upload has failed or you wish to improve it, you can export{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            the error log. This document details the anomalies detected
          </Typography>{' '}
          in the input file and assigns{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            a degree of criticality.
          </Typography>{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            {'(Bulk and single download)'}
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="uploads-dropdown-download"]', on: 'bottom-end' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('uploads').path);
        await waitForNode('[data-stepid="uploads-dropdown-download"]');
      },
    },
  },
  // Step 7
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={2}
        title="The marketplace, your application library (1/3)"
        video={{
          title: 'welcome-theMarketplaceYourApplicationLibrary-bulle1.mp4',
          width: '430px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Find the{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            applications
          </Typography>{' '}
          you have{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            subscribed to.
          </Typography>{' '}
          {"To access them easily, don't hesitate to click on the "}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            heart icon.
          </Typography>
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="marketplace-subscribed"]', on: 'bottom-start' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('marketplace').path);
        await waitForNode('[data-stepid="marketplace-subscribed"]');
      },
    },
  },
  // Step 8
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={2}
        title="The marketplace, your application library (2/3)"
        video={{
          title: 'welcome-theMarketplaceYourApplicationLibrary-bulle2.mp4',
          width: '420px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Discover the{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            Coverage by Manaos
          </Typography>{' '}
          and test the{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            coverage of your inventories
          </Typography>{' '}
          on all our{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            ESG providers,
          </Typography>{' '}
          for free!
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="marketplaceCard-manaos-coverageestimation"]', on: 'top-start' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('marketplace').path);
        await waitForNode('[data-stepid="marketplaceCard-manaos-coverageestimation"]');
      },
    },
  },
  // Step 9
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={2}
        title="The marketplace, your application library (3/3)"
        video={{
          title: 'welcome-theMarketplaceYourApplicationLibrary-bulle3.mp4',
          width: '425px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Find{' '}
          <Typography variant="body2" component="span" fontWeight={600}>
            the applications available,
          </Typography>{' '}
          subscribe to new ones and supercharge your business routine!
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="marketplace-all"]', on: 'bottom-start' },
      beforeShowPromise: async () => {
        navigate(getMarketplaceRoute('marketplace-all').link);
        await waitForNode('[data-stepid="marketplace-all"]');
      },
    },
  },
  // Step 10
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={3}
        title="Set up your profile and organisation (1/3)"
        video={{
          title: 'welcome-setUpYourPorfileAndOrganisation-bulle1.mp4',
          width: '430px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Manage your{' '}
          <Typography variant="body2" component="span">
            profile, organisation and applications
          </Typography>{' '}
          from here.
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="appSideNavBar-settingsAccount"]', on: 'right-end' },
      beforeShowPromise: async () => {
        navigate(getSettingsRoute('settings-users').link);
        await waitForNode('[data-stepid="appSideNavBar-settingsAccount"]');
      },
    },
  },
  // Step 11
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={3}
        title="Set up your profile and organisation (2/3)"
        video={{
          title: 'welcome-setUpYourPorfileAndOrganisation-bulle2.mp4',
          width: '390px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          A new member in your team ? Easily{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            grant them access
          </Typography>{' '}
          {'to your organisation. (If you are not an admin user, resquest access to the Manaos support.)'}
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="accountUserInformation-userInfosTable"]', on: 'left-end' },
      beforeShowPromise: async () => {
        navigate(getSettingsRoute('settings-users').link);
        await waitForNode('[data-stepid="accountUserInformation-userInfosTable"]');
      },
    },
  },
  // Step 12
  {
    element: ({ 'data-testid': testid }) => (
      <SubStep
        progressionValue={3}
        title="Set up your profile and organisation (3/3)"
        video={{
          title: 'welcome-setUpYourPorfileAndOrganisation-bulle3.mp4',
          width: '410px',
        }}
        data-testid={testid}
      >
        <Typography variant="body2">
          Manage{' '}
          <Typography variant="body2" component="span" fontWeight={600} color={colors.pinkColor}>
            your notifications
          </Typography>{' '}
          to keep you informed of actions taken on Manaos.
        </Typography>
      </SubStep>
    ),
    options: {
      version: 2401,
      arrow: true,
      attachTo: { element: '[data-stepid="settings-notifications"]', on: 'bottom-start' },
      beforeShowPromise: async () => {
        navigate(getSettingsRoute('settings-notifications').link);
        await waitForNode('[data-stepid="settings-notifications"]');
      },
    },
  },
  // Step 13
  {
    element: function Step({ 'data-testid': testid }) {
      const {
        complete,
        goLast,
        state: { stepIndex },
      } = useGuidedTour('welcome');
      const onUploadPortfolio = useUploadModalOpen();
      return (
        <GuidedStep.Center
          title="Give it a go!"
          backLabel="Close"
          nextLabel="Upload portfolios"
          logoClassname={logoStyles().manaos}
          onBack={goLast}
          onNext={useCallbackImmutable(() => {
            complete();
            onUploadPortfolio();
          })}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Stack margin="1rem 0" spacing="1rem" alignItems="center">
            <Typography>Now that you know the basics of Manaos,</Typography>
            <Typography fontWeight={500} width="22rem">
              {"it's time to upload your first portfolio!"}
            </Typography>
          </Stack>
        </GuidedStep.Center>
      );
    },
    options: {
      version: 2401,
      beforeShowPromise: async () => {
        navigate(getViewsRoute('portfolios-lists').path);
      },
    },
  },
  // last step
  {
    element: function Step({ 'data-testid': testid }) {
      const {
        complete,
        state: { stepIndex },
      } = useGuidedTour('welcome');
      return (
        <GuidedStep.Tooltip title="Guided tours" onClose={complete} data-testid={testid} data-stepindex={stepIndex}>
          Find the contextual guided tours at any time by clicking this icon !
        </GuidedStep.Tooltip>
      );
    },
    options: {
      arrow: true,
      attachTo: { on: 'bottom', element: '[data-stepid="welcome-help-button"]' },
      beforeShowPromise: async () => {
        navigate(getViewsRoute('marketplace').path);
        await waitForNode('[data-stepid="welcome-help-button"]');
      },
      version: 2401,
    },
  },
];
