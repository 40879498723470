import { StatusTag } from 'mns-components';
import React from 'react';

interface CollectStatusRendererProps {
  receptionDate?: string;
  disseminationStatus?: string;
  'data-testid': string;
}

export const CollectStatusRenderer: React.FC<CollectStatusRendererProps> = ({
  receptionDate,
  disseminationStatus,
  'data-testid': testid,
}: CollectStatusRendererProps) => {
  const isCollected = disseminationStatus === 'DISSEMINATED';

  if (receptionDate !== undefined || isCollected) {
    return <StatusTag variant="success" label="Collected" data-testid={testid} />;
  }
  return <StatusTag variant="warning" label="Not collected" data-testid={testid} />;
};

export const collectStatusRendererPlain = ({
  receptionDate,
  disseminationStatus,
}: Pick<CollectStatusRendererProps, 'receptionDate' | 'disseminationStatus'>) => {
  const isCollected = disseminationStatus === 'DISSEMINATED';

  if (receptionDate !== undefined || isCollected) {
    return 'Collected';
  }
  return 'Not collected';
};
