import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import { AppContent, AppMargin, Divider, Typo, colors, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useRef, useState } from 'react';
import { FundMappingController } from './FundMappingController';
import { FundMappingRequest } from './FundMappingRequest';
import { FundMappingCreateModal } from './modals/FundMappingCreateModal';
import { FundMappingDeleteModal } from './modals/FundMappingDeleteModal';
import { FundMappingUpdateModal } from './modals/FundMappingUpdateModal';

type FundMappingSettingsPageProps = { 'data-testid': string };

export const FundMappingSettingsPage: React.FC<FundMappingSettingsPageProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const gridApi = useRef<GridApi | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [isOpenUpdateFundId, setIsOpenUpdateFundId] = useState<undefined | CollectApi.FundMapping>(undefined);
  const handleCloseUpdateFundId = useCallbackImmutable(() => {
    setIsOpenUpdateFundId(undefined);
  });

  const [isOpenDeleteFundId, setIsOpenDeleteFundId] = useState<null | CollectApi.FundMapping>(null);
  const handleCloseDeleteFundId = useCallbackImmutable(() => {
    setIsOpenDeleteFundId(null);
  });

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const handleOpenCreate = useCallbackImmutable(() => setIsOpenCreate(true));
  const handleCloseCreate = useCallbackImmutable(() => setIsOpenCreate(false));

  return (
    <AppContent variant="expanded" data-testid={testid}>
      <AppMargin>
        <Stack gap="1rem">
          <Stack>
            <Typo variant="body1medium">Internal code mapping</Typo>
            <Typo variant="body2" color={colors.darkGreyColor}>
              If you have loaded portfolios with identifiers specific to your organization, it is necessary to provide
              the corresponding public identifier (ISIN, SEDOL, CUSIP, &hellip;) before you can collect the
              corresponding inventories.
            </Typo>
          </Stack>
          <Divider variant="default" />
        </Stack>
      </AppMargin>
      <Stack data-testid={createTestid('frame')} gap="1rem" flexGrow={1}>
        <FundMappingController
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onCreate={handleOpenCreate}
          data-testid={createTestid('controller')}
        />
        <FundMappingRequest
          searchValue={searchValue}
          onUpdate={setIsOpenUpdateFundId}
          onDelete={setIsOpenDeleteFundId}
          gridApi={gridApi}
          data-testid={createTestid('list')}
        />
      </Stack>

      {/* Modals */}
      <FundMappingCreateModal
        open={isOpenCreate}
        onClose={handleCloseCreate}
        gridApi={gridApi}
        data-testid={createTestid('create-modal')}
      />
      <FundMappingUpdateModal
        fundMapping={isOpenUpdateFundId}
        open={!!isOpenUpdateFundId}
        onClose={handleCloseUpdateFundId}
        gridApi={gridApi}
        data-testid={createTestid('update-modal')}
      />
      <FundMappingDeleteModal
        fundMapping={isOpenDeleteFundId}
        onClose={handleCloseDeleteFundId}
        gridApi={gridApi}
        data-testid={createTestid('delete-modal')}
      />
    </AppContent>
  );
};
