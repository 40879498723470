import { Stack, Tooltip } from '@mui/material';
import type { OptionType } from 'mns-components';
import { Accordion, Icon, Select, Switch, TextField, isEmail, isPhone, useTestid } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useApplicationsRequest } from '../../../../applications/marketplace/hooks';
import { getApplicationConfig, getApplicationPipeTitleFromConfig } from '../../../../common/getApplicationConfig';
import { useUser } from '../../../../hooks/useAuth';
import { useIsinListsRequest } from '../../../isinList/hooks';
import { isAppCode } from '../../../views/appDescriptions';
import { modalStyles as useStyles } from '../styles/accountSettingsStyles';

type CreateUserInputsProps<T extends AccountApi.User | undefined, D = AccountApi.UserRequest> = {
  userData?: T;
  onSave(data: D): void;
  formId?: string;
  'data-testid': string;
};
interface IFormInput {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  mfa?: 'email' | 'sms';
  isAdmin: boolean;
  enabled: boolean;
  applications?: string[];
  scope?: string[];
}
export const UserModalInputs = <T extends AccountApi.User | undefined, D = AccountApi.UserRequest>({
  userData,
  onSave,
  formId,
  'data-testid': testid,
}: CreateUserInputsProps<T, D>): React.ReactElement => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const { data: isinLists } = useIsinListsRequest('USER_SCOPE');
  const userscopes = isinLists?.map((item) => ({ value: item.name, label: item.name }));

  const userScopes = useMemo(() => {
    const newScopeData: string[] = [];
    userData?.scope.forEach((item) => {
      const scopeObject = isinLists?.find((isin) => isin.id === item);
      if (scopeObject) newScopeData.push(scopeObject.name);
    });
    return newScopeData;
  }, [isinLists, userData?.scope]);

  const { organisationId: orgId } = useUser();

  const { data: applicationsData } = useApplicationsRequest();
  const applications = useMemo(
    () =>
      applicationsData?.reduce((acc, app) => {
        const appCode = app.codeApplication;
        if (app.subscriptionsDto?.status === 'ACTIVATED' && isAppCode(appCode)) {
          const appConfig = getApplicationConfig(appCode);
          const { role } = appConfig.config;
          if (role) {
            acc.push({ value: role, label: getApplicationPipeTitleFromConfig(appConfig) });
          }
        }
        return acc;
      }, [] as OptionType<string>[]) ?? [],
    [applicationsData],
  );

  const methods = useForm<IFormInput>({
    defaultValues: {
      username: userData?.username ?? '',
      firstName: userData?.name ?? '',
      lastName: userData?.familyName ?? '',
      email: userData?.email ?? '',
      phoneNumber: userData?.phoneNumber ?? '',
      isAdmin: userData?.isAdmin ?? false,
      enabled: userData?.enabled,
      mfa: (userData?.emailMfaEnabled && 'email') || (userData?.smsMfaEnabled && 'sms') || undefined,
      applications: userData?.applications ?? undefined,
    },
  });
  methods.setValue('scope', userScopes);

  const onSubmit = (data: IFormInput) => {
    const newScopeData: string[] = [];
    data.scope?.forEach((item) => {
      const scopeObject = isinLists?.find((isin) => isin.name === item);
      if (scopeObject) newScopeData.push(scopeObject.id);
    });

    const body = {
      username: data.username.trim(),
      name: data.firstName.trim(),
      familyName: data.lastName.trim(),
      email: data.email.trim(),
      phoneNumber: data.phoneNumber.trim(),
      organisationId: userData?.organisationId ?? orgId,
      smsMfaEnabled: data.mfa === 'sms',
      emailMfaEnabled: data.mfa === 'email' || !data.mfa,
      isAdmin: data.isAdmin,
      enabled: data.enabled ?? true,
      applications: data.applications,
      scope: newScopeData,
    } as D;

    onSave(body);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id={formId} data-testid={createTestid('form')}>
        <Accordion
          data-testid={createTestid('accordeon-info')}
          title="Set up user information"
          expanded
          expansible
          sx={{ marginBottom: '1rem' }}
        >
          <Stack className={classes.formFields}>
            <TextField
              label="User name *"
              type="text"
              name="username"
              disableMargin
              disabled={!!userData}
              rules={{
                maxLength: { value: 40, message: 'Max length of 40' },
                required: 'This field is required',
                validate: (value) => !value.includes(' ') || 'Please remove white spaces',
              }}
              data-testid={createTestid('username-input')}
            />
            <TextField
              label="First name *"
              name="firstName"
              type="text"
              disableMargin
              disabled={!!userData}
              rules={{
                maxLength: { value: 40, message: 'Max length of 40' },
                minLength: { value: 3, message: 'Min length of 3' },
                required: 'This field is required',
              }}
              data-testid={createTestid('firstname-input')}
            />
            <TextField
              label="Last name *"
              name="lastName"
              type="text"
              disableMargin
              disabled={!!userData}
              rules={{
                maxLength: { value: 40, message: 'Max length of 40' },
                minLength: { value: 3, message: 'Min length of 3' },
                required: 'This field is required',
              }}
              data-testid={createTestid('lastname-input')}
            />
            <TextField
              label="Email *"
              name="email"
              type="text"
              disableMargin
              disabled={!!userData}
              rules={{
                required: 'This field is required',
                validate: (value) => isEmail(value) || 'Email format is not correct',
              }}
              data-testid={createTestid('email-input')}
            />
            <TextField
              label="Phone number *"
              name="phoneNumber"
              type="text"
              disableMargin
              rules={{
                required: 'This field is required',
                validate: (value) => isPhone(value) || 'Phone format is not correct',
              }}
              data-testid={createTestid('phone-input')}
            />
            <Select
              name="mfa"
              size="small"
              disableMargin
              label="MFA (MultiFactor Authentification preference)"
              options={[
                { label: 'Email', value: 'email' },
                { label: 'Sms', value: 'sms' },
              ]}
              optional
              data-testid={createTestid('select-mfa')}
            />
            {!userData && (
              <Stack direction="row" alignItems="center" gap=".5rem">
                <Switch
                  labelPlacement="end"
                  variant="secondary"
                  label="Define user as admin"
                  name="isAdmin"
                  data-testid={createTestid('isAdmin-input')}
                />
                <Tooltip title="An admin can manage users settings and organisation-level settings and subscriptions">
                  <Icon.Info size="small" color="secondary" data-testid={createTestid('info-icon')} />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Accordion>

        <Accordion data-testid={createTestid('accordeon-access')} title="Set up user access" expanded expansible>
          <Stack className={classes.formFields}>
            <Select
              name="applications"
              size="small"
              label="Applications"
              options={applications}
              multiple
              disableMargin
              optional
              data-testid={createTestid('select-applications')}
            />
            <Stack direction="row" alignItems="center" gap=".5rem" justifyContent="space-between">
              <Select
                name="scope"
                size="small"
                label="Portfolio scope"
                options={userscopes}
                multiple
                disableMargin
                optional
                fullWidth
                data-testid={createTestid('select-scope')}
              />
              <Tooltip title="Assigning portfolio scopes to a user limits the actions the user can take on these portfolios. The user will only see and be able to perform analyses on the portfolios within his scope. However, when the user loads a portfolio, it is automatically added to the scope.">
                <Icon.Info size="small" color="secondary" data-testid={createTestid('info-icon-scope')} />
              </Tooltip>
            </Stack>
          </Stack>
        </Accordion>
      </form>
    </FormProvider>
  );
};

export const CreateUserForm = UserModalInputs<undefined>;
export const UpdateUserForm = UserModalInputs<AccountApi.User>;
