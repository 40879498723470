import { makeStyles } from 'mns-components';

export const createEmailDeliveryModalStyles = makeStyles({
  form: {
    margin: '2rem auto',
    width: '20rem',
  },
  countText: {
    textAlign: 'right',
    opacity: 0.6,
  },
  addRecipientText: {
    margin: '1rem 0',
    fontSize: '0.90rem',
  },
  searchInput: {
    width: '100%',
    margin: '8px 0',
  },
});
