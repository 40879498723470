import { colors, gradient, makeStyles } from 'mns-components';

const handlerStyles = makeStyles({
  app: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    flexDirection: 'row',
  },
  appWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
  },
  content: {
    flexGrow: 1,
    background: gradient.gradientWhiteBackground,
    overflow: 'auto',
    '& > *': {
      zIndex: 1,
    },
  },
  breadcrumbsStyles: {
    textTransform: 'uppercase',
    '& li': {
      fontSize: '.875rem',
      lineHeight: '1.375rem',
      '& a': {
        fontWeight: 'normal',
        textDecoration: 'none',
        color: colors.defaultFontColor,
        cursor: 'pointer',
      },
      '& p': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        fontWeight: 'bold',
        fontFamily: 'Rubik, sans-serif',
      },
    },
  },
  headUserFlag: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
  },
});
export default handlerStyles;
