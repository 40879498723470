import { Modal } from '@mui/material';
import { CreateTemplateStepper } from './CreateTemplateStepper';
import { createTemplateStyles as useStyles } from './createTemplateStyles';

type CreateTemplateModalProps = {
  open: boolean;
  onClose: () => void;
  'data-testid': string;
};

export const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({ open, onClose, 'data-testid': testid }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.container}>
        <CreateTemplateStepper onClose={onClose} data-testid={testid} />
      </div>
    </Modal>
  );
};
