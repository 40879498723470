import { Button, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { automatedExtractionsSettingsStyles as useStyles } from './styles/automatedExtractionsSettingsStyles';

type AutomatedExtractionDeleteModalProps = {
  extractionDetails: DataExtractorApi.Extraction.Extraction;
  onClose(): void;
  onDelete(row: DataExtractorApi.Extraction.Extraction): void;
  'data-testid': string;
};

export const AutomatedExtractionDeleteModal: React.FC<AutomatedExtractionDeleteModalProps> = ({
  extractionDetails,
  onClose,
  onDelete,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [isDeleting, setDeleting] = useState(false);
  const handleDelete = useCallbackImmutable(() => {
    setDeleting(true);
    onDelete(extractionDetails);
  });

  return (
    <div className={classes.fullHeight} data-testid={testid}>
      <div className={classes.scroll}>
        <p>
          You are about to delete the extraction {JSON.stringify(extractionDetails.extractionName)}. Do you confirm?
        </p>
      </div>
      <div className={classes.buttonList}>
        <Button color="primary" outlined onClick={onClose} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleDelete} data-testid={createTestid('button-submit')} loading={isDeleting}>
          Delete
        </Button>
      </div>
    </div>
  );
};
