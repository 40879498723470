import { colors, makeStyles } from 'mns-components';

export const deliveryDetailsDialogEetStyles = makeStyles({
  mainLabel: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: colors.blueColor,
    marginBottom: '0.8rem',
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  itemLabel: {
    fontSize: '0.9rem',
    fontWeight: 600,
  },
  itemRow: {
    display: 'flex',
    alignItems: 'end',
    gap: '0.5rem',
    marginBottom: '0.3rem',
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  table: {
    width: '100%',
  },
});
