import { convertToDateUTC } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { QueryObserverOptions } from 'react-query';
import { currentMonthLastDate, firstDateAvailable } from '../../common/date';
import { useInvalidatePtfCache } from '../../hooks/usePortfoliosRequest';
import { api } from '../../store/api';

export const useFilesRequest = (
  {
    startDate = firstDateAvailable,
    endDate = currentMonthLastDate,
    dataFormats,
  }: { startDate?: string | Date; endDate?: string | Date; dataFormats?: CollectApi.File['dataFormat'][] } = {},
  options: QueryObserverOptions<CollectApi.File[]> = {},
) => {
  const inv = useInvalidatePtfCache();
  return api.inventories.collect.getFiles.useQuery(
    { endDate: convertToDateUTC(endDate), startDate: convertToDateUTC(startDate), dataFormats },
    {
      onSuccess: (...args) => {
        inv();
        options.onSuccess?.(...args);
      },
      ...options,
    },
  );
};
