import { Stack } from '@mui/material';
import type { FormProps } from 'mns-components';
import { EmptyStateV2, Backdrop, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { emailListApi, isinListsApi } from '../../../store/apis';
import { getSettingsRoute } from '../../settings/routes';
import { getViewsRoute } from '../../views/routes';
import type { JsonSchemaValue, ScheduledDeliveryInputsProps } from './ScheduledDeliveryForm';
import { EditScheduledDeliveryForm, CreateScheduledDeliveryForm } from './ScheduledDeliveryForm';
import { scheduledDeliveryModalStyles as useStyles } from './scheduledDeliveryModalStyles';

type MakeScheduledDeliveryRequestProps<InputType extends JsonSchemaValue | void> = {
  formComponent: React.FC<Omit<FormProps, 'children'> & ScheduledDeliveryInputsProps<InputType>>;
};

export type ScheduledDeliveryRequestProps<InputType extends JsonSchemaValue | void> = {
  initState: InputType;
  disabled?: boolean;
  onSubmit(data: DataDeliveryApi.CreateDataDeliveryScheduled): void;
  onClose(): void;
  'data-testid': string;
};

const makeScheduledDeliveryRequest = <InputType extends JsonSchemaValue | void>({
  formComponent: FormComp,
}: MakeScheduledDeliveryRequestProps<InputType>) => {
  const ScheduledDeliveryRequest: React.FC<ScheduledDeliveryRequestProps<InputType>> = ({
    initState,
    disabled,
    onSubmit,
    onClose,
    'data-testid': testid,
  }) => {
    const classes = useStyles();
    const createTestid = useTestid(testid);

    const {
      data: emailLists,
      isLoading: isLoadingEmailLists,
      error: errorEmailLists,
    } = useQuery(['emailListApi.getEmailLists'], emailListApi.getEmailLists);

    const {
      data: isinLists,
      isLoading: isLoadingIsinLists,
      error: errorIsinLists,
    } = useQuery(['listsApi.getLists'], () => isinListsApi.getLists());

    const emailListNames = useMemo(
      () =>
        emailLists
          ? Array.from(
              emailLists.reduce((acc, emailList) => {
                acc.add(emailList.title);
                return acc;
              }, new Set<string>()),
            )
          : undefined,
      [emailLists],
    );

    const isinListNames = useMemo(
      () =>
        isinLists
          ? Array.from(
              isinLists.reduce((acc, isinList) => {
                acc.add(isinList.name);
                return acc;
              }, new Set<string>()),
            )
          : undefined,
      [isinLists],
    );

    const handleSubmit = useCallbackImmutable((raw: JsonSchemaValue) => {
      if (emailLists && isinLists) {
        const emailList = emailLists.find(({ title }) => title === raw.emailListName);
        if (raw.scope === 'ALL' && emailList) {
          return onSubmit({
            emailingListId: emailList.id,
            scope: { mode: 'ALL' },
            enabled: true,
            name: raw.name,
            sharingFrequency: raw.sharingFrequency,
            triggerDay: raw.triggerDay,
          });
        }
        const isinList = isinLists.find(({ name }) => name === raw.isinListName);
        if (emailList && isinList) {
          return onSubmit({
            emailingListId: emailList.id,
            scope: {
              mode: 'CUSTOM',
              isinListId: isinList.id,
              isinListName: isinList.name,
            },
            enabled: true,
            name: raw.name,
            sharingFrequency: raw.sharingFrequency,
            triggerDay: raw.triggerDay,
          });
        }
      }
    });

    if (isLoadingEmailLists || isLoadingIsinLists) {
      return <Backdrop data-testid={createTestid('backdrop')} />;
    }

    if (errorEmailLists || !emailListNames) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            illustrationVariant="iconType"
            data-testid={createTestid('error-email')}
          />
        </Stack>
      );
    }

    if (errorIsinLists || !isinListNames) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            illustrationVariant="iconType"
            data-testid={createTestid('error-isin')}
          />
        </Stack>
      );
    }

    if (!emailListNames.length) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title="Get started by set up your email list!"
            subTitle="Start creating email list in order to send files to your recipients."
            buttonText="Create email list"
            buttonVariant="secondary"
            buttonHref={getSettingsRoute('settings-organisation').link}
            illustrationVariant="iconType"
            data-testid={createTestid('empty-email')}
          />
        </Stack>
      );
    }

    if (!isinListNames.length) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title="Get started by set up your ISIN list!"
            subTitle="Start creating ISIN list in order to send files to your recipients."
            buttonText="Create ISIN list"
            buttonVariant="secondary"
            buttonHref={getViewsRoute('isin-list').path}
            illustrationVariant="iconType"
            data-testid={createTestid('empty-isin')}
          />
        </Stack>
      );
    }

    return (
      <FormComp
        emailListNames={emailListNames}
        isinListNames={isinListNames}
        disabled={disabled}
        onSubmit={handleSubmit}
        onClose={onClose}
        className={classes.wrapper}
        data-testid={testid}
        initState={initState}
      />
    );
  };

  return ScheduledDeliveryRequest;
};

export const CreateScheduledDeliveryRequest = makeScheduledDeliveryRequest<void>({
  formComponent: CreateScheduledDeliveryForm,
});
export const EditScheduledDeliveryRequest = makeScheduledDeliveryRequest<JsonSchemaValue>({
  formComponent: EditScheduledDeliveryForm,
});
