import { colors, makeStyles } from 'mns-components';

export const cardModalStyles = makeStyles({
  cardModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paragraph: {
    paddingLeft: '0',
  },
  sectionContainer: {
    marginBottom: '1rem ',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '.85rem',
    marginRight: '1rem',
  },
  subtitle: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: 0,
    fontSize: '.85rem',
    marginBottom: '.7rem',
    marginRight: '1rem',
  },
  subText: {
    fontSize: '.75rem',
    lineHeight: '1rem',
    textAlign: 'justify',
    color: colors.darkGreyColor,
  },
  descLogo: {
    width: '5rem',
    height: '5rem',
    borderRadius: '10px',
    boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.05)',
    justifySelf: 'center',
    border: `1px solid ${colors.lightGreyColor}`,
    backgroundSize: 'contain',
  },
  infoContainer: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  rightContainer: {
    textAlign: 'center',
    marginLeft: '1rem',
  },
  partnerTitle: {
    justifySelf: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  keypoints: {
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  tags: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  tagTxt: {
    margin: '0',
    color: colors.blueColor,
    borderRadius: '1rem',
    fontSize: '12px',
    display: 'flex',
    width: 'fit-content',
    padding: '.2rem .4rem',
  },
  infos: {
    display: 'flex',
    '& > *': {
      marginRight: '8px',
    },
  },
  bgOrange: {
    backgroundColor: colors.lighOrangeColor,
  },
  bgGrey: {
    backgroundColor: colors.blueGreyColor,
  },
  bgGreen: {
    backgroundColor: colors.greyGreenColor,
  },
});
