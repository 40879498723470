import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { AxiosError } from 'axios';
import { EmptyState, EmptyStateV2, useAsyncLazy, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, WidgetApi } from 'mns-sdk-collect';
import type { HTMLAttributes } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { buildFromConfig } from '../../../../common/buildFromConfig';
import { getApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { useGetWidgetDoc, usePostWidgetDoc } from '../../hooks';
import type { ClarityTableProps } from './widgetComponent/ClarityTable';
import type { ClarityWidgetProps } from './widgetComponent/ClarityWidget';
import { WidgetHeader } from './WidgetHeader';

export type WidgetConfig = {
  load?(): Promise<void>;
  refresh?(props: { node: HTMLElement }): Promise<void>;
  Component: React.FC<ClarityWidgetProps> | React.FC<ClarityTableProps>;
  attributes: HTMLAttributes<HTMLDivElement>;
};

const loadWidgetProps = async (appCode: AppCode) => {
  const {
    config: { widgetConfig },
  } = getApplicationConfig(appCode);
  try {
    if (widgetConfig) {
      return await buildFromConfig<undefined, WidgetConfig>(widgetConfig)();
    }
    return null;
  } catch (err) {
    throw new Error('Can not load widget config');
  }
};

type WidgetAppProps = {
  portfolio: CollectApi.Portfolio;
  'data-testid': string;
};

export const WidgetApp: React.FC<WidgetAppProps> = ({ portfolio: currentPtf, 'data-testid': testid }) => {
  const MAX_LOADING_REQUEST = 5;
  const LOADING_REQUEST_FREQUENCY = 7_500;

  const { date, appCode } = useParams<{ date: string; appCode: AppCode }>();
  const [hasError, setHasError] = useState(false);
  const [hasGeneratedNewWidgetDoc, setHasGeneratedNewWidgetDoc] = useState(false);
  const [loadingQueryCount, setLoadingQueryCount] = useState(1);
  const [widgetDoc, setWidgetDoc] = useState<WidgetApi.Document>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridGroupAllOpened, setGridGroupAllOpened] = useState(true);
  const createTestid = useTestid(testid);

  const { execute: loadConfig, result: widgetConfig, error: widgetConfigError } = useAsyncLazy(loadWidgetProps);
  useEffect(() => {
    loadConfig(appCode);
  }, [loadConfig, appCode]);

  // Enabled, Start by loading the widget data
  const {
    data: loadWidgetDocData,
    error: loadWidgetDocError,
    refetch: reloadWidgetDoc,
    status: loadWidgetDocStatus,
  } = useGetWidgetDoc(appCode, currentPtf, date);

  // Disabled, Used if widget data not found
  const {
    data: generateWidgetDocData,
    error: generateWidgetDocError,
    refetch: generateWidgetDoc,
    status: generateWidgetDocStatus,
  } = usePostWidgetDoc(appCode, currentPtf, date);

  useEffect(() => {
    const hasErrorOnLoading = loadWidgetDocStatus === 'error';
    const isLoadingSuccessful = loadWidgetDocStatus === 'success';
    const isAnalysisComplete = loadWidgetDocData?.status === 'complete';
    const isAnalysisPending = loadWidgetDocData?.status === 'pending';
    const isMaxLoadingReached = loadingQueryCount >= MAX_LOADING_REQUEST;
    const hasValidWidgetData =
      ((loadWidgetDocData as WidgetApi.Document)?.widgetCredentials &&
        (loadWidgetDocData as WidgetApi.Document)?.providerPortfolioId) ||
      (loadWidgetDocData as WidgetApi.Document)?.widgetUrl;

    if (hasErrorOnLoading && !hasGeneratedNewWidgetDoc) {
      setHasGeneratedNewWidgetDoc(true);
      generateWidgetDoc();
      return;
    }

    if (hasErrorOnLoading && isMaxLoadingReached) {
      setHasError(true);
      return;
    }

    if (isLoadingSuccessful && isAnalysisComplete) {
      if (hasValidWidgetData) {
        setWidgetDoc(loadWidgetDocData);
      } else {
        setHasError(true);
      }
      return;
    }

    if (isLoadingSuccessful && isAnalysisPending && !isMaxLoadingReached) {
      setTimeout(() => {
        setLoadingQueryCount((prevCount) => prevCount + 1);
        reloadWidgetDoc();
      }, LOADING_REQUEST_FREQUENCY);
    }
  }, [
    reloadWidgetDoc,
    generateWidgetDoc,
    loadWidgetDocData,
    loadWidgetDocStatus,
    loadWidgetDocError,
    loadingQueryCount,
    hasGeneratedNewWidgetDoc,
  ]);

  // Handle Request new Widget doc
  useEffect(() => {
    const isAnalysisAlreadyPending =
      generateWidgetDocStatus === 'error' && (generateWidgetDocError as AxiosError)?.response?.status === 409;
    const isRequestSuccessful =
      generateWidgetDocStatus === 'success' && generateWidgetDocData?.status === 'REQUEST_SENT';

    if (!widgetDoc && isRequestSuccessful) {
      setLoadingQueryCount((prevCount) => prevCount + 1);
      reloadWidgetDoc();
      return;
    }
    if (!widgetDoc && isAnalysisAlreadyPending) {
      setTimeout(() => {
        setLoadingQueryCount((prevCount) => prevCount + 1);
        reloadWidgetDoc();
      }, LOADING_REQUEST_FREQUENCY);
      return;
    }
    if (!widgetDoc && generateWidgetDocStatus === 'error') {
      setHasError(true);
    }
  }, [reloadWidgetDoc, generateWidgetDocStatus, generateWidgetDocData, generateWidgetDocError, widgetDoc]);

  useEffect(() => {
    if (loadingQueryCount >= MAX_LOADING_REQUEST) {
      setHasError(true);
    }
  }, [loadingQueryCount]);

  const onRowGroupOpened = useCallbackImmutable((currentGrid: GridApi) => {
    let countCollapsed = 0;
    currentGrid.forEachNode((node) => {
      if (node.allChildrenCount && !node.expanded) {
        countCollapsed += 1;
      }
    });
    setGridGroupAllOpened(!countCollapsed);
  });

  if (hasError || widgetDoc?.status === 'failed' || widgetConfigError) {
    return (
      <Stack height="100%" flexGrow="1" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!widgetDoc || !widgetConfig) {
    return (
      <EmptyState
        iconEnable
        iconName="processing"
        title="Processing…"
        firstParagraph="Your portfolio is currently being analysed. Please wait a few seconds to get your portfolio scores"
        data-testid={createTestid('info-processing')}
      />
    );
  }
  return (
    <>
      <WidgetHeader
        gridApi={gridApi}
        gridGroupAllOpened={gridGroupAllOpened}
        onRowGroupOpened={onRowGroupOpened}
        currentPtf={currentPtf}
        appCode={appCode}
        data-testid={createTestid('header')}
      />
      <widgetConfig.Component
        widgetConfig={widgetConfig}
        widgetDoc={widgetDoc}
        getGridApiRef={setGridApi}
        onRowGroupOpened={onRowGroupOpened}
        data-testid={testid}
      />
    </>
  );
};
