import { CircularProgress, Stack } from '@mui/material';
import {
  EmptyState,
  SearchField,
  Select,
  SelectFiltersMulti,
  convertToDateLocal,
  lowerCase,
  useTestid,
  useWatchFormValues,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTemplates } from '../../../../api/templateData';
import { SelectTemplateCard } from './SelectTemplateCard';
import { selectTemplateStyles } from './selectTemplateStyles';

const sortOptions: {
  value: 'DESC' | 'ASC';
  label: string;
  method?(a: DataExtractorApi.Template.TemplateLight, b: DataExtractorApi.Template.TemplateLight): number;
}[] = [
  {
    value: 'DESC',
    label: 'New to old',
    method: (a, b) => (new Date(b.createdAt).getTime() < new Date(a.createdAt).getTime() ? -1 : 1),
  },
  {
    value: 'ASC',
    label: 'Old to new',
    method: (a, b) => (new Date(b.createdAt).getTime() < new Date(a.createdAt).getTime() ? 1 : -1),
  },
];

type SelectTemplateProps = {
  templateId: string;
  onTemplateIdChange(templateId: string): void;
  'data-testid': string;
};

export const SelectTemplate: React.FC<SelectTemplateProps> = ({
  templateId,
  onTemplateIdChange,
  'data-testid': testid,
}) => {
  const classes = selectTemplateStyles();
  const createTestid = useTestid(testid);
  const { data: templates, isLoading, isError } = useTemplates();

  const authorsList = useMemo(
    () =>
      templates?.reduce((acc, { createdBy }) => {
        if (!acc.some(({ value }) => value === createdBy)) {
          acc.push({ label: createdBy, value: createdBy });
        }
        return acc;
      }, [] as { label: string; value: string }[]) || [],
    [templates],
  );

  const methods = useForm<{ search: string; createdBy: string[]; sort: 'DESC' | 'ASC' }>({
    defaultValues: { search: '', createdBy: authorsList.map(({ value }) => value), sort: 'DESC' },
  });
  const { search, createdBy, sort } = useWatchFormValues(methods);

  const displayedTemplates = useMemo(() => {
    const filtered =
      templates?.filter((tpl) => {
        if (search && !lowerCase(tpl.name).includes(lowerCase(search))) return false;
        if (createdBy.length && !createdBy.includes(tpl.createdBy)) return false;
        return true;
      }) ?? [];

    const foundSort = sortOptions.find((sorter) => sorter.value === sort);
    return foundSort ? filtered.sort(foundSort.method) : filtered;
  }, [templates, search, createdBy, sort]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <EmptyState iconEnable iconName="warning" title="An issue occured" data-testid={createTestid('error')} />;
  }

  if (!templates?.length) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="No existing template"
        data-testid={createTestid('no-templates')}
      />
    );
  }

  return (
    <Stack>
      <FormProvider {...methods}>
        <Stack direction="row" spacing="1rem" alignItems="center" margin=".5rem 0 0 0">
          <SearchField
            value={search}
            uncontrolled={false}
            className={classes.search}
            data-testid={createTestid('input-search')}
          />
          <SelectFiltersMulti
            className={classes.filterInput}
            name="createdBy"
            label="Created by"
            variant="labeled"
            options={authorsList}
            data-testid={createTestid('select-createdBy')}
          />
          <Select
            className={classes.filterInput}
            name="sort"
            label="Sort"
            variant="labeled"
            options={sortOptions}
            data-testid={createTestid('select-sort')}
          />
        </Stack>
      </FormProvider>
      {displayedTemplates.length ? (
        <Stack marginTop="1rem" direction="row" gap="1.5rem .5rem" flexWrap="wrap">
          {displayedTemplates.map((template, index) => (
            <SelectTemplateCard
              key={template.id}
              templateId={template.id}
              title={template.name}
              createdBy={template.createdBy}
              createdAt={convertToDateLocal(template.createdAt)}
              isSelected={templateId === template.id}
              onClick={onTemplateIdChange}
              data-testid={createTestid(`template-${index}`)}
            />
          ))}
        </Stack>
      ) : (
        <EmptyState
          iconEnable
          iconName="warning"
          title="No matching template"
          data-testid={createTestid('emptyState')}
        />
      )}
    </Stack>
  );
};
