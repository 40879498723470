import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type {
  FormStepErrors,
  FormStepperStepProps,
  FormStepProps,
  FormStepValidator,
  JsonSchemaObject,
} from 'mns-components';
import { EmptyStateV2, FormCreator, isString, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { usePortfoliosRequest } from '../../../hooks/usePortfoliosRequest';
import { createEmailDeliveryModalStyles as useStyles } from './createEmailDeliveryModalStyles';
import { SelectScopeList } from './SelectScopeList';
import type { CreateEmailDeliverySteps } from './types';

const getPortfoliosNavDates = (portfolios: CollectApi.Portfolio[]) =>
  portfolios.reduce((acc, portfolio) => {
    if (!acc.includes(portfolio.latestVersion.valuationDate)) {
      acc.push(portfolio.latestVersion.valuationDate);
    }
    return acc;
  }, [] as string[]);

type FormType = { title: string; navDate: string };

const useInputSchema = (navDateOptions: string[], navDate = '') =>
  useMemo(
    (): JsonSchemaObject => ({
      variant: 'column',
      properties: {
        title: {
          type: 'string',
          title: 'Delivery name',
          variant: 'text',
          placeholder: `ie: Delivery of ${navDate}`,
        },
        navDate: { type: 'string', title: 'NAV date', variant: 'autocomplete', enum: navDateOptions },
      },
      required: ['title', 'navDate'],
    }),
    [navDateOptions, navDate],
  );

type SelectScopeStepProps = FormStepProps<CreateEmailDeliverySteps[0]>;

const SelectScopeStep: React.FC<SelectScopeStepProps> = ({
  stepValue: { title, navDate, portfolioVersionIds },
  setStepValue,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { data: portfolios } = usePortfoliosRequest({ portfolioOriginType: 'UPLOADED' });

  const navDateOptions = useMemo(() => (!portfolios ? [] : getPortfoliosNavDates(portfolios)), [portfolios]);
  const jsonSchema = useInputSchema(navDateOptions, navDate);

  const rows = useMemo(
    () =>
      portfolios?.length && navDate ? portfolios.filter((ptf) => ptf.latestVersion.valuationDate === navDate) : [],
    [navDate, portfolios],
  );

  const handleRowsSelected = useCallbackImmutable((selectList: CollectApi.Portfolio[]) =>
    setStepValue((current) => ({ ...current, portfolioVersionIds: selectList.map((row) => row.latestVersion.id) })),
  );

  const handleFormChange = useCallbackImmutable((update: (current: FormType) => FormType) =>
    setStepValue((current) => ({ ...current, ...update({ navDate: current.navDate, title: current.title }) })),
  );

  const handleGridLoad = useCallbackImmutable((grid: GridApi | null) => {
    grid?.forEachNode((node) => {
      const data = node.data as CollectApi.Portfolio;
      if (portfolioVersionIds?.includes(data.latestVersion.id)) {
        node.setSelected(true);
      }
    });
  });

  const formState = useMemo((): FormType => {
    const currentNavDate = navDate ?? navDateOptions[0];
    const shortUUID = Math.random().toString(36).substring(2, 10);

    const currentTitle = title ?? `email-delivery-${shortUUID}`;

    return { title: currentTitle, navDate: currentNavDate };
  }, [title, navDate, navDateOptions]);

  if (!rows.length) {
    return (
      <>
        <FormCreator<FormType>
          className={classes.form}
          jsonSchema={jsonSchema}
          state={formState}
          setState={handleFormChange}
          data-testid={createTestid('form')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There are no portfolios on this NAV date yet!"
            illustrationVariant="iconType"
            data-testid={createTestid('empty-rows')}
          />
        </Stack>
      </>
    );
  }

  return (
    <Stack style={{ height: '100%' }}>
      <FormCreator<FormType>
        className={classes.form}
        jsonSchema={jsonSchema}
        state={formState}
        setState={handleFormChange}
        data-testid={createTestid('form')}
      />
      <SelectScopeList
        rows={rows}
        onRowsSelected={handleRowsSelected}
        getGridApiRef={handleGridLoad}
        data-testid={createTestid('selectScopeList')}
      />
    </Stack>
  );
};

const validateSelectScopeStep: FormStepValidator<CreateEmailDeliverySteps[0]> = (data) => {
  const errors: FormStepErrors<CreateEmailDeliverySteps[0]> = [];

  if (!isString(data.title)) {
    errors.push({
      name: 'title',
      message: 'Delivery name should be filled',
    });
  } else if (!data.title.length) {
    errors.push({
      name: 'title',
      message: 'Delivery name should not be empty',
    });
  }

  if (!Array.isArray(data.portfolioVersionIds) || !data.portfolioVersionIds.length) {
    errors.push({
      name: 'portfolioVersionIds',
      message: 'Please select at least one portfolio',
    });
  }

  return errors;
};

export const buildSelectScopeStep = (): FormStepperStepProps<CreateEmailDeliverySteps[0]> => ({
  build: makeForm(SelectScopeStep, true),
  title: 'Select Scope',
  onValidate: validateSelectScopeStep,
});
