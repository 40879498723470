import { Tooltip } from '@mui/material';
import type { DropdownProps } from 'mns-components';
import { Dialog, Dropdown, Link, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, MarketplaceApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import type { ApplicationConfig } from '../../../../common/getApplicationConfig';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getViewsRoute } from '../../../../components/views/routes';
import useStyles from './styles/widgetAppDownloadPdfReportStyles';
import { WidgetAppGenerateModal } from './WidgetAppGenerateModal';

export const allowComplementaryReport = (
  complementaryReportType: ApplicationConfig['config']['complementaryReportType'],
) => {
  return ['pdfReport', 'underlyingData'].includes(complementaryReportType ?? '');
};

export type WidgetAppDownloadComplementaryReportProps = {
  appCode: AppCode;
  remainingReport?: number;
  dropdownOptions: DropdownProps['options'];
  currentPtf: CollectApi.Portfolio;
  valuationDate: string;
  applicationDetails: MarketplaceApi.Application;
  handleDownload(value: string): void;
  isGenerating?: boolean;
  generate(): Promise<void>;
  labelGenerate: string;
  'data-testid': string;
};

export const WidgetAppDownloadComplementaryReport: React.FC<WidgetAppDownloadComplementaryReportProps> = ({
  appCode,
  remainingReport,
  dropdownOptions,
  currentPtf,
  valuationDate,
  applicationDetails,
  handleDownload,
  isGenerating,
  labelGenerate,
  generate,
  'data-testid': testid,
}: WidgetAppDownloadComplementaryReportProps) => {
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const {
    config: { complementaryReportType },
  } = useApplicationConfig(appCode);

  const upgradePhrase =
    complementaryReportType === 'pdfReport'
      ? 'You have consumed all the PDF reports included in your current subscription. If you want to request a new PDF report, please renew your subscription.'
      : 'Download an Excel file of your portfolio score at security level, Available only for `Silver +` or `Golden +` Subscription';
  const updateLink = complementaryReportType === 'pdfReport' ? 'Renew subscription now' : 'Upgrade now';
  const complementaryTranslate = complementaryReportType === 'pdfReport' ? 'PDF Report' : 'Underlying data';

  const handleGenerate = useMemo(
    () => (complementaryReportType === 'pdfReport' ? () => setOpenGenerateModal(true) : generate),
    [complementaryReportType, generate],
  );

  const handleConfirm = useCallbackImmutable(async () => {
    await generate();
    setOpenGenerateModal(false);
  });

  const handleClose = useCallbackImmutable(() => setOpenGenerateModal(false));

  if (!allowComplementaryReport(complementaryReportType)) {
    return null;
  }

  return (
    <>
      <Tooltip
        className={classes.tooltip}
        title={
          <div>
            <p>
              <span>{upgradePhrase}</span>
              <Link
                to={generatePath(getViewsRoute('app-subscription').path, {
                  appCode: applicationDetails.codeApplication,
                })}
                data-testid={createTestid('link-upgrade')}
              >
                {updateLink}
              </Link>
            </p>
          </div>
        }
        placement="top"
        disableHoverListener={remainingReport !== 0}
      >
        <div>
          {remainingReport === 0 ? (
            <Dropdown
              options={dropdownOptions}
              disabled={dropdownOptions.length === 0}
              onClick={handleDownload}
              data-testid={createTestid('dropdown-complementaryReport')}
            >
              {complementaryTranslate}
            </Dropdown>
          ) : (
            <Dropdown.Generator
              options={dropdownOptions}
              onClick={handleDownload}
              isGenerating={isGenerating}
              labelGenerate={labelGenerate}
              onGenerate={handleGenerate}
              data-testid={createTestid('dropdown-complementaryReport')}
            >
              {complementaryTranslate}
            </Dropdown.Generator>
          )}
        </div>
      </Tooltip>
      {remainingReport && remainingReport !== 0 ? (
        <Dialog
          dialogTitle={`${complementaryTranslate} Request`}
          open={openGenerateModal}
          onClose={handleClose}
          data-testid={createTestid('dialog')}
        >
          <WidgetAppGenerateModal
            remainingComplementaryReports={remainingReport}
            onCancel={handleClose}
            currentPtf={currentPtf}
            valuationDate={valuationDate}
            onConfirm={handleConfirm}
            applicationDetails={applicationDetails}
            data-testid={createTestid('modal-complementaryReport')}
          />
        </Dialog>
      ) : null}
    </>
  );
};
