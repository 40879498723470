import { Stack } from '@mui/material';
import {
  AppContent,
  AppMargin,
  Backdrop,
  Divider,
  Typo,
  colors,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { useEmailListsData } from './hooks';
import { OrganisationCreateModal } from './modals/OrganisationCreateModal';
import { OrganisationDeleteModal } from './modals/OrganisationDeleteModal';
import { OrganisationUpdateModal } from './modals/OrganisationUpdateModal';
import { OrganisationController } from './OrganisationController';
import { OrganisationList } from './OrganisationList';

type OrganisationPageProps = { 'data-testid': string };

export const OrganisationSettingsPage: React.FC<OrganisationPageProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: emailLists = [], isLoading: isLoadingEmailLists } = useEmailListsData();

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const handleOpenCreate = useCallbackImmutable(() => setIsOpenCreate(true));
  const handleCloseCreate = useCallbackImmutable(() => setIsOpenCreate(false));

  const [isOpenDeleteEmailList, setIsOpenDeleteEmailList] = useState<null | EmailListApi.EmailList>(null);
  const handleCloseDeleteEmailList = useCallbackImmutable(() => {
    setIsOpenDeleteEmailList(null);
  });

  const [isOpenUpdateEmailList, setIsOpenUpdateEmailList] = useState<undefined | EmailListApi.EmailList>(undefined);
  const handleCloseUpdateEmailList = useCallbackImmutable(() => {
    setIsOpenUpdateEmailList(undefined);
  });

  if (isLoadingEmailLists) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <>
      <AppContent variant="expanded">
        <AppMargin>
          <Stack gap="1rem">
            <Stack>
              <Typo variant="body1medium">Email list</Typo>
              <Typo variant="body2" color={colors.darkGreyColor}>
                Create email lists to facilitate communication with people inside or outside your organization.
              </Typo>
            </Stack>
            <Divider variant="default" />
          </Stack>
        </AppMargin>
        <Stack gap="1rem" flex="1">
          <OrganisationController
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onCreate={handleOpenCreate}
            data-testid={createTestid('controller')}
          />
          <OrganisationList
            onUpdate={setIsOpenUpdateEmailList}
            onDelete={setIsOpenDeleteEmailList}
            searchValue={searchValue}
            emailLists={emailLists}
            data-testid={createTestid('list')}
          />
        </Stack>
      </AppContent>
      <OrganisationCreateModal
        open={isOpenCreate}
        onClose={handleCloseCreate}
        data-testid={createTestid('modal-create')}
      />
      <OrganisationUpdateModal
        emailList={isOpenUpdateEmailList}
        open={!!isOpenUpdateEmailList}
        onClose={handleCloseUpdateEmailList}
        data-testid={createTestid('modal-update')}
      />
      <OrganisationDeleteModal
        emailList={isOpenDeleteEmailList}
        onClose={handleCloseDeleteEmailList}
        data-testid={createTestid('modal-delete')}
      />
    </>
  );
};
