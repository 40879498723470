import type { FormStepErrors, FormStepValidator, OptionType, StepProps } from 'mns-components';
import {
  Button,
  ButtonList,
  ErrorText,
  Form,
  Frame,
  LoadingCircle,
  Select,
  isString,
  useCallbackImmutable,
  useStepperButtons,
  useTestid,
} from 'mns-components';
import { useEffect, useMemo, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { fileTypeMap } from '../common/fileTypeMap';
import { useEetLightFilesRequest, useUpdateEetLink, useUploadModalOpen } from '../hooks';
import { selectInputFileStyles as useStyles } from './styles/selectInputFileStyles';
import type { GenerateEetFormSteps } from './types';

export const validSelectLinkedFileStep =
  (
    updateInputLinked: (fileId: string, fileType: 'EET_LIGHT') => Promise<boolean>,
  ): FormStepValidator<GenerateEetFormSteps[1]> =>
  async ({ eetLightId }) => {
    if (isString(eetLightId)) {
      if (await updateInputLinked(eetLightId, 'EET_LIGHT')) {
        return null;
      } else {
        return [
          {
            name: 'eetLightId',
            message: 'Unexpected error',
          },
        ];
      }
    } else {
      return [
        {
          name: 'eetLightId',
          message: 'EET Light ID is invalid',
        },
      ];
    }
  };

type SelectLinkedFileFrameProps = {
  fileType: 'EET_LIGHT';
  handleSelect(linkedFileId: string): void;
  options: readonly OptionType[];
  isLoading: boolean;
  reloadInputFiles(): void;
  stepValue: Partial<{
    eetLightId: string | null;
  }>;
  error?: string;
  'data-testid': string;
};

const SelectLinkedFileFrame: React.FC<SelectLinkedFileFrameProps> = ({
  fileType,
  handleSelect,
  options,
  isLoading,
  reloadInputFiles,
  stepValue,
  error,
  'data-testid': testid,
}) => {
  const { inputName } = fileTypeMap[fileType];
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const onUploadPortfolio = useUploadModalOpen();

  const handleFirstSelection = useCallbackImmutable(({ setValue }: UseFormReturn<{ eetLightId: string }>) => {
    if (stepValue.eetLightId) {
      setValue('eetLightId', stepValue.eetLightId);
    }
  });

  if (!options.length) {
    if (isLoading) {
      return (
        <Frame variant="margined" data-testid={testid}>
          <div className={classes.buttonList}>
            <LoadingCircle data-testid={createTestid('loading')} />
          </div>
        </Frame>
      );
    }

    return (
      <Frame variant="margined" data-testid={testid}>
        <div className={classes.pickInputFileForm}>
          <div className={classes.pickInputFile}>
            <p>We didn&apos;t find any input file with the right general reference date</p>
          </div>
        </div>
        <div className={classes.buttonList}>
          <Button color="primary" onClick={onUploadPortfolio} data-testid={createTestid('button-upload')}>
            Upload File
          </Button>
          <Button
            loading={isLoading}
            color="primary"
            onClick={reloadInputFiles}
            data-testid={createTestid('button-refresh')}
          >
            Refresh
          </Button>
        </div>
      </Frame>
    );
  }

  return (
    <Frame variant="margined" data-testid={testid}>
      <div className={classes.pickInputFileForm}>
        <div className={classes.pickInputFile}>
          <p>Here are your input files already uploaded on platform</p>
          <Form onReady={handleFirstSelection} data-testid={createTestid('form')}>
            <Select
              name={inputName}
              onFieldChange={handleSelect}
              options={options}
              disabled={isLoading}
              error={!!error}
              data-testid={createTestid('select-file')}
            />
            <ErrorText variant="helperText" display={!!error} data-testid={`${testid}-error`}>
              {error}
            </ErrorText>
          </Form>
          <p>
            You don&apos;t find the right input file? Click{' '}
            <a href="#" onClick={onUploadPortfolio} data-testid={createTestid('button-upload')}>
              here
            </a>{' '}
            to define a new one
          </p>
        </div>
      </div>
    </Frame>
  );
};

type SelectLinkedFileStepProps = StepProps<GenerateEetFormSteps, 1>;

export const SelectLinkedFileStep: React.FC<SelectLinkedFileStepProps> = ({
  stepValues: [{ generalReferenceDate }],
  stepValue,
  setStepValue,
  goPrevious,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { eetId } = useParams<{ eetId: string }>();
  const [errors, setErrors] = useState<FormStepErrors<GenerateEetFormSteps[1]> | null>(null);

  const {
    mutate: updateLink,
    isLoading: isUpdatingLink,
    isSuccess: isUpdatedLink,
    error: errorUpdateLink,
  } = useUpdateEetLink(eetId);

  const {
    data: eetLightFiles,
    isLoading: isLoadingEetLight,
    isRefetching: isRefetchingEetLight,
    refetch: loadEetLightFiles,
  } = useEetLightFilesRequest({
    referenceDate: generalReferenceDate!,
    qualities: ['REGULATORY', 'BASIC'],
  });

  const handleSelect = useCallbackImmutable((linkedFileId: string) => {
    setErrors((current) => current?.filter((err) => err.name !== 'eetLightId') ?? null);
    setStepValue(() => {
      return { fileType: 'EET_LIGHT', eetLightId: linkedFileId, emtId: null };
    });
  });

  const options = useMemo<OptionType[]>(
    () => eetLightFiles?.map((file) => ({ label: file.name, value: file.processId })) ?? [],
    [eetLightFiles],
  );

  const reloadInputFiles = useCallbackImmutable(() => {
    loadEetLightFiles();
  });

  const handleNext = useCallbackImmutable(() => {
    if (stepValue.eetLightId) {
      updateLink({ linkedFileId: stepValue.eetLightId, type: stepValue.fileType });
    } else {
      setErrors([
        {
          name: 'eetLightId',
          message: 'Please choose a file',
        },
      ]);
    }
  });

  useEffect(() => {
    if (isUpdatedLink) {
      goNext();
    }
  }, [isUpdatedLink, goNext]);

  useEffect(() => {
    if (errorUpdateLink) {
      setErrors([
        {
          name: 'fileType',
          message: 'Unexpected error',
        },
      ]);
    }
  }, [errorUpdateLink]);

  const linkedFileError = errors?.find((err) => err.name === 'eetLightId')?.message;

  return (
    <>
      <SelectLinkedFileFrame
        fileType={'EET_LIGHT'}
        options={options}
        handleSelect={handleSelect}
        isLoading={isLoadingEetLight || isRefetchingEetLight}
        reloadInputFiles={reloadInputFiles}
        stepValue={stepValue}
        error={linkedFileError}
        data-testid={createTestid('formFile')}
      />
      <Buttons>
        <ButtonList
          center
          buttons={useStepperButtons({
            onNext: handleNext,
            onPrevious: goPrevious,
            previousLabel: 'Back',
            nextDisabled: !!errors?.length || !options.length,
            nextLoading: isUpdatingLink,
            'data-testid': createTestid('button'),
          })}
          data-testid={createTestid('buttons')}
        />
      </Buttons>
    </>
  );
};
