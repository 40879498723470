import { useTestid, clsx } from 'mns-components';
import { useMemo } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { Section } from '../../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../../common/getImageContent';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { cardModalStyles as useStyles } from './styles/cardAppDetailsStyles';

export type CardModalProps = {
  appCode: AppCode;
  'data-testid': string;
};

const licensingOptTxt = {
  BYOL: 'Bring Your Own License',
  SLOM: 'Shop Licence On Manaos',
};

export const CardAppDetail: React.FC<CardModalProps> = ({ appCode, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { content } = useApplicationConfig(appCode);

  const logo = useAppBackgroundClassName(appCode);

  const iconClasses = useMemo(() => clsx([classes.descLogo, logo]), [classes.descLogo, logo]);

  const companyDescription = content.sections
    ? content.sections.find((section) => section.title.includes("What you'll love about"))
    : undefined;

  const displaySection = (section: Section) => (
    <div className={classes.sectionContainer} data-testid={createTestid(section.title)}>
      <div className={classes.subtitle}>{section.title}</div>
      {section.text && <div className={classes.subText}>{section.text}</div>}
      {section.list && (
        <ul>
          {section.list.map((keypoint, i) => (
            <li
              className={classes.subText}
              key={i} // NOSONAR
            >
              {keypoint}
            </li>
          ))}
        </ul>
      )}
      {section.paragraphs &&
        section.paragraphs.map((paragraph, i) => (
          <div
            className={classes.subText}
            key={i} // NOSONAR
          >
            {paragraph}
          </div>
        ))}
    </div>
  );

  if (!content) {
    return null;
  }

  return (
    <div className={classes.cardModal}>
      <div className={classes.mainContainer}>
        {/* Sections */}
        <div style={{ flexDirection: 'column' }}>
          {content.sections?.map(
            (section) => !section.title.includes("What you'll love about") && displaySection(section),
          )}
          {/* Tags */}
          <div className={classes.sectionContainer}>
            {/* Usecases */}
            {content.useCases && (
              <div className={classes.tags}>
                <div className={classes.title}>UseCase{content.useCases.length > 1 && 's'}</div>
                <div className={classes.infos}>
                  {content.useCases.map((usecase) => (
                    <div key={usecase} className={clsx(classes.tagTxt, classes.bgGrey)}>
                      {usecase}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* App Coverage */}
            {content.appCoverageUniverse && (
              <div className={classes.tags}>
                <div className={classes.title}>App coverage</div>
                <div className={classes.infos}>
                  {content.appCoverageUniverse.map((coverage) => (
                    <div key={coverage} className={clsx(classes.tagTxt, classes.bgGreen)}>
                      {coverage}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Licensing Option */}
            {content.licensingOptions && (
              <div className={classes.tags}>
                <div className={classes.title}>Licensing option{content.licensingOptions.length > 1 && 's'}</div>
                <div className={classes.infos}>
                  {content.licensingOptions &&
                    content.licensingOptions.map((option) => (
                      <div key={option} className={clsx(classes.tagTxt, classes.bgOrange)}>
                        {`${option}: ${licensingOptTxt[option]}`}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* Company About */}
          {companyDescription && (
            <div className={classes.sectionContainer} key={'companyDescription'} data-testid={createTestid('about')}>
              {companyDescription && displaySection(companyDescription)}
            </div>
          )}
        </div>
        <div className={classes.icon}>
          {/* Partner Info */}
          <div className={classes.infoContainer}>
            <div className={classes.rightContainer}>
              {logo && <div className={iconClasses} data-testid={createTestid('logo')} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
