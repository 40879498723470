import { Stack } from '@mui/material';
import { Backdrop, EmptyState, EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import type { AnalysisApi, CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { generatePath, useParams } from 'react-router-dom';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getViewsRoute } from '../../../../components/views/routes';
import { CreateRequestModal } from '../../../analysisDemands/modals/createRequest/CreateRequestModal';
import { useAnalysisPortfolios, useDownloadAnalysis } from '../../hooks';
import { pdfReaderStyle as useStyles } from './styles/pdfReaderStyles';
import { DownloadPdfButton } from './tableComponents/DownloadPdfButton';

type PDFDocumentProxy = {
  numPages: number;
};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfReaderAppProps = {
  portfolio: CollectApi.Portfolio;
  pollingDelay?: number;
  'data-testid': string;
};

export const LoadingComponent = ({
  loadingState,
  'data-testid': testid,
}: {
  loadingState: string | null;
  'data-testid': string;
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.loadingContainer}>
      <Backdrop data-testid={createTestid('backdrop')} />
      <p>{`Loading … ${loadingState}`}</p>
    </div>
  );
};

export const PdfReaderApp: React.FC<PdfReaderAppProps> = ({
  portfolio: currentPtf,
  pollingDelay = 3000,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [openCreateAnalysis, setOpenCreateAnalysis] = useState(false);
  const { appCode } = useParams<{ date: string; appCode: AppCode }>();
  const {
    company: { providerName },
    config: { analysisRequestType, activatePortfolioViewPolling },
  } = useApplicationConfig(appCode);

  const { data: currentAnalysisPortfolio, refetch } =
    useAnalysisPortfolios<AnalysisApi.AnalysisPortfolio<AppCode> | null>(appCode, {
      select: (list) =>
        list
          ?.filter(
            (elem) =>
              elem.externalId === currentPtf.externalId.value &&
              elem.externalIdType === currentPtf.externalId.type &&
              elem.valuationDate === currentPtf.latestVersion.valuationDate &&
              elem.status !== 'failed',
          )
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          .at(0) ?? null,
      refetchIntervalInBackground: true,
      refetchInterval: (analysis) => (analysis?.status === 'pending' ? pollingDelay : false),
    });

  const analysisId =
    (currentAnalysisPortfolio?.status === 'complete' && currentAnalysisPortfolio.analysisId) || undefined;

  const {
    data: pdf,
    isLoading: isLoadingPdfUrl,
    error: errorPdf,
  } = useDownloadAnalysis(appCode, analysisId, {
    refetchIntervalInBackground: true,
    refetchInterval: (data) => (!data && activatePortfolioViewPolling ? pollingDelay : false),
  });

  const handleOpenCreateAnalysis = useCallbackImmutable(() => setOpenCreateAnalysis(true));
  const handleCloseCreateAnalysis = useCallbackImmutable(() => {
    setOpenCreateAnalysis(false);
    refetch();
  });

  const [loadingState, setLoadingState] = useState<string | null>('0 %');
  const [totalPages, setTotalPages] = useState<number>(0);

  const onDocumentSuccess = useCallbackImmutable(({ numPages }: PDFDocumentProxy): void => {
    setTotalPages(numPages);
  });

  const onLoadProgress = useCallbackImmutable(({ loaded, total }) =>
    setLoadingState(`${Math.trunc((loaded / total) * 100)} %`),
  );

  const emptyTitleLabel = useMemo(
    (): string =>
      analysisRequestType === 'enrich' ? 'Get started with an enrichment!' : 'Get started with an analyse!',
    [analysisRequestType],
  );

  const emptySubtitleLabel = useMemo(
    (): string =>
      analysisRequestType === 'enrich' ? 'Start enriching your portfolio.' : 'Start analysing your portfolio.',
    [analysisRequestType],
  );

  const createRequestLabel = useMemo(
    (): string => (analysisRequestType === 'enrich' ? 'Enrich portfolio' : 'Order Analysis'),
    [analysisRequestType],
  );

  if (currentAnalysisPortfolio === undefined || isLoadingPdfUrl) {
    return (
      <EmptyState
        iconEnable
        iconName="processing"
        title="Processing…"
        firstParagraph="Your portfolio is currently being analysed. Please wait a few seconds to get your portfolio scores"
        data-testid={createTestid('info-processing')}
      />
    );
  }

  if (currentAnalysisPortfolio?.status === 'pending') {
    return (
      <EmptyState
        iconEnable
        iconName={activatePortfolioViewPolling ? 'processing' : 'info'}
        title="Portfolio under analysis"
        firstParagraph={`Your portfolio is currently being analysed by ${providerName}. Get more details about the analysis status by clicking on the button bellow`}
        firstButtonText="GET MORE DETAILS"
        firstLinkTo={generatePath(getViewsRoute('analysis').path, { appCode })}
        data-testid={createTestid('warning-processing')}
      />
    );
  }

  if (!currentAnalysisPortfolio || pdf === null) {
    return (
      <>
        <Stack margin="auto">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title={emptyTitleLabel}
            subTitle={emptySubtitleLabel}
            buttonText={createRequestLabel}
            buttonVariant="secondary"
            buttonIcon="add"
            data-testid={createTestid('warning-empty')}
            buttonOnClick={handleOpenCreateAnalysis}
          />
        </Stack>
        <CreateRequestModal
          isOpen={openCreateAnalysis}
          onClose={handleCloseCreateAnalysis}
          appCode={appCode}
          data-testid={createTestid('modal-createAnalysis')}
        />
      </>
    );
  }

  if (errorPdf || !pdf?.presignedUrl) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team"
          data-testid={createTestid('error-failed')}
        />
      </Stack>
    );
  }

  return (
    <>
      {currentAnalysisPortfolio && (
        <div className={classes.header}>
          <DownloadPdfButton
            data={currentAnalysisPortfolio}
            appCode={appCode}
            rendererLocation="appHeader"
            data-testid={createTestid('button-download')}
          />
        </div>
      )}
      <div className={classes.pdfScroll} data-testid={createTestid('reader')}>
        <Document
          file={pdf.presignedUrl}
          onLoadProgress={onLoadProgress}
          loading={<LoadingComponent loadingState={loadingState} data-testid={createTestid('loading')} />}
          onLoadSuccess={onDocumentSuccess}
        >
          {Array.from({ length: totalPages }, (_, i: number) => (
            <Page
              width={900}
              pageNumber={i + 1}
              key={i}
              loading={<Backdrop data-testid={createTestid('backdrop')} />}
            />
          ))}
        </Document>
      </div>
    </>
  );
};
