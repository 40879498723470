// Layouts
import type { RouterInterface } from '../../components/routing/RouterInterface';
import { DashboardLayout, EmitterPermissionsLayout, InventoriesLayout, MyfilesLayout } from './CollectLayouts';
import { AccessesKpis } from './kpis/AccessesKpis';
import { DashboardKpis } from './kpis/DashboardKpis';
import { InventoriesKpis } from './kpis/InventoriesKpis';
import { UploadsKpi } from './kpis/UploadsKpi';

const rawRouter = [
  {
    label: 'Dashboard',
    link: '/apps/manaos-collection/dashboard',
    component: DashboardLayout,
    expectRole: 'APP_BETA',
    key: 'collection-dashboard',
    'data-stepid': 'tab-collection-dashboard',
  },
  {
    label: 'Inventories to collect',
    link: '/apps/manaos-collection/inventories',
    component: InventoriesLayout,
    key: 'collection-inventories',
    'data-stepid': 'tab-collection-inventories',
  },
  {
    label: 'Access requests',
    link: '/apps/manaos-collection/permissions',
    component: EmitterPermissionsLayout,
    key: 'collection-permissions',
  },
  {
    label: 'My files',
    link: '/apps/manaos-collection/files',
    component: MyfilesLayout,
    key: 'collection-uploads',
  },
] as const;

export type CollectRouteKey = typeof rawRouter[number]['key'];
export const router = rawRouter as readonly RouterInterface[];

export const getRoute = (key: CollectRouteKey): RouterInterface => router.find((route) => route.key === key)!;
export const getCollectRoute = getRoute;

/** @deprecated */
export const kpis: RouterInterface[] = [
  {
    link: '/apps/manaos-collection/dashboard',
    component: DashboardKpis,
    key: 'collection-dashboard',
  },
  {
    link: '/apps/manaos-collection/inventories',
    component: InventoriesKpis,
    key: 'collection-inventories',
  },
  {
    link: '/apps/manaos-collection/permissions',
    component: AccessesKpis,
    key: 'collection-permissions',
  },
  {
    link: '/apps/manaos-collection/files',
    component: UploadsKpi,
    key: 'collection-uploads',
  },
];
