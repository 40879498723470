import { Stack } from '@mui/material';
import { EmptyState, EmptyStateV2, useTestid } from 'mns-components';
import type { CollectApi, DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { extractionFunnelStyles as useStyles } from '../extractionFunnelStyles';
import type { PortfolioData } from '../SelectPtfSection';
import { SelectPtfSectionList } from './SelectPtfSectionList';

type SelectPtfSectionRequestProps = {
  ptfList: PortfolioData[] | undefined;
  portfolioOriginType: CollectApi.PortfolioOrigin;
  extractionType: DataExtractorApi.Extraction.ExtractionType;
  searchValue: string;
  selectedRows: PortfolioData[];
  onRowSelected: (rows: PortfolioData[]) => void;
  disabled?: boolean;
  'data-testid': string;
};

export const SelectPtfSectionRequest: React.FC<SelectPtfSectionRequestProps> = ({
  ptfList,
  portfolioOriginType,
  extractionType,
  searchValue,
  selectedRows,
  onRowSelected,
  disabled,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const filteredPtf = useMemo(
    () => (portfolioOriginType ? ptfList?.filter((ptf) => ptf.origin.includes(portfolioOriginType)) : ptfList),
    [portfolioOriginType, ptfList],
  );

  if (!ptfList) {
    return (
      <EmptyState
        iconEnable
        iconName="processing"
        title="Loading in progress"
        data-testid={createTestid('empty-loading')}
      />
    );
  }

  if (ptfList?.length === 0 || !filteredPtf || filteredPtf?.length === 0) {
    return (
      <Stack height="100%" flexGrow="1" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="noSearchResults"
          title="There is no match with your research!"
          subTitle="You can search again with new criteria"
          data-testid={createTestid('notfound-portfolio')}
        />
      </Stack>
    );
  }

  return (
    <Stack className={classes.container}>
      {disabled && <div className={classes.overlay} />}
      <SelectPtfSectionList
        key={portfolioOriginType}
        searchValue={searchValue}
        extractionType={extractionType}
        initialSelectedData={selectedRows}
        rowData={filteredPtf}
        onRowSelected={onRowSelected}
        data-testid={testid}
      />
    </Stack>
  );
};
