import { useCallbackImmutable } from 'mns-components';
import React, { useEffect } from 'react';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useAuthUser } from '../../hooks/useAuth';

declare const window: ThisWindow;

export type Props<T extends { 'data-testid': string } = { 'data-testid': string }> = RouteProps & {
  component: React.FC<T>;
  childProps?: AnyObject;
  'data-testid': string;
};

const LoggedOutRoute: React.FC<Props> = ({ component, path, childProps = {}, 'data-testid': testid, ...rest }) => {
  const Component = component;

  const [authUser] = useAuthUser();
  const isLoggedIn = !!authUser;

  useEffect(() => {
    if (!isLoggedIn && window?.HubSpotConversations) {
      setTimeout(() => {
        const status = window.HubSpotConversations.widget.status();

        window.hsConversationsSettings = {
          identificationEmail: '',
          identificationToken: '',
        };

        window.HubSpotConversations.clear({ resetWidget: true });
        if (status.loaded) {
          window.HubSpotConversations.widget.refresh();
        } else {
          window.HubSpotConversations.widget.load();
        }
      }, 1500);
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={useCallbackImmutable((routeProps: RouteComponentProps) => (
        <Component data-testid={testid} {...routeProps} {...childProps} />
      ))}
    />
  );
};

export default LoggedOutRoute;
