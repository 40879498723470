import type React from 'react';
import type { UseAuthProps } from '../../hooks/useAuth';
import { ChangePassword } from './ChangePassword';
import { ForgotPassword } from './ForgotPassword';
import { ForgotUsername } from './ForgotUsername';
import { Login } from './Login';
import { Logout } from './Logout';
import { Mfa } from './Mfa';
import { ResetPassword } from './ResetPassword';
import { Sso } from './Sso';

export type RouteInterface = {
  key: string;
  path: string;
  shouldBeLoggedIn?: boolean;
  component?: React.FC<{ 'data-testid': string }>;
};

export const rawRoutes = {
  appEntryUri: {
    key: 'entry',
    path: '/',
    shouldBeLoggedIn: true,
  },
  firstLoginUri: {
    key: 'change-password',
    path: '/login/change-password',
    component: ChangePassword,
  },
  forgotPasswordUri: {
    key: 'forgot-password',
    path: '/login/forgot-password',
    component: ForgotPassword,
  },
  forgotUsernameUri: {
    key: 'forgot-username',
    path: '/login/forgot-username',
    component: ForgotUsername,
  },
  resetPasswordUri: {
    key: 'reset-password',
    path: '/login/reset-password',
    component: ResetPassword,
  },
  loginUri: {
    key: 'login',
    path: '/login',
    component: Login,
  },
  mfaUri: {
    key: 'mfa',
    path: '/login/mfa',
    component: Mfa,
  },
  logoutUri: {
    key: 'logout',
    path: '/logout',
    component: Logout,
  },
  ssoUri: {
    key: 'sso',
    path: '/sso',
    component: Sso,
  },
} as const;

type AuthRouteKey = typeof rawRoutes[keyof typeof rawRoutes]['key'];

export const getRoute = (key: AuthRouteKey): RouteInterface =>
  Object.values(rawRoutes).find((route) => route.key === key)!;

export const getAuthRoute = getRoute;

export const routes = rawRoutes as Record<keyof UseAuthProps, RouteInterface>;
