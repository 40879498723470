import { Tooltip, type IconButtonProps, IconButton } from '@mui/material';
import type { AggridTableColumn, AggridTableProps, IconName } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  useDefaultColDef,
  makeActionColDef,
  useAutoSize,
  useImmutable,
  useTestid,
  useCallbackImmutable,
  convertToDateTimeUTCWithTZ,
  useRowSelection,
  Icon,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React from 'react';
import { extractionsStyles as useStyles } from '../extractionsStyles';
import { ExtractionGeneratedHistoryBulk } from './ExtractionGeneratedHistoryBulk';
import type { ExtractionStatus } from './ExtractionGeneratedStatus';
import { ExtractionGeneratedStatus, getExtractionStatusByReports } from './ExtractionGeneratedStatus';

const getActionColDef = makeActionColDef<DataExtractorApi.Extraction.Extraction>();

export const isReportProcessing = (status: ExtractionStatus) => ['REQUESTED', 'PROCESSING'].includes(status);
export const isReportHasCompleted = (status: ExtractionStatus) => ['COMPLETED', 'PARTIALLY_COMPLETED'].includes(status);
export const isReportSettled = (status: ExtractionStatus) =>
  ['COMPLETED', 'PARTIALLY_COMPLETED', 'FAILED'].includes(status);
export const isReportResumable = (status: ExtractionStatus) =>
  !['PARTIALLY_COMPLETED', 'PROCESSING', 'COMPLETED', 'FAILED'].includes(status);

const mapExtractionType: Record<DataExtractorApi.Extraction.ExtractionType, string> = {
  AUTOMATIC: 'Automated',
  MANUALLY: 'One-off',
};

type ActionButtonProps = Omit<IconButtonProps, 'color'> & {
  title: string;
  iconName: IconName;
  'data-testid': string;
};

const ActionButton: React.FC<ActionButtonProps> = ({ title, iconName, 'data-testid': testid, ...props }) => (
  <Tooltip title={title}>
    <IconButton size="small" color="secondary" data-testid={testid} {...props}>
      <Icon name={iconName} data-testid={`${testid}-icon`} />
    </IconButton>
  </Tooltip>
);

type UseColumnsProps = {
  reports: ExtractionsGeneratedListProps['reports'];
  cellSelectProps: AggridTableColumn<DataExtractorApi.Extraction.Extraction>;
  onResumeExtraction: ExtractionsGeneratedListProps['onResumeExtraction'];
  onRetryExtraction: ExtractionsGeneratedListProps['onRetryExtraction'];
  onDetailExtraction: ExtractionsGeneratedListProps['onDetailExtraction'];
  onDownloadExtraction: ExtractionsGeneratedListProps['onDownloadExtraction'];
  onDownloadLogs: ExtractionsGeneratedListProps['onDownloadLogs'];
  'data-testid': string;
};

const useColumns = ({
  reports,
  cellSelectProps,
  onDownloadExtraction,
  onDownloadLogs,
  // onResumeExtraction,
  onRetryExtraction,
  onDetailExtraction,
  'data-testid': testid,
}: UseColumnsProps): AggridTableColumn<DataExtractorApi.Extraction.Extraction>[] => {
  const createTestid = useTestid(testid);

  return [
    cellSelectProps,
    {
      field: 'extractionName',
      headerName: `Extraction name`,
    },
    {
      field: 'templateName',
      headerName: 'Template used',
    },
    {
      field: 'createdAt',
      headerName: 'Generated on',
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
      sort: 'desc',
      sortable: true,
    },
    {
      field: 'id',
      headerName: 'Scope',
      valueGetter: ({ data }) => data.extractionScope.length,
    },
    {
      field: 'extractionType',
      headerName: 'Type',
      valueGetter: ({ data }) => mapExtractionType[data.extractionType],
    },
    {
      field: 'id',
      headerName: 'Extraction Status',
      cellRendererFramework: ({ data }) => (
        <ExtractionGeneratedStatus
          status={getExtractionStatusByReports(data.id, reports)}
          data-testid={createTestid(`${data.id}-status`)}
        />
      ),
    },
    getActionColDef('id', 160, function ActionCol({ data }) {
      const status = getExtractionStatusByReports(data.id, reports);
      return (
        <>
          {/* <ActionButton
            title="Resume"
            iconName="resume"
            disabled={!isReportResumable(data.zipStatus)}
            onClick={useCallbackImmutable(() => onResumeExtraction(data))}
            data-testid={createTestid(`${data.id}-button-resume`)}
          /> */}
          <ActionButton
            title="Retry"
            iconName="retry"
            disabled={status !== 'FAILED'}
            onClick={useCallbackImmutable(() => onRetryExtraction(data))}
            data-testid={createTestid(`${data.id}-button-retry`)}
          />
          <ActionButton
            title="Details"
            iconName="details"
            disabled={!isReportSettled(status)}
            onClick={useCallbackImmutable(() => onDetailExtraction(data))}
            data-testid={createTestid(`${data.id}-button-detail`)}
          />
          <ActionButton
            title="Download"
            data-testid={createTestid(`${data.id}-button-download`)}
            disabled={!isReportHasCompleted(status)}
            iconName="download"
            onClick={useCallbackImmutable(() => onDownloadExtraction(data))}
          />
          <ExtractionGeneratedHistoryBulk
            disabled={!isReportSettled(status)}
            onDownloadLogs={useCallbackImmutable(() => onDownloadLogs(data))}
            data-testid={createTestid(`${data.id}-bulk`)}
          />
        </>
      );
    }),
  ];
};

type ExtractionsGeneratedListProps = {
  reports: DataExtractorApi.Report.ReportLight[];
  onRowsSelection(rows: DataExtractorApi.Extraction.Extraction[]): void;
  onRetryExtraction(row: DataExtractorApi.Extraction.Extraction): void;
  onResumeExtraction(row: DataExtractorApi.Extraction.Extraction): void;
  onDetailExtraction(row: DataExtractorApi.Extraction.Extraction): void;
  onDownloadExtraction(row: DataExtractorApi.Extraction.Extraction): void;
  onDownloadLogs(row: DataExtractorApi.Extraction.Extraction): void;
  'data-testid': string;
} & Omit<AggridTableProps<DataExtractorApi.Extraction.Extraction>, 'columnDefs' | 'defaultColDef'>;

export const ExtractionsGeneratedList: React.FC<ExtractionsGeneratedListProps> = ({
  reports,
  onRowsSelection,
  onRetryExtraction,
  onResumeExtraction,
  onDetailExtraction,
  onDownloadExtraction,
  onDownloadLogs,
  'data-testid': testid,
  ...props
}: ExtractionsGeneratedListProps) => {
  const classes = useStyles();
  const [gridSelectProps, cellSelectProps] = useRowSelection<DataExtractorApi.Extraction.Extraction>(
    (ev) => onRowsSelection(ev.api.getSelectedRows()),
    true,
  );
  return (
    <AggridTable
      {...props}
      {...gridSelectProps}
      columnDefs={useColumns({
        reports,
        cellSelectProps,
        onResumeExtraction,
        onRetryExtraction,
        onDetailExtraction,
        onDownloadExtraction,
        onDownloadLogs,
        'data-testid': testid,
      })}
      getRowNodeId={useImmutable(() => (row: DataExtractorApi.Extraction.Extraction) => row.id)}
      data-testid={testid}
      defaultColDef={useDefaultColDef<DataExtractorApi.Extraction.Extraction>()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      className={classes.aggrid}
    />
  );
};
