export const coverageRangeOptions = [
  {
    value: '10',
    label: '0 - 1O',
  },
  {
    value: '20',
    label: '10 - 20',
  },
  {
    value: '30',
    label: '20 - 30',
  },
  {
    value: '40',
    label: '30 - 40',
  },
  {
    value: '50',
    label: '40 - 50',
  },
  {
    value: '60',
    label: '50 - 60',
  },
  {
    value: '70',
    label: '60 - 70',
  },
  {
    value: '80',
    label: '70 - 80',
  },
  {
    value: '90',
    label: '80 - 90',
  },
  {
    value: '100',
    label: '90 - 100',
  },
];
interface CoverageMapping {
  [key: string]: {
    min: number;
    max: number;
  };
}

export const coverageMapping: CoverageMapping = {
  10: { min: 0.0, max: 0.1 },
  20: { min: 0.1, max: 0.2 },
  30: { min: 0.2, max: 0.3 },
  40: { min: 0.3, max: 0.4 },
  50: { min: 0.4, max: 0.5 },
  60: { min: 0.5, max: 0.6 },
  70: { min: 0.6, max: 0.7 },
  80: { min: 0.7, max: 0.8 },
  90: { min: 0.8, max: 0.9 },
  100: { min: 0.9, max: 1 },
};

interface CoverageDetails {
  [key: string]: string;
}

export const coverageDetailsMapping: CoverageDetails = {
  'Proxy to Parent': 'At parent level',
  'Proxy to Ultimate Parent': 'At ultimate parent level',
};
