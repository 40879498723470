import { Grid, Stack } from '@mui/material';
import type { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import type { OptionType } from 'mns-components';
import {
  useTestid,
  CardLogoTitle,
  Form,
  Autocomplete,
  useCallbackImmutable,
  convertToDateUTC,
  Typography,
  Icon,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { now } from '../../../common/date';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import { usePortfoliosRequest } from '../../../hooks/usePortfoliosRequest';
import { DashboardStyle as useStyles } from '../dashboard/Dashboard.styles';
import { useDashboardPortfolioSelected, useDashboardValuationDate } from '../dashboard/hooks';

const getPortfoliosOptions = (portfolios?: CollectApi.Portfolio[]) => {
  if (!portfolios || portfolios.length == 0) return [];
  let ptfCount = 0;
  const portfoliosOptions = portfolios.reduce((acc, portfolio) => {
    if (portfolio) {
      ptfCount++;
      acc.push({
        value: portfolio.portfolioId,
        label: `${portfolio.externalId.value} - ${portfolio.name}`,
      });
    }
    return acc;
  }, [] as OptionType[]);

  portfoliosOptions.unshift({ value: 'all', label: `All portfolios (${ptfCount})` });
  return portfoliosOptions;
};

type SelectFilterContainerProps = {
  portfolios: CollectApi.Portfolio[];
  'data-testid': string;
};

const year2000 = new Date(2000, 0, 1);
const year2050 = new Date(2050, 0, 1);

const SelectFilterContainer: React.FC<SelectFilterContainerProps> = ({ portfolios, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const portfolioOption = getPortfoliosOptions(portfolios);
  const [portfolio, setPortfolio] = useDashboardPortfolioSelected();
  const [open, setOpen] = useState(false);
  const [valuationDate, setValuationDate] = useDashboardValuationDate();
  const classes = useStyles();

  const getValue = (value: string) => {
    return portfolioOption.find((ptf) => ptf.value == value)?.label;
  };

  const handleSetPortfolio = useCallbackImmutable((value: string) => {
    const portfolioId = portfolioOption.find((ptf) => ptf.label == value)?.value;
    if (portfolioId) setPortfolio(portfolioId);
  });

  const onCalendarDateChange = useCallbackImmutable((date: Date | null, monthChange: boolean) => {
    if (monthChange && date) {
      setValuationDate(date);
    }
  });

  const onDateChange = useCallbackImmutable((date: Date | null) => {
    onCalendarDateChange(date, false);
  });

  const onMonthChange = useCallbackImmutable((date: Date | null) => {
    onCalendarDateChange(date, true);
  });

  const onModalClose = useCallbackImmutable(() => {
    setOpen(false);
  });

  const onDateClick = useCallbackImmutable(() => {
    setOpen(!open);
  });

  const dateRenderer = useCallbackImmutable((params: MuiTextFieldPropsType) => {
    const style = open ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' };

    return (
      <Typography className={classes.collectStatusLabel} color="secondary" onClick={onDateClick} ref={params.inputRef}>
        {valuationDate.toLocaleString('en-us', { month: 'long' })} {valuationDate.getFullYear()}{' '}
        <span className={classes.collectStatusExpandIcon} style={style}>
          <Icon name="expandmore" data-testid={'TODO'} />
        </span>
      </Typography>
    );
  });

  return (
    <Stack flexDirection="row" alignItems="center" gap="1rem">
      <Stack width="15rem">
        <Form data-testid={createTestid('form')}>
          <Autocomplete.Single
            name="portfolios"
            label="Portfolios"
            value={getValue(portfolio) ?? 'All portfolios'}
            options={portfolioOption.map((ptf) => ptf.label)}
            onChange={handleSetPortfolio}
            paperStyle={{ sx: { width: '150%', marginLeft: '-50%' } }}
            data-testid={createTestid('select-portfolio')}
          />
        </Form>
      </Stack>
      <Stack minWidth="8rem">
        <DatePicker
          open={open}
          openTo={'month'}
          views={['year', 'month']}
          label="Select the nav date"
          value={valuationDate}
          onChange={onDateChange}
          onMonthChange={onMonthChange}
          renderInput={dateRenderer}
          onClose={onModalClose}
          minDate={year2000}
          maxDate={year2050}
          data-testid={createTestid('select-datepicker')}
        />
      </Stack>
    </Stack>
  );
};

type DashboardKpiProps = { 'data-testid': string };

const startDate = new Date(Date.UTC(now.getFullYear() - 3, now.getMonth(), 0));
const endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 2, 0));

export const DashboardKpis: React.FC<DashboardKpiProps> = ({ 'data-testid': testid }: DashboardKpiProps) => {
  const createTestid = useTestid(testid);
  const appBackgroundClassName = useAppBackgroundStyles()['manaos-coverageestimation'];

  const { data, isError } = usePortfoliosRequest({
    portfolioOriginType: 'UPLOADED',
    endDate: convertToDateUTC(endDate),
    startDate: convertToDateUTC(startDate),
  });

  const sortedData = useMemo(() => data?.sort((a, b) => a.externalId.value.localeCompare(b.externalId.value)), [data]);

  return (
    <Grid container data-testid={testid}>
      <Grid item style={{ height: '3.5rem' }}>
        <CardLogoTitle
          company="Manaos"
          appName="Collection"
          appBackgroundClassName={appBackgroundClassName}
          variant="filled"
          data-testid={createTestid('card')}
        />
      </Grid>
      {sortedData && !isError && (
        <Grid item>
          <SelectFilterContainer portfolios={sortedData} data-testid={createTestid('contain')} />
        </Grid>
      )}
    </Grid>
  );
};
