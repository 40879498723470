import { makeStyles } from 'mns-components';

export const InventoriesControllerStyles = makeStyles({
  flexLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  controller: {
    padding: '.5rem 1rem',
  },
});
