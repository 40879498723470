import { Stack } from '@mui/material';
import { Accordion, TextField, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { FormProvider, useForm } from 'react-hook-form';
import { useUser } from '../../../../hooks/useAuth';

type FundMappingData = Pick<CollectApi.FundMapping, 'internalCode' | 'fundId'>;

type FundMappingInputsProps<T extends FundMappingData | undefined, D = FundMappingData> = {
  fundData?: T;
  onSave(data: D): void;
  formId?: string;
  'data-testid': string;
};
interface IFormInput {
  internalCode: string;
  fundId: string;
}
export const FundMappingModalInputs = <T extends FundMappingData | undefined, D = FundMappingData>({
  fundData,
  onSave,
  formId,
  'data-testid': testid,
}: FundMappingInputsProps<T, D>): React.ReactElement => {
  const createTestid = useTestid(testid);
  const { organisationId } = useUser();

  const methods = useForm<IFormInput>({
    defaultValues: {
      internalCode: fundData?.internalCode ?? '',
      fundId: fundData?.fundId ?? '',
    },
  });

  const onSubmit = (data: IFormInput) => {
    const body = {
      internalCode: data.internalCode.trim(),
      fundId: data.fundId.trim(),
      organisationId,
    } as D;

    onSave(body);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id={formId} data-testid={createTestid('form')}>
        <Accordion
          data-testid={createTestid('accordeon-info')}
          title="Set up mapping"
          expanded
          sx={{ marginBottom: '1rem' }}
        >
          <Stack gap="1rem">
            <TextField
              label="Internal code *"
              type="text"
              name="internalCode"
              disableMargin
              disabled={!!fundData}
              rules={{
                maxLength: { value: 40, message: 'Max length of 40' },
                required: 'This field is required',
                validate: (value) => !value.includes(' ') || 'Please remove white spaces',
              }}
              data-testid={createTestid('internalCode-input')}
            />
            <TextField
              label="Fund Id *"
              name="fundId"
              type="text"
              disableMargin
              rules={{
                maxLength: { value: 40, message: 'Max length of 40' },
                minLength: { value: 3, message: 'Min length of 3' },
                required: 'This field is required',
                validate: (value) => !value.includes(' ') || 'Please remove white spaces',
              }}
              data-testid={createTestid('fundId-input')}
            />
          </Stack>
        </Accordion>
      </form>
    </FormProvider>
  );
};

export const CreateFundMappingForm = FundMappingModalInputs<undefined>;
export const UpdateFundMappingForm = FundMappingModalInputs<FundMappingData>;
