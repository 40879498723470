import { Backdrop, EmptyState, useTestid } from 'mns-components';
import { useLocation, useParams } from 'react-router-dom';
import { useFetchEet } from '../hooks';
import { getRoute } from '../routes';
import type { GeneratedInitialValue } from './GenerateEetStepper';
import { GenerateEetStepper } from './GenerateEetStepper';

type GenerateEetProps = { 'data-testid': string };

export const GenerateEet: React.FC<GenerateEetProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { eetId } = useParams<{ eetId?: string }>();
  const { data: eet, isLoading: loadingEet } = useFetchEet(eetId);

  const { state } = useLocation<{ fileRefDate: string; eetLightId?: string; emtId?: string } | undefined>();

  const generatedInitialValue: GeneratedInitialValue | undefined = state?.fileRefDate
    ? {
        generalReferenceDate: state.fileRefDate,
        eetLightId: state.eetLightId,
        emtId: state.emtId,
      }
    : undefined;

  if (eetId && loadingEet) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (eetId && !eet) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to resume this EET"
        firstButtonText="Go back to the EET Generator app"
        firstLinkTo={getRoute('eet-files').link}
        data-testid={createTestid('error')}
      />
    );
  }

  return <GenerateEetStepper eet={eet} generatedInitialValue={generatedInitialValue} data-testid={testid} />;
};
