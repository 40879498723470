import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import type { TemplateFormData } from './SelectTemplate';
import { TemplateDatapoints } from './TemplateDatapoints';
import { TemplateEsgDatapoints } from './TemplateEsgDatapoints';
import { TemplateManaosDatapoints } from './TemplateManaosDatapoints';

type SelectTemplateDatapointsProps = {
  disabled?: boolean;
  requiredDatapoints: AnyObject<string, string[] | undefined>;
  setRequiredDatapoints: React.Dispatch<React.SetStateAction<AnyObject<string, string[] | undefined>>>;
  'data-testid': string;
};

export const SelectTemplateDatapoints: React.FC<SelectTemplateDatapointsProps> = ({
  disabled,
  requiredDatapoints,
  setRequiredDatapoints,
  'data-testid': testid,
}) => {
  const { templateType } = useWatch<TemplateFormData>();

  useEffect(() => {
    if (templateType !== 'ESG_APPS') {
      setRequiredDatapoints({});
    }
  }, [templateType, setRequiredDatapoints]);

  if (templateType === 'MY_TEMPLATES') {
    return <TemplateDatapoints disabled={disabled} generic={false} data-testid={testid} />;
  }

  if (templateType === 'MANAOS_TEMPLATES') {
    return <TemplateManaosDatapoints disabled={disabled} data-testid={testid} />;
  }

  return (
    <TemplateEsgDatapoints
      requiredEsgDatapoints={requiredDatapoints}
      setRequiredEsgDatapoints={setRequiredDatapoints}
      disabled={disabled}
      data-testid={testid}
    />
  );
};
