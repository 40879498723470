import { Menu } from '@mui/material';
import { Button, Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import type { ButtonColor } from 'mns-components';
import React from 'react';
import { CLOUDFRONT_DNSNAME } from '../../../app.constants';
import { downloadFile } from '../../../common/utils';

type DownloadSampleBulkProps = {
  providerName: string;
  docFactsheet?: string;
  docAnalytics?: string;
  docMethodology?: string;
  color?: ButtonColor;
  outlined?: boolean;
  'data-testid': string;
};

export const DownloadSampleBulk: React.FC<DownloadSampleBulkProps> = ({
  providerName,
  docFactsheet,
  docAnalytics,
  docMethodology,
  color,
  outlined,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((next?: () => void) => (): void => {
    setAnchorEl(null);
    if (next) next();
  });

  if (!providerName || (!docFactsheet && !docAnalytics && !docMethodology)) {
    return null;
  }

  const urlDocFactsheet = `//${CLOUDFRONT_DNSNAME}/providers/${providerName}/legal/${docFactsheet}`;
  const urlDocAnalytics = `//${CLOUDFRONT_DNSNAME}/providers/${providerName}/legal/${docAnalytics}`;
  const urlDocMethodology = `//${CLOUDFRONT_DNSNAME}/providers/${providerName}/legal/${docMethodology}`;

  const handleAll =
    docFactsheet && docAnalytics && docMethodology
      ? handleClose(() => {
          downloadFile(urlDocAnalytics as string, docAnalytics);
          downloadFile(urlDocFactsheet as string, docFactsheet);
          downloadFile(urlDocMethodology as string, docMethodology);
        })
      : undefined;

  const handleAnalytics = docAnalytics
    ? handleClose(() => downloadFile(urlDocAnalytics as string, docAnalytics))
    : undefined;

  const handleFactsheet = docFactsheet
    ? handleClose(() => downloadFile(urlDocFactsheet as string, docFactsheet))
    : undefined;

  const handleMethodology = docMethodology
    ? handleClose(() => downloadFile(urlDocMethodology as string, docMethodology))
    : undefined;

  return (
    <div>
      <Button
        color={color ? color : 'whiteSecondary'}
        outlined={outlined}
        fullwidth
        style={{ margin: '1.25rem 0 0 0' }}
        onClick={handleOpen}
        data-testid={createTestid('button-sample')}
        endIcon={<Icon.Expandmore data-testid={createTestid('icon-sample')} />}
      >
        Download Sample
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose()}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disabled={!handleAll} onClick={handleAll} data-testid={createTestid('button-all')}>
          All
        </MenuItem>
        <MenuItem disabled={!handleAnalytics} onClick={handleAnalytics} data-testid={createTestid('button-analytics')}>
          Analytics
        </MenuItem>
        <MenuItem disabled={!handleFactsheet} onClick={handleFactsheet} data-testid={createTestid('button-factsheet')}>
          Fact Sheet
        </MenuItem>
        <MenuItem
          disabled={!handleMethodology}
          onClick={handleMethodology}
          data-testid={createTestid('button-methodology')}
        >
          Methodology
        </MenuItem>
      </Menu>
    </div>
  );
};
