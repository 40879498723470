import { Icon, useTestid } from 'mns-components';
import React from 'react';
import { formStyles as useStyles } from './formStyles';

export const LimitationDisplay: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.form}>
      <h3 className={classes.formTitle}>
        <span>Standard pricing exceeded</span>
        <hr />
      </h3>

      <p className={classes.paragraph}>
        <Icon.Info data-testid={createTestid('info-icon')} />
        <span>The number of lines in your portfolio exceeds the limit for standard pricing requests.</span>
      </p>
    </div>
  );
};
