import { Button, Icon, Modal, makeStyles, useTestid } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';

const scheduledDeliveriesDeleteStyles = makeStyles({
  content: {
    padding: '2rem',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
});

type ScheduledDeliveriesDeleteModalProps = {
  schelduledDelivery: DataDeliveryApi.DataDeliveryScheduled | null;
  isDeleting: boolean;
  onClose(): void;
  onDelete(): void;
  'data-testid': string;
};

type ScheduledDeliveriesDeleteModalBodyProps = Replace<
  ScheduledDeliveriesDeleteModalProps,
  'schelduledDelivery',
  DataDeliveryApi.DataDeliveryScheduled
>;

const ScheduledDeliveriesDeleteModalBody: React.FC<ScheduledDeliveriesDeleteModalBodyProps> = ({
  schelduledDelivery,
  isDeleting,
  onClose,
  onDelete,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = scheduledDeliveriesDeleteStyles();

  return (
    <>
      <div className={classes.content}>
        <p>
          This will delete the scheduling{' '}
          <span data-testid={createTestid('schelduledDeliveryName')}>{schelduledDelivery.name}</span>.
        </p>
        <p>Are you sure ?</p>
      </div>
      <div className={classes.buttonList}>
        <Button
          color="primary"
          outlined
          startIcon={<Icon.Close data-testid={createTestid('icon-cancel')} />}
          onClick={onClose}
          data-testid={createTestid('button-cancel')}
        >
          Cancel
        </Button>
        <Button color="danger" onClick={onDelete} loading={isDeleting} data-testid={createTestid('button-submit')}>
          Delete
        </Button>
      </div>
    </>
  );
};

export const ScheduledDeliveriesDeleteModal: React.FC<ScheduledDeliveriesDeleteModalProps> = (props) => (
  <Modal open={!!props.schelduledDelivery} modalTitle="Delete Scheduled Delivery" onClose={props.onClose}>
    {props.schelduledDelivery && (
      <ScheduledDeliveriesDeleteModalBody {...(props as ScheduledDeliveriesDeleteModalBodyProps)} />
    )}
  </Modal>
);
