import { Stack } from '@mui/material';
import { useTestid, Backdrop, EmptyStateV2 } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useFilesRequest } from '../../../../components/views/hooks';
import { useUploadModalOpen } from '../hooks';
import { UploadList } from './UploadList';

export type UploadFilter = {
  search: string;
  referenceDate: string;
  uploadStatus: CollectApi.File['status'][];
};

type UploadListRequestProps = {
  filters: Partial<UploadFilter>;
  refreshDelay?: number;
  onRowSelected(rows: CollectApi.File[]): void;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  'data-testid': string;
};

const viewFileTypes: CollectApi.File['dataFormat'][] = ['EET', 'EMT'];

export const UploadListRequest: React.FC<UploadListRequestProps> = ({
  filters,
  refreshDelay = 3000,
  onRowSelected,
  onDownload,
  onDownloadErrorLog,
  'data-testid': testid,
}) => {
  const [openModal] = useUploadModalOpen();
  const createTestid = useTestid(testid);

  const [pickerStartDate, pickerEndDate] = (() => {
    const periodSplit = filters.referenceDate?.split(',');
    if (periodSplit) {
      const startDate = periodSplit[0] ? periodSplit[0] : undefined;
      const endDate = periodSplit[1] ? periodSplit[1] : undefined;
      return [startDate, endDate];
    }
    return [];
  })();

  const { data: filesData, error: filesError } = useFilesRequest(
    {
      startDate: pickerStartDate,
      endDate: pickerEndDate,
      dataFormats: viewFileTypes,
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: refreshDelay,
    },
  );

  const filteredData = useMemo(() => {
    const uploadStatuses = filters.uploadStatus;
    if (filesData && uploadStatuses?.length) {
      return filesData.filter((file) => uploadStatuses.includes(file.status));
    }
    return filesData;
  }, [filesData, filters.uploadStatus]);

  if (filesData === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (filesError) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!filesData || filesData.length === 0 || !filteredData || filteredData.length === 0) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="upload"
          title="Get started with your upload!"
          subTitle="Start your Manaos journey by uploading your file."
          buttonIcon="upload"
          buttonText="Upload file"
          buttonVariant="secondary"
          buttonOnClick={openModal}
          data-testid={createTestid('empty-files')}
        />
      </Stack>
    );
  }

  return (
    <UploadList
      rowData={filteredData}
      searchValue={filters.search}
      onRowSelected={onRowSelected}
      onDownload={onDownload}
      onDownloadErrorLog={onDownloadErrorLog}
      data-testid={createTestid('list')}
    />
  );
};
