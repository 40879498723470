import type { GridApi } from 'ag-grid-community';
import { Button, Dialogv2, Icon, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateMapping } from '../hooks';
import { UpdateFundMappingForm } from './FundMappingModalInputs';

type FundMappingUpdateModalProps = {
  fundMapping: CollectApi.FundMapping | undefined;
  open: boolean;
  onClose(): void;
  gridApi: MutableRefObject<GridApi | null>;
  'data-testid': string;
};

export const FundMappingUpdateModal: React.FC<FundMappingUpdateModalProps> = ({
  fundMapping,
  open,
  onClose,
  gridApi,
  'data-testid': testid,
}) => {
  const formName = 'form-fund-mapping-update';
  const createTestid = useTestid(testid);

  const { mutate: updateFundMapping, isSuccess, isError, isLoading } = useCreateMapping();

  useEffect(() => {
    if (isSuccess) {
      gridApi.current?.refreshServerSideStore({ purge: true });
      onClose();
      toast.success('Mapping successfuly updated');
    }
    if (isError) {
      onClose();
      toast.error('Failed to update mapping');
    }
  }, [isSuccess, onClose, isError, gridApi]);

  return (
    <Dialogv2
      open={open}
      onClose={onClose}
      dialogTitle="Update mapping"
      DialogTitleIcon={Icon.Edit}
      data-testid={createTestid('dialog')}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoading}
          type="submit"
          form={formName}
          data-testid={createTestid('confirm')}
        >
          Update mapping
        </Button>
      }
    >
      <UpdateFundMappingForm onSave={updateFundMapping} data-testid="test" formId={formName} fundData={fundMapping} />
    </Dialogv2>
  );
};
