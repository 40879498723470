import type { IconButtonProps } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import type { AggridTableColumn, AggridTableProps, IconName } from 'mns-components';
import {
  AggridTable,
  Icon,
  convertToDateTimeLocal,
  convertToDateUTC,
  useDefaultColDef,
  lowerCase,
  makeActionColDef,
  useAutoSize,
  useImmutable,
  useTestid,
  useCallbackImmutable,
  convertToDateTimeUTCWithTZ,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { ExtractionGeneratedHistoryBulk } from './ExtractionGeneratedHistoryBulk';
import { ExtractionsGeneratedStatusRenderer } from './ExtractionsGeneratedStatus';
import { extractionsGeneratedStyles as useStyles } from './styles/extractionsGeneratedStyles';

const getActionColDef = makeActionColDef<DataExtractorApi.Report.ReportLight>();

type ActionButtonProps = IconButtonProps & {
  title: string;
  iconName: IconName;
  'data-testid': string;
};

const ActionButton: React.FC<ActionButtonProps> = ({ title, iconName, 'data-testid': testid, ...props }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} arrow placement="top">
      <span>
        <IconButton data-testid={testid} {...props}>
          <Icon name={iconName} data-testid={`${testid}-icon`} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export type ActionClickHandler = (
  row: DataExtractorApi.Report.ReportLight,
  template?: DataExtractorApi.Template.TemplateLight,
) => void;

type UseColumnsProps = {
  templatesMap: ExtractionsGeneratedListProps['templatesMap'];
  onDownloadExtraction: ExtractionsGeneratedListProps['onDownloadExtraction'];
  onDownloadLogs: ExtractionsGeneratedListProps['onDownloadLogs'];
  onRetryExtraction: ExtractionsGeneratedListProps['onRetryExtraction'];
  'data-testid': string;
};

const useColumns = ({
  templatesMap,
  onDownloadExtraction,
  onDownloadLogs,
  onRetryExtraction,
  'data-testid': testid,
}: UseColumnsProps): AggridTableColumn<DataExtractorApi.Report.ReportLight>[] => {
  const createTestid = useTestid(testid);

  return [
    {
      field: 'extractionName',
      headerName: `Extraction name`,
    },
    {
      field: 'portfolio.externalIdValue',
      headerName: `Portfolio ID`,
    },
    {
      field: 'portfolio.name',
      headerName: 'Portfolio name',
    },
    {
      field: 'idTemplate',
      headerName: 'Template used',
      cellRendererFramework: ({ value }) => templatesMap?.[value]?.name || 'No template selected',
    },
    {
      field: 'portfolio.valuationDate',
      headerName: 'NAV date',
      cellRendererFramework: ({ data }) => convertToDateUTC(data.portfolio.valuationDate),
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Extraction date',
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
      sort: 'desc',
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRendererFramework: ({ data }) => (
        <ExtractionsGeneratedStatusRenderer value={data.status} data-testid={data.id + '-status'} />
      ),
    },
    getActionColDef('id', 130, function ActionCol({ data }) {
      return (
        <>
          <ActionButton
            title="Retry"
            size="small"
            iconName="retry"
            color="secondary"
            disabled={lowerCase(data.status) !== 'failed'}
            onClick={useCallbackImmutable(() => onRetryExtraction(data))}
            data-testid={createTestid(`${data.id}-button-retry`)}
          />
          <ActionButton
            title="Download"
            size="small"
            data-testid={createTestid(`${data.id}-button-download`)}
            disabled={lowerCase(data.status) !== 'complete'}
            iconName="download"
            color="secondary"
            onClick={useCallbackImmutable(() => onDownloadExtraction(data, templatesMap?.[data.idTemplate]))}
          />
          <ExtractionGeneratedHistoryBulk
            disabled={!['failed', 'complete'].includes(lowerCase(data.status))}
            onDownloadLogs={useCallbackImmutable(() => onDownloadLogs(data, templatesMap?.[data.idTemplate]))}
            data-testid={createTestid(`${data.id}-bulk`)}
          />
        </>
      );
    }),
  ];
};

type ExtractionsGeneratedListProps = {
  templatesMap?: Record<string, DataExtractorApi.Template.TemplateLight>;
  onDownloadExtraction: ActionClickHandler;
  onDownloadLogs: ActionClickHandler;
  onRetryExtraction: ActionClickHandler;
  'data-testid': string;
} & Omit<AggridTableProps<DataExtractorApi.Report.ReportLight>, 'columnDefs' | 'defaultColDef'>;

export const ExtractionsGeneratedList: React.FC<ExtractionsGeneratedListProps> = ({
  templatesMap,
  onDownloadExtraction,
  onDownloadLogs,
  onRetryExtraction,
  'data-testid': testid,
  ...props
}: ExtractionsGeneratedListProps) => (
  <AggridTable
    {...props}
    columnDefs={useColumns({
      templatesMap,
      onDownloadExtraction,
      onRetryExtraction,
      onDownloadLogs,
      'data-testid': testid,
    })}
    getRowNodeId={useImmutable(() => (row: DataExtractorApi.Report.ReportLight) => row.id)}
    data-testid={testid}
    defaultColDef={useDefaultColDef<DataExtractorApi.Report.ReportLight>()}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
  />
);
