import { Button, Dialogv2, Icon } from 'mns-components';

import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateUser } from '../../hooks';
import { CreateUserForm } from './UserModalInputs';

type CreateUserModalProps = { modalOpen?: boolean; onCloseModal(): void; 'data-testid': string };

export const CreateUserModal: React.FC<CreateUserModalProps> = ({ modalOpen, onCloseModal, 'data-testid': testid }) => {
  const {
    mutate: createUser,
    isLoading: isLoadingUser,
    isSuccess: isCreatedUser,
    isError: isErrorCreateUser,
  } = useCreateUser();
  const formName = 'form-user-create';

  useEffect(() => {
    if (isCreatedUser) {
      onCloseModal();
      toast.success('User successfuly created');
    }
    if (isErrorCreateUser) {
      onCloseModal();
      toast.error('Failed to create user');
    }
  }, [isCreatedUser, isErrorCreateUser, onCloseModal]);

  return (
    <Dialogv2
      open={!!modalOpen}
      onClose={onCloseModal}
      dialogTitle="Create user"
      DialogTitleIcon={Icon.Profile}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoadingUser}
          type="submit"
          form={formName}
          data-testid={`${testid}-confirm`}
        >
          Create user
        </Button>
      }
      data-testid={`${testid}-dialog`}
    >
      <CreateUserForm data-testid={testid} onSave={createUser} formId={formName} />
    </Dialogv2>
  );
};
