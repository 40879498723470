import type { GridApi, RowSelectedEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  TextField,
  useDefaultColDef,
  useAutoSize,
  useCallbackImmutable,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QualitiesRenderer } from '../../../cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../headerRender/QualitiesHeaderRenderer';
import { createEmailDeliveryModalStyles as useStyles } from '../createEmailDeliveryModalStyles';

const plurial = (count: number) => (count === 1 ? '' : 'es');

const useColumns = (testid: string) =>
  useMemo(
    (): AggridTableColumn<CollectApi.File>[] => [
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'shareClassCount',
        headerName: 'Scope',
        cellRenderer: ({ value }) => {
          return value ? `${value} share class${plurial(value)}` : '';
        },
      },
      {
        field: 'qualities',
        headerName: 'Qualities',
        cellRendererFramework: ({ value, data: { processId: fileId } }) => (
          <QualitiesRenderer qualities={value} data-testid={`${testid}-${fileId}-quality`} />
        ),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-quality`} />,
        minWidth: 100,
      },
    ],
    [testid],
  );

type SelectScopeFileStepProps = {
  rows: CollectApi.File[];
  onRowsSelected(rows: CollectApi.File[]): void;
  getGridApiRef?: React.MutableRefObject<GridApi | null> | ((api: GridApi | null) => void);
  'data-testid': string;
};

export const SelectScopeFileList: React.FC<SelectScopeFileStepProps> = ({
  rows,
  onRowsSelected,
  getGridApiRef,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [searchValue, setSearchValue] = useState('');
  const methods = useForm({ defaultValues: { search: '' } });

  const handleSearchChange = useImmutable(() => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  });

  const onRowsSelectedFct = useCallbackImmutable((ev: RowSelectedEvent) =>
    onRowsSelected(
      (ev.api.getSelectedRows() as CollectApi.File[]).reduce((acc, { processId }) => {
        const found = rows.find((row) => row.processId === processId);
        if (found) acc.push(found);
        return acc;
      }, [] as CollectApi.File[]),
    ),
  );

  const [aggridProps, colSelection] = useRowSelection<CollectApi.File>(onRowsSelectedFct, true);

  return (
    <FormProvider {...methods}>
      <TextField
        name="search"
        label="Search"
        type="text"
        onChange={handleSearchChange}
        search={true}
        className={classes.searchInput}
        data-testid={createTestid('searchInput')}
      />
      <AggridTable
        {...aggridProps}
        onRowSelected={onRowsSelectedFct}
        rowData={rows}
        columnDefs={[colSelection, ...useColumns(testid)]}
        getRowNodeId={useImmutable(() => (row: CollectApi.File) => row.processId)}
        getGridApiRef={getGridApiRef}
        data-testid={testid}
        quickFilterText={searchValue}
        defaultColDef={useDefaultColDef<CollectApi.File>()}
        onGridReady={useAutoSize('fit')}
        pagination
        paginationAutoPageSize
      />
    </FormProvider>
  );
};
