import { Stack } from '@mui/material';
import { Backdrop, useTestid, useStateRef, debounce, EmptyStateV2 } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dataDeliveryApi } from '../../../store/apis';
import type { AppCode } from '../../views/appDescriptions';
import {
  CreateScheduledDeliveryModal,
  EditScheduledDeliveryModal,
} from '../scheduledDeliveryModals/ScheduledDeliveryModal';
import { ScheduledDeliveriesController } from './ScheduledDeliveriesController';
import { ScheduledDeliveriesDeleteModal } from './ScheduledDeliveriesDeleteModal';
import { ScheduledDeliveriesList } from './ScheduledDeliveriesList';

const useCreateDeliveryRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(['dataDeliveryApi.createDeliveryScheduled'], dataDeliveryApi.createDeliveryScheduled, {
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

const useDeliveriesRequest = () =>
  useQuery(['dataDeliveryApi.getDeliveriesScheduled'], dataDeliveryApi.getDeliveriesScheduled);

const useUpdateDeliveryRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(['dataDeliveryApi.updateDeliveryScheduled'], dataDeliveryApi.updateDeliveryScheduled, {
    onSuccess: () => queryClient.invalidateQueries('dataDeliveryApi.getDeliveriesScheduled'),
  });
};

const useDeleteDeliveryRequest = () => {
  const queryClient = useQueryClient();
  return useMutation([], dataDeliveryApi.deleteDeliveryScheduled, {
    onSuccess: () => queryClient.invalidateQueries('dataDeliveryApi.getDeliveriesScheduled'),
  });
};

export const ScheduledDeliveries: React.FC<{
  appCode: AppCode;
  navElement?: React.ReactNode;
  'data-testid': string;
}> = ({ navElement, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [search, setSearch] = useState('');

  // read
  const { data: deliveries, isLoading: isLoadingDeliveries, error: errorDeliveries } = useDeliveriesRequest();
  const deliveriesResultRef = useStateRef(deliveries);

  // create modal
  const {
    mutate: createDeliveryScheduled,
    isLoading: isCreatingDeliveryScheduled,
    isSuccess: isCreatedDeliveryScheduled,
  } = useCreateDeliveryRequest();

  const [isOpenCreateModal, setOpenCreateModal] = useState(false);
  const [[onOpenCreateModal, onCloseCreateModal, onCreate]] = useState([
    () => setOpenCreateModal(true),
    () => setOpenCreateModal(false),
    debounce((create: DataDeliveryApi.CreateDataDeliveryScheduled) => createDeliveryScheduled(create), 200),
  ] as const);

  useEffect(() => {
    if (isCreatedDeliveryScheduled) onCloseCreateModal();
  }, [isCreatedDeliveryScheduled, onCloseCreateModal]);

  // edit modal
  const {
    mutate: updateDeliveryScheduled,
    isLoading: isUpdatingDeliveryScheduled,
    isSuccess: isUpdatedDeliveryScheduled,
  } = useUpdateDeliveryRequest();

  const [isOpenEditModal, setOpenEditModal] = useState<null | DataDeliveryApi.DataDeliveryScheduled>(null);
  const openEditModalRef = useStateRef(isOpenEditModal);
  const [[onOpenEditModal, onCloseEditModal, onEdit, handleToggleEnable]] = useState([
    (selectedItem: string) => {
      const found = deliveriesResultRef.current?.find((item) => item.id === selectedItem);
      if (found) {
        setOpenEditModal(found);
      }
    },
    () => setOpenEditModal(null),
    debounce((update: DataDeliveryApi.CreateDataDeliveryScheduled) => {
      const data = openEditModalRef.current;
      if (data) {
        updateDeliveryScheduled({ ...data, ...update });
      }
    }, 200),
    debounce((selectedItem: string, current: boolean) => {
      const found = deliveriesResultRef.current?.find((item) => item.id === selectedItem);
      if (found) {
        const update: DataDeliveryApi.DataDeliveryScheduled = { ...found, enabled: !current };
        updateDeliveryScheduled(update);
      }
    }, 200),
  ] as const);

  useEffect(() => {
    if (isUpdatedDeliveryScheduled) onCloseEditModal();
  }, [isUpdatedDeliveryScheduled, onCloseEditModal]);

  // delete modal
  const {
    mutate: deleteDeliveryScheduled,
    isLoading: isDeletingDeliveryScheduled,
    isSuccess: isDeletedDeliveryScheduled,
  } = useDeleteDeliveryRequest();

  const [isOpenDeleteModal, setOpenDeleteModal] = useState<null | DataDeliveryApi.DataDeliveryScheduled>(null);
  const openDeleteModalRef = useStateRef(isOpenDeleteModal);
  const [[onOpenDeleteModal, onCloseDeleteModal, onDelete]] = useState([
    (selectedItem: string) => {
      const found = deliveriesResultRef.current?.find((item) => item.id === selectedItem);
      if (found) {
        setOpenDeleteModal(found);
      }
    },
    () => setOpenDeleteModal(null),
    debounce(() => {
      if (openDeleteModalRef.current?.id) {
        deleteDeliveryScheduled(openDeleteModalRef.current?.id);
      }
    }, 200),
  ] as const);

  useEffect(() => {
    if (isDeletedDeliveryScheduled) onCloseDeleteModal();
  }, [isDeletedDeliveryScheduled, onCloseDeleteModal]);

  if (isLoadingDeliveries && !deliveries) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (errorDeliveries) {
    return (
      <>
        <ScheduledDeliveriesController
          navElement={navElement}
          onSearch={setSearch}
          onCreateDelivery={onOpenCreateModal}
          data-testid={createTestid('controller')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-server')}
          />
        </Stack>
        <CreateScheduledDeliveryModal
          open={isOpenCreateModal}
          onSubmit={onCreate}
          disabled={isCreatingDeliveryScheduled}
          onClose={onCloseCreateModal}
          data-testid={createTestid('createEmailDeliveryModal')}
        />
      </>
    );
  }

  if (!deliveries?.length) {
    return (
      <>
        <ScheduledDeliveriesController
          navElement={navElement}
          onSearch={setSearch}
          onCreateDelivery={onOpenCreateModal}
          data-testid={createTestid('controller')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There are no deliveries scheduled yet!"
            data-testid={createTestid('error-empty')}
          />
        </Stack>
        <CreateScheduledDeliveryModal
          open={isOpenCreateModal}
          onSubmit={onCreate}
          disabled={isCreatingDeliveryScheduled}
          onClose={onCloseCreateModal}
          data-testid={createTestid('createEmailDeliveryModal')}
        />
      </>
    );
  }

  return (
    <>
      <ScheduledDeliveriesController
        navElement={navElement}
        onSearch={setSearch}
        onCreateDelivery={onOpenCreateModal}
        data-testid={createTestid('controller')}
      />
      <ScheduledDeliveriesList
        search={search}
        rows={deliveries}
        onToggleEnable={handleToggleEnable}
        onEdit={onOpenEditModal}
        onDelete={onOpenDeleteModal}
        data-testid={testid}
      />
      <CreateScheduledDeliveryModal
        open={isOpenCreateModal}
        onSubmit={onCreate}
        disabled={isCreatingDeliveryScheduled}
        onClose={onCloseCreateModal}
        data-testid={createTestid('createEmailDeliveryModal')}
      />
      <EditScheduledDeliveryModal
        open={isOpenEditModal}
        onSubmit={onEdit}
        disabled={isUpdatingDeliveryScheduled}
        onClose={onCloseEditModal}
        data-testid={createTestid('updateEmailDeliveryModal')}
      />
      <ScheduledDeliveriesDeleteModal
        isDeleting={isDeletingDeliveryScheduled}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
        schelduledDelivery={isOpenDeleteModal}
        data-testid={createTestid('modal-delete')}
      />
    </>
  );
};
