import { convertToDateUTC, makeOpenModalBoolean } from 'mns-components';
import { useQuery } from 'react-query';
import { currentMonthLastDate, firstDateAvailable } from '../../common/date';
import { api } from '../../store/api';
import { appCollectApi } from '../../store/apis';
import type { PortfolioHistoryWithPeriod } from './inventories/modal/CollectHistoryList';
import { getCollectRoute } from './routes';

export const [useUploadModalOpen, useManageUploadModalOpen] = makeOpenModalBoolean(
  () => getCollectRoute('collection-uploads').link,
  'openUploadModal',
);

export const usePortfolioHistory = (portfolioId: string) =>
  useQuery(
    ['appCollectApi.getPortfolioHistory', portfolioId],
    async () =>
      (
        await appCollectApi.getPortfolioHistory({
          start: convertToDateUTC(firstDateAvailable),
          end: convertToDateUTC(currentMonthLastDate),
          portfolioId,
        })
      ).reduce((acc, item) => {
        const { productionPeriod } = item;
        item.details.forEach((history) => {
          acc.push({
            productionPeriod,
            ...history,
          });
        });
        return acc;
      }, [] as PortfolioHistoryWithPeriod[]),
    { enabled: !!portfolioId },
  );

/** Gets all inventories from all portfolios */

export const useAllFundPostion = (portfolioIdList: string[], valuationDate: string) =>
  useQuery(['appCollectApi.getAllFundPosition', valuationDate, portfolioIdList], () =>
    appCollectApi.getAllFundPosition(portfolioIdList, valuationDate),
  );

export const useGetCollectedFunds = (startDate: string, endDate: string) =>
  useQuery(['appCollectApi.getCollectedFunds', startDate], () => appCollectApi.getCollectedFunds(startDate, endDate));

export const useGetInventoriesToCollect = (portfolioId: string, valuationDate: string) => {
  return api.inventories.collect.getInventoriesToCollect.useQuery(portfolioId, valuationDate, {
    enabled: !!portfolioId && !!valuationDate,
    cacheTime: 0,
  });
};
