import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Stack } from '@mui/material';
import { Backdrop, SearchField, convertToDateUTC, useAsyncLazy, useTestid, EmptyStateV2 } from 'mns-components';
import React, { useEffect, useState } from 'react';
import { currentMonthLastDate, firstDateAvailable, previousMonthFirstDate } from '../../../common/date';
import { appCollectApi } from '../../../store/apis';
import { disseminationCommonStyles as useStyles } from '../disseminationCommonStyles';
import { KpiContainer, usePeriodPicker } from '../hooks';
import { SelectInventoryDate } from './components/SelectInventoryDate';
import { DisseminationInventoriesList } from './DisseminationInventoriesList';

export type DisseminationProps = {
  'data-testid': string;
};

export const Dissemination: React.FC<DisseminationProps> = ({ 'data-testid': testid }) => {
  const { trackPageView } = useMatomo();

  const [[startDate, endDate], setPeriod] = usePeriodPicker();
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { result: disseminationData, execute: loadDissemination } = useAsyncLazy(
    (startDt: Date = firstDateAvailable, endDt: Date = currentMonthLastDate) =>
      appCollectApi.getFilesLastVersions(convertToDateUTC(startDt), convertToDateUTC(endDt)),
  );

  useEffect(() => {
    trackPageView({
      documentTitle: `Dissemination App - Dissemination`, // optional
    });
  }, [trackPageView]);

  useEffect(() => {
    if (startDate && endDate) {
      loadDissemination(startDate, endDate);
    }
  }, [loadDissemination, startDate, endDate]);

  useEffect(() => {
    setPeriod([previousMonthFirstDate, currentMonthLastDate]);
  }, [setPeriod]);

  if (!disseminationData) {
    return (
      <>
        <KpiContainer>
          <SelectInventoryDate data-testid={createTestid('datepicker')} />
        </KpiContainer>
        <Backdrop data-testid={createTestid('backdrop')} />
      </>
    );
  }

  if (!disseminationData?.[0]) {
    return (
      <>
        <KpiContainer>
          <SelectInventoryDate data-testid={createTestid('datepicker')} />
        </KpiContainer>
        <div className={classes.gridContainer}>
          <Stack height="100%" justifyContent="center" alignItems="center">
            <EmptyStateV2
              variant="info"
              useCase="noActions"
              title="There are no inventories disseminated yet!"
              data-testid={createTestid('error-empty')}
            />
          </Stack>
        </div>
      </>
    );
  }

  return (
    <>
      <KpiContainer>
        <SelectInventoryDate data-testid={createTestid('datepicker')} />
      </KpiContainer>
      <div className={classes.gridContainer}>
        <div className={classes.searchContainer}>
          <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} />
        </div>
        <DisseminationInventoriesList
          searchValue={searchValue}
          rowData={disseminationData}
          data-testid={createTestid('list')}
        />
      </div>
    </>
  );
};
