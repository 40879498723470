import type { RouterInterface } from '../../components/routing/RouterInterface';
import { MarketplaceAll } from './marketplaceAll/MarketplaceAll';
import { MarketplaceSubscribed } from './marketplaceSubscribed/MarketplaceSubscribed';

const rawRoutes = [
  {
    key: 'marketplace-subscribed',
    link: '/marketplace/subscribed',
    component: MarketplaceSubscribed,
    label: 'My Apps',
    autoselect: true,
    displayTab: true,
    'data-stepid': 'marketplace-subscribed',
  },
  {
    key: 'marketplace-all',
    link: '/marketplace/all',
    component: MarketplaceAll,
    label: 'All Apps',
    displayTab: true,
    'data-stepid': 'marketplace-all',
  },
] as const;

export const getRoute = (key: typeof rawRoutes[number]['key']) =>
  rawRoutes.find((route) => route.key === key) as RouterInterface;

export const getMarketplaceRoute = getRoute;

export const routes = rawRoutes as readonly RouterInterface[];
