import { Stack } from '@mui/material';
import { DialogPopup, Radio, TextField, Typo, colors, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useFetchTemplates } from '../../hooks';
import type { TemplateType } from './SelectTemplateSection';

const mapDialogPropsByType: AnyObject<
  TemplateType,
  {
    description: string;
    radioCreate: string;
    radioHide: string;
    buttonCreate: string;
    buttonHide: string;
  }
> = {
  MY_TEMPLATES: {
    description: 'As you amended the existing template, do you wish to save this template as a new one?',
    radioCreate: 'Extract and save this template as a new one',
    radioHide: 'Extract without saving this template',
    buttonCreate: 'Save and extract',
    buttonHide: 'Extract',
  },
  MANAOS_TEMPLATES: {
    description: 'As you amended a Manaos template, do you wish to save this template as a new one?',
    radioCreate: 'Extract and save this template as a new one',
    radioHide: 'Extract without saving this template',
    buttonCreate: 'Save and extract',
    buttonHide: 'Extract',
  },
  ESG_APPS: {
    description: 'As you defined several data points, do you wish to save this parameter by creating a template?',
    radioCreate: 'Extract and create as a template',
    radioHide: 'Extract without create template',
    buttonCreate: 'Create and extract',
    buttonHide: 'Extract',
  },
};

const errorsMessages = {
  duplicateName: 'There is already a template with the same name.',
  fetchTemplates: 'Something went wrong, please try again or contact support team.',
};

export type ConfirmCreateExtractionFormData = {
  action: 'hide' | 'create';
  templateName: string;
};

type ConfirmCreateExtractionPopupProps = {
  open: boolean;
  onClose(): void;
  templateType: TemplateType;
  onChange(formData: ConfirmCreateExtractionFormData): void;
  onSubmit(): void;
  'data-testid': string;
};

export const ConfirmCreateExtractionPopup = React.memo<ConfirmCreateExtractionPopupProps>(
  function ConfirmCreateExtractionPopup({ open, onClose, templateType, onChange, onSubmit, 'data-testid': testid }) {
    const createTestid = useTestid(testid);
    const props = mapDialogPropsByType[templateType];
    const methods = useForm<ConfirmCreateExtractionFormData>({ defaultValues: { action: 'create', templateName: '' } });
    const { action, templateName } = methods.watch();

    const {
      data: templatesNames,
      isLoading: isLoadingTemplatesNames,
      isError: isErrorTemplatesNames,
    } = useFetchTemplates({
      select: (list) => list.map((tpl) => tpl.name),
    });

    useEffect(() => {
      onChange({ action, templateName });
    }, [onChange, action, templateName]);

    const handleChange = useCallbackImmutable((ev: React.ChangeEvent<HTMLInputElement>) => {
      methods.setValue('action', ev.target.value === 'create' ? 'create' : 'hide');
    });

    const templateNameError = (() => {
      if (templatesNames?.includes(templateName)) return errorsMessages.duplicateName;
      if (isErrorTemplatesNames) return errorsMessages.fetchTemplates;
    })();

    const disabled =
      action === 'create' &&
      (!templateName.length || isLoadingTemplatesNames || templatesNames === undefined || !!templateNameError);

    return (
      <DialogPopup
        open={open}
        onClose={onClose}
        title="Extract data"
        mainButtonText={action === 'create' ? props.buttonCreate : props.buttonHide}
        closeButtonText="Cancel"
        mainButtonDisabled={disabled}
        onMainActionClick={onSubmit}
        data-testid={testid}
      >
        <FormProvider {...methods}>
          <Typo variant="body2">{props.description}</Typo>
          <Radio.Uncontrolled
            name="action"
            value="create"
            color={action === 'create' ? 'primary' : undefined}
            label={props.radioCreate}
            checked={action === 'create'}
            onChange={handleChange}
            data-testid={createTestid('radio-create')}
          />
          <Stack
            margin="0 0 .5rem 2.5rem"
            overflow={action !== 'create' ? 'hidden' : 'visible'}
            height={action !== 'create' ? 0 : templateNameError ? '3.5rem' : '2.5rem'}
            sx={{ transition: 'height .2s ease' }}
          >
            <TextField
              name="templateName"
              label="Template name"
              disabled={action !== 'create'}
              fullWidth
              disableMargin
              data-testid={createTestid('input-templateName')}
              error={!!templateNameError}
              aria-errormessage={templateNameError}
            />
            {templateNameError && (
              <Stack width="max-content" padding="3px 14px 0 14px">
                <Typo variant="caption" color={colors.dangerColor} data-testid={createTestid('templateNameError')}>
                  {templateNameError}
                </Typo>
              </Stack>
            )}
          </Stack>
          <Radio.Uncontrolled
            name="action"
            value="hide"
            color={action === 'hide' ? 'primary' : undefined}
            label={props.radioHide}
            checked={action === 'hide'}
            onChange={handleChange}
            data-testid={createTestid('radio-hide')}
          />
        </FormProvider>
      </DialogPopup>
    );
  },
);
