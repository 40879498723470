import { Stack } from '@mui/material';
import {
  AppContent,
  AppLayout,
  AppMargin,
  Backdrop,
  EmptyStateV2,
  SearchField,
  SplitButton,
  debounce,
  useCallbackImmutable,
  useImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDownloadFile, useDownloadZip } from '../../../common/useDownloadFile';
import type { AppCode } from '../../../components/views/appDescriptions';
import { api } from '../../../store/api';
import { useFetchEetFiles, useQueriesDownloadEet } from '../hooks';
import { getRoute } from '../routes';
import { EetFilesList } from './EetFilesList';

const options = [
  {
    value: 'csv',
    label: 'Download (.csv)',
  },
  {
    value: 'xlsx',
    label: 'Download (.xlsx)',
  },
];

const generateEmtRoute = (eetId?: string) => generatePath(getRoute('generate-eet').link, { eetId });

type EetFilesProps = { 'data-testid': string };

export const EetFiles: React.FC<EetFilesProps> = ({ 'data-testid': testid = 'eet-files-view' }: EetFilesProps) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState<EetApi.EetFile<AppCode>[]>([]);
  const [downloadEet] = useDownloadFile<[fileId: string]>(useQueriesDownloadEet);
  const [downloadEetZip] = useDownloadZip<[fileId: string]>(useQueriesDownloadEet);
  const { data: eetFiles, isLoading: isLoadingEetFiles, refetch: refetchEetFiles, isError } = useFetchEetFiles();

  const createTestid = useTestid(testid);

  const handleDownload = useImmutable(() =>
    debounce(async (eetId: string, format: 'csv' | 'xlsx') => {
      downloadEet([eetId], undefined, format);
    }, 200),
  );

  const handleDownloadMany = useCallbackImmutable(
    debounce(async (_?: unknown, format = 'csv') => {
      const list = selectedRows.filter((row) => row.status === 'COMPLETED').map(({ id }): [string] => [id]);
      downloadEetZip(list, 'export', format);
    }, 200),
  );

  const handleResume = useCallbackImmutable((eetId: string) => navigate(generateEmtRoute(eetId)));

  const handleVisible = useCallbackImmutable(async (eetData: EetApi.EetFile<AppCode>) => {
    const eetId = eetData.id;
    if (eetData.documentId) {
      try {
        await api.eet.unPublishEet.raw(eetId);
        toast(`Your file "${eetData.fileName}" is now private`, { type: 'success' });
      } catch {
        toast(`couldn't unpublish file "${eetData.fileName}"`, { type: 'error' });
      }
    } else {
      try {
        await api.eet.publishEet.raw(eetId);
        toast(`Your file "${eetData.fileName}" is now public`, { type: 'success' });
      } catch {
        toast(`couldn't publish file "${eetData.fileName}"`, { type: 'error' });
      }
    }
    refetchEetFiles();
  });

  const hasCompletedRowSelected = !!selectedRows.find((row) => row.status === 'COMPLETED');

  if (!eetFiles && isLoadingEetFiles) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (isError) {
    return (
      <AppLayout data-testid={testid}>
        <AppContent>
          <Stack justifyContent="center" height="100%">
            <EmptyStateV2
              useCase="dataErrors"
              variant="error"
              title="Oops, something went wrong!"
              subTitle="Please refresh your page or contact our support team."
              data-testid={createTestid('error-eet')}
            />
          </Stack>
        </AppContent>
      </AppLayout>
    );
  }

  if (!eetFiles?.[0]) {
    return (
      <AppLayout data-testid={testid}>
        <AppContent>
          <Stack justifyContent="center" height="100%">
            <EmptyStateV2
              useCase="actions"
              variant="info"
              title="Get started with your EET!"
              subTitle="Start creating your EET to analyse your portfolio(s)."
              buttonVariant="secondary"
              buttonIcon="add"
              buttonText="Create EET"
              buttonHref={generateEmtRoute()}
              data-testid={createTestid('empty-eet')}
            />
          </Stack>
        </AppContent>
      </AppLayout>
    );
  }

  return (
    <AppLayout data-testid={testid}>
      <AppContent variant="expanded-noscroll">
        <AppMargin>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <SearchField value={search} onChange={setSearch} disableMargin data-testid={createTestid('search')} />
            <SplitButton
              label="Download EET"
              options={options}
              color="primary"
              disabled={!hasCompletedRowSelected}
              icon="download"
              onClickButton={handleDownloadMany}
              onClickDropdown={handleDownloadMany}
              data-testid={createTestid('download')}
            />
          </Stack>
        </AppMargin>
        <EetFilesList
          rows={eetFiles}
          search={search}
          onDownload={handleDownload}
          onResume={handleResume}
          onVisible={handleVisible}
          onRowsSelect={setSelectedRows}
          data-testid={createTestid('list')}
        />
      </AppContent>
    </AppLayout>
  );
};
