import { fileExtension, lowerCase, objectMap, objectValues, spinalCase } from 'mns-components';
import type { CollectApi, ProviderApi } from 'mns-sdk-collect';
import type { DownloadApi } from 'mns-sdk-collect/dist/providers/downloadApi';
import { read, utils, write } from 'xlsx';

export const tryParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    // do nothing
  }
  return json;
};

const cookiesSettingsSameSiteValues = ['Strict', 'Lax', 'None'] as const;
type CookiesSettingsSameSite = typeof cookiesSettingsSameSiteValues[number];

type CookiesSettings = {
  /**
   * set current cookies an expiration date,
   * the cookies are removed after this date
   */
  expires?: Date;
  /**
   * set current cookies a max age in seconds from
   * this cookie creation date, the cookies are
   * removed after the delay elapsed
   */
  maxAge?: number;
  /** set current cookies for a single domain */
  domain?: string;
  /** set current cookies for a subpath of domain */
  path?: string;
  /** if sameSite is 'None', secure is true */
  secure?: boolean;
  /** forbids customer to access document.cookies */
  httpOnly?: boolean;
  /**
   * value can be:
   * * 'Strict' means cookies are shared with current domain only.
   * * 'Lax' means cookies are shared with current domain and the external site customer is navigating to.
   * * 'None' means cookies are shared with all domains secured by HTTPS.
   */
  sameSite?: CookiesSettingsSameSite;
};

export const defaultSettings: CookiesSettings = {};

export const setCookiesDefaultSettings = ({
  expires,
  maxAge,
  domain,
  path,
  secure,
  httpOnly,
  sameSite,
}: CookiesSettings) => {
  if (expires !== undefined) defaultSettings.expires = expires;
  if (maxAge !== undefined) defaultSettings.maxAge = maxAge;
  if (domain !== undefined) defaultSettings.domain = domain;
  if (path !== undefined) defaultSettings.path = path;
  if (secure || sameSite === 'None') defaultSettings.secure = true;
  if (httpOnly) defaultSettings.httpOnly = true;
  if (sameSite && cookiesSettingsSameSiteValues.includes(sameSite)) defaultSettings.sameSite = sameSite;
};

export const formatCookiesSettings = ({
  expires,
  maxAge,
  domain,
  path,
  secure,
  httpOnly,
  sameSite,
} = defaultSettings) => {
  const list = [];
  if (expires !== undefined) list.push(`; Expires=${expires}`);
  if (maxAge !== undefined) list.push(`; Max-Age=${maxAge}`);
  if (domain !== undefined) list.push(`; Domain=${domain}`);
  if (path !== undefined) list.push(`; Path=${path}`);
  if (secure || sameSite === 'None') list.push('; Secure');
  if (httpOnly) list.push('; HttpOnly');
  if (sameSite && cookiesSettingsSameSiteValues.includes(sameSite)) list.push(`; SameSite=${sameSite}`);
  return list.join('');
};

export const getCookies = (): AnyObject =>
  document.cookie.split(';').reduce((acc, assign) => {
    const [key, ...others] = assign.split('=');
    const value = others.join('=');
    acc[decodeURIComponent(key.trim())] = value.length ? tryParse(decodeURIComponent(value)) : true;
    return acc;
  }, {} as AnyObject);

export const getCookie = (name: string) => getCookies()[name];

export const setCookie = (key: string, value: unknown, settings?: CookiesSettings) => {
  const settingsString = formatCookiesSettings(settings);
  document.cookie =
    value === true
      ? encodeURIComponent(key)
      : `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}${settingsString}`;
};

/** All cookies will be removed next navigation */
export const clearCookies = () =>
  Object.keys(getCookies()).forEach((key) =>
    setCookie(key, '', {
      expires: new Date(0),
      maxAge: 0,
    }),
  );

export function priceWithCurrency(price: number | string, currency: string | null): string {
  const formatedPrice = price.toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
  if (!currency || currency === 'EUR') {
    return `${formatedPrice} €`;
  }
  if (currency === 'USD') {
    return `$ ${formatedPrice}`;
  }
  if (currency === 'GBP') {
    return `£ ${formatedPrice}`;
  }
  return `${formatedPrice} €`;
}

/* trigger function only once per use case even with multiple calls */
export function debounce(func: AnyFunction, timeout = 300) {
  let timer: number;
  return (...args: AnyArray) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, timeout) as unknown as number;
  };
}

export type BlobTransform = (fileName: string, blob: Blob, inputFormat?: string) => Promise<void> | void;

export const defaultDownloadTransform: BlobTransform = (fileName, blob) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.setAttribute('href', `${blobUrl}`);
  element.setAttribute('id', `${blobUrl}`);
  element.setAttribute('target', `_blank`);
  element.setAttribute('download', `${fileName}`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  window.URL.revokeObjectURL(blobUrl);
};

export const transformBlobAsCsv = async (
  fileName: string,
  blob: Blob,
  inputFormat?: string,
): Promise<AnyObject<string, Blob>> => {
  const ext = fileExtension(fileName);
  if (!ext || lowerCase(inputFormat ?? ext) !== 'csv') {
    const workBook = read(await blob.arrayBuffer());
    const baseFileName = ext ? fileName.slice(0, fileName.length - ext.length - 1) : fileName;
    if (workBook.SheetNames.length === 1) {
      const sheet = workBook.Sheets[workBook.SheetNames[0]];
      const csvBlob = new Blob([utils.sheet_to_csv(sheet)]);
      return { [baseFileName + '.csv']: csvBlob };
    }
    return workBook.SheetNames.reduce((acc, sheetName) => {
      const sheet = workBook.Sheets[sheetName];
      const csvBlob = new Blob([utils.sheet_to_csv(sheet)]);
      acc[baseFileName + '_' + sheetName + '.csv'] = csvBlob;
      return acc;
    }, {} as AnyObject<string, Blob>);
  } else {
    return { [fileName]: blob };
  }
};

export const downloadBlobAsCsv: BlobTransform = async (fileName, blob, inputFormat) => {
  const dico = await transformBlobAsCsv(fileName, blob, inputFormat);
  await Promise.all(objectValues(objectMap(dico, (data, name) => defaultDownloadTransform(name, data))));
};

export const transformBlobAsXlsx = async (
  fileName: string,
  blob: Blob,
  inputFormat?: string,
  separator?: string,
): Promise<AnyObject<string, Blob>> => {
  const ext = fileExtension(fileName);
  if (!ext || lowerCase(inputFormat ?? ext) !== 'xlsx') {
    let workBook = null;
    if (separator) {
      workBook = read(await blob.arrayBuffer(), { FS: separator });
    } else {
      workBook = read(await blob.arrayBuffer());
    }
    const data: Uint8Array = write(workBook, { type: 'buffer' });
    const baseFileName = ext ? fileName.slice(0, fileName.length - ext.length - 1) : fileName;
    return {
      [baseFileName + '.xlsx']: new Blob([data]),
    };
  } else {
    return { [fileName]: blob };
  }
};

export function getDownloadBlobAsXlsxWithSeparator(sep = ';') {
  return async (fileName: string, blob: Blob, inputFormat: string | undefined) => {
    const dico = await transformBlobAsXlsx(fileName, blob, inputFormat, sep);
    await Promise.all(objectValues(objectMap(dico, (data, name) => defaultDownloadTransform(name, data))));
  };
}

export const downloadBlobAsXlsx: BlobTransform = async (fileName, blob, inputFormat) => {
  const dico = await transformBlobAsXlsx(fileName, blob, inputFormat);
  await Promise.all(objectValues(objectMap(dico, (data, name) => defaultDownloadTransform(name, data))));
};

export const transformJsonToCsv = async (fileName: string, blob: Blob) => {
  const ext = fileExtension(fileName);
  const sheet = utils.json_to_sheet(JSON.parse(await blob.text()));
  const csvBlob = new Blob([utils.sheet_to_csv(sheet)]);
  const baseFileName = ext && ext !== 'csv' ? fileName.slice(0, fileName.length - ext.length - 1) + '.csv' : fileName;

  return {
    fileName: baseFileName,
    blob: csvBlob,
  };
};

export const downloadJsonAsCsv: BlobTransform = async (fileName, blob) => {
  try {
    const { fileName: csvFileName, blob: csvBlob } = await transformJsonToCsv(fileName, blob);
    return await defaultDownloadTransform(csvFileName, csvBlob);
  } catch {
    // do nothing
  }
  defaultDownloadTransform(fileName, blob);
};

export const downloadFile = async (
  url: string,
  fileName: string,
  downloadCb = defaultDownloadTransform,
): Promise<void> => {
  const response = await fetch(url, { method: 'GET' });
  if (response.status === 200) {
    const blob = await response.blob();
    await downloadCb(fileName, blob);
  } else {
    throw new Error(`Can not download ${fileName} file`);
  }
};

/**
 * compute a color matching percent, where 0 is red and 1 is green.
 * @param percent between 0 and 1.
 * @returns a CSS string color.
 */
export const percentToColor = (percent: number): string => `hsl(${Math.min(1, Math.max(0, percent)) * 120}, 100%, 40%)`;

/**
 * compute a color matching percent, where 0 is green and 1 is red.
 * @param percent between 0 and 1.
 * @returns a CSS string color.
 */
export const percentToInvertColor = (percent: number): string =>
  `hsl(${Math.min(1, Math.max(0, 1 - percent)) * 120}, 100%, 40%)`;

/**
 * add parameters `params` in query string of `url`.
 * @param url full URL
 * @param params a dictionary
 * @returns a new url string
 */
export const urlSetQueryParams = (url: string, params: Record<string, string>) => {
  const parsed = new URL(url);
  Object.entries(params).forEach(([key, value]) => parsed.searchParams.set(key, value));
  return parsed.toString();
};

/**
 *
 * @param csvString string in CSV format
 * @param fileName string
 * Automatically download CSV file
 */
export const downloadCSV = (csvString: string, fileName: string) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = url;
  link.click();
};

export const UNION = ' — ';

export const splitPortfolioText = (portfolio: string): [string, string] => {
  const [extId, ptfName = ''] = portfolio.split(UNION);
  return [extId, ptfName.trim()];
};
export const getPortfolioText = (
  portfolio: CollectApi.Portfolio | ProviderApi.Portfolio | [string, string | undefined],
) => {
  if (Array.isArray(portfolio)) {
    if (portfolio[0]) {
      if (portfolio[1]) {
        return `${portfolio[0]}${UNION}${portfolio[1]}`;
      }
      return portfolio[0];
    }
    throw new Error('invalid portfolio');
  }

  const ptfName = portfolio.name?.trim();
  if (ptfName) {
    return `${portfolio.externalId.value}${UNION}${ptfName}`;
  }

  return portfolio.externalId.value;
};

export const computeFundPositionDetailStatus = (delta: number) => {
  if (delta === 0) return 'Due today';
  if (delta > 0) return `Due in ${delta} business days`;
  else return `Exceeded by ${Math.abs(delta)} business days`;
};

export async function processPromisesBatch<T>(
  items: Array<T>,
  limit: number,
  fn: (item: T) => Promise<T>,
): Promise<(T | PromiseSettledResult<Awaited<T>>)[]> {
  let results: (T | PromiseSettledResult<Awaited<T>>)[] = [];
  for (let start = 0; start < items.length; start += limit) {
    const end = start + limit > items.length ? items.length : start + limit;

    const slicedResults = await Promise.allSettled(items.slice(start, end).map(fn));

    results = [...results, ...slicedResults];
  }

  return results;
}

export const formatAnalysisFileName = (fileName: string, metadata: DownloadApi.DownloadRequestMetadata) => {
  const fileExt = fileExtension(fileName);
  const fileType = fileExt === 'csv' ? 'underlying_data' : 'aggregated_data';
  const formattedFileName =
    metadata.externalId && metadata.navDate && metadata.applicationCode
      ? `${fileType}-${spinalCase(metadata.externalId)}-${spinalCase(metadata.navDate)}-${spinalCase(
          metadata.applicationCode,
        )}.${fileExt}`
      : `${fileType}-${fileName}`;

  return { formattedFileName, fileExt };
};
