import { colors, makeStyles } from 'mns-components';

const coverageRendererStyles = makeStyles({
  arrow: {
    color: 'white',
  },
  tooltip: {
    cursor: 'pointer',
    backgroundColor: 'white',
    color: colors.defaultFontColor,
  },
  cellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    padding: '.5rem 1rem',
    margin: '0 auto',
    borderRadius: '10px',
  },
  white: {
    color: colors.whiteColor,
  },
  info: {
    color: colors.infoColor,
  },
  black: {
    background: colors.defaultFontColor,
  },
  green: {
    background: colors.greenBlueColor,
  },
});

export default coverageRendererStyles;
