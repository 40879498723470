import { Switch } from 'mns-components';

export const isAutomatedTogglerClickable = (remainingNumber: number | null, buttonsDisabled?: boolean) =>
  !buttonsDisabled && remainingNumber !== null && remainingNumber !== 0;

export type ExtractorPreferencesListAutomatedTogglerProps = {
  automatedRequestStatus: boolean;
  remainingNumber: number | null;
  buttonsDisabled?: boolean;
  onClick?(): void;
  'data-testid': string;
};

export const ExtractorPreferencesListAutomatedToggler: React.FC<ExtractorPreferencesListAutomatedTogglerProps> = ({
  automatedRequestStatus,
  remainingNumber,
  buttonsDisabled,
  onClick,
  'data-testid': testid,
}) => {
  if (remainingNumber === null) return null;

  if (remainingNumber !== 0)
    return <Switch uncontrolled value={automatedRequestStatus} isIconVisible disabled data-testid={testid} />;

  return (
    <Switch
      uncontrolled
      isIconVisible
      disabled={buttonsDisabled}
      value={automatedRequestStatus}
      onChange={onClick}
      data-testid={testid}
    />
  );
};
