import { Modal, Fade } from '@mui/material';
import {
  Button,
  Icon,
  Link,
  joinClass,
  locationReload,
  useCallbackImmutable,
  useImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import React, { useEffect, useState } from 'react';
import { CLOUDFRONT_DNSNAME } from '../../../app.constants';
import { getAuthRoute } from '../../../components/auth/routes';
import { useRefreshToken, useUser } from '../../../hooks/useAuth';
import { useAcceptTermsAndConditions } from '../hooks';
import { marketplaceTCsModalStyles as useStyles } from './styles/marketplaceTCsModalStyles';

const tcsGlobalLink = `//${CLOUDFRONT_DNSNAME}/global/ManaosPlatform_TCs.pdf`;

type ModalColor = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';

const mapColorClasses: Record<ModalColor, keyof ReturnType<typeof useStyles>> = {
  primary: 'titlePrimary',
  secondary: 'titleSecondary',
  success: 'titleSuccess',
  info: 'titleInfo',
  warning: 'titleWarning',
  danger: 'titleDanger',
};

interface MarketplaceTCsModalProps {
  open: boolean;
  color?: ModalColor;
  onClose?(): void;
  'data-testid': string;
}

export const MarketplaceTCsModal: React.FC<MarketplaceTCsModalProps> = ({
  open,
  color = 'info',
  'data-testid': testid,
}: MarketplaceTCsModalProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const refreshToken = useRefreshToken();
  const orgId = useUser().organisationId;

  const [agreement, setAgreement] = useState(false);

  const handleChange = useImmutable(
    () => (event: React.ChangeEvent<HTMLInputElement>) => setAgreement(event.target.checked),
  );

  const { mutateAsync: acceptTerms, status: statusAcceptTerms } = useAcceptTermsAndConditions();

  const handleAcceptTerms = useCallbackImmutable(async () => {
    await acceptTerms(orgId);
    await refreshToken();
    locationReload();
  });

  useEffect(() => {
    if (statusAcceptTerms === 'success') {
      navigate(getAuthRoute('logout').path);
    }
  }, [navigate, statusAcceptTerms]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      className={classes.modal}
    >
      <Fade in={open}>
        <div className={classes.container} data-testid={testid}>
          <h2 className={joinClass(classes.title, classes[mapColorClasses[color]])}>MANAOS - Terms and Conditions</h2>
          <p className={classes.infoParagraph}>
            You don’t have accepted the terms and conditions to use our platform for your organisation. To be able to
            use our platform for your organisation, you have to read and agreed the following T&C’s.
          </p>
          <hr className={classes.hrStyle} />
          <div>
            <div className={classes.termsContainer}>
              <p>
                These Manaos Terms and Conditions including the terms of the Order Confirmation (as defined below) and
                our Privacy Policy (the “<strong>Agreement”</strong>) constitute an agreement between you, acting in the
                name and on behalf of the company for which you are performing your professional activity (“
                <strong>You</strong>
                ”or the “<strong>Client</strong>”) and AELX S.A.S, having its registered office at 1 , boulevard
                Haussmann , 75009 Paris , registered under n° 852 146836, hereinafter (“<strong>Manaos</strong>”,the “
                <strong>Company</strong>” or “<strong>We</strong>”) for the access and use by You of the Manaos Platform
                and the service selected by You and set out in the Order Confirmation and any accompanying documentation
                (collectively, the “<strong>Service</strong>”) from our hosted facility and/or other systems used by
                Manaos to host the Service.
              </p>
              <p>
                You and Manaos maybe referred to as a “<strong>Party</strong>” or collectively “
                <strong>the Parties</strong>
                ”.
              </p>
              <p>
                By creating an Account, You acknowledge having read, understood and accepted to be bound by the
                provisions of this Agreement and of any of its addenda.
              </p>
              <p>
                <strong>1.Order Confirmation:</strong>
              </p>
              <ul>
                <li>
                  Upon acceptance of this Terms and Conditionsand subject to Company’sknow-your-customer requirements,
                  Manaos shall provide You by e-mail with an electronic order confirmation (“Order Confirmation“) which
                  will confirm the Service you ordered, your username and unique password for logging-in and access the
                  Service (collectively, “Account Information”), the URL where you canaccess the Service (the “Access
                  URL”) and the term of Your permitted use of the Service (the “Term”)
                </li>
              </ul>
              <p>
                <strong>2.Account Information and Access URL</strong>
              </p>
              <ul>
                <li>
                  You are solely responsible for the confidentiality and use of Your Account Information and the Access
                  URL and all charges incurred from use of the Service accessed with your Account Information.
                </li>
                <li>
                  In addition to transactions entered into by You on your behalf, You also agree to be bound by the
                  terms of this Agreement for transactions entered into on your behalf by anyone who uses your Account
                  Information. Manaos shall not be responsible for any unauthorized use of your Account Information.
                  Without limiting the generality of the foregoing, in no event shall Manaos be liable for any loss of
                  data or other claims to the extent the same arose from unauthorized access to your Account by
                  obtaining a password caused by a negligent or willfulact on your part.
                </li>
                <li>
                  You may change your password at any time. You must carefully select your password so that it cannot be
                  easily guessed by anyone else.
                </li>
              </ul>
              <p>
                <strong>3.Services on the Manaos Platform and Key Features</strong>
              </p>
              <ul>
                <li>
                  The Service includes a cloud-based platform. The aim of the Manaos platform is to develop an ecosystem
                  based on Amazon Web Services (AWS) public cloud technology that links various Authorized Members
                  including, asset managers, asset owners, data providers and asset servicer for data and services
                  exchanges.
                </li>
                <li>
                  It includes without limitation application for asset managers, asset owners and asset servicers, to
                  improve inventories collection process as described on our website.
                </li>
                <li>
                  For the purpose of this Agreement “<strong>Authorized Member</strong>” shall mean any customer
                  (whether disseminating or collecting data through the Manaos platform ) having signed an agreement
                  with Manaos for the purpose of accessing the Manaos platform and any associated Services, including
                  their employees, subcontractorsand agents who havea need to know for the purpose of accessing the
                  Manaos platform or using the Services.
                </li>
              </ul>

              <p>
                <strong>4.Financial Terms</strong>
              </p>
              <ul>
                <li>
                  The Service includes several features. Some of the features provided by the Service are provided for
                  free (the “<strong>Free Features</strong>”). Complementary features are provided to Client against the
                  payment of fees (the “<strong>Paid-for Features</strong>”) as detailed in the Order Confirmation.
                </li>
                <li>
                  All invoices shall be paid within thirty (30)days of receipt and/or as detailed in the Order
                  Confirmation.
                </li>
              </ul>

              <p>
                <strong>5.Status of the Manaos platform</strong>
              </p>
              <ul>
                <li>
                  Manaos <strong>does not</strong> act, in any manner, as:
                  <ul>
                    <li>
                      a financial investment advisor, the information provided and the services offered by the Service
                      as a whole, or parts thereof, do not constitute and should not be construed, in any way, as an
                      advice, an investment advice, an offer to buy or sell any financial instruments or any
                      solicitation to such effect, a personalized or tailored advice or recommendation or any other
                      recommendation made on behalf of Manaos to invest in any financial instruments;-a broker in
                      financial instruments;
                    </li>
                    <li>
                      any other type of investment service provider (including the notion of investment service provider
                      within the meaning of article L.531-1 of the French Financial and Monetary Code;)
                    </li>
                    <li>
                      a data reporting services provider within the meaning of the Markets in Financial Instruments
                      Directive (MiFID II)2014/65/EU; and
                    </li>
                  </ul>
                </li>
                <li>
                  Manaos acts as the Service’s operator, and provides a technical solution designed to facilitate
                  contact between theAuthorized Membersof the Manaos platform. Manaos acts therefore as a hosting
                  service provider. Manaos’ MANAOSTerms and Conditions January 2021liability is strictly limited to the
                  delivery of this technical solution and under no circumstances shall Manaos be held liable for any
                  claim or damage that may arise out of the relationships established through the Service between the
                  Authorized Members or decisions and/or choices made pursuant to information disseminated and/or
                  collected by anyAuthorized Memberthrough Manaosplatform.
                </li>
                <li>
                  Manaos does not provide a personalized service to the Client and does not make recommendations of
                  products or services based upon the Client’s investment objectives.
                </li>
              </ul>

              <p>
                <strong>6.License Rights and Intellectual Property</strong>
              </p>
              <ul>
                <li>
                  During the Term, Companygrants You a non-transferable and non-exclusive, limited license to remotely
                  access and use the Servicesfor Your internal business purposes inaccordance with the Agreement. You
                  shall not use the Service for any other purposes.
                </li>
                <li>
                  You shall not remove notices and notations in the Servicethat refer to copyrights, trademark rights,
                  patent rights and other intellectual property rights. Unless expressly agreed otherwise herein, any
                  and all patent rights, copyrights, trademark rights and other rights in the Service, as well as any
                  improvements, inventions, design contributions or derivative works conceived or created by either
                  Party in or to the Serviceshall remain the exclusive property of Manaosand/or its licensors. Except
                  for the limited license rights expressly granted herein, this Agreement does not transfer any
                  proprietary right or interest in the Servicesto You. Between You and the Companyall title to and
                  rights in the Services, operational know-how and business secrets related thereto vest exclusively in
                  the Companyand/or its licensors, notably copyright and rights of authorship, rights to inventions, and
                  any other industrial and intellectual property rights. All license rights not expressly granted to You
                  in this Agreement are reserved by the Companyand its licensors. You do not acquire any rights to the
                  source code of any software.
                </li>
                <li>
                  You shall not be entitled to license, sell, lease, rent, outsource or otherwise transfer, make
                  available or otherwise give access to the Serviceto third parties. You may utilize subcontractors for
                  those activities within the scope of this Agreement provided You ensure that such subcontractors
                  adhere to the provisions set forth in this Agreement and You remain liable to the Companyfor the acts
                  and omissions of yoursubcontractors.
                </li>
                <li>
                  You shall not be entitled to duplicate, translate, decompile, reverse-engineer or otherwise modify any
                  parts of the software used for the Services. No development activities are allowed or supported under
                  this Agreement.
                </li>
              </ul>

              <p>
                <strong>7.Maintenance and Access</strong>
              </p>
              <ul>
                <li>
                  Manaos will provide You with telephone or electronic support during Manaos’ normal business hours in
                  order to help You correct problems with the Service.
                </li>
                <li>
                  Manaos cannot guarantee that the Services will be permanently accessible. You acknowledge that
                  Manaosreserves the right to perform maintenance of our systems,the Manaos platformand/or the
                  Serviceand therefore Manaos may suspend access to Manaos platform and/or the Service for maintenance
                  purposesor in case of emergency.
                </li>
              </ul>
              <p>
                <strong>8.Obligations applicable to Clients</strong>
              </p>
              <ul>
                <li>
                  Clients undertake:
                  <ul>
                    <li>
                      to use the Service in accordance with the provisions of this Agreement and in accordance with all
                      applicable laws and regulations;
                    </li>
                    <li>
                      to upload information to the Manaos Platform in accordance with the provisions of this Agreement
                      and all applicable laws and regulations and to assume all responsibilities regarding such
                      information;
                    </li>
                    <li>
                      not to use the Service to disseminate any content that is unlawful, misleading, discriminatory or
                      fraudulent, or that infringes or violates someone else&apos;s rights;
                    </li>
                    <li>
                      not to disclose information they are not allowed to disclose or in respect of which disclosure is
                      prohibited by any applicable law andregulation;
                    </li>
                    <li>
                      not to upload, disclose or disseminate information which would be misleading, privileged or
                      insider information, or otherwise unlawful or prohibited under any applicable law and/or
                      regulation;
                    </li>
                    <li>not use the Service for any unlawful purpose;</li>
                    <li>not to use the Service to send mass or unsolicited messages;</li>
                    <li>
                      not to bypass or attempt to bypass any security measures implemented on the Service or any access
                      controls of the Service;
                    </li>
                    <li>
                      not to reverse engineer, decompile, or otherwise attempt to retrieve the source code of the
                      Service or any related technologythat is not publicly available; and
                    </li>
                    <li>
                      not to use any data mining tools or similar data extraction tools to extract any substantial parts
                      of the content of the Service without prior express written authorization of Manaos.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <strong>9.Warranties and Limitation of Liabilities</strong>
              </p>
              <ul>
                <li>
                  <strong>Warranty.</strong> Manaos represents and warrants to Youthat the Serviceswill
                  performsubstantially in accordance with their documentation.{' '}
                </li>
                <li>
                  <strong>Mutual Warranties.</strong> Each Party represents and warrants to the other that (a) it has
                  full power and authority to enter into and perform this Agreement, (b) the execution and delivery of
                  this Agreement has been duly authorized, (c) it will comply with all applicable laws, rules,
                  regulations and ordinances in the performance of MANAOSTerms and Conditions January 2021this
                  Agreementincluding without limitation anti-money laundering, counter-terrorist financing, anti-bribery
                  and anti-corruption laws, regulations or rules, and (d) its performance hereunder does not breach any
                  other agreement to which it is bound.
                </li>
                <li>
                  <strong>Disclaimer.</strong> Except as expresslyset forth above, and except to the extentprohibited by
                  law, Manaos and its affiliates and licensors make no representation or warranties of any kind whether
                  express, implied, statutory or otherwise, regarding the Manaos platform and/or Services and disclaim
                  all other warranties including any implied or expressed warranties (a) of merchantability,
                  satisfactory quality, fitness for a particular purpose, quiet enjoyment, (b) that the Services will be
                  uninterrupted or error free and (d) that any content including Client Content will be secured or
                  nototherwise lost or damaged.
                </li>
                <li>
                  <strong>Exclusion.</strong> Except for payment obligations, neither Party nor any of their affiliates
                  or licensors will be liable to the other Party under any cause of action or theory of liability, even
                  if a Party has been advised of the possibility of such damages, for (a) indirect, incidental, special,
                  consequential or exemplary damages, (b) the value of Client Content, (c) loss of profits, revenues,
                  customers, opportunities, or goodwill, or (d) unavailability of the Services.
                </li>
                <li>
                  <strong>Cap of Liability.</strong> Except for payment obligations, the aggregate liability under this
                  Agreement of either Party and any of their respective affiliates or licensors will not exceed the
                  higher of (a) the amounts paid by Youto Manaos under this Agreement for the Services that gave rise to
                  the liability during the 12 months before the liability arose, or (b) EUR 10,000 (ten thousand euros);
                </li>
              </ul>
              <p>
                <strong>10.Data Protection and Confidentiality</strong>
              </p>
              <ul>
                <li>Each Party shall comply with applicable data protection laws and regulations.</li>
                <li>
                  This Agreement is subject to the Privacy Policyavailable at our website. Manaoscollects and processes
                  personal data of users only in order to provide the Service described above to You and acts in this
                  capacity as a data controller.
                </li>
                <li>
                  You acknowledge and agree that You are not permitted to request or collect anySensitive Data (as
                  defined below) and shall instruct Your users to not provide anySensitive Data. “
                  <strong>Sensitive Data</strong>” shall include but not be limited to information on racial or ethnic
                  origin, political opinions, religious or philosophical beliefs, trade-union membership, health or sex
                  life and personal data concerning bank and credit accounts.
                </li>
                <li>
                  Usingof the Service involves the input of certain electronic information by You, or on Your behalf
                  including without limitationinformation on Client portfolio (portfolio name, currency, net asset
                  valuation, valuation date...) and its holdings (identification code of the instrument, quantity,
                  amount, market valuation and exposure information, instrument characteristics and issuers...)(“
                  <strong>Client Content</strong>”).We make no guarantee that the Client Content will be available after
                  the end of the Agreement. You are responsible for the ClientContent, including its legality,
                  appropriateness, integrity, completeness, reliability, accuracy and quality.We are allowed to remove
                  any Client Content that we believe breaches these requirements.
                </li>
                <li>
                  For the avoidance of doubt, You will be entitled to remove, at any time and in your sole discretion,
                  any Client Content from the platform, in which case, Manaos shall do whatever is necessary to make
                  such removal effective. Manaos further confirms that any Client Content will only be accessible
                  through the platform to the relevant Authorized Member for which the Client uploaded the Client
                  Content or to which permission has been granted (to the exclusion of the Manaos and any other third
                  party)through Manaos Data Sharing functionality.
                </li>
                <li>
                  IF YOU DISSEMINATE CLIENT CONTENT ON THE MANAOS PLATFORM, YOU ACKNOWLEDGE THAT BY USING THE DATA
                  SHARING FUNCTIONALITY OF THE SERVICE YOU CONSENT TO THE DISCLOSURE OF SUCH CLIENT CONTENT TO
                  AUTHORIZED MEMBERS OF THE MANAOS PLATFORM THAT SHALL BE BOUND BY CONFIDENTIALITY OBLIGATIONSAS
                  DETAILED BELOW. THE DECISION TO SHARE OR NOT ANY OF THE CLIENT CONTENT TO ANY AUTHORIZED MEMBER SHALL
                  BE UNDER YOUR SOLE AND EXCLUSIVE CONTROL AND RESPONSIBILITY.
                </li>
                <li>
                  IF YOU RECEIVE DATA ON THE MANAOS PLATFORM YOU ACKNOWLEDGE THAT BY USING THE SERVICES YOU MAY BE
                  GRANTED ACCESS TO DATA OFANOTHERAUTHORIZED MEMBER (USINGITSELFTHE DATA SHARING FUNCTIONALITY), AND
                  THAT SUCH DATA SHALL BE DEEMED CONFIDENTIAL INFORMATION AND CAN ONLY BE USED AS AUTHORIZED BY SAID
                  AUTHORIZED MEMBER.
                </li>
                <li>
                  Each Party will be exposed to confidential proprietary information belonging to the other Partyor to
                  any Authorized Members, which pertains to the operation of each Party’s and/or each Authorized
                  Member’s business, including without limitation, product samples and prototypes, strategic plans,
                  advertising and marketing materials, trademark, copyright and patent information, research and
                  dataregarding each Party products, finances, customers and consumers (collectively, the “
                  <strong>Confidential Information</strong>
                  ”). All Client Content will be deemed Confidential Information.
                </li>
                <li>
                  The Parties and each Authorized Member shall keep confidential any such Confidential Information. The
                  foregoing obligations will survive any termination of Agreement. The Partiesnor any Authorized Member
                  will, without the prior written consent of the other Party, disclose or use the Confidential
                  Information other than in connection with these Terms and Conditions, or in any way that would be
                  detrimental to the other Party or any Authorized Members.
                </li>
                <li>
                  The Parties and each Authorized Members may only reveal the Confidential Information to their
                  employees, agents, subcontractors representatives, affiliates, principals and advisers on a need to
                  know basis and only for the purpose of accessing the Services, or for the purpose of performing any
                  underlying obligations between any such Authorized Members.
                </li>
                <li>
                  The Partiesand each Authorized Memberswill protect any Confidential Information disclosed onthe Manaos
                  platformusing the same security measures and standard of care that they would apply to safeguard their
                  own Confidential Information.
                </li>
                <li>
                  The obligations of confidentiality shall not apply to any Confidential Information that(i) is known
                  to, or in the possession of, either party prior to the disclosure of that Confidential Information,
                  (ii) is or becomes publicly known otherwise than pursuant to a breach of these Terms and Conditions by
                  either Partyor any Authorized Member, (iii) is required to be disclosed in proceedings before any
                  court or tribunal arising out of, or in connection with, this Agreement; or (iv) is required to be
                  disclosed byany relevant authority under any law, authority, administrative guidelines, directive,
                  request or policy, whether or not having force of law; or (v) is disclosed to Company’s affiliates
                  where required in connection with or for the provision the Service to be provided under these Terms
                  and Conditions, and Companyinternal reporting requirements or international policies, financial or
                  risk management purposes, and anti-money laundering and know-your-customer requirements.
                </li>
              </ul>
              <p>
                <strong>11.Indemnity</strong>
              </p>
              <ul>
                <li>
                  As a condition of use of the Service, You agree to indemnify and hold Manaos harmless from and against
                  any loss, liability, cost, expense or damage arising out of any claim, suit action or judgment brought
                  against Manaos or its affiliates related to Your violation of any provision of this Agreement or any
                  laws or regulations or any third party rights. This indemnification obligation will survive Agreement
                  and Your use of the Service.
                </li>
              </ul>
              <p>
                <strong>12.Term and Termination</strong>
              </p>
              <ul>
                <li>
                  This Agreement enters into force upon the availability of the Serviceto You and remains effective for
                  the Term. It ceases automatically upon expiration of the Termwithout requiring any notice of
                  termination.
                </li>
                <li>
                  Either Party may terminate this Agreement at any time during the Term upon thirty (30) days
                  priorwritten notice to the other Party.
                </li>
                <li>
                  Either Party may terminate this Agreement in the event the other Party breaches an obligation under
                  this Agreement
                </li>
                <li>
                  Upon expiration or termination of this Agreement Your access to the ManaosPlatformandServiceshall be
                  terminated. To the extent applicable,Youshall return to the Companyand/or destroy any
                  documentationprovided by the Companyunder this Agreement. Any further use of the ManaosPlatformand
                  Servicesis not allowed and all parts of the Service, if any, must be removed in its entirety from Your
                  systems.
                </li>
                <li>
                  Upon expiration or termination of this Agreement and upon Your request, Company will delete or destroy
                  any of the Client Content available on the Manaos Platform or still in its possession.
                </li>
              </ul>
              <p>
                <strong>13.General</strong>
              </p>
              <ul>
                <li>
                  This Agreementconstitutes the complete and exclusive statement of the agreement between Companyand You
                  related to the subject matter hereof, and supersedes all prior written and oral contracts, proposals
                  and other communications between the parties relating to the subject matter.
                </li>
                <li>Any changes to this Agreement must be done in writing.</li>
                <li>
                  All notices or reports which are required or may be given pursuant to this Agreement shall be in
                  writing and shall be deemed duly given when delivered to the respective addresses specified by either
                  Party.
                </li>
                <li>
                  You may not assign or otherwise transfer any of its rights under this Agreement without the
                  Company’sprior written consent.
                </li>
                <li>
                  If any provision of this Agreement proves to be invalid, this will not affect any other provisionsof
                  this Agreement.
                </li>
                <li>
                  This Agreement does not entitle either Party to use the other Party’s name, trademark or trade
                  designation for purposes of advertising and marketing without prior written consent of this Party
                  unless provided otherwise herein.
                </li>
                <li>
                  This Agreement shall be governed by and construed under the law of Francelaw without reference to its
                  conflicts of law principles. TheParties agree that the exclusive jurisdictionof the court of Paris.
                </li>
                <li>
                  The waiver by either Party of any of its rights hereunder shall not be construed as a waiver of any
                  subsequent breach.
                </li>
              </ul>
            </div>
            <hr className={classes.hrStyle} />
            <p>
              <input type="checkbox" name="agreement" className={classes.checkbox} onChange={handleChange} />
              <span>
                I agree to Manaos{' '}
                <Link.Underlined extern to={tcsGlobalLink} target="tcs" data-testid={createTestid('link-tcs')}>
                  terms and conditions
                </Link.Underlined>
              </span>
            </p>
            <p className={classes.infoWarningLogout}>
              <Icon.Warning size="small" data-testid={createTestid('icon-warning')} />{' '}
              <span>After accepting the terms and conditions, you will be logged out. Please log back in. </span>
            </p>
            <div className={classes.buttonContainer}>
              <Button
                href={getAuthRoute('logout').path}
                size="medium"
                color="primary"
                outlined
                data-testid={createTestid('button-logout')}
              >
                Logout
              </Button>

              <Button
                disabled={!agreement}
                onClick={handleAcceptTerms}
                size="medium"
                color="primary"
                data-testid={createTestid('button-send')}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
