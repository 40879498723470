import type { CollectApi } from 'mns-sdk-collect';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { appCollectApi } from '../../store/apis';

export const useApplicationSettings = <TSelect = CollectApi.AppCollectSettings>(
  options: UseQueryOptions<CollectApi.AppCollectSettings, unknown, TSelect, AnyArray>,
) =>
  useQuery(
    ['appCollectApi.appSettings.data_collect'],
    async () => appCollectApi.appSettings.data_collect.get(),
    options,
  );
