import type { AggridTableColumn } from 'mns-components';
import { AggridTable, makeActionColDef, useAutoSize, useDefaultColDef, useImmutable, useTestid } from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { OrganisationListActions } from './OrganisationListActions';

type UseColumnsProps = Pick<OrganisationListProps, 'onUpdate' | 'onDelete' | 'data-testid'>;

const getActionColDef = makeActionColDef<EmailListApi.EmailList>();
const useColumns = ({ onUpdate, onDelete, 'data-testid': testid }: UseColumnsProps) => {
  return useMemo(
    (): AggridTableColumn<EmailListApi.EmailList>[] => [
      {
        field: 'title',
        headerName: 'Email list name',
        maxWidth: 250,
      },
      {
        field: 'recipients',
        headerName: 'Recipients',
        flex: 1,
        suppressMenu: true,
      },
      getActionColDef('id', 100, ({ data: emailList }) =>
        OrganisationListActions({
          emailList,
          onUpdate,
          onDelete,
          'data-testid': testid,
        }),
      ),
    ],
    [onUpdate, onDelete, testid],
  );
};

type OrganisationListProps = {
  'data-testid': string;
  emailLists: EmailListApi.EmailList[];
  searchValue: string;
  onUpdate?(emailList: EmailListApi.EmailList): void;
  onDelete?(emailList: EmailListApi.EmailList): void;
};

export const OrganisationList = ({
  'data-testid': testid,
  emailLists,
  searchValue,
  onUpdate,
  onDelete,
}: OrganisationListProps) => {
  const createTestid = useTestid(testid);

  const columns = useColumns({ onUpdate, onDelete, 'data-testid': testid });

  return (
    <AggridTable
      rowData={emailLists}
      quickFilterText={searchValue}
      getRowNodeId={useImmutable(() => (row: EmailListApi.EmailList) => row.id)}
      columnDefs={columns}
      defaultColDef={useDefaultColDef<EmailListApi.EmailList>()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationPageSize={20}
      rowHeight={50}
      data-testid={createTestid('table')}
    />
  );
};
