import type { ColDef, GridApi, RowGroupOpenedEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import { AggridTable, useDefaultColDef, useAutoSize, useImmutable } from 'mns-components';
import type { ClaritySfdrApi } from 'mns-sdk-collect';
import React from 'react';
import CoverageSfdrRenderer from './CoverageSfdrRenderer';
import ValueSfdrRenderer from './ValueSfdrRenderer';

export type MetricData = Partial<ClaritySfdrApi.Metric>;

const autoGroupColumnDef: ColDef = {
  field: 'dictionaryName',
  headerName: 'Metrics',
  minWidth: 450,
  resizable: true,
};

const columns: AggridTableColumn<MetricData>[] = [
  {
    field: 'coverage',
    headerName: 'Coverage',
    minWidth: 100,
    cellRendererFramework: ({ data: { coverage } }) => <CoverageSfdrRenderer coverage={coverage} />,
  },
  {
    field: 'dictionaryName',
    headerName: 'dictionaryName',
    hide: true,
    sort: 'asc',
  },
  {
    field: 'value',
    headerName: 'Value',
    minWidth: 100,
    maxWidth: 150,
    cellRendererFramework: ({ data: { value } }) => <ValueSfdrRenderer value={value} />,
  },
  {
    field: 'units',
    headerName: 'Units',
    minWidth: 100,
    maxWidth: 250,
  },
];

interface ClarityAppSfdrListProps {
  rowData: MetricData[];
  getGridApiRef?(api: GridApi | null): void;
  onRowGroupOpened(api: GridApi): void;
  'data-testid': string;
}

export const ClarityAppSfdrList: React.FC<ClarityAppSfdrListProps> = ({
  rowData,
  getGridApiRef,
  onRowGroupOpened,
  'data-testid': testid,
}: ClarityAppSfdrListProps) => (
  <AggridTable
    rowData={rowData}
    columnDefs={columns}
    getGridApiRef={getGridApiRef}
    onRowGroupOpened={useImmutable(
      () =>
        ({ api }: RowGroupOpenedEvent) =>
          onRowGroupOpened(api),
    )}
    autoGroupColumnDef={autoGroupColumnDef}
    treeData
    getDataPath={useImmutable(
      () =>
        ({ fullPath }: MetricData) =>
          fullPath ?? [],
    )}
    getRowNodeId={useImmutable(
      () =>
        ({ id }: MetricData) =>
          id ?? '',
    )}
    defaultColDef={useDefaultColDef<MetricData>()}
    onGridReady={useAutoSize('fit')}
    data-testid={testid}
  />
);
