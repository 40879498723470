import { IconButton, Menu, Tooltip } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { downloadFilesRendererStyles as useStyles } from './downloadFilesRendererStyles';

type DownloadKey = 'input' | 'output';

const downloadFilesMapping: {
  key: DownloadKey;
  dlType: CollectApi.FileType;
  label: string;
  shouldDisplay?(data: Pick<DownloadFilesRendererProps, 'processId' | 'status' | 'name'>): boolean;
}[] = [
  {
    key: 'input',
    dlType: 'input',
    label: 'Download input file',
  },
  {
    key: 'output',
    dlType: 'output',
    label: 'Download processed file',
    shouldDisplay: ({ status }: CollectApi.File) => status === 'OK' || status === 'WARNING',
  },
];

type DownloadFilesRendererProps = Pick<CollectApi.File, 'processId' | 'status' | 'name'> & {
  'data-testid': string;
  openErrorLogModal: (fileName: string, processId: string) => void;
};

export const DownloadFilesRenderer: React.FC<DownloadFilesRendererProps> = ({
  processId,
  status,
  name,
  openErrorLogModal,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const downloadPresignedUrl = useDownloadPresignedUrl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [previousStatus, setPreviousStatus] = useState<CollectApi.File['status']>();
  const [shouldShake, setShouldShake] = useState(false);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  const handleDownload = useCallbackImmutable(async (downloadKey: DownloadKey) => {
    const { dlType, label } = downloadFilesMapping.find((assoc) => assoc.key === downloadKey)!;
    await downloadPresignedUrl(
      {
        fileDownloadType: dlType,
        fileDownloadTypeLabel: label,
        processId: processId,
        fileName: name,
      },
      true,
    );
  });

  useEffect(() => {
    if (!previousStatus) {
      return setPreviousStatus(status);
    }
    if (
      previousStatus !== 'REJECTED' &&
      previousStatus !== 'WARNING' &&
      (status === 'REJECTED' || status === 'WARNING')
    ) {
      setShouldShake(true);
      return setPreviousStatus(status);
    }
    return undefined;
  }, [previousStatus, status]);

  if ((status && status === 'CANCELED') || !processId) {
    return null;
  }

  const items = downloadFilesMapping.filter(
    ({ shouldDisplay }) => !shouldDisplay || shouldDisplay({ processId, name, status }),
  );

  const onErroLogButtonClick = (processName: string, processIdData: string) => () => {
    openErrorLogModal(processName, processIdData);
  };

  return (
    <>
      <div
        className={classes.bulkContainer}
        data-filename={name}
        data-proccessid={processId}
        data-testid={createTestid(`menubutton`)}
      >
        {['REJECTED', 'WARNING'].includes(status) ? (
          <IconButton
            onClick={onErroLogButtonClick(name, processId)}
            className={shouldShake ? classes.shake : classes.noShake}
          >
            <Tooltip title="View error log" arrow placement="left-start">
              <Icon.Error size="medium" color="secondary" data-testid={createTestid('icon-error')} />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton disableTouchRipple style={{ cursor: 'default' }} className={classes.noShake}>
            <Tooltip title="No log for this file" arrow placement="left-start">
              <Icon.Error size="medium" color="disabled" data-testid={createTestid('icon-error')} />
            </Tooltip>
          </IconButton>
        )}
        <IconButton color="secondary" onClick={handleClick} data-testid={createTestid('button-menu')}>
          <Icon.More size="medium" data-testid={createTestid('icon-menu')} />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-filename={name}
        data-proccessid={processId}
        data-testid={createTestid('menu')}
      >
        {items.map((buttonData, iterator) => (
          <MenuItem.Loading
            value={buttonData.key}
            onClick={handleDownload}
            data-testid={createTestid(`download-${iterator}`) ?? ''}
            key={buttonData.key}
          >
            {buttonData.label}
          </MenuItem.Loading>
        ))}
      </Menu>
    </>
  );
};
