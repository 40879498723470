import { InputAdornment } from '@mui/material';
import type { FormStepErrors, StepProps } from 'mns-components';
import {
  ButtonList,
  DatePicker,
  Frame,
  TextField,
  convertToDateLocal,
  isDate,
  isString,
  makeFieldChange,
  makeStyles,
  useCallbackImmutable,
  useNavigate,
  useStepperButtons,
  useTestid,
} from 'mns-components';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { eetApi } from '../../../store/apis';
import { useCreateEet, useUpdateEetDate } from '../hooks';
import { getRoute } from '../routes';
import type { GenerateEetFormSteps } from './types';

const useStyles = makeStyles({
  dateStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: '2rem',
    width: '20rem',
    margin: 'auto',
  },
  inputMargined: {
    margin: '0 0 2rem 0',
    height: '3.5rem',
  },
});

const getDateStringOrNull = (value: Date | null) => (value && convertToDateLocal(value)) || null;

export const validSelectDateStep = ({ generalReferenceDate, fileName }: GenerateEetFormSteps[0]) => {
  const errors: FormStepErrors<GenerateEetFormSteps[0]> = [];

  if (!isString(generalReferenceDate)) {
    errors.push({
      name: 'generalReferenceDate',
      message: 'Invalid date format',
    });
  }

  if (!isString(fileName) || !fileName.length) {
    errors.push({
      name: 'fileName',
      message: 'Invalid file name format',
    });
  }

  if (fileName?.match(/[./]/)) {
    errors.push({
      name: 'fileName',
      message: 'Filename should not contain / or .',
    });
  }

  if (errors.length) {
    return errors;
  }
};

type SelectDateStepProps = StepProps<GenerateEetFormSteps, 0>;

export const SelectDateStep: React.FC<SelectDateStepProps> = ({
  stepValue,
  setStepValue,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const date = isDate(stepValue.generalReferenceDate) ? new Date(stepValue.generalReferenceDate) : null;

  const createTestid = useTestid(testid);
  const classes = useStyles();
  const { eetId } = useParams<{ eetId?: string }>();
  const adornment = date ? `EET-${convertToDateLocal(date)}-` : 'EET-yyyy-mm-dd-';
  const navigate = useNavigate();

  const [errors, setErrors] = useState<FormStepErrors<GenerateEetFormSteps[0]> | null>(null);

  const useFieldChange = makeFieldChange(setErrors, setStepValue);
  const onDateChange = useFieldChange('generalReferenceDate', getDateStringOrNull);
  const onFileNameChange = useFieldChange('fileName');

  const {
    mutate: createEet,
    data: createdEet,
    isLoading: isCreatingEet,
    isSuccess: isCreatedEet,
    error: errorCreateEet,
  } = useCreateEet();

  const {
    mutate: updateEetDate,
    isLoading: isUpdatingEetDate,
    isSuccess: isUpdatedEetDate,
    error: errorUpdateEetDate,
  } = useUpdateEetDate(eetId);

  const handleNext = useCallbackImmutable(() => {
    const catched = validSelectDateStep(stepValue);
    if (!catched?.length) {
      if (!eetId) {
        createEet({
          generalReferenceDate: stepValue.generalReferenceDate!,
          fileName: adornment + stepValue.fileName!,
        });
      } else {
        updateEetDate({
          generalReferenceDate: stepValue.generalReferenceDate!,
          fileName: adornment + stepValue.fileName!,
        });
      }
    } else {
      setErrors(catched);
    }
  });

  useEffect(() => {
    if (isCreatedEet && createdEet) {
      navigate(generatePath(getRoute('generate-eet').link, { eetId: createdEet.id }));
      goNext();
    } else if (isUpdatedEetDate) {
      goNext();
    }
  }, [navigate, goNext, isCreatedEet, isUpdatedEetDate, createdEet]);

  useEffect(() => {
    if (errorCreateEet || errorUpdateEetDate) {
      if (eetApi.isErrorFileNameTaken(errorCreateEet || errorUpdateEetDate)) {
        setErrors([
          {
            name: 'fileName',
            message: 'EET file name already exists in your organisation',
          },
        ]);
      } else {
        setErrors([
          {
            name: 'fileName',
            message: 'Something went wrong, please try again or contact the support',
          },
        ]);
      }
    }
  }, [errorCreateEet, errorUpdateEetDate]);

  const errorDate = errors?.find((err) => err.name === 'generalReferenceDate')?.message;
  const errorFile = errors?.find((err) => err.name === 'fileName')?.message;

  return (
    <Frame variant="margined" data-testid={testid}>
      <div className={classes.dateStep}>
        <div className={classes.inputMargined}>
          <DatePicker
            uncontrolled
            label="Reference date"
            value={date}
            onFieldChange={onDateChange}
            helperText={errorDate}
            data-testid={createTestid('referenceDate')}
          />
        </div>
        <div className={classes.inputMargined}>
          <TextField
            uncontrolled
            name="fileName"
            label="EET file name"
            value={stepValue.fileName}
            type="text"
            InputProps={{
              startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
            }}
            onFieldChange={onFileNameChange}
            disabled={!date}
            error={!!errorFile}
            helperText={errorFile}
            data-testid={createTestid('fileName')}
          />
        </div>
      </div>
      <Buttons>
        <ButtonList
          center
          buttons={useStepperButtons({
            onNext: handleNext,
            previousDisabled: true,
            previousLabel: 'Back',
            nextDisabled: !!errors?.length,
            nextLoading: isCreatingEet || isUpdatingEetDate,
            'data-testid': createTestid('button'),
          })}
          data-testid={createTestid('buttons')}
        />
      </Buttons>
    </Frame>
  );
};
