import { IconButton, Stack } from '@mui/material';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Button,
  Icon,
  SearchField,
  TextField,
  isEmail,
  makeActionColDef,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type EmailType = {
  id: number;
  email: string;
};

type UseColumnsProps = {
  onDeleteEmail: (email: EmailType) => void;
  'data-testid': string;
};

type TableIconsCompProps = {
  data: EmailType;
  onDelete(row: EmailType): void;
  'data-testid': string;
};

const TableIconsComp = ({ data, onDelete, 'data-testid': testid }: TableIconsCompProps) => {
  const createTestid = useTestid(testid);

  const handleDelete = useCallbackImmutable(() => {
    onDelete?.(data);
  });

  return (
    <IconButton size="small" color="error" onClick={handleDelete} data-testid={createTestid('button-delete')}>
      <Icon.Delete data-testid={createTestid('icon-delete')} />
    </IconButton>
  );
};

const getActionColDef = makeActionColDef<EmailType>();
const useColumns = ({ onDeleteEmail, 'data-testid': testid }: UseColumnsProps) => {
  return useMemo(
    (): AggridTableColumn<EmailType>[] => [
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        suppressMenu: true,
      },
      getActionColDef('email', 60, ({ data: email }) => (
        <TableIconsComp data={email} onDelete={onDeleteEmail} data-testid={testid} />
      )),
    ],
    [onDeleteEmail, testid],
  );
};

const getEmailListDataFormat = (emailList: EmailListApi.EmailList | undefined) => {
  if (emailList) {
    const newEmailList: EmailType[] = [];
    emailList.recipients.forEach((item, index) => newEmailList.push({ id: index, email: item }));
    return newEmailList;
  }
  return [];
};

type OrganisationModalFormProps<T extends EmailListApi.EmailList | undefined, D = EmailListApi.EmailList> = {
  emailList?: T;
  onSave(data: D | Omit<D, 'id'>): void;
  formId?: string;
  'data-testid': string;
};

export const OrganisationModalForm = <T extends EmailListApi.EmailList | undefined, D = EmailListApi.EmailList>({
  emailList,
  onSave,
  formId,
  'data-testid': testid,
}: OrganisationModalFormProps<T, D>): React.ReactElement => {
  const createTestid = useTestid(testid);
  const [inputTitleValue, setInputTitleValue] = useState<string>(emailList?.title ?? '');
  const [inputTitleError, setInputTitleError] = useState<boolean>(false);
  const [inputEmailValue, setInputEmailValue] = useState<string>('');
  const [inputEmailError, setInputEmailError] = useState<boolean>(false);
  const [emailListData, setEmailListData] = useState<EmailType[]>(getEmailListDataFormat(emailList));

  const [searchValue, setSearchValue] = useState<string>('');

  const methods = useForm();

  const onAddEmail = useCallbackImmutable(() => {
    setEmailListData([...emailListData, { id: emailListData.length + 1, email: inputEmailValue }]);
    setInputEmailValue('');
  });
  const onDeleteEmail = useCallbackImmutable((email: EmailType) => {
    setEmailListData((oldValues) => {
      return oldValues.filter((item) => item !== email);
    });
  });

  const onSubmit = () => {
    if (inputTitleValue === '') {
      setInputTitleError(true);
      return;
    }
    if (emailListData.length === 0) {
      setInputEmailError(true);
      return;
    }

    const newEmailLists: string[] = [];
    emailListData.forEach((item) => newEmailLists.push(item.email));

    onSave({
      title: inputTitleValue.trim(),
      recipients: newEmailLists,
      ...(emailList && { id: emailList.id }),
      description: '',
    } as D);
  };

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)} id={formId} data-testid={createTestid('form')}>
        <Stack gap="1rem">
          <TextField
            label="List name *"
            type="text"
            name="title"
            disableMargin
            value={inputTitleValue}
            onFieldChange={setInputTitleValue}
            uncontrolled
            error={inputTitleError && inputTitleValue === ''}
            helperText={inputTitleError && inputTitleValue === '' && 'This field is required'}
            data-testid={createTestid('name-input')}
          />
          <Stack flexDirection="row" gap="1.5rem" flex="1">
            <TextField
              label="Email adress"
              type="text"
              name="email"
              value={inputEmailValue}
              onFieldChange={setInputEmailValue}
              disableMargin
              data-testid={createTestid('new-email-input')}
              error={
                (!isEmail(inputEmailValue) && inputEmailValue !== '') || (inputEmailError && emailListData.length === 0)
              }
              helperText={
                (!isEmail(inputEmailValue) && inputEmailValue !== '' && 'Wrong email format') ||
                (inputEmailError && emailListData.length === 0 && 'You need to add at least 1 email.')
              }
              uncontrolled
              fullWidth
            />
            <Button
              color="primary"
              disabled={!isEmail(inputEmailValue) || inputEmailValue === ''}
              onClick={onAddEmail}
              data-testid={createTestid('button-add')}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </form>
      <Stack margin="1.5rem 0 1rem 0">
        <SearchField
          data-testid="modal-search"
          value={searchValue}
          onChange={setSearchValue}
          disableMargin
          disabled={emailListData.length < 1}
        />
      </Stack>
      <Stack>
        <AggridTable
          rowData={emailListData}
          quickFilterText={searchValue}
          getRowNodeId={useImmutable(() => (row: { email: string }) => row.email)}
          columnDefs={useColumns({ onDeleteEmail, 'data-testid': testid })}
          defaultColDef={useDefaultColDef<EmailType>()}
          onGridReady={useAutoSize('fit')}
          rowHeight={50}
          domLayout="autoHeight"
          data-testid={createTestid('table')}
        />
      </Stack>
    </>
  );
};

export const CreateEmailListForm = OrganisationModalForm<undefined>;
export const UpdateEmailListForm = OrganisationModalForm<EmailListApi.EmailList>;
