import type { RouterInterface } from '../../components/routing/RouterInterface';
import { EmailDelivery } from './delivery/EmailDelivery';
import { Uploads } from './files/Uploads';
import { Dissemination } from './inventories/Dissemination';
import { ReceiverPermissions } from './permissions/ReceiverPermissions';

const rawRoutes = [
  {
    label: 'Inventories to disseminate',
    isDisabled: false,
    link: '/apps/manaos-dissemination/dashboard',
    component: Dissemination,
    key: 'dissemination-dashboard',
    'data-stepid': 'tab-dissemination-dashboard',
  },
  {
    label: 'Access requests',
    isDisabled: false,
    link: '/apps/manaos-dissemination/permissions',
    component: ReceiverPermissions,
    key: 'dissemination-permissions',
    'data-stepid': 'tab-dissemination-permissions',
  },
  {
    label: 'Email delivery',
    isDisabled: false,
    link: '/apps/manaos-dissemination/email-delivery/:view',
    linkDefaultArgs: { view: 'past-deliveries' },
    component: EmailDelivery,
    key: 'dissemination-email-delivery',
    expectRole: 'emailDeliveryActive',
  },
  {
    label: 'My files',
    isDisabled: false,
    link: '/apps/manaos-dissemination/files',
    component: Uploads,
    key: 'dissemination-uploads',
    'data-stepid': 'tab-dissemination-uploads',
  },
] as const;

export type DisseminationRouteKey = typeof rawRoutes[number]['key'];
export const routes = rawRoutes as readonly RouterInterface[];

export const getDisseminationRoute = (key: DisseminationRouteKey) => routes.find((route) => route.key === key)!;
