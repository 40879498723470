import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  filterTruthy,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { AnalysisListStatusRenderer } from '../../../../components/cellRender/AnalysisListStatusRenderer';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../../components/headerRender/QualitiesHeaderRenderer';
import { UploadActions } from './UploadActions';

const getActionColDef = makeActionColDef<CollectApi.File>();

type UploadListColumnsProps = {
  colSelectProps: AggridTableColumn<CollectApi.File>;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  testid: string;
};

const useColumns = ({ colSelectProps, onDownload, onDownloadErrorLog, testid }: UploadListColumnsProps) =>
  useMemo(
    () =>
      filterTruthy<AggridTableColumn<CollectApi.File>>([
        colSelectProps,
        {
          field: 'name',
          headerName: 'File name',
          minWidth: 200,
        },
        {
          field: 'dataFormat',
          headerName: 'File type',
        },
        {
          field: 'referenceDate',
          headerName: 'Reference date',
        },
        {
          field: 'uploadedOn',
          headerName: 'Uploaded on',
          sort: 'desc',
          valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.uploadedOn),
          cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.uploadedOn),
        },
        {
          field: 'shareClassCount',
          headerName: 'Scope',
          cellRendererFramework: ({ value }) =>
            value && Number.isNaN(value) ? 'Not yet defined' : `${value} share classes`,
        },
        {
          field: 'uploadSource',
          headerName: 'Upload source',
        },
        {
          field: 'status',
          headerName: 'Upload status',
          cellRendererFramework: ({ value, data: { processId } }) => (
            <AnalysisListStatusRenderer value={value} data-testid={`${testid}-${processId}-render`} />
          ),
        },
        {
          field: 'qualities',
          headerName: 'Qualities',
          cellRendererFramework: ({ value, data: { processId } }) => (
            <QualitiesRenderer qualities={value} data-testid={`${testid}-${processId}-qualities`} />
          ),
          headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
          minWidth: 100,
        },
        (onDownload || onDownloadErrorLog) &&
          getActionColDef('status', 90, ({ value, data }) => (
            <UploadActions
              status={value}
              fileData={data}
              onDownload={onDownload}
              onDownloadErrorLog={onDownloadErrorLog}
              data-testid={`${testid}-${data.processId}-uploadActions`}
            />
          )),
      ]),
    [colSelectProps, onDownload, onDownloadErrorLog, testid],
  );

type UploadListProps = {
  rowData: CollectApi.File[];
  searchValue?: string;
  onRowSelected(rows: CollectApi.File[]): void;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  'data-testid': string;
};

export const UploadList: React.FC<UploadListProps> = ({
  rowData,
  // currentView,
  searchValue = '',
  onRowSelected,
  onDownload,
  onDownloadErrorLog,
  'data-testid': testid,
}: UploadListProps) => {
  const [gridProps, colSelectProps] = useRowSelection<CollectApi.File>(({ api }) =>
    onRowSelected(api.getSelectedRows()),
  );
  return (
    <AggridTable
      quickFilterText={searchValue}
      rowData={rowData}
      defaultColDef={useDefaultColDef<CollectApi.File>()}
      columnDefs={useColumns({ colSelectProps, onDownload, onDownloadErrorLog, testid })}
      getRowNodeId={useImmutable(() => (row: CollectApi.File) => row.processId)}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      {...gridProps}
      data-testid={testid}
    />
  );
};
