// Material UI
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// Component
import { Icon, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import { EmitterPermissionsRequestAccessForm } from '../../../../components/forms/EmitterPermissionsRequestAccessForm';
import type { EmitterPermissionFormData } from '../../../../components/forms/types';
import useStyles from '../styles/RequestAccessPermission.styles';

interface AccessRequestProps {
  selectedItems: CollectApi.AccessDemand[];
  closeModal: () => void;
  savePermissionsFormHandler: (formData: EmitterPermissionFormData) => Promise<void>;
  'data-testid': string;
}

export const ReOpenAccessPermission: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  savePermissionsFormHandler,
  'data-testid': testid,
}: AccessRequestProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <>
      <div className={classes.titleContainerWithIcon}>
        <Icon.Info data-testid={createTestid('icon-info')} />
        <h3 className={classes.cardTitles}>You are about to request access for the following shareclass</h3>
      </div>
      <h3 className={classes.subTitles}>Shareclass selected</h3>
      <Table aria-label="simple table" data-testid={createTestid('table-selected')}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaders}>Shareclass ID</TableCell>
            <TableCell className={classes.tableHeaders}>Shareclass name</TableCell>
            <TableCell className={classes.tableHeaders}>Asset manager</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItems.map((row) => (
            <TableRow key={`${row.portfolioId}-${row.externalId}`}>
              <TableCell component="th" scope="row">
                {row.externalId}
              </TableCell>
              <TableCell className={classes.tableContent}>{row.fundName}</TableCell>
              <TableCell className={classes.tableContent}>{row.receptor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EmitterPermissionsRequestAccessForm
        handleSave={savePermissionsFormHandler}
        handleCancel={closeModal}
        data-testid={createTestid('form')}
      />
    </>
  );
};
