import { Dialog } from 'mns-components';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { useApplicationDetailsRequest, usePortfoliosRequest } from '../../hooks';
import { ConsumeRequestStepper } from './ConsumeRequestStepper';
import { PayRequestStepper } from './PayRequestStepper';

type CreateRequestModalProps = {
  appCode: AppCode;
  isOpen: boolean;
  onClose(): void;
  disablePtfAndDate?: boolean;
  'data-testid': string;
};

export const CreateRequestModal: React.FC<CreateRequestModalProps> = ({
  appCode,
  isOpen,
  onClose,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const {
    config: { analysisRequestType },
  } = useApplicationConfig(appCode);
  const { data: portfolios } = usePortfoliosRequest(appCode);
  const { data: applicationDetails } = useApplicationDetailsRequest(appCode);

  if (!portfolios?.[0] || !applicationDetails) {
    return null;
  }

  if (analysisRequestType === 'pay') {
    return (
      <Dialog
        dialogTitle="Portfolio analysis order form"
        open={isOpen}
        onClose={onClose}
        data-testid={`${testid}-dialog`}
      >
        <PayRequestStepper
          appCode={appCode}
          portfolios={portfolios}
          applicationDetails={applicationDetails}
          closeModal={onClose}
          disablePtfAndDate={disablePtfAndDate}
          data-testid={testid}
        />
      </Dialog>
    );
  }

  if (analysisRequestType === 'remaining') {
    return (
      <Dialog
        dialogTitle="Portfolio analysis order form"
        open={isOpen}
        onClose={onClose}
        data-testid={`${testid}-dialog`}
      >
        <ConsumeRequestStepper
          appCode={appCode}
          portfolios={portfolios}
          applicationDetails={applicationDetails}
          closeModal={onClose}
          disablePtfAndDate={disablePtfAndDate}
          data-testid={testid}
        />
      </Dialog>
    );
  }

  if (analysisRequestType === 'enrich') {
    return (
      <Dialog dialogTitle="Portfolio enrichment form" open={isOpen} onClose={onClose} data-testid={`${testid}-dialog`}>
        <ConsumeRequestStepper
          appCode={appCode}
          portfolios={portfolios}
          applicationDetails={applicationDetails}
          closeModal={onClose}
          disablePtfAndDate={disablePtfAndDate}
          data-testid={testid}
        />
      </Dialog>
    );
  }

  throw new Error('This application can not send analysis requests');
};
