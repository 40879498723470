import { Stack } from '@mui/material';
import { Icon, Typography, colors } from 'mns-components';
import React from 'react';

interface Data {
  status: string;
  sharingStatus?: string;
}

interface Props {
  value?: Data;
  'data-testid': string;
}

export const PublicationStateRenderer = React.memo<Props>(function PublicationStateRendererComp({
  value,
  'data-testid': testid,
}) {
  switch (value?.sharingStatus) {
    case 'NOT_DISSEMINATED':
      if (value.status && value.status.includes('exceeded')) {
        return (
          <Stack direction="row" spacing=".5rem">
            <Icon.Warning size="small" color="danger" data-testid={`${testid}-icon`} />
            <Typography variant="caption" color={colors.dangerColor}>
              {value.status}
            </Typography>
          </Stack>
        );
      }
      return (
        <Stack direction="row" spacing=".5rem">
          <Icon.Info size="small" color="secondary" data-testid={`${testid}-icon`} />
          <Typography variant="caption">{value.status}</Typography>
        </Stack>
      );
    case 'DISSEMINATED':
      if (value.status === 'late') {
        return (
          <Stack direction="row" spacing=".5rem">
            <Icon.Warning size="small" color="warning" data-testid={`${testid}-icon`} />
            <Typography variant="caption">published late</Typography>
          </Stack>
        );
      }
      return (
        <Stack direction="row" spacing=".5rem">
          <Icon.Success size="small" color="success" data-testid={`${testid}-icon`} />
          <Typography variant="caption">on time</Typography>
        </Stack>
      );
    default:
      return null;
  }
});

export const getPublicationStatePlain = (value: Data) => {
  switch (true) {
    case value.sharingStatus === 'NOT_DISSEMINATED':
      return value.status;
    case value.sharingStatus === 'DISSEMINATED' && value.status === 'late':
      return 'published late';
    case value.sharingStatus === 'DISSEMINATED':
      return 'on time';
    default:
      return '';
  }
};
