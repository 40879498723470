import { Icon, useTestid } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { formStyles as useStyles } from './formStyles';

const remainingDisplay = (count: NonNullable<MarketplaceApi.Application['subscriptionsDto']>['remainingAnalysis']) => {
  if (count === -1) return 'Unlimited';
  if (count === 0) return 'No more analysis to order';
  return `${count} remaining analysis to order`;
};

type SubscriptionDetailsProps = {
  subscription: NonNullable<MarketplaceApi.Application['subscriptionsDto']>;
  'data-testid': string;
};

export const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({ subscription, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const offer = subscription?.offerDto;

  return (
    <div className={classes.form} data-testid={testid}>
      <div className={classes.flexRow}>
        <div className={classes.flexRow}>
          <div className={classes.icon}>
            <Icon.Localoffer data-testid={createTestid('icon-localoffer')} />
          </div>
          {offer && (
            <div>
              <h3 className={classes.formTitle} data-testid={createTestid('offerName')}>
                {offer.offerName}
              </h3>
              <div className={classes.list}>
                {offer.features?.map((feat, index) => (
                  <span
                    className={classes.feature}
                    key={index} // NOSONAR
                  >
                    <Icon.Check data-testid={createTestid('icon-check')} />
                    {feat}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={classes.price} data-testid={createTestid('remaning')}>
          {remainingDisplay(subscription.remainingAnalysis)}
        </div>
      </div>
    </div>
  );
};
