import { Stack } from '@mui/material';
import { PeriodPicker, SearchField, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, DataExtractorApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { currentMonthLastDate, subtractYears } from '../../../../../common/date';
import type { PortfolioData } from '../SelectPtfSection';
import { SelectPtfSectionRequest } from './SelectPtfSectionRequest';

type SelectPtfSectionViewProps = {
  ptfList?: PortfolioData[];
  extractionType: DataExtractorApi.Extraction.ExtractionType;
  view: CollectApi.PortfolioOrigin;
  initialStartDate?: Date;
  initialEndDate?: Date;
  selectedRows?: PortfolioData[];
  setSelectedRows: React.Dispatch<React.SetStateAction<PortfolioData[]>>;
  disabled?: boolean;
  'data-testid': string;
};

export const SelectPtfSectionView: React.FC<SelectPtfSectionViewProps> = ({
  ptfList,
  extractionType,
  view,
  initialEndDate = currentMonthLastDate,
  initialStartDate = subtractYears(initialEndDate, 3),
  selectedRows = [],
  setSelectedRows,
  disabled,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  // Date Picker
  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const handleDateChange = useCallbackImmutable(([start, end]: [Date | null, Date | null]) => {
    setStartDate(start ?? subtractYears(currentMonthLastDate, 3));
    setEndDate(end ?? currentMonthLastDate);
  });

  const [searchValue, setSearchValue] = useState('');

  const ptfListByView = useMemo(() => ptfList?.filter((ptf) => ptf.origin == view), [ptfList, view]);

  return (
    <>
      <Stack direction="row" gap=".5rem" alignItems="center">
        <SearchField
          fullWidth
          disableMargin
          disabled={disabled}
          label="Search portfolio ID, portfolio name"
          value={searchValue}
          onChange={setSearchValue}
          data-testid={createTestid('search')}
        />
        {extractionType != 'AUTOMATIC' && (
          <PeriodPicker
            label="NAV date period"
            variant="inline"
            disabled={disabled}
            onFieldChange={handleDateChange}
            start={startDate}
            end={endDate}
            data-testid={createTestid('periodPicker')}
          />
        )}
      </Stack>
      <SelectPtfSectionRequest
        ptfList={ptfListByView}
        portfolioOriginType={view}
        selectedRows={selectedRows}
        searchValue={searchValue}
        extractionType={extractionType}
        onRowSelected={setSelectedRows}
        disabled={disabled}
        data-testid={testid}
      />
    </>
  );
};
