import { useMatomo } from '@datapunt/matomo-tracker-react';
import { DesktopAccessDisabled as DesktopAccessDisabledIcon, Home as HomeIcon } from '@mui/icons-material';
import { Button, Link, useTestid } from 'mns-components';
import React, { useEffect } from 'react';
import useStyles from './styles/unauthorizedStyles';

const Unauthorized: React.FC = () => {
  const createTestid = useTestid('unauthorized');
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: `Unauthorized`, // optional
    });
  }, [trackPageView]);

  return (
    <div className={classes.mainComponent}>
      <h1>Unauthorized access.</h1>
      <div className={classes.mainContent}>
        <DesktopAccessDisabledIcon />
        <p className={classes.textContent}>
          You tried to reach an authorized content.
          <br />
          Check your user rights and your subscriptions to the services of our platform and try again.
        </p>
      </div>
      <Link to="/" data-testid={createTestid('link-back')}>
        <Button color="primary" startIcon={<HomeIcon />} data-testid={createTestid('button-back')}>
          Back to home
        </Button>
      </Link>
    </div>
  );
};

export default Unauthorized;
