import type { GridReadyEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  useRowSelection,
  convertToDateLocal,
  convertToDateTimeLocal,
  makeActionColDef,
  Button,
  useTestid,
  AggridTable,
  useDefaultColDef,
  useImmutable,
  useCallbackImmutable,
} from 'mns-components';
import type { AnalysisApi, ProviderApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { getApplicationConfig } from '../../../../common/getApplicationConfig';
import { downloadFile, downloadJsonAsCsv, formatAnalysisFileName } from '../../../../common/utils';
import { CompilingStepStatus } from '../../../../components/cellRender/CompilingFileStep';
import { type AppCode, isAppCode } from '../../../../components/views/appDescriptions';
import { api } from '../../../../store/api';
import { isAnalysisEligibleForDownload } from './ProviderAnalysisTable';

export const getApplicationName = (appCode: string): string => {
  if (isAppCode(appCode)) {
    return getApplicationConfig(appCode).content.title;
  }
  return appCode;
};

const getActionColDef = makeActionColDef<ProviderApi.UnderlyingDataInfo>();

const useColumns = (
  columnSelector: AggridTableColumn<ProviderApi.UnderlyingDataInfo>,
  createTestid: (suffix: string | number) => string,
  handleDownload: (analysisId: AnalysisApi.AnalysisPortfolio<AppCode>['analysisId'], appCode: string) => Promise<void>,
  hasSubscribedToApp: (appCode: string) => boolean,
  isAnalysisRelaunchable: (analyse: ProviderApi.UnderlyingDataInfo) => boolean,
  relaunchAnalyses: (analysisToRelaunch: ProviderApi.UnderlyingDataInfo[]) => void,
) =>
  useMemo(
    (): AggridTableColumn<ProviderApi.UnderlyingDataInfo>[] => [
      columnSelector,
      {
        field: 'externalId',
        headerName: 'Portfolio ID',
        flex: 1,
      },
      {
        field: 'portfolioName',
        headerName: 'Portfolio name',
        flex: 1,
        maxWidth: 350,
      },
      {
        field: 'valuationDate',
        headerName: 'NAV date',
        flex: 1,
        valueGetter: ({ data }) => convertToDateLocal(data.valuationDate),
      },
      {
        field: 'createdAt',
        headerName: 'Order date',
        sort: 'desc',
        flex: 1,
        valueGetter: ({ data }) => convertToDateTimeLocal(data.createdAt),
      },
      {
        field: 'createdBy',
        headerName: 'Created by',
        flex: 1,
      },
      {
        field: 'applicationCode',
        headerName: 'Application',
        flex: 1,
        valueGetter: ({ data }) => getApplicationName(data.applicationCode),
      },
      {
        field: 'applicationCode',
        headerName: 'Subscription',
        flex: 1,
        valueGetter: ({ data }) => (hasSubscribedToApp(data.applicationCode) ? 'Active' : 'Expired'),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        cellRendererFramework: ({ value, data }) => (
          <CompilingStepStatus status={value} data-testid={createTestid(data.id)} />
        ),
      },
      getActionColDef('id', 95, function ActionCol({ data }) {
        return (
          <>
            <Button.Icon
              iconName="download"
              data-testid={createTestid(`${data.id}-download`)}
              size="medium"
              color="secondary"
              disabled={!isAnalysisEligibleForDownload(data)}
              onClick={useCallbackImmutable(() => handleDownload(data.id, data.applicationCode))}
              tooltip="Download"
            />
            <Button.Icon
              iconName="retry"
              data-testid={createTestid(`${data.id}-relaunch`)}
              size="medium"
              color="secondary"
              disabled={!isAnalysisRelaunchable(data)}
              tooltip="Relaunch"
              onClick={useCallbackImmutable(() => relaunchAnalyses([data]))}
            />
          </>
        );
      }),
    ],
    [columnSelector, hasSubscribedToApp, createTestid, isAnalysisRelaunchable, handleDownload, relaunchAnalyses],
  );

type ProviderAnalysisGridProps = {
  analysis: ProviderApi.UnderlyingDataInfo[];
  searchFilter: string;
  setSelectedAnalyses: (analyses: ProviderApi.UnderlyingDataInfo[]) => void;
  hasSubscribedToApp: (appCode: string) => boolean;
  isAnalysisRelaunchable: (analyse: ProviderApi.UnderlyingDataInfo) => boolean;
  relaunchAnalyses: (analysisToRelaunch: ProviderApi.UnderlyingDataInfo[]) => void;
  onGridReady(event: GridReadyEvent): void;
  'data-testid': string;
};

export const ProviderAnalysisGrid = React.memo<ProviderAnalysisGridProps>(function ProviderAnalysisGrid({
  analysis,
  searchFilter,
  setSelectedAnalyses,
  hasSubscribedToApp,
  isAnalysisRelaunchable,
  relaunchAnalyses,
  onGridReady,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const handleDownload = useCallbackImmutable(
    async (analysisId: AnalysisApi.AnalysisPortfolio<AppCode>['analysisId'], appCode: string) => {
      toast('The files are being downloaded, it may takes a few seconds.', { type: 'info' });
      const currentAnalysedFile = await api.provider.download.downloadRequest.raw({
        appCode: appCode,
        requestId: analysisId,
      });

      if (!currentAnalysedFile || currentAnalysedFile.length === 0) {
        toast('Failed to download the files, please try again later or contact the support team.', { type: 'error' });
        return;
      }

      await Promise.all(
        currentAnalysedFile.map(async ({ presignedUrl, fileName, metadata }) => {
          const { formattedFileName } = formatAnalysisFileName(fileName, metadata);
          await downloadFile(presignedUrl, formattedFileName, downloadJsonAsCsv);
        }),
      );
    },
  );

  const [gridProps, columnProps] = useRowSelection<ProviderApi.UnderlyingDataInfo>(
    (grid) => setSelectedAnalyses(grid.api.getSelectedRows()),
    true,
  );

  return (
    <AggridTable<ProviderApi.UnderlyingDataInfo>
      {...gridProps}
      rowData={analysis}
      defaultColDef={useDefaultColDef<ProviderApi.UnderlyingDataInfo>()}
      columnDefs={useColumns(
        columnProps,
        createTestid,
        handleDownload,
        hasSubscribedToApp,
        isAnalysisRelaunchable,
        relaunchAnalyses,
      )}
      getRowNodeId={useImmutable(() => (row: ProviderApi.UnderlyingDataInfo) => row.id)}
      onGridReady={onGridReady}
      pagination
      data-testid={testid}
      quickFilterText={searchFilter}
      paginationPageSize={40}
    />
  );
});
