import type { MenuProps, StackProps } from '@mui/material';
import { Chip, Stack } from '@mui/material';
import type { OptionType, TextFieldProps } from 'mns-components';
import {
  Autocomplete,
  SelectSection,
  TextField,
  Typography,
  filterOptionsBySearch,
  filterTruthy,
  floatToPercent,
  objectKeys,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPortfolioText, splitPortfolioText } from '../../../common/utils';
import { usePtfAndDate } from '../../../hooks/useGlobalState';
import useStyles from './styles/selectorPtfAndDateStyles';

type FormState = {
  portfolio: string;
  valuationDate: string;
  version: string;
};

const findFirstValuationDate = (ptf: CollectApi.Portfolio) =>
  ptf ? ptf.latestVersion.valuationDate ?? ptf.valuationDates[0] : '';

const getAvailableDates = (ptf: CollectApi.Portfolio) =>
  filterTruthy([ptf.latestVersion.valuationDate, ...(ptf.valuationDates ?? [])]);

const getListAvailableDates = (list: CollectApi.Portfolio[]) =>
  list.reduce((acc, ptf) => {
    const dates = getAvailableDates(ptf);
    for (const dt of dates) if (!acc.includes(dt)) acc.push(dt);
    return acc;
  }, [] as string[]);

const getListAvailableVersions = (list: CollectApi.Portfolio[], date: string) =>
  list.reduce((acc, ptf) => {
    if (getAvailableDates(ptf).includes(date) && !acc.includes(ptf.organisationName)) acc.push(ptf.organisationName);
    return acc;
  }, [] as string[]);

const getDefaultValue = (
  portfolios: CollectApi.Portfolio[],
  portfolioId?: string,
  valuationDate?: string,
): FormState => {
  if (portfolioId) {
    const portfolio = portfolios.find((ptf) => ptf.portfolioId === portfolioId);

    if (portfolio) {
      const availableDates = getAvailableDates(portfolio);
      if (valuationDate && availableDates.includes(valuationDate)) {
        return {
          portfolio: getPortfolioText(portfolio),
          valuationDate,
          version: portfolio.organisationName,
        };
      } else {
        return {
          portfolio: getPortfolioText(portfolio),
          valuationDate: findFirstValuationDate(portfolio),
          version: portfolio.organisationName,
        };
      }
    }
  }

  const first = portfolios[0];
  return {
    portfolio: getPortfolioText(first),
    valuationDate: findFirstValuationDate(first),
    version: first.organisationName,
  };
};

const getDefaultSection = (state: FormState, portfolios: CollectApi.Portfolio[]) => {
  const [extId] = splitPortfolioText(state.portfolio);
  const portfolio = portfolios.find(
    (ptf) =>
      ptf.externalId.value === extId &&
      getAvailableDates(ptf).includes(state.valuationDate) &&
      ptf.organisationName === state.version,
  );
  return portfolio?.origin ?? 'UPLOADED';
};

const mapOriginLabel: AnyObject<CollectApi.PortfolioOrigin, string> = {
  UPLOADED: 'My portfolios',
  COLLECTED: 'Collected portfolios',
};

const menuProps: Partial<MenuProps> = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  transformOrigin: { horizontal: 'right', vertical: 'top' },
};

const textfieldProps: Partial<TextFieldProps> = { disableMargin: true, uncontrolled: true };

export type SelectorPtfAndDateProps = StackProps & {
  portfolios: CollectApi.Portfolio[];
  displayLookthrough?: boolean;
  smallVersion?: boolean;
  'data-testid': string;
};

export const SelectorPtfAndDate: React.FC<SelectorPtfAndDateProps> = ({
  portfolios,
  displayLookthrough = false,
  smallVersion = false,
  'data-testid': testid,
  ...stackProps
}: SelectorPtfAndDateProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const { id: urlExternalId, date: urlValuationDate } = useParams<{ id?: string; date?: string }>();
  const [[portfolioId, valuationDate], setPtfAndDate] = usePtfAndDate();
  const [searchPtf, setSearchPtf] = useState('');

  const [state, setState] = useState(() =>
    getDefaultValue(portfolios, urlExternalId ?? portfolioId, urlValuationDate ?? valuationDate),
  );

  const sectionsPtf = useMemo(() => {
    const origins = portfolios.reduce((acc, ptf) => {
      acc.add(ptf.origin);
      return acc;
    }, new Set<CollectApi.PortfolioOrigin>());

    if (origins.size > 1) {
      return objectKeys(mapOriginLabel).map((origin) => ({
        value: origin,
        children: (
          <Stack direction="row" gap=".5rem" alignItems="center">
            <Typography variant="body2">{mapOriginLabel[origin]}</Typography>
            <Chip
              color="secondary"
              size="small"
              variant="badge"
              label={portfolios.reduce((count, ptf) => (ptf.origin === origin ? count + 1 : count), 0)}
            />
          </Stack>
        ),
      }));
    } else {
      return undefined;
    }
  }, [portfolios]);

  const [sectionPtf, setSectionPtf] = useState<CollectApi.PortfolioOrigin | undefined>(() =>
    getDefaultSection(state, portfolios),
  );

  // get portfolio name, unique by externalId
  const portfolioOptions = useMemo(
    () =>
      filterOptionsBySearch(
        portfolios
          .reduce((acc, ptf) => {
            if (sectionPtf && ptf.origin !== sectionPtf) return acc;
            if (!acc.find((saved) => saved.externalId.value === ptf.externalId.value)) acc.push(ptf);
            return acc;
          }, [] as CollectApi.Portfolio[])
          .map(
            (ptf): OptionType => ({
              label: getPortfolioText(ptf),
              value: getPortfolioText(ptf),
            }),
          )
          .sort((optA, optB) => optB.label.localeCompare(optA.label)),
        searchPtf,
      ),
    [portfolios, searchPtf, sectionPtf],
  );

  // get dates of every portfolios matching selected state.portfolio.externalId.value
  const dateOptions = useMemo(() => {
    const [extId] = splitPortfolioText(state.portfolio);
    return portfolios
      .filter((ptf) => ptf.externalId.value === extId)
      .reduce((acc, ptf) => {
        getAvailableDates(ptf).forEach((dt) => {
          if (!acc.includes(dt)) {
            acc.push(dt);
          }
        });
        return acc;
      }, [] as string[])
      .sort((a, b) => b.localeCompare(a));
  }, [state?.portfolio, portfolios]);

  // get providers names of every portfolios matching selected state.portfolio externalId and state.date
  const versionsOptions = useMemo(() => {
    const [extId] = splitPortfolioText(state.portfolio);
    return portfolios
      .filter((ptf) => ptf.externalId.value === extId && getAvailableDates(ptf).includes(state.valuationDate))
      .reduce((acc, ptf) => {
        if (!acc.includes(ptf.organisationName)) acc.push(ptf.organisationName);
        return acc;
      }, [] as string[])
      .sort();
  }, [state?.portfolio, state?.valuationDate, portfolios]);

  const lookThroughRate = useMemo(() => {
    if (displayLookthrough) {
      const [extId] = splitPortfolioText(state.portfolio);
      const portfolio = portfolios.find((ptf) => ptf.externalId.value === extId);
      if (portfolio) {
        return portfolio.latestVersion.lookThroughRate;
      }
    }
    return undefined;
  }, [state?.portfolio, portfolios, displayLookthrough]);

  const handlePortfolioChange = useCallbackImmutable((value: string) =>
    setState((current) => {
      const [extId, ptfName] = splitPortfolioText(value);
      const ptfs = ptfName
        ? portfolios.filter((ptf) => ptf.externalId.value === extId && ptf.name == ptfName)
        : portfolios.filter((ptf) => ptf.externalId.value === extId);
      if (!ptfs.length) {
        const first = portfolios[0];
        return {
          portfolio: value,
          valuationDate: first.latestVersion.valuationDate,
          version: first.organisationName,
        };
      }

      const date = getListAvailableDates(ptfs)[0];
      const versions = getListAvailableVersions(ptfs, date);
      const version = versions.includes(current.version) ? current.version : versions[0];

      if (current.portfolio === value && current.valuationDate === date && current.version === version) return current;
      return { portfolio: value, valuationDate: date, version };
    }),
  );

  const handleDateChange = useCallbackImmutable((value: string) =>
    setState((current) => {
      const [extId, ptfName] = splitPortfolioText(current.portfolio);
      const ptfs = portfolios.filter((ptf) => ptf.externalId.value === extId && ptf.name === ptfName);
      if (!ptfs.length) {
        const first = portfolios[0];
        return {
          portfolio: getPortfolioText(first),
          valuationDate: value,
          version: first.organisationName,
        };
      }

      const dates = getListAvailableDates(ptfs);
      const date = dates.includes(value) ? value : dates[0];

      const versions = getListAvailableVersions(ptfs, date);
      const version = versions.includes(current.version) ? current.version : versions[0];

      if (current.valuationDate === date && current.version === version) return current;
      return { portfolio: current.portfolio, valuationDate: date, version };
    }),
  );

  const handleVersionChange = useCallbackImmutable((value: string) =>
    setState((current) => {
      const [extId, ptfName] = splitPortfolioText(current.portfolio);
      const ptfs = portfolios.filter((ptf) => ptf.externalId.value === extId && ptf.name === ptfName);
      if (!ptfs.length) {
        const first = ptfs[0];
        return {
          portfolio: getPortfolioText(first),
          valuationDate: first.latestVersion.valuationDate,
          version: first.organisationName,
        };
      }

      const dates = getListAvailableDates(ptfs);
      const date = dates.includes(current.valuationDate) ? current.valuationDate : dates[0];

      const versions = getListAvailableVersions(ptfs, date);
      const version = versions.includes(value) ? value : versions[0];

      if (current.version === value) return current;
      return { portfolio: current.portfolio, valuationDate: date, version };
    }),
  );

  useEffect(() => {
    const [extId] = splitPortfolioText(state.portfolio);
    const portfolio = portfolios.find(
      (ptf) =>
        ptf.externalId.value === extId &&
        getAvailableDates(ptf).includes(state.valuationDate) &&
        ptf.organisationName === state.version,
    );
    if (portfolio) {
      setPtfAndDate([portfolio.portfolioId, state.valuationDate]);
    }
  }, [setPtfAndDate, state, portfolios]);

  return (
    <Stack
      display="inline-flex"
      direction="row-reverse"
      gap="0 .5rem"
      alignItems="center"
      flexWrap={smallVersion ? 'unset' : 'wrap-reverse'}
      flexGrow="1 1 0"
      minWidth={0}
      width={smallVersion ? 'auto' : '40rem'}
      data-stepid="SelectorPtfAndDate"
      data-testid={testid}
      {...stackProps}
    >
      {(lookThroughRate || lookThroughRate == 0) && (
        <Stack
          flex={smallVersion ? '' : '1 1 10rem'}
          minWidth={smallVersion ? '6rem' : '10rem'}
          margin={smallVersion ? '.5rem 1rem' : '.5rem 0'}
        >
          <TextField
            name="lookThroughRate"
            label="Look-through rate"
            type="text"
            value={floatToPercent(lookThroughRate)}
            uncontrolled
            disabled
            disableMargin
            className={smallVersion ? classes.smallTextField : ''}
            data-testid={createTestid('lookThroughRate')}
          />
        </Stack>
      )}
      <Stack
        flex={smallVersion ? '' : '1 1 10rem'}
        minWidth={smallVersion ? '6rem' : '10rem'}
        width={smallVersion ? '6rem' : 'auto'}
        margin={smallVersion ? '.5rem 1rem' : '.5rem 0'}
      >
        <Autocomplete
          name="version"
          label="Provided by"
          value={state.version}
          options={versionsOptions}
          onChange={handleVersionChange}
          disabled={versionsOptions.length <= 1}
          textfieldProps={textfieldProps}
          fullWidth
          className={smallVersion ? classes.smallSelector : ''}
          data-testid={createTestid('version')}
        />
      </Stack>
      <Stack
        flex={smallVersion ? '' : '1 1 10rem'}
        minWidth={smallVersion ? '6rem' : '10rem'}
        width={smallVersion ? '6rem' : 'auto'}
        margin={smallVersion ? '.5rem 1rem' : '.5rem 0'}
      >
        <Autocomplete
          name="valuationDate"
          label="NAV Date"
          value={state.valuationDate}
          options={dateOptions}
          onChange={handleDateChange}
          disabled={dateOptions.length <= 1}
          textfieldProps={textfieldProps}
          fullWidth
          className={smallVersion ? classes.smallSelector : ''}
          data-testid={createTestid('valuationDate')}
        />
      </Stack>
      <Stack flex={smallVersion ? '' : '2 1 10rem'} minWidth={smallVersion ? '10rem' : '15rem'}>
        <SelectSection<string, CollectApi.PortfolioOrigin>
          uncontrolled
          size="small"
          name="portfolio"
          label="Portfolio"
          value={state.portfolio}
          options={portfolioOptions}
          search={searchPtf}
          onSearch={setSearchPtf}
          sectionOptions={sectionsPtf}
          section={sectionPtf}
          onChangeSection={setSectionPtf}
          onFieldChange={handlePortfolioChange}
          menuProps={menuProps}
          fullWidth
          className={smallVersion ? classes.smallSelector : ''}
          data-testid={createTestid('portfolio')}
        />
      </Stack>
    </Stack>
  );
};
