import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, Quality } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useDownloadPresignedUrl } from '../../../../hooks/useDownloadPresignedUrl';
import { usePortfolioHistory } from '../../hooks';
import { CollectHistoryList } from './CollectHistoryList';

type CollectHistoryListRequestProps = {
  portfolio: Required<CollectApi.FundPositionToCollect>;
  filterQualities: Quality[];
  'data-testid': string;
};

export const CollectHistoryListRequest: React.FC<CollectHistoryListRequestProps> = ({
  portfolio,
  filterQualities,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const {
    data: portfolioHistory,
    isLoading: isLoadingPortfolioHistory,
    error,
  } = usePortfolioHistory(portfolio.disseminatorPortfolio?.portfolioId ?? '');

  const filteredPortfolioHistory = useMemo(
    () => portfolioHistory?.filter((hist) => hist.qualities.some((quality) => filterQualities.includes(quality))) ?? [],
    [portfolioHistory, filterQualities],
  );

  const downloadPresignedUrl = useDownloadPresignedUrl();

  const handleDownload = useCallbackImmutable((navDate: string) => {
    downloadPresignedUrl({
      externalId: portfolio.fundPosition.externalId,
      externalIdType: portfolio.fundPosition.externalIdType,
      fileDownloadType: 'output',
      fileDownloadTypeLabel: 'Processed File',
      fileName: portfolio.fundPosition.externalId,
      fileDate: navDate,
      valuationDate: navDate,
    });
  });

  if (isLoadingPortfolioHistory) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (error) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!portfolioHistory?.length) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There are no inventories collected for this fund yet!"
          data-testid={createTestid('empty')}
        />
      </Stack>
    );
  }

  return <CollectHistoryList rows={filteredPortfolioHistory} onDownload={handleDownload} data-testid={testid} />;
};
