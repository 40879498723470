import type { BuildStep } from 'mns-components';
import { Stepper, makeStep } from 'mns-components';
import { useState } from 'react';
import { usePrefetchAppsColumns, usePrefetchPtfColumns } from '../../../api/extractionsData';
import { usePrefetchTemplates } from '../../../api/templateData';
import type { AppCode } from '../../../components/views/appDescriptions';
import { ApplicationsDataPointsStep } from './steps/ApplicationsDataPointsStep';
import { FinalizeStep } from './steps/FinalizeStep';
import { PortfolioDataPointsStep } from './steps/PortfolioDataPointsStep';
import { SetUpStep } from './steps/SetUpStep';
import type { CreateTemplateSteps } from './types';

const steps: BuildStep<CreateTemplateSteps>[] = [
  {
    Step: makeStep(0, SetUpStep),
    title: 'Set up extraction',
    iconName: 'settings',
  },
  {
    Step: makeStep(1, PortfolioDataPointsStep),
    title: 'Select portfolio data points',
    iconName: 'ptfdata',
  },
  {
    Step: makeStep(2, ApplicationsDataPointsStep),
    title: 'Select ESG data points',
    iconName: 'marketplaceesg',
  },
  {
    Step: makeStep(3, FinalizeStep),
    title: 'Finalize extraction',
    iconName: 'success',
  },
];

type CreateTemplateStepperProps = {
  onClose(): void;
  'data-testid': string;
};

export const CreateTemplateStepper: React.FC<CreateTemplateStepperProps> = ({ onClose, 'data-testid': testid }) => {
  usePrefetchPtfColumns()();
  usePrefetchAppsColumns()();
  usePrefetchTemplates()();

  const [currentStep, setCurrentStep] = useState(0);
  const [stepValues, setStepValues] = useState<CreateTemplateSteps>([
    {
      templateName: '',
      templateFrom: undefined,
      templateId: '',
      accordionPanel: null,
    },
    { portfolioDataPoints: [], selectedCategory: null },
    { applicationsDataPoints: {} as AnyObject<AppCode, string[]>, selectedAppCode: null },
    undefined as never,
  ]);

  return (
    <Stepper.Modal<CreateTemplateSteps>
      title="Create a new template"
      subTitle="Create a template to match the scope of your portfolios when creating an extraction."
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepValues={stepValues}
      steps={steps}
      onClose={onClose}
      setStepValues={setStepValues}
      data-testid={testid}
    />
  );
};
