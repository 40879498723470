import { Stack } from '@mui/material';
import { Button, useTestid, Icon, SearchField, AppMargin } from 'mns-components';

export type FormData = {
  search: string;
};

type OrganisationControllerProps = {
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  onCreate(): void;
  'data-testid': string;
};

export const OrganisationController: React.FC<OrganisationControllerProps> = ({
  searchValue,
  setSearchValue,
  onCreate,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  return (
    <AppMargin data-testid={createTestid('controller')}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} disableMargin />
        <Stack direction="row" alignItems="center" spacing="1rem">
          {/* Will be added back when bulk delete will be added */}
          {/* <Button
            color="danger"
            startIcon={<Icon.Delete data-testid={createTestid('icon-delete')} />}
            data-testid={createTestid('button-delete')}
            onClick={onCreate}
            outlined
            disabled
          >
            Delete email list
          </Button> */}
          {onCreate && (
            <Button
              color="secondary"
              startIcon={<Icon.Add data-testid={createTestid('icon-create')} />}
              data-testid={createTestid('button-create')}
              onClick={onCreate}
            >
              Create email list
            </Button>
          )}
        </Stack>
      </Stack>
    </AppMargin>
  );
};
