import { Box } from '@mui/material';
import type { FormProps } from 'mns-components';
import { Button, Typography, colors, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCognitoAuthError, useAuthUser } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import {
  CodeField,
  ConfirmPasswordField,
  ErrorBox,
  NewPasswordField,
  SuccessRedirectLayout,
  UsernameField,
} from './Forms';
import { useHandleResetPassword } from './hooks';
import { routes } from './routes';
import type { ResetPasswordFormData } from './types';
import { getResetFormErrors } from './utils';

export type ResetPasswordInputsProps = Omit<FormProps<ResetPasswordFormData>, 'children'> & { error?: Error };

const ResetPasswordInputs: React.FC<ResetPasswordInputsProps> = ({ error, 'data-testid': testid }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const createTestid = useTestid(testid);

  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<ResetPasswordFormData>();

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  watch(() => setErrorMessage(''));

  return (
    <>
      <ErrorBox error={errorMessage} data-testid={createTestid('error-verification-code')} />
      <UsernameField data-testid={createTestid('username')} />
      <CodeField data-testid={createTestid('code')} />
      <NewPasswordField data-testid={createTestid('newPassword')} />
      <ConfirmPasswordField data-testid={createTestid('confirmPassword')} />
      <Box margin="0.5rem">
        <Button color="secondary" type="submit" loading={isSubmitting} data-testid={createTestid('submit')}>
          Change your password
        </Button>
      </Box>
    </>
  );
};

const ResetPasswordForm = makeForm<ResetPasswordFormData, ResetPasswordInputsProps>(ResetPasswordInputs);

const defaultValues: ResetPasswordFormData = { username: '', code: '', newPassword: '', confirmPassword: '' };

export const ResetPassword: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [cognitoUser] = useAuthUser();
  const [hasSucceded, handleResetPassword] = useHandleResetPassword(routes.loginUri.path);
  const handleValidation = useCallbackImmutable(getResetFormErrors);

  if (!isCognitoAuthError(cognitoUser) && hasSucceded) {
    return <SuccessRedirectLayout data-testid={testid}></SuccessRedirectLayout>;
  }

  return (
    <AuthPageLayout data-testid={testid}>
      <Box margin="0.5rem">
        <Typography variant="h1">Reset password</Typography>
        <Typography variant="body1" margin="1rem 0" color={colors.disabledColor}>
          The reset password email redirected you here for changing your password, please fill in your username and the
          code in this email.
        </Typography>
      </Box>
      <ResetPasswordForm
        error={isCognitoAuthError(cognitoUser) ? cognitoUser : undefined}
        defaultValues={defaultValues}
        onSubmit={handleResetPassword}
        onValidate={handleValidation}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
