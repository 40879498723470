import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import type { IsinListApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { useIsinListsRequest, useManageOpenedEditIsinList } from './hooks';
import { IsinList } from './view/IsinList';
import { IsinListController } from './view/IsinListController';
import { IsinListModals } from './view/IsinListModals';

type IsinListCompProps = {
  testId: string;
  variant: 'ISIN' | 'USER_SCOPE';
};

export const IsinListComp = ({ testId, variant }: IsinListCompProps) => {
  const createTestid = useTestid(testId);

  const [search, setSearch] = useState('');
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState<undefined | IsinListApi.List>(undefined);

  const openCreateModal = useCallbackImmutable(() => setIsOpenCreateModal(true));
  const closeCreateModal = useCallbackImmutable(() => setIsOpenCreateModal(false));

  const {
    data: isinLists,
    isLoading,
    isError,
  } = useIsinListsRequest(variant === 'USER_SCOPE' ? 'USER_SCOPE' : undefined);
  const [selectedEditId, openEditModal, closeEditModal] = useManageOpenedEditIsinList();

  const handleDelete = useCallbackImmutable((listId: string) => {
    const found = isinLists?.find((list) => list.id === listId);
    if (found) {
      setSelectedDelete(found);
    }
  });

  if (isLoading) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (isError) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  return (
    <>
      <IsinListModals
        isInListsData={isinLists}
        isOpenCreateModal={isOpenCreateModal}
        onCloseCreateModal={closeCreateModal}
        selectedDelete={selectedDelete}
        setSelectedDelete={setSelectedDelete}
        selectedEditId={selectedEditId}
        closeEditModal={closeEditModal}
        variant={variant}
        data-testid={createTestid('modal')}
      />
      {!isinLists?.length ? (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title={variant === 'ISIN' ? 'Get started with a portfolio list' : 'Get started with a scope'}
            subTitle={
              variant === 'ISIN'
                ? 'Start creating your portfolio list.'
                : 'Start creating your scope to restrict user access to portfolios.'
            }
            buttonText={variant === 'ISIN' ? 'Create portfolio list' : 'Create portfolio scope'}
            buttonIcon="add"
            buttonVariant={variant === 'ISIN' ? 'primary' : 'secondary'}
            buttonOnClick={openCreateModal}
            data-testid={createTestid('error')}
          />
        </Stack>
      ) : (
        <>
          <IsinListController
            search={search}
            onSearch={setSearch}
            onOpenCreateModal={openCreateModal}
            variant={variant}
            data-testid={createTestid('controller')}
          />
          <IsinList
            search={search}
            rows={isinLists}
            onDelete={handleDelete}
            onEdit={openEditModal}
            variant={variant}
            data-testid={createTestid('table')}
          />
        </>
      )}
    </>
  );
};
