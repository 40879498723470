import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import {
  AppMargin,
  Autocomplete,
  Button,
  EmptyStateV2,
  Icon,
  PeriodPicker,
  SearchField,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { currentMonthLastDate, firstDateAvailable, subtractYears } from '../../../common/date';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { usePortfoliosRequest } from '../../../hooks/usePortfoliosRequest';
import { PortfoliosCollectedRequest } from './PortfoliosCollectedRequest';
import { PortfoliosRequest } from './PortfoliosRequest';

type PortfoliosProps = {
  portfolioOriginType: CollectApi.PortfolioOriginRequest;
  startDate?: Date;
  endDate?: Date;
  onOpenModal(): void;
  'data-testid': string;
};

export const Portfolios: React.FC<PortfoliosProps> = ({
  portfolioOriginType,
  endDate: initEndDate = currentMonthLastDate,
  startDate: initStartDate = subtractYears(initEndDate, 3),
  onOpenModal,
  'data-testid': testid,
}: PortfoliosProps) => {
  const [startDate, setStartDate] = useState<Date>(initStartDate);
  const [endDate, setEndDate] = useState<Date>(initEndDate);
  const [selectedRows, setSelectedRows] = useState<CollectApi.Portfolio[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const createTestid = useTestid(testid);
  const formMethods = useForm();

  const { data: ptfList, isError } = usePortfoliosRequest({ startDate, endDate, portfolioOriginType });

  const handleChange = useCallbackImmutable(([start, end]: [Date | null, Date | null]) => {
    setStartDate(start ?? subtractYears(currentMonthLastDate, 3));
    setEndDate(end ?? currentMonthLastDate);
  });

  const downloadPresignedUrl = useDownloadPresignedUrl();

  const handleClickDownloadInput = useCallbackImmutable(() => {
    selectedRows.forEach((data) => {
      downloadPresignedUrl({
        fileDownloadType: 'input',
        fileDownloadTypeLabel: 'Input File',
        processId: data.latestVersion.processId,
        fileDate: data.latestVersion.valuationDate,
        fileName: data.name || data.externalId.value,
      });
    });
  });

  const organisationNameOptions = useMemo(
    () =>
      ptfList?.reduce((acc, ptf) => {
        if (!acc.includes(ptf.organisationName)) acc.push(ptf.organisationName);
        return acc;
      }, [] as string[]) ?? [],
    [ptfList],
  );

  const [organisationName, setOrganisationName] = useState('');

  const onDownloadInputFile = useCallbackImmutable(() => selectedRows.length && handleClickDownloadInput());
  const onExportTable = useCallbackImmutable(() =>
    gridApi?.exportDataAsExcel({
      columnKeys: [
        'externalId.value',
        'name',
        'organisationName',
        'latestVersion.valuationDate',
        'latestVersion.uploadOn',
        'latestVersion.qualities',
      ],
    }),
  );

  if (isError) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  return (
    <>
      <AppMargin>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap="1rem" flexWrap="wrap">
          <FormProvider {...formMethods}>
            <Stack direction="row" alignItems="center" gap="1rem">
              <SearchField
                value={searchValue}
                disableMargin
                onChange={setSearchValue}
                data-testid={createTestid('search')}
              />
              <PeriodPicker
                start={startDate}
                end={endDate}
                onFieldChange={handleChange}
                minStartDate={firstDateAvailable}
                minEndDate={firstDateAvailable}
                maxStartDate={currentMonthLastDate}
                maxEndDate={currentMonthLastDate}
                label="NAV date period"
                variant="inline"
                disableMargin
                data-testid={testid}
                data-stepid="portfolios-navDatePeriod"
              />
              {portfolioOriginType != 'UPLOADED' && (
                <Autocomplete.Single
                  disableMargin
                  name="organisationName"
                  options={organisationNameOptions}
                  value={organisationName}
                  label="Provided by"
                  onChange={setOrganisationName}
                  data-testid={createTestid('organisationName')}
                />
              )}
            </Stack>
          </FormProvider>
          <Stack direction="row" alignItems="center" gap="1rem" flexGrow={1} justifyContent="flex-end">
            <Button
              startIcon={<Icon name="download" data-testid={createTestid('icon-export')} />}
              color="primary"
              outlined
              onClick={onExportTable}
              size="medium"
              data-testid={createTestid('button-export')}
            >
              Export table
            </Button>
            <Button
              startIcon={<Icon name="download" data-testid={createTestid('icon-download')} />}
              color="primary"
              disabled={!selectedRows.length}
              onClick={onDownloadInputFile}
              size="medium"
              data-testid={createTestid('button-download-original')}
              data-stepid="portfolios-download"
            >
              Download
            </Button>
          </Stack>
        </Stack>
      </AppMargin>
      <Stack flex="1 1 0" minHeight={0}>
        {portfolioOriginType == 'COLLECTED' ? (
          <PortfoliosCollectedRequest
            ptfList={ptfList}
            searchValue={searchValue}
            organisationName={organisationName}
            onRowSelected={setSelectedRows}
            getGridApiref={setGridApi}
            data-testid={createTestid('list')}
          />
        ) : (
          <PortfoliosRequest
            ptfList={ptfList}
            searchValue={searchValue}
            onOpenModal={onOpenModal}
            organisationName={organisationName}
            onRowSelected={setSelectedRows}
            getGridApiref={setGridApi}
            data-testid={createTestid('list')}
          />
        )}
      </Stack>
    </>
  );
};
