import { makeStyles } from 'mns-components';

const selectorPtfAndDate = makeStyles({
  smallSelector: {
    '& div.MuiTextField-root': {
      width: '8rem',
    },
    '& div.MuiInputBase-root': {
      width: '7rem',
    },
  },
  smallTextField: {
    width: '7rem',
    '& div.MuiInputBase-root': {
      width: '7rem',
    },
  },
});

export default selectorPtfAndDate;
