import { colors, makeStyles } from 'mns-components';

export const extractionFunnelStyles = makeStyles({
  container: {
    height: '65vh',
    minHeight: '345px',
    position: 'relative',
  },
  overlay: {
    width: '100%',
    height: '100%',
    zIndex: '1',
    position: 'absolute',
    opacity: '.5',
    background: colors.whiteColor,
  },
  setupInput: {
    width: '22rem',
  },
});
