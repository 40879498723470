import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import { useEmitterAccessRequests } from '../../../hooks/useGlobalState';
import { appCollectApi } from '../../../store/apis';

interface EmitterPermissionsActionsRendererProps {
  value: string;
  data: CollectApi.AccessDemand;
  setSelected: (selectedItems: CollectApi.AccessDemand[]) => void; // FIXME: Type
  openManagePermissionsModal: (isOpen: boolean) => void;
  'data-testid': string;
}

export const EmitterPermissionsActionsRenderer: React.FC<EmitterPermissionsActionsRendererProps> = ({
  value,
  setSelected,
  openManagePermissionsModal,
  data,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const handleClick = useCallbackImmutable(() => {
    setSelected([data]);
    openManagePermissionsModal(true);
  });

  const { refetch: loadEAR } = useEmitterAccessRequests({ enabled: false });

  const handleCancel = useCallbackImmutable(async () => {
    const accessRequestToCancelStatus: CollectApi.AccessAuthorizationUpdateRequest = {
      ids: [data.dataAccessAuthorizationId],
      status: 'CANCELED',
      comment: '',
    };
    await appCollectApi.updateStatusOnMultipleDataAuthorizationAccess(accessRequestToCancelStatus);
    loadEAR();
  });

  switch (value) {
    case 'ACCEPTED':
      return (
        <Button
          data-testid="button-close"
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Close data-testid={createTestid('icon-close')} />}
          onClick={handleClick}
        >
          Close
        </Button>
      );
    case 'PENDING':
      return (
        <Button
          data-testid="button-cancel"
          color="danger"
          outlined
          size="small"
          startIcon={<Icon.Close data-testid={createTestid('icon-close')} />}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      );
    case 'CANCEL':
    case 'REVOKED':
      return (
        <Button
          data-testid="button-reopen"
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Accessopen data-testid={createTestid('icon-accessopen')} />}
          onClick={handleClick}
        >
          Re open
        </Button>
      );
    default:
  }
  return <i />;
};
