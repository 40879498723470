import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { getCookie } from './common/utils';
import { MatomoProvider } from './MatomoProvider';
import * as serviceWorker from './serviceWorker';

import './awsRum';

import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './assets/aggrid.css';
import './assets/global.css';

const cookiesAccepted = getCookie('manaos-cookies-accepted');

render(
  <React.StrictMode>
    <CookiesProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {cookiesAccepted && <MatomoProvider />}
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
