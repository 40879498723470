import { IconButton } from '@mui/material';
import { useTestid, Icon } from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useMemo } from 'react';

type OrganisationListActionsProps = {
  emailList: EmailListApi.EmailList;
  onUpdate?(emailList: EmailListApi.EmailList): void;
  onDelete?(emailList: EmailListApi.EmailList): void;
  'data-testid': string;
};

export const OrganisationListActions: React.FC<OrganisationListActionsProps> = ({
  emailList,
  onUpdate,
  onDelete,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const handleUpdate = useMemo(() => onUpdate && (() => onUpdate(emailList)), [onUpdate, emailList]);
  const handleDelete = useMemo(() => onDelete && (() => onDelete(emailList)), [onDelete, emailList]);

  return (
    <>
      {handleUpdate && (
        <IconButton color="secondary" onClick={handleUpdate} data-testid={createTestid('button-edit')}>
          <Icon.Edit data-testid={createTestid('icon-edit')} />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton onClick={handleDelete} data-testid={createTestid('button-delete')}>
          <Icon.Delete color="danger" data-testid={createTestid('icon-delete')} />
        </IconButton>
      )}
    </>
  );
};
