import { colors, makeStyles } from 'mns-components';

const emitterPermissionsFormStyles = makeStyles({
  subTitles: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.blueColor,
    marginTop: '2.25rem',
  },
  formFooter: {
    textAlign: 'center',
    bottom: 0,
    position: 'absolute',
    width: 'calc(100% - 3.125rem)',
    height: '4.25rem',
    backgroundColor: `${colors.whiteColor} !important`,
    zIndex: 6666,
    '& > *': {
      margin: '1rem .25rem',
    },
    '& hr': {
      margin: 0,
      backgroundColor: 'rgba(95,122,241, 0.4)',
      height: '1px',
      border: 'none',
    },
  },
  buttonDeny: {
    background: 'rgba(210,210, 210, 0.4)',
    color: colors.infoColor,
    fontWeight: 'bold',
    opacity: 1,
    marginRight: '.25rem',
    '&:hover': {
      background: colors.whiteColor,
      color: colors.infoColor,
    },
  },
  buttonGrant: {
    background: colors.infoColor,
    color: colors.whiteColor,
    fontWeight: 'bold',
    opacity: 1,
    marginLeft: '.25rem',
  },
  formcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '25rem',
    marginBottom: '4rem',
  },
  customSelect: {
    margin: '.5rem',
    '& .MuiInputBase-root': {
      minHeight: '2.5rem',
    },
  },
});

export default emitterPermissionsFormStyles;
