import type { BuildStep } from 'mns-components';
import { Stepper, makeStep } from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useState } from 'react';
import type { AppCode } from '../../../components/views/appDescriptions';
import { getRoute } from '../routes';
import { CompilingFileStep } from './CompilingFileStep';
import { SelectDateStep } from './SelectDateStep';
import { SelectLinkedFileStep } from './SelectLinkedFileStep';
import { SelectPortfoliosStep } from './SelectPortfoliosStep';
import { SelectProvidersStep } from './SelectProvidersStep';
import type { GenerateEetFormSteps } from './types';

const steps: BuildStep<GenerateEetFormSteps>[] = [
  {
    Step: makeStep(0, SelectDateStep),
    title: 'Choose your reference date',
  },
  {
    Step: makeStep(1, SelectLinkedFileStep),
    title: 'Choose your input file',
  },
  {
    Step: makeStep(2, SelectPortfoliosStep),
    title: 'Choose your portfolios',
  },
  {
    Step: makeStep(3, SelectProvidersStep),
    title: 'Choose your ESG sources',
  },
  {
    Step: makeStep(4, CompilingFileStep),
    title: 'Generate your file',
  },
];

export type GeneratedInitialValue = {
  generalReferenceDate: string;
  eetLightId?: string;
  emtId?: string;
};

type GenerateEetStepperProps = {
  eet?: EetApi.Eet<AppCode>;
  generatedInitialValue?: GeneratedInitialValue;
  'data-testid': string;
};

export const GenerateEetStepper: React.FC<GenerateEetStepperProps> = ({
  eet,
  generatedInitialValue,
  'data-testid': testid,
}) => {
  const [currentStep, setCurrentStep] = useState(() => {
    if (eet) {
      switch (true) {
        case eet.status === 'SELECTED_DATE':
          return 1;
        case eet.status === 'INPUT_FILE_LINKED':
          return 2;
        case eet.status === 'SELECTED_PORTFOLIOS':
          return 3;
        case eet.status === 'SELECTED_ESG_APPS' || eet.status === 'REQUESTED_ESG_APPS':
          return 4;
      }
    }
    return 0;
  });

  const [stepValues, setStepValues] = useState((): GenerateEetFormSteps => {
    if (eet) {
      const val = eet as EetApi.EetProviderSelected<AppCode>;
      const date = val.generalReferenceDate;
      const fileName = val.fileName.replace(`EET-${date}-`, '');
      return [
        { generalReferenceDate: val.generalReferenceDate, fileName },
        { fileType: val.eetLightId ? 'EET_LIGHT' : 'EMT', emtId: val.emtId, eetLightId: val.eetLightId },
        { portfolios: (val.portfolios as EetApi.UploadedPortfolio[]) ?? [] },
        {
          taxonomyApp: val.taxonomyApp || undefined,
          sfdrApp: val.sfdrApp || undefined,
          shareSustainableInvestmentApp: val.shareSustainableInvestmentApp || undefined,
        },
        {},
      ];
    } else if (generatedInitialValue) {
      return [
        { generalReferenceDate: generatedInitialValue.generalReferenceDate, fileName: null },
        {
          fileType: generatedInitialValue.eetLightId ? 'EET_LIGHT' : 'EMT',
          emtId: generatedInitialValue.emtId ?? null,
          eetLightId: generatedInitialValue.eetLightId ?? null,
        },
        { portfolios: [] },
        { taxonomyApp: undefined, sfdrApp: undefined, shareSustainableInvestmentApp: undefined },
        {},
      ];
    }
    return [
      { generalReferenceDate: '', fileName: null },
      { fileType: 'EMT', eetLightId: null, emtId: null },
      { portfolios: [] },
      { taxonomyApp: undefined, sfdrApp: undefined, shareSustainableInvestmentApp: undefined },
      {},
    ];
  });

  return (
    <Stepper
      name="Create your EET"
      cancelHref={getRoute('eet-files').link}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepValues={stepValues}
      setStepValues={setStepValues}
      steps={steps}
      cancelLabel={eet ? 'Save and continue later' : 'Close'}
      data-testid={testid}
    />
  );
};
