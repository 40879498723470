import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  PublishButton,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  filterTruthy,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../../components/headerRender/QualitiesHeaderRenderer';
import { UploadActions } from '../upload/UploadActions';

type MyDocumentsListColumnsProps = {
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  handleVisible?(fileData: CollectApi.File): Promise<void>;
  'data-testid': string;
};

const useColumns = ({
  onDownload,
  onDownloadErrorLog,
  handleVisible,
  'data-testid': testid,
}: MyDocumentsListColumnsProps) =>
  useMemo(
    () =>
      filterTruthy<AggridTableColumn<CollectApi.File>>([
        {
          field: 'name',
          headerName: 'File name',
        },
        {
          field: 'dataFormat',
          headerName: 'File type',
        },
        {
          field: 'uploadedOn',
          headerName: 'Uploaded date',
          sort: 'desc',
          valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.uploadedOn),
          cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.uploadedOn),
        },
        {
          field: 'referenceDate',
          headerName: 'General reference date',
        },
        {
          field: 'shareClassCount',
          headerName: 'Scope',
          cellRendererFramework: ({ value }) =>
            value && Number.isNaN(value) ? 'Not yet defined' : `${value} share classes`,
        },
        {
          field: 'qualities',
          headerName: 'Qualities',
          cellRendererFramework: ({ value, data: { processId } }) => (
            <QualitiesRenderer qualities={value} data-testid={`${testid}-${processId}-qualities`} />
          ),
          headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
          minWidth: 100,
        },
        (onDownload || onDownloadErrorLog) &&
          handleVisible && {
            field: 'status',
            maxWidth: 70,
            cellRendererFramework: ({ value, data }) => (
              <UploadActions
                status={value}
                fileData={data}
                onDownload={onDownload}
                onDownloadErrorLog={onDownloadErrorLog}
                data-testid={`${testid}-${data.processId}`}
              />
            ),
          },
        {
          field: 'publicDocumentId',
          maxWidth: 100,
          cellRendererFramework: ({ value, data }) => (
            <PublishButton
              isVisible={value ? true : false}
              onVisible={handleVisible && (() => handleVisible(data))}
              data-testid={`${testid}-${data.processId}-button-${value ? 'unpublish' : 'publish'}`}
            />
          ),
        },
      ]),
    [onDownload, onDownloadErrorLog, handleVisible, testid],
  );

type MyDocumentsListProps = {
  rowData: CollectApi.File[];
  searchValue?: string;
  handleVisible?(fileData: CollectApi.File): Promise<void>;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  'data-testid': string;
};

export const MyDocumentsList: React.FC<MyDocumentsListProps> = ({
  rowData,
  searchValue = '',
  handleVisible,
  onDownload,
  onDownloadErrorLog,
  'data-testid': testid,
}: MyDocumentsListProps) => (
  <AggridTable
    quickFilterText={searchValue}
    rowData={rowData}
    defaultColDef={useDefaultColDef<CollectApi.File>()}
    columnDefs={useColumns({
      onDownload,
      onDownloadErrorLog,
      handleVisible,
      'data-testid': testid,
    })}
    getRowNodeId={useImmutable(() => (row: CollectApi.File) => row.processId)}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
