import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type {
  FormStepErrors,
  FormStepperStepProps,
  FormStepProps,
  FormStepValidator,
  JsonSchemaObject,
} from 'mns-components';
import {
  EmptyStateV2,
  FormCreator,
  isString,
  makeForm,
  uniqueBy,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useFilesRequest } from '../../../views/hooks';
import { createEmailDeliveryModalStyles as useStyles } from '../createEmailDeliveryModalStyles';
import type { CreateEmailDeliveryFileSteps } from '../types';
import { SelectScopeFileList } from './SelectScopeFileList';

const viewFileTypes: CollectApi.File['dataFormat'][] = ['EET', 'EMT'];

const getFileReferenceDate = (files: CollectApi.File[]) =>
  files.reduce((acc, file) => {
    if (file.referenceDate && !acc.includes(file.referenceDate)) {
      acc.push(file.referenceDate);
    }
    return acc;
  }, [] as string[]);

type FormType = { title: string; referenceDate: string };

const useInputSchema = (refDateOptions: string[], referenceDate = '') =>
  useMemo(
    (): JsonSchemaObject => ({
      variant: 'column',
      properties: {
        title: {
          type: 'string',
          title: 'Delivery name',
          variant: 'text',
          placeholder: `ie: Delivery of ${referenceDate}`,
        },
        referenceDate: {
          type: 'string',
          title: 'general reference date',
          variant: 'autocomplete',
          enum: refDateOptions,
        },
      },
      required: ['title', 'referenceDate'],
    }),
    [refDateOptions, referenceDate],
  );

type SelectScopeStepProps = FormStepProps<CreateEmailDeliveryFileSteps[0]>;

const SelectScopeFileStep: React.FC<SelectScopeStepProps> = ({
  stepValue: { title, referenceDate, fileIds },
  setStepValue,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { data: filesData } = useFilesRequest({ dataFormats: viewFileTypes });

  const filteredData = filesData?.filter((file) => ['OK', 'WARNING'].includes(file.status));
  const refDateOptions = useMemo(() => (!filteredData ? [] : getFileReferenceDate(filteredData)), [filteredData]);
  const jsonSchema = useInputSchema(refDateOptions, referenceDate);

  const rows = useMemo(
    () =>
      filteredData?.length && referenceDate
        ? uniqueBy(
            filteredData.filter((file) => file.referenceDate === referenceDate),
            'name',
            (list) => list.reduce((acc, item) => (acc.uploadedOn.localeCompare(item.uploadedOn) > 0 ? acc : item)),
          )
        : [],
    [referenceDate, filteredData],
  );

  const handleRowsSelected = useCallbackImmutable((selectList: CollectApi.File[]) =>
    setStepValue((current) => ({ ...current, fileIds: selectList.map((row) => row.processId) })),
  );

  const handleFormChange = useCallbackImmutable((update: (current: FormType) => FormType) =>
    setStepValue((current) => ({
      ...current,
      ...update({ referenceDate: current.referenceDate, title: current.title }),
    })),
  );

  const handleGridLoad = useCallbackImmutable((grid: GridApi | null) => {
    grid?.forEachNode((node) => {
      const data = node.data as CollectApi.Portfolio;
      if (fileIds?.includes(data.latestVersion.id)) {
        node.setSelected(true);
      }
    });
  });

  const formState = useMemo((): FormType => {
    const currentRefDate = referenceDate ?? refDateOptions[0];
    const shortUUID = Math.random().toString(36).substring(2, 10);

    const currentTitle = title ?? `email-delivery-${shortUUID}`;

    return { title: currentTitle, referenceDate: currentRefDate };
  }, [title, referenceDate, refDateOptions]);

  if (!rows.length) {
    return (
      <>
        <FormCreator<FormType>
          className={classes.form}
          jsonSchema={jsonSchema}
          state={formState}
          setState={handleFormChange}
          data-testid={createTestid('form')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noSearchResults"
            title="There is no match with your research!"
            subTitle="You can search again with new criteria."
            illustrationVariant="iconType"
            data-testid={createTestid('empty-rows')}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      <FormCreator<FormType>
        className={classes.form}
        jsonSchema={jsonSchema}
        state={formState}
        setState={handleFormChange}
        data-testid={createTestid('form')}
      />
      <SelectScopeFileList
        rows={rows}
        onRowsSelected={handleRowsSelected}
        getGridApiRef={handleGridLoad}
        data-testid={createTestid('selectScopeList')}
      />
    </>
  );
};

const validateSelectScopeStep: FormStepValidator<CreateEmailDeliveryFileSteps[0]> = (data) => {
  const errors: FormStepErrors<CreateEmailDeliveryFileSteps[0]> = [];

  if (!isString(data.title)) {
    errors.push({
      name: 'title',
      message: 'Delivery name should be filled',
    });
  } else if (!data.title.length) {
    errors.push({
      name: 'title',
      message: 'Delivery name should not be empty',
    });
  }

  if (!Array.isArray(data.fileIds) || !data.fileIds.length) {
    errors.push({
      name: 'fileIds',
      message: 'Please select at least one file',
    });
  }

  return errors;
};

export const buildSelectScopeFileStep = (): FormStepperStepProps<CreateEmailDeliveryFileSteps[0]> => ({
  build: makeForm(SelectScopeFileStep),
  title: 'Select Scope',
  onValidate: validateSelectScopeStep,
});
