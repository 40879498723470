import { Stack } from '@mui/material';
import { Accordion, Switch, Typo, spinalCase, useTestid } from 'mns-components';

export type NotificationGroupListProps = {
  groups: {
    title: string;
    items: { id: string; label: string; value: boolean; key: string }[];
  }[];
  onClick(notificationCode: string): void;
  disabled?: boolean;
  'data-testid': string;
};

export const NotificationsGroupList = ({
  groups,
  onClick,
  disabled,
  'data-testid': testId,
}: NotificationGroupListProps) => {
  const createTestid = useTestid(testId);

  const handleChange = (id: string) => () => {
    onClick(id);
  };

  return (
    <>
      {groups.map(({ title, items }) => (
        <Accordion title={title} data-testid={createTestid(`${spinalCase(title)}-accordeon`)} key={title}>
          <Stack gap={'0.5rem'}>
            {items.map(({ label, value, key, id }) => (
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between" key={key}>
                <Typo variant="body2" data-testid={createTestid(`${spinalCase(title)}-text-${id}`)}>
                  {label}
                </Typo>
                <Switch
                  uncontrolled
                  isIconVisible
                  disabled={disabled}
                  variant="secondary"
                  data-testid={createTestid(`${spinalCase(title)}-toggler-${id}`)}
                  value={value}
                  onChange={handleChange(id)}
                />
              </Stack>
            ))}
          </Stack>
        </Accordion>
      ))}
    </>
  );
};
