import { GuidedTourProvider, MnsComponentsProvider } from 'mns-components';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TermsAndConditions } from './applications/marketplace/termsAndConditions/TermsAndConditions';
import { AppRouter } from './AppRouter';
import CookiesBanner from './components/layout/CookiesBanner';
import LayoutHandler from './components/layout/handler';
declare const window: ThisWindow;

window.hsConversationsSettings = {
  loadImmediately: false,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <MnsComponentsProvider>
      <GuidedTourProvider data-testid="guidedTour">
        <CookiesBanner data-testid="cookie" />
        <LayoutHandler data-testid="layout">
          <TermsAndConditions data-testid="tcs" />
          <AppRouter />
        </LayoutHandler>
      </GuidedTourProvider>
    </MnsComponentsProvider>
  </QueryClientProvider>
);

export default App;
