import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import type { CheckboxValue } from 'mns-components';
import { Checkbox, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useState } from 'react';
import { EmitterPermissionsRequestAccessForm } from '../../../../components/forms/EmitterPermissionsRequestAccessForm';
import type { EmitterPermissionFormData } from '../../../../components/forms/types';
import useStyles from '../styles/RequestAccessPermission.styles';

const getKey = (item: CollectApi.FundPositionToCollect) =>
  `${item.disseminatorPortfolio?.portfolioId}-${item.fundPosition.externalId}`;

interface AccessRequestProps {
  selectedItems: CollectApi.FundPositionToCollect[];
  closeModal: () => void;
  savePermissionsFormHandler: (
    formData: EmitterPermissionFormData,
    selectedItems: CollectApi.FundPositionToCollect[],
  ) => Promise<void>;
  'data-testid': string;
}

export const RequestAccessDetailsPermission: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  savePermissionsFormHandler,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const [checkedItems, setCheckedItems] = useState<string[]>();

  useEffect(() => {
    const items = selectedItems.map((item) => getKey(item));
    setCheckedItems(items);
  }, [selectedItems, setCheckedItems]);

  const handleCheckboxChange = useCallbackImmutable((field: CheckboxValue) => {
    if (checkedItems === undefined) return;

    if (field.value && !checkedItems.includes(field.name)) {
      setCheckedItems([...checkedItems, field.name]);
    }

    if (!field.value) {
      setCheckedItems(checkedItems.filter((item) => item !== field.name));
    }
  });

  const handleSubmit = useCallbackImmutable(async (formData: EmitterPermissionFormData) => {
    const items = selectedItems.filter((item) => checkedItems?.includes(getKey(item)));
    return savePermissionsFormHandler(formData, items);
  });

  return (
    <>
      <div className={classes.titleContainerWithIcon}>
        <Icon.Info data-testid={createTestid('icon-info')} />
        <h3 className={classes.cardTitles}>You are about to request access for the following shareclass</h3>
      </div>
      <h3 className={classes.subTitles}>Shareclass selected</h3>
      <Table aria-label="simple table" data-testid={createTestid('table-selected')}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableSelector}></TableCell>
            <TableCell className={classes.tableHeaders}>Shareclass ID</TableCell>
            <TableCell className={classes.tableHeaders}>Shareclass name</TableCell>
            <TableCell className={classes.tableHeaders}>Asset manager</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItems.map((row, index) => (
            <TableRow key={getKey(row)}>
              <TableCell>
                <Checkbox
                  uncontrolled
                  name={getKey(row)}
                  onFieldChange={handleCheckboxChange}
                  defaultChecked={true}
                  data-testid={createTestid(`checkbox-${index}`)}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {row.fundPosition.externalId}
              </TableCell>
              <TableCell className={classes.tableContent}>{row.fundPosition.name}</TableCell>
              <TableCell className={classes.tableContent}>{row.disseminatorPortfolio?.organisationName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EmitterPermissionsRequestAccessForm
        handleSave={handleSubmit}
        handleCancel={closeModal}
        data-testid={createTestid('form')}
      />
    </>
  );
};
