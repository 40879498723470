import type { RouterInterface } from '../../components/routing/RouterInterface';
import { AutomatedExtractionsSettingsPage } from './automatedExtractionsSettings/AutomatedExtractionsSettingsPage';
import { ExtractionsGeneratedPage } from './extractionsGenerated/ExtractionsGeneratedPage';
import { ExtractorPreferences } from './preferences/ExtractorPreferences';
import { ExtractorTemplatesPage } from './templates/ExtractorTemplatesPage';

export const extractorRoutes = [
  {
    label: 'Extractions generated',
    link: '/apps/manaos-extractor/extractions-generated',
    key: 'extractor-extractions-generated',
    component: ExtractionsGeneratedPage,
    autoselect: true,
  },
  {
    label: 'Automated extractions settings',
    link: '/apps/manaos-extractor/automated-extractions-settings',
    key: 'extractor-automated-extractions-settings',
    component: AutomatedExtractionsSettingsPage,
    autoselect: true,
  },
  {
    label: 'Templates',
    link: '/apps/manaos-extractor/templates',
    key: 'extractor-templates',
    component: ExtractorTemplatesPage,
  },
  {
    label: 'Preferences',
    link: '/apps/manaos-extractor/preferences',
    key: 'extractor-preferences',
    component: ExtractorPreferences,
  },
] as const;

type ExtractorRoutesKeys = typeof extractorRoutes[number]['key'];

export const routes: readonly RouterInterface[] = extractorRoutes;

export const getRoute = <K extends ExtractorRoutesKeys>(key: K) =>
  extractorRoutes.find((route) => route.key === key)! as Replace<RouterInterface, 'key', K>;

export const getDataExtractorRoute = getRoute;
