import { IconButton, Menu, Stack } from '@mui/material';
import { HeaderButtonPortal, Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGuidedTour } from '../../hooks/useGuidedTour';
import type { GuidedTourScenario } from '../guidedTour';

const hrefMap: Record<string, string> = {
  portfolios: 'uploading-on-manaos',
  files: 'uploading-on-manaos',
  'settings/users': 'adding-new-users',
  'manaos-collection': 'manaos-apps#collection-app',
  'manaos-dissemination': 'manaos-apps#dissemination-app',
  'coverage-estimation': 'working-with-the-portfolio-coverage-estimation-app',
  'manaos-paistatement': 'working-with-the-pai-statement-generator',
  'manaos-productrangecollection': 'eet-collection',
  'manaos-productrangedissemination': 'eet-dissemination',
  esg: 'partners-apps',
};

const CustomHeaderGuidedTourMenuItem: React.FC<{ scenario: GuidedTourScenario; 'data-testid': string }> = ({
  scenario,
  'data-testid': testid,
}) => {
  const { start: startGuidedTour } = useGuidedTour(scenario);

  return (
    <MenuItem onClick={startGuidedTour} data-testid={testid}>
      Guided tour
    </MenuItem>
  );
};

export const CustomHeaderButtons: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentScenario: GuidedTourScenario | undefined = useMemo(() => {
    if (pathname.includes('marketplace')) return 'welcome';
    if (pathname.includes('manaos-collection')) return 'collection';
    if (pathname.includes('coverage-estimation')) return 'coverageEstimation';
    if (pathname.includes('manaos-dissemination')) return 'dissemination';
  }, [pathname]);

  const currentRoute: string = useMemo(() => {
    let value = '';
    for (const key in hrefMap) {
      if (pathname.includes(key)) value = hrefMap[key];
    }
    return value;
  }, [pathname]);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable(() => {
    setAnchorEl(null);
  });

  return (
    <Stack direction="row" alignItems="center" spacing="1rem">
      <HeaderButtonPortal />
      <IconButton onClick={handleClick} data-testid={createTestid(`help-button`)}>
        <Icon.Help
          color="default"
          data-testid={createTestid('help-icon')}
          data-stepid={`${currentScenario}-help-button`}
        />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        data-testid={createTestid('menu')}
        PaperProps={{
          sx: {
            mt: 1,
          },
        }}
      >
        {currentScenario && (
          <CustomHeaderGuidedTourMenuItem
            scenario={currentScenario}
            data-testid={createTestid('menuItem-guidedTour')}
          />
        )}
        <MenuItem.Link
          to={{ pathname: 'https://faq.manaos.com/manaos-faq/' + currentRoute }}
          target="_blank"
          data-testid={createTestid('menuItem-faq')}
        >
          FAQ
        </MenuItem.Link>
      </Menu>
    </Stack>
  );
};
