import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import {
  Backdrop,
  appendUTCToken,
  convertToDateTimeUTCWithTZ,
  exportCSV,
  filterTruthy,
  useCallbackImmutable,
  useTestid,
  EmptyStateV2,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { downloadCSV } from '../../../common/utils';
import {
  isFundAllowedToChaseUp,
  isFundAllowedToDownload,
  isFundAllowedToInvite,
  isFundAllowedToRequestAccess,
} from '../../../components/cellRender/CollectionStatusButtonRenderer';
import { collectStatusRendererPlain } from '../../../components/cellRender/CollectStatusRenderer';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { usePtfAndDate } from '../../../hooks/useGlobalState';
import { useGetInventoriesToCollect, useUploadModalOpen } from '../hooks';
import { ManageInventoriesPermissions } from './components/ManageInventoriesPermissions';
import type { SelectedStatus } from './InventoriesController';
import {
  InventoriesController,
  downloadOptions,
  filterByStatusAndProviderAndAssetManager,
  getAssetManagersOptions,
  getProviderOptions,
  getQualityOptions,
  getStatusesOptions,
} from './InventoriesController';
import { CollectInventoriesList } from './InventoriesList';
import { CollectDetailsModal } from './modal/CollectHistoryDetails';
import { CollectHistoryModal } from './modal/CollectHistoryModal';

type ExportFormat = {
  externalId: string;
  fundName: string;
  issuerName: string;
  sharingFrequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY';
  receptionDate?: string;
  updateDate?: string;
  lastRegulatoryNavDate?: string;
  lastRegulatoryReceptionDate?: string;
  deadline?: string;
  organisationOwnerName?: string;
  uploadSource?: string;
  valuationDate: string;
  qualities: string;
  status: string;
  chaseUp: number;
};

const exportInventoriesAsCsv = (kpis: CollectApi.FundPositionToCollect[], valuationDate: string) =>
  downloadCSV(
    exportCSV(
      kpis.map(
        (row): ExportFormat => ({
          externalId: row.fundPosition.externalId,
          fundName: row.fundPosition.name,
          issuerName: row.fundPosition.issuerName,
          sharingFrequency: row.accessRequest?.sharingFrequency,
          receptionDate:
            row.disseminatorPortfolio?.receptionDate &&
            convertToDateTimeUTCWithTZ(appendUTCToken(row.disseminatorPortfolio?.receptionDate)),
          updateDate:
            row.disseminatorPortfolio?.updateDate &&
            convertToDateTimeUTCWithTZ(appendUTCToken(row.disseminatorPortfolio?.updateDate)),
          lastRegulatoryNavDate: row.disseminatorPortfolio?.lastRegulatoryNavDate,
          lastRegulatoryReceptionDate: row.disseminatorPortfolio?.lastRegulatoryReceptionDate,
          deadline: row.disseminatorPortfolio?.deadline,
          organisationOwnerName: row.disseminatorPortfolio?.organisationName,
          uploadSource: row.disseminatorPortfolio?.ingestionInfos?.uploadSource,
          valuationDate: valuationDate,
          qualities: row.disseminatorPortfolio?.ingestionInfos?.qualities?.join(', ') ?? '',
          status: collectStatusRendererPlain({
            receptionDate: row.disseminatorPortfolio?.receptionDate,
            disseminationStatus: row.disseminatorPortfolio?.disseminationStatus,
          }),
          chaseUp: row.disseminatorPortfolio?.chaseUpCount ?? 0,
        }),
      ),
      {
        externalId: 'Fund id',
        fundName: 'Fund name',
        issuerName: 'Issuer',
        sharingFrequency: 'Valuation frequency',
        valuationDate: 'NAV date',
        receptionDate: 'First received on',
        updateDate: 'Updated on (if applicable)',
        lastRegulatoryNavDate: 'Last regulatory NAV date',
        lastRegulatoryReceptionDate: 'Last regulatory reception date',
        deadline: 'Dissemination deadline',
        organisationOwnerName: 'Uploaded by',
        uploadSource: 'Import type',
        qualities: 'Qualities',
        status: 'Status',
        chaseUp: 'Number of chase up',
      },
    ),
    `export-${valuationDate}`,
  );

type InventoriesProps = { 'data-testid': string };

export const Inventories: React.FC<InventoriesProps> = ({ 'data-testid': testid }: InventoriesProps) => {
  const createTestid = useTestid(testid);
  const { trackPageView } = useMatomo();

  const [[portfolioId, valuationDate]] = usePtfAndDate();

  const [selectedItems, setSelectedItems] = useState<CollectApi.FundPositionToCollect[]>([]);
  const [downloadItems, setDownloadItems] = useState<CollectApi.FundPositionToCollect[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => trackPageView({ documentTitle: `Collect App - Todo` }), [trackPageView]);

  const {
    data: inventoriesToCollectData,
    isLoading,
    refetch: refetchInventoriesToCollect,
  } = useGetInventoriesToCollect(portfolioId ?? '', valuationDate ?? '');

  const orgId = localStorage.getItem('organization-id') ?? '';
  const fundPositions = inventoriesToCollectData?.fundToCollectDetailsDtos;

  const statusOptions = useMemo(() => getStatusesOptions(fundPositions, orgId), [fundPositions, orgId]);
  const providerOptions = useMemo(() => getProviderOptions(fundPositions), [fundPositions]);
  const assetManagerOptions = useMemo(() => getAssetManagersOptions(fundPositions), [fundPositions]);
  const qualityOptions = useMemo(() => getQualityOptions(fundPositions), [fundPositions]);

  const [status, setStatus] = useState('all');
  const [provider, setProvider] = useState('all');
  const [assetManager, setAssetManager] = useState('all');
  const [quality, setQuality] = useState<'all' | CollectApi.Quality>('all');
  const gridApi = useRef<GridApi | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatus[]>([]);
  const [displayedHistory, setDisplayedHistory] = useState<Required<CollectApi.FundPositionToCollect> | null>(null);
  const [displayedDetails, setDisplayedDetails] = useState<Required<CollectApi.FundPositionToCollect> | null>(null);

  // on close
  const onClose = useCallbackImmutable((): void => {
    if (valuationDate && portfolioId) {
      refetchInventoriesToCollect();
      setSelectedItems([]);
      setSelectedStatus([]);
    }
  });

  const onResetFilters = useCallbackImmutable((): void => {
    setStatus('all');
    setProvider('all');
    setAssetManager('all');
    setQuality('all');
  });

  const setGridApi = useCallbackImmutable((g: GridApi) => {
    gridApi.current = g;
  });

  const handleRowsSelected = useCallbackImmutable((rows: CollectApi.FundPositionToCollect[]) => {
    const downloadList = rows.filter(isFundAllowedToDownload);

    setDownloadItems(downloadList);

    const isExistStatus = rows.find((item) => isFundAllowedToRequestAccess(item, orgId));
    const isAcceptedStatus = rows.find(isFundAllowedToChaseUp);
    const isNoExistingStatus = rows.find((row) => isFundAllowedToInvite(row));

    setSelectedStatus(
      filterTruthy<SelectedStatus>([
        isAcceptedStatus && 'ACCEPTED',
        isExistStatus && 'EXIST',
        downloadList.length && 'DOWNLOAD',
        isNoExistingStatus && 'NO_EXISTING',
      ]),
    );
  });

  const filteredData = useMemo(
    () => filterByStatusAndProviderAndAssetManager(fundPositions, orgId, status, provider, assetManager, quality),
    [fundPositions, orgId, status, provider, assetManager, quality],
  );

  const handleExport = useCallbackImmutable(() => {
    if (valuationDate) {
      exportInventoriesAsCsv(filteredData, valuationDate);
    }
  });

  const handleDisplay = useCallbackImmutable((view: 'chaseUp' | 'request' | 'invite') => {
    const rows = gridApi.current?.getSelectedRows() as undefined | CollectApi.FundPositionToCollect[];
    if (rows?.length) {
      if (view === 'chaseUp') setSelectedItems(rows.filter(isFundAllowedToChaseUp));
      if (view === 'request') setSelectedItems(rows.filter((row) => isFundAllowedToRequestAccess(row, orgId)));
      if (view === 'invite') setSelectedItems(rows.filter((row) => isFundAllowedToInvite(row)));
    }
  });

  const downloadPresignedUrl = useDownloadPresignedUrl();

  const handleDownload = useCallbackImmutable(
    async (data: CollectApi.FundPositionToCollect, dlType: CollectApi.ShareclassFileType): Promise<void> => {
      const latestVersionAsOf = data.disseminatorPortfolio?.ingestionInfos?.latestVersionAsOf;
      const externalId = data.fundPosition.externalId;
      const externalIdType = data.fundPosition.externalIdType;
      const externalIdOwner =
        data.fundPosition?.externalId !== data.disseminatorPortfolio?.externalId
          ? data.disseminatorPortfolio?.externalId
          : undefined;

      if (!latestVersionAsOf) {
        return;
      }
      const dlLabel = downloadOptions.find((assoc) => assoc.value === dlType)!.children;
      let hasDownloaded = await downloadPresignedUrl(
        {
          fileDownloadType: dlType,
          fileDownloadTypeLabel: `${dlLabel}`,
          externalId: externalId,
          externalIdType: externalIdType,
          valuationDate: latestVersionAsOf,
          fileDate: latestVersionAsOf,
          fileName: externalId,
        },
        externalIdOwner === undefined,
      );

      if (!hasDownloaded && externalIdOwner !== undefined) {
        const externalIdTypeUsed = externalIdType != 'INTERNAL' ? 'INTERNAL' : externalIdType;
        hasDownloaded = await downloadPresignedUrl(
          {
            fileDownloadType: dlType,
            fileDownloadTypeLabel: `${dlLabel}`,
            externalId: externalId,
            externalIdType: externalIdTypeUsed,
            valuationDate: latestVersionAsOf,
            fileDate: latestVersionAsOf,
            fileName: externalId,
          },
          false,
        );
      }

      if (!hasDownloaded && externalIdOwner !== undefined) {
        const hasDownloadedWithOriginalExternalIdType = await downloadPresignedUrl(
          {
            fileDownloadType: dlType,
            fileDownloadTypeLabel: `${dlLabel}`,
            externalId: externalIdOwner ?? '',
            externalIdType: externalIdType,
            valuationDate: latestVersionAsOf,
            fileDate: latestVersionAsOf,
            fileName: externalId,
          },
          false,
        );

        if (!hasDownloadedWithOriginalExternalIdType) {
          await downloadPresignedUrl({
            fileDownloadType: dlType,
            fileDownloadTypeLabel: `${dlLabel}`,
            externalId: externalIdOwner ?? '',
            externalIdType: 'ISIN',
            valuationDate: latestVersionAsOf,
            fileDate: latestVersionAsOf,
            fileName: externalId,
          });
        }
      }
    },
  );

  const handleClickDownload = useCallbackImmutable((dlType: CollectApi.ShareclassFileType): void => {
    // sonar fix
    Promise.all(downloadItems.map((data) => handleDownload(data, dlType))).then();
  });

  const handleOpenModal = useUploadModalOpen();
  const handleDisplayItem = useCallbackImmutable((item: CollectApi.FundPositionToCollect) => setSelectedItems([item]));
  const handleDisplayHistory = useCallbackImmutable((item: CollectApi.FundPositionToCollect) =>
    setDisplayedHistory(item as Required<CollectApi.FundPositionToCollect>),
  );
  const handleDisplayDetails = useCallbackImmutable((item: CollectApi.FundPositionToCollect) =>
    setDisplayedDetails(item as Required<CollectApi.FundPositionToCollect>),
  );
  const handleCloseHistory = useCallbackImmutable(() => setDisplayedHistory(null));
  const handleCloseDetails = useCallbackImmutable(() => setDisplayedDetails(null));

  if (isLoading) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!valuationDate || !portfolioId || !fundPositions) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="upload"
          title="Get started by uploading your portfolio !"
          subTitle="Start your collection journey by uploading your portoflio."
          buttonOnClick={handleOpenModal}
          buttonText="Upload portfolio"
          buttonVariant="secondary"
          buttonIcon="upload"
          data-testid={createTestid('empty-ptf')}
        />
      </Stack>
    );
  }

  if (fundPositions.length === 0) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There are no inventories to collect in this portfolio yet!"
          subTitle="Please select another portfolio that contains shareclasses to collect or upload a new one."
          data-testid={createTestid('empty-funds')}
        />
      </Stack>
    );
  }

  // Filters
  return (
    <>
      <InventoriesController
        selectedStatus={selectedStatus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        statuses={statusOptions}
        status={status}
        setStatus={setStatus}
        provider={provider}
        providers={providerOptions}
        setProvider={setProvider}
        assetManagers={assetManagerOptions}
        assetManager={assetManager}
        setAssetManager={setAssetManager}
        quality={quality}
        qualityOptions={qualityOptions}
        setQuality={setQuality}
        countAll={fundPositions.length}
        onReset={onResetFilters}
        onExport={handleExport}
        onDisplay={handleDisplay}
        onDownload={handleClickDownload}
        data-testid={createTestid('controller')}
      />
      <CollectInventoriesList
        searchValue={searchValue}
        rowData={filteredData}
        onDisplayItem={handleDisplayItem}
        onDisplayHistory={handleDisplayHistory}
        onDisplayDetails={handleDisplayDetails}
        onDownload={handleDownload}
        onRowsSelected={handleRowsSelected}
        getGridApiRef={setGridApi}
        data-testid={createTestid('list')}
      />
      <ManageInventoriesPermissions
        selectedItems={selectedItems}
        onClose={onClose}
        data-testid={createTestid('permissions')}
      />
      <CollectHistoryModal
        portfolio={displayedHistory}
        onClose={handleCloseHistory}
        data-testid={createTestid('collectHistoryModal')}
      />
      <CollectDetailsModal
        portfolio={displayedDetails}
        onClose={handleCloseDetails}
        data-testid={createTestid('collectDetailsModal')}
      />
    </>
  );
};
