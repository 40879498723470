import { FormStepper, useCallbackImmutable, useImmutable } from 'mns-components';
import type { FormStepperProps, FormStepperStepProps } from 'mns-components';
import type { ProviderApi, MarketplaceApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../../common/getImageContent';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { useUser } from '../../../../hooks/useAuth';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import { usePayAnalysisPortfolioRequest, useTriggerPortfolioCoverageRequest } from '../../hooks';
import { BillStep, isValidBillStep } from './BillStep';
import type { PayRequestSteps } from './createRequest.types';
import { isValidPayStep, PayStep } from './PayStep';

type PayStepsProps = {
  appCode: AppCode;
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  needsCoverage: boolean;
  isNotPriced: boolean;
  disablePtfAndDate?: boolean;
};

const paySteps = ({
  appCode,
  portfolios,
  applicationDetails,
  needsCoverage,
  isNotPriced,
  disablePtfAndDate,
}: PayStepsProps): FormStepperProps<PayRequestSteps>['steps'] => {
  if (needsCoverage) {
    return [
      {
        title: 'Request coverage',
        build: (args) => (
          <PayStep {...args} appCode={appCode} portfolios={portfolios} disablePtfAndDate={disablePtfAndDate} />
        ),
        onValidate: isValidPayStep,
      } as FormStepperStepProps<PayRequestSteps[0], PayRequestSteps>,
    ];
  }
  if (isNotPriced) {
    return [
      {
        title: 'Select analysis options',
        build: (args) => <PayStep {...args} appCode={appCode} portfolios={portfolios} />,
        onValidate: isValidPayStep,
      } as FormStepperStepProps<PayRequestSteps[0], PayRequestSteps>,
    ];
  }
  return [
    {
      title: 'Select analysis options',
      build: (args) => <PayStep {...args} appCode={appCode} portfolios={portfolios} />,
      onValidate: isValidPayStep,
    } as FormStepperStepProps<PayRequestSteps[0], PayRequestSteps>,
    {
      title: 'Confirm order',
      build: (args) => (
        <BillStep {...args} portfolios={portfolios} applicationDetails={applicationDetails} appCode={appCode} />
      ),
      onValidate: isValidBillStep,
    } as FormStepperStepProps<PayRequestSteps[1], PayRequestSteps>,
  ];
};

type PayRequestStepperProps = {
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  appCode: AppCode;
  closeModal(): void;
  disablePtfAndDate?: boolean;
  'data-testid': string;
};

export const PayRequestStepper: React.FC<PayRequestStepperProps> = ({
  portfolios,
  applicationDetails,
  appCode,
  closeModal,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const {
    company: { name },
    content: { title },
  } = useApplicationConfig(appCode);
  const [needsCoverage, setNeedCoverage] = useState<boolean>(false);
  const [isNotPriced, setIsNotPriced] = useState<boolean>(false);
  const [, setPtfAndDate] = usePtfAndDate();
  const appBackgroundClassName = useAppBackgroundStyles()[appCode];
  const { organisationId: orgId } = useUser();

  const header = useMemo(
    (): FormStepperProps<PayRequestSteps>['header'] => ({
      appTitle: title,
      providerName: name,
      appBackgroundClassName,
    }),
    [name, title, appBackgroundClassName],
  );

  const {
    mutateAsync: payAnalysisPortfolios,
    isLoading: isAnalysisSubmitting,
    isSuccess: isAnalysisSent,
  } = usePayAnalysisPortfolioRequest();

  const {
    mutateAsync: triggerPortfolioCoverage,
    isLoading: isCoverageSubmitting,
    isSuccess: isCoverageSent,
  } = useTriggerPortfolioCoverageRequest();

  const handleSubmit = useCallbackImmutable(async (data: PayRequestSteps) => {
    const { portfolioId, valuationDate } = data[0];
    const ptf = portfolios.find((item) => item.portfolioId === portfolioId);

    const [{ needsCoverage: n, agreeCoverage }] = data;
    if (ptf && n && agreeCoverage) {
      await triggerPortfolioCoverage({
        appCode,
        externalId: ptf.externalId.value,
        externalIdType: ptf.externalId.type,
        nbrOfSecurities: ptf.numberOfLines,
        valuationDate: valuationDate,
        portfolioOriginType: ptf.organisationId === orgId ? 'UPLOADED' : 'COLLECTED',
      });
      setPtfAndDate([portfolioId, valuationDate]);
      return closeModal();
    }

    const [{ isNotPriced: p }] = data;
    if (p && ptf) {
      window.open('https://manaos.com/book-a-demo/', '_blank', 'noopener noreferrer');
      setPtfAndDate([portfolioId, valuationDate]);
      return closeModal();
    }

    const [
      { offer: { offerId } = { offerId: undefined }, options },
      { agreeOrderConditions, address, billedTo, company, function: fn, vat },
    ] = data;
    if (ptf && offerId && options && agreeOrderConditions) {
      await payAnalysisPortfolios({
        appCode,
        address,
        billedTo,
        company,
        offerId,
        options,
        valuationDate,
        externalId: ptf.externalId.value,
        externalIdType: ptf.externalId.type,
        function: fn,
        intraCommunity: vat,
        portfolioName: ptf.name,
        portfolioVersionId: ptf.portfolioVersionId,
        providerCoverage: ptf.providerCoverage ?? undefined,
        portfolioOriginType: ptf.organisationId === orgId ? 'UPLOADED' : 'COLLECTED',
      });
      setPtfAndDate([portfolioId, valuationDate]);
      return closeModal();
    }

    throw new Error(`Can not find portfolio ${portfolioId}`);
  });

  useEffect(() => {
    if (isAnalysisSent || isCoverageSent) closeModal();
  }, [isAnalysisSent, isCoverageSent, closeModal]);

  const steps = useMemo(
    () => paySteps({ appCode, portfolios, applicationDetails, needsCoverage, isNotPriced, disablePtfAndDate }),
    [appCode, applicationDetails, portfolios, needsCoverage, isNotPriced, disablePtfAndDate],
  );

  const verifyCoverageNeeded = useImmutable(() => (data: PayRequestSteps) => {
    const [{ needsCoverage: n, isNotPriced: p }] = data;
    setNeedCoverage(!!n);
    setIsNotPriced(!!p);
  });

  return (
    <FormStepper
      header={header}
      steps={steps}
      onSubmit={handleSubmit}
      onCancel={closeModal}
      onChange={verifyCoverageNeeded}
      buttonBackground="white"
      isSubmitting={isAnalysisSubmitting || isCoverageSubmitting}
      displayCounter={steps.length > 1}
      displayNavigation
      displayErrors
      data-testid={testid}
    />
  );
};
