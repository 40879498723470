import { colors, makeStyles } from 'mns-components';

const revokePermissionsStyle = makeStyles({
  subTitles: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.blueColor,
    marginTop: '1rem',
    marginLeft: '1rem',
  },
  description: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    marginTop: '0.625rem',
    marginBottom: '1rem',
    '& svg': {
      color: colors.dangerColor,
      marginRight: '0.625rem',
    },
  },
  tableHeaders: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '.875rem',
    lineHeight: '1rem',
    textAlign: 'left',
    color: colors.blueColor,
    opacity: '60%',
  },
  tableContent: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '.875rem',
    textAlign: 'left',
    color: colors.blueColor,
    '& svg': {
      verticalAlign: 'middle',
      color: colors.infoColor,
      marginRight: '0.25rem',
    },
  },
  formFooter: {
    textAlign: 'center',
    bottom: 0,
    position: 'absolute',
    width: 'calc(100% - 3rem)',
    height: '4.25rem',
    backgroundColor: `${colors.whiteColor} !important`,
    zIndex: 6666,
    '& > *': {
      margin: '1rem .25rem',
    },
    '& hr': {
      margin: 0,
      backgroundColor: 'rgba(95,122,241, 0.4)',
      height: '1px',
      border: 'none',
    },
  },
});

export default revokePermissionsStyle;
