import { isBoolean, makeGlobalState } from 'mns-components';
import { useEffect } from 'react';
import { accountApi } from '../store/apis';
import { isCognitoUser, useAuthUser } from './useAuth';

const useEmailDeliveryRaw = makeGlobalState<boolean | undefined>();

export const useEmailDelivery = (appCode: string) => {
  const [emailDeliveryActive, setEmailDeliveryActive] = useEmailDeliveryRaw();
  const [authUser] = useAuthUser();

  useEffect(() => {
    if (!isBoolean(emailDeliveryActive) && isCognitoUser(authUser)) {
      accountApi
        .getDeliverySettings(appCode)
        .then(({ active }) => setEmailDeliveryActive(active))
        .catch(() => setEmailDeliveryActive(false));
    }
  }, [emailDeliveryActive, setEmailDeliveryActive, appCode, authUser]);

  return emailDeliveryActive;
};
