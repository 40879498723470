import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Backdrop } from 'mns-components';
import { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import type { RouteInterface as AuthRouteInterface } from './components/auth/routes';
import { routes as authRoutes } from './components/auth/routes';
// Router
import LoggedInRoute from './components/routing/LoggedInRoute';
import LoggedOutRoute from './components/routing/LoggedOutRoute';
// Views

import ErrorPage from './components/views/ErrorPage';
import Home from './components/views/Home';
import { routes as loggedInRoutes } from './components/views/routes';
import Unauthorized from './components/views/Unauthorized';
import { isCognitoUser, useAuth, useAuthUser } from './hooks/useAuth';
import { useAuthProvider } from './hooks/useAuthProvider';

// Todo Impement "Role" Route

const authRoutePaths = (Object.entries(authRoutes) as [keyof typeof authRoutes, AuthRouteInterface][]).reduce(
  (acc, [key, { path }]) => {
    acc[key] = path;
    return acc;
  },
  {} as AnyObject,
);

const authRouteComps = Object.values(authRoutes).filter((route) => route.component) as Required<AuthRouteInterface>[];

export const AppRouter: React.FC = () => {
  const { pushInstruction } = useMatomo();
  const [authUser] = useAuthUser();

  useEffect(() => {
    if (isCognitoUser(authUser)) {
      pushInstruction('setUserId', authUser.getUsername());
    }
  }, [authUser, pushInstruction]);

  const loading = !useAuthProvider();
  useAuth(authRoutePaths);

  if (loading) {
    return <Backdrop data-testid={'backdrop'} />;
  }

  return (
    <Suspense fallback={<Backdrop data-testid={'backdrop'} />}>
      <Switch>
        {loggedInRoutes.map(({ key, ...route }) => (
          <LoggedInRoute key={key} data-testid={key} {...route} />
        ))}
        {authRouteComps.map((route) => {
          if (route.shouldBeLoggedIn) {
            return (
              <LoggedInRoute
                key={route.key}
                data-testid={route.key}
                exact
                path={route.path}
                component={route.component}
              />
            );
          } else {
            return (
              <LoggedOutRoute
                key={route.key}
                data-testid={route.key}
                exact
                path={route.path}
                component={route.component}
              />
            );
          }
        })}
        {/** OTHERS */}
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/" component={Home} />
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  );
};
