import type { FormProps } from 'mns-components';
import {
  useTestid,
  Backdrop,
  makeForm,
  useAsyncLazy,
  Select as SelectInput,
  useCallbackImmutable,
} from 'mns-components';
import type { CollectApi, Quality } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { appCollectApi } from '../../../../../store/apis';
import { qualityLabelMapper } from '../../../../cellRender/QualitiesRenderer';
import { settingsStyle as useStyles } from '../settingsStyle';

const appName = 'data_collect';

const monthBeforeNavValues = Array.from({ length: 13 }, (_, i) => i).map((i) => ({
  label: `${i} month${i > 1 ? 's' : ''}`,
  value: `${i}`,
}));

const frequencyValues = ['MONTHLY', 'QUARTERLY'].map((i) => ({
  label: i,
  value: i,
}));

const lookThrowLevelValues = Array.from({ length: 8 }, (_, i) => i).map((i) => ({
  label: `Level ${i}`,
  value: `${i}`,
}));

const deliveryDeadlineValues = Array.from({ length: 16 }, (_, i) => i).map((i) => ({
  label: `${i} day${i > 1 ? 's' : ''}`,
  value: `${i}`,
}));

const usableQualities = ['BASIC', 'REGULATORY'] as Quality[];
const qualitiesValues = usableQualities.map((quality) => ({
  label: qualityLabelMapper[quality],
  value: quality,
}));

type AccessRequestSettingsInputsProps = Omit<
  FormProps<CollectApi.AppCollectSettings['dataAccessSettings']>,
  'children'
>;

const AccessRequestSettingsInputs: React.FC<AccessRequestSettingsInputsProps> = ({
  onSubmit,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const { watch, getValues } = useFormContext<CollectApi.AppCollectSettings['dataAccessSettings']>();

  useEffect(() => {
    if (onSubmit && watch) {
      const subscription = watch(() => {
        const data = getValues();

        // We currently use getValues() because all field in the watch function can be undefined
        onSubmit({
          frequency: data.frequency,
          monthBeforeNav: +data.monthBeforeNav,
          lookThroughLevel: +data.lookThroughLevel,
          deliveryDeadlineInDays: +data.deliveryDeadlineInDays,
          expectedPortfolioQualities: data.expectedPortfolioQualities,
        });
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, onSubmit, getValues]);

  return (
    <>
      <h2 className={classes.settingsCategory}>Access request</h2>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Start access date</div>
          <div className={classes.settingsDescription}>
            The access start date allows you to define the date from which you can start collecting a fund. If you want
            to collect the inventories of a fund over the last 3 months, you will have to define the date accordingly.
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Month before NAV date"
            name="monthBeforeNav"
            options={monthBeforeNavValues}
            data-testid={createTestid('select-before-nav')}
          />
        </div>
      </div>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Frequency</div>
          <div className={classes.settingsDescription}>
            Determines the frequency of collection. Among other things, the collection deadlines will depend on this
            parameter.
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Frequency"
            name="frequency"
            options={frequencyValues}
            data-testid={createTestid('select-frequency')}
          />
        </div>
      </div>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Look-through level</div>
          <div className={classes.settingsDescription}>
            <p>
              The look-through level allows you to define the level of transparency to be applied to your portfolio.
            </p>
            <p>
              Ex: if you collect a fund (level 0) which contains a fund (level 1) you can set the parameter to Level 1.
            </p>
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Look-through level"
            name="lookThroughLevel"
            options={lookThrowLevelValues}
            data-testid={createTestid('select-lookThroughLevel')}
          />
        </div>
      </div>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Deadline</div>
          <div className={classes.settingsDescription}>
            The deadline allows you to inform the asset manager of the time before which you wish to collect a fund. It
            is defined in number of working days after the end of the previous month.{' '}
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Business days after EOM"
            name="deliveryDeadlineInDays"
            options={deliveryDeadlineValues}
            data-testid={createTestid('select-deadline')}
          />
        </div>
      </div>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Expected portfolio qualities</div>
          <div className={classes.settingsDescription}>
            This parameter allows you to notify your data suppliers of the data qualities expected by your organization.
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Expected portfolio qualities"
            name="expectedPortfolioQualities"
            options={qualitiesValues}
            data-testid={createTestid('select-deadline')}
            multiple
          />
        </div>
      </div>
    </>
  );
};

const AccessRequestSettingsForm = makeForm<
  CollectApi.AppCollectSettings['dataAccessSettings'],
  AccessRequestSettingsInputsProps
>(AccessRequestSettingsInputs);

export const AccessRequestSettings: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const [formState, setFormState] = useState<CollectApi.AppCollectSettings | null>(null);
  const createTestid = useTestid(testid);

  const { execute: loadConfig, isExecuting: isLoading } = useAsyncLazy(async () => {
    const settings = await appCollectApi.appSettings[appName].get();
    setFormState(settings);
  });

  const { execute: updateConfig } = useAsyncLazy(async (settings: CollectApi.AppCollectSettings) => {
    appCollectApi.appSettings[appName].update(settings);
  });

  const onSubmitForm = useCallbackImmutable(
    (dataAccessSettings: CollectApi.AppCollectSettings['dataAccessSettings']) => {
      if (formState == null) return;

      const newSettings = { ...formState, dataAccessSettings };
      updateConfig(newSettings);
      setFormState(newSettings);
    },
  );

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  if (isLoading || !formState || !formState) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <AccessRequestSettingsForm
      data-testid={testid}
      defaultValues={formState.dataAccessSettings}
      onSubmit={onSubmitForm}
    />
  );
};
