import { Backdrop, Button, DatePicker, throttle, Select, useTestid, useCallbackImmutable } from 'mns-components';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { subtractMonths } from '../../common/date';
import { usePtfAndDate } from '../../hooks/useGlobalState';
import { useApplicationSettings } from './hooks';
import useStyles from './styles/emitterPermissionsFormStyles';
import type { EmitterPermissionFormData } from './types';

interface EmitterPermissionsFormProps {
  handleSave(formData: EmitterPermissionFormData): Promise<void>;
  handleCancel(): void;
  'data-testid': string;
}

const frequencyList = [
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
];

const lookThroughListOptions = Array(8)
  .fill(1)
  .map((x, gap) => ({
    label: `${gap} level${gap != 1 ? 's' : ''}`,
    value: `${gap}`,
  }));

const deliveryTimeListOptions = Array.from({ length: 16 }, (_, i) => i)
  .concat(Array.from(Array(8).keys(), (x, i) => (i + 2) * 10))
  .map((x) => ({
    label: x === 1 ? `${x} business day` : `${x} business days`,
    value: `${x}`,
  }))
  .slice(1);

export const EmitterPermissionsRequestAccessForm: React.FC<EmitterPermissionsFormProps> = ({
  handleSave,
  handleCancel,
  'data-testid': testid,
}: EmitterPermissionsFormProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const [[, valuationDate]] = usePtfAndDate();
  const [selectedDate, setSelectedDate] = useState<Date>(valuationDate ? new Date(valuationDate) : new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<EmitterPermissionFormData>({
    mode: 'onBlur',
    defaultValues: {
      sharingFrequency: 'MONTHLY',
      lookThroughLevel: '0',
      acceptedDelayDayCount: '1',
    },
  });

  const { isLoading } = useApplicationSettings({
    onSuccess: ({ dataAccessSettings }) => {
      setSelectedDate(subtractMonths(selectedDate, dataAccessSettings.monthBeforeNav));
      methods.setValue('sharingFrequency', dataAccessSettings.frequency);
      methods.setValue('lookThroughLevel', dataAccessSettings.lookThroughLevel.toString());
      methods.setValue('acceptedDelayDayCount', dataAccessSettings.deliveryDeadlineInDays.toString());
    },
  });

  const onSave = useCallbackImmutable(
    throttle(async (data: EmitterPermissionFormData) => {
      setIsSubmitting(true);
      await handleSave({ ...data, expectedAsOf: selectedDate });
      setIsSubmitting(false);
    }, 1000),
  );

  const { handleSubmit } = methods;
  const datepickerChange = useCallbackImmutable((date) => setSelectedDate(date ?? new Date()));

  if (isLoading) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} data-testid={testid}>
        <h3 className={classes.subTitles}>Request details</h3>
        <div className={classes.formcontainer}>
          <DatePicker
            label="Access start date"
            value={selectedDate}
            onFieldChange={datepickerChange}
            data-testid={createTestid('datepicker-expectedAsOf')}
          />
          <Select
            variant="normal"
            name="sharingFrequency"
            label="Frequency"
            options={frequencyList}
            data-testid={createTestid('select-sharingFrequency')}
          />
          <Select
            variant="normal"
            name="lookThroughLevel"
            label="Look-through levels"
            options={lookThroughListOptions}
            data-testid={createTestid('select-lookThroughLevel')}
          />
          <Select
            variant="normal"
            name="acceptedDelayDayCount"
            label="Delivery Time"
            options={deliveryTimeListOptions}
            data-testid={createTestid('select-acceptedDelayDayCount')}
          />
        </div>
        <div className={classes.formFooter}>
          <hr />
          <Button color="primary" outlined onClick={handleCancel} data-testid={createTestid('button-cancel')}>
            Cancel
          </Button>
          <Button color="primary" type="submit" loading={isSubmitting} data-testid={createTestid('button-submit')}>
            Confirm access request
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
