import type {
  FormStepErrors,
  FormStepProps,
  FormStepValidator,
  FormStepperStepProps,
  ListGroupType,
  ListItemType,
  OptionType,
} from 'mns-components';
import {
  Backdrop,
  Link,
  Select,
  TextField,
  filterTruthy,
  makeForm,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { getSettingsRoute } from '../../../components/settings/routes';
import { emailListApi } from '../../../store/apis';
import { useEmailListsData } from '../../settings/organisation/hooks';
import { createEmailDeliveryModalStyles as useStyles } from './createEmailDeliveryModalStyles';
import { SelectRecipientsList } from './SelectRecipientsList';
import type { CreateEmailDeliverySteps } from './types';

type SelectRecipientsStepProps = FormStepProps<CreateEmailDeliverySteps[1]>;

const SelectRecipientsStep: React.FC<SelectRecipientsStepProps> = ({
  setStepValue,
  stepValue: { emails },
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { data: emailLists, isLoading: isLoadingEmailLists } = useEmailListsData();
  const [emailListNamesSelected, setEmailListNamesSelected] = useState<string[]>(emails ?? []);
  const [emailsSelected, setEmailsSelected] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [emailListDetails, setEmailListDetails] = useState<Record<string, null | EmailListApi.EmailList>>({});

  const handleEmailListNamesSelected = useImmutable(() => (value: string[]) => setEmailListNamesSelected(value));

  const handleRenderSelect = useCallbackImmutable((list: string[]) =>
    filterTruthy(emailLists ? list.map((listId) => emailLists.find(({ id }) => listId === id)?.title) : []).join(', '),
  );

  const emailListNameOptions = useMemo(
    () => emailLists?.map((list): OptionType => ({ label: list.title, value: list.id })),
    [emailLists],
  );

  // when a new emailList has been selected, fetch emailListDetails which contains recipients (email) and cache it
  useEffect(() => {
    emailListNamesSelected.forEach(async (id) => {
      if (!(id in emailListDetails)) {
        const emailListDetail = await emailListApi.getEmailList(id);
        setEmailListDetails((current) => ({ ...current, [id]: emailListDetail }));
        setEmailsSelected((current) => Array.from(new Set([...current, ...emailListDetail.recipients])));
      }
    });
  }, [emailListDetails, emailListNamesSelected]);

  // when emailList selection changed, available options changes, applying filters
  const emailListDetailOptions = useMemo(
    () =>
      emailListNamesSelected.reduce((acc, emailListId) => {
        const found = emailLists?.find(({ id }) => id === emailListId);
        const emailComposition = emailListDetails[emailListId]?.recipients ?? [];
        const emailFiltered = search ? emailComposition.filter((email) => email.includes(search)) : emailComposition;
        if (found && (!search || emailFiltered.length)) {
          acc.push({
            items: emailFiltered.map(
              (email): ListItemType => ({
                label: email,
                value: email,
              }),
            ),
            label: found.title,
            value: found.id,
          });
        }
        return acc;
      }, [] as ListGroupType[]),
    [emailListDetails, emailListNamesSelected, emailLists, search],
  );

  // when available email selection changes (ie: when an emailList has been removed), remove unavailable emails from selection
  useEffect(
    () =>
      setEmailsSelected((current) => {
        const all = emailListDetailOptions.reduce((acc, group) => {
          acc.push(...group.items.map((item) => item.value));
          return acc;
        }, [] as string[]);
        const filtered = current.filter((email) => all.includes(email));
        return current.length !== filtered.length ? filtered : current;
      }),
    [emailListDetailOptions],
  );

  // when email selection change, apply on formStepper
  useEffect(() => setStepValue({ emails: emailsSelected }), [setStepValue, emailsSelected]);

  if (isLoadingEmailLists) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <>
      <Select
        name="email-lists"
        label="Email Lists"
        value={emailListNamesSelected}
        onFieldChange={handleEmailListNamesSelected}
        options={emailListNameOptions}
        renderValue={handleRenderSelect}
        variant="normal"
        multiple
        data-testid={createTestid('select-emailLists')}
      />

      {!!emailListDetailOptions.length && (
        <>
          <TextField
            name="search"
            label="Search"
            type="text"
            value={search}
            onFieldChange={setSearch}
            search={true}
            className={classes.searchInput}
            data-testid={createTestid('searchInput')}
          />
          <SelectRecipientsList
            emailListOptions={emailListDetailOptions}
            emailList={emailsSelected}
            setEmailList={setEmailsSelected}
            data-testid={createTestid('recipientsList')}
          />
          <p className={classes.countText}>{emailsSelected.length} selected</p>
        </>
      )}

      <p className={classes.addRecipientText}>
        Manage email lists from the{' '}
        <Link.Underlined
          to={getSettingsRoute('settings-organisation').link}
          data-testid={createTestid('link-settings')}
        >
          organisation settings
        </Link.Underlined>
        .
      </p>
    </>
  );
};

const validateSelectRecipientsStep: FormStepValidator<CreateEmailDeliverySteps[1]> = (data) => {
  const errors: FormStepErrors<CreateEmailDeliverySteps[1]> = [];

  if (!Array.isArray(data.emails) || !data.emails.length) {
    errors.push({
      name: 'emails',
      message: 'Please select at least one recipient',
    });
  }

  return errors;
};

export const buildSelectRecipientsStep = (): FormStepperStepProps<CreateEmailDeliverySteps[1]> => ({
  build: makeForm(SelectRecipientsStep),
  title: 'Select recipients',
  onValidate: validateSelectRecipientsStep,
});
