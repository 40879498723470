import type { OptionType } from 'mns-components';
import { RadioGroup, makeForm } from 'mns-components';
import { useEffect, useMemo } from 'react';
import { priceWithCurrency } from '../../../../common/utils';
import { formStyles as useStyles } from './formStyles';

type ReplyOfferPickerForm = { offerId: string };

type OfferPickerInputsProps = {
  applicationPricings: AnyArray;
  offerId: string | undefined;
  onOfferChange?(offerId: string): void;
  'data-testid': string;
};

const OfferPickerInputs: React.FC<OfferPickerInputsProps> = ({
  applicationPricings,
  offerId,
  onOfferChange,
  'data-testid': testid,
}) => {
  const classes = useStyles();

  const options = useMemo(
    () =>
      applicationPricings.map<OptionType>((pricing) => ({
        label: pricing.offerName,
        value: pricing.offerId,
        price: priceWithCurrency(pricing.pricingDto.price, pricing.pricingDto.currency),
        descriptions: pricing.features,
      })),
    [applicationPricings],
  );

  useEffect(() => onOfferChange?.(options[0].value), [onOfferChange, options]);

  return (
    <div className={classes.form}>
      <h3 className={classes.formTitle}>
        <span> Choose your report type</span>
        <hr />
      </h3>
      <div className={classes.radioBox}>
        <RadioGroup.Uncontrolled
          name="offerId"
          value={offerId}
          options={options}
          onFieldChange={onOfferChange}
          data-testid={testid}
        />
      </div>
    </div>
  );
};

export const OfferPickerForm = makeForm<ReplyOfferPickerForm, OfferPickerInputsProps>(OfferPickerInputs);
