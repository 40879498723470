import { makeStyles, colors } from 'mns-components';

export const pdfReaderStyle = makeStyles({
  pdfScroll: {
    height: '50rem',
    overflow: 'auto',
    textAlign: 'center',
    borderRadius: '5px 0 0 5px',
    background: colors.whiteColor,
    marginBottom: '4rem',
  },
  loadingContainer: {
    userSelect: 'none',
    margin: '5rem auto 0 auto',
    '& div.MuiBackdrop-root': {
      zIndex: '999 !important',
      position: 'relative',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: '1rem',
    '& > :not(:first-child)': {
      marginLeft: '1rem',
    },
  },
});
