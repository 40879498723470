import { Fade, Modal } from '@mui/material';
import { Button, joinClass, useTestid } from 'mns-components';
import React from 'react';
import { getAuthRoute } from '../../../components/auth/routes';
import { marketplaceTCsModalStyles as useStyles } from './styles/marketplaceTCsModalStyles';

type ModalColor = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';

const mapColorClasses: Record<ModalColor, keyof ReturnType<typeof useStyles>> = {
  primary: 'titlePrimary',
  secondary: 'titleSecondary',
  success: 'titleSuccess',
  info: 'titleInfo',
  warning: 'titleWarning',
  danger: 'titleDanger',
};

interface MarketplaceTCsInfoModalProps {
  open: boolean;
  color?: ModalColor;
  'data-testid': string;
}

export const MarketplaceTCsInfoModal: React.FC<MarketplaceTCsInfoModalProps> = ({
  open,
  color = 'info',
  'data-testid': testid,
}: MarketplaceTCsInfoModalProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      className={classes.modal}
    >
      <Fade in={open}>
        <div className={classes.container} data-testid={testid}>
          <h2 className={joinClass(classes.title, classes[mapColorClasses[color]])}>MANAOS - Terms and Conditions</h2>
          <div className={classes.paragraphs}>
            <p>Your organisation have not accepted the terms and conditions of our platform.</p>
            <p>Please contact your administrator to solve this problem.</p>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              href={getAuthRoute('logout').path}
              size="medium"
              color="primary"
              outlined
              data-testid={createTestid('button-logout')}
            >
              Logout
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
