import { colors, makeStyles } from 'mns-components';

export const headerRendererStyles = makeStyles({
  infoIcon: {
    color: colors.infoColor,
  },
  spanStyle: {
    fontSize: '14px',
    color: 'rgba(17, 17, 60, 0.6)',
  },
  arrow: {
    color: 'white',
  },
  tooltip: {
    cursor: 'pointer',
    backgroundColor: colors.whiteColor,
    color: colors.defaultFontColor,
  },
  numberHeaderComponent: {
    display: 'block',
  },
  requiredBlock: {
    position: 'absolute',
    left: '10px',
    color: 'red',
    fontSize: '15px',
  },
});
