import { Backdrop, useAsync, Select as SelectInput, makeForm, useTestid, useCallbackImmutable } from 'mns-components';
import type { OptionType } from 'mns-components';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { appCollectApi } from '../../../../../store/apis';
import { settingsStyle as useStyles } from '../settingsStyle';

const plural = (num: number) => (num === -1 || num === 1 ? '' : 's');

const valuationGapListMin = Array.from(
  { length: 21 },
  (_, gap): OptionType => ({
    label: `- ${gap} Business day${plural(gap)}`,
    value: `${gap}`,
  }),
);

const valuationGapListMax = Array.from(
  { length: 21 },
  (_, gap): OptionType => ({
    label: `+ ${gap} Business day${plural(gap)}`,
    value: `${gap}`,
  }),
);

const proxyGapList = Array.from({ length: 7 }, (_, gap): OptionType => {
  if (gap === 0) {
    return {
      label: 'None',
      value: `${gap}`,
    };
  }
  if (gap === 1) {
    return {
      label: `+ ${gap} Month late`,
      value: `${gap}`,
    };
  }
  return {
    label: `+ ${gap} Months late`,
    value: `${gap}`,
  };
});

export interface SettingFormData {
  valuationGapMin: number;
  valuationGapMax: number;
  fundProxyGap: number;
}

export interface SettingFormDataInput {
  valuationGapMin: string;
  valuationGapMax: string;
  fundProxyGap: string;
}

interface CollectGapSettingsInputProps {
  handleSave?(formData: SettingFormData): void;
  'data-testid': string;
}

const CollectGapSettingsInput: React.FC<CollectGapSettingsInputProps> = ({
  handleSave,
  'data-testid': testid,
}: CollectGapSettingsInputProps) => {
  const createTestid = useTestid(testid);
  const methods = useFormContext<SettingFormDataInput>();
  const { watch, getValues } = methods;
  const classes = useStyles();

  useEffect(() => {
    if (watch && handleSave) {
      const subscr = watch(() => {
        const data = getValues();

        handleSave({
          fundProxyGap: +data.fundProxyGap,
          valuationGapMax: +data.valuationGapMax,
          valuationGapMin: +data.valuationGapMin,
        });
      });
      return () => subscr.unsubscribe();
    }
  }, [getValues, handleSave, watch]);

  // Form
  return (
    <>
      <h2 className={classes.settingsCategory}>Collect</h2>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Valuation date tolerance</div>
          <div className={classes.settingsDescription}>
            <p>
              This parameter allows you to collect inventories that are not necessarily dated on the last day of the
              month.
            </p>
            <p>
              By setting this parameter to + and - 3 days, inventories dating from December 28 to January 3 will be
              collected for December. If the valuation date is out of the date range, it will not be collected.
            </p>
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Valuation date gap min"
            name="valuationGapMin"
            options={valuationGapListMin}
            data-testid={createTestid('input-valuationGapMin')}
          />
          <SelectInput
            label="Valuation date gap max"
            name="valuationGapMax"
            options={valuationGapListMax}
            data-testid={createTestid('input-valuationGapMax')}
          />
        </div>
      </div>

      <div className={classes.settingsField}>
        <div className={classes.settingsLabel}>
          <div className={classes.settingsName}>Look-through period</div>
          <div className={classes.settingsDescription}>
            <p>
              This parameter allows you to define that you want to retrieve the lookthrough data of the funds for the
              last X months.
            </p>
            <p>
              Ex: set to 3 months the resulting transparency file will contain the transparency data of the last 3
              months.
            </p>
          </div>
        </div>

        <div className={classes.settingsInput}>
          <SelectInput
            label="Fund proxy gap"
            name="fundProxyGap"
            options={proxyGapList}
            data-testid={createTestid('input-fundProxyGap')}
          />
        </div>
      </div>
    </>
  );
};

const CollectGapSettingsForm = makeForm<SettingFormData, CollectGapSettingsInputProps>(CollectGapSettingsInput);

interface CollectGapSettingsProps {
  'data-testid': string;
}

export const CollectGapSettings: React.FC<CollectGapSettingsProps> = ({ 'data-testid': testid }) => {
  const [loadSettings, isLoadingSettings, settings] = useAsync(appCollectApi.appSettings.data_collect.get);
  const createTestid = useTestid(testid);

  useEffect(() => {
    if (!isLoadingSettings && !settings) {
      loadSettings();
    }
  }, [isLoadingSettings, loadSettings, settings]);

  // Form handler
  const saveSettingFormHandler = useCallbackImmutable(async (formData: SettingFormData) => {
    if (settings === undefined) return;

    await appCollectApi.appSettings.data_collect.update({ ...settings, ...formData });
  });

  if (!settings) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return <CollectGapSettingsForm handleSave={saveSettingFormHandler} defaultValues={settings} data-testid={testid} />;
};
