import type { DropdownProps, SelectFilterItem } from 'mns-components';
import {
  Button,
  Dropdown,
  Icon,
  SearchField,
  SelectFilter,
  makeForm,
  theme,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { qualities } from 'mns-sdk-collect';
import { qualityLabelMapper } from '../../../components/cellRender/QualitiesRenderer';
import { InventoriesControllerStyles as useStyles } from './styles/InventoriesController.styles';

const mergerAuthStatusMapping = [
  {
    label: 'Not authorized',
    color: theme.colors.danger,
    filter: (row: CollectApi.FundPositionToCollect, orgId: string): boolean =>
      orgId !== row.disseminatorPortfolio?.organisationId && row.accessRequest?.status !== 'ACCEPTED',
  },
  {
    label: 'Not collected',
    color: theme.colors.warning,
    filter: (row: CollectApi.FundPositionToCollect, orgId: string): boolean =>
      orgId !== row.disseminatorPortfolio?.organisationId &&
      row.disseminatorPortfolio?.disseminationStatus !== 'DISSEMINATED' &&
      row.accessRequest?.status === 'ACCEPTED',
  },
  {
    label: 'Collected',
    color: theme.colors.success,
    filter: (row: CollectApi.FundPositionToCollect): boolean =>
      row.disseminatorPortfolio?.disseminationStatus === 'DISSEMINATED',
  },
] as const;

type MergerLabel = typeof mergerAuthStatusMapping[number]['label'];

const doesFundMatchStatus = (item: CollectApi.FundPositionToCollect, orgId: string, status: MergerLabel) => {
  const mergers = mergerAuthStatusMapping.filter((mrg) => mrg.filter(item, orgId));
  return mergers.some((mrg) => mrg.label === status);
};

const mergeAuthStatus = (
  rowsData: CollectApi.FundPositionToCollect[],
  orgId: string,
): Record<MergerLabel, CollectApi.FundPositionToCollect[]> =>
  rowsData.reduce((acc, item) => {
    const mergers = mergerAuthStatusMapping.filter((mrg) => mrg.filter(item, orgId));
    for (const merger of mergers) {
      const { label } = merger;
      if (!(label in acc)) {
        acc[label] = [];
      }
      acc[label].push(item);
    }

    return acc;
  }, {} as Record<MergerLabel, CollectApi.FundPositionToCollect[]>);

export const getStatusesOptions = (rowData: CollectApi.FundPositionToCollect[] | undefined, orgId: string) => {
  const mergedStatuses = mergeAuthStatus(rowData ?? [], orgId);
  return (Object.keys(mergedStatuses) as MergerLabel[]).map(
    (statusName): SelectFilterItem<'unknown' | 'all' | MergerLabel> => ({
      value: statusName,
      label: statusName,
      color: mergerAuthStatusMapping.find((status) => status.label === statusName)?.color,
      count: mergedStatuses[statusName].length,
    }),
  );
};

export const getProviderOptions = (rowData: CollectApi.FundPositionToCollect[] = []) =>
  Object.values(
    rowData.reduce((acc, data) => {
      const name = data.disseminatorPortfolio?.organisationName;
      const id = data.disseminatorPortfolio?.organisationId;

      if (id && name) {
        if (acc[id] === undefined) {
          acc[id] = {
            value: id,
            label: name,
            count: 0,
          };
        }
        acc[id].count += 1;
      } else {
        if (acc.unknown === undefined) {
          acc.unknown = {
            value: 'unknown',
            label: 'Unknown',
            count: 0,
          };
        }
        acc.unknown.count += 1;
      }
      return acc;
    }, {} as Record<string, SelectFilterItem<string>>),
  ).sort((a, b) => a.label.localeCompare(b.label));

export const getAssetManagersOptions = (rowData: CollectApi.FundPositionToCollect[] = []) =>
  Object.values(
    rowData.reduce((acc, { fundPosition: { issuerName: name } }) => {
      if (name) {
        if (acc[name] === undefined) {
          acc[name] = {
            value: name,
            label: name,
            count: 0,
          };
        }
        acc[name].count += 1;
      } else {
        if (acc.unknown === undefined) {
          acc.unknown = {
            value: 'unknown',
            label: 'Unknown',
            count: 0,
          };
        }
        acc.unknown.count += 1;
      }
      return acc;
    }, {} as Record<string, SelectFilterItem<string>>),
  ).sort((a, b) => a.label.localeCompare(b.label));

export const getQualityOptions = (rowData: CollectApi.FundPositionToCollect[] = []) => {
  const qualityOptions = qualities.reduce((acc, quality) => {
    acc[quality] = {
      value: quality,
      label: qualityLabelMapper[quality],
      count: 0,
    };
    return acc;
  }, {} as Record<string, SelectFilterItem<CollectApi.Quality>>);

  return Object.values(
    rowData.reduce((acc, data) => {
      const qualitiesName = data.disseminatorPortfolio?.ingestionInfos?.qualities;
      if (qualitiesName?.length) {
        qualitiesName.forEach((name) => {
          acc[name].count += 1;
        });
      }
      return acc;
    }, qualityOptions),
  );
};

export const filterByStatusAndProviderAndAssetManager = (
  rowData: CollectApi.FundPositionToCollect[] | undefined,
  orgId: string,
  status: string,
  provider: string,
  assetManager: string,
  quality: 'all' | CollectApi.Quality,
) => {
  const rows = rowData ?? [];
  const statusRows =
    status !== 'all' ? rows.filter((item) => doesFundMatchStatus(item, orgId, status as MergerLabel)) : rows;
  const providerRows =
    provider !== 'all'
      ? statusRows.filter((item) => (item.disseminatorPortfolio?.organisationId ?? 'unknown') === provider)
      : statusRows;
  const assetRows =
    assetManager !== 'all'
      ? providerRows.filter((item) => (item.fundPosition.issuerName ?? 'unknown') === assetManager)
      : providerRows;
  const qualityRows =
    quality !== 'all'
      ? assetRows.filter((item) => {
          const itemQualities = item.disseminatorPortfolio?.ingestionInfos?.qualities ?? [];
          return itemQualities.includes(quality);
        })
      : assetRows;
  return qualityRows;
};

export const downloadOptions: DropdownProps<CollectApi.ShareclassFileType>['options'] = [
  { children: 'Input File', value: 'input' },
  { children: 'Shared Error Log', value: 'shared_error_log' },
  { children: 'Processed File', value: 'output' },
];

export type SelectedStatus = CollectApi.FundPositionDetail['authorisationStatus'] | 'DOWNLOAD';

type InventoriesControllerProps = {
  selectedStatus?: SelectedStatus[];
  searchValue: string;
  setSearchValue(searchValue: string): void;
  statuses: SelectFilterItem<MergerLabel | 'all' | 'unknown'>[];
  status: string;
  setStatus(status: string): void;
  assetManagers: SelectFilterItem<string>[];
  assetManager: string;
  setAssetManager(assetManager: string): void;
  providers: SelectFilterItem<string>[];
  provider: string;
  setProvider(provider: string): void;
  quality: CollectApi.Quality | 'all';
  qualityOptions: SelectFilterItem<'all' | CollectApi.Quality>[];
  setQuality(quality: CollectApi.Quality | 'all'): void;
  countAll: number;
  onExport?(): void;
  onDisplay(view: 'chaseUp' | 'request' | 'invite'): void;
  onReset?(): void;
  onDownload(dlType: CollectApi.ShareclassFileType): void;
  'data-testid': string;
};

const InventoriesControllerInputs: React.FC<InventoriesControllerProps> = ({
  selectedStatus,
  searchValue,
  setSearchValue,
  statuses,
  status,
  setStatus,
  providers,
  provider,
  setProvider,
  assetManagers,
  assetManager,
  setAssetManager,
  quality,
  qualityOptions,
  setQuality,
  countAll,
  onExport,
  onDisplay,
  onReset,
  onDownload,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const color = selectedStatus?.length ? 'primary' : 'disabled';

  return (
    <div className={classes.controller}>
      <div className={classes.flexLine}>
        <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} />
        <div className={classes.flexRight}>
          <Dropdown
            options={downloadOptions}
            onClick={onDownload}
            disabled={!selectedStatus?.includes('DOWNLOAD')}
            outlined
            size="small"
            color={color}
            title="Download selected items"
            data-testid={createTestid('dropdown-download')}
          >
            Download
          </Dropdown>
          <Button
            color="primary"
            disabled={!selectedStatus?.includes('ACCEPTED')}
            outlined
            size="small"
            startIcon={<Icon.Chaseup data-testid={createTestid('icon-chaseUp')} />}
            onClick={useCallbackImmutable(() => onDisplay('chaseUp'))}
            data-testid={createTestid('button-chaseup')}
          >
            Chase up
          </Button>
          <Button
            color="primary"
            disabled={!selectedStatus?.includes('EXIST')}
            outlined
            size="small"
            startIcon={<Icon.Accessopen data-testid={createTestid('icon-request')} />}
            onClick={useCallbackImmutable(() => onDisplay('request'))}
            data-testid={createTestid('button-request')}
          >
            Request access
          </Button>
          <Button
            color="primary"
            disabled={!selectedStatus?.includes('NO_EXISTING')}
            outlined
            size="small"
            startIcon={
              <Icon.Invite
                size="medium"
                color={selectedStatus?.includes('NO_EXISTING') ? 'secondary' : 'disabled'}
                data-testid={createTestid('icon-invite')}
              />
            }
            onClick={useCallbackImmutable(() => onDisplay('invite'))}
            data-testid={createTestid('button-invite')}
          >
            Invite
          </Button>
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-export')} />}
            color="primary"
            outlined
            onClick={onExport}
            size="small"
            data-testid={createTestid('button-export')}
          >
            Export table
          </Button>
        </div>
      </div>
      <div className={classes.flexRight}>
        <SelectFilter
          label="Status"
          items={statuses}
          onClick={setStatus}
          activeItem={status}
          countAll={countAll}
          data-testid={createTestid('select-status')}
        />
        <SelectFilter
          label="Provided by"
          dropdownItems={providers}
          onClick={setProvider}
          activeItem={provider}
          countAll={countAll}
          data-testid={createTestid('select-provider')}
        />
        <SelectFilter
          label="Asset Manager"
          dropdownItems={assetManagers}
          onClick={setAssetManager}
          activeItem={assetManager}
          countAll={countAll}
          data-testid={createTestid('select-assetManager')}
        />
        <SelectFilter
          activeItem={quality}
          label="Quality"
          onClick={setQuality}
          dropdownItems={qualityOptions}
          countAll={countAll}
          data-testid={createTestid('select-qualities')}
        />
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Filter data-testid={createTestid('icon-resetFilters')} />}
          onClick={onReset}
          data-testid={createTestid('button-resetFilters')}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
};

export const InventoriesController = makeForm(InventoriesControllerInputs);
