import { IconButton, Menu } from '@mui/material';
import { Icon, MenuItem, makeStyles, useImmutable, useTestid } from 'mns-components';
import { useState } from 'react';

export const useStyles = makeStyles({
  button: {
    minWidth: 0,
    height: '2rem',
    width: '2rem',
    marginRight: '.5rem',
    position: 'relative',
  },
  bulkTitle: {
    width: '80%',
    margin: '0px auto',
    color: 'gray',
    borderBottom: '1px solid gray',
  },
});

type ExtractionGeneratedHistoryBulkProps = {
  disabled?: boolean;
  onDownloadLogs(): void;
  'data-testid': string;
};

export const ExtractionGeneratedHistoryBulk: React.FC<ExtractionGeneratedHistoryBulkProps> = ({
  disabled,
  onDownloadLogs,
  'data-testid': testid,
}: ExtractionGeneratedHistoryBulkProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        className={classes.button}
        onClick={useImmutable(
          () =>
            ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              setAnchorEl(currentTarget),
        )}
        disabled={disabled}
        color="secondary"
        data-testid={createTestid('button-more')}
      >
        <Icon.More data-testid={createTestid('icon-more')} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={useImmutable(() => () => setAnchorEl(null))}>
        <MenuItem disabled divider data-testid={createTestid('section-title')}>
          Download
        </MenuItem>
        <MenuItem onClick={onDownloadLogs} data-testid={createTestid('button-downloadLogs')}>
          Execution Log
        </MenuItem>
      </Menu>
    </>
  );
};
