import { Typo, colors, isFiniteNumber } from 'mns-components';

export const greatScoreRules = [
  {
    limit: 5,
    color: '#2E7D32',
  },
  {
    limit: 7.25,
    color: '#ED6C02',
  },
  {
    limit: 10,
    color: '#D32F2F',
  },
];

export function getColorByValue(
  value: number | string | undefined,
  rules: Array<{ limit: number; color: string }>,
  defaultRule = { limit: 0, color: '' },
) {
  const checkedValue = typeof value === 'string' ? parseFloat(value) : value;

  if (!isFiniteNumber(checkedValue)) return defaultRule.color;

  return rules.reduce((acc, rule) => {
    if (checkedValue > acc.limit && checkedValue <= rule.limit) return rule;

    return acc;
  }, defaultRule).color;
}

interface GreatScoringRendererProps {
  value?: number;
}

export const GreatScoringRenderer: React.FC<GreatScoringRendererProps> = ({ value }: GreatScoringRendererProps) => (
  <Typo
    component="span"
    variant="body2medium"
    color={getColorByValue(value, greatScoreRules, {
      color: colors.darkGreyColor,
      limit: 0,
    })}
  >
    {value ? `${value.toFixed(2)}` : null}
  </Typo>
);
