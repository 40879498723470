import { colors, makeStyles } from 'mns-components';

export const extractorPreferencesStyles = makeStyles({
  controllerSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 1rem',
  },
  controllerButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > :not(:first-of-type)': {
      marginLeft: '.5rem',
    },
    '& > span': {
      fontSize: '.75rem',
      width: '4rem',
    },
  },
  toggleStatus: {
    '&&': {
      width: '3.5rem',
    },
  },
  unavailable: {
    backgroundColor: `${colors.lightGreyColor} !important`,
  },
});
