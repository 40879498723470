import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Backdrop,
  Dialog,
  convertToDateLocal,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { dataDeliveryApi } from '../../../store/apis';
import { QualitiesRenderer } from '../../cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../headerRender/QualitiesHeaderRenderer';
import { deliveryDetailsDialogStyles as useStyles } from './deliveryDetailsDialogStyles';

const plural = (value: number) => (value > 1 ? 's' : '');

const useEmailColumns = (): AggridTableColumn<DataDeliveryApi.DataDeliveryEmail>[] =>
  useMemo(
    () => [
      {
        headerLabel: 'Email',
        field: 'recipient',
      },
    ],
    [],
  );

const usePortfolioColumns = (testid: string) => {
  const createTestid = useTestid(testid);
  return useMemo(
    (): AggridTableColumn<DataDeliveryApi.Scope>[] => [
      {
        headerName: 'Id',
        field: 'identification',
      },
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Qualities',
        field: 'qualities',
        cellRendererFramework: ({ value, data: { identification } }) => (
          <QualitiesRenderer qualities={value ?? []} data-testid={createTestid(`${identification}-qualities`)} />
        ),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={createTestid('qualities')} />,
        minWidth: 100,
      },
      {
        headerName: 'NAV date',
        field: 'productionDate',
      },
    ],
    [createTestid],
  );
};

interface DeliveryDetailsProps {
  delivery: DataDeliveryApi.DataDelivery;
  'data-testid': string;
}

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({ delivery, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainLabel}>{delivery.info.title}</div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created on:</span>
        <span className={classes.itemValue}>{convertToDateLocal(delivery.info.createdAt)}</span>
      </div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created by:</span>
        <span className={classes.itemValue}>{delivery.info.createdBy}</span>
      </div>

      <div className={classes.mainLabel}>
        {delivery.scopes.length} file{plural(delivery.scopes.length)}
      </div>
      <div>
        <AggridTable
          rowData={delivery.scopes}
          onGridReady={useAutoSize('fit')}
          getRowNodeId={useImmutable(() => (row: DataDeliveryApi.Scope) => row.identification)}
          columnDefs={usePortfolioColumns(testid)}
          defaultColDef={useDefaultColDef<DataDeliveryApi.Scope>()}
          data-testid={createTestid('scopes')}
        />
      </div>
      <div className={classes.mainLabel}>
        {delivery.emails.length} recipient{plural(delivery.emails.length)}
      </div>

      <div>
        <AggridTable
          rowData={delivery.emails}
          onGridReady={useAutoSize('fit')}
          getRowNodeId={useImmutable(() => (row: DataDeliveryApi.DataDeliveryEmail) => row.recipient)}
          columnDefs={useEmailColumns()}
          defaultColDef={useDefaultColDef<DataDeliveryApi.DataDeliveryEmail>()}
          data-testid={createTestid('emails')}
        />
      </div>
    </>
  );
};

export type DeliveryDetailsModalProps = {
  deliveryId: string;
  onClose(): void;
  'data-testid': string;
};

export const DeliveryDetailsModal: React.FC<DeliveryDetailsModalProps> = ({
  deliveryId,
  onClose,
  'data-testid': testid,
}) => {
  const { data: deliveryDetail } = useQuery(
    ['dataDeliveryApi.getDelivery', deliveryId],
    () => {
      if (deliveryId) return dataDeliveryApi.getDelivery(deliveryId);
    },
    { enabled: !!deliveryId },
  );

  return (
    <Dialog dialogTitle="Delivery details" open={!!deliveryId} onClose={onClose} data-testid={`${testid}-dialog`}>
      {deliveryDetail ? (
        <DeliveryDetails delivery={deliveryDetail} data-testid={testid} />
      ) : (
        <Backdrop data-testid={`${testid}-backdrop`} />
      )}
    </Dialog>
  );
};
