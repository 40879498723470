import { Stack } from '@mui/material';
import type { GridApi, GridReadyEvent } from 'ag-grid-community';
import JSZip from 'jszip';
import type { SelectFilterItem } from 'mns-components';
import {
  useAutoSize,
  Icon,
  Button,
  AppMargin,
  useCallbackImmutable,
  PeriodPicker,
  EmptyState,
  SearchField,
  SelectFilter,
  useTestid,
} from 'mns-components';
import type { AnalysisApi, ProviderApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { now } from '../../../../common/date';
import { getApplicationConfig } from '../../../../common/getApplicationConfig';
import { defaultDownloadTransform, formatAnalysisFileName, processPromisesBatch } from '../../../../common/utils';
import type { ProviderName } from '../../../../components/views/appDescriptions';
import { isAppCode } from '../../../../components/views/appDescriptions';
import { useUser } from '../../../../hooks/useAuth';
import { api } from '../../../../store/api';
import { useUnderlyingDataForProvider } from '../hooks';
import { ProviderAnalysisGrid } from './ProviderAnalysisGrid';

export type ProviderAnalysisTableProps = {
  provider: ProviderName;
  'data-testid': string;
};

export const trimString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength - 3)}...`;
  }
  return str;
};

export const getStatusOptions = (analysis: ProviderApi.UnderlyingDataInfo[]): SelectFilterItem<string>[] => {
  const statusSet = new Set(analysis.map((item) => item.status));
  return Array.from(statusSet).map((status) => ({
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
    count: analysis.filter((item) => item.status === status).length,
  }));
};

export const getAppsOptions = (analysis: ProviderApi.UnderlyingDataInfo[]): SelectFilterItem<string>[] => {
  const appsSet = new Set(analysis.map((item) => item.applicationCode));
  return Array.from(appsSet)
    .filter((app) => isAppCode(app))
    .map((app) => ({
      value: app,
      label: trimString(getApplicationConfig(app).content.title, 25),
      count: analysis.filter((item) => item.applicationCode === app).length,
    }));
};

const timeWindow = 30 * 24 * 60 * 60 * 1000 * 6;

export const hasSubscribedToApp = (apps: string[]) => (appCode: string) => {
  return apps.some((app) => app === 'APP_' + appCode.toUpperCase());
};

export const isAnalysisEligibleForRelaunch = (apps: string[]) => (analysis: ProviderApi.UnderlyingDataInfo) =>
  analysis.status !== 'pending' && hasSubscribedToApp(apps)(analysis.applicationCode);

export const isAnalysisEligibleForDownload = (analysis: ProviderApi.UnderlyingDataInfo) =>
  analysis.status === 'complete';

export const ProviderAnalysisTable: React.FC<ProviderAnalysisTableProps> = ({ provider, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [availableStatus, setAvailableStatus] = React.useState<SelectFilterItem<string>[]>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<string>('all');

  const [availableApps, setAvailableApps] = React.useState<SelectFilterItem<string>[]>([]);
  const [selectedApp, setSelectedApp] = React.useState<string>('all');
  const [searchFilter, setSearchFilter] = React.useState<string>('');

  const [startDate, setStartDate] = React.useState<Date>(new Date(now.getTime() - timeWindow));
  const [endDate, setEndDate] = React.useState<Date>(now);

  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

  const [selectedAnalyses, setSelectedAnalyses] = React.useState<ProviderApi.UnderlyingDataInfo[]>([]);

  const { apps } = useUser();
  const { data: rawAnalysis, isLoading } = useUnderlyingDataForProvider(provider, startDate, endDate);
  const { mutateAsync: relaunchAnalysis, isLoading: isRelaunching } =
    api.provider.analysis.postBulkAnalysis.useMutation();
  const hasSubscribed = useMemo(() => hasSubscribedToApp(apps), [apps]);
  const isAnalysisRelaunchable = useMemo(() => isAnalysisEligibleForRelaunch(apps), [apps]);

  const onPeriodChange = useCallbackImmutable(([start, end]: [Date | null, Date | null]) => {
    if (start && end) {
      setStartDate(start);
      setEndDate(end);
    }
  });

  useEffect(() => {
    if (isLoading || rawAnalysis === undefined) {
      if (availableApps.length != 0) {
        setAvailableApps([]);
      }
      if (availableStatus.length != 0) {
        setAvailableStatus([]);
      }

      return;
    }

    const uniqStatus = getStatusOptions(rawAnalysis);
    setAvailableStatus(uniqStatus);
    setSelectedStatus('all');

    const uniqApps = getAppsOptions(rawAnalysis);
    setAvailableApps(uniqApps);
    setSelectedApp('all');

    if (gridApi) {
      gridApi.forEachNode((node) => {
        const shouldSelectNode = selectedAnalyses.some(({ id }) => node.data.id === id);
        if (shouldSelectNode) node.setSelected(true);
      });
    }
  }, [rawAnalysis, isLoading, availableApps.length, availableStatus.length, gridApi, selectedAnalyses]);

  const analysis = useMemo(() => {
    const appPredicate =
      selectedApp === 'all'
        ? () => true
        : (item: ProviderApi.UnderlyingDataInfo) => item.applicationCode === selectedApp;
    const statusPredicate =
      selectedStatus === 'all' ? () => true : (item: ProviderApi.UnderlyingDataInfo) => item.status === selectedStatus;

    return rawAnalysis?.filter((item) => appPredicate(item) && statusPredicate(item));
  }, [rawAnalysis, selectedApp, selectedStatus]);

  const isRelaunchButtonEnabled = useMemo(
    () => selectedAnalyses.some(isAnalysisEligibleForRelaunch(apps)),
    [selectedAnalyses, apps],
  );

  const isBulkDownloadButtonEnabled = useMemo(
    () => selectedAnalyses.some(isAnalysisEligibleForDownload),
    [selectedAnalyses],
  );

  const relaunchBulkAnalyses = useCallbackImmutable(async (analysisToRelaunch: ProviderApi.UnderlyingDataInfo[]) => {
    const eligibleAnalysesToRelaunch = analysisToRelaunch.filter(isAnalysisEligibleForRelaunch(apps)).map((item) => ({
      externalId: item.externalId,
      externalIdType: item.externalIdType as AnalysisApi.RequestBulkAnalysis['externalIdType'],
      valuationDate: item.valuationDate,
      applicationCode: item.applicationCode,
    }));

    try {
      await relaunchAnalysis({
        requests: eligibleAnalysesToRelaunch,
        forceProviderCreation: true,
      });

      toast.info('Analyses have been relaunched, please wait.');
    } catch {
      toast.error('Failed to relaunch the analyses, please try again or contact support.');
    }
  });

  const bulkDownloadAnalysis = useCallbackImmutable(async (analysisToDownload: ProviderApi.UnderlyingDataInfo[]) => {
    const eligibleAnalysesToDownload = analysisToDownload.filter(isAnalysisEligibleForDownload);
    const zipBuilder = new JSZip();
    let fileBuilder = { fileName: '', blob: new Blob() };

    const toastId = toast.loading(`The files are being downloaded (0/${eligibleAnalysesToDownload.length})`);

    let i = 0;
    await processPromisesBatch(eligibleAnalysesToDownload, 50, async (analysisPromise) => {
      const currentAnalysedFile = await api.provider.download.downloadRequest.raw({
        appCode: analysisPromise.applicationCode,
        requestId: analysisPromise.id,
      });

      await Promise.all(
        currentAnalysedFile.map(async ({ presignedUrl, fileName, metadata }) => {
          if (!fileName.endsWith('.csv')) return;
          const { formattedFileName } = formatAnalysisFileName(fileName, metadata);
          const response = await fetch(presignedUrl, { method: 'GET' });
          const blob = await response.blob();
          if (eligibleAnalysesToDownload.length === 1) fileBuilder = { fileName: formattedFileName, blob };
          else zipBuilder.file(formattedFileName, blob);
        }),
      );
      toast.update(toastId, {
        render: `The files are being downloaded (${++i}/${eligibleAnalysesToDownload.length})`,
      });

      return analysisPromise;
    });

    if (eligibleAnalysesToDownload.length === 1) defaultDownloadTransform(fileBuilder.fileName, fileBuilder.blob);
    else {
      const zipBlob = await zipBuilder.generateAsync({ type: 'blob' });
      const zipName = `underlying_data-${provider}.zip`;
      defaultDownloadTransform(zipName, zipBlob);
    }
    toast.dismiss(toastId);
    toast.success('The files have been downloaded successfully.');
  });

  const onRelaunch = useCallbackImmutable(() => relaunchBulkAnalyses(selectedAnalyses));
  const onDownload = useCallbackImmutable(() => bulkDownloadAnalysis(selectedAnalyses));

  const autoSize = useAutoSize('fit');
  const handleGridReady = useCallbackImmutable((params: GridReadyEvent) => {
    autoSize(params);
    setGridApi(params.api);
  });

  return (
    <Stack direction="column" gap="1.5rem" flexGrow="1">
      <AppMargin>
        <Stack direction="column">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <SearchField data-testid={createTestid('search')} value={searchFilter} onChange={setSearchFilter} />
              <PeriodPicker
                start={startDate}
                end={endDate}
                disableFuture
                label="Order date period"
                variant="inline"
                onFieldChange={onPeriodChange}
                data-testid={createTestid('period')}
              />
            </Stack>

            <Stack direction="row" gap="1rem" alignItems="center" justifyContent="end">
              <SelectFilter
                label="Apps"
                data-testid={createTestid('select-apps')}
                dropdownItems={availableApps}
                activeItem={selectedApp}
                onClick={setSelectedApp}
                size="medium"
              />
              <SelectFilter
                label="Status"
                data-testid={createTestid('select-status')}
                dropdownItems={availableStatus}
                activeItem={selectedStatus}
                onClick={setSelectedStatus}
                size="medium"
              />

              <Button
                data-testid={createTestid('download')}
                onClick={onDownload}
                size="medium"
                startIcon={<Icon.Download data-testid={createTestid('download-icon')} />}
                color="primary"
                disabled={!isBulkDownloadButtonEnabled}
                loading={isRelaunching && isBulkDownloadButtonEnabled}
              >
                {/* loading */}
                Download
              </Button>

              <Button
                data-testid={createTestid('relaunch')}
                onClick={onRelaunch}
                size="medium"
                startIcon={<Icon.Retry data-testid={createTestid('relaunch-icon')} />}
                color="primary"
                disabled={!isRelaunchButtonEnabled}
                loading={isRelaunching && isRelaunchButtonEnabled}
              >
                Relaunch
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </AppMargin>

      {(isLoading && (
        <EmptyState
          iconEnable
          iconName="processing"
          title="Loading, please wait"
          data-testid={createTestid('info-processing')}
        />
      )) || (
        <ProviderAnalysisGrid
          searchFilter={searchFilter}
          analysis={analysis ?? []}
          hasSubscribedToApp={hasSubscribed}
          setSelectedAnalyses={setSelectedAnalyses}
          isAnalysisRelaunchable={isAnalysisRelaunchable}
          relaunchAnalyses={relaunchBulkAnalyses}
          onGridReady={handleGridReady}
          data-testid={createTestid('grid')}
        />
      )}
    </Stack>
  );
};
