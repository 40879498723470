import { AppContent, AppLayout, useTestid } from 'mns-components';
import { DialogAddPortfolio } from '../../../components/views/import-create-portfolio/DialogAddPortfolio';
import { Audit } from '../../../components/views/uploads/Audit';
import { useManageUploadModalOpen } from '../hooks';

export const Upload: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [isOpenModal, openModal, closeUploadModal] = useManageUploadModalOpen();

  return (
    <AppLayout data-testid={testid}>
      <AppContent variant="expanded-noscroll">
        <Audit onOpenModal={openModal} buttonVariant="primary" data-testid={testid} />
        <DialogAddPortfolio isOpenModal={isOpenModal} onClose={closeUploadModal} data-testid={createTestid('add')} />
      </AppContent>
    </AppLayout>
  );
};
