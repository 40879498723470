import { Dialog } from 'mns-components';
import type { AppCode } from '../../views/appDescriptions';
import { CreateEmailDeliveryStepper } from './CreateEmailDeliveryStepper';

type CreateEmailDeliveryModalProps = {
  open: boolean;
  appCode: AppCode;
  onClose(): void;
  'data-testid': string;
};

export const CreateEmailDeliveryModal: React.FC<CreateEmailDeliveryModalProps> = ({
  open,
  appCode,
  onClose,
  'data-testid': testid,
}) => (
  <Dialog dialogTitle="Send to" open={open} onClose={onClose} data-testid={`${testid}-dialog`}>
    <CreateEmailDeliveryStepper onClose={onClose} appCode={appCode} data-testid={testid} />
  </Dialog>
);
