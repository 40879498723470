import { Stack } from '@mui/material';
import { FunnelSection, Typo, colors, useTestid } from 'mns-components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { TemplateData, TemplateFormData } from './SelectTemplate';
import { SelectTemplate } from './SelectTemplate';

const templateTypes = ['MY_TEMPLATES', 'MANAOS_TEMPLATES', 'ESG_APPS'] as const;
export type TemplateType = typeof templateTypes[number];

type SelectTemplateSectionProps = {
  disabled?: boolean;
  onChange(formData: TemplateData): void;
  'data-testid': string;
};

export const SelectTemplateSection = React.memo<SelectTemplateSectionProps>(function SelectTemplateSection({
  disabled,
  onChange,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const accordionName = 'selectTemplate';
  const [expanded, setExpanded] = useState<string | null>(accordionName);

  const methods = useForm<TemplateFormData>({
    defaultValues: {
      templateType: '',
      search: '',
      datapoints: {},
      view: undefined,
      matchTemplateId: null,
    },
  });
  const { templateType, datapoints, matchTemplateId } = methods.watch();

  // when datapoints change, call onChange with new values
  useEffect(() => {
    onChange({
      templateType,
      selected: datapoints,
      matchTemplateId,
    });
  }, [onChange, templateType, datapoints, matchTemplateId]);

  return (
    <FunnelSection
      name={accordionName}
      title="Select the extraction template"
      expansible
      value={expanded}
      onExpandValue={setExpanded}
      disabled={disabled}
      data-testid={createTestid('section')}
    >
      <Stack gap="1rem">
        <Typo variant="body2" color={disabled ? colors.disabledFontColor : undefined}>
          <Typo component="span" variant="body2medium">
            A template is a selection of portfolio and ESG data.
          </Typo>{' '}
          <Typo component="span" variant="body2">
            When you create an extraction, you can use a template you created (
          </Typo>
          <Typo component="span" variant="body2medium">
            My templates
          </Typo>
          <Typo component="span" variant="body2">
            ), a pre-configurated template created by Manaos (
          </Typo>
          <Typo component="span" variant="body2medium">
            Manaos template
          </Typo>
          <Typo component="span" variant="body2">
            ), or select data points directly from your ESG applications (
          </Typo>
          <Typo component="span" variant="body2medium">
            ESG apps
          </Typo>
          <Typo component="span" variant="body2">
            ). If you want to create a specific Manaos template, please contact us.
          </Typo>
        </Typo>
        <SelectTemplate disabled={disabled} onChange={onChange} data-testid={testid} />
      </Stack>
    </FunnelSection>
  );
});
