import { IconButton } from '@mui/material';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Icon,
  convertToDateLocal,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useMemoBase,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { QualitiesRenderer, getQualitiesRendererText } from '../../../components/cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../components/headerRender/QualitiesHeaderRenderer';
import { BulkDownloadButton } from '../common/BulkDownloadButton';

const getActionColDef = makeActionColDef<CollectApi.File>();

type UseColumnsProps = {
  onDownload?(eetLight: CollectApi.File): void;
  onGenerate?(eetLightId: string): void;
  testid: string;
};
const useColumns = ({ onDownload, onGenerate, testid }: UseColumnsProps) =>
  useMemoBase({ onDownload, onGenerate, testid }, (base): AggridTableColumn<CollectApi.File>[] => [
    {
      headerName: 'File name',
      field: 'name',
    },
    {
      headerName: 'Upload date',
      field: 'uploadedOn',
      cellRendererFramework: ({ value }) => convertToDateLocal(value),
    },
    {
      headerName: 'General reference date',
      field: 'referenceDate',
      sort: 'desc',
    },
    {
      headerName: 'Source',
      field: 'uploadSource',
    },
    {
      headerName: 'Scope',
      field: 'shareClassCount',
      cellRendererFramework: ({ value }) => `${value} share classes`,
    },
    {
      headerName: 'Qualities',
      field: 'qualities',
      valueGetter: ({ data: { qualities } }) => getQualitiesRendererText(qualities),
      cellRendererFramework: ({ data: { qualities, processId } }) => (
        <QualitiesRenderer qualities={qualities} data-testid={`${base.testid}-${processId}-qualities`} />
      ),
      headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${base.testid}-qualities`} />,
    },
    getActionColDef('status', 110, function ActionCell({ data }) {
      const handleDownload = useMemoBase(
        { data },
        (record) => base.onDownload && (() => base.onDownload?.(record.data)),
        [base.onDownload],
      );
      const handleGenerate = useMemoBase(
        { data },
        (record) => base.onGenerate && (() => base.onGenerate?.(record.data.processId)),
        [base.onGenerate],
      );
      return (
        <>
          <IconButton
            color="secondary"
            onClick={handleDownload}
            data-testid={`${base.testid}-${data.processId}-button-download`}
          >
            <Icon.Download data-testid={`${base.testid}-${data.processId}-icon-download`} />
          </IconButton>
          <BulkDownloadButton
            type="light EET"
            onGenerate={handleGenerate}
            data-testid={`${base.testid}-${data.processId}-bulk-button`}
          />
        </>
      );
    }),
  ]);

export type EetLightFilesListProps = {
  rows: CollectApi.File[];
  search?: string;
  onDownload?(eetLight: CollectApi.File): void;
  onGenerate?(eetLightId: string): void;
  'data-testid': string;
};

export const EetLightFilesList: React.FC<EetLightFilesListProps> = ({
  rows,
  search,
  onDownload,
  onGenerate,
  'data-testid': testid,
}) => (
  <AggridTable
    rowData={rows}
    getRowNodeId={useImmutable(() => (row: CollectApi.File) => row.processId)}
    columnDefs={useColumns({ onDownload, onGenerate, testid })}
    defaultColDef={useDefaultColDef<CollectApi.File>()}
    quickFilterText={search}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
