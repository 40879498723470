import { joinClass, colors, makeStyles, useTestid, useCallbackImmutable, Icon } from 'mns-components';

const useStyles = makeStyles({
  spoiler: {
    border: `1px solid ${colors.lightGreyColor}`,
    background: colors.whiteLessColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '2rem 3rem',
  },
  spoilerHeader: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    '& > h3': {
      flex: '1 0 auto',
      minWidth: 0,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  spoilerHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > span': {
      flex: '1 0 auto',
      minWidth: 0,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  spoilerContent: {
    flex: '1 0 auto',
    minHeight: '10rem',
    overflow: 'clip auto',
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  arrow: {
    transition: 'all .2s ease',
    transform: 'rotate(0)',
    '&.collapsed': {
      transform: 'rotate(.5turn)',
    },
  },
});

type SpoilerProps = {
  title: string;
  info: string;
  children: React.ReactNode;
  collapse?: boolean;
  onToggleCollapse?(prev: boolean): void;
  'data-testid': string;
};

export const Spoiler: React.FC<SpoilerProps> = ({
  title,
  info,
  children,
  collapse = false,
  onToggleCollapse,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const handleClick = useCallbackImmutable((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onToggleCollapse?.(collapse);
  });

  return (
    <div className={classes.spoiler} data-testid={testid}>
      <div className={classes.spoilerHeader} onClick={handleClick} data-testid={createTestid('button-header')}>
        <h3>{title}</h3>
        <div className={classes.spoilerHeaderRight}>
          <span>{info}</span>
          <div className={joinClass(classes.arrow, collapse && 'collapsed')}>
            <Icon.Expandmore data-testid={createTestid('icon-more')} />
          </div>
        </div>
      </div>
      <div
        className={joinClass(classes.spoilerContent, collapse && classes.hide)}
        data-testid={createTestid('content')}
      >
        {children}
      </div>
    </div>
  );
};
