import { Stack } from '@mui/material';
import { Button, Icon, SearchField, isObject, useCallbackImmutable, useTestid } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../../hooks/useAuth';
import { useOrganisation } from '../../../hooks/useGlobalState';
import { useOrganisationUsers, useUpdateUser } from '../hooks';
import { CreateUserModal } from './modal/CreateUserModal';
import { EditUserModal } from './modal/EditUserModal';
import { UsersInformationsGrid } from './UsersInformationsGrid';

type ModalData = null | true | AccountApi.User;

type UserInformationProps = { 'data-testid': string };

export const UserInformation: React.FC<UserInformationProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [modalOpen, setModalOpen] = useState<ModalData>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayedUsers, setDisplayedUsers] = useState<AccountApi.User[] | undefined>();

  const { isAdmin } = useUser();
  const { data: organisation } = useOrganisation();
  const { data: users, isLoading: isLoadingUsers } = useOrganisationUsers();

  const { mutate: updateUser, error: errorUpdateUser, isSuccess: isUserUpdated } = useUpdateUser();

  useEffect(() => {
    if (users) {
      setDisplayedUsers(users);
    }
  }, [isLoadingUsers, isUserUpdated, users]);

  useEffect(() => {
    if (errorUpdateUser) {
      toast.error("Couldn't update user");
    }
  }, [errorUpdateUser]);

  const onCloseModal = useCallbackImmutable((): void => setModalOpen(null));

  const onSearch = useCallbackImmutable((search: string) => {
    let searchResult = users;
    setSearchValue(search);
    if (search && users) {
      searchResult = users.filter((user) => {
        if (user.username.includes(search)) return true;
        if (`${user.name} ${user.familyName}`.includes(search)) return true;
        if (user.email.includes(search)) return true;
        return false;
      });
    }

    setDisplayedUsers(searchResult);
  });

  const resetSearchValue = useCallbackImmutable(() => {
    setSearchValue('');
    setDisplayedUsers(users);
  });

  const onAddUser = useCallbackImmutable((): void => {
    setModalOpen(true);
  });

  const onEdit = useCallbackImmutable((user: AccountApi.User): void => {
    setModalOpen(user);
  });

  const onChangeAdmin = useCallbackImmutable(async (user: AccountApi.User, value: boolean) => {
    updateUser({ ...user, isAdmin: value });
  });

  const onChangeActivate = useCallbackImmutable(async (user: AccountApi.User, value: boolean) => {
    updateUser({ ...user, enabled: value });
  });

  if (!organisation || isLoadingUsers) {
    return null;
  }

  return (
    <>
      <Stack gap="0.6rem" height="100%" data-stepid="accountUserInformation-userInfosTable">
        <Stack margin="1rem 2rem" direction="row" justifyContent="space-between" alignItems="center">
          <SearchField
            value={searchValue}
            onChange={onSearch}
            onClear={resetSearchValue}
            disableMargin
            data-testid={createTestid('search')}
          />
          {isAdmin && (
            <Button
              color="secondary"
              startIcon={<Icon.Profile data-testid={createTestid('icon-add')} />}
              onClick={onAddUser}
              data-testid={createTestid('button-add')}
            >
              Create user
            </Button>
          )}
        </Stack>
        <UsersInformationsGrid
          rowData={displayedUsers}
          onEdit={onEdit}
          onChangeAdmin={onChangeAdmin}
          onChangeActivate={onChangeActivate}
          isAdmin={isAdmin}
          data-testid={createTestid('list')}
        />
      </Stack>
      <CreateUserModal
        modalOpen={modalOpen === true}
        onCloseModal={onCloseModal}
        data-testid={createTestid('modal-add')}
      />
      <EditUserModal
        modalOpen={isObject(modalOpen)}
        user={isObject(modalOpen) ? modalOpen : undefined}
        onCloseModal={onCloseModal}
        data-testid={createTestid('modal-update')}
      />
    </>
  );
};
