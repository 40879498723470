import { Dialog, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import { appCollectApi } from '../../../../store/apis';
import { ReplyAccessRequest } from './ReplyAccessRequest';
import { RevokePermissions } from './RevokePermissions';

interface Props {
  selectedItems: CollectApi.AccessDemand[];
  isManagePermissionsOpen: boolean;
  onClose: () => void;
  'data-testid': string;
}

export const ManagePermissions: React.FC<Props> = ({
  isManagePermissionsOpen,
  selectedItems,
  onClose,
  'data-testid': testid,
}: Props) => {
  const createTestid = useTestid(testid);

  const handleRevoke = useCallbackImmutable(async (comment: string) => {
    const list = selectedItems.map((x) => x.dataAccessAuthorizationId);
    const revokeOrder: CollectApi.AccessAuthorizationUpdateRequest = {
      ids: list,
      status: 'REVOKED',
      comment,
    };
    await appCollectApi.updateStatusOnMultipleDataAuthorizationAccess(revokeOrder);
    onClose();
  });

  const handleAccept = useCallbackImmutable(async (comment: string, scopeToShare: string, embargoDelay?: number) => {
    const list = selectedItems.map((x) => x.dataAccessAuthorizationId);
    const acceptOrder: CollectApi.AccessAuthorizationAcceptRequest = {
      ids: list,
      status: 'ACCEPTED' as const,
      comment,
      embargoDelay,
      scopeToShare,
    };
    await appCollectApi.updateStatusOnMultipleDataAuthorizationAccess(acceptOrder);
    onClose();
  });

  // Form handler
  const handleRefuse = useCallbackImmutable(async (comment: string) => {
    const list = selectedItems.map((x) => x.dataAccessAuthorizationId);
    const denyOrder: CollectApi.AccessAuthorizationUpdateRequest = {
      ids: list,
      status: 'REFUSED',
      comment,
    };
    await appCollectApi.updateStatusOnMultipleDataAuthorizationAccess(denyOrder);
    onClose();
  });

  switch (true as boolean) {
    case !selectedItems?.[0]?.status:
      return null;
    case selectedItems[0].status === 'ACCEPTED':
      return (
        <Dialog
          dialogTitle="Revoke access"
          open={isManagePermissionsOpen}
          onClose={onClose}
          data-testid={`${testid}-dialog`}
        >
          <RevokePermissions
            selectedItems={selectedItems}
            onRevoke={handleRevoke}
            closeModal={onClose}
            data-testid={createTestid('modal-revoke')}
          />
        </Dialog>
      );
    case selectedItems[0].status === 'PENDING':
      return (
        <Dialog
          dialogTitle="Reply to access request(s)"
          open={isManagePermissionsOpen}
          onClose={onClose}
          data-testid={`${testid}-dialog`}
        >
          <ReplyAccessRequest
            selectedItems={selectedItems}
            onAccept={handleAccept}
            onRefuse={handleRefuse}
            closeModal={onClose}
            data-testid={createTestid('modal-reply')}
          />
        </Dialog>
      );
    default:
      return null;
  }
};
