import { Checkbox } from '@mui/material';
import { Link, useCallbackImmutable, useTestid } from 'mns-components';
import React from 'react';
import { CLOUDFRONT_DNSNAME } from '../../../../app.constants';
import { formStyles as useStyles } from './formStyles';

type AgreeOrderConditionsDisplayProps = {
  companyName: string;
  providerName: string;
  onAgreeOrderConditions?(bo: boolean): void;
  'data-testid': string;
};

const documentNames: Record<string, string | undefined> = {
  rightbasedonscience: 'platform_agreement.docx',
};

const policyFileUrl = (providerName: string, documentName: string) =>
  `//${CLOUDFRONT_DNSNAME}/providers/${providerName}/legal/tcs/subscription/${documentName}`;

export const AgreeOrderConditionsDisplay: React.FC<AgreeOrderConditionsDisplayProps> = ({
  companyName,
  providerName,
  onAgreeOrderConditions,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const handleAgreeOrderConditions = useCallbackImmutable((ev: React.ChangeEvent<HTMLInputElement>, bo: boolean) =>
    onAgreeOrderConditions?.(bo),
  );

  const documentName = documentNames[providerName];

  return (
    <p className={classes.paragraph}>
      <Checkbox name="agreeOrderConditions" onChange={handleAgreeOrderConditions} data-testid={testid} />
      <span>
        By clicking on &quot;Confirm Order&quot;, I agree to {companyName}{' '}
        {documentName ? (
          <Link
            extern
            to={policyFileUrl(providerName, documentName)}
            target="tcs"
            data-testid={createTestid('link-view-tcs')}
          >
            General Conditions
          </Link>
        ) : (
          'General Conditions'
        )}{' '}
        of Sale.
      </span>
    </p>
  );
};
