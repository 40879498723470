import { Stack } from '@mui/material';
import { AppContent, Typo, convertToDateLocal, useTestid, EmptyStateV2, LoadingCircle } from 'mns-components';
import { useState } from 'react';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { useGetCollectedFunds } from '../../hooks';
import { DashboardStyle as useStyles } from '../Dashboard.styles';

export type LatestCollectProps = {
  startDate: Date;
  endDate: Date;
  'data-testid': string;
};
type LatestCollectInnerProps = {
  setNumberOfInv(value: number): void;
};

const LatestCollectInner: React.FC<LatestCollectProps & LatestCollectInnerProps> = ({
  startDate,
  endDate,
  setNumberOfInv,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { data, isLoading, isError } = useGetCollectedFunds(convertToDateLocal(startDate), convertToDateLocal(endDate));
  const funds = data?.fundToCollectDetailsDtos;
  if (funds) setNumberOfInv(funds?.length);
  else setNumberOfInv(0);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" flex="1" width="100%">
        <LoadingCircle size="medium" data-testid={createTestid('emptyState-status')} />
      </Stack>
    );
  }

  if (isError || !funds) {
    return (
      <EmptyStateV2
        variant="error"
        useCase="dataErrors"
        title="Oops, something went wrong!"
        subTitle="Please refresh your page or contact our support team."
        illustrationVariant="iconType"
        data-testid={createTestid('error-status')}
      />
    );
  }

  if (funds.length == 0)
    return (
      <EmptyStateV2
        variant="info"
        useCase="noActions"
        title="There are no inventories collected yet!"
        illustrationVariant="iconType"
        data-testid={createTestid('emptyState-status')}
      />
    );

  return (
    <Stack className={classes.scroll} gap="1rem">
      {funds.map(({ disseminatorPortfolio }, index) => (
        <Stack
          key={disseminatorPortfolio?.externalId}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <Typo.Ellipsis
            variant="body2"
            title={`${disseminatorPortfolio?.externalId} - ${disseminatorPortfolio?.portfolioName}`}
            data-testid={createTestid(`${index}-inventory-name`)}
          >
            {disseminatorPortfolio?.externalId} - {disseminatorPortfolio?.portfolioName}
          </Typo.Ellipsis>
          {disseminatorPortfolio && (
            <QualitiesRenderer
              qualities={disseminatorPortfolio?.ingestionInfos?.qualities ?? []}
              data-testid={createTestid(`${index}-${disseminatorPortfolio?.ingestionInfos?.processId}-qualities`)}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export const LatestCollect: React.FC<LatestCollectProps> = ({ startDate, endDate, 'data-testid': testid }) => {
  const [numberOfInv, setNumberOfInv] = useState<number>(0);
  return (
    <AppContent data-testid={testid}>
      <Typo variant="h3">Latest inventories collected ({numberOfInv})</Typo>
      <LatestCollectInner
        startDate={startDate}
        endDate={endDate}
        setNumberOfInv={setNumberOfInv}
        data-testid={testid}
      />
    </AppContent>
  );
};
