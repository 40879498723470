import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, useTestid } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { MarketplaceFiltersAndSearch } from '../components/MarketplaceFiltersAndSearch';
import { useApplicationsRequest } from '../hooks';
import { marketplaceStyles as useStyles } from '../styles/marketplaceStyles';
import { MarketplaceExecuteList, useApplicationsSubscribed } from './MarketplaceExecuteList';

const defaultFilter = { search: '', 'data-only': false, 'portfolio-analysis': false };

export const MarketplaceSubscribed: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const [filteredApps, setFilteredApps] = useState<MarketplaceApi.Application[]>([]);
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const applicationsRequest = useApplicationsRequest();
  const applications = useApplicationsSubscribed();

  if (applicationsRequest.error) {
    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Unable to load your applications. Please refresh your page or contact our support team."
          data-testid={createTestid('error-app')}
        />
      </Stack>
    );
  }

  if (applicationsRequest.isLoading && !applicationsRequest.data) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <div className={classes.marketplace} data-testid={testid}>
      <div className={classes.marketplaceColumn}>
        <MarketplaceFiltersAndSearch
          applications={applications}
          onFilter={setFilteredApps}
          data-testid={createTestid('header')}
          defaultValues={defaultFilter}
        />
        <MarketplaceExecuteList applications={filteredApps} data-testid={createTestid('list')} />
      </div>
    </div>
  );
};
