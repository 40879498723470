import { colors, makeStyles } from 'mns-components';

export const scheduledDeliveriesStyles = makeStyles({
  hr: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${colors.greyColor}`,
  },
});
