import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';

export const isFundAllowedToChaseUp = (fund: CollectApi.FundPositionToCollect) => {
  const dataAccessAuthorizationId = fund.accessRequest?.id;

  const notDisseminated = fund.disseminatorPortfolio?.disseminationStatus !== 'DISSEMINATED';
  return fund?.accessRequest?.status === 'ACCEPTED' && !!dataAccessAuthorizationId && notDisseminated;
};

// new function to rework
export const isFundNotCollected = (fund: CollectApi.FundPositionToCollect) => {
  const notDisseminated = fund.disseminatorPortfolio?.disseminationStatus !== 'DISSEMINATED';
  return fund.fundPosition.existOnThePlatform && notDisseminated;
};

export const isFundAllowedToRequestQuality = (
  data: CollectApi.FundPositionToCollect,
  orgId = localStorage.getItem('organization-id'),
) =>
  !isFundAllowedToChaseUp(data) &&
  data.disseminatorPortfolio?.ingestionInfos?.processId &&
  data.disseminatorPortfolio?.organisationId !== orgId &&
  !(data.disseminatorPortfolio?.ingestionInfos.doesQualitiesMatchExpectation ?? false);

export const isFundAllowedToRequestAccess = (
  fund: CollectApi.FundPositionToCollect,
  orgId = localStorage.getItem('organization-id'),
) => {
  const organisationOwner = fund.disseminatorPortfolio?.organisationId;
  const portfolioId = fund.disseminatorPortfolio?.portfolioId;

  const doesAccessRequestStatusAllowsAccessRequest = !['PENDING', 'ACCEPTED'].includes(
    fund.accessRequest?.status ?? '',
  );

  return (
    fund.fundPosition.existOnThePlatform &&
    (!fund.accessRequest || doesAccessRequestStatusAllowsAccessRequest) &&
    organisationOwner !== orgId &&
    !!portfolioId
  );
};

export const isFundAllowedToInvite = (fund: CollectApi.FundPositionToCollect) => {
  return !fund.fundPosition.existOnThePlatform && !fund.fundPosition.invitationAlreadySent; // Fund does not exist on the platform
};

// new function to rework
export const isFundAllowedToInviteBis = (fund: CollectApi.FundPositionToCollect) => {
  return !fund.fundPosition.existOnThePlatform; // Fund does not exist on the platform
};

export const isFundAllowedToDownload = (fund: CollectApi.FundPositionToCollect) =>
  !!(
    fund?.disseminatorPortfolio?.ingestionInfos?.processId &&
    fund.disseminatorPortfolio?.ingestionInfos?.latestVersionAsOf
  );

export const isFundCollected = ({ disseminatorPortfolio }: CollectApi.FundPositionToCollect) =>
  !!disseminatorPortfolio?.ingestionInfos?.processId;

type CollectionStatusButtonRendererProps = {
  orgId: string | null;
  data: CollectApi.FundPositionToCollect;
  setSelected: (selectedItems: CollectApi.FundPositionToCollect) => void;
  'data-testid': string;
};

export const CollectionStatusButtonRenderer: React.FC<CollectionStatusButtonRendererProps> = ({
  setSelected,
  data,
  orgId,
  'data-testid': testid,
}) => {
  const handleClick = useCallbackImmutable(() => setSelected(data));
  const createTestid = useTestid(testid);

  switch (true) {
    case isFundAllowedToChaseUp(data):
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon name={'chaseup'} size="medium" data-testid={createTestid('chaseUp-icon')} />}
          onClick={handleClick}
          data-testid={createTestid('chaseUp')}
        >
          Chase up
        </Button>
      );
    case isFundAllowedToInvite(data):
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Invite size="medium" data-testid={createTestid('invite-icon')} />}
          onClick={handleClick}
          data-testid={createTestid('invite')}
        >
          Invite
        </Button>
      );
    case isFundAllowedToRequestAccess(data, orgId):
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon.Accessopen size="medium" data-testid={createTestid('requestAccess-icon')} />}
          onClick={handleClick}
          data-testid={createTestid('requestAccess')}
        >
          Request Access
        </Button>
      );
    case isFundAllowedToRequestQuality(data, orgId):
      return (
        <Button
          color="primary"
          outlined
          size="small"
          startIcon={<Icon name={'chaseup'} size="medium" data-testid={createTestid('chaseUp-icon')} />}
          onClick={handleClick}
          data-testid={createTestid('requestQuality')}
        >
          Request Quality
        </Button>
      );
    default:
      return <i />;
  }
};
