import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useState } from 'react';
import useStyles from '../styles/CloseAccessPermission.styles';

interface AccessRequestProps {
  selectedItems: CollectApi.AccessDemand[];
  closeModal: () => void;
  saveCloseAccess: () => void;
  'data-testid': string;
}

export const CloseAccessPermission: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  saveCloseAccess,
  'data-testid': testid,
}: AccessRequestProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const handleSave = useCallbackImmutable(() => {
    setIsSaving(true);
    saveCloseAccess();
  });

  return (
    <>
      <span className={classes.description}>
        <Icon.Info data-testid={createTestid('icon-info')} />
        <h3 className={classes.cardTitles}>You are about to close your access for the following shareclass</h3>
      </span>
      <h3>Shareclass selected</h3>
      <div className={classes.verticalScrollTable}>
        <Table aria-label="simple table" data-testid={createTestid('table-selected')}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaders}>Shareclass ID</TableCell>
              <TableCell className={classes.tableHeaders}>Shareclass name</TableCell>
              <TableCell className={classes.tableHeaders}>Asset manager</TableCell>
              <TableCell className={classes.tableHeaders}>Frequency</TableCell>
              <TableCell className={classes.tableHeaders}>Deadline</TableCell>
              <TableCell className={classes.tableHeaders}>Look-through rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row: CollectApi.AccessDemand) => (
              <TableRow key={`${row.fundId}-${row.dataAccessAuthorizationId}`}>
                <TableCell component="th" scope="row">
                  {row.fundId}
                </TableCell>
                <TableCell className={classes.tableContent}>{row.fundName}</TableCell>
                <TableCell className={classes.tableContent}>{row.receptor}</TableCell>
                <TableCell className={classes.tableContent}>
                  <Icon.Calendar data-testid={createTestid(`${row.fundId}-icon-calendar`)} />
                  {row.sharingFrequency.charAt(0).toUpperCase() + row.sharingFrequency.slice(1).toLowerCase()}
                </TableCell>
                <TableCell className={classes.tableContent}>{row.deadline}</TableCell>
                <TableCell className={classes.tableContent}>{row.lookThroughLevel}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.formFooter}>
        <hr />
        <Button color="primary" outlined onClick={closeModal} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} loading={isSaving} data-testid={createTestid('button-submit')}>
          Confirm
        </Button>
      </div>
    </>
  );
};
