import { CardSelector, useTestid } from 'mns-components';
import { useMemo } from 'react';
import type { ApplicationConfig } from '../../../../common/getApplicationConfig';
import { useProvidersIconBackgroundStyles } from '../../../../common/getImageContent';
import type { ProviderName } from '../../../../components/views/appDescriptions';

export type ProviderSelectorProps = {
  providers: ApplicationConfig['company'][];
  selectedProvider?: ApplicationConfig['company'];
  onChange(providerName: ProviderName): void;
  'data-testid': string;
};

export const ProviderSelector: React.FC<ProviderSelectorProps> = ({
  providers,
  selectedProvider,
  onChange,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const appBackgroundClasses = useProvidersIconBackgroundStyles();
  const options = useMemo(
    () =>
      providers.map((provider) => ({
        value: provider.providerName,
        label: provider.name,
        appBackgroundClassName: appBackgroundClasses[provider.providerName],
      })),
    [appBackgroundClasses, providers],
  );

  return (
    <CardSelector
      options={options}
      defaultValue={selectedProvider?.providerName ?? ''}
      onChange={onChange}
      data-testid={createTestid('selector')}
      name="choose-partner"
      label="Choose partner"
    />
  );
};
