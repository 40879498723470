import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type {
  FormStepErrors,
  FormStepperStepProps,
  FormStepProps,
  FormStepValidator,
  JsonSchemaObject,
} from 'mns-components';
import { EmptyStateV2, FormCreator, isString, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, EetApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import type { AppCode } from '../../../views/appDescriptions';
import { useFetchEetFiles } from '../../hooks';
import { createEmailDeliveryModalStyles as useStyles } from '../createEmailDeliveryModalStyles';
import type { CreateEmailDeliveryEetSteps } from '../types';
import { SelectScopeEetList } from './SelectScopeEetList';

const getEetGeneralReferenceDate = (files: EetApi.EetFile<AppCode>[]) => {
  return files.reduce((acc, eet) => {
    if (!acc.includes(eet.generalReferenceDate)) {
      acc.push(eet.generalReferenceDate);
    }
    return acc;
  }, [] as string[]);
};

type FormType = { title: string; generalReferenceDate: string };

const useInputSchema = (refDateOptions: string[], generalReferenceDate = '') =>
  useMemo(
    (): JsonSchemaObject => ({
      variant: 'column',
      properties: {
        title: {
          type: 'string',
          title: 'Delivery name',
          variant: 'text',
          placeholder: `ie: Delivery of ${generalReferenceDate}`,
        },
        generalReferenceDate: {
          type: 'string',
          title: 'general reference date',
          variant: 'autocomplete',
          enum: refDateOptions,
        },
      },
      required: ['title', 'generalReferenceDate'],
    }),
    [refDateOptions, generalReferenceDate],
  );

type SelectScopeStepProps = FormStepProps<CreateEmailDeliveryEetSteps[0]>;

const SelectScopeEetStep: React.FC<SelectScopeStepProps> = ({
  stepValue: { title, generalReferenceDate, eetIds },
  setStepValue,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { data: eetFiles } = useFetchEetFiles();

  const fileteredEet = eetFiles?.filter((eet) => eet.status == 'COMPLETED');

  const refDateOptions = useMemo(() => (!fileteredEet ? [] : getEetGeneralReferenceDate(fileteredEet)), [fileteredEet]);
  const jsonSchema = useInputSchema(refDateOptions, generalReferenceDate);

  const rows = useMemo(
    () =>
      fileteredEet?.length && generalReferenceDate
        ? fileteredEet.filter((eet) => eet.generalReferenceDate === generalReferenceDate)
        : [],
    [generalReferenceDate, fileteredEet],
  );

  const handleRowsSelected = useCallbackImmutable((selectList: EetApi.EetFile<AppCode>[]) =>
    setStepValue((current) => ({ ...current, eetIds: selectList.map((row) => row.id) })),
  );

  const handleFormChange = useCallbackImmutable((update: (current: FormType) => FormType) =>
    setStepValue((current) => ({
      ...current,
      ...update({ generalReferenceDate: current.generalReferenceDate, title: current.title }),
    })),
  );

  const handleGridLoad = useCallbackImmutable((grid: GridApi | null) => {
    grid?.forEachNode((node) => {
      const data = node.data as CollectApi.Portfolio;
      if (eetIds?.includes(data.latestVersion.id)) {
        node.setSelected(true);
      }
    });
  });

  const formState = useMemo((): FormType => {
    const currentRefDate = generalReferenceDate ?? refDateOptions[0];
    const shortUUID = Math.random().toString(36).substring(2, 10);

    const currentTitle = title ?? `email-delivery-${shortUUID}`;

    return { title: currentTitle, generalReferenceDate: currentRefDate };
  }, [title, generalReferenceDate, refDateOptions]);

  if (!rows.length) {
    return (
      <>
        <FormCreator<FormType>
          className={classes.form}
          jsonSchema={jsonSchema}
          state={formState}
          setState={handleFormChange}
          data-testid={createTestid('form')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noSearchResults"
            title="There is no match with your research!"
            subTitle="You can search again with new criteria."
            illustrationVariant="iconType"
            data-testid={createTestid('empty-rows')}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      <FormCreator<FormType>
        className={classes.form}
        jsonSchema={jsonSchema}
        state={formState}
        setState={handleFormChange}
        data-testid={createTestid('form')}
      />
      <SelectScopeEetList
        rows={rows}
        onRowsSelected={handleRowsSelected}
        getGridApiRef={handleGridLoad}
        data-testid={createTestid('selectScopeList')}
      />
    </>
  );
};

const validateSelectScopeStep: FormStepValidator<CreateEmailDeliveryEetSteps[0]> = (data) => {
  const errors: FormStepErrors<CreateEmailDeliveryEetSteps[0]> = [];

  if (!isString(data.title)) {
    errors.push({
      name: 'title',
      message: 'Delivery name should be filled',
    });
  } else if (!data.title.length) {
    errors.push({
      name: 'title',
      message: 'Delivery name should not be empty',
    });
  }

  if (!Array.isArray(data.eetIds) || !data.eetIds.length) {
    errors.push({
      name: 'eetIds',
      message: 'Please select at least one EET',
    });
  }

  return errors;
};

export const buildSelectScopeEetStep = (): FormStepperStepProps<CreateEmailDeliveryEetSteps[0]> => ({
  build: makeForm(SelectScopeEetStep),
  title: 'Select Scope',
  onValidate: validateSelectScopeStep,
});
