import React from 'react';
import { authPageLayoutStyles as useStyles } from './authPageLayoutStyles';

type AuthPagelayout = { children?: React.ReactNode; 'data-testid': string };

export const AuthPageLayout: React.FC<AuthPagelayout> = ({ children, 'data-testid': testid }) => {
  const classes = useStyles();
  return (
    <div className={classes.authPage} data-testid={testid}>
      <div className={classes.formSide}>
        <div>{children}</div>
      </div>
      <div className={classes.infoSide}>
        <p>
          We believe technology should allow institutional investors to make ever more effective, responsible investment
          decisions based on a comprehensive understanding of what they have at hand.
        </p>
      </div>
    </div>
  );
};
