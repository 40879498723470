import { Grid } from '@mui/material';
import type { SubIndicators } from 'mns-components';
import { BigKpiCard, colors, CardLogoTitle, useTestid } from 'mns-components';
import React, { useMemo } from 'react';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import { useEmitterAccessRequests } from '../../../hooks/useGlobalState';

type AccessesKpisProps = { 'data-testid': string };

export const AccessesKpis: React.FC<AccessesKpisProps> = ({ 'data-testid': testid }: AccessesKpisProps) => {
  const createTestid = useTestid(testid);
  const { data: authorizationList } = useEmitterAccessRequests();
  const pendingKPI = authorizationList?.filter((a) => a.status === 'PENDING').length ?? 0;

  const subAccessesIndicators = useMemo(
    (): SubIndicators[] => [
      {
        key: 'pending',
        label: 'pending',
        value: pendingKPI,
        color: pendingKPI > 0 ? colors.orangeColor : colors.blueColor,
      },
      {
        key: 'active',
        label: 'active',
        value: authorizationList?.filter((a) => a.status === 'ACCEPTED').length ?? 0,
        color: colors.blueColor,
      },
      {
        key: 'inactive',
        label: 'inactive',
        value: authorizationList?.filter((a) => a.status !== 'ACCEPTED' && a.status !== 'PENDING').length ?? 0,
        color: colors.blueColor,
      },
    ],
    [authorizationList, pendingKPI],
  );

  const appBackgroundClassName = useAppBackgroundStyles()['manaos-coverageestimation'];

  return (
    <Grid container data-testid={testid}>
      <Grid item>
        <CardLogoTitle
          company="Manaos"
          appName="Collection"
          appBackgroundClassName={appBackgroundClassName}
          variant="filled"
          data-testid={createTestid('card')}
        />
      </Grid>
      {authorizationList && (
        <Grid item>
          <BigKpiCard
            iconName="vpnkey"
            indicatorNumber={authorizationList.length}
            indicatorDescription="Access requests"
            subIndicators={subAccessesIndicators}
            data-testid={createTestid('kpis')}
          />
        </Grid>
      )}
    </Grid>
  );
};
