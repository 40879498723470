import type { OptionType } from 'mns-components';
import {
  Button,
  useTestid,
  Select,
  filterTruthy,
  joinClass,
  Icon,
  useImmutable,
  useCallbackImmutable,
} from 'mns-components';
import React, { useState, useMemo } from 'react';
import { useMultipleContactList } from '../../../../components/settings/organisation/hooks';
import useStyles from '../styles/InviteAccessPermission.styles';

type SelectAmContactListProps = {
  amOptionList: OptionType[];
  addEmailLists: (value: string[]) => void;
  'data-testid': string;
};

export const SelectAmContactList: React.FC<SelectAmContactListProps> = ({
  amOptionList,
  addEmailLists,
  'data-testid': testid,
}: SelectAmContactListProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const [amIdsSelected, setAmIdsSelected] = useState<string[]>([]);
  const amContactListQueries = useMultipleContactList(amOptionList.map((amOption) => amOption.value));

  const amEmailsMap = useMemo(
    () => new Map(amOptionList.map((option, index) => [option.value, amContactListQueries[index].data])),
    [amContactListQueries, amOptionList],
  );

  const handleAmSelected = useImmutable(() => (value: string[]) => {
    setAmIdsSelected(value);
  });

  const handleAddEmails = useCallbackImmutable(() => {
    const emailsToAdd: string[] = [];
    amIdsSelected.forEach((amId) => amEmailsMap.get(amId)?.forEach((email) => emailsToAdd.push(email)));
    setAmIdsSelected([]);
    addEmailLists(emailsToAdd);
  });

  const handleRender = useCallbackImmutable((list: string[]) =>
    filterTruthy(list.map((amId) => amOptionList.find((option) => amId === option.value)?.label)).join(', '),
  );

  return (
    <div className={joinClass(classes.emailListSelect, classes.emailText)}>
      <Select
        uncontrolled
        name="emails"
        label="Select asset manager email list"
        placeholder="Select asset manager email list"
        value={amIdsSelected}
        onFieldChange={handleAmSelected}
        options={amOptionList}
        renderValue={handleRender}
        variant="normal"
        multiple
        data-testid={createTestid('select-emailLists')}
      />
      <Button
        color="primary"
        size="medium"
        startIcon={<Icon.Add data-testid={createTestid('icon-addEmailList')} />}
        onClick={handleAddEmails}
        disabled={amIdsSelected.length == 0}
        data-testid={createTestid('button-addEmailList')}
      >
        Add Emails
      </Button>
    </div>
  );
};
