import type { SimpleTableProps } from 'mns-components';
import { SimpleTable } from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useMemo } from 'react';

const useColumns = () =>
  useMemo(
    (): SimpleTableProps<EetApi.Portfolio>['columns'] => [
      {
        field: 'name',
        head: '',
        cell: () => '',
        flex: 1,
      },
      {
        field: 'externalId',
        head: 'Financial Instrument ID',
        flex: 3,
      },
      {
        field: 'valuationDate',
        head: 'Required Nav Date',
        flex: 3,
      },
    ],
    [],
  );

type MissingShareClassSimpleTableProps = { rows: EetApi.Portfolio[]; 'data-testid': string };

export const MissingShareClassSimpleTable: React.FC<MissingShareClassSimpleTableProps> = ({
  rows,
  'data-testid': testid,
}) => <SimpleTable columns={useColumns()} rows={rows} data-testid={testid} />;
