import { colors, makeStyles } from 'mns-components';

export const DashboardStyle = makeStyles({
  // ----- MyTask -----
  containerHeader: {
    justifyContent: 'space-between',
    paddingBottom: '1rem',
    alignItems: 'center',
  },
  section: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionBorder: {
    borderBottom: `${colors.borderLightColor} 1px solid`,
  },
  sectionTitle: {
    color: colors.darkGreyColor,
  },
  line: {
    color: colors.defaultFontColor,
    paddingRight: '1rem',
    fontSize: '0.875rem',
  },
  footer: {
    paddingTop: '1rem',
    borderTop: `${colors.greyColor} 1px solid`,
    justifyContent: 'center',

    button: {
      fontWeight: '600',
    },
  },
  deadlineChip: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '.25rem',
    },
  },
  info: {
    padding: '0.25rem 0.375rem !important',
  },

  // ----- Scroll -----
  scroll: {
    flex: 1,
    overflowY: 'scroll',

    // ---- Scrollbar ----
    '&::-webkit-scrollbar': {
      width: '.4rem',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: colors.warningGreyColor,
    },
  },

  // ----- Collect status -----
  emptyStatus: {
    '& > div': {
      padding: '2rem 1rem 1rem 1rem',
      marginTop: '0',
      marginBottom: '0',
    },
  },
  // ----- Collect status label -----
  collectStatusLabel: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },

  collectStatusExpandIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
  },
});
