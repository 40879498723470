import { Button, Icon, Modal, useTestid } from 'mns-components';
import { modalStyles as useModalStyles } from './styles/modalStyles';

type ConfirmMissingsModalProps = {
  isOpen?: boolean;
  onClose?(): void;
  onConfirm?(): void;
  'data-testid': string;
};

export const ConfirmMissingsModal: React.FC<ConfirmMissingsModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  'data-testid': testid,
}) => {
  const classes = useModalStyles();
  const createTestid = useTestid(testid);

  return (
    <Modal modalTitle="Warning" open={!!isOpen} onClose={onClose} color="warning">
      <div className={classes.body}>
        <p>Some portfolios are missing, are you sure you want to continue?</p>
      </div>
      <div className={classes.buttonList}>
        <Button
          color="primary"
          outlined
          startIcon={<Icon.Close data-testid={createTestid('icon-close')} />}
          onClick={onClose}
          data-testid={createTestid('button-close')}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm} data-testid={createTestid('button-submit')}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
