import { Stack } from '@mui/material';
import type { ToggleButtonGroupItem } from 'mns-components';
import {
  Backdrop,
  EmptyStateV2,
  ToggleButtonGroup,
  useCallbackImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import { useParams } from 'react-router-dom';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';
import { useUploadModalOpen } from '../../uploads/hooks';
import { Layout } from '../common/Layout';
import { esgExtractionsRoutes } from './routesExtractions';

type NavKey = typeof esgExtractionsRoutes[number]['key'] & string;

const navs = esgExtractionsRoutes.map(
  (route): ToggleButtonGroupItem<NavKey> => ({
    value: route.key,
    children: route.label,
  }),
);

export const Extractions: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const handleNavUploadFiles = useUploadModalOpen();
  const { view } = useParams<{ view: NavKey }>();
  const handleChange = useCallbackImmutable((_, value: NavKey[]) => {
    if (value.length >= 2) {
      const last = value[value.length - 1];
      const found = esgExtractionsRoutes.find(({ key }) => last === key);
      if (found) {
        navigate(found.link);
      }
    }
  });

  const ptfsRequest = useFetchAllPortfolios();

  if (ptfsRequest.isLoading) {
    return (
      <Layout data-testid={testid}>
        <Backdrop data-testid={createTestid('loading')} />
      </Layout>
    );
  }

  if (ptfsRequest.isError) {
    return (
      <Layout data-testid={testid}>
        <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
          <EmptyStateV2
            useCase="dataErrors"
            variant="error"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error')}
          />
        </Stack>
      </Layout>
    );
  }

  if (ptfsRequest.data?.length === 0) {
    return (
      <Layout data-testid={testid}>
        <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
          <EmptyStateV2
            useCase="upload"
            variant="info"
            title="Get started with your upload!"
            subTitle="To be able to create an extraction, you need to upload a portfolio."
            buttonIcon="upload"
            buttonText="Upload"
            buttonVariant="secondary"
            buttonOnClick={handleNavUploadFiles}
            data-testid={createTestid('error-empty')}
          />
        </Stack>
      </Layout>
    );
  }

  const route = esgExtractionsRoutes.find(({ key }) => view === key);
  const Comp = route?.component;

  return (
    <Layout data-testid={testid}>
      <Stack padding="1.5rem 2rem 1.5rem 2rem">
        <ToggleButtonGroup.Uncontrolled<NavKey>
          list={navs}
          value={[view]}
          onChange={handleChange}
          data-testid={createTestid('nav')}
        />
      </Stack>
      {route && Comp && <Comp data-testid={createTestid(view)} />}
    </Layout>
  );
};
