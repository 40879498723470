import { Stack } from '@mui/material';
import type { AggridTableColumn, AggridTableProps } from 'mns-components';
import { AggridTable, useDefaultColDef, objectKeys, useAutoSize, useImmutable } from 'mns-components';
import { useMemo } from 'react';

const getColumnDefs = (keys: string[], pinned: string[]) =>
  keys.map(
    (key): AggridTableColumn<AnyObject<string, string>> =>
      pinned.includes(key)
        ? {
            field: key,
            pinned: 'left',
            sort: 'asc',
          }
        : {
            field: key,
          },
  );

const useColumnDefs = (rowData: AnyObject<string, string>[], uniqueKey: string) =>
  useMemo(() => getColumnDefs(objectKeys(rowData[0]), [uniqueKey]), [rowData, uniqueKey]);

export type AnalysisDetailsListProps = {
  rows: AnyObject<string, string>[];
  uniqueKey: string;
  getGridApiRef?: AggridTableProps<AnyObject<string, string>>['getGridApiRef'];
  'data-testid': string;
};

export const AnalysisDetailsList: React.FC<AnalysisDetailsListProps> = ({
  rows,
  uniqueKey,
  getGridApiRef,
  'data-testid': testid,
}) => (
  <Stack flexGrow={1}>
    <AggridTable
      theme="alpine"
      columnDefs={useColumnDefs(rows, uniqueKey)}
      defaultColDef={useDefaultColDef<AnyObject<string, string>>()}
      rowData={rows}
      paginationPageSize={50}
      onGridReady={useAutoSize('auto')}
      getRowNodeId={useImmutable(() => (row: AnyObject<string, string>) => row[uniqueKey])}
      getGridApiRef={getGridApiRef}
      data-testid={testid}
    />
  </Stack>
);
