import { Stack } from '@mui/material';
import { Button, useTestid, makeForm, Icon, SearchField, AppMargin } from 'mns-components';

type IsinListControllerProps = {
  search: string;
  onSearch(search: string): void;
  onOpenCreateModal(): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

const IsinListControllerComp: React.FC<IsinListControllerProps> = ({
  search,
  onSearch,
  onOpenCreateModal,
  variant,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  return (
    <AppMargin>
      <Stack
        direction="row"
        gap="1rem"
        marginTop={variant === 'USER_SCOPE' ? '1rem' : 0}
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchField
          uncontrolled
          disableMargin
          value={search}
          onChange={onSearch}
          data-testid={createTestid('form-search')}
        />
        <Button
          color={variant === 'ISIN' ? 'primary' : 'secondary'}
          startIcon={<Icon.Add data-testid={createTestid('icon-add')} />}
          size="medium"
          data-testid={createTestid('button-add')}
          onClick={onOpenCreateModal}
        >
          {variant === 'ISIN' ? 'Create list' : 'Create portfolio scope'}
        </Button>
      </Stack>
    </AppMargin>
  );
};

export const IsinListController = makeForm(IsinListControllerComp);
