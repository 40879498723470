import { TextField, makeForm, useTestid } from 'mns-components';
import type { PayRequestSteps } from './createRequest.types';
import { formStyles as useStyles } from './formStyles';

export type ReplyBillForm = PayRequestSteps[1];

type BillInputsProps = {
  'data-testid': string;
};

const BillInputs: React.FC<BillInputsProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.form} data-testid={testid}>
      <h3 className={classes.formTitle}>
        <span>Billing details</span>
        <hr />
      </h3>
      <label>
        <span>Company:</span>
        <TextField
          type="text"
          variant="outlined"
          name="company"
          placeholder="Name of the company invoiced"
          required
          data-testid={createTestid('company')}
        />
      </label>
      <label>
        <span>First name/Last name:</span>
        <TextField
          type="text"
          variant="outlined"
          name="billedTo"
          placeholder="Name of the customer invoiced"
          required
          data-testid={createTestid('billedTo')}
        />
      </label>
      <label>
        <span>Function:</span>
        <TextField
          type="text"
          variant="outlined"
          name="function"
          placeholder="Function of the customer invoiced"
          required
          data-testid={createTestid('function')}
        />
      </label>
      <label>
        <span>Address:</span>
        <TextField
          type="text"
          variant="outlined"
          name="address"
          placeholder="Invoice address"
          required
          data-testid={createTestid('address')}
        />
      </label>
      <label>
        <span>Intra-community VAT:</span>
        <TextField
          type="text"
          variant="outlined"
          name="vat"
          placeholder="Kind of VAT applied on the invoice"
          required
          data-testid={createTestid('vat')}
        />
      </label>
    </div>
  );
};

export const BillForm = makeForm<ReplyBillForm, BillInputsProps>(BillInputs);
