import { CLOUDFRONT_DNSNAME } from '../../app.constants';
import { wait } from '../../common/date';

export const waitFor = async (cb: AnyFunction, untilTimestamp = Date.now() + 10000) => {
  let lastErr;
  while (untilTimestamp === 0 || untilTimestamp > Date.now()) {
    try {
      await cb();
      return;
    } catch (err) {
      lastErr = err;
      await wait(10);
    }
  }
  throw lastErr;
};

export const waitForNode = (querySelector: string, untilTimestamp?: number) =>
  waitFor(() => {
    const node = document.querySelector(querySelector);
    if (node === null) throw new Error(`Can not find ${JSON.stringify(querySelector)} element`);
  }, untilTimestamp);

/** @warn should be removed when Back is ready to store assets */
export const getAsset = (scenarioName: string, fileName: string) =>
  `//${CLOUDFRONT_DNSNAME}/guidedTours/${scenarioName}/${fileName}`;
