import type { FormStepErrors, StepProps } from 'mns-components';
import {
  Backdrop,
  Button,
  ButtonList,
  EmptyState,
  Frame,
  makeStyles,
  useCallbackImmutable,
  useStepperButtons,
  useTestid,
} from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEetPortfoliosRequest, useUpdateEetPortfolios, useUploadModalOpen } from '../hooks';
import type { AvailableShareClassSimpleTableProps } from './AvailableShareClassSimpleTable';
import { AvailableShareClassSimpleTable } from './AvailableShareClassSimpleTable';
import { ConfirmMissingsModal } from './ConfirmMissingsModal';
import { MissingShareClassSimpleTable } from './MissingShareClassSimpleTable';
import { Spoiler } from './Spoiler';
import type { GenerateEetFormSteps } from './types';

const useStyles = makeStyles({
  portfoliosStep: {
    padding: '2rem',
    '& > :not(:first-child)': {
      marginTop: '2rem',
    },
  },
  buttons: {
    textAlign: 'right',
  },
});

export const validSelectPortfoliosStep = ({
  portfolios,
}: GenerateEetFormSteps[2]): FormStepErrors<GenerateEetFormSteps[2]> => {
  if (!Array.isArray(portfolios)) {
    return [
      {
        name: 'portfolios',
        message: 'Invalid format',
      },
    ];
  }
  return null;
};

type SelectPortfoliosStepProps = StepProps<GenerateEetFormSteps, 2>;

export const SelectPortfoliosStep: React.FC<SelectPortfoliosStepProps> = ({
  setStepValue,
  stepValue,
  goPrevious,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { eetId } = useParams<{ eetId: string }>();

  const [displayMissings, setDisplayMissings] = useState(true);
  const [displayAvailable, setDisplayAvailable] = useState(true);
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [, setErrors] = useState<FormStepErrors<GenerateEetFormSteps[2]> | null>(null);

  const onUploadPortfolio = useUploadModalOpen();

  const { data: portfolios } = useEetPortfoliosRequest(eetId);
  const {
    mutate: updatePortfolios,
    isLoading: isUpdatingPortfolios,
    isSuccess: isUpdatedPortfolios,
    error: errorUpdatePortfolios,
  } = useUpdateEetPortfolios(eetId);

  const [initPortfolios, setInitPortfolios] = useState<string[]>();
  useEffect(() => {
    if (stepValue.portfolios.length) {
      setInitPortfolios(stepValue.portfolios.map((item) => item.externalId));
    } else if (portfolios) {
      setInitPortfolios(portfolios.alreadyUploadedPortfolios.map((item) => item.externalId));
      setStepValue({ portfolios: portfolios.alreadyUploadedPortfolios });
    }
  }, [stepValue, setStepValue, portfolios?.alreadyUploadedPortfolios, portfolios]);

  const handleSelection = useCallbackImmutable<Required<AvailableShareClassSimpleTableProps>['onSelectionChange']>(
    (externalIdList) => {
      setStepValue({
        portfolios: externalIdList.reduce((acc, item) => {
          const found = portfolios?.alreadyUploadedPortfolios.find((ptf) => ptf.externalId === item);
          if (found) acc.push(found);
          return acc;
        }, [] as EetApi.UploadedPortfolio[]),
      });
    },
  );

  const handleNext = useCallbackImmutable(async () => {
    setOpenConfirm(false);
    const catched = validSelectPortfoliosStep(stepValue);
    const ptfs = stepValue.portfolios;
    if (!catched?.length) {
      updatePortfolios(ptfs);
    } else {
      setErrors(catched);
    }
  });

  const handleConfirm = useCallbackImmutable(() => {
    if (portfolios?.missingPortfolios.length) {
      setOpenConfirm(true);
    } else {
      handleNext();
    }
  });

  // when updated, go next step
  useEffect(() => {
    if (isUpdatedPortfolios) {
      goNext();
    }
  }, [isUpdatedPortfolios, goNext]);

  // when update fails, display errors
  useEffect(() => {
    if (errorUpdatePortfolios) {
      setErrors([
        {
          name: 'portfolios',
          message: 'Unexpected error',
        },
      ]);
    }
  }, [errorUpdatePortfolios]);

  const handleCloseConfirmModal = useCallbackImmutable(() => setOpenConfirm(false));

  const buttons = useStepperButtons({
    onNext: handleConfirm,
    onPrevious: goPrevious,
    previousLabel: 'Back',
    nextLoading: isUpdatingPortfolios,
    'data-testid': createTestid('button'),
  });

  if (!portfolios) {
    return (
      <>
        <Backdrop data-testid={createTestid('backdrop')} />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </>
    );
  }

  if (!Array.isArray(portfolios.alreadyUploadedPortfolios) || !Array.isArray(portfolios.missingPortfolios)) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="warning"
          title="Unable to load portfolios"
          firstParagraph="The request ended with error, please try to load it again or contact administrator"
          data-testid={createTestid('error-ptf')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  const missingCount = portfolios.missingPortfolios.length ?? 0;
  const availableCount = portfolios.alreadyUploadedPortfolios.length ?? 0;
  const allCount = missingCount + availableCount;

  return (
    <Frame variant="margined" data-testid={testid}>
      <div className={classes.portfoliosStep}>
        {portfolios.missingPortfolios.length ? (
          <Spoiler
            title="Missing portfolios you need to upload"
            info={`${missingCount} missing out of ${allCount}`}
            collapse={!displayMissings}
            onToggleCollapse={setDisplayMissings}
            data-testid={createTestid('missing-spoiler-button')}
          >
            <div className={classes.buttons}>
              <Button
                color="primary"
                outlined
                variant="outlined"
                onClick={onUploadPortfolio}
                disabled={!portfolios.missingPortfolios.length}
                data-testid={createTestid('button-upload')}
              >
                Upload Missing Portfolios
              </Button>
            </div>
            <MissingShareClassSimpleTable
              rows={portfolios.missingPortfolios}
              data-testid={createTestid('missing-list')}
            />
          </Spoiler>
        ) : null}
        {initPortfolios && portfolios.alreadyUploadedPortfolios.length ? (
          <Spoiler
            title="Portfolios collected via Manaos platform"
            info={`${availableCount} collected out of ${allCount}`}
            collapse={!displayAvailable}
            onToggleCollapse={setDisplayAvailable}
            data-testid={createTestid('collected-spoiler-button')}
          >
            <AvailableShareClassSimpleTable
              rows={portfolios.alreadyUploadedPortfolios}
              selection={initPortfolios}
              onSelectionChange={handleSelection}
              data-testid={createTestid('collected-list')}
            />
          </Spoiler>
        ) : null}
      </div>
      <ConfirmMissingsModal
        onClose={handleCloseConfirmModal}
        isOpen={isOpenConfirm}
        onConfirm={handleNext}
        data-testid={createTestid('missing-modal')}
      />
      <Buttons>
        <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
      </Buttons>
    </Frame>
  );
};
