import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateLocal,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
} from 'mns-components';
import type { IsinListApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { IsinListActions } from './IsinListActions';

const getActionColDef = makeActionColDef<IsinListApi.List>();

type UseColumnsProps = Pick<IsinListProps, 'onEdit' | 'onDelete' | 'variant' | 'data-testid'>;

const useColumns = ({ onEdit, onDelete, variant, 'data-testid': testid }: UseColumnsProps) =>
  useMemo(
    (): AggridTableColumn<IsinListApi.List>[] => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        maxWidth: 350,
      },
      {
        field: 'createdAt',
        headerName: 'Created on',
        flex: 1,
        maxWidth: 350,
        cellRendererFramework: ({ value }) => convertToDateLocal(value),
      },
      variant === 'ISIN'
        ? {
            field: 'createdBy.name',
            headerName: 'Created by',
            flex: 4,
          }
        : {
            field: 'funds',
            headerName: 'Portfolios',
            flex: 4,
            cellRendererFramework: ({ value }) => value.length,
          },
      getActionColDef('id', 120, ({ value }) =>
        IsinListActions({ isinListId: value, onEdit, onDelete, 'data-testid': `${testid}-${value}` }),
      ),
    ],
    [variant, onEdit, onDelete, testid],
  );

type IsinListProps = {
  search: string;
  rows: IsinListApi.List[];
  onEdit?(isinListId: string): void;
  onDelete?(isinListId: string): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

export const IsinList: React.FC<IsinListProps> = ({
  search,
  rows,
  onEdit,
  onDelete,
  variant,
  'data-testid': testid,
}) => (
  <AggridTable
    quickFilterText={search}
    rowData={rows}
    defaultColDef={useDefaultColDef<IsinListApi.List>()}
    columnDefs={useColumns({ onEdit, onDelete, variant, 'data-testid': testid })}
    getRowNodeId={useImmutable(() => (row: IsinListApi.List) => row.id)}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
