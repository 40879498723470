import { colors, makeStyles } from 'mns-components';

export const selectInputFileStyles = makeStyles({
  pickInputFileForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '0 2rem',

    '& > :not(:first-child)': {
      marginLeft: '2rem',
    },

    'label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '.MuiFormControl-root': {
      width: '100%',
    },
  },
  pickInputFile: {},
  infoEmt: {
    flex: '2 0 20rem',
    padding: '0 1rem',
    background: colors.lightGreyColor,

    '& dl > div': {
      display: 'flex',
      '& > dt': {
        width: '10rem',
      },
    },
  },
  infoHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0 0 0',
  },
  buttonList: {
    display: 'flex',
    padding: '2rem 0',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
});
