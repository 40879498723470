import { makeStyles, mediaScreenSizes, colors, gradient } from 'mns-components';

export const authPageLayoutStyles = makeStyles({
  authPage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    background: colors.whiteColor,
    fontSize: '1rem',
    textAlign: 'left',
    zIndex: 1200,
  },
  formSide: {
    flex: '1 1 40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: colors.whiteColor,
    overflow: 'clip auto',
    '& > div': {
      width: '22rem',
      maxHeight: '100%',
    },
  },
  infoSide: {
    flex: '1 1 60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: gradient.gradientPink,
    '& p': {
      fontSize: '2.375rem',
      color: colors.whiteColor,
      lineHeight: '3.625rem',
      margin: 'auto 5rem',
    },
    [mediaScreenSizes.tiny]: {
      display: 'none',
    },
  },
});
