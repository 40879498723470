import { useLoggout } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';

export const Logout: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  useLoggout();

  return (
    <AuthPageLayout data-testid={testid}>
      <h1 className={classes.title}>We are logging you out!</h1>
    </AuthPageLayout>
  );
};
