import { Stack } from '@mui/material';
import type {
  CheckboxItemType,
  DatapointsPickerDatapointOptionGroups,
  DatapointsPickerViewOption,
} from 'mns-components';
import {
  EmptyStateV2,
  DatapointsPicker,
  LoadingCircle,
  Typo,
  colors,
  filterTruthy,
  lowerCase,
  objectFilter,
  objectKeys,
  objectReduce,
  uniqueBy,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getApplicationConfig, getApplicationPipeTitle } from '../../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../../common/getImageContent';
import { useQueriesMemo } from '../../../../common/useDownloadFile';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { isAppCode } from '../../../../components/views/appDescriptions';
import { useFetchMetadataSources, useFetchMetadataSourcesDatapoints } from '../../hooks';
import type { TemplateFormData } from './SelectTemplate';

export const portfolioCode = 'tptv6';

export const mandatoryEsgSource: DataExtractorApi.Metadata.Source = {
  code: portfolioCode,
  name: portfolioCode,
  origins: [portfolioCode],
  categories: ['Portfolio Characteristics and valuation'],
  description: 'Identification of the fund or share class',
  hasDatapointPivot: true,
  datapointPivotCodes: ['identification_code_of_the_instrument_14'],
  isVisible: true,
  type: 'LOOKTHROUGH',
};

/**
 * Get datapoints indexed by templateId.
 * It is helpful for reducing component refresh by comparing new templates just loaded (useQueries returns a changing list).
 * @param sourceList the template Ids that should be loaded.
 * @returns
 */
const useEsgSourcesDatapoints = (rawSourceList: string[], esgSources: DataExtractorApi.Metadata.Source[]) => {
  const sourceList = filterTruthy(
    rawSourceList.map((appCode) => esgSources.find(({ name }) => name === appCode)?.code),
  );
  const sourcesDatapointsResult = useQueriesMemo(useFetchMetadataSourcesDatapoints(sourceList));
  const [sourcesDatapoints, setSourcesDatapoints] = useState<AnyObject<string, DataExtractorApi.Metadata.Datapoint[]>>(
    {},
  );

  useEffect(() => {
    setSourcesDatapoints((current) => {
      const currentSourceCodes = objectKeys(current);
      const newSourceCodes = filterTruthy(sourcesDatapointsResult.data.map((data) => data?.[0]?.sourceCode));
      if (newSourceCodes.find((code) => !currentSourceCodes.includes(code))) {
        return sourcesDatapointsResult.data.reduce((acc, data) => {
          const sourceCode = data?.[0]?.sourceCode;
          const appCode = esgSources.find(({ code }) => code === sourceCode)?.name;
          if (appCode && data) acc[appCode] = data;
          return acc;
        }, {} as AnyObject<string, DataExtractorApi.Metadata.Datapoint[]>);
      }
      return current;
    });
  }, [esgSources, sourcesDatapointsResult]);

  return sourcesDatapoints;
};

const select = (sources: DataExtractorApi.Metadata.Source[]) => [
  // source type 'ESG_ANALYSIS' or 'LOOKTHROUGH' is a datapoint provider, other source type is a computed source (datamart) and should not be displayed
  ...sources.filter((source) => source.type === 'ESG_ANALYSIS' || source.type === 'LOOKTHROUGH'),
  // insert 'tptv6' (duplicates are removed)
  mandatoryEsgSource,
];

type TemplateEsgDatapointsProps = {
  disabled?: boolean;
  requiredEsgDatapoints: AnyObject<string, string[] | undefined>;
  setRequiredEsgDatapoints: React.Dispatch<React.SetStateAction<AnyObject<string, string[] | undefined>>>;
  'data-testid': string;
};

export const TemplateEsgDatapoints: React.FC<TemplateEsgDatapointsProps> = ({
  disabled,
  requiredEsgDatapoints,
  setRequiredEsgDatapoints,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const methods = useFormContext<TemplateFormData>();
  const appBgClasses = useAppBackgroundStyles();
  const { templateType, view, search } = methods.watch();
  const { setValue } = methods;
  const [sourceList, setSourceList] = useState<string[]>([portfolioCode]);

  const { data: esgSources = [mandatoryEsgSource] } = useFetchMetadataSources({ select });

  const esgSourcesDatapoints = useEsgSourcesDatapoints(sourceList, esgSources);

  const viewOptions = useMemo(
    (): DatapointsPickerViewOption[] | undefined =>
      uniqueBy(esgSources, 'name')
        ?.sort((a, b) => {
          if (a.name === portfolioCode) return -1;
          if (b.name === portfolioCode) return 1;
          return a.name.localeCompare(b.name);
        })
        .map((source) => {
          const appCode = source.name;
          const origins = source.origins.map((o) => {
            if (o === portfolioCode) return 'Portfolios';
            if (isAppCode(o)) return getApplicationConfig(o).company.name;
            return o;
          });
          const tags = source.categories ? filterTruthy([...origins, ...source.categories]) : origins;
          if (appCode === portfolioCode) {
            return {
              title: 'Portfolios',
              value: appCode,
              createdBy: 'Manaos',
              createdAt: source.createdAt,
              tags,
            };
          } else if (isAppCode(appCode)) {
            return {
              title: getApplicationPipeTitle(appCode),
              value: appCode,
              createdBy: 'Manaos',
              createdAt: source.createdAt,
              tags,
            };
          } else {
            return {
              title: source.code,
              value: appCode,
              createdBy: 'Manaos',
              createdAt: source.createdAt,
              tags,
            };
          }
        }),
    [esgSources],
  );

  const datapointOptionGroups = useMemo(
    () =>
      objectReduce(
        esgSourcesDatapoints,
        (acc, result, appCode) => {
          const [first] = result;
          if (first) {
            const source = esgSources?.find(({ name }) => name === appCode);
            if (source) {
              acc[appCode] = uniqueBy(result, 'name').map((row): CheckboxItemType => {
                const foundAppCode = row.origins.find((o) => isAppCode(o)) as AppCode | undefined;
                if (foundAppCode) {
                  const bgClass = appBgClasses[foundAppCode];
                  return {
                    label: row.name,
                    value: row.name,
                    required: row.required,
                    description: row.description,
                    backgroundClassname: bgClass,
                  };
                }
                return {
                  label: row.name,
                  value: row.name,
                  required: row.required,
                  description: row.description,
                  iconName: 'portfolios',
                };
              });
            }
          }
          return acc;
        },
        {} as DatapointsPickerDatapointOptionGroups,
      ),
    [appBgClasses, esgSources, esgSourcesDatapoints],
  );

  useEffect(() => {
    setRequiredEsgDatapoints((current) => {
      const requiredDps = objectReduce(
        esgSourcesDatapoints,
        (acc, list, sourceCode) => {
          const dps = list.filter(({ required }) => required).map(({ name }) => name);
          if (dps.length) acc[sourceCode] = dps;
          return acc;
        },
        {} as AnyObject<string, string[]>,
      );

      if (
        objectKeys(
          objectFilter(requiredDps, (list, sourceCode) => {
            const currentList = current[sourceCode];
            if (!currentList) return true;
            else return !!list.find((name) => !currentList.includes(name));
          }),
        ).length
      ) {
        return requiredDps;
      }

      return current;
    });
  }, [setRequiredEsgDatapoints, esgSources, esgSourcesDatapoints]);

  // INFO: temporary replaced https://bivwak.atlassian.net/browse/MNS-11110
  // get only datapoints which are matching with search
  // const datapointOptionGroupsFiltered = useMemo(
  //   () =>
  //     search
  //       ? objectMap(datapointOptionGroups, (list, sourceCode) => {
  //           const source = esgSources?.find(({ code }) => code === sourceCode);
  //           if (source?.name.includes(search)) {
  //             return list;
  //           } else {
  //             const dps = list?.filter((dp) => dp.label.includes(search));
  //             if (dps?.length) {
  //               return dps;
  //             }
  //           }
  //           return [];
  //         })
  //       : datapointOptionGroups,
  //   [datapointOptionGroups, search, esgSources],
  // );

  // const viewOptionsFiltered = useMemo(
  //   () => viewOptions?.filter(({ value }) => datapointOptionGroupsFiltered[value]?.length !== 0),
  //   [datapointOptionGroupsFiltered, viewOptions],
  // );

  const viewOptionsFiltered = useMemo(() => {
    const lowerSearch = lowerCase(search);
    return viewOptions?.filter(({ title }) => lowerCase(title).includes(lowerSearch));
  }, [search, viewOptions]);

  // when view changes, load its datapoints
  useEffect(() => {
    if (view) {
      setSourceList((list) => {
        if (list.includes(view)) return list;
        const current = list.slice();
        current.push(view);
        return current;
      });
    }
  }, [view]);

  useEffect(() => {
    setValue('datapoints', requiredEsgDatapoints);
    setValue('matchTemplateId', null);
  }, [setValue, templateType, requiredEsgDatapoints]);

  if (!viewOptionsFiltered?.length) {
    return (
      <EmptyStateV2
        variant="info"
        useCase="noSearchResults"
        title="There is no match with your research!"
        subTitle="You can search again with new criteria."
        illustrationVariant="iconType"
        data-testid={createTestid('empty-searchResult')}
      />
    );
  }

  return (
    <Stack minHeight="200px" height="50vh">
      <DatapointsPicker
        variant="checkbox"
        datapointName="datapoints"
        datapointOptionGroups={datapointOptionGroups}
        viewName="view"
        viewOptions={viewOptionsFiltered}
        datapointPlaceholder={
          <Stack justifyContent="center" alignItems="center" height="100%" color={colors.primaryColor} gap="1rem">
            <LoadingCircle size="medium" data-testid={createTestid('datapoints-backdrop')} />
            <Typo variant="body1medium" color={colors.defaultFontColor}>
              Loading in progress
            </Typo>
          </Stack>
        }
        disabled={disabled}
        data-testid={testid}
      />
    </Stack>
  );
};
