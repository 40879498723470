import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { EmptyStateV2Props, SplitButtonProps } from 'mns-components';
import {
  EmptyStateV2,
  AppMargin,
  Backdrop,
  Button,
  Icon,
  PeriodPicker,
  SearchField,
  SplitButton,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useState } from 'react';
import { firstDateAvailable, getPreviousTwoMonthLastDate, now, previousTwoMonthLastDate } from '../../../common/date';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { useFilesRequest } from '../hooks';
import { FilesList } from './FilesList';
import { DialogAuditErrorLog } from './modals/DialogAuditErrorLog';

type AuditFilesProps = {
  searchValue: string;
  pickerStartDate: Date;
  pickerEndDate: Date;
  refreshDelay?: number;
  setSelectedRows: (rows: CollectApi.File[]) => void;
  setGridApi: (api: GridApi | null) => void;
  onOpenModal(): void;
  openErrorLogModal: (fileName: string, processID: string) => void;
  buttonVariant: EmptyStateV2Props['buttonVariant'];
  'data-testid': string;
};

const AuditFiles: React.FC<AuditFilesProps> = ({
  pickerStartDate,
  pickerEndDate,
  searchValue,
  refreshDelay,
  setSelectedRows,
  setGridApi,
  onOpenModal,
  openErrorLogModal,
  buttonVariant,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const {
    data: filesData,
    error: filesError,
    isLoading: isLoadingFiles,
  } = useFilesRequest(
    {
      startDate: pickerStartDate,
      endDate: pickerEndDate,
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: refreshDelay,
    },
  );

  if (filesData === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!isLoadingFiles && (!filesData || filesData.length === 0)) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="noSearchResults"
          title="There is no file on this upload date period yet!"
          subTitle="You can search again with a new range period or upload a new one."
          buttonText="Upload file"
          buttonIcon="upload"
          buttonVariant={buttonVariant}
          buttonOnClick={onOpenModal}
          data-testid={createTestid('error-empty')}
        />
      </Stack>
    );
  }

  if (filesError) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team"
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  return (
    <FilesList
      searchValue={searchValue}
      rowData={filesData}
      onRowSelected={setSelectedRows}
      getGridApiref={setGridApi}
      openErrorLogModal={openErrorLogModal}
      data-testid={createTestid('list')}
    />
  );
};

const downloadOptions: { label: string; value: CollectApi.FileType }[] = [
  { label: 'Error Log', value: 'error_log' },
  { label: 'Input File', value: 'input' },
  { label: 'Processed File', value: 'output' },
];

type Props = {
  refreshDelay?: number;
  buttonVariant: EmptyStateV2Props['buttonVariant'];
  onOpenModal(): void;
  'data-testid': string;
};

export const Audit: React.FC<Props> = ({
  refreshDelay = 10000,
  onOpenModal,
  buttonVariant,
  'data-testid': testid,
}: Props) => {
  const createTestid = useTestid(testid);

  const [searchValue, setSearchValue] = useState('');

  const [pickerStartDate, setPickerStartDate] = useState<Date>(previousTwoMonthLastDate);
  const [pickerEndDate, setPickerEndDate] = useState<Date>(now);
  const [selectedRows, setSelectedRows] = useState<CollectApi.File[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [errorLogDialogOpen, setErrorLogDialogOpen] = useState<boolean>(false);
  const [errorLogFileData, setErrorLogFileData] = useState<{ fileName: string; processID: string }>({
    fileName: '',
    processID: '',
  });

  const handleChange = useCallbackImmutable(([start, end]: [Date | null, Date | null]) => {
    const startDate = start ?? getPreviousTwoMonthLastDate(end ?? now);
    setPickerStartDate(startDate);
    setPickerEndDate(end ?? now);
  });

  const downloadPresignedUrl = useDownloadPresignedUrl();

  const handleClickDropdown = useCallbackImmutable((_, dlType: CollectApi.FileType): void => {
    selectedRows.forEach((data) => {
      const dlLabel = downloadOptions.find((assoc) => assoc.value === dlType)!.label;
      downloadPresignedUrl({
        fileDownloadType: dlType,
        fileDownloadTypeLabel: dlLabel,
        processId: data.processId,
        fileName: data.name,
      });
    });
  });

  const handleOpenErrorLogDialog = useCallbackImmutable((fileName: string, processID: string) => {
    setErrorLogFileData({ fileName, processID });
    setErrorLogDialogOpen(true);
  });
  const handleCloseErrorLogDialog = useCallbackImmutable(() => setErrorLogDialogOpen(false));

  const handleClickDownload = useCallbackImmutable(() => handleClickDropdown(null, 'output'));

  const color = selectedRows.length ? 'primary' : 'disabled';
  const options = downloadOptions.map((opt): SplitButtonProps['options'][number] => ({
    label: opt.label,
    value: opt.value,
  }));

  return (
    <>
      <AppMargin>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="1rem" flexWrap="wrap">
          <Stack flexGrow={1}>
            <SearchField
              value={searchValue}
              onChange={setSearchValue}
              disableMargin
              data-testid={createTestid('search')}
            />
          </Stack>
          <PeriodPicker
            start={pickerStartDate}
            end={pickerEndDate}
            onFieldChange={handleChange}
            minStartDate={firstDateAvailable}
            minEndDate={firstDateAvailable}
            disableFuture
            disableMargin
            label="Upload period"
            variant="inline"
            data-testid={testid}
          />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="1rem" flexGrow={1}>
            <Button
              startIcon={<Icon name="download" data-testid={createTestid('icon-export')} />}
              color="primary"
              outlined
              onClick={useCallbackImmutable(() =>
                gridApi?.exportDataAsExcel({
                  columnKeys: [
                    'name',
                    'dataFormat',
                    'uploadedOn',
                    'uploadAuthor',
                    'status',
                    'uploadSource',
                    'qualities',
                  ],
                }),
              )}
              size="medium"
              data-testid={createTestid('button-export')}
            >
              Export table
            </Button>
            <SplitButton
              label="Download"
              options={options}
              onClickDropdown={handleClickDropdown}
              onClickButton={handleClickDownload}
              disabled={!selectedRows.length}
              size="medium"
              icon="download"
              color={color}
              title="Download selected items"
              data-testid={createTestid('dropdown-download')}
              data-stepid="uploads-dropdown-download"
            />
          </Stack>
        </Stack>
      </AppMargin>
      <AuditFiles
        searchValue={searchValue}
        setSelectedRows={setSelectedRows}
        setGridApi={setGridApi}
        onOpenModal={onOpenModal}
        openErrorLogModal={handleOpenErrorLogDialog}
        refreshDelay={refreshDelay}
        pickerEndDate={pickerEndDate}
        pickerStartDate={pickerStartDate}
        buttonVariant={buttonVariant}
        data-testid={testid}
      />
      <DialogAuditErrorLog
        isOpenModal={errorLogDialogOpen}
        errorLogFileData={errorLogFileData}
        onClose={handleCloseErrorLogDialog}
        data-testid={createTestid('error-log')}
      />
    </>
  );
};
