import { makeStyles } from 'mns-components';

export const disseminationCommonStyles = makeStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    right: '3.125rem',
    top: '15.625rem',
  },
  searchContainer: {
    position: 'static',
    padding: '0.5rem 0.5rem 0 0.5rem',
    width: '15.625rem',
    display: 'block',
    margin: '0 auto auto 0',
  },
  gridContainer: {
    margin: '2rem',
    background: 'white',
    padding: '0',
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    borderRadius: '5px',

    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    minHeight: 0,
  },
});
