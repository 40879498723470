import type { GridApi } from 'ag-grid-community';
import {
  Backdrop,
  Button,
  EmptyState,
  Icon,
  PeriodPicker,
  SearchField,
  SelectFiltersMulti,
  convertToDateUTC,
  joinCase,
  objectHas,
  spinalCase,
  useCallbackImmutable,
  useImmutable,
  useMemoBase,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTemplates } from '../../../api/templateData';
import { toastUpdateOrCreate } from '../../../common/toast';
import { downloadFile } from '../../../common/utils';
import { dataExtractorApi } from '../../../store/apis';
import { useCreateExtraction, useReports } from '../../esgApp/hooks';
import { useOpenCreateExtractionModal } from '../hooks';
import type { ActionClickHandler } from './ExtractionsGeneratedList';
import { ExtractionsGeneratedList } from './ExtractionsGeneratedList';
import { extractionsGeneratedStyles as useStyles } from './styles/extractionsGeneratedStyles';

const onDownloadExtraction: ActionClickHandler = async (row) => {
  try {
    const { reportPresignedUrl } = await dataExtractorApi.report.getReport(row.id);
    await downloadFile(
      reportPresignedUrl,
      `${spinalCase(row.extractionName)}_${joinCase(row.portfolio.externalIdValue)}_${spinalCase(
        row.portfolio.valuationDate,
      )}.csv`,
    );
    toast.success(`The extraction file download succeed`);
  } catch {
    toast.error("The extraction file couldn't be downloaded");
  }
};

const onDownloadLogs: ActionClickHandler = async (row) => {
  try {
    const { logsPresignedUrl } = await dataExtractorApi.report.getReport(row.id);
    await downloadFile(
      logsPresignedUrl,
      `${spinalCase(row.extractionName)}_${joinCase(row.portfolio.externalIdValue)}_${spinalCase(
        row.portfolio.valuationDate,
      )}_EXECUTIONLOG.csv`,
    );
    toast.success(`The execution log file download succeed`);
  } catch {
    toast.error("The execution log file couldn't be downloaded");
  }
};

const statusList = [
  { label: 'complete', value: 'COMPLETE' },
  { label: 'processing', value: 'IN_PROGRESS' },
  { label: 'failed', value: 'FAILED' },
];

const statusAlsoMatchWith = {
  IN_PROGRESS: ['REQUESTED'],
};

const settledStatus = ['COMPLETE', 'FAILED'];

export type ExtractionsGeneratedPageProps = {
  'data-testid': string;
};

export const ExtractionsGeneratedPage: React.FC<ExtractionsGeneratedPageProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const gridApi = useRef<GridApi | null>(null);
  const onOpenCreateExtractionModal = useOpenCreateExtractionModal();
  const [extractionsDatePeriodStart, setExtractionsDatePeriodStart] = useState<Date | null>(null);
  const [extractionsDatePeriodEnd, setExtractionsDatePeriodEnd] = useState<Date | null>(null);
  const [[minExtractionDate, maxExtractionDate], setMinMaxExtractionsDate] = useState<
    [Date, Date] | [undefined, undefined]
  >([undefined, undefined]);

  // form
  const methods = useForm({
    defaultValues: {
      search: '',
      extractionStatus: [] as string[],
      extractionDatePeriod: [] as unknown as [Date, Date],
    },
  });
  const { watch } = methods;
  const [search = '', extractionStatus = []] = watch(['search', 'extractionStatus']);

  const resetSearchField = useCallbackImmutable(() => methods.resetField('search'));

  const {
    data: templatesMap,
    isError: isErrorTemplates,
    isLoading: isLoadingTemplates,
  } = useTemplates<Record<string, DataExtractorApi.Template.TemplateLight>>({
    select: (data) =>
      data.reduce((acc, template) => {
        acc[template.id] = template;
        return acc;
      }, {} as Record<string, DataExtractorApi.Template.TemplateLight>),
  });

  // requests
  const {
    data: extractions,
    isError: isErrorReports,
    isLoading: isLoadingReports,
  } = useReports({
    onSuccess: (data) => {
      if (data?.length) {
        const sortedExtractions = data
          .map(({ createdAt }) => new Date(createdAt))
          .sort((a, b) => a.valueOf() - b.valueOf());
        const firstDate = sortedExtractions[0];
        const lastDate = sortedExtractions[sortedExtractions.length - 1];

        setMinMaxExtractionsDate([firstDate, lastDate]);
        setExtractionsDatePeriodStart(firstDate);
        setExtractionsDatePeriodEnd(lastDate);
      }

      return data;
    },
    refetchIntervalInBackground: true,
    refetchInterval: (data) => (!data || data.find((item) => !settledStatus.includes(item.status)) ? 3000 : false),
  });
  const { mutateAsync: createExtraction } = useCreateExtraction();

  const onRetryExtraction: ActionClickHandler = useCallbackImmutable(async (row) => {
    const toastId = toast.info('The extraction is being proccessed');
    try {
      const extractionDetails = await dataExtractorApi.extraction.getExtraction(row.idExtraction);
      await createExtraction({
        templateId: extractionDetails.templateId,
        extractionType: extractionDetails.extractionType,
        extractionName: extractionDetails.extractionName,
        extractionFeed: extractionDetails.extractionFeed,
        extractionScope: extractionDetails.extractionScope,
      });
      return toastUpdateOrCreate(toastId, {
        type: 'success',
        render: 'The extraction retry succeed',
      });
    } catch {
      // do nothing
    }
    toastUpdateOrCreate(toastId, {
      type: 'error',
      render: "The extraction couldn't be retryed",
    });
  });

  // table
  const onExportAll = useImmutable(() => () => gridApi.current?.exportDataAsExcel());

  const displayedExtractions = useMemoBase(
    { maxExtractionDate, minExtractionDate },
    (base) => {
      let filteredExtractions = extractions?.slice();
      if (filteredExtractions?.length && base.minExtractionDate && base.maxExtractionDate) {
        // is extraction status selected?
        if (extractionStatus.length) {
          filteredExtractions = filteredExtractions.filter(
            (extraction) =>
              extractionStatus.filter(
                (status: string) =>
                  status.includes(extraction.status) ||
                  (objectHas(statusAlsoMatchWith, status) && statusAlsoMatchWith[status].includes(extraction.status)),
              ).length,
          );
        }

        // is extraction creation date between period picker?
        const minDate = convertToDateUTC(extractionsDatePeriodStart!);
        const maxDate = convertToDateUTC(extractionsDatePeriodEnd!);
        if (
          minDate !== convertToDateUTC(base.minExtractionDate) ||
          maxDate !== convertToDateUTC(base.maxExtractionDate)
        ) {
          filteredExtractions = filteredExtractions.filter((extraction) => {
            const createdAt = convertToDateUTC(extraction.createdAt);
            return createdAt >= minDate && createdAt <= maxDate;
          });
        }
      }
      return filteredExtractions;
    },
    [extractions, extractionStatus, extractionsDatePeriodEnd, extractionsDatePeriodStart, templatesMap],
  );

  if (isLoadingReports || isLoadingTemplates) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!isLoadingReports && !isLoadingTemplates && (isErrorReports || isErrorTemplates)) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to load extractions"
        firstParagraph="The request ended with error, please try to load it again or contact administrator"
        data-testid={createTestid('error-extractions')}
      />
    );
  }

  if (!extractions?.length) {
    return (
      <EmptyState
        title="No extraction available"
        firstParagraph="Start extracting your portfolios"
        firstButtonText="Create new extraction"
        firstButtonProps={{
          color: 'secondary',
          startIcon: <Icon.Extraction color="inversed" data-testid={createTestid('button-createExtraction')} />,
          'data-testid': createTestid('firstButton'),
        }}
        firstOnClickButton={onOpenCreateExtractionModal}
        data-testid={createTestid('no-extractions')}
      />
    );
  }

  return (
    <>
      <div className={classes.filtersWrapper}>
        <div className={classes.filtersContainer}>
          <FormProvider {...methods}>
            <SearchField
              value={search}
              name="search"
              onClear={resetSearchField}
              uncontrolled={false}
              data-testid={createTestid('search')}
            />
            <PeriodPicker
              name="extractionDatePeriod"
              label="Extraction date Period"
              variant="inline"
              onFieldStartChange={setExtractionsDatePeriodStart}
              onFieldEndChange={setExtractionsDatePeriodEnd}
              start={extractionsDatePeriodStart}
              end={extractionsDatePeriodEnd}
              minStartDate={minExtractionDate}
              maxStartDate={watch('extractionDatePeriod')[1]}
              minEndDate={watch('extractionDatePeriod')[0]}
              maxEndDate={maxExtractionDate}
              data-testid={createTestid('extractionDatePeriod')}
            />
            <SelectFiltersMulti
              FormControlProps={{ style: { marginLeft: 'auto' } }}
              name="extractionStatus"
              label="Status"
              variant="labeled"
              options={statusList}
              data-testid={createTestid('select-extractionStatus')}
            />
            <Button
              startIcon={<Icon.Download color="inversed" data-testid={createTestid('icon-export')} />}
              color="primary"
              size="medium"
              disabled={!extractions?.length}
              onClick={onExportAll}
              data-testid={createTestid('button-export')}
            >
              Export table
            </Button>
          </FormProvider>
        </div>
      </div>
      <ExtractionsGeneratedList
        quickFilterText={search}
        rowData={displayedExtractions}
        templatesMap={templatesMap}
        getGridApiRef={gridApi}
        onDownloadExtraction={onDownloadExtraction}
        onDownloadLogs={onDownloadLogs}
        onRetryExtraction={onRetryExtraction}
        data-testid={createTestid('list')}
      />
    </>
  );
};
