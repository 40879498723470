import { IconButton } from '@mui/material';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Icon,
  appendUTCToken,
  camelCase,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../../components/headerRender/QualitiesHeaderRenderer';

const getActionColDef = makeActionColDef<PortfolioHistoryWithPeriod>();

type DownloadCellProps = {
  value: string;
  onDownload(navDate: string): void;
  'data-testid': string;
};

const DownloadCell: React.FC<DownloadCellProps> = ({ value, onDownload, 'data-testid': testid }) => (
  <IconButton
    title="Download Output File"
    color="secondary"
    onClick={useCallbackImmutable(() => onDownload(value))}
    data-testid={`${testid}-${camelCase(value)}-follow`}
  >
    <Icon.Download data-testid={`${testid}-icon-download`} />
  </IconButton>
);

export type PortfolioHistoryWithPeriod = CollectApi.PortfolioHistory & { productionPeriod: string };

const useColumns = (onDownload: CollectHistoryListProps['onDownload'], testid: string) => {
  return useMemo(
    (): AggridTableColumn<PortfolioHistoryWithPeriod>[] => [
      {
        field: 'productionPeriod',
        headerName: 'Month',
      },
      {
        field: 'valuationDate',
        headerName: 'Nav date',
        sort: 'desc',
      },
      {
        field: 'receptionDate',
        headerName: 'First received on',
        valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(appendUTCToken(data.receptionDate)),
        cellRendererFramework: ({ data }) => convertToDateTimeLocal(appendUTCToken(data.receptionDate)),
      },
      {
        field: 'updateDate',
        headerName: 'Updated on (if applicable)',
        valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(appendUTCToken(data.updateDate)),
        cellRendererFramework: ({ data }) => convertToDateTimeLocal(appendUTCToken(data.updateDate)),
      },
      {
        field: 'qualities',
        headerName: 'Qualities',
        cellRendererFramework: ({ value, data: portfolioVersionId }) => (
          <QualitiesRenderer qualities={value} data-testid={`${testid}-${portfolioVersionId}-qualities`} />
        ),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
        minWidth: 100,
      },
      getActionColDef('valuationDate', 80, ({ value }) => DownloadCell({ value, onDownload, 'data-testid': testid })),
    ],
    [onDownload, testid],
  );
};

type CollectHistoryListProps = {
  onDownload(navDate: string): void;
  rows: PortfolioHistoryWithPeriod[];
  'data-testid': string;
};

export const CollectHistoryList: React.FC<CollectHistoryListProps> = ({ rows, onDownload, 'data-testid': testid }) => (
  <AggridTable
    rowData={rows}
    columnDefs={useColumns(onDownload, testid)}
    defaultColDef={useDefaultColDef<PortfolioHistoryWithPeriod>()}
    getRowNodeId={useImmutable(() => (row: PortfolioHistoryWithPeriod) => row.portfolioVersionId)}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
