import { Button, convertToDateLocal, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import useStyles from '../styles/DetailsPermissionsDialog.styles';

interface DetailsPermissionsDialogProps {
  selectedItems: CollectApi.AccessDemand[];
  closeModal: () => void;
  viewOrigin: string;
  'data-testid': string;
}

export const DetailsPermissionsDialog: React.FC<DetailsPermissionsDialogProps> = ({
  selectedItems,
  closeModal,
  viewOrigin,
  'data-testid': testid,
}: DetailsPermissionsDialogProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <>
      <div data-testid={createTestid('details-container')}>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Shareclass Id</span>
          <span className={classes.selectedItemValues}>{selectedItems[0].fundId ? selectedItems[0].fundId : '-'}</span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Shareclass name</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].fundName ? selectedItems[0].fundName : '-'}
          </span>
        </p>
        {viewOrigin === 'Collection' ? (
          <p className={classes.inlineFlex}>
            <span className={classes.selectedItemKeys}>Requested to</span>
            <span className={classes.selectedItemValues}>
              {selectedItems[0].receptor ? selectedItems[0].receptor : '-'}
            </span>
          </p>
        ) : (
          <p className={classes.inlineFlex}>
            <span className={classes.selectedItemKeys}>Requestor</span>
            <span className={classes.selectedItemValues}>
              {selectedItems[0].requestor ? selectedItems[0].requestor : '-'}
            </span>
          </p>
        )}
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Access start date</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].startPoint ? convertToDateLocal(selectedItems[0].startPoint) : '-'}
          </span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Access end date</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].endPoint ? convertToDateLocal(selectedItems[0].endPoint) : '-'}
          </span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Look-through level</span>
          <span className={classes.selectedItemValues}>{selectedItems[0].lookThroughLevel}</span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Frequency</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].sharingFrequency ? selectedItems[0].sharingFrequency : '-'}
          </span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Delivery deadline</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].deadline ? selectedItems[0].deadline : '-'}
          </span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Embargo delay</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].embargoDelay ? selectedItems[0].embargoDelay : '-'}
          </span>
        </p>
        <p className={classes.inlineFlex}>
          <span className={classes.selectedItemKeys}>Scope to share</span>
          <span className={classes.selectedItemValues}>
            {selectedItems[0].scopeToShare ? selectedItems[0].scopeToShare : '-'}
          </span>
        </p>
        <p className={classes.paragraph}>Comment</p>
        <p className={classes.commentArea}>{selectedItems[0].comment ? selectedItems[0].comment : '-'}</p>
      </div>
      <div className={classes.footerDialog}>
        <Button
          color="primary"
          type="submit"
          onClick={closeModal}
          data-testid={createTestid('button-close-dialog-details')}
          style={{ margin: '0 auto' }}
        >
          Close
        </Button>
      </div>
    </>
  );
};
