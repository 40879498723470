import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Stack } from '@mui/material';
import { Backdrop, Dialog, EmptyStateV2, SearchField, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useState } from 'react';
import { useEmitterAccessRequests } from '../../../hooks/useGlobalState';
import { DetailsPermissionsDialog } from './components/DetailsPermissionsDialog';
import { ManageAccessPermissions } from './components/ManageAccessPermissions';
import { EmitterPermissionList } from './EmitterPermissionList';
import useStyles from './styles/EmitterPermissions.styles';

type EmitterPermissionsProps = {
  filter?: string;
  'data-testid': string;
};

export const EmitterPermissions: React.FC<EmitterPermissionsProps> = ({ filter = '', 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { trackPageView } = useMatomo();
  const [selectedItems, setSelectedItems] = useState<CollectApi.AccessDemand[]>([]);
  const [isPermissionsDetailsModalOpen, setIsPermissionsDetailsModalOpen] = useState(false);
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const { data: authorizationList, refetch: loadEmitterAccessRequets } = useEmitterAccessRequests();

  const closeDetailsPermissionsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(false);
  });

  const openPermissionsDetailsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(true);
    setIsManagePermissionsModalOpen(false);
  });

  const openManagePermissionsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(false);
    setIsManagePermissionsModalOpen(true);
  });

  const closeManagePermissionsModal = useCallbackImmutable((): void => {
    loadEmitterAccessRequets();
    setSelectedItems([]);
  });

  useEffect(() => {
    trackPageView({
      documentTitle: `Collect App - Emitter Permissions`, // optional
    });
  }, [trackPageView]);

  if (authorizationList === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  // TODO: never create a let in component, replace it by a const or a useMemo
  let data;
  switch (filter) {
    case 'ACCESSES_TO_RELAUNCH':
    case 'PENDING':
      // PENDING & ACCESSES_TO_RELAUNCH Filters
      data = authorizationList.filter((s) => s.status === 'PENDING' || s.status === 'INVITATION_SENT');
      break;
    default:
      // ALL_ACCESSES
      data = authorizationList;
      break;
  }

  if (data.length === 0) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There are no access requests yet!"
          data-testid={createTestid('no-access')}
        />
      </Stack>
    );
  }

  return (
    <>
      <div className={classes.searchContainer}>
        <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} />
      </div>
      <EmitterPermissionList
        searchValue={searchValue}
        rowData={data}
        setSelectedItems={setSelectedItems}
        openManagePermissionsModal={openManagePermissionsModal}
        openPermissionsDetailsModal={openPermissionsDetailsModal}
        data-testid={createTestid('list')}
      />
      <ManageAccessPermissions
        selectedItems={selectedItems}
        onClose={closeManagePermissionsModal}
        isManagePermissionsOpen={isManagePermissionsModalOpen}
        data-testid={createTestid('permissions')}
      />
      <Dialog
        dialogTitle="Access request details"
        open={isPermissionsDetailsModalOpen}
        onClose={closeDetailsPermissionsModal}
        data-testid={createTestid('details-permissions')}
      >
        <DetailsPermissionsDialog
          selectedItems={selectedItems}
          closeModal={closeDetailsPermissionsModal}
          data-testid={createTestid('details-permissions-content')}
          viewOrigin="Collection"
        />
      </Dialog>
    </>
  );
};
