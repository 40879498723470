import { colors, makeStyles } from 'mns-components';

export const formStyles = makeStyles({
  formsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    margin: '1rem',
    padding: '1rem',
    borderRadius: '4px',
    background: '#fbfbfd',
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.14)',
  },

  form: {
    boxShadow: '0 0 2px 0 rgba(95,122,241,0.3)',
    background: colors.whiteColor,
    padding: '1rem',
    borderRadius: '4px',

    display: 'flex',
    flexDirection: 'column',

    '& > label': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& > span': {
        fontWeight: 400,
        fontSize: '.75rem',
        lineHeight: '2.5rem',
      },

      '& > div': {
        width: '15rem',
      },

      '& input': {
        fontSize: '.75rem',
      },

      '& input::placeholder': {
        fontSize: '.75rem',
      },
    },
  },

  paragraph: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'center',
    fontSize: '.75rem',

    '& > svg': {
      color: colors.primaryColor,
      marginRight: '1rem',
    },
  },

  formTitle: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'baseline',

    margin: 0,
    minWidth: 'fit-content',
    color: '#11113C',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '1.125rem',
    whiteSpace: 'nowrap',

    '& > hr': {
      width: '100%',
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: '1px solid #e9e9ea',
    },

    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },

  customSelectOrderForm: {
    background: colors.whiteColor,
    maxWidth: '11.25rem',
    minWidth: '6.25rem',
    marginTop: '0 !important',
    margin: '0 0 auto auto',
    fontSize: '.75rem',
    fontFamily: 'Rubik',
    borderRadius: '4px',
    border: '1px solid #ced4da',
    paddingLeft: '.5rem',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },

  icon: {
    '& > svg': {
      color: colors.whiteColor,
      width: '2rem',
      height: '2rem',
      padding: '1rem',
      background: colors.primaryColor,
      borderRadius: '4px',
    },
  },

  feature: {
    fontWeight: 400,
    color: 'rgba(0,0,0,0.4)',
    fontSize: '.625rem',
    lineHeight: '.625rem',
    display: 'flex',

    '& > svg': {
      fontSize: '1em',
      marginRight: '.5rem',
    },
  },

  price: {
    color: '#11113C',
    margin: '.25rem 0',
    lineHeight: '1rem',
    fontSize: '1rem',
    textAlign: 'right',
    maxWidth: '8rem',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  radioBox: {
    padding: '.625rem 0 0 0',
    '& div > div[role="radiogroup"] > div': {
      border: 'solid 1px rgba(0,0,0,0.08)',
      marginBottom: '.25rem',
    },
  },
});
