import { colors, makeStyles } from 'mns-components';

const cookiesBannerStyles = makeStyles({
  blockContainer: {
    textAlign: 'left',
    zIndex: 999999,
    position: 'absolute',
    bottom: '2rem',
    left: '2rem',
    maxWidth: '24rem',
    color: colors.defaultFontColor,
    background: colors.whiteColor,
    boxShadow: '0 .5rem 1rem .25rem rgba(127, 127, 127, .1)',
    padding: '0 1rem 1rem 1rem',
    borderRadius: '.5rem',
    opacity: 0.95,
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 0,
  },
});

export default cookiesBannerStyles;
