import { IconButton } from '@mui/material';
import type { ButtonProps } from 'mns-components';
import {
  Button,
  ButtonList,
  capitalCase,
  joinClass,
  fileBasename,
  fileExtension,
  filterTruthy,
  LoadingCircle,
  snakeCase,
  uniqueBy,
  useImmutable,
  useMemoBase,
  useTestid,
  AlertMessage,
  Link,
  useCallbackImmutable,
  Icon,
} from 'mns-components';
import React, { useEffect, useMemo, useState } from 'react';
import type { FileError, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { useInvalidatePtfCache } from '../../../../hooks/usePortfoliosRequest';
import {
  AWSDEPOSIT_ERRCODE,
  manageUploads,
  PRESIGNED_ERRCODE,
  TRIGGERETL_ERRCODE,
  ZIP_ERRCODE,
} from '../../../../store/upload';
import {
  dialogStyles as useDialogStyles,
  uploadModalFilesStyles as useListStyles,
  uploadModalStyles as useStyles,
} from './uploadModalStyles';

type FileRejection = {
  file: FileWithPath;
  errors: FileError[];
};

const ERROR_NOEXTENSION = 'ERROR_NOEXTENSION';
const ERROR_ALREADYAWAITING = 'ERROR_ALREADYAWAITING';
const failedKeys = [AWSDEPOSIT_ERRCODE, TRIGGERETL_ERRCODE, ZIP_ERRCODE];

const validateFilename = (awaitingFiles: FileWithPath[]) => (file: FileWithPath) => {
  if (!fileExtension(file.name)) {
    return {
      code: ERROR_NOEXTENSION,
      message: 'file name should contain extension',
    };
  }
  if (awaitingFiles.find((item) => file.name === item.name)) {
    return {
      code: ERROR_ALREADYAWAITING,
      message: 'a file with same name is already waiting to be uploaded',
    };
  }
  return null;
};

type UploadModalDropZoneProps = {
  disabled?: boolean;
  awaitingFiles: FileWithPath[];
  onDrop(awaitingFiles: FileWithPath[]): void;
  'data-testid': string;
};

const UploadModalDropZone: React.FC<UploadModalDropZoneProps> = ({
  awaitingFiles,
  onDrop,
  disabled,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [rejectedFiled, setRejectedFiles] = useState<FileRejection[]>([]);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: '.csv, .xls, .xlsx, .xlsm, .txt, .zip, .xlsb',
    validator: validateFilename(awaitingFiles),
    onDrop,
  });

  useEffect(() => {
    if (fileRejections.length) {
      setRejectedFiles((current) => [...current, ...fileRejections]);
    }
  }, [fileRejections]);

  const handleCloseFailed = useCallbackImmutable(() => setRejectedFiles([]));

  return (
    <>
      <section {...getRootProps()} className={classes.dropZone} data-testid={testid}>
        <input {...getInputProps({ disabled })} />
        <p>
          <Icon.Upload className={classes.icon} data-testid={createTestid('icon-dropzone')} />
        </p>
        <p>
          Drag your file here or <span className={classes.fakeLink}>browse</span>
        </p>
        <p className={classes.description}>Files accepted: CSV/XLS/XLSX/XLSM/XLSB/TXT</p>
        <p className={classes.description}>
          File formats accepted : TPT v3, TPT v4, TPT v5, TPT v6, Manaos template ESG format, Advent Geneva, Geneva,
          Multi-fonds, Multi-fonds FA, EET v1, EET v1.1, EMT v3.1, EMT v4
        </p>
      </section>
      {rejectedFiled.length > 0 && (
        <div className={classes.failListContainer} data-testid={createTestid('errors')}>
          <div className={classes.failListHead}>
            <h3>These files are not valid:</h3>
            <IconButton onClick={handleCloseFailed} data-testid={createTestid('button-close')}>
              <Icon.Close data-testid={createTestid('icon-close')} />
            </IconButton>
          </div>
          <ul className={classes.failList}>
            {rejectedFiled.map(({ file, errors }, index) => (
              <li
                key={file.path ?? index}
                className={classes.failListItem}
                title={file.path}
                data-testid={createTestid(`item-${snakeCase(file.path ?? index.toString())}`)}
              >
                <strong>{file.path}</strong>
                {errors.length > 1 && (
                  <span>Errors: {capitalCase(errors.map((err) => `${err.message}`).join(', '))}</span>
                )}
                {errors.length === 1 && <span>{capitalCase(errors.map((err) => `${err.message}`).join(', '))}</span>}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

type UploadModalFilesVariant = 'ready' | 'successed' | 'failed' | 'failedUpload';

const mapIcon: Record<UploadModalFilesVariant, React.FC<{ 'data-testid': string }>> = {
  failed: Icon.Error,
  failedUpload: Icon.Error,
  ready: Icon.Upload,
  successed: Icon.Success,
};

const mapButton: Record<UploadModalFilesVariant, React.FC<{ 'data-testid': string }>> = {
  failed: Icon.Close,
  failedUpload: Icon.Error,
  ready: Icon.Close,
  successed: Icon.Success,
};

const UploadModalFile: React.FC<{
  file: FileWithPath;
  variant: UploadModalFilesVariant;
  disabled?: boolean;
  onRemove?(file: FileWithPath): void;
  onRetry?(file: FileWithPath): void;
  'data-testid': string;
}> = ({ file, variant, disabled, onRemove, onRetry, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useListStyles();

  const IconComp = mapIcon[variant];
  const Btn = mapButton[variant];
  const key = snakeCase(file.name);

  const handleRetry = useCallbackImmutable(() => onRetry?.(file));
  const handleRemove = useCallbackImmutable(() => onRemove?.(file));

  return (
    <li key={key} title={file.name} data-testid={testid}>
      <div className={joinClass(classes.item, disabled ? classes.disabled : classes[variant])}>
        <IconComp data-testid={createTestid(`${key}-icon-status`)} />
        <span data-testid={createTestid(`${key}-filename`)}>{file.name}</span>
        {onRetry && (
          <IconButton
            color="secondary"
            onClick={handleRetry}
            title="Retry upload"
            disabled={disabled}
            data-testid={createTestid(`${key}-button-retry`)}
          >
            <Icon.Retry data-testid={createTestid(`${key}-icon-retry`)} />
          </IconButton>
        )}
        {onRemove && (
          <IconButton
            color="secondary"
            onClick={handleRemove}
            title="Remove"
            disabled={disabled}
            data-testid={createTestid(`${key}-button-remove`)}
          >
            <Btn data-testid={createTestid(`${key}-icon-button`)} />
          </IconButton>
        )}
      </div>
    </li>
  );
};

type UploadModalFilesProps = {
  title: string;
  variant: UploadModalFilesVariant;
  files: FileWithPath[];
  onRemove?(file: FileWithPath): void;
  onRetry?(file: FileWithPath): void;
  disabled?: boolean;
  'data-testid': string;
};

const UploadModalFiles: React.FC<UploadModalFilesProps> = ({
  title,
  variant,
  files,
  onRemove,
  onRetry,
  disabled,
  'data-testid': testid,
}) => {
  const classes = useListStyles();
  const createTestid = useTestid(testid);

  if (files.length) {
    return (
      <div data-testid={testid}>
        <h3>{title}</h3>

        {variant === 'failed' && (
          <AlertMessage variant="error" data-testid={createTestid('error-format')}>
            Please ensure that all of your filenames only contain letters (both uppercase and lowercase), numbers,
            hyphens, underscores, spaces, parentheses, apostrophes, and dots. Special characters and symbols outside of
            these are not allowed
          </AlertMessage>
        )}

        {variant === 'failedUpload' && (
          <AlertMessage variant="error" data-testid={createTestid('error-format')}>
            An error occurred while uploading your files. Please contact the support.
          </AlertMessage>
        )}

        <ul className={classes.list}>
          {files.map((file) => (
            <UploadModalFile
              key={file.name}
              file={file}
              variant={variant}
              disabled={disabled}
              onRemove={onRemove}
              onRetry={onRetry}
              data-testid={createTestid(snakeCase(file.name))}
            />
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const useButtons = (testid: string, onClose?: () => void, onUpload?: () => void, isUploading?: boolean) => {
  const createTestid = useTestid(testid);
  return useMemo(
    (): (ButtonProps & { key: string })[] => [
      {
        key: 'cancel',
        startIcon: <Icon.Close data-testid={createTestid('icon-cancel')} />,
        children: 'Cancel',
        color: 'primary',
        outlined: true,
        disabled: !onClose,
        onClick: onClose,
        'data-testid': createTestid('cancel'),
      },
      isUploading
        ? {
            key: 'upload',
            children: <LoadingCircle data-testid={createTestid('loadingCircle')} />,
            color: 'secondary',
            'data-testid': createTestid('loading'),
          }
        : {
            key: 'upload',
            startIcon: <Icon.Upload data-testid={createTestid('icon-loading')} />,
            children: 'Upload',
            color: 'secondary',
            disabled: !onUpload,
            onClick: onUpload,
            'data-testid': createTestid('loading'),
          },
    ],
    [onClose, onUpload, isUploading, createTestid],
  );
};

const removeItem = <T extends unknown>(list: T[], item: T) => {
  const result = list.slice();
  const indexToRemove = list.indexOf(item);
  result.splice(indexToRemove, 1);
  return result;
};

export type UploadModalContentProps = {
  open?: boolean;
  templateUrl?: string;
  onClose?(): void;
  onSuccess?(): void;
  'data-testid': string;
};

export const UploadModalContent: React.FC<UploadModalContentProps> = ({
  templateUrl,
  onClose,
  onSuccess,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useDialogStyles();

  const [isUploading, setUploading] = useState(false);
  const [awaitingFiles, setAwaitingFiles] = useState<FileWithPath[]>([]);
  const [presignedFailedFiles, setPresignedFailedFiles] = useState<FileWithPath[]>([]);
  const [failedUploadFiles, setFailedUploadFiles] = useState<FileWithPath[]>([]);
  const [successFiles, setSuccessFiles] = useState<FileWithPath[]>([]);

  const { handleDrop, handleCloseAwaiting, handleCloseFailed, handleCloseSuccess, handleRetry } = useImmutable(() => ({
    handleDrop: (acceptedFiles: FileWithPath[]) =>
      setAwaitingFiles((currentList) => uniqueBy([...currentList, ...acceptedFiles], 'name')),
    handleCloseAwaiting: (file: FileWithPath) => setAwaitingFiles((current) => removeItem(current, file)),
    handleCloseFailed: (file: FileWithPath) => setPresignedFailedFiles((current) => removeItem(current, file)),
    handleCloseFailedUpload: (file: FileWithPath) => setFailedUploadFiles((current) => removeItem(current, file)),
    handleCloseSuccess: (file: FileWithPath) => setSuccessFiles((current) => removeItem(current, file)),
    handleRetry: (file: FileWithPath) => {
      setPresignedFailedFiles((current) => removeItem(current, file));
      setAwaitingFiles((current) => [...current, file]);
    },
  }));

  const invalidatePtfCache = useInvalidatePtfCache();

  const handleUpload = useMemoBase(
    {
      awaitingFiles,
      onSuccess,
      setUploading,
      setSuccessFiles,
      setPresignedFailedFiles,
      setAwaitingFiles,
      setFailedUploadFiles,
      invalidatePtfCache,
    },
    (base) =>
      awaitingFiles.length
        ? async () => {
            base.setUploading(() => true);
            const { uploaded, errors } = await manageUploads(base.awaitingFiles);

            base.setSuccessFiles((current) =>
              uniqueBy(
                [
                  ...current,
                  ...filterTruthy(
                    uploaded.map((filename) => base.awaitingFiles.find((file) => file.name === filename)),
                  ).filter((file) => !current.includes(file)),
                ],
                'name',
              ),
            );

            if (errors[PRESIGNED_ERRCODE]) {
              base.setPresignedFailedFiles((current) =>
                uniqueBy(
                  [
                    ...current,
                    ...filterTruthy(
                      errors[PRESIGNED_ERRCODE].map((filename) =>
                        base.awaitingFiles.find((file) => file.name === filename),
                      ),
                    ).filter((file) => !current.includes(file)),
                  ],
                  'name',
                ),
              );
            }

            const failedFilesItems = failedKeys.reduce((acc, key) => [...acc, ...(errors[key] ?? [])], [] as string[]);
            base.setFailedUploadFiles((current) =>
              uniqueBy(
                [
                  ...current,
                  ...filterTruthy(
                    failedFilesItems.map((filename) => base.awaitingFiles.find((file) => file.name === filename)),
                  ).filter((file) => !current.includes(file)),
                ],
                'name',
              ),
            );

            base.setAwaitingFiles(() => []);
            base.setUploading(() => false);

            if (!Object.keys(errors).length) {
              base.invalidatePtfCache();
              base.onSuccess?.();
            }
          }
        : undefined,
    [!awaitingFiles.length],
  );

  const templateBasename = templateUrl && fileBasename(templateUrl);

  return (
    <div className={classes.modal} data-testid={testid}>
      <div className={classes.modalContent}>
        <h2>Upload your files</h2>
        <UploadModalDropZone
          onDrop={handleDrop}
          awaitingFiles={awaitingFiles}
          disabled={isUploading}
          data-testid={createTestid('dropzone')}
        />
        {templateUrl && templateBasename && (
          <div className={classes.templateContainer}>
            <p className={classes.createPtfOr}>OR</p>
            <h2>Use our template</h2>
            <Link.Underlined
              extern
              to={templateUrl}
              download={templateBasename}
              target={templateBasename}
              data-testid={createTestid('link-template')}
            >
              <Button
                startIcon={<Icon.Download data-testid={createTestid('icon-template')} />}
                outlined
                color="primary"
                data-testid={createTestid('button-template')}
              >
                {templateBasename}
              </Button>
            </Link.Underlined>
          </div>
        )}
        <h2>Your files</h2>
        <UploadModalFiles
          files={presignedFailedFiles}
          variant="failed"
          title="Invalid files"
          onRemove={handleCloseFailed}
          onRetry={handleRetry}
          disabled={isUploading}
          data-testid={createTestid('failed')}
        />
        <UploadModalFiles
          files={failedUploadFiles}
          variant="failedUpload"
          title="Upload failed"
          onRemove={handleCloseFailed}
          onRetry={handleRetry}
          disabled={isUploading}
          data-testid={createTestid('failed')}
        />
        <UploadModalFiles
          files={awaitingFiles}
          variant="ready"
          title="Files ready to upload"
          onRemove={handleCloseAwaiting}
          disabled={isUploading}
          data-testid={createTestid('ready')}
        />
        <UploadModalFiles
          files={successFiles}
          variant="successed"
          title="Files uploaded successfully"
          onRemove={handleCloseSuccess}
          data-testid={createTestid('successed')}
        />
      </div>
      <div className={classes.modalButtons}>
        <ButtonList
          buttons={useButtons(createTestid('buttons'), onClose, handleUpload, isUploading)}
          data-testid={createTestid('buttons')}
        />
      </div>
    </div>
  );
};
