import { IconButton, Stack } from '@mui/material';
import { NotificationDot, Icon, useTestid, useCallbackImmutable, EmptyStateV2 } from 'mns-components';
import useStyles from '../styles/InviteAccessPermission.styles';

const InviteEmailItem: React.FC<{ mail: string; removeEmail(mail: string): void; 'data-testid': string }> = ({
  mail,
  removeEmail,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div className={classes.emailList}>
      <span className={classes.email}>{mail}</span>
      <div className={classes.btn}>
        <IconButton
          onClick={useCallbackImmutable(() => removeEmail(mail))}
          data-testid={createTestid(`button-removeEmail`)}
        >
          <Icon name="delete" color="secondary" size="medium" data-testid={createTestid(`icon-removeEmail`)} />
        </IconButton>
      </div>
    </div>
  );
};

type InviteEmailsDisplayProps = {
  invitationList: string[];
  removeEmail(email: string): void;
  'data-testid': string;
};

export const InviteEmailsDisplay: React.FC<InviteEmailsDisplayProps> = ({
  invitationList,
  removeEmail,
  'data-testid': testid,
}: InviteEmailsDisplayProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  if (invitationList.length < 1)
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There are no emails selected yet!"
          data-testid={createTestid('emptyState')}
        />
      </Stack>
    );

  return (
    <>
      <div className={classes.sectionTitle}>
        Selected emails
        <NotificationDot notifValue={invitationList.length} isInTab />
      </div>
      {invitationList.map((mail, index) => (
        <InviteEmailItem key={mail + index} data-testid={createTestid(index)} mail={mail} removeEmail={removeEmail} />
      ))}
    </>
  );
};
