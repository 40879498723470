import { Stack } from '@mui/material';
import { DialogPopup, Typo, useCallbackImmutable } from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDeleteEmailList } from '../hooks';

type OrganisationDeleteModalProps = {
  emailList: EmailListApi.EmailList | null;
  onClose(): void;
  'data-testid': string;
};

export const OrganisationDeleteModal: React.FC<OrganisationDeleteModalProps> = ({
  emailList,
  onClose,
  'data-testid': testid,
}) => {
  const { mutate: deleteEmailList, isSuccess, isLoading, isError } = useDeleteEmailList();

  const onSubmit = useCallbackImmutable(() => {
    if (emailList) deleteEmailList(emailList.id);
  });

  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast.success('Email list successfuly deleted');
    }
    if (isError) {
      onClose();
      toast.error('Failed to delete email list');
    }
  }, [isSuccess, onClose, isError]);

  if (!emailList) return null;

  return (
    <DialogPopup
      title="Delete mapping"
      open={!!emailList}
      onClose={onClose}
      onMainActionClick={onSubmit}
      mainButtonText="Delete mapping"
      mainButtonColor="danger"
      mainButtonDisabled={isLoading}
      data-testid={testid}
    >
      <Stack gap="1rem">
        <Stack>
          <Typo variant="body2">
            You are about to delete the email list{' '}
            <Typo component="span" variant="body2medium">
              &quot;{emailList.title}&quot;
            </Typo>{' '}
            from your organisation.
          </Typo>
          <Typo variant="body2">
            The email list will no longer be usable for communicating or sending files to your contacts.
          </Typo>
        </Stack>
        <Typo variant="body2medium">Do you confirm ?</Typo>
      </Stack>
    </DialogPopup>
  );
};
