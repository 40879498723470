import { colors, makeStyles } from 'mns-components';

const appLayoutStyles = makeStyles({
  mainTitle: {
    width: '129px',
    height: '25px',
    color: colors.defaultFontColor,
    fontSize: '21px',
    lineHeight: '25px',
    textAlign: 'left',
    margin: '42px 0 0 0',
  },
  subTitle: {
    opacity: '0.6',
    color: colors.defaultFontColor,
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    margin: '7px 0 0 0',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',
    boxSizing: 'border-box',
  },
  buttonContainer: {
    position: 'fixed',
    top: '13px',
    right: '20px',
    zIndex: 1200,
  },
  kpisContainer: {
    padding: '1rem 2rem 1rem 1rem',
    background: 'white',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    background: 'white',
    padding: '0',
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    borderRadius: '5px',
    flex: '1 1 0',
    minHeight: 0,
  },
  gridContainerDashboard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    borderRadius: '5px',
    flex: '1 1 0',
  },
  tabsContainer: {
    background: 'white',
    padding: '0 0 0 2rem',
  },
});
export default appLayoutStyles;
