import type { FormGenerationProps, FormStepError, FormStepErrors, FormStepProps, OptionType } from 'mns-components';
import { useImmutable, useTestid } from 'mns-components';
import type { AnalysisApi, ProviderApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import { usePricingByApplicationRequest } from '../../hooks';
import type { PayRequestSteps } from './createRequest.types';
import { formStyles as useStyles } from './formStyles';
import { LimitationDisplay } from './LimitationDisplay';
import { NeedCoverageDisplay } from './NeedCoverageDisplay';
import { OfferOptionsForm } from './OfferOptionsForm';
import { OfferPickerForm } from './OfferPickerForm';
import type { ReplyPortfolioPickerForm } from './PortfolioPickerForm';
import { PortfolioPickerForm } from './PortfolioPickerForm';

const AppWithMinLookthroughRate = ['Sequantis'];

export const isValidCoverage = (el: ProviderApi.Portfolio['providerCoverage']): el is number =>
  typeof el === 'number' && el <= 1 && el >= 0;

export const isAllowedToCoverage = (el: ProviderApi.Portfolio['coverageStatus']) =>
  !['requested', 'pending'].includes(el as string);

export const isFilledString = (el: unknown): el is string => typeof el === 'string' && !!el.length;

export const isValidPayStep = (data: Partial<PayRequestSteps[0]>): FormStepErrors<PayRequestSteps[0]> => {
  const errors: FormStepError<PayRequestSteps[0]>[] = [];

  if (!isFilledString(data.portfolioId)) {
    errors.push({
      name: 'portfolioId',
      message: 'A valid Portfolio ID must be selected',
    });
  }

  if (!isFilledString(data.valuationDate)) {
    errors.push({
      name: 'valuationDate',
      message: 'A valid NAV Date must be selected',
    });
  }

  if (data.needsCoverage) {
    if (!data.agreeCoverage) {
      errors.push({
        name: 'agreeCoverage',
        message: 'Please agree to share a subset of portfolio to get its coverage rate',
      });
    }
    return errors;
  }

  if (data.isNotPriced) {
    return errors;
  }

  if (!data.offer) {
    errors.push({
      name: 'offer',
      message: 'An offer must be selected. If none appears, please reload application',
    });
  }

  if (data.offer?.orderFormOptions) {
    const rawForms = JSON.parse(data.offer.orderFormOptions) as FormGenerationProps[];

    const { options } = data;
    if (!Array.isArray(options)) {
      errors.push({
        name: 'options',
        message: 'Options must be filled',
      });
    } else {
      const foundUnfilled = rawForms
        .filter(
          (form) =>
            !options.find((opt) => opt.optionId === form.optionId && opt.value !== null && opt.value !== undefined),
        )
        .map((form) => form.optionId);

      if (foundUnfilled[0]) {
        errors.push({
          name: 'options',
          message: `Options must be filled (${foundUnfilled})`,
        });
      }
    }
  }
  return errors;
};

export type PayStepProps = FormStepProps<PayRequestSteps[0], PayRequestSteps> & {
  appCode: AppCode;
  portfolios: ProviderApi.Portfolio[];
  disablePtfAndDate?: boolean;
  'data-testid': string;
};

export const PayStep: React.FC<PayStepProps> = ({
  appCode,
  portfolios,
  setStepValue,
  setSubmit,
  setNext,
  stepValue,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const {
    config: { maxPtfLine, coverageRequired },
    company: { name },
  } = useApplicationConfig(appCode);
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [[defaultPortfolioId, defaultValuationDate]] = usePtfAndDate();

  const [agreeCoverage, setAgreeCoverage] = useState(false);
  const [portfolioId, setPortfolioId] = useState<string | undefined>(stepValue.portfolioId ?? defaultPortfolioId);
  const [valuationDate, setValuationDate] = useState<string | undefined>(
    stepValue.valuationDate ?? defaultValuationDate,
  );
  const [offerId, setOfferId] = useState<string | undefined>(stepValue.offer?.offerId);
  const [options, setOptions] = useState<AnyObject | undefined>(
    stepValue.options?.reduce((acc, opt) => {
      acc[opt.optionId] = opt.value;
      return acc;
    }, {} as AnyObject),
  );

  const portfolio = useMemo(
    () =>
      portfolios?.find(
        (ptf) => ptf.portfolioId === portfolioId && (!valuationDate || ptf.valuationDate === valuationDate),
      ) ?? portfolios[0],
    [portfolioId, portfolios, valuationDate],
  );

  const { data: applicationPricings } = usePricingByApplicationRequest(appCode, portfolio.numberOfLines);

  const orderFormOptions = useMemo(() => {
    try {
      const offer = applicationPricings?.find((ofr) => ofr.offerId === offerId);
      if (offer) {
        const rawForms: (FormGenerationProps & { defaultValue?: string })[] = JSON.parse(offer.orderFormOptions);
        if (Array.isArray(rawForms) && rawForms[0]) {
          setOptions(
            (current) =>
              current ??
              rawForms?.reduce((acc, opt) => {
                acc[opt.optionId] = opt.defaultValue ?? opt.options[0].value;
                return acc;
              }, {} as AnyObject),
          );

          return rawForms;
        }
        setOptions((current) => current ?? {});
      }
    } catch {
      // do nothing
    }
    return null;
  }, [applicationPricings, offerId]);

  const valuationDateOptions = useMemo(() => {
    return portfolios
      ?.filter((ptf) => ptf.portfolioId === portfolioId)
      .map(({ valuationDate: navDate }): OptionType => ({ label: navDate, value: navDate }));
  }, [portfolioId, portfolios]);

  const defaultPtfAndDateValues = useMemo((): Partial<ReplyPortfolioPickerForm> => {
    if (portfolio) {
      return {
        portfolioId: portfolio.portfolioId,
        valuationDate: portfolio.valuationDate,
        organisationId: portfolio.organisationId,
      };
    }
    return {
      portfolioId: defaultPortfolioId ?? portfolios?.[0]?.portfolioId,
      valuationDate: defaultValuationDate ?? valuationDateOptions?.[0]?.value,
    };
  }, [defaultPortfolioId, defaultValuationDate, portfolio, portfolios, valuationDateOptions]);

  useEffect(() => {
    if (AppWithMinLookthroughRate.includes(name)) {
      setNext((current) => ({
        ...current,
        disabled: !portfolio || portfolio.lookThroughRate < 0.75,
      }));
    }
  }, [setNext, portfolio, name]);

  useEffect(() => {
    const { portfolioId: pId, valuationDate: navDate } = portfolio;
    // needs coverage
    if (coverageRequired && !isValidCoverage(portfolio.providerCoverage)) {
      setStepValue({ portfolioId: pId, valuationDate: navDate, needsCoverage: true, agreeCoverage });
      setSubmit((current) => ({
        ...current,
        label: 'Request coverage',
        disabled: !isAllowedToCoverage(portfolio.coverageStatus),
      }));
    }
    // can not be priced, please contact cox
    else if (maxPtfLine && maxPtfLine < portfolio.numberOfLines) {
      setStepValue({ portfolioId: pId, valuationDate: navDate, isNotPriced: true });
      setSubmit((current) => ({ ...current, disabled: false, label: 'Request pricing' }));
    }
    // offer and options selected, please proceed
    else if (offerId) {
      const offer = applicationPricings?.find((ofr) => ofr.offerId === offerId);
      if (offer) {
        const formattedOptions = options
          ? Object.keys(options).map(
              (optionId): AnalysisApi.PayAnalysisPortfolioRequestOption => ({ optionId, value: options[optionId] }),
            )
          : [];
        setStepValue({ portfolioId: pId, valuationDate: navDate, offer, options: formattedOptions });
        setSubmit((current) => ({ ...current, disabled: false, label: 'Confirm order' }));
      }
    }
  }, [
    agreeCoverage,
    applicationPricings,
    coverageRequired,
    maxPtfLine,
    offerId,
    options,
    portfolio,
    setStepValue,
    setSubmit,
  ]);

  const getOptions = useImmutable(() => (newValues: AnyObject) => {
    setOptions((current) => ({ ...current, ...newValues }));
    return null;
  });

  const needCoverage = !!coverageRequired && !isValidCoverage(portfolio.providerCoverage);
  const displayNeedCoverage = needCoverage && isAllowedToCoverage(portfolio.coverageStatus);
  const displayLimitation =
    !needCoverage && ((!!maxPtfLine && maxPtfLine < portfolio.numberOfLines) || !applicationPricings?.[0]);
  const displayOfferPicker = !needCoverage && !displayLimitation && !!applicationPricings?.[0];
  const displayOptions = !needCoverage && !displayLimitation && !!orderFormOptions?.[0];

  return (
    <div className={classes.formsContainer} data-testid={testid}>
      <PortfolioPickerForm
        portfolios={portfolios}
        valuationDateOptions={valuationDateOptions}
        portfolio={portfolio}
        defaultValues={defaultPtfAndDateValues}
        onPortfolioChange={setPortfolioId}
        onValuationDateChange={setValuationDate}
        disablePtfAndDate={disablePtfAndDate}
        data-testid={createTestid('portfolioPicker')}
      />
      {displayNeedCoverage && (
        <NeedCoverageDisplay
          companyName={name}
          onAgreeCoverage={setAgreeCoverage}
          data-testid={createTestid('needCoverage')}
        />
      )}
      {displayLimitation && <LimitationDisplay data-testid={createTestid('linesLimitation')} />}
      {displayOfferPicker && (
        <OfferPickerForm
          applicationPricings={applicationPricings}
          offerId={offerId}
          onOfferChange={setOfferId}
          data-testid={createTestid('offers')}
        />
      )}
      {displayOptions && (
        <OfferOptionsForm
          inputList={orderFormOptions}
          validationAtEvent="all"
          onValidate={getOptions}
          defaultValues={options}
          data-testid={createTestid('offerOptions')}
        />
      )}
    </div>
  );
};
