import { Button, SearchField, useTestid } from 'mns-components';
import { extractorPreferencesStyles as useStyles } from '../styles/extractorPreferencesStyles';

export type ExtractorPreferencesControllerProps = {
  searchValue: string;
  setSearch(search: string): void;
  onExportAll(): void;
  'data-testid': string;
};

export const ExtractorPreferencesController: React.FC<ExtractorPreferencesControllerProps> = ({
  searchValue,
  setSearch,
  onExportAll,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <div className={classes.controllerSpace} data-testid={testid}>
      <SearchField value={searchValue} onChange={setSearch} data-testid={createTestid('search')} />
      <div className={classes.controllerButtons}>
        <Button outlined color="primary" size="small" onClick={onExportAll} data-testid={createTestid('export-button')}>
          Export table
        </Button>
      </div>
    </div>
  );
};
