import { Stack } from '@mui/material';
import type { SimpleTableProps } from 'mns-components';
import { Accordion, colors, Dialogv2, Icon, SimpleTable, Typo, useTestid } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useState } from 'react';

const columns: SimpleTableProps<DataExtractorApi.PortfolioLight & DataExtractorApi.PortfolioLightAndDate>['columns'] = [
  {
    field: 'externalIdValue',
    head: 'Portfolio ID',
  },
  {
    field: 'name',
    head: 'Portfolio name',
  },
];

type ExtractionAutomatedDetailsProps = {
  extractionDetails: DataExtractorApi.Extraction.Extraction;
  'data-testid': string;
};

const ExtractionAutomatedDetails: React.FC<ExtractionAutomatedDetailsProps> = ({
  extractionDetails,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [expanded, setExpanded] = useState<string | null>('name');

  return (
    <Stack gap="1rem">
      <Accordion
        title="Template used"
        expansible
        name="name"
        value={expanded}
        onExpandValue={setExpanded}
        data-testid={createTestid('templateName-accordion')}
      >
        <Typo component="p" variant="body2" color={colors.blueColor} data-testid={createTestid('templateName')}>
          {extractionDetails.templateName}
        </Typo>
      </Accordion>
      <Accordion
        title="Scope details"
        expansible
        name="list"
        value={expanded}
        onExpandValue={setExpanded}
        data-testid={createTestid('list-accordion')}
      >
        <SimpleTable
          rows={extractionDetails.extractionScope}
          columns={columns}
          disableLeftPadding
          data-testid={createTestid('list')}
        />
      </Accordion>
    </Stack>
  );
};

export const ExtractionAutomatedDetailsModal: React.FC<{
  extractionDetails?: DataExtractorApi.Extraction.Extraction;
  onClose(): void;
  'data-testid': string;
}> = ({ extractionDetails, onClose, 'data-testid': testid }) => (
  <Dialogv2
    dialogTitle={`Scope Details — ${extractionDetails?.extractionName}`}
    DialogTitleIcon={Icon.Details}
    open={!!extractionDetails}
    onClose={onClose}
    data-testid={`${testid}-modal`}
  >
    {extractionDetails && <ExtractionAutomatedDetails extractionDetails={extractionDetails} data-testid={testid} />}
  </Dialogv2>
);
