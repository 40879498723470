import { integerToPercent } from 'mns-components';
import type { ClaritySfdrApi } from 'mns-sdk-collect';
import React from 'react';
import useStyles from './coverageSfdrRendererStyles';

interface Props {
  coverage?: ClaritySfdrApi.Metric['coverage'];
}

const CoverageSfdrRenderer: React.FC<Props> = ({ coverage }: Props) => {
  const classes = useStyles();

  if (typeof coverage === 'number') {
    /**
     * @warn Should not use `floatToPercent` because clarity-sfdr is the only provider sending its
     * coverage between 0 and 100. This is a tempfix until the clients need to hit API directly.
     * https://bivwak.atlassian.net/browse/MNS-10240
     */
    return <div className={classes.coverageRenderer}>{integerToPercent(coverage)}</div>;
  }
  return null;
};

export default React.memo(CoverageSfdrRenderer);
