import { StatusTag } from 'mns-components';

export const CompilingStepStatus: React.FC<{ status?: 'failed' | 'pending' | 'complete'; 'data-testid': string }> = ({
  status,
  'data-testid': testid,
}) => {
  if (status === 'failed') {
    return <StatusTag variant="error" label="Failed" data-testid={testid} />;
  }

  if (status === 'pending') {
    return <StatusTag variant="processing" label="Processing" data-testid={testid} />;
  }

  if (status === 'complete') {
    return <StatusTag variant="success" label="Completed" data-testid={testid} />;
  }

  return null;
};
