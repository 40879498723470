import { AppContent, AppLayout } from 'mns-components';
import { Audit } from '../../../components/views/uploads/Audit';
import { useUploadModalOpen } from '../hooks';

export const Uploads: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <AppLayout data-testid={testid}>
    <AppContent variant="expanded-noscroll">
      <Audit onOpenModal={useUploadModalOpen()} buttonVariant="secondary" data-testid={testid} />
    </AppContent>
  </AppLayout>
);
