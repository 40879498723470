import { Stack } from '@mui/material';
import { useTestid, Typo } from 'mns-components';
import type { CoverageApi } from 'mns-sdk-collect';
import React from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getMatchingLevelWording } from '../../../coverageApp/components/CoverageCollapsible';
import CoverageRenderer from '../../../coverageApp/components/CoverageRenderer';

export type CardCoverageProps = {
  appCode: AppCode;
  coverage: CoverageApi.Coverage;
  'data-testid': string;
};

export const CardCoverageMainValue: React.FC<CardCoverageProps> = ({ appCode, coverage, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const providerCode = useApplicationConfig(appCode)?.company?.providerName;
  const coverageLevelWording = getMatchingLevelWording(
    providerCode,
    coverage.coverage,
    coverage.coverageDetails,
    coverage.matchingLevel,
    coverage.errorMessage,
  );

  return (
    <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Stack marginRight="0.75rem">
        <Typo variant="caption">{coverageLevelWording}</Typo>
      </Stack>
      <CoverageRenderer
        coverage={coverage.coverage}
        error={null}
        status={'success'}
        isClarityDetails={false}
        data-testid={createTestid('')}
      />
    </Stack>
  );
};
