import { IconButton } from '@mui/material';
import { Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';

type UserInformationsButtonsProps = {
  data: AccountApi.User;
  onEdit?(row: AccountApi.User): void;
  onDelete?(row: AccountApi.User): void;
  disabled?: boolean;
  'data-testid': string;
};

export const UserInformationsButtons: React.FC<UserInformationsButtonsProps> = ({
  data,
  onEdit,
  onDelete,
  disabled,
  'data-testid': testid,
}: UserInformationsButtonsProps) => {
  const createTestid = useTestid(testid);

  const handleEdit = useCallbackImmutable((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onEdit?.(data);
  });

  const handleDelete = useCallbackImmutable((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDelete?.(data);
  });

  return (
    <>
      {onEdit && (
        <IconButton
          size="small"
          color="secondary"
          onClick={handleEdit}
          disabled={disabled}
          data-testid={createTestid('button-edit')}
        >
          <Icon.Edit data-testid={createTestid('icon-edit')} />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          size="small"
          color="secondary"
          onClick={handleDelete}
          disabled={disabled}
          data-testid={createTestid('button-delete')}
        >
          <Icon.Delete data-testid={createTestid('icon-delete')} />
        </IconButton>
      )}
    </>
  );
};
