import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import {
  Backdrop,
  Button,
  EmptyStateV2,
  Icon,
  SearchField,
  SelectFilter,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteExtraction, useGetExtractions, useUpdateExtractionStatus } from '../../../../api/extractionsData';
import { toastUpdateOrCreate } from '../../../../common/toast';
import {
  useManageOpenAutomatedExtractionDeleteModal,
  useManageOpenAutomatedExtractionDetailsModal,
} from '../../../dataExtractorApp/hooks';
import { trimString } from '../../monitoring/components/ProviderAnalysisTable';
import { getEsgRoute } from '../../routes';
import { ExtractionAutomatedDeletePopup } from './ExtractionAutomatedDeletePopup';
import { ExtractionAutomatedDetailsModal } from './ExtractionAutomatedDetailsModal';
import { ExtractionsAutomatedList, type BulkClickHandler } from './ExtractionAutomatedList';

export const ExtractionsAutomated: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const gridApi = useRef<GridApi | null>(null);
  const [templateOptions, setTemplateOptions] = useState<{ label: string; value: string; count: number }[]>([]);
  const [templateSelected, setTemplateSelected] = useState('all');

  //request
  const {
    data: automaticExtractions,
    isLoading: isLoadingAutomaticExtractions,
    isError: isErrorAutomaticExtractions,
  } = useGetExtractions('AUTOMATIC', {
    onSuccess: (data) => {
      setTemplateOptions(
        data.reduce((acc, { templateId, templateName }) => {
          const found = acc.find(({ value }) => value === templateId);
          if (!found) {
            acc.push({ label: trimString(templateName, 25), value: templateId, count: 1 });
          } else {
            found.count++;
          }

          return acc;
        }, [] as { label: string; value: string; count: number }[]),
      );
    },
  });
  const { mutateAsync: updateExtractionStatus } = useUpdateExtractionStatus();
  const { mutateAsync: deleteAutomaticExtraction } = useDeleteExtraction();

  // modal
  const [extractionIdDetails, setOpenDetails, setCloseDetails] = useManageOpenAutomatedExtractionDetailsModal();
  const [extractionIdDelete, setOpenDelete, setCloseDelete] = useManageOpenAutomatedExtractionDeleteModal();

  const extractionDetailsOpen = useMemo(
    () => automaticExtractions?.find((ext) => ext.id === extractionIdDetails),
    [automaticExtractions, extractionIdDetails],
  );
  const extractionDeleteOpen = useMemo(
    () => automaticExtractions?.find((ext) => ext.id === extractionIdDelete),
    [automaticExtractions, extractionIdDelete],
  );

  // form
  const methods = useForm({
    defaultValues: {
      search: '',
      templates: [] as string[],
    },
  });
  const { watch } = methods;
  const [templates, search] = watch(['templates', 'search']);

  // table
  const displayedAutomaticExtractions = useMemo(() => {
    let filteredExtractions = automaticExtractions;
    // is extraction feed selected?
    if (filteredExtractions?.length && templates?.length) {
      filteredExtractions = filteredExtractions.filter((extraction) => templates.includes(extraction.templateId));
    }
    if (filteredExtractions?.length && templateSelected != 'all') {
      filteredExtractions = filteredExtractions.filter((extraction) =>
        templateSelected.includes(extraction.templateId),
      );
    }
    return filteredExtractions;
  }, [automaticExtractions, templateSelected, templates]);

  const onToggleStatus: BulkClickHandler = useCallbackImmutable(async (row) => {
    const isActivate = row.extractionStatus === 'ACTIVATED';
    const toastId = toast.info('The automated extraction activation is switching');
    try {
      await updateExtractionStatus(row.id, isActivate ? 'DEACTIVATED' : 'ACTIVATED');
      toastUpdateOrCreate(toastId, {
        render: `Automated extraction has been ${isActivate ? 'turned off' : 'activation'} with success`,
        type: 'success',
      });
    } catch {
      toastUpdateOrCreate(toastId, { render: "The automated extraction couldn't be activated", type: 'error' });
    }
  });

  const onExportAll = useImmutable(() => () => gridApi.current?.exportDataAsExcel());

  const onDisplayDetails: BulkClickHandler = useCallbackImmutable((row) => setOpenDetails(row.id));
  const onDeleteExtraction = useCallbackImmutable((row: DataExtractorApi.Extraction.Extraction) =>
    setOpenDelete(row.id),
  );

  const onConfirmDeleteExtraction = useCallbackImmutable(({ id }) => {
    const toastId = toast.info('The automated extraction delete is being proccessed');
    (async () => {
      try {
        await deleteAutomaticExtraction(id);
        toastUpdateOrCreate(toastId, { render: 'The automated extraction delete succeed', type: 'success' });
      } catch {
        toastUpdateOrCreate(toastId, { render: "The automated extraction couldn't be deleted", type: 'error' });
      }
    })();
  });

  if (isLoadingAutomaticExtractions) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!isLoadingAutomaticExtractions && isErrorAutomaticExtractions) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error-extractions')}
        />
      </Stack>
    );
  }

  if (!automaticExtractions?.length) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
        <EmptyStateV2
          variant="info"
          useCase="actions"
          title="Get started with an automated extraction!"
          subTitle="Start extracting your portfolio."
          buttonText="Create extraction"
          buttonVariant="secondary"
          buttonHref={generatePath(getEsgRoute('generate-extraction').link)}
          data-testid={createTestid('empty-extractions')}
        />
      </Stack>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" margin="0 2rem 1rem 2rem">
          <SearchField
            value={search}
            name="search"
            uncontrolled={false}
            disableMargin
            data-testid={createTestid('input-search')}
          />
          <Stack direction="row" alignItems="center" gap="1rem">
            <SelectFilter
              label="Template used"
              size="medium"
              activeItem={templateSelected}
              dropdownItems={templateOptions}
              onClick={setTemplateSelected}
              data-testid={createTestid('input-templates')}
            />
            <Button
              startIcon={<Icon name="download" color="inversed" data-testid={createTestid('icon-export')} />}
              color="primary"
              size="medium"
              disabled={!automaticExtractions?.length}
              onClick={onExportAll}
              data-testid={createTestid('button-export')}
            >
              Export table
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
      <ExtractionsAutomatedList
        quickFilterText={search}
        rowData={displayedAutomaticExtractions}
        getGridApiRef={gridApi}
        onToggleStatus={onToggleStatus}
        onDisplayDetails={onDisplayDetails}
        onDeleteExtraction={onDeleteExtraction}
        data-testid={createTestid('list')}
      />
      <ExtractionAutomatedDetailsModal
        extractionDetails={extractionDetailsOpen}
        onClose={setCloseDetails}
        data-testid={createTestid('modal-details')}
      />
      <ExtractionAutomatedDeletePopup
        onDelete={onConfirmDeleteExtraction}
        open={!!extractionDeleteOpen}
        extractionDetails={extractionDeleteOpen}
        onClose={setCloseDelete}
        data-testid={createTestid('delete-popup')}
      />
    </>
  );
};
