import { IconButton, Stack } from '@mui/material';
import type { CsvToAggrid, OptionType } from 'mns-components';
import {
  EmptyStateV2,
  Button,
  Dialog,
  EmptyState,
  Icon,
  Link,
  Select,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi, DataExtractorApi } from 'mns-sdk-collect';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTemplates } from '../../../../api/templateData';
import { downloadFile } from '../../../../common/utils';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getDataExtractorRoute } from '../../../dataExtractorApp/routes';
import { ExtractorTemplateDetailsModal } from '../../../dataExtractorApp/templates/ExtractorTemplateDetailsModal';
import { useCreateExtraction, useReport, useReportDetails, useReportFile } from '../../hooks';
import { dataExtractorAppStyles as useStyles } from './styles/dataExtractorAppStyles';
import { StateAppListGrid } from './tableComponents/StateAppList';

type DataExtractorViewProps = {
  'data-testid': string;
  templateId: string;
  loading: boolean;
  report: DataExtractorApi.Report.ReportLight | undefined;
  reportDetails: DataExtractorApi.Report.Report | undefined;
  reportFile: CsvToAggrid<'id'> | undefined;
  createExtraction: () => void;
};

export const DataExtractorView: React.FC<DataExtractorViewProps> = ({
  'data-testid': testid,
  templateId,
  loading,
  report,
  createExtraction,
  reportDetails,
  reportFile,
}) => {
  const createTestid = useTestid(testid);

  if (!loading) {
    if (!templateId) {
      return (
        <Stack height="100%" flexGrow="1" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There is no dashboard selected yet!"
            subTitle="Start visualizing your data by selecting a dashboard in the list above."
            data-testid={createTestid('empty-no-selection')}
          />
        </Stack>
      );
    }

    if (!report) {
      return (
        <Stack height="100%" flexGrow="1" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="actions"
            title="Get started with an enrichment!"
            subTitle="Start enriching your portfolio."
            buttonText="Enrich portfolio"
            buttonOnClick={createExtraction}
            buttonVariant="secondary"
            data-testid={createTestid('empty-not-enriched')}
          />
        </Stack>
      );
    }

    if (reportDetails && reportDetails?.status === 'FAILED') {
      return (
        <Stack height="100%" justifyContent="center" flexGrow={1}>
          <EmptyStateV2
            useCase="dataErrors"
            variant="error"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('empty-failed')}
          />
        </Stack>
      );
    }

    if (reportFile) {
      return <StateAppListGrid rowData={reportFile.rowData} columnDefs={reportFile.columnDefs} data-testid={testid} />;
    }
  }

  return (
    <EmptyState
      iconEnable
      iconName="processing"
      title="Processing"
      firstParagraph="Your portfolio is currently being enriched. Please wait a few seconds to get your portfolio scores."
      data-testid={createTestid('empty-processing')}
    />
  );
};

type DataExtractorProps = {
  portfolio: CollectApi.Portfolio;
  'data-testid': string;
};

export const DataExtractorApp: React.FC<DataExtractorProps> = ({ 'data-testid': testid, portfolio }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const { date: valuationDate } = useParams<{ id: string; date: string; appCode: AppCode }>();
  const [detailsOpen, setDetailsOpen] = useState<DataExtractorApi.Template.TemplateLight | null>(null);

  const onCloseDetailsModal = useCallbackImmutable(() => setDetailsOpen(null));

  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const { data: templates, isLoading, isError } = useTemplates();

  const report = useReport(portfolio.externalId.value, valuationDate, selectedTemplate);
  const reportDetails = useReportDetails(report.data?.id);
  const reportFile = useReportFile(reportDetails.data);

  const createExtraction = useCreateExtraction();

  const getTemplateLight = useCallbackImmutable(() => {
    const templateLight = templates?.find((template) => template.id == selectedTemplate);
    if (!templateLight) return null;
    return templateLight;
  });

  const getTemplateList = useCallbackImmutable(() => {
    if (!templates) return [];
    return templates.reduce((acc, template) => {
      acc.push({ label: template.name, value: template.id });
      return acc;
    }, [] as OptionType[]);
  });

  const handleCreateExtraction = useCallbackImmutable(() =>
    createExtraction.mutate({
      templateId: selectedTemplate,
      extractionFeed: 'API',
      extractionName: `${portfolio.name}-${valuationDate}`,
      extractionType: 'MANUALLY',
      extractionScope: [
        {
          name: portfolio.name,
          externalIdType: portfolio.externalId.type,
          externalIdValue: portfolio.externalId.value,
          valuationDate: valuationDate,
        },
      ],
    }),
  );

  const handleDownload = useCallbackImmutable(() => {
    if (reportDetails.data) {
      downloadFile(
        reportDetails.data.reportPresignedUrl,
        `${reportDetails.data.portfolio.name}-${reportDetails.data.portfolio.valuationDate}.csv`,
      );
    }
  });

  return (
    <>
      <div className={classes.templateContainer}>
        <Select
          name="Select a Template"
          label="Select a Template"
          variant="normal"
          options={getTemplateList()}
          value={selectedTemplate}
          style={{ width: '16rem' }}
          uncontrolled
          disabled={isLoading || isError}
          onFieldChange={useCallbackImmutable((newValue) => setSelectedTemplate(newValue))}
          data-testid={createTestid('select')}
        />
        <IconButton
          color="secondary"
          size="medium"
          onClick={useCallbackImmutable(() => setDetailsOpen(getTemplateLight))}
          disabled={selectedTemplate == ''}
          data-testid={createTestid('button-template')}
        >
          <Icon.View
            color={selectedTemplate === '' ? 'disabled' : 'secondary'}
            data-testid={createTestid('icon-select')}
          />
        </IconButton>
        <div className={classes.download}>
          <Button
            color="primary"
            onClick={handleDownload}
            disabled={!reportFile.data}
            startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
            data-testid={createTestid('button-download')}
          >
            Download
          </Button>
        </div>
      </div>

      <div className={classes.textLink}>
        You don&apos;t find the right template? Click{' '}
        <Link.Underlined
          to={getDataExtractorRoute('extractor-templates').link}
          data-testid={createTestid('link-extractor')}
        >
          here
        </Link.Underlined>{' '}
        to define a new one.
      </div>
      <DataExtractorView
        data-testid={testid}
        templateId={selectedTemplate}
        loading={report.isLoading || reportDetails.isLoading || reportFile.isLoading || createExtraction.isLoading}
        report={report.data}
        createExtraction={handleCreateExtraction}
        reportDetails={reportDetails.data}
        reportFile={reportFile.data}
      />
      <Dialog
        dialogTitle={`${detailsOpen?.name} - Details`}
        StartIcon={Icon.View}
        open={!!detailsOpen}
        onClose={onCloseDetailsModal}
        data-testid={createTestid('dialog')}
      >
        {detailsOpen && (
          <ExtractorTemplateDetailsModal
            template={detailsOpen}
            setClose={onCloseDetailsModal}
            data-testid={createTestid('details-modal')}
          />
        )}
      </Dialog>
    </>
  );
};
