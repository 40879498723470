/* eslint-disable react-hooks/rules-of-hooks */
import { isFunction, isObject, objectReduce, useCallbackImmutable } from 'mns-components';
import type {
  FetchQueryOptions,
  MutationOptions,
  QueryClient,
  QueryObserverOptions,
  UseMutationOptions,
  UseMutationResult as UseMutationResultQuery,
} from 'react-query';
import { useMutation as useMutationQuery, useQueries, useQuery, useQueryClient } from 'react-query';

const PREQUERY_STALE_TIME = 5 * 60 * 60 * 1000; // 5 minutes

/** force size of args to be `length` */
const splitArgs = <Args extends AnyArray>(params: AnyArray, length: number): Args =>
  Array.from({ ...params, length }) as Args;

const splitArgsOptions = <TOptions, Args extends AnyArray>(params: AnyArray, length: number): [Args, TOptions] => [
  splitArgs(params, length),
  params.at(length) ?? {},
];

const splitArgsClient = <Args extends AnyArray>(params: AnyArray, length: number): [Args, QueryClient] => [
  splitArgs(params, length),
  params.at(length),
];

const splitArgsClientOptions = <TOptions, Args extends AnyArray>(
  params: AnyArray,
  length: number,
): [Args, QueryClient, TOptions] => [splitArgs(params, length), params.at(length), params.at(length + 1) ?? {}];

export type UseMutationResult<
  TData = unknown,
  TError = unknown,
  TVariables extends AnyArray = [],
  TContext = unknown,
> = Omit<UseMutationResultQuery<TData, TError, TVariables, TContext>, 'mutate' | 'mutateAsync'> & {
  mutate(...args: TVariables): void;
  mutateAsync(...args: TVariables): Promise<TData>;
};

export const useMutation = <TData = unknown, TError = unknown, TVariables extends AnyArray = [], TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> = {},
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const { mutate, mutateAsync, ...mut } = useMutationQuery(options);
  return {
    ...mut,
    mutate: useCallbackImmutable((...args: TVariables) => mutate(args)),
    mutateAsync: useCallbackImmutable((...args: TVariables) => mutateAsync(args)),
  };
};

/**
 * this function helps to explode a query function into react-query best practices, containing : useQuery, usePrequery,
 * useMutation, invalidateQuery, useInvalidateQuery, getQueryState, useQueryState, getQueryKey & getRootQueryKey.
 *
 * @example
 * const getTemplate = new DevelopQuery(dataExtractorApi.template.getTemplate, 'dataExtractorApi.template.getTemplate');
 *
 * export const Comp: React.FC = () => {
 *   const { data: test } = getTemplate.useQuery('123', { select: (data) => data.name });
 *   console.log(test);
 *   return null;
 * };
 */

export class DevelopQuery<
  Fn extends AnyFunction,
  Args extends AnyArray = Parameters<Fn>,
  TData extends Awaited<ReturnType<Fn>> = Awaited<ReturnType<Fn>>,
> {
  constructor(private fn: Fn, private fullName: string) {}

  // scope
  getQueryKey(...args: Args): [string, ...Args] {
    return [this.fullName, ...args];
  }

  getRootQueryKey(): [string] {
    return [this.fullName];
  }

  /**
   * Build query options used in `useQuery` or `useQueries`.
   * @param {Args} `...args` is a list of arguments to be sent to the API, of the same length as the request function.
   * @param {QueryObserverOptions<TData, unknown, TSelect>} `options` is an optional settings, next to `args` parameters.
   */
  getQueryOptions<TSelect = TData>(
    ...params: [...Args, Omit<QueryObserverOptions<TData, unknown, TSelect>, 'queryKey' | 'queryFn'> | undefined] | Args
  ): QueryObserverOptions<TData, unknown, TSelect> {
    const [args, { staleTime = PREQUERY_STALE_TIME, ...options }] = splitArgsOptions<
      QueryObserverOptions<TData, unknown, TSelect>,
      Args
    >(params, this.fn.length);
    return {
      ...options,
      queryKey: this.getQueryKey(...args),
      queryFn: () => this.fn(...args),
      staleTime,
    };
  }

  // request
  /**
   * Run hook `useQuery` in your component to fetch data.
   * @param {Args} `...args` is a list of arguments to be sent to the API, of the same length as the request function.
   * @param {QueryObserverOptions<TData, unknown, TSelect>} `options` is an optional settings, next to `args` parameters.
   */
  useQuery<TSelect = TData>(
    ...params:
      | [...Args, Omit<QueryObserverOptions<TData, unknown, TSelect>, 'mutationKey' | 'mutationFn'> | undefined]
      | Args
  ) {
    return useQuery<TData, unknown, TSelect>(this.getQueryOptions(...params));
  }

  /**
   * Build a mutation query options used in `useMutation`.
   * @param {MutationOptions<TData, unknown, TSelect>} `options` is an optional settings.
   */
  getMutationOptions(
    options?: Omit<MutationOptions<TData, unknown, Args>, 'mutationKey' | 'mutationFn'>,
  ): MutationOptions<TData, unknown, Args> {
    return {
      ...options,
      mutationKey: this.getRootQueryKey(),
      mutationFn: (args) => this.fn(...args),
    };
  }

  /**
   * Run hook `useMutation` in your component to get a callback for sending data.
   * @param {MutationOptions<TData, unknown, Args>} `options` is an optional settings.
   */
  useMutation(options?: Omit<MutationOptions<TData, unknown, Args>, 'mutationKey' | 'mutationFn'>) {
    return useMutation<TData, unknown, Args>(this.getMutationOptions(options));
  }

  // prequery
  /**
   * Run `prequery` to prefetch data, and insert response in `react-query` cache.
   * @param {Args} `...args` is a list of arguments to be sent to the API, of the same length as the request function.
   * @param {QueryClient} `queryClient` is a mandatory `react-query` manager, next to `args` parameters. You can get manager by typing `const queryClient = useQueryClient();` in your component.
   * @param {QueryObserverOptions<TData>} `options` is an optional settings, next to `queryClient` parameter.
   */
  async prequery(
    ...params:
      | [...Args, QueryClient, Omit<QueryObserverOptions<TData>, 'mutationKey' | 'mutationFn'> | undefined]
      | [...Args, QueryClient]
  ) {
    const [args, queryClient, { staleTime = PREQUERY_STALE_TIME, ...options }] = splitArgsClientOptions<
      QueryObserverOptions<TData>,
      Args
    >(params, this.fn.length);
    return queryClient.prefetchQuery<TData, unknown, TData, [string, ...Args]>(
      this.getQueryKey(...args),
      () => this.fn(...args),
      {
        staleTime,
        ...options,
      } as FetchQueryOptions<TData, unknown, TData, [string, ...Args]>,
    );
  }

  /**
   * Run hook `usePrequery` in your component to get a callback for prefetching data, and insert response in `react-query` cache.
   * @param {QueryObserverOptions<TData>} `options` is an optional settings.
   */
  usePrequery(options: Omit<QueryObserverOptions<TData>, 'mutationKey' | 'mutationFn'> = {}) {
    const queryClient = useQueryClient();
    return useCallbackImmutable((...argsRaw: Args) => {
      const args = splitArgs<Args>(argsRaw, this.fn.length);
      this.prequery(...args, queryClient, options);
    });
  }

  // state
  /**
   * Run `getQueryState` to get state of query logged in `react-query` cache.
   * @param {Args} `...args` is a list of arguments to be sent to the API, of the same length as the request function.
   * @param {QueryClient} `queryClient` is a mandatory `react-query` manager, next to `args` parameters. You can get manager by typing `const queryClient = useQueryClient();` in your component.
   */
  getQueryState(...params: [...Args, QueryClient]) {
    const [args, queryClient] = splitArgsClient<Args>(params, this.fn.length);
    return queryClient.getQueryState<TData, unknown>(this.getQueryKey(...args));
  }

  /**
   * Run `useQueryState` in your component to get a callback for loading state of query logged in `react-query` cache.
   */
  useQueryState() {
    const queryClient = useQueryClient();
    return useCallbackImmutable((...argsRaw: Args) => {
      const args = splitArgs<Args>(argsRaw, this.fn.length);
      return this.getQueryState(...args, queryClient);
    });
  }

  // invalidate
  /**
   * Run `invalidateQuery` to clear `react-query` cache in your request, and force components to reload it.
   * @param {Args} `...args` is a list of arguments to be sent to the API, of the same length as the request function.
   * @param {QueryClient} `queryClient` is a mandatory `react-query` manager, next to `args` parameters. You can get manager by typing `const queryClient = useQueryClient();` in your component.
   */
  async invalidateQuery(...params: [...Args, QueryClient]) {
    const [args, queryClient] = splitArgsClient<Args>(params, this.fn.length);
    return queryClient.invalidateQueries(this.getQueryKey(...args));
  }

  /**
   * Run `useInvalidateQuery` in your component to get a callback for clearing `react-query` cache in your request, and force components to reload it.
   */
  useInvalidateQuery() {
    const queryClient = useQueryClient();
    return useCallbackImmutable(async (...argsRaw: Args) => {
      const args = splitArgs<Args>(argsRaw, this.fn.length);
      await this.invalidateQuery(...args, queryClient);
    });
  }

  /**
   * Run `invalidateRootQuery` to clear `react-query` cache in your request, and force components to reload it.
   * @param {QueryClient} `queryClient` is a mandatory `react-query` manager, next to `args` parameters. You can get manager by typing `const queryClient = useQueryClient();` in your component.
   */
  async invalidateRootQuery(queryClient: QueryClient) {
    return queryClient.invalidateQueries(this.getRootQueryKey());
  }

  /**
   * Run `useInvalidateRootQuery` in your component to get a callback for clearing `react-query` cache in your request, and force components to reload it.
   */
  useInvalidateRootQuery() {
    const queryClient = useQueryClient();
    return useCallbackImmutable(async () => {
      await this.invalidateRootQuery(queryClient);
    });
  }

  /**
   * Run hook `useQueries` in your component to fetch multiple data from `this` single entry.
   * @param {Args[]} `argList` is a list of arguments to be sent to the API, each item is fetching data from `this` entry.
   * @param {QueryObserverOptions<TData, unknown, TSelect>} `options` is an optional settings, next to `args` parameters, applied on each fetch.
   */
  useQueries<TSelect = TData>(
    argList: Args[],
    options?: Omit<QueryObserverOptions<TData, unknown, TSelect>, 'mutationKey' | 'mutationFn'>,
  ) {
    return useQueries(argList.map((args) => this.getQueryOptions(...args, options)));
  }

  get raw() {
    return this.fn;
  }
}

type DevelopNsQuery<Ns extends AnyObject<string, AnyFunction | AnyObject>> = {
  [k in keyof Ns]: Ns[k] extends AnyFunction
    ? DevelopQuery<Ns[k]>
    : Ns[k] extends AnyObject
    ? ReturnType<typeof developNamespaceQuery<Ns[k]>>
    : never;
};

/**
 * this function helps to explode a namespace of query functions into react-query best practices, containing : useQuery
 * usePrequery, useMutation, invalidateQuery, useInvalidateQuery, getQueryState, useQueryState, getQueryKey & getRootQueryKey.
 *
 * @example
 * const dataExtractor = developNamespaceQuery(dataExtractorApi, 'dataExtractorApi');
 *
 * export const Comp: React.FC = () => {
 *   const { data: test } = dataExtractor.template.getTemplate.useQuery('123', { select: (data) => data.name });
 *   console.log(test);
 *   return null;
 * };
 */

export const developNamespaceQuery = <Ns extends AnyObject<string, AnyFunction | AnyObject>>(
  ns: Ns,
  fullName: string,
) =>
  objectReduce(
    ns,
    (acc, fnObj, key) => {
      if (isFunction(fnObj)) {
        acc[key] = new DevelopQuery(fnObj, `${fullName}.${key}`);
      } else if (isObject(fnObj)) {
        acc[key] = developNamespaceQuery(fnObj, `${fullName}.${key}`);
      }
      return acc;
    },
    {} as AnyObject,
  ) as DevelopNsQuery<Ns>;
