import { colors, makeStyles } from 'mns-components';

export const automatedExtractionsSettingsStyles = makeStyles({
  sendStatusRender: {
    width: '8rem',
    height: '3.125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  scroll: {
    overflowX: 'clip',
    overflowY: 'auto',
  },
  arrow: {
    color: '#EFF2FE',
  },
  tooltip: {
    cursor: 'pointer',
    backgroundColor: '#EFF2FE',
    color: colors.defaultFontColor,
    fontSize: '.75rem',
    fontFamily: 'Rubik',
  },
  icon: {
    marginRight: '.5rem',
    top: '.25rem',
    position: 'relative',
  },
  dialog: {
    '&&': {
      borderBottom: `solid 2px ${colors.borderLight}`,
    },
  },
  detailsDialogTitle: {
    '&&': {
      color: colors.blueColor,
    },
  },
  detailsDialogSection: {
    marginBottom: '2rem',
  },
  detailsDialogTableTitle: {
    borderBottom: `solid .01rem ${colors.borderBlue}`,
    margin: '.6rem 0',
    paddingBottom: '.4rem',
  },
  toggleStatus: {
    '&&': {
      width: '3.5rem',
    },
  },
  inputWidth: {
    display: 'block',
    width: 'auto',
  },
  ptfDataPointCounter: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    left: '.3rem',
    bottom: '.15rem',
    width: '1.4rem',
    height: '1.4rem',
    fontSize: '.9rem',
    textAlign: 'center',
    borderRadius: '5rem',
    backgroundColor: colors.blueGreyColor,
  },
  list: {
    paddingTop: '1.2rem',
    paddingBottom: '.5rem',
    borderBottom: '.1rem solid #F4F4F5',
    '&:first-of-type': {
      paddingTop: 0,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  listSubHeader: {
    paddingBottom: '.1rem',
    color: '#10113b',
  },
  accordion: {
    width: '100%',
    padding: 0,
    paddingTop: '.4rem',
    boxShadow: 'none',
    borderRadius: 0,
  },
  accordionDivider: {
    borderBottom: `1px solid ${colors.lightGreyColor}`,
  },
  accordionSummary: {
    minHeight: 'inherit',
    paddingTop: '.4rem',
    paddingBottom: '.4rem',
    '& > .MuiAccordionSummary-content': {
      margin: 0,
      flexDirection: 'column',
      minWidth: 0,
    },
  },
  accordionDetails: {
    paddingTop: 0,
  },
  accordionTitle: {
    fontSize: '1.1rem',
    margin: 0,
  },
  accordionSubtitle: {
    margin: 0,
    height: 0,
    paddingRight: '.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '.8rem',
    color: 'transparent',
    transition: '.2s color, .3s height',
  },
  accordionSubtitleVisible: {
    transition: '.2s color, .2s height',
    height: '1.1rem',
    color: colors.darkGreyColor,
  },
  templateName: {
    marginTop: '.3rem',
    marginBottom: 0,
    fontSize: '.9rem',
    color: '#969494',
  },
  subAccordion: {
    paddingTop: 0,
  },
  subAccordionSummary: {
    paddingTop: '.5rem',
  },
  subAccorcionExpandIcon: {
    color: colors.blueColor,
  },
  subAccordionTitle: {
    fontSize: '1rem',
    color: colors.blueColor,
    marginBottom: '.3rem',
  },
  listItemText: {
    marginBottom: '.4rem',
    fontSize: '.8rem',
    color: colors.darkGreyColor,
  },
  collapseButton: {
    marginTop: '.3rem',
    marginBottom: 0,
    fontSize: '.9rem',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  filtersWrapper: {
    padding: '0.5rem 1rem 0.5rem 0.5rem',
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',

    '&& > *': {
      margin: '.5rem 1rem .5rem 0',
    },
  },
});
