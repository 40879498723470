import { Stack } from '@mui/material';
import { AppMargin, Button, Icon, PeriodPicker, SearchField, useTestid } from 'mns-components';

type PastDeliveriesControllerProps = {
  search: string;
  period: [start: Date | null, end: Date | null];
  dateFieldName: string;
  navElement?: React.ReactNode;
  onSearch?(value: string): void;
  onChangePeriod?(period: [start: Date | null, end: Date | null]): void;
  onCreateDelivery?(): void;
  'data-testid': string;
};

export const PastDeliveriesController: React.FC<PastDeliveriesControllerProps> = ({
  search,
  period: [start, end],
  dateFieldName,
  navElement,
  onSearch,
  onChangePeriod,
  onCreateDelivery,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  return (
    <AppMargin>
      <Stack direction="row" gap="1rem" flexWrap="wrap">
        <Stack direction="row" gap="1rem" alignItems="center" justifyContent="flex-start" flexGrow={1}>
          <SearchField
            value={search}
            name="search"
            disableMargin
            onChange={onSearch}
            data-testid={createTestid('search')}
          />
          <PeriodPicker
            uncontrolled
            variant="inline"
            name="navDatePeriod"
            label={`${dateFieldName} period`}
            disableMargin
            onFieldChange={onChangePeriod}
            start={start}
            end={end}
            data-testid={createTestid('periodPicker')}
          />
        </Stack>
        <Stack direction="row" gap="1rem" alignItems="center" justifyContent="flex-end" flexGrow={1}>
          {navElement}
          <Button
            color="primary"
            outlined
            startIcon={<Icon.Invite data-testid={createTestid('icon-sendTo')} />}
            onClick={onCreateDelivery}
            data-testid={createTestid('button-sendTo')}
          >
            Send to
          </Button>
        </Stack>
      </Stack>
    </AppMargin>
  );
};
