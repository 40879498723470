import type { GridApi } from 'ag-grid-community';
import {
  Backdrop,
  Button,
  Dialog,
  EmptyState,
  Icon,
  SearchField,
  SelectFiltersMulti,
  snakeCase,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteExtraction, useGetExtractions, useUpdateExtractionStatus } from '../../../api/extractionsData';
import { toastUpdateOrCreate } from '../../../common/toast';
import { downloadFile } from '../../../common/utils';
import { dataExtractorApi } from '../../../store/apis';
import {
  useManageOpenAutomatedExtractionDeleteModal,
  useManageOpenAutomatedExtractionDetailsModal,
  useOpenCreateExtractionModal,
} from '../hooks';
import { AutomatedExtractionDeleteModal } from './AutomatedExtractionDeleteModal';
import { AutomatedExtractionDetailsModal } from './AutomatedExtractionDetailsModal';
import type { BulkClickHandler } from './AutomatedExtractionsSettingsList';
import { AutomatedExtractionsSettingsList } from './AutomatedExtractionsSettingsList';
import { automatedExtractionsSettingsStyles as useStyles } from './styles/automatedExtractionsSettingsStyles';

const onDownloadExtraction: BulkClickHandler = async (row) => {
  try {
    const { url } = await dataExtractorApi.extraction.getExtractionFileLocation(row.id);
    downloadFile(url, `${snakeCase(row.extractionName)}.zip`);
    toast.success(`The automated extraction file download succeed`);
  } catch {
    toast.error("The automated extraction file couldn't be downloaded");
  }
};

export interface AutomatedExtractionsSettingsPageProps {
  'data-testid': string;
}

export const AutomatedExtractionsSettingsPage: React.FC<AutomatedExtractionsSettingsPageProps> = ({
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const gridApi = useRef<GridApi | null>(null);
  const [extractionFeedOptions, setExtractionFeedOptions] = useState<{ label: string; value: string }[]>([]);

  //request
  const {
    data: automaticExtractions,
    isLoading: isLoadingAutomaticExtractions,
    isError: isErrorAutomaticExtractions,
  } = useGetExtractions('AUTOMATIC', {
    onSuccess: (data) => {
      setExtractionFeedOptions(
        data.reduce((acc, { extractionFeed }) => {
          if (!acc.filter(({ value }) => value === extractionFeed).length)
            acc.push({ label: extractionFeed, value: extractionFeed });

          return acc;
        }, [] as { label: string; value: string }[]),
      );
    },
  });
  const { mutateAsync: updateExtractionStatus } = useUpdateExtractionStatus();
  const { mutateAsync: deleteAutomaticExtraction } = useDeleteExtraction();

  // modal
  const onOpenCreateExtractionModal = useOpenCreateExtractionModal();
  const [extractionIdDetails, setOpenDetails, setCloseDetails] = useManageOpenAutomatedExtractionDetailsModal();
  const [extractionIdDelete, setOpenDelete, setCloseDelete] = useManageOpenAutomatedExtractionDeleteModal();

  const extractionDetailsOpen = useMemo(
    () => automaticExtractions?.find((ext) => ext.id === extractionIdDetails),
    [automaticExtractions, extractionIdDetails],
  );
  const extractionDeleteOpen = useMemo(
    () => automaticExtractions?.find((ext) => ext.id === extractionIdDelete),
    [automaticExtractions, extractionIdDelete],
  );

  // form
  const methods = useForm({
    defaultValues: {
      search: '',
      extractionFeed: [] as string[],
    },
  });
  const { watch } = methods;
  const [extractionFeed, search] = watch(['extractionFeed', 'search']);

  const resetSearchField = useCallbackImmutable(() => methods.resetField('search'));

  // table
  const displayedAutomaticExtractions = useMemo(() => {
    let filteredExtractions = automaticExtractions;
    // is extraction feed selected?
    if (filteredExtractions?.length && extractionFeed?.length) {
      filteredExtractions = filteredExtractions.filter((extraction) =>
        extractionFeed.includes(extraction.extractionFeed),
      );
    }
    return filteredExtractions;
  }, [automaticExtractions, extractionFeed]);

  const onToggleStatus: BulkClickHandler = useCallbackImmutable(async (row) => {
    const isActivate = row.extractionStatus === 'ACTIVATED';
    const toastId = toast.info('The automated extraction activation is switching');
    try {
      await updateExtractionStatus(row.id, isActivate ? 'DEACTIVATED' : 'ACTIVATED');
      toastUpdateOrCreate(toastId, {
        render: `Automated extraction has been ${isActivate ? 'turned off' : 'activation'} with success`,
        type: 'success',
      });
    } catch {
      toastUpdateOrCreate(toastId, { render: "The automated extraction couldn't be activated", type: 'error' });
    }
  });

  const onExportAll = useImmutable(() => () => gridApi.current?.exportDataAsExcel());

  const onDisplayDetails: BulkClickHandler = useCallbackImmutable((row) => setOpenDetails(row.id));
  const onDeleteExtraction: BulkClickHandler = useCallbackImmutable((row) => setOpenDelete(row.id));

  const onConfirmDeleteExtraction: BulkClickHandler = useCallbackImmutable(async ({ id }) => {
    const toastId = toast.info('The automated extraction delete is being proccessed');
    try {
      await deleteAutomaticExtraction(id);
      setCloseDelete();
      toastUpdateOrCreate(toastId, { render: 'The automated extraction delete succeed', type: 'success' });
    } catch {
      toastUpdateOrCreate(toastId, { render: "The automated extraction couldn't be deleted", type: 'error' });
    }
  });

  if (isLoadingAutomaticExtractions) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!isLoadingAutomaticExtractions && isErrorAutomaticExtractions) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to load extractions"
        firstParagraph="The request ended with error, please try to load it again or contact administrator"
        data-testid={createTestid('error-extractions')}
      />
    );
  }

  if (!automaticExtractions?.length) {
    return (
      <EmptyState
        title="No extraction available"
        firstParagraph="Start extracting your portfolios"
        firstButtonText="Create new extraction"
        firstButtonProps={{
          color: 'secondary',
          startIcon: <Icon name="extraction" color="inversed" data-testid={createTestid('icon-createExtraction')} />,
          'data-testid': createTestid('firstButton'),
        }}
        firstOnClickButton={onOpenCreateExtractionModal}
        data-testid={createTestid('empty-extractions')}
      />
    );
  }

  return (
    <>
      <div className={classes.filtersWrapper}>
        <div className={classes.filtersContainer}>
          <FormProvider {...methods}>
            <SearchField
              value={search}
              name="search"
              uncontrolled={false}
              onClear={resetSearchField}
              data-testid={createTestid('input-search')}
            />
            <SelectFiltersMulti
              FormControlProps={{ style: { marginLeft: 'auto' } }}
              name="extractionFeed"
              label="Extraction feed"
              variant="labeled"
              options={extractionFeedOptions}
              data-testid={createTestid('input-extractionFeed')}
            />
            <Button
              startIcon={<Icon name="download" color="inversed" data-testid={createTestid('icon-export')} />}
              color="primary"
              size="medium"
              disabled={!automaticExtractions?.length}
              onClick={onExportAll}
              data-testid={createTestid('button-export')}
            >
              Export table
            </Button>
          </FormProvider>
        </div>
      </div>
      <AutomatedExtractionsSettingsList
        quickFilterText={search}
        rowData={displayedAutomaticExtractions}
        getGridApiRef={gridApi}
        onToggleStatus={onToggleStatus}
        onDisplayDetails={onDisplayDetails}
        onDeleteExtraction={onDeleteExtraction}
        onDownloadExtraction={onDownloadExtraction}
        data-testid={createTestid('list')}
      />
      <Dialog
        dialogTitle="Scope Details"
        dialogTitleProps={{ className: classes.detailsDialogTitle }}
        StartIcon={Icon.Details}
        open={!!extractionDetailsOpen}
        onClose={setCloseDetails}
        data-testid={createTestid('dialog-details')}
      >
        {extractionDetailsOpen && (
          <AutomatedExtractionDetailsModal
            extractionDetails={extractionDetailsOpen}
            onClose={setCloseDetails}
            data-testid={createTestid('modal-details')}
          />
        )}
      </Dialog>
      <Dialog
        dialogTitle="Delete Extraction"
        StartIcon={Icon.Delete}
        open={!!extractionDeleteOpen}
        onClose={setCloseDelete}
        data-testid={createTestid('dialog-delete')}
      >
        {extractionDeleteOpen && (
          <AutomatedExtractionDeleteModal
            extractionDetails={extractionDeleteOpen}
            onClose={setCloseDelete}
            onDelete={onConfirmDeleteExtraction}
            data-testid={createTestid('modal-delete')}
          />
        )}
      </Dialog>
    </>
  );
};
