import { IconButton, Menu } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useState } from 'react';

export interface MoreDetailPastDeliveriesRendererProps {
  emailDeliveryId: string;
  setSelected: (selectedItem: string) => void;
  'data-testid': string;
}

const MoreDetailPastDeliveriesRenderer = ({
  emailDeliveryId,
  setSelected,
  'data-testid': testid,
}: MoreDetailPastDeliveriesRendererProps) => {
  const createTestid = useTestid(testid);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  const handleClickDetails = useCallbackImmutable(() => {
    setSelected(emailDeliveryId);
    handleClose();
  });

  return (
    <>
      <IconButton color="secondary" onClick={handleClick} data-testid={createTestid('button-menu')}>
        <Icon.More data-testid={createTestid('icon-menu')} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClickDetails} data-testid={createTestid('menu-button-details')}>
          Show details
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreDetailPastDeliveriesRenderer;
