import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateLocal,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
} from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { UserInformationsButtons } from './UserInformationsButtons';
import { UserInformationSwitchs } from './UserInformationSwitchs';

const getActionColDef = makeActionColDef<AccountApi.User>();

type UseColumnDefsProps = {
  isAdmin: boolean;
  onEdit: UsersInformationsProps['onEdit'];
  onChangeAdmin(user: AccountApi.UserRequest, value: boolean): void;
  onChangeActivate(user: AccountApi.User, value: boolean): void;
  'data-testid': string;
};

const useColumnDefs = ({
  isAdmin,
  onEdit,
  onChangeAdmin,
  onChangeActivate,
  'data-testid': testid,
}: UseColumnDefsProps) =>
  useMemo(
    (): AggridTableColumn<AccountApi.User>[] => [
      {
        field: 'username',
        headerName: 'Username',
        minWidth: 200,
      },
      {
        field: 'familyName',
        headerName: 'Name',
        minWidth: 250,
        cellRendererFramework: ({ data: { name, familyName } }) => `${name} ${familyName}`,
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 270,
      },
      {
        field: 'creationDate',
        headerName: 'Created on',
        minWidth: 110,
        sort: 'desc',
        cellRendererFramework: ({ value }) => convertToDateLocal(value),
      },
      {
        field: 'applications',
        headerName: 'Applications',
        width: 150,
        cellRendererFramework: ({ value }) => value.length,
      },
      {
        field: 'isAdmin',
        headerName: 'Admin',
        width: 100,
        menuTabs: [],
        cellRendererFramework: ({ value, data }) => (
          <UserInformationSwitchs
            data={data}
            checked={value}
            onChange={onChangeAdmin}
            disabled={!isAdmin}
            data-testid={`${testid}-switch-admin`}
          />
        ),
      },
      {
        field: 'enabled',
        headerName: 'Activated',
        width: 100,
        menuTabs: [],
        cellRendererFramework: ({ value, data }) => (
          <UserInformationSwitchs
            data={data}
            checked={value}
            onChange={onChangeActivate}
            disabled={!isAdmin}
            data-testid={`${testid}-switch-activate`}
          />
        ),
      },
      getActionColDef('username', 70, ({ data }) => (
        <UserInformationsButtons
          data={data}
          onEdit={onEdit}
          disabled={!isAdmin}
          data-testid={`${testid}-button-update-${data.username}`}
        />
      )),
    ],
    [isAdmin, onChangeActivate, onChangeAdmin, onEdit, testid],
  );

type UsersInformationsProps = {
  rowData?: AccountApi.User[];
  setGridApi?(gridApi: GridApi | null): void;
  onEdit?(row: AccountApi.User): void;
  onChangeAdmin(user: AccountApi.UserRequest, value: boolean): void;
  onChangeActivate(user: AccountApi.User, value: boolean): void;
  isAdmin: boolean;
  'data-testid': string;
};

export const UsersInformationsGrid: React.FC<UsersInformationsProps> = ({
  rowData,
  setGridApi,
  onEdit,
  onChangeAdmin,
  onChangeActivate,
  isAdmin,
  'data-testid': testid,
}: UsersInformationsProps) => {
  return (
    <AggridTable
      rowData={rowData}
      getGridApiRef={setGridApi}
      columnDefs={useColumnDefs({ isAdmin, onEdit, onChangeAdmin, onChangeActivate, 'data-testid': testid })}
      getRowNodeId={useImmutable(() => (row: AccountApi.User) => row.username)}
      defaultColDef={useDefaultColDef<AccountApi.User>()}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationPageSize={10}
      data-testid={testid}
    />
  );
};
