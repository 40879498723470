import type { JsonSchemaObject } from 'mns-components';
import { FormCreator, makeForm } from 'mns-components';
import type { Quality } from 'mns-sdk-collect';
import { qualities } from 'mns-sdk-collect';
import { useState, useEffect } from 'react';
import { qualityLabelMapper, qualityLabelUnMapper } from '../../../../components/cellRender/QualitiesRenderer';
import { collectHistoryStyles as useStyles } from './CollectHistory.styles';

type CollectHistoryFilters = { qualities: readonly string[] };

const jsonSchema: JsonSchemaObject = {
  properties: {
    qualities: {
      type: 'array',
      title: 'Qualities',
      items: {
        type: 'string',
        enum: qualities.map((quality) => qualityLabelMapper[quality]),
      },
    },
  },
};

type CollectHistoryControllerProps = {
  onQualityChange(qualities: Quality[]): void;
  'data-testid': string;
};

const CollectHistoryControllerInputs: React.FC<CollectHistoryControllerProps> = ({
  onQualityChange,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<CollectHistoryFilters>({
    qualities: qualities.map((quality) => qualityLabelMapper[quality]),
  });

  useEffect(() => {
    onQualityChange(state.qualities.slice().map((quality) => qualityLabelUnMapper[quality]));
  }, [state.qualities, onQualityChange]);

  return (
    <FormCreator<CollectHistoryFilters>
      className={classes.controller}
      jsonSchema={jsonSchema}
      setState={setState}
      state={state}
      data-testid={testid}
    />
  );
};

export const CollectHistoryController = makeForm(CollectHistoryControllerInputs);
