import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import { Backdrop, lowerCase, useNavigate, useTestid, useCallbackImmutable, EmptyStateV2 } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { getCollectRoute } from '../../../applications/collectionApp/routes';
import { PortfoliosCollectedList } from './PortfoliosCollectedList';

const generateSeeAllRoute = () => generatePath(getCollectRoute('collection-inventories').link);

type PortfoliosCollectedRequestProps = {
  ptfList: CollectApi.Portfolio[] | undefined;
  searchValue: string;
  organisationName: string;
  onRowSelected: React.Dispatch<React.SetStateAction<CollectApi.Portfolio[]>>;
  getGridApiref(api: GridApi | null): void;
  'data-testid': string;
};

export const PortfoliosCollectedRequest: React.FC<PortfoliosCollectedRequestProps> = ({
  ptfList,
  searchValue,
  organisationName,
  onRowSelected,
  getGridApiref,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const navigate = useNavigate();

  const filteredPtf = useMemo(
    () =>
      organisationName
        ? ptfList?.filter((ptf) => lowerCase(ptf.organisationName).includes(lowerCase(organisationName)))
        : ptfList,
    [organisationName, ptfList],
  );

  const navigateToCollect = useCallbackImmutable(() => navigate(generateSeeAllRoute()));

  if (!ptfList) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (ptfList?.length === 0 || !filteredPtf || filteredPtf?.length === 0) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="noSearchResults"
          title="There is no collected portfolio in this nav date period yet!"
          subTitle="You can search again with a new range period or collect a new one."
          buttonText="Go to Collection application"
          buttonVariant="primary"
          buttonOnClick={navigateToCollect}
          data-testid={createTestid('empty-portfolio')}
        />
      </Stack>
    );
  }

  return (
    <PortfoliosCollectedList
      searchValue={searchValue}
      rowData={filteredPtf}
      onRowSelected={onRowSelected}
      getGridApiref={getGridApiref}
      data-testid={createTestid('list')}
    />
  );
};
