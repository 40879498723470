// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
export const VERSION = process.env.REACT_APP_VERSION;
/// export const DEBUG_INFO_ENABLED: boolean = !!process.env.REACT_APP_DEBUG_INFO_ENABLED;
//
export const DEBUG_INFO_ENABLED = false;
export const URL_API_COLLECT = process.env.REACT_APP_URL_API_COLLECT;
export const URL_API_MARKETPLACE = process.env.REACT_APP_URL_API_MARKETPLACE;
export const BUILD_TIMESTAMP = process.env.REACT_APP_BUILD_TIMESTAMP;

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID;
export const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;
export const COGNITO_SSO_DOMAIN = process.env.REACT_APP_COGNITO_SSO_DOMAIN;
export const COGNITO_IDP_DOMAIN = process.env.REACT_APP_COGNITO_IDP_DOMAIN;
export const CLARITY_WIDGET_URL = process.env.REACT_APP_CLARITY_WIDGET_URL;
export const CLARITY_SFDR_URL = process.env.REACT_APP_CLARITY_SFDR_URL;
export const CLARITY_DOCUMENT_PLATFORM = process.env.REACT_APP_CLARITY_DOCUMENT_PLATFORM;
export const UTIL_DOCUMENT = process.env.REACT_APP_UTIL_DOCUMENT;
export const UTIL_DOCUMENT_ANALYTICS = process.env.REACT_APP_UTIL_DOCUMENT_ANALYTICS;
export const UTIL_DOCUMENT_FACTSHEET = process.env.REACT_APP_UTIL_DOCUMENT_FACTSHEET;
export const MANAOS_PORTFOLIO_TEMPLATE = process.env.REACT_APP_MANAOS_PORTFOLIO_TEMPLATE;
export const VIGEO_ESG_DOCUMENT_FACTSHEET = process.env.REACT_APP_VIGEO_ESG_DOCUMENT_FACTSHEET;
export const VIGEO_CLIMATE_DOCUMENT_FACTSHEET = process.env.REACT_APP_VIGEO_CLIMATE_DOCUMENT_FACTSHEET;
export const CLOUDFRONT_DNSNAME = process.env.REACT_APP_CLOUDFRONT_DNSNAME;
export const matomoID = process.env.REACT_APP_matomoID;

export const NODE_ENV =
  !process.env.NODE_ENV?.toLowerCase().startsWith('prod') &&
  process.env.REACT_APP_NODE_ENV?.toLowerCase().startsWith('dev')
    ? 'DEV'
    : 'PROD';

export const isDev = NODE_ENV === 'DEV';

export const IS_LOCALHOST = CLOUDFRONT_DNSNAME?.startsWith('localhost') ?? false;
