import { colors, makeStyles } from 'mns-components';

export const templateStyles = makeStyles({
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  dialog: {
    '&&': {
      borderBottom: `solid 2px ${colors.borderLight}`,
    },
  },
  scroll: {
    overflowX: 'clip',
    overflowY: 'auto',
  },
  inputWidth: {
    display: 'block',
    width: 'auto',
  },
});
