import JSZip from 'jszip';
import { fileExtension, generateUuid, upperCase } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { FileWithPath } from 'react-dropzone';
import { appCollectApi } from './apis';

const awsDeposit = async (file: Blob, url: string, encryption: string, encryptionKey: string, filename?: string) => {
  const headers: AnyObject = {
    'without-bearer': 'true',
    'x-amz-server-side-encryption': encryption,
    'x-amz-server-side-encryption-aws-kms-key-id': encryptionKey,
    'content-type': 'multipart/form-data',
  };
  if (filename) {
    headers['x-amz-meta-filename'] = filename;
  }

  await fetch(url, {
    method: 'PUT',
    headers: headers,
    body: file,
  });
};

export const PRESIGNED_ERRCODE = 'PRESIGNED_ERRCODE';
export const AWSDEPOSIT_ERRCODE = 'AWSDEPOSIT_ERRCODE';
export const TRIGGERETL_ERRCODE = 'TRIGGERETL_ERRCODE';
export const ZIP_ERRCODE = 'ZIP_ERRCODE';

export class ErrorList<T> extends Error {
  list: AnyArray<T>;

  constructor(message: string, list: AnyArray<T> = []) {
    super(message);
    this.list = list;
  }
}

const isFileZip = (file: File | FileWithPath) => upperCase(fileExtension(file.name) ?? '') === 'ZIP';
const isFileListContainingZip = (files: (File | FileWithPath)[]) => !!files.find(isFileZip);

const getUploadPresignedUrls = async (fileKeys: string[]) => {
  try {
    return await appCollectApi.getUploadPresignedUrls(fileKeys);
  } catch (e) {
    console.error(e);
    throw new Error(PRESIGNED_ERRCODE);
  }
};

const awsDepositFiles = async (
  filesDico: Record<string, File | FileWithPath>,
  presignedUrls: CollectApi.PresignedUrlV2[],
) => {
  const errFiles: (File | FileWithPath)[] = [];
  await Promise.all(
    presignedUrls.map(async ({ key, presignedUrl, encryption, encryptionKey }) => {
      const file = filesDico[key];
      if (file) {
        try {
          await awsDeposit(file, presignedUrl, encryption, encryptionKey);
        } catch {
          errFiles.push(file);
        }
      }
    }),
  );
  if (errFiles[0]) {
    throw new ErrorList(AWSDEPOSIT_ERRCODE, errFiles);
  }
};

const triggerUploadEtl = async (fileKeys: string[], batchId: string) => {
  try {
    await appCollectApi.triggerUploadEtl(fileKeys, batchId);
  } catch {
    throw new Error(TRIGGERETL_ERRCODE);
  }
};

export const zipBuild = async (filesDico: Record<string, File | FileWithPath>) => {
  try {
    const zipBuilder = JSZip();
    Object.keys(filesDico).forEach((fileKey) => {
      zipBuilder.file(fileKey, filesDico[fileKey]);
    });
    return await zipBuilder.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 6 } });
  } catch {
    throw new Error(ZIP_ERRCODE);
  }
};

// https://ifsalpha.atlassian.net/browse/MNS-4937
export const uploadFiles = async (fileList: (File | FileWithPath)[]) => {
  const orgId = localStorage.getItem('organization-id') || '';
  const batchId = generateUuid();
  const filesDico = fileList.reduce((acc, file) => {
    let fileKey: string;
    do {
      fileKey = `${orgId}/${batchId}/${file.name}`;
    } while (acc[fileKey]);
    acc[fileKey] = file;
    return acc;
  }, {} as Record<string, File | FileWithPath>);

  // when upload list file is lower than 10 items, do not transform files and upload them as is: Rule 1
  // when upload list file contains a ZIP, do not transform files and upload them as is: Rule 4
  if (fileList.length < 10 || isFileListContainingZip(fileList)) {
    const fileKeys = Object.keys(filesDico);
    const presignedUrls = await getUploadPresignedUrls(fileKeys);
    await awsDepositFiles(filesDico, presignedUrls);
    await triggerUploadEtl(Object.keys(filesDico), batchId);
  } else {
    const zipFile = await zipBuild(filesDico);
    const zipFileName = generateUuid();
    const zipFileKey = `${orgId}/${batchId}/${zipFileName}.zip`;
    const [{ presignedUrl, encryption, encryptionKey }] = await getUploadPresignedUrls([zipFileKey]);
    try {
      await awsDeposit(zipFile, presignedUrl, encryption, encryptionKey);
    } catch {
      throw new ErrorList(AWSDEPOSIT_ERRCODE, Object.values(filesDico));
    }
    await triggerUploadEtl([zipFileKey], batchId);
  }
};

const isErrorList = (err: Error): err is ErrorList<File | FileWithPath> =>
  err instanceof ErrorList && Array.isArray(err.list);

export const manageUploads = async (myFiles: (File | FileWithPath)[]) => {
  const uploaded: string[] = [];
  const errors: Record<string, string[]> = {};
  try {
    await uploadFiles(myFiles);
    uploaded.push(...myFiles.map(({ name }) => name));
  } catch (err) {
    console.error(err);
    if (err instanceof Error) {
      if (!errors[err.message]) {
        errors[err.message] = [];
      }

      if (isErrorList(err)) {
        errors[err.message].push(...err.list.map(({ name }) => name));

        uploaded.push(
          ...myFiles
            .filter((file) => !(err as ErrorList<File | FileWithPath>).list.includes(file))
            .map(({ name }) => name),
        );
      } else {
        errors[err.message].push(...myFiles.map(({ name }) => name));
      }
    }
  }
  return {
    uploaded,
    errors,
  };
};
