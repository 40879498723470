import { IconButton } from '@mui/material';
import type { ListButtonType, OptionType } from 'mns-components';
import { makeForm, isEmail, TextField, List, useTestid, Icon, Typography, useCallbackImmutable } from 'mns-components';
import type { NotificationApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUser } from '../../../hooks/useAuth';
import { appNotification } from '../../../store/apis';

const useButtons = (onDelete: (value: string) => void): ListButtonType<string>[] =>
  useMemo(
    () => [
      {
        key: 'delete',
        icon: Icon.Delete,
        onClick: onDelete,
      },
    ],
    [onDelete],
  );

type ContactListSettingProps = { notifCode: NotificationApi.NotificationCode; 'data-testid': string };

export const ContactListSetting: React.FC<ContactListSettingProps> = ({ notifCode, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { organisationId: orgId } = useUser();
  const [contacts, setContacts] = useState<NotificationApi.ContactList['contactList']>([]);
  const [emailAdd, setEmailAdd] = useState('');
  const emailAddRef = useRef('');

  useEffect(() => {
    emailAddRef.current = emailAdd;
  }, [emailAdd]);

  const handleRemove = useCallbackImmutable(async (value: string) => {
    const list = contacts.slice();
    const indexToRemove = list.indexOf(value);
    list.splice(indexToRemove, 1);
    await appNotification.updateContactList({
      contactList: list,
      notificationCode: notifCode,
      organizationId: orgId,
      visibility: 'public',
    });
    setContacts(list);
  });

  const handleAdd = useCallbackImmutable(async () => {
    const newEmail = emailAddRef.current;
    if (isEmail(newEmail) && !contacts.includes(newEmail)) {
      const list = [...contacts, newEmail];
      await appNotification.updateContactList({
        contactList: list,
        notificationCode: notifCode,
        organizationId: orgId,
        visibility: 'public',
      });
      setContacts(list);
      setEmailAdd(() => '');
    }
  });

  useEffect(() => {
    appNotification.getContactList(orgId, notifCode).then(async (list) => {
      if (!list) {
        await appNotification.createContactList({
          contactList: [],
          notificationCode: notifCode,
          organizationId: orgId,
          visibility: 'public',
        });
      } else {
        setContacts(list);
      }
    });
  }, [setContacts, orgId, notifCode]);

  const currentList = useMemo(() => contacts.map((value): OptionType => ({ label: value, value })), [contacts]);
  const buttons = useButtons(handleRemove);

  return (
    <>
      <div>
        <TextField
          name="email"
          type="email"
          label="Email address"
          value={emailAdd}
          variant="outlined"
          onFieldChange={setEmailAdd}
          data-testid={createTestid('input-email')}
        />
        <IconButton
          color="secondary"
          data-testid={createTestid('button-addEmail')}
          onClick={handleAdd}
          disabled={!isEmail(emailAdd)}
        >
          <Icon.Add data-testid={createTestid('icon-addEmail')} />
        </IconButton>
      </div>
      <div>
        {currentList.length === 1 ? (
          <>
            <List items={currentList} data-testid={createTestid('email-list')} />
            <Typography variant="caption">You cannot remove the e-mail address as at least 1 must remain.</Typography>
          </>
        ) : (
          <List items={currentList} buttons={buttons} data-testid={createTestid('email-list')} />
        )}
      </div>
    </>
  );
};

export const ContactListSettingForm = makeForm(ContactListSetting);
