import { Stack } from '@mui/material';
import type { OptionType } from 'mns-components';
import {
  Typography,
  useTestid,
  objectToHash,
  Backdrop,
  convertToDateLocal,
  filterTruthy,
  makeForm,
  MultiSelectManaged,
  useAsyncLazy,
  useCallbackImmutable,
  Icon,
  Select as SelectInput,
  Switch,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { previousMonthLastDate } from '../../../../../common/date';
import { appCollectApi, etlApi } from '../../../../../store/apis';
import { settingsStyle as useStyles } from '../settingsStyle';
import { ChaseUpSettings } from './ChaseUpSettings';
import { chaseUpSettingsStyles as useSettingsStyles } from './chaseUpSettingsStyles';

import { DisseminationApplicationSettingsModal } from './DisseminationApplicationModalSettings';

type DissemSettings = CollectApi.AppDisseminationSettings['autoAcceptAccessRequestSettingDto'];
type Collector = DissemSettings['collectors'][number];

const app = 'data_dissemination';

const DisseminationApplicationSettingsInputs: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const settingsClasses = useSettingsStyles();
  const createTestid = useTestid(testid);

  const [openModalOnOrgName, setOpenModalOnOrgName] = useState<string>();
  const [formState, setFormState] = useState<DissemSettings | null>(null);
  const [formHash, setFormHash] = useState<string | null>(null);
  const [automatedDeliveryDeadlineExtensionInDays, setAutomatedDeliveryDeadlineExtensionInDays] = useState<
    number | null
  >(null);

  const { execute: loadScopes, result: scopesToShare } = useAsyncLazy(async () => {
    const data = await etlApi.getOutputTemplates();
    return data.map(({ label, id }): OptionType => ({ label, value: id }));
  });

  const { execute: loadSettings } = useAsyncLazy(async () => {
    const { autoAcceptAccessRequestSettingDto: settings, automatedDeliveryDeadlineExtensionInDays: deadlineExtension } =
      await appCollectApi.appSettings[app].get();
    setFormState(settings);
    setAutomatedDeliveryDeadlineExtensionInDays(deadlineExtension);
  });

  const { execute: loadCollectors, result: collectors } = useAsyncLazy(appCollectApi.getRequestAccessEmitters);

  const { execute: updateSettings } = useAsyncLazy(async (settings: CollectApi.AppDisseminationSettings) =>
    appCollectApi.appSettings[app].update(settings),
  );

  useEffect(() => {
    loadScopes();
    loadSettings();
    loadCollectors();
  }, [loadScopes, loadSettings, loadCollectors]);

  const toggleAutoAccept = useCallbackImmutable(() => {
    setFormState((current) => {
      if (current) {
        const newData = { ...current, enabled: !current.enabled };
        setFormHash(objectToHash(newData));
        return newData;
      }
      return current;
    });
  });

  const setOrganisationsAuthorized = useCallbackImmutable((updateOrgsAuth: (list: string[]) => string[]) => {
    setFormState((current) => {
      if (current && scopesToShare) {
        const init = current.collectors.map(({ collector: { name } }) => name);
        const list = updateOrgsAuth(init);

        const added = list.find((item) => !init.includes(item));
        if (added) {
          setOpenModalOnOrgName(() => added);
        }

        const foundList = filterTruthy(
          list.map((orgName) => {
            const foundCollector = collectors?.find((coll) => coll.name === orgName)?.id;
            if (foundCollector) {
              const alreadyExistingItem = current.collectors.find(({ collector: { id } }) => id === foundCollector);
              if (alreadyExistingItem) {
                return alreadyExistingItem;
              } else {
                const newOrg: Collector = {
                  embargo: 0,
                  scope: scopesToShare[0].value,
                  maxLookThroughLevel: 0,
                  collector: { id: foundCollector, name: orgName },
                  minStartPoint: convertToDateLocal(previousMonthLastDate),
                };
                return newOrg;
              }
            }
            return undefined;
          }),
        );
        const newData = { ...current, collectors: foundList };
        setFormHash(objectToHash(newData));
        return newData;
      }
      return null;
    });
  });

  const organisationsAuthorized = useMemo(
    () => formState?.collectors.map(({ collector: { name } }) => name) ?? [],
    [formState],
  );

  const collectorOptions = useMemo(() => collectors?.map(({ name }) => name) ?? [], [collectors]);

  useEffect(() => {
    if (formState && formHash) {
      updateSettings({
        autoAcceptAccessRequestSettingDto: formState,
        automatedDeliveryDeadlineExtensionInDays: automatedDeliveryDeadlineExtensionInDays!,
      });
    }
  }, [automatedDeliveryDeadlineExtensionInDays, formState, formHash, updateSettings]);

  const openCollector = useMemo(
    () => formState?.collectors.find((coll) => coll.collector.name === openModalOnOrgName),
    [formState?.collectors, openModalOnOrgName],
  );

  const handleClose = useCallbackImmutable(() => setOpenModalOnOrgName(() => undefined));

  const handleAutomatedDeliveryDeadlineChange = useCallbackImmutable((value: string) => {
    setAutomatedDeliveryDeadlineExtensionInDays(() => parseInt(value, 10));
    setFormHash(
      objectToHash({
        ...formState,
        automatedDeliveryDeadlineExtensionInDays: parseInt(value, 10),
      }),
    );
  });

  const updateCollector = useCallbackImmutable((update: Collector | ((current: Collector) => Collector)) =>
    setFormState((current) => {
      if (openCollector && current) {
        const value = typeof update === 'function' ? update(openCollector) : update;
        const newData = {
          ...current,
          collectors: current.collectors.map((item) => (item.collector.id === value.collector.id ? value : item)),
        };
        setFormHash(
          objectToHash({
            ...newData,
            automatedDeliveryDeadlineExtensionInDays: automatedDeliveryDeadlineExtensionInDays!,
          }),
        );
        return newData;
      }
      return current;
    }),
  );

  const automatedDeliveryDeadlineExtensionInDaysValues = [
    {
      value: `-1`,
      label: 'End of month',
    },
    {
      value: '0',
      label: 'Send only on sending day',
    },
    ...Array.from({ length: 15 }, (_, i) => i + 1).map((i) => ({
      value: `${i}`,
      label: `${i} BD after sending day`,
    })),
  ];

  if (!formState) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <>
      <div className={classes.label}>
        <div className={classes.field}>
          <div className={classes.settingsName}>Enable auto accept</div>
        </div>
        <div className={classes.input}>
          <Switch
            uncontrolled
            name="autoAccept"
            variant="secondary"
            value={formState.enabled}
            onChange={toggleAutoAccept}
            data-testid={createTestid('autoAccept')}
          />
        </div>
      </div>

      <div className={classes.settingsName}>Authorized collectors</div>

      <div className={classes.orgsContainer}>
        <MultiSelectManaged
          name="organisationsAuthorized"
          onManage={setOpenModalOnOrgName}
          options={collectorOptions}
          startIcon={<Icon.Profile color="secondary" data-testid={createTestid('icon-organisationsAuthorized')} />}
          value={organisationsAuthorized}
          setValue={setOrganisationsAuthorized}
          disabled={!formState.enabled}
          data-testid={createTestid('organisationsAuthorized')}
        />
      </div>
      <DisseminationApplicationSettingsModal
        collectorName={openModalOnOrgName}
        collector={openCollector}
        onClose={handleClose}
        setCollector={updateCollector}
        scopesToShare={scopesToShare}
        data-testid={createTestid('modal')}
      />
      <Typography variant={'h2'} className={classes.settingsCategory}>
        Automatic e-mailing
      </Typography>

      <Stack className={settingsClasses.twoColumns} style={{ flexDirection: 'row' }}>
        <Stack className={settingsClasses.descrColumn}>
          <Typography className={settingsClasses.info}>
            From sending date, continue sending remaining inventories until:
          </Typography>
        </Stack>
        <Stack className={settingsClasses.wideColumn}>
          {automatedDeliveryDeadlineExtensionInDays !== null && (
            <SelectInput
              label="Sending extension"
              name={'automatedDeliveryDeadlineExtensionInDays'}
              value={automatedDeliveryDeadlineExtensionInDays?.toString()}
              options={automatedDeliveryDeadlineExtensionInDaysValues}
              data-testid={createTestid('automatedDeliveryDeadlineExtensionInDays')}
              onFieldChange={handleAutomatedDeliveryDeadlineChange}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

const DisseminationApplicationSettingsForm = makeForm(DisseminationApplicationSettingsInputs);

export const DisseminationApplicationSettings: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <div data-testid={testid} className={classes.settingSection}>
      <Typography variant={'h2'} className={classes.settingsCategory}>
        Access request
      </Typography>
      <DisseminationApplicationSettingsForm data-testid={createTestid('accessRequest')} />
      <ChaseUpSettings data-testid={createTestid('chaseUp')} />
    </div>
  );
};
