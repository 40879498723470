import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { LoadPaginatedEvent } from 'mns-components';
import { EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';
import { api } from '../../../store/api';
import { FundMappingList } from './FundMappingList';

type FundMappingRequestProps = {
  searchValue: string;
  onUpdate(fundMapping: CollectApi.FundMapping): void;
  onDelete(fundMapping: CollectApi.FundMapping): void;
  gridApi: MutableRefObject<GridApi | null>;
  'data-testid': string;
};

export const FundMappingRequest: React.FC<FundMappingRequestProps> = ({
  searchValue,
  onUpdate,
  onDelete,
  gridApi,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [isError, setIsError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const queryParams = useRef({
    q: searchValue,
  });

  const handleUpdate = useCallbackImmutable((mapping: CollectApi.FundMapping) => {
    onUpdate(mapping);
  });

  const handleDelete = useCallbackImmutable((mapping: CollectApi.FundMapping) => {
    onDelete(mapping);
  });

  const loadPaginated = useCallbackImmutable(
    async ({ success, request: { startRow = 0, endRow = 100 } }: LoadPaginatedEvent<CollectApi.FundMapping>) => {
      const size = endRow - startRow;
      const page = Math.trunc(startRow / size);
      try {
        const data = await api.inventories.collect.getFundMappings.raw({ ...queryParams.current, page, size });
        setIsEmpty(data.totalElements === 0);
        success({ rowData: data.content, rowCount: data.totalElements });
      } catch {
        setIsError(true);
      }
    },
  );

  if (isError) {
    return (
      <EmptyStateV2
        useCase="dataErrors"
        variant="error"
        title="Oops, something went wrong!"
        subTitle="Please refresh your page or contact our support team"
        data-testid={createTestid('error')}
      />
    );
  }

  if (isEmpty) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="noActions"
          variant="info"
          title="There is no mapping yet!"
          data-testid={createTestid('empty')}
        />
      </Stack>
    );
  }

  return (
    <FundMappingList
      onLoadPaginated={loadPaginated}
      search={searchValue}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      gridApi={gridApi}
      data-testid={testid}
    />
  );
};
