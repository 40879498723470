import { colors, makeStyles } from 'mns-components';

export const selectTemplateStyles = makeStyles({
  search: {
    flexGrow: 2,
  },
  filterInput: {
    flexGrow: 1,
  },
});

export const cardStyles = makeStyles({
  card: {
    minWidth: 0,
    flex: '0 0 calc(33.333% - 6px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem 1rem 1rem 0',
    borderRadius: '1rem',
    border: `1px solid ${colors.borderLight}`,
    cursor: 'pointer',
    background: colors.whiteColor,
    transition: 'background .2s ease',

    '&:hover': {
      background: colors.whiteLessColor,
    },

    '& > .MuiRadio-root.Mui-checked': {
      color: colors.primaryPinkColor,
    },
  },
});
