import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.RUM_ROLE_ARN,
    identityPoolId: process.env.RUM_IDENTITY_POOL_ID,
    endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true,
  };

  const APPLICATION_ID = String(process.env.RUM_APPLICATION_ID);
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'eu-central-1';

  new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
