export const getPreviousTwoMonthLastDate = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth() - 1, 0));

export const firstDateAvailable = new Date(Date.UTC(2015, 0, 1));
export const now = new Date();
export const currentMonthLastDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0));
export const previousMonthLastDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 0));
export const previousMonthFirstDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() - 1, 1));
export const previousTwoMonthLastDate = getPreviousTwoMonthLastDate(now);
export const previousYearDate = new Date(Date.UTC(now.getFullYear() - 1, now.getMonth(), now.getDate()));
export const nextYearDate = new Date(Date.UTC(now.getFullYear() + 1, now.getMonth(), now.getDate()));
export const nextNewYearDate = new Date(Date.UTC(now.getFullYear() + 1, 0, 1));

export const subtractMonths = (date: Date, monthsToSubtract: number) => {
  const newDate = new Date(date);
  const currentMonth = newDate.getMonth();

  const yearsToSubtract = Math.floor(monthsToSubtract / 12);
  const remainingMonthsToSubtract = monthsToSubtract % 12;

  newDate.setFullYear(newDate.getFullYear() - yearsToSubtract);

  if (currentMonth < remainingMonthsToSubtract) {
    newDate.setFullYear(newDate.getFullYear() - 1);
    newDate.setMonth(currentMonth - remainingMonthsToSubtract + 12);
  } else {
    newDate.setMonth(currentMonth - remainingMonthsToSubtract);
  }

  return newDate;
};

export const subtractYears = (date: Date, yearsToSubtract: number) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() - yearsToSubtract);
  return newDate;
};

export const wait = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));
