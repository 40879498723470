import { Button, Dialogv2, Icon, useTestid } from 'mns-components';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateEmailList } from '../hooks';
import { CreateEmailListForm } from './OrganisationModalForm';

type OrganisationCreateModalProps = {
  open: boolean;
  onClose(): void;
  'data-testid': string;
};

export const OrganisationCreateModal: React.FC<OrganisationCreateModalProps> = ({
  open,
  onClose,
  'data-testid': testid,
}) => {
  const formName = 'form-email-list-create';
  const createTestid = useTestid(testid);

  const { mutate: createEmailList, isSuccess, isError, isLoading } = useCreateEmailList();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast.success('Email list successfuly created');
    }
    if (isError) {
      onClose();
      toast.error('Failed to create email list');
    }
  }, [isSuccess, onClose, isError]);

  return (
    <Dialogv2
      open={open}
      onClose={onClose}
      dialogTitle="Create email list"
      DialogTitleIcon={Icon.Add}
      data-testid={createTestid('dialog')}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoading}
          type="submit"
          form={formName}
          data-testid={createTestid('confirm')}
        >
          Create email list
        </Button>
      }
    >
      <CreateEmailListForm onSave={createEmailList} data-testid="create-modal" formId={formName} />
    </Dialogv2>
  );
};
