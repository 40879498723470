import { IconButton } from '@mui/material';
import { Icon, useTestid } from 'mns-components';
import { useMemo } from 'react';

type IsinListActionsProps = {
  isinListId: string;
  onEdit?(isinListId: string): void;
  onDelete?(isinListId: string): void;
  'data-testid': string;
};

export const IsinListActions: React.FC<IsinListActionsProps> = ({
  isinListId,
  onEdit,
  onDelete,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const handleEdit = useMemo(() => onEdit && (() => onEdit(isinListId)), [onEdit, isinListId]);
  const handleDelete = useMemo(() => onDelete && (() => onDelete(isinListId)), [onDelete, isinListId]);

  return (
    <>
      {handleEdit && (
        <IconButton title="Edit list" color="secondary" onClick={handleEdit} data-testid={createTestid('button-edit')}>
          <Icon.Edit data-testid={createTestid('icon-edit')} />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton
          title="Delete list"
          color="error"
          onClick={handleDelete}
          data-testid={createTestid('button-delete')}
        >
          <Icon.Delete data-testid={createTestid('icon-delete')} />
        </IconButton>
      )}
    </>
  );
};
