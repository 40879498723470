import type { SimpleTableProps } from 'mns-components';
import { Checkbox, SimpleTable, objectEntries } from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const columns: SimpleTableProps<EetApi.Portfolio>['columns'] = [
  {
    field: 'name',
    head: '',
    cell: ({ externalId }) => (
      <div style={{ height: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Checkbox label="" name={externalId} data-testid={'checkbox'} />
      </div>
    ),
    flex: 1,
  },
  {
    field: 'externalId',
    head: 'Financial Instrument ID',
    flex: 3,
  },
  {
    field: 'valuationDate',
    head: 'Nav Date',
    flex: 3,
  },
];

export type AvailableShareClassSimpleTableProps = {
  rows: EetApi.Portfolio[];
  selection: string[];
  onSelectionChange(list: string[]): void;
  'data-testid': string;
};

export const AvailableShareClassSimpleTable: React.FC<AvailableShareClassSimpleTableProps> = ({
  rows,
  selection,
  onSelectionChange,
  'data-testid': testid,
}) => {
  const methods = useForm({
    defaultValues: rows.reduce((acc, item) => {
      acc[item.externalId] = selection.includes(item.externalId);
      return acc;
    }, {} as AnyObject<string, boolean>),
  });

  useEffect(() => {
    return methods.watch(() => {
      onSelectionChange(
        objectEntries(methods.getValues())
          .filter(([, checked]) => checked)
          .map(([key]) => key),
      );
    }).unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <SimpleTable columns={columns} rows={rows} data-testid={testid} />
    </FormProvider>
  );
};
