import { Stack } from '@mui/material';
import {
  AppLayout,
  Button,
  FunnelHeader,
  FunnelSection,
  objectReduce,
  useCallbackImmutable,
  useImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { toast, type Id } from 'react-toastify';
import { toastUpdateOrCreate } from '../../../../common/toast';
import { useCreateTemplate, useFetchTemplates } from '../../hooks';
import { getEsgRoute } from '../../routes';
import type { TemplateData } from '../ExtractionFunnel/SelectTemplate';
import { SelectTemplateContainer } from './SelectTemplateContainer';
import type { TemplateNameFormData } from './SelectTemplateName';
import { SelectTemplateName } from './SelectTemplateName';

const errorsMessages = {
  duplicateName: 'There is already a template with the same name.',
  fetchTemplates: 'Something went wrong, please try again or contact support team.',
};

type TemplateFormData = {
  templateName: TemplateNameFormData;
  template: TemplateData;
};

const useSubmit = (formData: TemplateFormData, onEnd: () => void) => {
  const toastIdRef = useRef<Id>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: createTemplate, isSuccess: isCreatedTemplate, isError: isErrorTemplate } = useCreateTemplate();

  const handleCreateTemplate = useCallbackImmutable(() => {
    const columns: DataExtractorApi.Template.Pair[] = objectReduce(
      formData.template.selected,
      (acc, list, appCode) => {
        if (list) acc.push(...list.map((code) => ({ code, source: appCode })));
        return acc;
      },
      [] as DataExtractorApi.Template.Pair[],
    );
    createTemplate(columns, formData.templateName.name, false);
  });

  const handleSubmit = useCallbackImmutable(() => {
    setIsSubmitting(true);
    toastIdRef.current = toast.info('The template creation is in progress');
    handleCreateTemplate();
  });

  useEffect(() => {
    if (isCreatedTemplate) {
      toastUpdateOrCreate(toastIdRef.current, {
        type: 'success',
        render: 'The template has been created and will be available in a few minutes',
      });
      setIsSubmitting(false);
      onEnd();
    }
  }, [isCreatedTemplate, onEnd]);

  useEffect(() => {
    if (isErrorTemplate) {
      toastUpdateOrCreate(toastIdRef.current, {
        type: 'error',
        render: 'The creation of template failed',
      });
      setIsSubmitting(false);
    }
  }, [isErrorTemplate]);

  return [isSubmitting, handleSubmit] as const;
};

type TemplateFunnelProps = { 'data-testid': string };

export const TemplateFunnel: React.FC<TemplateFunnelProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const backRoute = useImmutable(() => generatePath(getEsgRoute('esg-extraction').link, { view: 'templates' }));

  // get templates names
  const {
    data: templatesNames,
    isLoading: isLoadingTemplatesNames,
    isError: isErrorTemplatesNames,
  } = useFetchTemplates({
    select: (list) => list.map((tpl) => tpl.name),
  });

  const accordionName = 'setup';
  const [expanded, setExpanded] = useState<string | null>(accordionName);

  const [formData, setFormData] = useState<TemplateFormData>({
    templateName: { name: '' },
    template: { templateType: '', selected: {}, matchTemplateId: null },
  });

  const handleNavigateAfterSubmit = useCallbackImmutable(() => {
    navigate(backRoute);
  });

  const [isSubmitting, handleSubmit] = useSubmit(formData, handleNavigateAfterSubmit);

  const errorTemplateName = (() => {
    if (templatesNames?.includes(formData.templateName.name)) return errorsMessages.duplicateName;
    if (isErrorTemplatesNames) return errorsMessages.fetchTemplates;
  })();

  const disabledSetupTemplate = isSubmitting || isLoadingTemplatesNames;
  const disabledSelectTemplate = disabledSetupTemplate || !formData.templateName.name || !!errorTemplateName;

  const setNameFormData = useCallbackImmutable(({ name }: TemplateNameFormData) =>
    setFormData((current) => ({ ...current, templateName: { name } })),
  );

  const setTemplateFormData = useCallbackImmutable((template: TemplateData) =>
    setFormData((current) => ({ ...current, template })),
  );

  const isBtnDisabled = useMemo(
    () =>
      disabledSelectTemplate ||
      objectReduce(formData.template.selected, (acc, list) => acc + (list?.length ?? 0), 0) <= 0,
    [disabledSelectTemplate, formData.template.selected],
  );

  return (
    <Stack height="100%" flexGrow={1}>
      <FunnelHeader
        title="Create a new template"
        subtitle="Create a template to extract custom portfolio and/or ESG data."
        hrefBack={backRoute}
        data-testid={createTestid('header')}
      />
      <Stack overflow="clip auto" flexGrow={1}>
        <AppLayout>
          <Stack flexGrow={0}>
            <FunnelSection
              name={accordionName}
              expansible
              title="Set up template"
              value={expanded}
              onExpandValue={setExpanded}
              disabled={isSubmitting}
              data-testid={createTestid('section')}
            >
              <Stack gap="2rem">
                <SelectTemplateName
                  onChange={setNameFormData}
                  templateNameError={errorTemplateName}
                  disabled={disabledSetupTemplate}
                  data-testid={createTestid('templateName')}
                />
                <SelectTemplateContainer
                  disabled={disabledSelectTemplate}
                  onChange={setTemplateFormData}
                  data-testid={createTestid('selectTemplate')}
                />
              </Stack>
            </FunnelSection>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="flex-end" marginRight="3.5rem" flexGrow={1}>
            <Button
              size="medium"
              color="secondary"
              disabled={isBtnDisabled}
              data-testid={createTestid('generate-template')}
              onClick={handleSubmit}
            >
              Create template
            </Button>
          </Stack>
        </AppLayout>
      </Stack>
    </Stack>
  );
};
