import { colors, makeStyles } from 'mns-components';

export const uploadStyles = makeStyles({
  container: {
    flex: '1 1 auto',
    minHeight: '22rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    margin: '2rem',
    padding: 0,
    background: colors.whiteColor,
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    '& > .ag-theme-material': {
      flex: '1 1 auto',
      minHeight: 0,
    },
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem 2rem 1rem 1rem',
    '& > *:first-child': {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflow: 'hidden',
      maxHeight: '3.25rem',
    },
    '& > *': {
      flex: '0 0 auto',
    },
  },
});
