import { StatusTag, useTestid } from 'mns-components';
import React from 'react';

interface ReceiverPermissionsStatusRendererProps {
  status: string;
  'data-testid': string;
}

export const receiverStatusMappings = {
  ACCEPTED: 'Active',
  REFUSED: 'Refused',
  REVOKED: 'Revoked',
  PENDING: 'Pending',
  CANCEL: 'Canceled',
};

export const ReceiverPermissionsStatusRenderer: React.FC<ReceiverPermissionsStatusRendererProps> = ({
  status,
  'data-testid': testid,
}: ReceiverPermissionsStatusRendererProps) => {
  const createTestid = useTestid(testid);

  switch (status) {
    case 'Active':
      return <StatusTag variant="success" label="Active" data-testid={createTestid('status')} />;
    case 'Refused':
      return <StatusTag variant="error" label="Refused" data-testid={createTestid('status')} />;
    case 'Revoked':
      return <StatusTag variant="error" label="Revoked" data-testid={createTestid('status')} />;
    case 'Pending':
      return <StatusTag variant="warning" label="Pending" data-testid={createTestid('status')} />;
    default:
      return null;
  }
};

export const receiverPermissionsStatusRendererPlain = ({
  status,
}: Pick<ReceiverPermissionsStatusRendererProps, 'status'>) => {
  return receiverStatusMappings[status as keyof typeof receiverStatusMappings] || '';
};
