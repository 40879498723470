import type { RouterInterface } from '../routing/RouterInterface';
import { UserSettingsPage } from './account/UserSettingsPage';
import { ApplicationsSettingsPage } from './applications/ApplicationsSettingsPage';
import { FundMappingSettingsPage } from './mappings/FundMappingSettingsPage';
import { NotificationsSettingsPage } from './notifications/NotificationsSettingsPage';
import { OrganisationSettingsPage } from './organisation/OrganisationSettingsPage';

export const rawRoutes = [
  {
    label: 'Users',
    link: '/settings/users',
    component: UserSettingsPage,
    autoselect: true,
    key: 'settings-users',
  },
  {
    label: 'Notifications',
    link: '/settings/notifications',
    component: NotificationsSettingsPage,
    key: 'settings-notifications',
    'data-stepid': 'settings-notifications',
  },
  {
    label: 'Organisation',
    link: '/settings/organisation',
    component: OrganisationSettingsPage,
    key: 'settings-organisation',
    expectRole: 'ROLE_ADMIN',
  },
  {
    label: 'Applications',
    link: '/settings/applications/:appCode',
    component: ApplicationsSettingsPage,
    key: 'settings-applications',
    expectRole: 'adminAndFirstAppCode',
  },
  {
    label: 'Mappings',
    link: '/settings/mapping',
    component: FundMappingSettingsPage,
    key: 'settings-mapping',
  },
] as const;

type SettingKey = typeof rawRoutes[number]['key'];
export type SettingRouterInterface<Key = SettingKey> = Replace<RouterInterface, 'key', Key>;

export const getRoute = <S extends SettingKey>(key: S) =>
  rawRoutes.find((route) => route.key === key) as SettingRouterInterface<S>;

export const getSettingsRoute = getRoute;

export const settingsRoutes = rawRoutes as readonly SettingRouterInterface[];
