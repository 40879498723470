import { colors, makeStyles } from 'mns-components';
import dataOnly from '../../../../assets/images/iconCategoriesCards/data-only.svg';
import portfolioAnalysis from '../../../../assets/images/iconCategoriesCards/portfolio.svg';

const blueLightColor = colors.blueLight;

export const coverageCollapsibleStyles = makeStyles({
  mainContainer: {
    border: `solid ${blueLightColor} 1px`,
    borderRadius: '10px',
    margin: '1rem 0rem 1rem 0',
  },
  providers: {
    display: 'flex',
    padding: '.5rem',
    backgroundColor: blueLightColor,
    borderRadius: '10px 10px 0 0',
  },
  appdetails: {
    border: `solid ${blueLightColor} 1px`,
    borderRadius: '10px',
    padding: '.5rem',
    margin: '.5rem',
  },
  header: {
    display: 'flex',
    width: '100%',
  },
  providerTitle: {
    alignSelf: 'center',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
  descLogo: {
    width: '3rem',
    height: '3rem',
    borderRadius: '10px',
    justifySelf: 'center',
    backgroundSize: 'cover',
  },
  expandIcon: {
    alignSelf: 'center',
    paddingTop: '4px',
    marginRight: '.4rem',
  },
  headerDetails: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
  },
  headerDetailsTxt: {
    fontSize: '.8rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    paddingTop: '1px',
  },
  'Data only': {
    backgroundImage: `url(${dataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysis})`,
  },
  iconAppDeliverable: {
    width: '1rem',
    height: '1rem',
    marginRight: '.25rem',
    backgroundSize: 'cover',
  },
  coverageRenderer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    '& > *': {
      fontSize: '.7rem',
    },
  },
  space: {
    magrinLeft: '2rem',
    width: '100%',
  },
  width100: {
    width: '100%',
  },
  collapseContainer: {
    marginTop: '0.25rem',
  },
});
