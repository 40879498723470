import { Stack } from '@mui/material';
import type {
  CheckboxItemType,
  DatapointsPickerDatapointOptionGroups,
  DatapointsPickerViewOption,
} from 'mns-components';
import {
  EmptyStateV2,
  DatapointsPicker,
  LoadingCircle,
  Typo,
  colors,
  convertToDateLocal,
  lowerCase,
  objectKeys,
  objectMap,
  objectReduce,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppBackgroundStyles } from '../../../../common/getImageContent';
import { isAppCode } from '../../../../components/views/appDescriptions';
import { useFetchTemplates, useFetchTemplatesDatapoints } from '../../hooks';
import type { TemplateFormData } from './SelectTemplate';

/**
 * Get datapoints indexed by sources.
 * It is helpful for reducing component refresh by comparing new sources just loaded (useQueries returns a changing list).
 * @param templateIdList the sources that should be loaded.
 * @returns
 */
const useTemplatesDatapoints = (templateIdList: string[]) => {
  const templatesDatapointsResults = useFetchTemplatesDatapoints(templateIdList);
  const [templatesDatapoints, setTemplatesDatapoints] = useState<AnyObject<string, DataExtractorApi.Template.Template>>(
    {},
  );

  useEffect(() => {
    setTemplatesDatapoints((current) => {
      const grouped = templatesDatapointsResults.reduce((acc, result) => {
        const id = result.data?.id;
        if (id && result.data) acc[id] = result.data;
        return acc;
      }, {} as AnyObject<string, DataExtractorApi.Template.Template>);
      const newIds = objectKeys(grouped);
      if (newIds.find((id) => !current[id])) {
        return grouped;
      }
      return current;
    });
  }, [templatesDatapointsResults]);

  return templatesDatapoints;
};

const getTemplateDatapointsAvailable = (template: DataExtractorApi.Template.Template) =>
  template.columns.reduce((acc, { formula: { column, source } }) => {
    if (!acc[source]) acc[source] = [];
    acc[source]?.push(column);
    return acc;
  }, {} as AnyObject<string, string[] | undefined>);

const getTemplatedIsAmended = (
  template: DataExtractorApi.Template.Template,
  datapoints: AnyObject<string, string[] | undefined>,
) => {
  const dps = getTemplateDatapointsAvailable(template);
  return objectReduce(
    dps,
    (acc, list, source) => {
      if (acc) return acc;
      const selected = datapoints[source];
      if (!selected) return true;
      if (selected.length < list!.length) return true;
      return acc;
    },
    false,
  );
};

type TemplateDatapointsProps = {
  disabled?: boolean;
  generic: boolean;
  'data-testid': string;
};

export const TemplateDatapoints: React.FC<TemplateDatapointsProps> = ({ disabled, generic, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const methods = useFormContext<TemplateFormData>();
  const { view, datapointsRaw, datapoints, search } = methods.watch();
  const { setValue } = methods;
  const [templateIdList, setTemplateIdList] = useState<string[]>([]);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState(true);
  const appBgClasses = useAppBackgroundStyles();
  const shouldCheckAllOnLoad = useRef<string>();

  const { data: templates } = useFetchTemplates({
    select: (list) => list.filter((tmp) => !tmp.isHidden && generic === tmp.generic),
  });
  const templatesDatapoints = useTemplatesDatapoints(templateIdList);

  const viewOptions = useMemo(
    (): DatapointsPickerViewOption[] | undefined =>
      templates
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((template) => ({
          title: template.name,
          value: template.id,
          createdBy: generic ? 'Manaos' : template.createdBy,
          createdAt: generic ? undefined : convertToDateLocal(template.createdAt),
        })),
    [generic, templates],
  );

  const datapointOptionGroups = useMemo(
    (): DatapointsPickerDatapointOptionGroups =>
      objectMap(templatesDatapoints, (templateDatapoints) =>
        templateDatapoints.columns.map(({ name, required, formula: { type, source, column } }): CheckboxItemType => {
          if (isAppCode(source)) {
            const bgClass = appBgClasses[source];
            return {
              label: column,
              value: name,
              backgroundClassname: bgClass,
              required,
            };
          }
          return {
            label: column,
            value: name,
            iconName: type === 'data_point' ? 'portfolios' : 'mixed',
            required,
          };
        }),
      ),
    [appBgClasses, templatesDatapoints],
  );

  // INFO: temporary replaced https://bivwak.atlassian.net/browse/MNS-11110
  // get only datapoints which are matching with search
  // const datapointOptionGroupsFiltered = useMemo(
  //   () =>
  //     objectMap(datapointOptionGroups, (list, templateId) => {
  //       const template = templates?.find((tpl) => tpl.id === templateId);
  //       if (template?.name.includes(search)) {
  //         return list;
  //       } else {
  //         const dps = list?.filter((dp) => dp.label.includes(search));
  //         if (dps?.length) {
  //           return dps;
  //         }
  //       }
  //       return [];
  //     }),
  //   [datapointOptionGroups, search, templates],
  // );

  // const viewOptionsFiltered = useMemo(
  //   () => viewOptions?.filter(({ value }) => datapointOptionGroupsFiltered[value]?.length !== 0),
  //   [datapointOptionGroupsFiltered, viewOptions],
  // );

  // get only views which are matching with search
  const viewOptionsFiltered = useMemo(() => {
    const lowerSearch = lowerCase(search);
    return viewOptions?.filter(({ title }) => lowerCase(title).includes(lowerSearch));
  }, [viewOptions, search]);

  // when view changes, load its datapoints
  useEffect(() => {
    if (view) {
      setTemplateIdList((list) => {
        if (list.includes(view)) return list;
        const current = list.slice();
        current.push(view);
        return current;
      });
    }
  }, [view]);

  // at first sight, select the first source, but do not select its datapoints
  // then, checkboxes are available
  useEffect(() => {
    if (datapointsRaw && objectReduce(datapointsRaw, (acc, list) => acc + (list?.length ?? 0), 0) >= 0) {
      setDisabledCheckboxes(false);
    }
  }, [datapointsRaw]);

  // if view did not changed after loading, should check all datapoints
  useEffect(() => {
    if (view && view === shouldCheckAllOnLoad.current) {
      const template = templatesDatapoints[view];
      if (template) {
        setValue('datapointsRaw', { [template.id]: template.columns.map(({ name }) => name) });
        shouldCheckAllOnLoad.current = undefined;
      }
    }
  }, [setValue, templatesDatapoints, view]);

  // if datapoints selected are the same as template, then should not amend template, else should amend template.
  useEffect(() => {
    if (view) {
      const template = templatesDatapoints[view];
      if (template) {
        setValue('matchTemplateId', getTemplatedIsAmended(template, datapoints) ? null : template.id);
      }
    }
  }, [setValue, datapoints, templatesDatapoints, view]);

  // when `datapointsRaw` changes, it apply its equivalence in `datapoints` field in form.
  useEffect(() => {
    if (datapointsRaw) {
      const dps = objectReduce(
        datapointsRaw,
        (acc, list, templateId) => {
          if (list?.length) {
            const template = templatesDatapoints[templateId];
            list.forEach((value) => {
              const dp = template?.columns.find(({ name }) => name === value);
              if (dp) {
                const { source, column } = dp.formula;
                if (!acc[source]) acc[source] = [];
                acc[source].push(column);
              }
            });
          }
          return acc;
        },
        {} as AnyObject<string, string[]>,
      );
      setValue('datapoints', dps);
    }
  }, [datapointsRaw, setValue, templatesDatapoints]);

  const handleFocus = useCallbackImmutable((_, templateId: string) => {
    const template = templatesDatapoints[templateId];
    if (template) {
      setValue('datapointsRaw', { [template.id]: template.columns.map(({ name }) => name) });
    } else {
      shouldCheckAllOnLoad.current = templateId;
    }
  });

  if (!viewOptionsFiltered?.length) {
    return (
      <EmptyStateV2
        variant="info"
        useCase="noSearchResults"
        title="There is no match with your research!"
        subTitle="You can search again with new criteria."
        illustrationVariant="iconType"
        data-testid={createTestid('empty-searchResult')}
      />
    );
  }

  return (
    <Stack minHeight="200px" height="50vh">
      <DatapointsPicker
        variant="radio"
        datapointName="datapointsRaw"
        datapointOptionGroups={datapointOptionGroups}
        viewName="view"
        viewOptions={viewOptionsFiltered}
        datapointPlaceholder={
          <Stack justifyContent="center" alignItems="center" height="100%" color={colors.primaryColor} gap="1rem">
            <LoadingCircle size="medium" data-testid={createTestid('datapoints-backdrop')} />
            <Typo variant="body1medium" color={colors.defaultFontColor}>
              Loading in progress
            </Typo>
          </Stack>
        }
        disabled={disabled}
        disabledCheckboxes={disabledCheckboxes}
        onFocus={handleFocus}
        data-testid={testid}
      />
    </Stack>
  );
};
