import { IconButton } from '@mui/material';
import { Bulk, Icon, filterTruthy, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { uploadActionsStyles as useStyles } from '../styles/uploadActionsStyles';

type UploadActionsProps = {
  status: CollectApi.File['status'];
  fileData: CollectApi.File;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  'data-testid': string;
};

export const UploadActions: React.FC<UploadActionsProps> = ({
  status,
  fileData,
  onDownload,
  onDownloadErrorLog,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const handleDownload = useMemo(() => onDownload && (() => onDownload(fileData)), [onDownload, fileData]);
  const handleDownloadErrorLog = useMemo(
    () => onDownloadErrorLog && (() => onDownloadErrorLog(fileData)),
    [onDownloadErrorLog, fileData],
  );

  const hasErrorLog = status === 'REJECTED' || status === 'WARNING';

  const bulkItems = useMemo(
    () =>
      filterTruthy([
        handleDownloadErrorLog &&
          hasErrorLog && {
            key: 'error-log',
            label: 'Error Log',
            onClick: handleDownloadErrorLog,
          },
      ]),
    [handleDownloadErrorLog, hasErrorLog],
  );

  return (
    <div className={classes.actions}>
      {handleDownload && (
        <IconButton
          title="Download"
          color="secondary"
          onClick={handleDownload}
          data-testid={createTestid('button-download')}
        >
          <Icon.Download data-testid={createTestid('icon-download')} />
        </IconButton>
      )}
      {bulkItems.length > 0 && <Bulk items={bulkItems} data-testid={testid} />}
    </div>
  );
};
