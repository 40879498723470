import { Stack } from '@mui/material';
import type { SimpleTableProps } from 'mns-components';
import { Checkbox, SimpleTable, objectEntries } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const useColumns = (testid: string) =>
  useMemo(
    (): SimpleTableProps<CollectApi.FundPositionToCollect>['columns'] => [
      {
        field: 'fundPosition',
        head: '',
        cell: ({ fundPosition }) => (
          <Stack flexDirection="row" justifyContent="space-between" height="16px">
            <Checkbox
              label=""
              name={fundPosition.id}
              variant="rose"
              data-testid={`${testid}-${fundPosition.id}-checkbox`}
            />
          </Stack>
        ),
        dataTestId: () => `fundPositionId`,
      },
      {
        field: 'fundPosition',
        head: 'Shareclass ID',
        cell: ({ fundPosition }) => fundPosition.externalId,
        dataTestId: () => `externalId`,
      },
      {
        field: 'fundPosition',
        head: 'Shareclass Name',
        cell: ({ fundPosition }) => fundPosition.name,
        dataTestId: () => `fundName`,
      },
    ],
    [testid],
  );

export type ShareclassSimpleTableProps = {
  rows: CollectApi.FundPositionToCollect[];
  selection: string[];
  onSelectionChange(list: string[]): void;
  'data-testid': string;
};

export const ShareclassSimpleTable: React.FC<ShareclassSimpleTableProps> = ({
  rows,
  selection,
  onSelectionChange,
  'data-testid': testid,
}) => {
  const methods = useForm({
    defaultValues: rows.reduce((acc, item) => {
      acc[item.fundPosition.id] = selection.includes(item.fundPosition.id);
      return acc;
    }, {} as AnyObject<string, boolean>),
  });

  useEffect(() => {
    return methods.watch(() => {
      onSelectionChange(
        objectEntries(methods.getValues())
          .filter(([, checked]) => checked)
          .map(([key]) => key),
      );
    }).unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <SimpleTable columns={useColumns(testid)} rows={rows} data-testid={testid} />
    </FormProvider>
  );
};
