import { Chip, Stack } from '@mui/material';
import { EmptyStateV2, FunnelSection, ToggleButtonGroup, Typo, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, DataExtractorApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { currentMonthLastDate, subtractYears } from '../../../../common/date';
import { usePortfoliosRequest } from '../../../../hooks/usePortfoliosRequest';
import { SelectPtfSectionView } from './SelectPtfSection/SelectPtfSectionView';

const ToggleBtnChildTitle = ({
  count,
  title,
  'data-testid': testid,
}: {
  count: number;
  title: string;
  'data-testid': string;
}) => (
  <Stack direction="row" alignItems="center" spacing=".5rem" fontWeight="inherit">
    <Typo variant="button">{title}</Typo>
    <Chip label={count > 999 ? '+999' : count} variant="badge" data-testid={testid} />
  </Stack>
);

export type PortfolioData = {
  portfolioId: string;
  externalIdValue: string;
  externalIdType: 'INTERNAL' | 'ISIN';
  name: string;
  valuationDate: string;
  uploadedOn: string;
  origin: CollectApi.PortfolioOrigin;
};

const getValuationDates = (ptf: CollectApi.Portfolio): string[] =>
  Array.from(
    new Set<string>([ptf.latestVersion.valuationDate, ...ptf.valuationDates].sort((a, b) => a.localeCompare(b))),
  );

// if extraction is MANUALLY, for a ptf, for each date in the list valuationDates, duplicate data with one valuationDate
export const portfoliosDuplicated = (
  portfolios: CollectApi.Portfolio[],
  extractionType: DataExtractorApi.Extraction.ExtractionType,
) => {
  if (!portfolios) return [];
  if (extractionType === 'MANUALLY') {
    return portfolios.reduce((acc, ptf) => {
      const dates = getValuationDates(ptf);
      for (const date of dates) {
        acc.push({
          portfolioId: ptf.portfolioId,
          externalIdValue: ptf.externalId.value,
          externalIdType: ptf.externalId.type,
          name: ptf.name,
          valuationDate: date,
          uploadedOn: ptf.latestVersion.uploadOn,
          origin: ptf.origin,
        });
      }
      return acc;
    }, [] as PortfolioData[]);
  }
  return portfolios.reduce((acc, ptf) => {
    acc.push({
      portfolioId: ptf.portfolioId,
      externalIdValue: ptf.externalId.value,
      externalIdType: ptf.externalId.type,
      name: ptf.name,
      valuationDate: ptf.latestVersion.valuationDate,
      uploadedOn: ptf.latestVersion.uploadOn,
      origin: ptf.origin,
    });
    return acc;
  }, [] as PortfolioData[]);
};

type SelectPtfSectionProps = {
  extractionType: DataExtractorApi.Extraction.ExtractionType;
  initialStartDate?: Date;
  initialEndDate?: Date;
  initialSelectedPtfs?: CollectApi.Portfolio[];
  onChange: (data: DataExtractorApi.Extraction.ExtractionScope) => void;
  disabled?: boolean;
  'data-testid': string;
};

export const SelectPtfSection = React.memo<SelectPtfSectionProps>(function SelectPtfSection({
  extractionType,
  initialEndDate,
  initialStartDate,
  initialSelectedPtfs = [],
  onChange,
  disabled,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const name = 'selectPtf';
  const [expanded, setExpanded] = useState<string | null>(name);

  // Portfolio
  const [selectedRows, setSelectedRows] = useState<PortfolioData[]>(
    portfoliosDuplicated(initialSelectedPtfs, extractionType),
  );
  const [selectedRowsUploaded, setSelectedRowsUploaded] = useState<PortfolioData[]>(
    selectedRows.filter((row) => row.origin.includes('UPLOADED')),
  );
  const [selectedRowsCollected, setSelectedRowsCollected] = useState<PortfolioData[]>(
    selectedRows.filter((row) => row.origin.includes('COLLECTED')),
  );

  useEffect(() => {
    setSelectedRows(selectedRowsCollected.concat(selectedRowsUploaded));
  }, [selectedRowsCollected, selectedRowsUploaded, setSelectedRows]);

  useEffect(() => {
    const extractionScope = selectedRows.reduce((acc, row) => {
      acc.push({
        name: row.name,
        externalIdValue: row.externalIdValue,
        externalIdType: row.externalIdType,
        valuationDate: row.valuationDate,
      });
      return acc;
    }, [] as DataExtractorApi.Extraction.ExtractionScope);
    onChange(extractionScope);
  }, [selectedRows, onChange]);

  // ToggleButtonGroup
  const [ptfTypeView, setPtfTypeView] = useState<CollectApi.PortfolioOrigin>('UPLOADED');
  const handleViewChange = useCallbackImmutable((_, newValue: CollectApi.PortfolioOrigin) => setPtfTypeView(newValue));

  const selectedUploadedCount = useMemo(
    () => selectedRows.filter((row) => row.origin.includes('UPLOADED')).length,
    [selectedRows],
  );
  const selectedCollectedCount = useMemo(
    () => selectedRows.filter((row) => row.origin.includes('COLLECTED')).length,
    [selectedRows],
  );

  const { data: ptfList, isError } = usePortfoliosRequest(
    { startDate: subtractYears(currentMonthLastDate, 3), endDate: currentMonthLastDate, portfolioOriginType: 'ALL' },
    { select: (portfolios) => portfoliosDuplicated(portfolios, extractionType) },
  );
  const isUploadedTabVisible = useMemo(
    () => ptfList?.filter((row) => row.origin.includes('UPLOADED')).length != 0,
    [ptfList],
  );
  const isCollectedTabVisible = useMemo(
    () => ptfList?.filter((row) => row.origin.includes('COLLECTED')).length != 0,
    [ptfList],
  );

  const toggleButtonList = useMemo(() => {
    const list = [];
    if (isUploadedTabVisible)
      list.push({
        value: 'UPLOADED',
        children: (
          <ToggleBtnChildTitle
            count={selectedUploadedCount}
            title="My portfolios"
            data-testid={createTestid('chip-uploaded')}
          />
        ),
      });
    if (isCollectedTabVisible)
      list.push({
        value: 'COLLECTED',
        children: (
          <ToggleBtnChildTitle
            count={selectedCollectedCount}
            title="Collected portfolios"
            data-testid={createTestid('chip-collected')}
          />
        ),
      });
    return list;
  }, [createTestid, selectedCollectedCount, selectedUploadedCount, isCollectedTabVisible, isUploadedTabVisible]);

  return (
    <FunnelSection
      name={name}
      title="Select the perimeter of porfolios"
      expansible
      value={expanded}
      onExpandValue={setExpanded}
      disabled={disabled}
      data-testid={createTestid('section')}
    >
      {isError ? (
        <Stack height="100%" flexGrow="1" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            illustrationVariant="iconType"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-portfolio')}
          />
        </Stack>
      ) : (
        <Stack gap="1rem">
          <ToggleButtonGroup.Uncontrolled
            list={toggleButtonList}
            value={[ptfTypeView]}
            exclusive
            disabled={disabled}
            onChange={handleViewChange}
            data-testid={createTestid('toggle')}
            fullWidth
          />
          {ptfTypeView == 'UPLOADED' && (
            <SelectPtfSectionView
              ptfList={ptfList}
              extractionType={extractionType}
              view={ptfTypeView}
              initialStartDate={initialStartDate}
              initialEndDate={initialEndDate}
              selectedRows={selectedRowsUploaded}
              setSelectedRows={setSelectedRowsUploaded}
              disabled={disabled}
              data-testid={createTestid('list')}
            />
          )}
          {ptfTypeView == 'COLLECTED' && (
            <SelectPtfSectionView
              ptfList={ptfList}
              extractionType={extractionType}
              view={ptfTypeView}
              initialStartDate={initialStartDate}
              initialEndDate={initialEndDate}
              selectedRows={selectedRowsCollected}
              setSelectedRows={setSelectedRowsCollected}
              disabled={disabled}
              data-testid={createTestid('list')}
            />
          )}
        </Stack>
      )}
    </FunnelSection>
  );
});
