import { colors, makeStyles } from 'mns-components';

export const accountSettingsStyles = makeStyles({
  part: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    margin: '4rem 0',
    '& > :first-of-type': {
      flexGrow: 0,
      width: '28rem',
    },
    '& > :not(:first-of-type)': {
      flexGrow: 1,
    },
    '& > :not(:last-child)': {
      marginRight: '4rem',
    },
  },
  partTitle: {
    textAlign: 'left',
    '& h2': {
      fontSize: '1.5rem',
      margin: '0 0 1rem 0',
      padding: 0,
    },
    '& small': {
      fontSize: '1rem',
      color: 'grey',
    },
  },
  frame: {
    '&&': {
      minWidth: '0',
    },
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  definitionSize: {
    maxWidth: '26rem',
  },
  line: {
    border: 'none',
    borderBottom: `1px solid ${colors.lightGreyColor}`,
  },
  spaceBottom: {
    marginBottom: '2rem',
  },
});

export const modalStyles = makeStyles({
  spacingTextField: {
    padding: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flexGrow: 1,
      margin: '0 !important',
    },
  },
  spacingSelect: {
    padding: '.5rem',
    display: 'flex',
    '& > *': {
      flexGrow: 1,
      margin: '0 !important',
    },
  },
  spacingRadio: {
    padding: '.5rem',
    '& > div > div': {
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
  },
  spacingButtons: {
    padding: '.5rem',
    display: 'flex',
    justifyContent: 'center',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  spacingDefs: {
    padding: '2rem',
  },
  error: {
    color: colors.dangerColor,
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem',
    borderTop: `1px solid rgba(0, 0, 0, .23)`,
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  formFields: {
    gap: '1rem',
  },
});
