import type { FormStepperProps, FormStepperStepProps } from 'mns-components';
import { FormStepper, useCallbackImmutable } from 'mns-components';
import type { ProviderApi, MarketplaceApi } from 'mns-sdk-collect';
import { useEffect, useMemo } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../../common/getImageContent';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { useUser } from '../../../../hooks/useAuth';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import { useConsumeAnalysisPortfolioRequest } from '../../hooks';
import type { ConsumeRequestSteps } from './createRequest.types';
import { PortfolioPickerStep } from './PortfolioPickerStep';

type ConsumeStepProps = {
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  appCode: AppCode;
  disablePtfAndDate?: boolean;
};

const consumeSteps = ({
  appCode,
  applicationDetails,
  portfolios,
  disablePtfAndDate,
}: ConsumeStepProps): FormStepperProps<ConsumeRequestSteps>['steps'] => [
  {
    title: 'Confirm Order',
    build: (args) => (
      <PortfolioPickerStep
        {...args}
        portfolios={portfolios}
        applicationDetails={applicationDetails}
        appCode={appCode}
        disablePtfAndDate={disablePtfAndDate}
      />
    ),
  } as FormStepperStepProps<ConsumeRequestSteps[0], ConsumeRequestSteps>,
];

type ConsumeRequestStepperProps = {
  appCode: AppCode;
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  closeModal(): void;
  disablePtfAndDate?: boolean;
  'data-testid': string;
};

export const ConsumeRequestStepper: React.FC<ConsumeRequestStepperProps> = ({
  appCode,
  portfolios,
  applicationDetails,
  closeModal,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const {
    company: { name },
    content: { title },
  } = useApplicationConfig(appCode);
  const [, setPtfAndDate] = usePtfAndDate();
  const appBackgroundClassName = useAppBackgroundStyles()[appCode];
  const { organisationId: orgId } = useUser();

  const header = useMemo(
    (): FormStepperProps<ConsumeRequestSteps>['header'] => ({
      appBackgroundClassName,
      appTitle: title,
      providerName: name,
    }),
    [name, title, appBackgroundClassName],
  );

  const {
    mutateAsync: consumeAnalysisPortfolios,
    isLoading: isSubmitting,
    isSuccess: isAnalysisSent,
  } = useConsumeAnalysisPortfolioRequest();

  const handleSubmit = useCallbackImmutable(async ([{ portfolioId, valuationDate }]: ConsumeRequestSteps) => {
    const portfolio = portfolios.find((ptf) => ptf.portfolioId === portfolioId);

    if (portfolio) {
      await consumeAnalysisPortfolios({
        appCode,
        externalId: portfolio.externalId.value,
        externalIdType: portfolio.externalId.type,
        portfolioOriginType: portfolio.organisationId === orgId ? 'UPLOADED' : 'COLLECTED',
        valuationDate,
      });
      setPtfAndDate([portfolioId, valuationDate]);
    }

    throw new Error(`Can not find portfolio ${portfolioId}`);
  });

  useEffect(() => {
    if (isAnalysisSent) closeModal();
  }, [isAnalysisSent, closeModal]);

  const steps = useMemo(
    () => consumeSteps({ portfolios, applicationDetails, appCode, disablePtfAndDate }),
    [portfolios, applicationDetails, appCode, disablePtfAndDate],
  );

  return (
    <FormStepper
      header={header}
      steps={steps}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      onCancel={closeModal}
      buttonBackground="white"
      submitLabel="Confirm Order"
      displayNavigation
      displayErrors
      data-testid={testid}
    />
  );
};
