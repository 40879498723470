import { useFederatedSignIn } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';

export const Sso: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  useFederatedSignIn();

  return (
    <AuthPageLayout data-testid={testid}>
      <h1 className={classes.title}>Log in with SSO</h1>
      <p className={classes.info}>You will be redirected to your SSO provider</p>
    </AuthPageLayout>
  );
};
