import type { CardSelectorOptions, CsvToAggrid } from 'mns-components';
import { csvToAggrid, filterTruthy, objectValues } from 'mns-components';
import type { DataExtractorApi, AnalysisApi, WidgetApi, CollectApi } from 'mns-sdk-collect';
import type { DownloadApi } from 'mns-sdk-collect/dist/providers/downloadApi';
import { useMemo } from 'react';
import type { MutationOptions, QueryObserverOptions, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { getApplicationPipeTitleFromConfig } from '../../common/getApplicationConfig';
import { useProvidersIconBackgroundStyles } from '../../common/getImageContent';
import type { AppCode } from '../../components/views/appDescriptions';
import { apps } from '../../components/views/appDescriptions';
import { useUser } from '../../hooks/useAuth';
import { api } from '../../store/api';
import { appDeliverableStyles as useAppDeliverableStyles } from './styles/appSwitchStyles';

export const getExtractionReportsLight = async (extractionId: string): Promise<DataExtractorApi.Report.ReportLight[]> =>
  api.dataExtractor.extraction.getExtractionReports.raw(extractionId);

export const getExtractionReports = async (extractionId: string): Promise<DataExtractorApi.Report.Report[]> =>
  api.dataExtractor.extraction.getExtractionReports.raw(extractionId);

export const getReport = async (reportId: string): Promise<DataExtractorApi.Report.Report> =>
  api.dataExtractor.report.getReport.raw(reportId);

export const getExtraction = async (extractionId: string): Promise<DataExtractorApi.Extraction.Extraction> =>
  api.dataExtractor.extraction.getExtraction.raw(extractionId);

export const useReports = <TData = DataExtractorApi.Report.ReportLight[]>(
  options: QueryObserverOptions<DataExtractorApi.Report.ReportLight[], unknown, TData> = {},
) => api.dataExtractor.report.getReports.useQuery(options);

export const useAppOptions = (roles?: string[]) => {
  const appBackgroundClasses = useProvidersIconBackgroundStyles();
  const appDelivClasses = useAppDeliverableStyles() as Record<string, string>;

  return useMemo(
    () =>
      roles &&
      objectValues(apps)
        .filter(({ config: { portfolioViewType, role: appRole } }) => {
          return portfolioViewType && (!appRole || roles.includes(appRole));
        })
        .map(
          (app): CardSelectorOptions<AppCode> => ({
            label: getApplicationPipeTitleFromConfig(app),
            selectedLabel: app.content.shortTitle,
            appBackgroundClassName: appBackgroundClasses[app.company.providerName],
            appDeliverable: app.content.appDeliverable,
            appDeliverableIconClassName: appDelivClasses[app.content.appDeliverable],
            value: app.appCode,
          }),
        )
        .sort((optA, optB) => optA.label.localeCompare(optB.label)),
    [roles, appBackgroundClasses, appDelivClasses],
  );
};

export const useCreateExtraction = () => {
  const invReports = api.dataExtractor.report.getReports.useInvalidateQuery();
  const invExtrs = api.dataExtractor.extraction.getExtractions.useInvalidateRootQuery();
  const invExtrReports = api.dataExtractor.extraction.getExtractionReports.useInvalidateRootQuery();
  return api.dataExtractor.extraction.createExtraction.useMutation({
    onSuccess: async () => {
      await invReports();
      await invExtrs();
      await invExtrReports();
    },
  });
};

export const useCreateTemplate = (
  options?: MutationOptions<
    DataExtractorApi.Template.Template,
    unknown,
    [columns: DataExtractorApi.Template.Pair[], name: string, isHidden?: boolean | undefined]
  >,
) => {
  const inv = api.dataExtractor.template.getTemplates.useInvalidateQuery();
  return api.dataExtractor.template.createTemplate.useMutation({
    ...options,
    onSuccess: async (...args) => {
      await inv();
      await options?.onSuccess?.(...args);
    },
  });
};

export const useReport = (portfolioExternalId: string, valuationDate: string, templateId: string) => {
  const query = useReports();
  return {
    ...query,
    data: useMemo(
      () =>
        query.data
          ?.filter(
            (report) =>
              report.portfolio.externalIdValue == portfolioExternalId &&
              report.portfolio.valuationDate == valuationDate &&
              report.idTemplate == templateId,
          )
          .sort((repA, repB) => new Date(repA.createdAt).getTime() - new Date(repB.createdAt).getTime())
          .pop(),
      [query.data, portfolioExternalId, valuationDate, templateId],
    ),
  };
};

export const useReportDetails = (reportId: string | undefined) =>
  api.dataExtractor.report.getReport.useQuery(reportId!, {
    refetchInterval: (report) => (!report || ['REQUESTED', 'IN_PROGRESS'].includes(report.status) ? 2000 : false),
    enabled: !!reportId,
  });

export const useReportFile = (report?: DataExtractorApi.Report.Report) => {
  return useQuery(
    ['fetchCsvUrl', report?.reportPresignedUrl],
    async () => {
      const response = await fetch(report!.reportPresignedUrl);
      if (!response.ok) throw new Error('Could not fetch file');
      const file = await response.text();
      return csvToAggrid(file, ['id']);
    },
    { enabled: !!report && !!report.reportPresignedUrl && report.status === 'COMPLETE' },
  );
};

export const useAnalysisPortfolios = <TSelect = AnalysisApi.AnalysisPortfolio<AppCode>[]>(
  appCode: AppCode,
  options?: QueryObserverOptions<AnalysisApi.AnalysisPortfolio<AppCode>[], unknown, TSelect>,
) => api.provider.analysis.getAnalysisPortfolios.useQuery(appCode, 'ALL', options);

export const useDownloadAnalysis = <TSelect = DownloadApi.DownloadRequestParams | null>(
  code: AppCode,
  analysisId?: string,
  options: UseQueryOptions<DownloadApi.DownloadRequestParams | null, unknown, TSelect, AnyArray> = {},
) => {
  return useQuery(
    ['appDownloadApi.downloadRequest', code, analysisId],
    async () => {
      if (analysisId) {
        const currentAnalysedFiles = await api.provider.download.downloadRequest.raw({
          appCode: code,
          requestId: analysisId,
        });
        return currentAnalysedFiles.find((elem) => elem.fileName.split('.').pop() === 'pdf') ?? null;
      }
      return null;
    },
    { enabled: !!analysisId, ...options },
  );
};

export const useFetchUnderlying = (
  params: WidgetApi.UnderlyingDataRequest,
  options?: QueryObserverOptions<WidgetApi.UnderlyingData[], unknown, WidgetApi.UnderlyingData[]>,
) => api.provider.widget.getUnderlyingData.useQuery(params, options);

export const useFetchClarityApplication = (appCode: AppCode) => {
  const { organisationId: orgId } = useUser();
  return api.marketplace.getClarityApplication.useQuery(orgId, appCode);
};

export const useFetchClaritySfdrMetrics = (ptfId: string, dataToken: string) =>
  api.claritySfdr.getClaritySfdrMetrics.useQuery(ptfId, dataToken);

export const useFetchClarityDictionary = (dataToken: string) =>
  api.claritySfdr.getClarityDictionary.useQuery(dataToken);

export const useGreatAnalysisStatus = <TSelect = AnalysisApi.AnalysisPortfolio<AppCode>>(
  params?: AnalysisApi.GreatAnalysisRequest,
  options?: QueryObserverOptions<AnalysisApi.AnalysisPortfolio<AppCode>, unknown, TSelect>,
) =>
  api.provider.analysis.getGreatAnalysisStatus.useQuery(params!, {
    enabled: !!params,
    ...options,
  });

export const useWidget = <TSelect = AnalysisApi.GreatAnalysisFullRequest>(
  widgetUrl?: string,
  options?: UseQueryOptions<AnalysisApi.GreatAnalysisFullRequest, unknown, TSelect, AnyArray>,
) =>
  useQuery(
    ['fetchWidget', widgetUrl],
    async (): Promise<AnalysisApi.GreatAnalysisFullRequest> => {
      const response = await fetch(widgetUrl!);
      const data = await response.json();
      return data;
    },
    { enabled: !!widgetUrl, ...options },
  );

export const useGreatAnalysisRequest = (
  options: UseMutationOptions<AnalysisApi.GreatAnalysisStatus, unknown, [AnalysisApi.GreatAnalysisRequest]> = {},
) => {
  const invalidateGreatAnalysis = api.provider.analysis.getGreatAnalysisStatus.useInvalidateRootQuery();
  return api.provider.analysis.postGreatAnalysis.useMutation({
    onSuccess: async (...args) => {
      await invalidateGreatAnalysis();
      options.onSuccess?.(...args);
    },
    ...options,
  });
};

export const useAnalysisAggridData = <TSelect = CsvToAggrid<'id'>>(
  appCode: string,
  analysisId?: string,
  options?: UseQueryOptions<CsvToAggrid<'id'>, unknown, TSelect, AnyArray>,
) =>
  useQuery(
    ['api.provider.portfolio.downloadAnalysis', appCode, analysisId],
    async () => {
      const currentAnalysedFile = await api.provider.download.downloadRequest.raw({
        appCode,
        requestId: analysisId!,
      });
      if (currentAnalysedFile?.length) {
        const currentFileUrl = currentAnalysedFile.find((file) => file.fileName == 'report.csv')?.presignedUrl;
        if (currentFileUrl) {
          const res = await fetch(currentFileUrl);
          if (res.ok) {
            return csvToAggrid(await res.text(), ['id']);
          }
        }
      }
      throw new Error('Could not fetch file');
    },
    { enabled: !!analysisId, retry: true, refetchIntervalInBackground: true, retryDelay: 5000, ...options },
  );

export const useFetchExtractions = <TSelect = DataExtractorApi.Extraction.Extraction[]>(
  type?: DataExtractorApi.Extraction.ExtractionType,
  options: QueryObserverOptions<DataExtractorApi.Extraction.Extraction[], unknown, TSelect> = {},
) => api.dataExtractor.extraction.getExtractions.useQuery(type, options);

export const useFetchReports = <TSelect = DataExtractorApi.Report.ReportLight[]>(
  options: QueryObserverOptions<DataExtractorApi.Report.ReportLight[], unknown, TSelect>,
) => api.dataExtractor.report.getReports.useQuery(options);

export const useFetchMetadataSources = (options: QueryObserverOptions<DataExtractorApi.Metadata.Source[]> = {}) =>
  api.dataExtractor.metadata.getSources.useQuery(options);

export const useFetchMetadataSourceDatapoints = <TSelect = DataExtractorApi.Metadata.Datapoint[]>(
  source: string,
  options?: QueryObserverOptions<DataExtractorApi.Metadata.Datapoint[], unknown, TSelect, AnyArray>,
) => api.dataExtractor.metadata.getSourceDatapoints.useQuery(source, options);

export const useFetchMetadataSourcesDatapoints = (
  sources: string[],
  options?: UseQueryOptions<DataExtractorApi.Metadata.Datapoint[]>,
) =>
  api.dataExtractor.metadata.getSourceDatapoints.useQueries(
    filterTruthy(sources).map((source) => [source]),
    options,
  );

export const useFetchTemplates = <TSelect = DataExtractorApi.Template.TemplateLight[]>({
  staleTime = Infinity,
  ...queryOptions
}: QueryObserverOptions<DataExtractorApi.Template.TemplateLight[], unknown, TSelect> = {}) =>
  api.dataExtractor.template.getTemplates.useQuery({
    staleTime,
    ...queryOptions,
  });

export const useFetchTemplatesDatapoints = (
  sources: string[],
  options?: UseQueryOptions<DataExtractorApi.Template.Template>,
) =>
  api.dataExtractor.template.getTemplate.useQueries(
    filterTruthy(sources).map((source) => [source]),
    options,
  );

export const useGetWidgetDoc = (appCode: string, ptf: CollectApi.Portfolio, date: string) =>
  api.provider.widget.getDocument.useQuery(appCode, ptf.externalId.value, ptf.externalId.type, date, ptf.origin, {
    enabled: true,
  });

export const usePostWidgetDoc = (appCode: string, ptf: CollectApi.Portfolio, date: string) =>
  api.provider.widget.createDocument.useQuery(appCode, ptf.externalId.value, ptf.externalId.type, date, ptf.origin, {
    enabled: false,
  });
