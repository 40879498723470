import { Stack } from '@mui/material';
import { TextField, Typo, colors, useTestid } from 'mns-components';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type TemplateNameFormData = {
  name: string;
};

type SelectTemplateNameProps = {
  onChange(formData: TemplateNameFormData): void;
  templateNameError?: string;
  disabled?: boolean;
  'data-testid': string;
};

export const SelectTemplateName: React.FC<SelectTemplateNameProps> = ({
  onChange,
  templateNameError,
  disabled,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const methods = useForm<TemplateNameFormData>();

  const { name } = methods.watch();

  useEffect(() => {
    onChange({ name });
  }, [onChange, name]);

  return (
    <FormProvider {...methods}>
      <Stack gap="1rem">
        <Typo variant="body2">
          A template is a selection of portfolio and/or ESG data. Initialise template creation by choosing a template
          name.
        </Typo>
        <Stack width="17rem">
          <TextField
            label="Template name"
            name="name"
            disableMargin
            disabled={disabled}
            data-testid={createTestid('templateName-input')}
            error={!!templateNameError}
            aria-errormessage={templateNameError}
          />
          {templateNameError && (
            <Stack width="max-content" padding="3px 14px 0 14px">
              <Typo variant="caption" color={colors.dangerColor} data-testid={createTestid('error')}>
                {templateNameError}
              </Typo>
            </Stack>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
};
