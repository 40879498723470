import { Grid } from '@mui/material';
import { PeriodPicker } from 'mns-components';
import React from 'react';
import { currentMonthLastDate, firstDateAvailable } from '../../../../common/date';
import { usePeriodPicker } from '../../hooks';
import useStyles from './selectInventoryDateStyles';

type SelectInventoryDateProps = { 'data-testid': string };

export const SelectInventoryDate: React.FC<SelectInventoryDateProps> = ({
  'data-testid': testid,
}: SelectInventoryDateProps) => {
  const [[startDate, endDate], setPeriod] = usePeriodPicker();

  const classes = useStyles();

  return (
    <Grid item>
      <div className={classes.dateContainerInventoriesContainer}>
        <div className={classes.datepickers} data-stepid="selectInventoryDate-datepicker">
          <PeriodPicker
            startLabel="Start Date"
            endLabel="End Date"
            minStartDate={firstDateAvailable}
            minEndDate={firstDateAvailable}
            maxStartDate={currentMonthLastDate}
            maxEndDate={currentMonthLastDate}
            onFieldChange={setPeriod}
            start={startDate}
            end={endDate}
            data-testid={testid}
          />
        </div>
      </div>
    </Grid>
  );
};
