import type { OptionType } from 'mns-components';
import { makeForm, Select, TextField, useTestid } from 'mns-components';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useStyles from './replyAccessRequestStyles';

export type ReplyFormType = { comment: string; scopeToShare: string; embargoDelay: string };

type AccessRequestProps = { scopesToShare: Record<string, string>; 'data-testid': string };

const ReplyAccessRequestFields: React.FC<AccessRequestProps> = ({ scopesToShare, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const {
    formState: { errors },
  } = useFormContext<ReplyFormType>();
  const scopeToShareOptions: OptionType[] = useMemo(
    () => Object.entries(scopesToShare).map(([value, label]) => ({ value, label })),
    [scopesToShare],
  );

  return (
    <div className={classes.uniForm}>
      <h3 className={classes.subTitles}>Requests complements</h3>
      <Select
        label="Scope to share"
        name="scopeToShare"
        options={scopeToShareOptions}
        disabled={!scopeToShareOptions[0]}
        variant="normal"
        data-testid={createTestid('input-scopeToShare')}
      />
      <TextField
        type="number"
        label="Embargo delay after EOM"
        name="embargoDelay"
        error={!!errors?.embargoDelay}
        helperText={errors?.embargoDelay?.message}
        data-testid={createTestid('input-embargoDelay')}
      />
      <TextField type="text" label="Comment" name="comment" fullWidth data-testid={createTestid('input-comment')} />
    </div>
  );
};

export const ReplyAccessRequestForm = makeForm<ReplyFormType, AccessRequestProps>(ReplyAccessRequestFields);
