import { objectKeys } from 'mns-components';
import type { ApplicationConfig } from '../../../common/getApplicationConfig';
// # apps
import bnppamEsgdatasharing from './bnppam-esgdatasharing.json';
import cdpScores from './cdp-scores.json';
import clarityBiodiversityimpactdata from './clarity-biodiversityimpactdata.json';
import clarityClimate from './clarity-climate.json';
import clarityDependencydata from './clarity-dependencydata.json';
import clarityEsgimpact from './clarity-esgimpact.json';
import clarityEsgrisk from './clarity-esgrisk.json';
import clarityEutaxonomy from './clarity-eutaxonomy.json';
import clarityImpacthighlights from './clarity-impacthighlights.json';
import clarityNegativescreening from './clarity-negativescreening.json';
import clarityRawdata from './clarity-rawdata.json';
import claritySfdr from './clarity-sfdr.json';
import clarityUnsdgs from './clarity-unsdgs.json';
import denominatorDei from './denominator-dei.json';
import esgbookDisclosuresdatafeed from './esgbook-disclosuresdatafeed.json';
import esgbookEmissionsestimations from './esgbook-emissionsestimations.json';
import esgbookEsgperformancescore from './esgbook-esgperformancescore.json';
import esgbookSfdrpai from './esgbook-sfdrpai.json';
import esgbookTemperaturescore from './esgbook-temperaturescore.json';
import greenomyEutaxonomysfdr from './greenomy-eutaxonomysfdr.json';
import icebergdatalabIcebergdl from './icebergdatalab-icebergdl.json';
import issBiodiversityImpact from './iss-biodiversityimpact.json';
import issCarbonRiskRating from './iss-carbonriskrating.json';
import issClimateCore from './iss-climatecore.json';
import issClimateImpact from './iss-climateimpact.json';
import issClimatePhysicalRisk from './iss-climatephysicalrisk.json';
import issClimateTransitionRisk from './iss-climatetransitionrisk.json';
import issCorporateratings from './iss-corporateratings.json';
import issEetRegulatory from './iss-eetregulatory.json';
import issEetScreening from './iss-eetscreening.json';
import issEsqs from './iss-esqs.json';
import issGovernance from './iss-governance.json';
import issModernSlavery from './iss-modernslavery.json';
import issNetZero from './iss-netzero.json';
import issPotentialAvoidedEmissions from './iss-potentialavoidedemissions.json';
import issRsi from './iss-rsi.json';
import issSdga from './iss-sdga.json';
import issSdgRating from './iss-sdgrating.json';
import issSectorscreening from './iss-sectorscreening.json';
import issSovereignEmissions from './iss-sovereignemissions.json';
import issWaterRisk from './iss-waterrisk.json';
import lbpamGreat from './lbpam-great.json';
import mainstreetGssbondsratingsimpact from './mainstreet-gssbondsratingsimpact.json';
import manaosCollection from './manaos-collection.json';
import manaosCoverageEstimation from './manaos-coverageestimation.json';
import manaosDataextractor from './manaos-dataextractor.json';
import manaosDatalake from './manaos-datalake.json';
import manaosDissemination from './manaos-dissemination.json';
import manaosEet from './manaos-eet.json';
import manaosPaistatement from './manaos-paistatement.json';
import manaosProductrangecollection from './manaos-productrangecollection.json';
import manaosProductrangedissemination from './manaos-productrangedissemination.json';
import msciAgrratings from './msci-agrratings.json';
import msciAgrsupp from './msci-agrsupp.json';
import msciBiodiversity from './msci-biodiversity.json';
import msciBisr from './msci-bisr.json';
import msciBisrabortion from './msci-bisrabortion.json';
import msciBisradultentertainment from './msci-bisradultentertainment.json';
import msciBisralcohol from './msci-bisralcohol.json';
import msciBisranimalwelfare from './msci-bisranimalwelfare.json';
import msciBisrbiocides from './msci-bisrbiocides.json';
import msciBisrbreastmilk from './msci-bisrbreastmilk.json';
import msciBisrcannabis from './msci-bisrcannabis.json';
import msciBisrcontroversialweapons from './msci-bisrcontroversialweapons.json';
import msciBisrconventionalweapons from './msci-bisrconventionalweapons.json';
import msciBisrfirearms from './msci-bisrfirearms.json';
import msciBisrforprofithealthcare from './msci-bisrforprofithealthcare.json';
import msciBisrforprofitprisons from './msci-bisrforprofitprisons.json';
import msciBisrfur from './msci-bisrfur.json';
import msciBisrgambling from './msci-bisrgambling.json';
import msciBisrgeneticengineering from './msci-bisrgeneticengineering.json';
import msciBisrislamic from './msci-bisrislamic.json';
import msciBisrlendingpractices from './msci-bisrlendingpractices.json';
import msciBisrnuclearpower from './msci-bisrnuclearpower.json';
import msciBisrnuclearweapons from './msci-bisrnuclearweapons.json';
import msciBisrpalmoil from './msci-bisrpalmoil.json';
import msciBisrstemcell from './msci-bisrstemcell.json';
import msciBisrtobacco from './msci-bisrtobacco.json';
import msciBisrusccb from './msci-bisrusccb.json';
import msciBurma from './msci-burma.json';
import msciCarbonemissionsandenergyuse from './msci-carbonemissionsandenergyuse.json';
import msciCarbonemissionspb from './msci-carbonemissionspb.json';
import msciCarbonemissionsscope3estimates from './msci-carbonemissionsscope3estimates.json';
import msciCeqi from './msci-ceqi.json';
import msciClimatechangetargetsandcommitments from './msci-climatechangetargetsandcommitments.json';
import msciClimatechangetargetsandcommitmentssummary from './msci-climatechangetargetsandcommitmentssummary.json';
import msciClimateValueAtRisk from './msci-climatevalueatrisk.json';
import msciClimatevalueatriskcompanylevel from './msci-climatevalueatriskcompanylevel.json';
import msciControversies from './msci-controversies.json';
import msciControversiesscores from './msci-controversiesscores.json';
import msciControversycases from './msci-controversycases.json';
import msciCountryclimatechangedata from './msci-countryclimatechangedata.json';
import msciCrcapex from './msci-crcapex.json';
import msciCuba from './msci-cuba.json';
import msciDerivedesg from './msci-derivedesg.json';
import msciDrilldown from './msci-drilldown.json';
import msciEetsupp from './msci-eetsupp.json';
import msciEmissionintensity from './msci-emissionintensity.json';
import msciEmissionreductionrequirement from './msci-emissionreductionrequirement.json';
import msciEnvironmentalimpactrevenue from './msci-environmentalimpactrevenue.json';
import msciEsgfundmetrics from './msci-esgfundmetrics.json';
import msciEutaxonomyalignment from './msci-eutaxonomyalignment.json';
import msciEutaxonomyts from './msci-eutaxonomyts.json';
import msciFcpa from './msci-fcpa.json';
import msciFinancialriskcategories from './msci-financialriskcategories.json';
import msciFossilfuelproductionandreserves from './msci-fossilfuelproductionandreserves.json';
import msciFundbiodiversity from './msci-fundbiodiversity.json';
import msciFundclimatechange from './msci-fundclimatechange.json';
import msciFundeusustfi from './msci-fundeusustfi.json';
import msciFundimpact from './msci-fundimpact.json';
import msciFundmetricseusustainablefinance from './msci-fundmetricseusustainablefinance.json';
import msciFundratingsandscores from './msci-fundratingsandscores.json';
import msciFundratingsclimatechange from './msci-fundratingsclimatechange.json';
import msciFundrisk from './msci-fundrisk.json';
import msciFundvaluesalignment from './msci-fundvaluesalignment.json';
import msciGlobalsanctions from './msci-globalsanctions.json';
import msciGovernancedata from './msci-governancedata.json';
import msciGovernmentratingrisk from './msci-governmentratingsrisk.json';
import msciGovernmentratingsscreening from './msci-governmentratingsscreening.json';
import msciGovernmentratingsscreeningts from './msci-governmentratingsscreeningts.json';
import msciGovernmentratingssummary from './msci-governmentratingssummary.json';
import msciGovratings from './msci-govratings.json';
import msciGreenbond from './msci-greenbond.json';
import msciIran from './msci-iran.json';
import msciIsrael from './msci-israel.json';
import msciIssuersummary from './msci-issuersummary.json';
import msciItr from './msci-itr.json';
import msciLowcarbonpatents from './msci-lowcarbonpatents.json';
import msciLowcarbontransitionscores from './msci-lowcarbontransitionscores.json';
import msciMifid from './msci-mifid.json';
import msciNaturalcapitalpollution from './msci-naturalcapitalpollution.json';
import msciNorthernireland from './msci-northernireland.json';
import msciNorthkorea from './msci-northkorea.json';
import msciOfac from './msci-ofac.json';
import msciPhyscvar1pt5ngfs from './msci-physcvar1pt5ngfs.json';
import msciPhyscvar2ipcc from './msci-physcvar2ipcc.json';
import msciPhyscvar2ngfs from './msci-physcvar2ngfs.json';
import msciPhyscvar3ipcc from './msci-physcvar3ipcc.json';
import msciPhyscvar3ngfs from './msci-physcvar3ngfs.json';
import msciPhyscvar4ipcc from './msci-physcvar4ipcc.json';
import msciPhyscvar5ipcc from './msci-physcvar5ipcc.json';
import msciPhyscvarlegacy from './msci-physcvarlegacy.json';
import msciPhysremind1pt5and2d from './msci-physremind1pt5and2d.json';
import msciPhysremindngfs3d from './msci-physremindngfs3d.json';
import msciPhysriskhazard from './msci-physriskhazard.json';
import msciPowergenerationcapacity from './msci-powergenerationcapacity.json';
import msciRussiaukraine from './msci-russiaukraine.json';
import msciScbqi from './msci-scbqi.json';
import msciScenarioanalysisgvt from './msci-scenarioanalysisgvt.json';
import msciScenarioanalysisgvtissueryc from './msci-scenarioanalysisgvtissueryc.json';
import msciAlignment from './msci-sdgalignment.json';
import msciSema from './msci-sema.json';
import msciSfdr from './msci-sfdr.json';
import msciSim from './msci-sim.json';
import msciSocialcorporatebehavior from './msci-socialcorporatebehavior.json';
import msciSudan from './msci-sudan.json';
import msciSummaryscores from './msci-summaryscores.json';
import msciSyria from './msci-syria.json';
import msciTaxonucgasaligncapex from './msci-taxonucgasaligncapex.json';
import msciTaxonucgasaligncapexts from './msci-taxonucgasaligncapexts.json';
import msciTaxonucgasalignopex from './msci-taxonucgasalignopex.json';
import msciTaxonucgasalignturnover from './msci-taxonucgasalignturnover.json';
import msciTaxonucgasalignturnoverts from './msci-taxonucgasalignturnoverts.json';
import msciTaxonucgaseligicapex from './msci-taxonucgaseligicapex.json';
import msciTaxonucgaseligicapexts from './msci-taxonucgaseligicapexts.json';
import msciTaxonucgaseligiopex from './msci-taxonucgaseligiopex.json';
import msciTaxonucgaseligiturnover from './msci-taxonucgaseligiturnover.json';
import msciTaxonucgaseligiturnoverts from './msci-taxonucgaseligiturnoverts.json';
import msciTaxonucgasexposure from './msci-taxonucgasexposure.json';
import msciTaxorepnonfialignmentts from './msci-taxorepnonfialignmentts.json';
import msciTaxorepnonfieligibilityts from './msci-taxorepnonfieligibilityts.json';
import msciTemperaturealignment from './msci-temperaturealignment.json';
import msciTransaimcge from './msci-transaimcge.json';
import msciTranscvar1pt5ngfs from './msci-transcvar1pt5ngfs.json';
import msciTranscvar2ngfs from './msci-transcvar2ngfs.json';
import msciTranscvar3ngfs from './msci-transcvar3ngfs.json';
import msciTranscvargcam from './msci-transcvargcam.json';
import msciTranscvarimage from './msci-transcvarimage.json';
import msciTransitionremindngfs from './msci-transitionremindngfs.json';
import msciUndscphysicalcosts1d from './msci-undscphysicalcosts1d.json';
import msciUndscphysicalcosts2dipccssp1and2pt6 from './msci-undscphysicalcosts2dipccssp1and2pt6.json';
import msciUndscphysicalcosts2drdisorderly from './msci-undscphysicalcosts2drdisorderly.json';
import msciUndscphysicalcosts2drorderly from './msci-undscphysicalcosts2drorderly.json';
import msciUndscphysicalcosts3dipccssp2and4pt5 from './msci-undscphysicalcosts3dipccssp2and4pt5.json';
import msciUndscphysicalcosts3drcurrentpolicies from './msci-undscphysicalcosts3drcurrentpolicies.json';
import msciUndscphysicalcosts3drndc from './msci-undscphysicalcosts3drndc.json';
import msciUndscphysicalcosts4d from './msci-undscphysicalcosts4d.json';
import msciUndscphysicalcosts5d from './msci-undscphysicalcosts5d.json';
import msciUndsctransitioncostsaimcge1pt5d from './msci-undsctransitioncostsaimcge1pt5d.json';
import msciUndsctransitioncostsaimcge2d from './msci-undsctransitioncostsaimcge2d.json';
import msciUndsctransitioncostsaimcge3d from './msci-undsctransitioncostsaimcge3d.json';
import msciUndsctransitioncostsgcamssp1and2d from './msci-undsctransitioncostsgcamssp1and2d.json';
import msciUndsctransitioncostsimagessp1and2d from './msci-undsctransitioncostsimagessp1and2d.json';
import msciUndsctransitioncostsremind from './msci-undsctransitioncostsremind.json';
import msciWorkforceanddiversity from './msci-workforceanddiversity.json';
import owlesgOwlEsgConsensusRatings from './owlesg-owlesgconsensusratings.json';
import positionBasedReporting from './position-based-reporting.json';
import repriskUnsdgsandcontroversies from './reprisk-unsdgsandcontroversies.json';
import rightbasedonscienceClimateimpact from './rightbasedonscience-climateimpact.json';
import sequantisScr from './sequantis-scr.json';
import sequantisScr2 from './sequantis-scr2.json';
import sequantisTptcreation from './sequantis-tptcreation.json';
import sesammEsgcontroversyalert from './sesamm-esgcontroversyalert.json';
import sesammEsgrelativescore from './sesamm-esgrelativescore.json';
import sustainalyticsCarbonemissions from './sustainalytics-carbonemissions.json';
import sustainalyticsCarbonriskrating from './sustainalytics-carbonriskrating.json';
import sustainalyticsControversialweapons from './sustainalytics-controversialweapons.json';
import sustainalyticsControversies from './sustainalytics-controversies.json';
import sustainalyticsCountryriskrating from './sustainalytics-countryriskrating.json';
import sustainalyticsEsgriskrating from './sustainalytics-esgriskrating.json';
import sustainalyticsEuTaxonomy from './sustainalytics-eutaxonomy.json';
import sustainalyticsGlobalStandardsScreening from './sustainalytics-globalstandardsscreening.json';
import sustainalyticsImsustainableactivities from './sustainalytics-imsustainableactivities.json';
import sustainalyticsImthemesdg from './sustainalytics-imthemesdg.json';
import sustainalyticsImtoplevel from './sustainalytics-imtoplevel.json';
import sustainalyticsLctr from './sustainalytics-lctr.json';
import sustainalyticsLctrannualemissions from './sustainalytics-lctrannualemissions.json';
import sustainalyticsLctrmanagementindicators from './sustainalytics-lctrmanagementindicators.json';
import sustainalyticsProductInvolvement from './sustainalytics-productinvolvement.json';
import sustainalyticsSfdrpai from './sustainalytics-sfdrpai.json';
import trucostCarbonearningsatrisk from './trucost-carbonearningsatrisk.json';
import trucostCarbonfootprint from './trucost-carbonfootprint.json';
import trucostClimatedataphysicalrisk from './trucost-climatedataphysicalrisk.json';
import trucostEnvironmentaldatacoalpubliccompanies from './trucost-environmentaldatacoalpubliccompanies.json';
import trucostEnvironmentaldataghgemissionsprivatecompanies from './trucost-environmentaldataghgemissionsprivatecompanies.json';
import trucostEnvironmentaldataghgemissionspublicompanies from './trucost-environmentaldataghgemissionspublicompanies.json';
import trucostEnvironmentaldatawastewaterpubliccompanies from './trucost-environmentaldatawastewaterpubliccompanies.json';
import trucostEnvironmentalfootprint from './trucost-environmentalfootprint.json';
import trucostEsganalytics from './trucost-esganalytics.json';
import trucostEutaxonomy from './trucost-eutaxonomy.json';
import trucostEutaxonomyrevenuesharecompanylevel from './trucost-eutaxonomyrevenuesharecompanylevel.json';
import trucostGlobalesgscores from './trucost-globalesgscores.json';
import trucostGreenbonds from './trucost-greenbonds.json';
import trucostParisalignment from './trucost-parisalignment.json';
import trucostParisalignmentdata from './trucost-parisalignmentdata.json';
import trucostPhysicalrisk from './trucost-physicalrisk.json';
import trucostSdganalytics from './trucost-sdganalytics.json';
import trucostSdganalyticscompanylevel from './trucost-sdganalyticscompanylevel.json';
import trucostSfdranalytics from './trucost-sfdranalytics.json';
import trucostSovereign from './trucost-sovereign.json';
import trucostStrandedassets from './trucost-strandedassets.json';
import trucostTcfd from './trucost-tcfd.json';
import trucostTransitionrisk from './trucost-transitionrisk.json';
import trusteamFinance from './trusteam-finance.json';
import urgewaldGcel from './urgewald-gcel.json';
import urgewaldGogelgasfiredpowerexpansion from './urgewald-gogelgasfiredpowerexpansion.json';
import urgewaldGogelmidstreamexpansion from './urgewald-gogelmidstreamexpansion.json';
import urgewaldGogelupstream from './urgewald-gogelupstream.json';
import utilUnsdgs from './util-unsdgs.json';
import vigeoClimatereporting from './vigeo-climatereporting.json';
import vigeoControversies from './vigeo-controversies.json';
import vigeoEsgreporting from './vigeo-esgreporting.json';
import vigeoEsgsuperpremium from './vigeo-esgsuperpremium.json';
import vigeoEutaxonomy from './vigeo-eutaxonomy.json';
import vigeoSfdrcorpo from './vigeo-sfdrcorpo.json';
import vigeoSfdrsov from './vigeo-sfdrsov.json';

const appsMap = {
  'bnppam-esgdatasharing': bnppamEsgdatasharing,
  'cdp-scores': cdpScores,
  'clarity-biodiversityimpactdata': clarityBiodiversityimpactdata,
  'clarity-climate': clarityClimate,
  'clarity-dependencydata': clarityDependencydata,
  'clarity-esgimpact': clarityEsgimpact,
  'clarity-esgrisk': clarityEsgrisk,
  'clarity-eutaxonomy': clarityEutaxonomy,
  'clarity-impacthighlights': clarityImpacthighlights,
  'clarity-negativescreening': clarityNegativescreening,
  'clarity-rawdata': clarityRawdata,
  'clarity-sfdr': claritySfdr,
  'clarity-unsdgs': clarityUnsdgs,
  'denominator-dei': denominatorDei,
  'esgbook-disclosuresdatafeed': esgbookDisclosuresdatafeed,
  'esgbook-emissionsestimations': esgbookEmissionsestimations,
  'esgbook-esgperformancescore': esgbookEsgperformancescore,
  'esgbook-sfdrpai': esgbookSfdrpai,
  'esgbook-temperaturescore': esgbookTemperaturescore,
  'greenomy-eutaxonomysfdr': greenomyEutaxonomysfdr,
  'icebergdatalab-icebergdl': icebergdatalabIcebergdl,
  'iss-biodiversityimpact': issBiodiversityImpact,
  'iss-carbonriskrating': issCarbonRiskRating,
  'iss-climatecore': issClimateCore,
  'iss-climateimpact': issClimateImpact,
  'iss-climatephysicalrisk': issClimatePhysicalRisk,
  'iss-climatetransitionrisk': issClimateTransitionRisk,
  'iss-corporateratings': issCorporateratings,
  'iss-eetregulatory': issEetRegulatory,
  'iss-eetscreening': issEetScreening,
  'iss-esqs': issEsqs,
  'iss-governance': issGovernance,
  'iss-modernslavery': issModernSlavery,
  'iss-netzero': issNetZero,
  'iss-potentialavoidedemissions': issPotentialAvoidedEmissions,
  'iss-rsi': issRsi,
  'iss-sdga': issSdga,
  'iss-sdgrating': issSdgRating,
  'iss-sectorscreening': issSectorscreening,
  'iss-sovereignemissions': issSovereignEmissions,
  'iss-waterrisk': issWaterRisk,
  'lbpam-great': lbpamGreat,
  'mainstreet-gssbondsratingsimpact': mainstreetGssbondsratingsimpact,
  'manaos-coverageestimation': manaosCoverageEstimation,
  'manaos-datalake': manaosDatalake,
  'manaos-eet': manaosEet,
  'manaos-paistatement': manaosPaistatement,
  'manaos-productrangecollection': manaosProductrangecollection,
  'manaos-productrangedissemination': manaosProductrangedissemination,
  'msci-agrratings': msciAgrratings,
  'msci-agrsupp': msciAgrsupp,
  'msci-biodiversity': msciBiodiversity,
  'msci-bisr': msciBisr,
  'msci-bisrabortion': msciBisrabortion,
  'msci-bisradultentertainment': msciBisradultentertainment,
  'msci-bisralcohol': msciBisralcohol,
  'msci-bisranimalwelfare': msciBisranimalwelfare,
  'msci-bisrbiocides': msciBisrbiocides,
  'msci-bisrbreastmilk': msciBisrbreastmilk,
  'msci-bisrcannabis': msciBisrcannabis,
  'msci-bisrcontroversialweapons': msciBisrcontroversialweapons,
  'msci-bisrconventionalweapons': msciBisrconventionalweapons,
  'msci-bisrfirearms': msciBisrfirearms,
  'msci-bisrforprofithealthcare': msciBisrforprofithealthcare,
  'msci-bisrforprofitprisons': msciBisrforprofitprisons,
  'msci-bisrfur': msciBisrfur,
  'msci-bisrgambling': msciBisrgambling,
  'msci-bisrgeneticengineering': msciBisrgeneticengineering,
  'msci-bisrislamic': msciBisrislamic,
  'msci-bisrlendingpractices': msciBisrlendingpractices,
  'msci-bisrnuclearpower': msciBisrnuclearpower,
  'msci-bisrnuclearweapons': msciBisrnuclearweapons,
  'msci-bisrpalmoil': msciBisrpalmoil,
  'msci-bisrstemcell': msciBisrstemcell,
  'msci-bisrtobacco': msciBisrtobacco,
  'msci-bisrusccb': msciBisrusccb,
  'msci-burma': msciBurma,
  'msci-carbonemissionsandenergyuse': msciCarbonemissionsandenergyuse,
  'msci-carbonemissionspb': msciCarbonemissionspb,
  'msci-carbonemissionsscope3estimates': msciCarbonemissionsscope3estimates,
  'msci-ceqi': msciCeqi,
  'msci-climatechangetargetsandcommitments': msciClimatechangetargetsandcommitments,
  'msci-climatechangetargetsandcommitmentssummary': msciClimatechangetargetsandcommitmentssummary,
  'msci-climatevalueatrisk': msciClimateValueAtRisk,
  'msci-climatevalueatriskcompanylevel': msciClimatevalueatriskcompanylevel,
  'msci-controversies': msciControversies,
  'msci-controversiesscores': msciControversiesscores,
  'msci-controversycases': msciControversycases,
  'msci-countryclimatechangedata': msciCountryclimatechangedata,
  'msci-crcapex': msciCrcapex,
  'msci-cuba': msciCuba,
  'msci-derivedesg': msciDerivedesg,
  'msci-drilldown': msciDrilldown,
  'msci-eetsupp': msciEetsupp,
  'msci-emissionintensity': msciEmissionintensity,
  'msci-emissionreductionrequirement': msciEmissionreductionrequirement,
  'msci-environmentalimpactrevenue': msciEnvironmentalimpactrevenue,
  'msci-esgfundmetrics': msciEsgfundmetrics,
  'msci-eutaxonomyalignment': msciEutaxonomyalignment,
  'msci-eutaxonomyts': msciEutaxonomyts,
  'msci-fcpa': msciFcpa,
  'msci-financialriskcategories': msciFinancialriskcategories,
  'msci-fossilfuelproductionandreserves': msciFossilfuelproductionandreserves,
  'msci-fundbiodiversity': msciFundbiodiversity,
  'msci-fundclimatechange': msciFundclimatechange,
  'msci-fundeusustfi': msciFundeusustfi,
  'msci-fundimpact': msciFundimpact,
  'msci-fundmetricseusustainablefinance': msciFundmetricseusustainablefinance,
  'msci-fundratingsandscores': msciFundratingsandscores,
  'msci-fundratingsclimatechange': msciFundratingsclimatechange,
  'msci-fundrisk': msciFundrisk,
  'msci-fundvaluesalignment': msciFundvaluesalignment,
  'msci-globalsanctions': msciGlobalsanctions,
  'msci-governancedata': msciGovernancedata,
  'msci-governmentratingsrisk': msciGovernmentratingrisk,
  'msci-governmentratingsscreening': msciGovernmentratingsscreening,
  'msci-governmentratingsscreeningts': msciGovernmentratingsscreeningts,
  'msci-governmentratingssummary': msciGovernmentratingssummary,
  'msci-govratings': msciGovratings,
  'msci-greenbond': msciGreenbond,
  'msci-iran': msciIran,
  'msci-israel': msciIsrael,
  'msci-issuersummary': msciIssuersummary,
  'msci-itr': msciItr,
  'msci-lowcarbonpatents': msciLowcarbonpatents,
  'msci-lowcarbontransitionscores': msciLowcarbontransitionscores,
  'msci-mifid': msciMifid,
  'msci-naturalcapitalpollution': msciNaturalcapitalpollution,
  'msci-northernireland': msciNorthernireland,
  'msci-northkorea': msciNorthkorea,
  'msci-ofac': msciOfac,
  'msci-physcvar1pt5ngfs': msciPhyscvar1pt5ngfs,
  'msci-physcvar2ipcc': msciPhyscvar2ipcc,
  'msci-physcvar2ngfs': msciPhyscvar2ngfs,
  'msci-physcvar3ipcc': msciPhyscvar3ipcc,
  'msci-physcvar3ngfs': msciPhyscvar3ngfs,
  'msci-physcvar4ipcc': msciPhyscvar4ipcc,
  'msci-physcvar5ipcc': msciPhyscvar5ipcc,
  'msci-physcvarlegacy': msciPhyscvarlegacy,
  'msci-physremind1pt5and2d': msciPhysremind1pt5and2d,
  'msci-physremindngfs3d': msciPhysremindngfs3d,
  'msci-physriskhazard': msciPhysriskhazard,
  'msci-powergenerationcapacity': msciPowergenerationcapacity,
  'msci-russiaukraine': msciRussiaukraine,
  'msci-scbqi': msciScbqi,
  'msci-scenarioanalysisgvt': msciScenarioanalysisgvt,
  'msci-scenarioanalysisgvtissueryc': msciScenarioanalysisgvtissueryc,
  'msci-sdgalignment': msciAlignment,
  'msci-sema': msciSema,
  'msci-sfdr': msciSfdr,
  'msci-sim': msciSim,
  'msci-socialcorporatebehavior': msciSocialcorporatebehavior,
  'msci-sudan': msciSudan,
  'msci-summaryscores': msciSummaryscores,
  'msci-syria': msciSyria,
  'msci-taxonucgasaligncapex': msciTaxonucgasaligncapex,
  'msci-taxonucgasaligncapexts': msciTaxonucgasaligncapexts,
  'msci-taxonucgasalignopex': msciTaxonucgasalignopex,
  'msci-taxonucgasalignturnover': msciTaxonucgasalignturnover,
  'msci-taxonucgasalignturnoverts': msciTaxonucgasalignturnoverts,
  'msci-taxonucgaseligicapex': msciTaxonucgaseligicapex,
  'msci-taxonucgaseligicapexts': msciTaxonucgaseligicapexts,
  'msci-taxonucgaseligiopex': msciTaxonucgaseligiopex,
  'msci-taxonucgaseligiturnover': msciTaxonucgaseligiturnover,
  'msci-taxonucgaseligiturnoverts': msciTaxonucgaseligiturnoverts,
  'msci-taxonucgasexposure': msciTaxonucgasexposure,
  'msci-taxorepnonfialignmentts': msciTaxorepnonfialignmentts,
  'msci-taxorepnonfieligibilityts': msciTaxorepnonfieligibilityts,
  'msci-temperaturealignment': msciTemperaturealignment,
  'msci-transaimcge': msciTransaimcge,
  'msci-transcvar1pt5ngfs': msciTranscvar1pt5ngfs,
  'msci-transcvar2ngfs': msciTranscvar2ngfs,
  'msci-transcvar3ngfs': msciTranscvar3ngfs,
  'msci-transcvargcam': msciTranscvargcam,
  'msci-transcvarimage': msciTranscvarimage,
  'msci-transitionremindngfs': msciTransitionremindngfs,
  'msci-undscphysicalcosts1d': msciUndscphysicalcosts1d,
  'msci-undscphysicalcosts2dipccssp1and2pt6': msciUndscphysicalcosts2dipccssp1and2pt6,
  'msci-undscphysicalcosts2drdisorderly': msciUndscphysicalcosts2drdisorderly,
  'msci-undscphysicalcosts2drorderly': msciUndscphysicalcosts2drorderly,
  'msci-undscphysicalcosts3dipccssp2and4pt5': msciUndscphysicalcosts3dipccssp2and4pt5,
  'msci-undscphysicalcosts3drcurrentpolicies': msciUndscphysicalcosts3drcurrentpolicies,
  'msci-undscphysicalcosts3drndc': msciUndscphysicalcosts3drndc,
  'msci-undscphysicalcosts4d': msciUndscphysicalcosts4d,
  'msci-undscphysicalcosts5d': msciUndscphysicalcosts5d,
  'msci-undsctransitioncostsaimcge1pt5d': msciUndsctransitioncostsaimcge1pt5d,
  'msci-undsctransitioncostsaimcge2d': msciUndsctransitioncostsaimcge2d,
  'msci-undsctransitioncostsaimcge3d': msciUndsctransitioncostsaimcge3d,
  'msci-undsctransitioncostsgcamssp1and2d': msciUndsctransitioncostsgcamssp1and2d,
  'msci-undsctransitioncostsimagessp1and2d': msciUndsctransitioncostsimagessp1and2d,
  'msci-undsctransitioncostsremind': msciUndsctransitioncostsremind,
  'msci-workforceanddiversity': msciWorkforceanddiversity,
  'owlesg-owlesgconsensusratings': owlesgOwlEsgConsensusRatings,
  'position-based-reporting': positionBasedReporting,
  'reprisk-unsdgsandcontroversies': repriskUnsdgsandcontroversies,
  'rightbasedonscience-climateimpact': rightbasedonscienceClimateimpact,
  'sequantis-scr': sequantisScr,
  'sequantis-scr2': sequantisScr2,
  'sequantis-tptcreation': sequantisTptcreation,
  'sesamm-esgcontroversyalert': sesammEsgcontroversyalert,
  'sesamm-esgrelativescore': sesammEsgrelativescore,
  'sustainalytics-carbonemissions': sustainalyticsCarbonemissions,
  'sustainalytics-carbonriskrating': sustainalyticsCarbonriskrating,
  'sustainalytics-controversialweapons': sustainalyticsControversialweapons,
  'sustainalytics-controversies': sustainalyticsControversies,
  'sustainalytics-countryriskrating': sustainalyticsCountryriskrating,
  'sustainalytics-esgriskrating': sustainalyticsEsgriskrating,
  'sustainalytics-eutaxonomy': sustainalyticsEuTaxonomy,
  'sustainalytics-globalstandardsscreening': sustainalyticsGlobalStandardsScreening,
  'sustainalytics-imsustainableactivities': sustainalyticsImsustainableactivities,
  'sustainalytics-imthemesdg': sustainalyticsImthemesdg,
  'sustainalytics-imtoplevel': sustainalyticsImtoplevel,
  'sustainalytics-lctr': sustainalyticsLctr,
  'sustainalytics-lctrannualemissions': sustainalyticsLctrannualemissions,
  'sustainalytics-lctrmanagementindicators': sustainalyticsLctrmanagementindicators,
  'sustainalytics-productinvolvement': sustainalyticsProductInvolvement,
  'sustainalytics-sfdrpai': sustainalyticsSfdrpai,
  'trucost-carbonearningsatrisk': trucostCarbonearningsatrisk,
  'trucost-carbonfootprint': trucostCarbonfootprint,
  'trucost-climatedataphysicalrisk': trucostClimatedataphysicalrisk,
  'trucost-environmentaldatacoalpubliccompanies': trucostEnvironmentaldatacoalpubliccompanies,
  'trucost-environmentaldataghgemissionsprivatecompanies': trucostEnvironmentaldataghgemissionsprivatecompanies,
  'trucost-environmentaldataghgemissionspublicompanies': trucostEnvironmentaldataghgemissionspublicompanies,
  'trucost-environmentaldatawastewaterpubliccompanies': trucostEnvironmentaldatawastewaterpubliccompanies,
  'trucost-environmentalfootprint': trucostEnvironmentalfootprint,
  'trucost-esganalytics': trucostEsganalytics,
  'trucost-eutaxonomy': trucostEutaxonomy,
  'trucost-eutaxonomyrevenuesharecompanylevel': trucostEutaxonomyrevenuesharecompanylevel,
  'trucost-globalesgscores': trucostGlobalesgscores,
  'trucost-greenbonds': trucostGreenbonds,
  'trucost-parisalignment': trucostParisalignment,
  'trucost-parisalignmentdata': trucostParisalignmentdata,
  'trucost-physicalrisk': trucostPhysicalrisk,
  'trucost-sdganalytics': trucostSdganalytics,
  'trucost-sdganalyticscompanylevel': trucostSdganalyticscompanylevel,
  'trucost-sfdranalytics': trucostSfdranalytics,
  'trucost-sovereign': trucostSovereign,
  'trucost-strandedassets': trucostStrandedassets,
  'trucost-tcfd': trucostTcfd,
  'trucost-transitionrisk': trucostTransitionrisk,
  'trusteam-finance': trusteamFinance,
  'urgewald-gcel': urgewaldGcel,
  'urgewald-gogelgasfiredpowerexpansion': urgewaldGogelgasfiredpowerexpansion,
  'urgewald-gogelmidstreamexpansion': urgewaldGogelmidstreamexpansion,
  'urgewald-gogelupstream': urgewaldGogelupstream,
  'util-unsdgs': utilUnsdgs,
  'vigeo-climatereporting': vigeoClimatereporting,
  'vigeo-controversies': vigeoControversies,
  'vigeo-esgreporting': vigeoEsgreporting,
  'vigeo-esgsuperpremium': vigeoEsgsuperpremium,
  'vigeo-eutaxonomy': vigeoEutaxonomy,
  'vigeo-sfdrcorpo': vigeoSfdrcorpo,
  'vigeo-sfdrsov': vigeoSfdrsov,
  data_collect: manaosCollection,
  data_dissemination: manaosDissemination,
  data_extractor: manaosDataextractor,
};

export type AppCode = keyof typeof appsMap;

export const apps = appsMap as Record<AppCode, ApplicationConfig>;

export const appCodes = objectKeys(appsMap);

export const isAppCode = (code: string): code is AppCode => appCodes.includes(code as AppCode);

export const providerNames = [
  'bnppam',
  'bp2s',
  'cdp',
  'clarity',
  'esgbook',
  'denominator',
  'greenomy',
  'icebergdatalab',
  'iss',
  'lbpam',
  'mainstreet',
  'manaos',
  'msci',
  'owlesg',
  'reprisk',
  'sequantis',
  'sesamm',
  'sustainalytics',
  'trucost',
  'trusteam',
  'urgewald',
  'util',
  'vigeo',
  'rightbasedonscience',
] as const;

export type ProviderName = typeof providerNames[number] & string;

export const appCodesGroupedByProviders = appCodes.reduce((acc, code) => {
  const providerName = Object.values(apps).find((app) => app.appCode === code)?.company.providerName;
  if (providerName && providerNames.includes(providerName)) {
    if (!Array.isArray(acc[providerName])) {
      acc[providerName] = [];
    }
    acc[providerName].push(code);
  }
  return acc;
}, {} as Record<ProviderName, AppCode[]>);
