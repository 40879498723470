import type { JsonSchemaObject, ButtonProps } from 'mns-components';
import {
  DialogFilters,
  useTestid,
  objectEntries,
  convertToDateLocal,
  ButtonList,
  Form,
  FormCreator,
  useCallbackImmutable,
  Icon,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { previousYearDate, now } from '../../../../common/date';
import { useFilesRequest } from '../../../../components/views/hooks';
import type { UploadFilter } from './UploadListRequest';

const useFiltersJsonSchema = (uploadStatus: string[]): JsonSchemaObject =>
  useMemo(
    () => ({
      properties: {
        search: { type: 'string', variant: 'search', title: 'Search a file name' },
        referenceDate: {
          type: 'string',
          variant: 'period',
          title: 'Reference date',
          default: convertToDateLocal(previousYearDate) + ',' + convertToDateLocal(now),
        },
        uploadStatus: {
          type: 'array',
          variant: 'dropdown',
          title: 'Upload Status',
          items: { type: 'string', enum: uploadStatus },
        },
      },
    }),
    [uploadStatus],
  );

const useButtons = (testid: string, onDownload?: () => void, onOpenFiltersModal?: () => void) =>
  useMemo(
    (): (ButtonProps & { key: string })[] => [
      {
        key: 'download',
        startIcon: <Icon.Download data-testid={`${testid}-icon-download`} />,
        children: 'Download',
        color: 'primary',
        variant: 'outlined',
        disabled: !onDownload,
        onClick: onDownload,
        'data-testid': `${testid}-download`,
      },
      {
        key: 'filters',
        startIcon: <Icon.Filter data-testid={`${testid}-icon-filter`} />,
        children: 'Filters',
        color: 'primary',
        variant: 'outlined',
        disabled: !onOpenFiltersModal,
        onClick: onOpenFiltersModal,
        'data-testid': `${testid}-filters`,
      },
    ],
    [onDownload, onOpenFiltersModal, testid],
  );

type FilterStatusCount = Record<'OK' | 'REJECTED', number>;
type MapStatus = Record<'OK' | 'REJECTED', string>;

const mapStatus: MapStatus = {
  OK: 'Accepted',
  REJECTED: 'Rejected',
};

const viewFileTypes: CollectApi.File['dataFormat'][] = ['EET', 'EMT'];

type UploadControllerProps = {
  setFilters: React.Dispatch<React.SetStateAction<UploadFilter>>;
  onDownload?(): void;
  'data-testid': string;
};

export const UploadController: React.FC<UploadControllerProps> = ({
  setFilters,
  onDownload,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const handleOpenFiltersModal = useCallbackImmutable(() => setOpenFiltersModal(true));
  const handleCloseFiltersModal = useCallbackImmutable(() => setOpenFiltersModal(false));

  const [rawFilters, setRawFilters] = useState<{ referenceDate: string; uploadStatus?: string[]; search: string }>({
    referenceDate: '',
    search: '',
  });
  const [pickerStartDate, pickerEndDate] = (() => {
    const periodSplit = rawFilters.referenceDate?.split(',');
    if (periodSplit) {
      const startDate = periodSplit[0] ? periodSplit[0] : undefined;
      const endDate = periodSplit[1] ? periodSplit[1] : undefined;
      return [startDate, endDate];
    }
    return [];
  })();

  // fetch all files
  const { data: filesData } = useFilesRequest({
    startDate: pickerStartDate,
    endDate: pickerEndDate,
    dataFormats: viewFileTypes,
  });
  const dict = useMemo(
    (): FilterStatusCount =>
      filesData?.reduce(
        (acc, item) => {
          switch (item.status) {
            case 'OK':
            case 'WARNING':
              acc.OK++;
              break;
            case 'REJECTED':
              acc.REJECTED++;
          }
          return acc;
        },
        { OK: 0, REJECTED: 0 } as FilterStatusCount,
      ) ?? { OK: 0, REJECTED: 0 },
    [filesData],
  );
  const mapStatusFilters = useMemo(
    () =>
      objectEntries(dict).reduce((acc, [key, value]) => {
        acc[key] = `${mapStatus[key]} (${value})`;
        return acc;
      }, {} as MapStatus),
    [dict],
  );
  const statusFilters = useMemo(() => Object.values(mapStatusFilters), [mapStatusFilters]);
  const filtersJsonSchema = useFiltersJsonSchema(statusFilters);

  useEffect(
    () =>
      setFilters(() => {
        const uploadStatuses: CollectApi.File['status'][] = [];
        if (rawFilters.uploadStatus?.includes(mapStatusFilters.OK)) {
          uploadStatuses.push('OK', 'WARNING');
        }
        if (rawFilters.uploadStatus?.includes(mapStatusFilters.REJECTED)) {
          uploadStatuses.push('REJECTED');
        }
        return {
          search: rawFilters.search,
          referenceDate: rawFilters.referenceDate,
          uploadStatus: uploadStatuses,
        };
      }),
    [mapStatusFilters, rawFilters, setFilters],
  );

  const buttons = useButtons(createTestid('buttons'), onDownload, handleOpenFiltersModal);

  return (
    <>
      <Form data-testid={createTestid('form')}>
        <FormCreator
          jsonSchema={filtersJsonSchema}
          state={rawFilters}
          setState={setRawFilters}
          data-testid={createTestid('filters')}
        />
      </Form>
      <ButtonList buttons={buttons} data-testid={createTestid('buttons')} />
      <DialogFilters
        dialogTitle="Filters"
        open={openFiltersModal}
        onClose={handleCloseFiltersModal}
        jsonSchema={filtersJsonSchema}
        currentFilters={rawFilters}
        onFiltersChange={setRawFilters}
        data-testid={createTestid('modal')}
      />
    </>
  );
};
