import { Box, Stack, Tooltip } from '@mui/material';
import { Icon, Link, TextField, Typography, colors, useTestid } from 'mns-components';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';
import { routes } from './routes';

export const UsernameField: React.FC<{ 'data-testid': string; disabled?: boolean }> = ({
  'data-testid': testid,
  disabled,
}) => {
  return (
    <Stack direction="row">
      <TextField name="username" type="text" label="username" disabled={disabled} data-testid={testid} />
    </Stack>
  );
};

export const CodeField: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  return (
    <Stack direction="row">
      <TextField name="code" type="text" label="code received" autoComplete="code" data-testid={testid} />
    </Stack>
  );
};

export const NewPasswordField: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  return (
    <Stack direction="row" alignItems="center" spacing=".75rem">
      <TextField
        name="newPassword"
        type="password"
        label="new password"
        autoComplete="new-password"
        data-testid={testid}
      />
      <Tooltip
        title="The password size must be greater than 12 characters &amp; must contain at least a lower-case letter, an upper-case letter, a number and a symbol (between @&§!?$%;:=./+_-())"
        data-testid={createTestid('tooltip')}
      >
        <Icon.Info data-testid={createTestid('tooltip')} />
      </Tooltip>
    </Stack>
  );
};

export const ConfirmPasswordField: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  return (
    <Stack direction="row">
      <TextField
        name="confirmPassword"
        type="password"
        label="confirm new password"
        autoComplete="confirm-password"
        data-testid={testid}
      />
    </Stack>
  );
};

export const SuccessRedirectLayout: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  return (
    <AuthPageLayout data-testid={testid}>
      <Typography variant="body1">
        Your password has been successfully updated, you are now going to be redirected.
      </Typography>
      <Link to={routes.loginUri.path} className={classes.link} data-testid={createTestid('success-redirect')}>
        Redirect to login
      </Link>
    </AuthPageLayout>
  );
};

export const ErrorBox: React.FC<{ error: string | undefined; 'data-testid': string }> = ({
  error,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  if (!error) return <></>;
  return (
    <Box
      data-testid={testid}
      color={colors.dangerColor}
      border={`1px solid ${colors.dangerColor}`}
      borderRadius="5px"
      padding="1rem"
      width="fit-content"
      margin="1rem auto"
      bgcolor="rgba(205, 92, 92, 0.15)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize=".75rem"
    >
      <Icon.Error data-testid={createTestid('error')} />
      <Box component="span" marginLeft="0.5rem">
        {error}
      </Box>
    </Box>
  );
};
