import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import { EmptyState, EmptyStateV2, LoadingCircle, useTestid } from 'mns-components';
import type { ClaritySfdrApi, WidgetApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { useFetchClarityDictionary, useFetchClaritySfdrMetrics } from '../../../hooks';
import { ClarityAppSfdrList } from '../tableComponents/ClarityAppSfdrList';

export const computeSfdrData = (metrics: ClaritySfdrApi.Metric[], dictionary: ClaritySfdrApi.Dictionary[]) => {
  const computeDatas = metrics.map((metric) => {
    const dictionaryData = dictionary.find((dictionaryElem) => dictionaryElem.id === metric.id);
    const fullPath =
      dictionaryData?.category && dictionaryData?.name ? [dictionaryData.category, dictionaryData.name] : undefined;
    return {
      ...metric,
      dictionaryName: dictionaryData?.name,
      category: dictionaryData?.category,
      fullPath,
    };
  });

  return computeDatas;
};

export type ClarityTableProps = {
  widgetDoc: WidgetApi.Document;
  getGridApiRef?(api: GridApi): void;
  onRowGroupOpened(api: GridApi): void;
  'data-testid': string;
};

export const ClarityTable: React.FC<ClarityTableProps> = ({
  widgetDoc,
  getGridApiRef,
  onRowGroupOpened,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const {
    isLoading: isLoadingMetrics,
    data: metricsData,
    error: errorMetrics,
  } = useFetchClaritySfdrMetrics(widgetDoc.providerPortfolioId, widgetDoc.widgetCredentials);

  const metrics = metricsData?.metrics;

  const {
    isLoading: isLoadingDictionary,
    data: dictionary,
    error: errorDictionary,
  } = useFetchClarityDictionary(widgetDoc.widgetCredentials);

  const categories = useMemo(() => {
    const allCategories = dictionary?.map((elem: ClaritySfdrApi.Dictionary) => {
      return elem.category;
    });
    return [...new Set(allCategories)];
  }, [dictionary]);

  const catDictionary = useMemo(
    () =>
      categories.map(
        (cat): Pick<ClaritySfdrApi.Metric, 'id' | 'dictionaryName' | 'category' | 'fullPath'> => ({
          id: cat,
          dictionaryName: cat,
          category: cat,
          fullPath: [cat],
        }),
      ),
    [categories],
  );

  const computedSfdrData = useMemo(() => {
    if (metrics?.[0] && dictionary?.[0]) {
      return computeSfdrData(metrics, dictionary);
    }
    return null;
  }, [metrics, dictionary]);

  const computedSfdrDataWithCats = useMemo(() => {
    return catDictionary && computedSfdrData ? [...catDictionary, ...computedSfdrData] : null;
  }, [catDictionary, computedSfdrData]);

  // first, expand only first level
  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        if (node.allChildrenCount && node.level === 0) {
          node.setExpanded(true);
        }
      });
      getGridApiRef?.(gridApi);
    }
  }, [getGridApiRef, gridApi]);

  if (isLoadingMetrics || isLoadingDictionary) {
    return (
      <EmptyState
        iconEnable
        iconName="processing"
        title="Processing…"
        firstParagraph="Your portfolio is currently being analysed by Clarity AI.
      Please wait a few seconds to get your portfolio scores"
        data-testid={createTestid('loading')}
      />
    );
  }

  if (errorMetrics || errorDictionary) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!metrics?.length) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="noActions"
          title="There is no metrics yet!"
          data-testid={createTestid('empty-metrics')}
        />
      </Stack>
    );
  }

  if (computedSfdrDataWithCats) {
    return (
      <ClarityAppSfdrList
        rowData={computedSfdrDataWithCats}
        getGridApiRef={setGridApi}
        onRowGroupOpened={onRowGroupOpened}
        data-testid={testid}
      />
    );
  }

  return <LoadingCircle data-testid={createTestid('loading')} />;
};
