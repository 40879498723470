import { makeStyles } from 'mns-components';

const coverageSfdrRendererStyles = makeStyles({
  coverageRenderer: {
    textAlign: 'right',
    paddingRight: '1.25rem',
  },
});

export default coverageSfdrRendererStyles;
