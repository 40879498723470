import type { FormStepError, FormStepErrors, FormStepProps } from 'mns-components';
import { useCallbackImmutable, useTestid } from 'mns-components';
import type { ProviderApi, MarketplaceApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { AgreeOrderConditionsDisplay } from './AgreeOrderConditions';
import { BillForm } from './BillForm';
import type { PayRequestSteps } from './createRequest.types';
import { formStyles as useStyles } from './formStyles';
import { OfferDetails } from './OfferDetails';
import { OrderDetails } from './OrderDetails';
import { PortfolioDetails } from './PortfolioDetails';

const isFilledString = (el: unknown): el is string => typeof el === 'string' && !!el.length;

export const isValidBillStep = (data?: PayRequestSteps[1]): FormStepErrors<PayRequestSteps[1]> => {
  const errors: FormStepError<PayRequestSteps[1]>[] = [];

  if (!isFilledString(data?.company)) {
    errors.push({
      name: 'company',
      message: 'Invoiced company name must be filled',
    });
  }

  if (!isFilledString(data?.billedTo)) {
    errors.push({
      name: 'billedTo',
      message: 'Invoiced customer name must be filled',
    });
  }

  if (!isFilledString(data?.function)) {
    errors.push({
      name: 'function',
      message: 'Invoiced customer function must be filled',
    });
  }

  if (!isFilledString(data?.address)) {
    errors.push({
      name: 'address',
      message: 'Invoice address must be filled',
    });
  }

  if (!isFilledString(data?.vat)) {
    errors.push({
      name: 'vat',
      message: 'Invoice VAT must be filled',
    });
  }

  if (!data?.agreeOrderConditions) {
    errors.push({
      name: 'agreeOrderConditions',
      message: 'Please agree to General Conditions of Sale',
    });
  }

  return errors;
};

type BillStepProps = FormStepProps<PayRequestSteps[1], PayRequestSteps> & {
  portfolios: ProviderApi.Portfolio[];
  applicationDetails: MarketplaceApi.Application;
  appCode: AppCode;
  'data-testid': string;
};

export const BillStep: React.FC<BillStepProps> = ({
  portfolios,
  applicationDetails,
  appCode,
  stepsValue,
  stepValue,
  setStep,
  setStepValue,
  'data-testid': testid,
}) => {
  const {
    company: { name, providerName },
  } = useApplicationConfig(appCode);

  const [agree, setAgree] = useState(false);
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { portfolioId, offer } = stepsValue[0];
  const portfolio = useMemo(() => portfolios.find((ptf) => ptf.portfolioId === portfolioId), [portfolios, portfolioId]);

  const handleEdit = useCallbackImmutable((data: PayRequestSteps[1]) => {
    setStepValue(({ agreeOrderConditions }) => ({ ...data, agreeOrderConditions }));
    return null;
  });

  useEffect(() => {
    setStepValue((current) => ({ ...current, agreeOrderConditions: agree }));
  }, [agree, setStepValue]);

  if (!portfolio || !offer) {
    setStep((current) => current - 1);
    return null;
  }

  return (
    <div className={classes.formsContainer} data-testid={testid}>
      <OfferDetails offer={offer} data-testid={createTestid('offer')} />
      <PortfolioDetails portfolio={portfolio} data-testid={createTestid('portfolioDetails')} />
      <OrderDetails offer={offer} applicationDetails={applicationDetails} data-testid={createTestid('orderDetails')} />
      <BillForm
        validationAtEvent="all"
        onValidate={handleEdit}
        defaultValues={stepValue}
        data-testid={createTestid('bill')}
      />
      <AgreeOrderConditionsDisplay
        onAgreeOrderConditions={setAgree}
        companyName={name}
        providerName={providerName}
        data-testid={createTestid('agreeConditions')}
      />
    </div>
  );
};
