import { objectValues, type ConfigBuilderOperation } from 'mns-components';
import type { ProviderApi, UseCase } from 'mns-sdk-collect';
import { useMemo } from 'react';
import type { ProviderName, AppCode } from '../components/views/appDescriptions';
import { appCodesGroupedByProviders, apps, isAppCode } from '../components/views/appDescriptions';

export type Role = 'MANAOS_PLATFORM' | `APP_${Uppercase<AppCode>}`;

export type Section = {
  title: string;
  text?: string;
  list?: string[];
  paragraphs?: string[];
};

export type ApplicationConfig = {
  appCode: AppCode;
  content: {
    title: string;
    shortTitle?: string;
    appDeliverable: `Portfolio analysis` | `Data only` | `Other`;
    useCases: UseCase[];
    appCoverageUniverse?: string[];
    licensingOptions?: ('BYOL' | 'SLOM')[];
    cardDescription?: string;
    sections?: Section[];
    screenshots?: string[];
    /** Full URL of a video */
    video?: string;
    /** in DownloadSample only */
    docFactsheet?: string | null;
    docAnalytics?: string | null;
    docMethodology?: string | null;
  };
  company: {
    providerName: ProviderName;
    /** Filename of a picture in `src/assets/images/application-catalog` directory. */
    logoUrl: string;
    name: string;
    shortName?: string;
    websiteUrl?: string;
  };
  config: {
    role: Role;
    coverageCalculationAvailable?: boolean;
    /** when undefined, user can not subscribe */
    subscriptionRequestType?: 'pay' | 'activate';
    /** when undefined, user can not display */
    analysisRequestType?: 'pay' | 'remaining' | 'enrich';
    /** when true, you should request coverage before analysis of portfolio */
    coverageRequired?: boolean;
    /** when undefined, should not display this widget in widget list */
    portfolioViewType?: 'widget' | 'pdf' | 'state' | 'tableWithKpis' | 'extractor';
    /** when true, you should poll request until all results are no more pending */
    activateAnalysisPolling?: boolean;
    /** when true, you should poll request until portfolio view is no more pending */
    activatePortfolioViewPolling?: boolean;
    /** when undefined, should not display "Settings" button in analysis table view */
    settingsProperties?: (keyof ProviderApi.Preference)[]; // already existing
    /** when undefined, you should not display button for complementary report generation & download */
    complementaryReportType?: 'underlyingData' | 'pdfReport';
    /** should be defined when `portfolioViewType` is `"widget"` */
    widgetConfig?: ConfigBuilderOperation[];
    link?: string;

    /** in AppLayout selecting provider only */
    maxPtfLine?: number;
    /** @deprecated */
    optionalColumns?: string[];
    applicationType?: string;
    displayOnlyUploadedPortfolios?: boolean;
  };
};

export const getApplicationConfig = (codeApplication: string): ApplicationConfig => {
  if (!(codeApplication in apps) || !isAppCode(codeApplication)) {
    throw new Error(`The application code ${codeApplication} does not exists`);
  }
  return apps[codeApplication];
};

export const getApplicationByRole = (role: string): ApplicationConfig | null =>
  objectValues(apps).find(({ config: { role: r } }) => r === role) ?? null;

export const useApplicationConfig = (codeApplication: AppCode): ApplicationConfig =>
  useMemo(() => getApplicationConfig(codeApplication), [codeApplication]);

export const getApplicationPipeTitleFromConfig = (appConfig: ApplicationConfig): string => {
  const {
    content: { shortTitle, title },
    company: { name, shortName },
  } = appConfig;
  return `${shortName ?? name} | ${shortTitle ?? title}`;
};

export const getApplicationPipeTitle = (codeApplication: string): string =>
  getApplicationPipeTitleFromConfig(getApplicationConfig(codeApplication));

export const useApplicationPipeTitle = (codeApplication: string): string =>
  useMemo(() => getApplicationPipeTitle(codeApplication), [codeApplication]);

export const getFirstApplicationConfigByProvider = (providerName: ProviderName): ApplicationConfig => {
  const list = appCodesGroupedByProviders[providerName];
  if (!Array.isArray(list) || !list.length) {
    throw new Error(`The provider name ${providerName} does not exists`);
  }
  return getApplicationConfig(list[0]);
};

export const useFirstApplicationConfigByProvider = (providerName: ProviderName): ApplicationConfig =>
  useMemo(() => getFirstApplicationConfigByProvider(providerName), [providerName]);
