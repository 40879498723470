import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import type { ReactPlayerProps } from 'react-player';
import MReactPlayer from 'react-player';
import JetpackBusinessman from '../../assets/images/jetpack-businessman.jpg';
import RopeBusinessman from '../../assets/images/rope-businessman.png';
import useStyles from './styles/errorPageStyles';

const ReactPlayer = MReactPlayer as unknown as React.FC<ReactPlayerProps>;

const ErrorPage: React.FC = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: `Error page`, // optional
    });
  }, [trackPageView]);

  return (
    <div className={classes.mainComponent}>
      <h1 className={classes.title}>404 - not found</h1>
      <p className={classes.textContent}>
        It is amazing to see all the things our users can do to watch our fantastic demo&hellip;
      </p>
      <ReactPlayer url="https://vimeo.com/461433239" className={classes.videoWrapper} />
      <div>
        <img src={JetpackBusinessman} alt="jetpack businessman" className={classes.imgJetpackBusinessman} />
        <img src={RopeBusinessman} alt="jetpack businessman" className={classes.imgRopeBusinessman} />
      </div>
    </div>
  );
};

export default ErrorPage;
