import { makeStyles } from 'mns-components';

const ValueSfdrRendererStyles = makeStyles({
  valueRenderer: {
    textAlign: 'right',
    paddingRight: '1.25rem',
  },
});

export default ValueSfdrRendererStyles;
