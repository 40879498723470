import { Stack } from '@mui/material';
import { PopUpApp, joinClass, useCallbackImmutable, useTestid } from 'mns-components';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useApplicationConfig } from '../../../common/getApplicationConfig';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useUser } from '../../../hooks/useAuth';
import { AppDescriptionButtonList } from '../components/AppDescriptionButtonList';
import { useFetchAppDetails, useFetchAppOffers } from '../hooks';
import {
  appDeliverableStyles as useAppDeliverableStyles,
  bannerBgStyles as useBannerBgStyles,
  cardModalStyles as useStyles,
  navButtonsStyles as useNavButtonsStyles,
} from '../styles/cardModalStyles';
import { CardAppDetail } from './CardModalTabs/CardAppDetail';
import { CardCoverage } from './CardModalTabs/CardCoverage';
import { CardPreview } from './CardModalTabs/CardPreview';

const CardModalTab: React.FC<{
  label: string;
  value: string;
  className: string;
  onClick(value: string): void;
  'data-testid': string;
}> = ({ label, value, className, onClick, 'data-testid': testid }) => (
  <div key={value} className={className} onClick={useCallbackImmutable(() => onClick(value))} data-testid={testid}>
    {label}
  </div>
);

type CardModalContentProps = {
  appCode: AppCode;
  'data-testid': string;
};

const CardModalContent: React.FC<CardModalContentProps> = ({ appCode, 'data-testid': testid }) => {
  const classes = useStyles();
  const appDelivClasses = useAppDeliverableStyles();
  const tabClasses = useNavButtonsStyles();
  const { isAdmin } = useUser();
  const createTestid = useTestid(testid);
  const appConfig = useApplicationConfig(appCode);
  const { content, company, config } = appConfig;

  //tabs
  const appDetailComp: ReactNode = <CardAppDetail appCode={appCode} data-testid={createTestid('appDetail')} />;
  const appPreviewComp: ReactNode = <CardPreview appCode={appCode} data-testid={createTestid('preview')} />;
  const appCoverageComp: ReactNode = (
    <CardCoverage appCode={appCode} data-testid={createTestid('coverageEstimationModal')} />
  );
  const tabs = [
    { key: 'appDetail', name: 'App detail', component: appDetailComp },
    { key: 'preview', name: 'Preview', component: appPreviewComp },
  ];
  if (config.coverageCalculationAvailable)
    tabs.push({ key: 'coverage', name: 'Coverage estimation', component: appCoverageComp });
  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  const appDeliverableIcon = useMemo(() => {
    return content.appDeliverable !== 'Other' ? appDelivClasses[content.appDeliverable] : null;
  }, [content.appDeliverable, appDelivClasses]);

  const bannerBgClasses = useBannerBgStyles() as Record<string, string>;
  const bannerBg = useMemo(() => bannerBgClasses[content.appDeliverable], [content.appDeliverable, bannerBgClasses]);

  /* START Button props */
  const { data: applicationDetails } = useFetchAppDetails(appCode);
  const { data: applicationOffers, isLoading: isLoadingOffer } = useFetchAppOffers(applicationDetails?.applicationId);

  const haveFreeTrial = useMemo(
    () => !!applicationOffers?.find((offer) => offer.freeTrialConsumed !== true && offer.eligibleFreeTrial),
    [applicationOffers],
  );

  const hasCurrentOffer = useMemo(
    () =>
      !!(
        applicationOffers?.find((offer) => offer.statusOfSubscription === 'ACTIVATED') ||
        (applicationDetails?.subscriptionsDto?.status === 'ACTIVATED' && applicationDetails.subscriptionsDto.offerDto)
      ),
    [applicationDetails, applicationOffers],
  );

  const pendingStatus = useMemo(
    () => applicationOffers?.find((offer) => offer.statusOfSubscription === 'PENDING'),
    [applicationOffers],
  );
  /* END Button props */

  if (!applicationDetails || !applicationOffers || !applicationDetails.applicationId || !appConfig) {
    return null;
  }

  return (
    <div className={classes.cardModal}>
      <Stack className={classes.scroll}>
        <div className={classes.header}>
          {/* Banner */}
          <div className={classes.banner}>
            <div className={classes.bannerText}>
              <div className={classes.bannerTitle}>{content.title}</div>
              <div className={classes.bannerSubText}>Powered by {company.name}</div>
              <div className={classes.bannerInfo}>
                {appDeliverableIcon && <div className={joinClass(classes.iconAppDeliverable, appDeliverableIcon)} />}
                <div className={classes.bannerInfoTxt} data-testid={createTestid('appDeliverable')}>
                  {content.appDeliverable}
                </div>
              </div>
            </div>
            <div className={joinClass(classes.iconProvider, bannerBg)} />
          </div>
        </div>
        {/* Tabs */}
        <div className={tabClasses.tabsContainer}>
          {tabs &&
            tabs.map((tab) => (
              <CardModalTab
                key={tab.key}
                label={tab.name}
                value={tab.key}
                className={joinClass(tabClasses.tab, currentTab == tab.key && tabClasses.activeTab)}
                onClick={setCurrentTab}
                data-testid={createTestid(tab.key)}
              />
            ))}
        </div>
        <div className={classes.compContainer}>{tabs.find((tab) => tab.key === currentTab)!.component}</div>
      </Stack>
      {/* Footer Buttons */}
      <div className={classes.buttons}>
        <AppDescriptionButtonList
          applicationDetails={applicationDetails}
          applicationOffers={applicationOffers}
          isAdmin={isAdmin}
          isPendingStatus={!!pendingStatus}
          haveCurrentOffer={hasCurrentOffer}
          haveFreeTrial={!!haveFreeTrial}
          isLoadingOffer={isLoadingOffer}
          providerName={company.providerName}
          data-testid={createTestid('buttons')}
        />
      </div>
    </div>
  );
};

export type CardModalProps = {
  appCode: AppCode;
  isOpen: boolean;
  onClose(): void;
  'data-testid': string;
};

export const CardModal: React.FC<CardModalProps> = ({ appCode, isOpen, onClose, 'data-testid': testid }) => (
  <PopUpApp open={isOpen} onClose={onClose}>
    {appCode && <CardModalContent appCode={appCode} data-testid={testid} />}
  </PopUpApp>
);
