import type { ApplicationCardStatus } from 'mns-components';
import {
  ApplicationCard,
  HorizontalScroll,
  convertToDateLocal,
  objectHas,
  useButtonPrefetch,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { useCases as globalUseCases } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { getApplicationConfig, useApplicationConfig } from '../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import type { AppCode } from '../../../components/views/appDescriptions';
import { appCodes } from '../../../components/views/appDescriptions';
import { CardModal } from '../cardModal/CardModal';
import { useApplicationsRequest, useManageMarketplaceAppModal, usePrefetchCardModal } from '../hooks';
import {
  marketplaceListStyles,
  appDeliverableLightStyles as useAppDeliverableLightStyles,
  appDeliverableStyles as useAppDeliverableStyles,
} from '../styles/marketplaceListStyles';

const isApplicationVisible = (appData: MarketplaceApi.Application) =>
  appCodes.includes(appData.codeApplication as AppCode) && appData.visible;

export const useApplicationsVisible = () => {
  const applicationsRequest = useApplicationsRequest();
  return useMemo(
    () =>
      applicationsRequest.data
        ?.filter(isApplicationVisible)
        .sort((a, b) =>
          getApplicationConfig(a.codeApplication).content.title.localeCompare(
            getApplicationConfig(b.codeApplication).content.title,
          ),
        ) ?? [],
    [applicationsRequest.data],
  );
};

type ExpandDirection = Parameters<typeof ApplicationCard.MarketplaceButton>[0]['expandDirection'];

const getExpandDirection = (count: number, index: number): ExpandDirection => {
  if (index === 0) return 'right';
  if (index >= count - 1) return 'left';
  return 'middle';
};

type MarketplaceItemProps = {
  appData: MarketplaceApi.Application;
  expandDirection: ExpandDirection;
  openModal?: (appCode: AppCode) => void;
  'data-testid': string;
};

const MarketplaceItemDescribe: React.FC<MarketplaceItemProps> = ({
  appData,
  expandDirection,
  openModal,
  'data-testid': testid,
}) => {
  const appCode = appData.codeApplication as AppCode;
  const appBackgroundClasses = useAppBackgroundStyles();
  const {
    content: { title, appDeliverable, cardDescription },
    company: { name },
  } = useApplicationConfig(appCode);
  const appBackgroundClassName = appBackgroundClasses[appCode];

  const status = useMemo((): ApplicationCardStatus => {
    if (appCode === 'manaos-coverageestimation') {
      return 'GIVEN';
    } else if (appData.hasAlreadySubscribed && !appData.subscribed && appData.subscriptionsDto?.status !== 'PENDING') {
      return 'EXPIRED';
    } else {
      return appData.subscriptionsDto?.status ?? null;
    }
  }, [appData.hasAlreadySubscribed, appData.subscribed, appData.subscriptionsDto?.status, appCode]);

  const statusDate = useMemo(
    () => convertToDateLocal(appData.subscriptionsDto?.startDate ?? ''),
    [appData.subscriptionsDto?.startDate],
  );

  const appDelivClasses = useAppDeliverableStyles();
  const appDeliverableBackgroundClassName = useMemo(
    () => (objectHas(appDelivClasses, appDeliverable) ? appDelivClasses[appDeliverable] : undefined),
    [appDelivClasses, appDeliverable],
  );

  const appDelivLightClasses = useAppDeliverableLightStyles();
  const backgroundClassName = useMemo(
    () => (objectHas(appDelivLightClasses, appDeliverable) ? appDelivLightClasses[appDeliverable] : undefined),
    [appDelivLightClasses, appDeliverable],
  );

  const handleOpenModal = useCallbackImmutable(() => {
    openModal?.(appCode);
  });

  const prefetchProps = useButtonPrefetch(usePrefetchCardModal(), 200, [appData.applicationId]);

  return (
    <ApplicationCard.MarketplaceButton
      title={title}
      appDeliverable={appDeliverable}
      companyName={name}
      cardDescription={cardDescription}
      expandDirection={expandDirection}
      applicationBackgroundClassname={appBackgroundClassName}
      appDeliverableBackgroundClassName={appDeliverableBackgroundClassName}
      appDeliverableShinyBackgroundClassName={backgroundClassName}
      subscriptionStatus={status}
      subscriptionStartDate={statusDate}
      onClick={handleOpenModal}
      data-testid={testid}
      {...prefetchProps}
    />
  );
};

type MarketPlacebyUseCasesProps = {
  useCase: string;
  applicationsByUseCase?: MarketplaceApi.Application[];
  openModal?: (appCode: AppCode) => void;
  'data-testid': string;
};

const MarketPlacebyUseCases: React.FC<MarketPlacebyUseCasesProps> = ({
  useCase,
  applicationsByUseCase,
  'data-testid': testid,
  openModal,
}) => {
  const createTestid = useTestid(testid);

  if (!applicationsByUseCase || applicationsByUseCase.length === 0) {
    return null;
  }

  return (
    <HorizontalScroll title={useCase} count={applicationsByUseCase.length} data-testid={testid}>
      {applicationsByUseCase.map((appData, index) => (
        <MarketplaceItemDescribe
          appData={appData}
          data-testid={createTestid(appData.codeApplication)}
          openModal={openModal}
          expandDirection={getExpandDirection(applicationsByUseCase.length, index)}
          key={appData.codeApplication}
        />
      ))}
    </HorizontalScroll>
  );
};

type MarketplaceDescribeListProps = {
  applications: MarketplaceApi.Application[];
  isDescribing?: boolean;
  'data-testid': string;
};

export const MarketplaceDescribeList: React.FC<MarketplaceDescribeListProps> = ({
  applications,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = marketplaceListStyles();

  const appsByUseCases = useMemo(
    () =>
      new Map(
        globalUseCases.map((useCase) => [
          useCase,
          applications.filter((app) => getApplicationConfig(app.codeApplication).content.useCases.includes(useCase)),
        ]),
      ),
    [applications],
  );

  const [appCode, openModal, closeModal] = useManageMarketplaceAppModal();

  if (applications.length === 0) {
    return null;
  }

  return (
    <div data-testid={testid} className={classes.mainContainer}>
      {Array.from(appsByUseCases).map(([useCase, apps]) => (
        <MarketPlacebyUseCases
          useCase={useCase}
          applicationsByUseCase={apps}
          data-testid={createTestid(useCase)}
          openModal={openModal}
          key={useCase}
        />
      ))}
      {appCode && <CardModal appCode={appCode} isOpen onClose={closeModal} data-testid={testid} />}
    </div>
  );
};
