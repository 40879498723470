import type { JsonSchemaObject } from 'mns-components';
import { useTestid, objectEntries, Form, FormCreator } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useFilesRequest } from '../../../../components/views/hooks';
import type { MyDocumentsFilter } from './MyDocumentsListRequest';

const useFiltersJsonSchema = (): JsonSchemaObject => ({
  properties: {
    search: { type: 'string', variant: 'search', title: 'Search a file name' },
  },
});

type FilterStatusCount = Record<'OK' | 'REJECTED', number>;
type MapStatus = Record<'OK' | 'REJECTED', string>;

const mapStatus: MapStatus = {
  OK: 'Accepted',
  REJECTED: 'Rejected',
};

type MyDocumentsControllerProps = {
  viewFileTypes: CollectApi.File['dataFormat'][];
  setFilters: React.Dispatch<React.SetStateAction<MyDocumentsFilter>>;
  'data-testid': string;
};

export const MyDocumentsController: React.FC<MyDocumentsControllerProps> = ({
  viewFileTypes,
  setFilters,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { data: filesData } = useFilesRequest({ dataFormats: viewFileTypes });
  const dict = useMemo(
    (): FilterStatusCount =>
      filesData?.reduce(
        (acc, item) => {
          switch (item.status) {
            case 'OK':
            case 'WARNING':
              acc.OK++;
              break;
            case 'REJECTED':
              acc.REJECTED++;
          }
          return acc;
        },
        { OK: 0, REJECTED: 0 } as FilterStatusCount,
      ) ?? { OK: 0, REJECTED: 0 },
    [filesData],
  );
  const mapStatusFilters = useMemo(
    () =>
      objectEntries(dict).reduce((acc, [key, value]) => {
        acc[key] = `${mapStatus[key]} (${value})`;
        return acc;
      }, {} as MapStatus),
    [dict],
  );
  const filtersJsonSchema = useFiltersJsonSchema();
  const [rawFilters, setRawFilters] = useState<MyDocumentsFilter>({ search: '' });

  useEffect(
    () =>
      setFilters(() => {
        return { search: rawFilters.search };
      }),
    [mapStatusFilters, rawFilters, setFilters],
  );

  return (
    <Form data-testid={createTestid('form')}>
      <FormCreator
        jsonSchema={filtersJsonSchema}
        state={rawFilters}
        setState={setRawFilters}
        data-testid={createTestid('filters')}
      />
    </Form>
  );
};
