import { Stack } from '@mui/material';
import { Button, Icon, useTestid, Typography } from 'mns-components';

type Label = {
  count: number;
  text: string;
};

type CreateTemplateButtonsProps = {
  labels?: Label[];
  nextDisabled?: boolean;
  nextLoading?: boolean;
  submitLabel?: string;
  onPrevious?(): void;
  onNext?(): void;
  onSubmit?(): void;
  'data-testid': string;
};

export const CreateTemplateButtons: React.FC<CreateTemplateButtonsProps> = ({
  labels = [],
  nextDisabled = false,
  nextLoading = false,
  submitLabel = 'Submit',
  onPrevious,
  onNext,
  onSubmit,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Stack direction="row" spacing="1rem" justifyContent="flex-start" alignItems="center">
        {labels.map(({ count, text }) => (
          <Stack key={text} direction="row" spacing=".5rem" alignItems="center">
            <Typography fontWeight="500">{count}</Typography>
            <Typography variant="body2">{text}</Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" spacing="1rem" justifyContent="flex-end" alignItems="center">
        {onPrevious && (
          <Button
            onClick={onPrevious}
            color="primary"
            outlined
            startIcon={<Icon name="before" data-testid={createTestid('icon-previous')} />}
            data-testid={createTestid('button-previous')}
          >
            Back
          </Button>
        )}
        {onNext && (
          <Button
            onClick={onNext}
            color="primary"
            endIcon={<Icon name="next" data-testid={createTestid('icon-next')} />}
            disabled={nextDisabled}
            loading={nextLoading}
            data-testid={createTestid('button-next')}
          >
            Continue
          </Button>
        )}
        {onSubmit && (
          <Button
            onClick={onSubmit}
            color="secondary"
            endIcon={<Icon name="next" data-testid={createTestid('icon-submit')} />}
            disabled={nextDisabled}
            loading={nextLoading}
            data-testid={createTestid('button-submit')}
          >
            {submitLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
