import { Stack, Tooltip } from '@mui/material';
import type { OptionType } from 'mns-components';
import { FunnelSection, Icon, Select, TextField, Typo, colors, useTestid } from 'mns-components';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOrganisation } from '../../../../hooks/useGlobalState';
import { extractionFunnelStyles as useStyles } from './extractionFunnelStyles';

type ExtractionType = 'MANUALLY' | 'AUTOMATIC';

const extractionTypeOptions: OptionType<ExtractionType>[] = [
  {
    label: 'Automated extraction',
    value: 'AUTOMATIC',
  },
  {
    label: 'One-off extraction',
    value: 'MANUALLY',
  },
];

type ExtractionFeed = 'API' | 'SFTP';

const extractionFeedWithSftpOptions: OptionType<ExtractionFeed>[] = [
  {
    label: 'API/File download',
    value: 'API',
  },
  {
    label: 'API/File download and SFTP',
    value: 'SFTP',
  },
];

const extractionFeedWithoutSftpOptions: OptionType<ExtractionFeed>[] = [
  {
    label: 'API/File download',
    value: 'API',
  },
];

export type SetupFormData = {
  extractionName: string;
  extractionType?: ExtractionType;
  extractionFeed?: ExtractionFeed;
};

type SetupSectionProps = {
  disabled?: boolean;
  onChange(formData: SetupFormData): void;
  'data-testid': string;
};

export const SetupSection = React.memo<SetupSectionProps>(function SetupSection({
  disabled,
  onChange,
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const methods = useForm<SetupFormData>();
  const accordionName = 'setup';
  const [expanded, setExpanded] = useState<string | null>(accordionName);

  const { data: organisationDetails } = useOrganisation();
  const extractionFeedOptions = useMemo(
    () => (organisationDetails?.sftpConfigured ? extractionFeedWithSftpOptions : extractionFeedWithoutSftpOptions),
    [organisationDetails?.sftpConfigured],
  );

  const { extractionName, extractionType, extractionFeed } = methods.watch();

  useEffect(() => {
    onChange({ extractionName, extractionType, extractionFeed });
  }, [onChange, extractionName, extractionType, extractionFeed]);

  return (
    <FunnelSection
      name={accordionName}
      expansible
      title="Set up extraction"
      value={expanded}
      onExpandValue={setExpanded}
      disabled={disabled}
      data-testid={createTestid('section')}
    >
      <FormProvider {...methods}>
        <Stack gap="1rem">
          <Typo variant="body2" color={disabled ? colors.disabledFontColor : undefined}>
            Initialize new extraction by defining these parameters.
          </Typo>
          <Stack width="17rem">
            <TextField
              label="Extraction name"
              name="extractionName"
              disabled={disabled}
              disableMargin
              data-testid={createTestid('extractionName-input')}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap=".5rem">
            <Select
              variant="labeled"
              label="Type of extraction"
              disabled={disabled}
              disableMargin
              options={extractionTypeOptions}
              name="extractionType"
              FormControlProps={{ className: classes.setupInput }}
              data-testid={createTestid('extractionType-input')}
            />
            <Tooltip
              placement="right"
              title={
                <Stack>
                  <Typo variant="caption" component="p">
                    <Typo variant="captionmedium" component="span">
                      Automated extraction
                    </Typo>
                    : the extraction will be automatically regenerated when one of the portfolios is re-uploaded with a
                    new NAV date.
                  </Typo>
                  <Typo variant="caption" component="p">
                    <Typo variant="captionmedium" component="span">
                      One-off extraction
                    </Typo>
                    : the extraction will be created only once.
                  </Typo>
                </Stack>
              }
            >
              <Icon.Info
                color={disabled ? 'disabled' : 'secondary'}
                data-testid={createTestid('extractionType-icon-tooltip')}
              />
            </Tooltip>
          </Stack>
          {extractionType === 'AUTOMATIC' && (
            <Stack direction="row" alignItems="center" gap=".5rem">
              <Select
                variant="labeled"
                label="Extraction feed"
                disabled={disabled}
                disableMargin
                options={extractionFeedOptions}
                name="extractionFeed"
                FormControlProps={{ className: classes.setupInput }}
                data-testid={createTestid('extractionFeed-input')}
              />
              <Tooltip
                placement="right"
                title={
                  <Stack>
                    <Typo variant="caption" component="p">
                      <Typo variant="captionmedium" component="span">
                        API/File download
                      </Typo>
                      : Download the extraction on the platform.
                    </Typo>
                    <Typo variant="caption" component="p">
                      <Typo variant="captionmedium" component="span">
                        API/File download and SFTP
                      </Typo>
                      : Channel use to transfer the extraction on your system.
                    </Typo>
                  </Stack>
                }
              >
                <Icon.Info
                  color={disabled ? 'disabled' : 'secondary'}
                  data-testid={createTestid('extractionFeed-icon-tooltip')}
                />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </FormProvider>
    </FunnelSection>
  );
});
