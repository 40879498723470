import { Dialog, useTestid } from 'mns-components';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateIsinListRequest } from '../hooks';
import { CreateIsinListForm } from './IsinListInputs';
import { modalStyles as useStyles } from './modalStyles';

type CreateIsinListModalProps = {
  open: boolean;
  onClose(): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

export const CreateIsinListModal: React.FC<CreateIsinListModalProps> = ({
  open,
  onClose,
  variant,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const { mutate: createList, isSuccess: isListCreated, isError } = useCreateIsinListRequest();

  useEffect(() => {
    if (isListCreated) {
      onClose();
      toast.success(`List successfuly created`);
    }
    if (isError) {
      onClose();
      toast.error(`Failed to create list`);
    }
  }, [isListCreated, isError, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      dialogTitle={variant === 'ISIN' ? 'Create portfolio list' : 'Create portfolio scope'}
      data-testid={createTestid('create-dialog')}
    >
      <CreateIsinListForm
        isinList={undefined}
        submitLabel="Create"
        onClose={onClose}
        onSave={createList}
        className={classes.fullHeight}
        variant={variant}
        data-testid={testid}
      />
    </Dialog>
  );
};
