import { Chip, Tooltip } from '@mui/material';
import { joinClass, LoadingCircle, floatToPercent, useTestid, Icon, colors } from 'mns-components';
import React from 'react';
import useStyles from './styles/coverageRendererStyles';

function getCoverageBackgroundColor(isClarityDetails: boolean) {
  return isClarityDetails ? colors.borderLight : colors.primaryColor;
}

function getCoverageTextColor(isClarityDetails: boolean) {
  return isClarityDetails ? colors.defaultFontColor : colors.whiteColor;
}

interface Props {
  /** percent, between 0 and 1 */
  coverage?: number | null;
  status?: string;
  error?: string | null;
  isClarityDetails?: boolean;
  'data-testid': string;
}

const CoverageRenderer: React.FC<Props> = ({
  coverage,
  status,
  error,
  isClarityDetails = false,
  'data-testid': testid,
}: Props) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  if (coverage === undefined) {
    return null;
  }

  if (status === 'failed' || (coverage === null && status === 'complete')) {
    return (
      <Tooltip
        title={error || 'Failed to calculate coverage. Please relaunch calculation or contact support team'}
        arrow
        placement="right-start"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <Icon.Info color="error" size="medium" data-testid={createTestid('icon-info')} />
      </Tooltip>
    );
  }

  if (coverage === null) {
    return (
      <div className={joinClass(classes.cellCenter, classes.info)}>
        <LoadingCircle data-testid={createTestid('loading')} />
      </div>
    );
  }

  return (
    <Chip
      variant="filled"
      size="small"
      label={floatToPercent(coverage)}
      style={{
        color: getCoverageTextColor(isClarityDetails),
        background: getCoverageBackgroundColor(isClarityDetails),
        fontSize: '12px',
      }}
      data-testid={createTestid('chip')}
    />
  );
};

export default React.memo(CoverageRenderer);
