import type { ColDef, GridApi, RowGroupOpenedEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  useDefaultColDef,
  floatToPercent,
  useAutoSize,
  useImmutable,
  isNumberFinite,
} from 'mns-components';
import type { LookthroughApi } from 'mns-sdk-collect';
import React from 'react';

const autoGroupColumnDef: ColDef = {
  field: 'Asset_ID',
  headerName: 'Portfolio',
  minWidth: 250,
  maxWidth: 350,
};

const columns: AggridTableColumn<LookthroughApi.PortfolioTransparisation>[] = [
  {
    field: 'Asset_Name',
    headerName: 'Asset Name',
    tooltipField: 'Asset_Name',
    minWidth: 200,
  },
  {
    field: 'Asset_class',
    headerName: 'Asset class',
    tooltipField: 'Asset_class',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    field: 'Currency',
    headerName: 'Currency',
    minWidth: 110,
    maxWidth: 130,
  },
  {
    field: 'Country',
    headerName: 'Country',
    tooltipField: 'Country',
    minWidth: 100,
    maxWidth: 150,
  },
  {
    field: 'Weight',
    headerName: 'Weight',
    minWidth: 100,
    maxWidth: 120,
    sortable: true,
    valueGetter: ({ data: { Weight: value } }) => (isNumberFinite(value) ? floatToPercent(value) : ''),
  },
  {
    field: 'Quantity',
    headerName: 'Amount',
    minWidth: 100,
    maxWidth: 180,
    valueGetter: ({ data: { Quantity: value } }) =>
      isNumberFinite(value)
        ? Math.trunc(value)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        : '',
  },
];

type CompositionListProps = {
  rowData: LookthroughApi.PortfolioTransparisation[];
  searchValue: string;
  getGridApiRef?(api: GridApi | null): void;
  onRowGroupOpened(api: GridApi): void;
  'data-testid': string;
};

export const CompositionList: React.FC<CompositionListProps> = ({
  rowData,
  searchValue,
  getGridApiRef,
  onRowGroupOpened,
  'data-testid': testid,
}: CompositionListProps) => (
  <AggridTable
    quickFilterText={searchValue}
    columnDefs={columns}
    rowData={rowData}
    autoGroupColumnDef={autoGroupColumnDef}
    treeData
    getGridApiRef={getGridApiRef}
    getDataPath={useImmutable(
      () =>
        ({ full_path: fullPath }: LookthroughApi.PortfolioTransparisation) =>
          fullPath ?? [],
    )}
    getRowNodeId={useImmutable(
      () =>
        ({ full_path: fullPath }: LookthroughApi.PortfolioTransparisation) =>
          fullPath.join(),
    )}
    onRowGroupOpened={useImmutable(
      () =>
        ({ api }: RowGroupOpenedEvent) =>
          onRowGroupOpened(api),
    )}
    defaultColDef={useDefaultColDef<LookthroughApi.PortfolioTransparisation>()}
    onGridReady={useAutoSize('fit')}
    data-testid={testid}
  />
);
