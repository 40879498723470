import type { DataExtractorApi, LookthroughApi } from 'mns-sdk-collect';
import type { QueryObserverOptions, UseQueryOptions } from 'react-query';
import { api } from '../../store/api';

export const useFetchComposition = (params: LookthroughApi.PortfolioTransparisationRequest) => {
  return api.looktrough.getPortoliosTransparisation.useQuery(params);
};

export const useFetchLookthroughRate = (params: LookthroughApi.PortfolioTransparisationRequest) => {
  return api.looktrough.getLooktroughtRateKpi.useQuery(params, { staleTime: 0 });
};

export const useFetchNav = (portfolioId: string, date: string) =>
  api.inventories.collect.transparisedKpi.useQuery(portfolioId, date);

export const useFetchTemplates = (
  options: Omit<UseQueryOptions<DataExtractorApi.Template.TemplateLight[]>, 'queryKey' | 'queryFn'> = {},
) => api.dataExtractor.template.getTemplates.useQuery(options);

export const useReports = <TData = DataExtractorApi.Report.ReportLight[]>(
  options: QueryObserverOptions<DataExtractorApi.Report.ReportLight[], unknown, TData> = {},
) => api.dataExtractor.report.getReports.useQuery(options);
