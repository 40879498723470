import { StatusTag } from 'mns-components';
import React from 'react';

interface DisseminationStateRendererProps {
  disseminationState?: string;
  publicationState?: string;
  'data-testid': string;
}

export const DisseminationStateRenderer = React.memo<DisseminationStateRendererProps>(
  function DisseminationStateRendererComp({ disseminationState, publicationState, 'data-testid': testid }) {
    if (publicationState === 'published on time' || disseminationState === 'disseminated') {
      return <StatusTag variant="success" label="Disseminated" data-testid={testid} />;
    }

    return <StatusTag variant="warning" label="Not disseminated" data-testid={testid} />;
  },
);

export const disseminationStateRendererPlain = ({
  disseminationState,
  publicationState,
}: Pick<DisseminationStateRendererProps, 'disseminationState' | 'publicationState'>) => {
  if (publicationState === 'published on time' || disseminationState === 'disseminated') {
    return 'Disseminated';
  }

  return 'Not disseminated';
};
