import type { GridApi, RowSelectedEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  TextField,
  useDefaultColDef,
  useAutoSize,
  useCallbackImmutable,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QualitiesRenderer } from '../../cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../headerRender/QualitiesHeaderRenderer';
import { createEmailDeliveryModalStyles as useStyles } from './createEmailDeliveryModalStyles';

const useColumns = (colSelection: AggridTableColumn<CollectApi.Portfolio>, testid: string) =>
  useMemo(
    (): AggridTableColumn<CollectApi.Portfolio>[] => [
      colSelection,
      {
        field: 'externalId.value',
        headerName: 'ID',
      },
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'qualities',
        headerName: 'Qualities',
        cellRendererFramework: ({
          data: {
            latestVersion: { qualities },
            externalId,
          },
        }) => <QualitiesRenderer qualities={qualities} data-testid={`${testid}-${externalId.value}-qualities`} />,
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
        minWidth: 100,
      },
    ],
    [colSelection, testid],
  );

type SelectScopeStepProps = {
  rows: CollectApi.Portfolio[];
  onRowsSelected(rows: CollectApi.Portfolio[]): void;
  getGridApiRef?: React.MutableRefObject<GridApi | null> | ((api: GridApi | null) => void);
  'data-testid': string;
};

export const SelectScopeList: React.FC<SelectScopeStepProps> = ({
  rows,
  onRowsSelected,
  getGridApiRef,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [searchValue, setSearchValue] = useState('');
  const methods = useForm({ defaultValues: { search: '' } });

  const handleSearchChange = useImmutable(() => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  });

  const onRowsSelectedFct = useCallbackImmutable((ev: RowSelectedEvent) =>
    onRowsSelected(
      (ev.api.getSelectedRows() as CollectApi.Portfolio[]).reduce((acc, { externalId: { value: externalIdName } }) => {
        const found = rows.find((row) => row.externalId.value === externalIdName);
        if (found) acc.push(found);
        return acc;
      }, [] as CollectApi.Portfolio[]),
    ),
  );

  const [aggridProps, colSelection] = useRowSelection<CollectApi.Portfolio>(onRowsSelectedFct, true);

  return (
    <FormProvider {...methods}>
      <TextField
        name="search"
        label="Search"
        type="text"
        onChange={handleSearchChange}
        search={true}
        className={classes.searchInput}
        data-testid={createTestid('searchInput')}
        uncontrolled
      />
      <AggridTable
        {...aggridProps}
        rowData={rows}
        columnDefs={useColumns(colSelection, testid)}
        getRowNodeId={useImmutable(() => (row: CollectApi.Portfolio) => row.externalId.value)}
        getGridApiRef={getGridApiRef}
        data-testid={testid}
        quickFilterText={searchValue}
        defaultColDef={useDefaultColDef<CollectApi.Portfolio>()}
        onGridReady={useAutoSize('fit')}
        pagination
        paginationAutoPageSize
      />
    </FormProvider>
  );
};
