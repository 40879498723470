import { useTestid } from 'mns-components';
import { AccessRequestSettings } from './AccessRequestSettings';
import { CollectGapSettings } from './CollectGapSettings';

export const CollectApplicationSettings: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  return (
    <>
      <AccessRequestSettings data-testid={createTestid('access-request')} />
      <CollectGapSettings data-testid={createTestid('collect-gap')} />
    </>
  );
};
