import { StatusTag } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React from 'react';

interface ReportStatusProps {
  status: DataExtractorApi.Report.ReportLight['status'];
  'data-testid': string;
}

export const ReportStatus: React.FC<ReportStatusProps> = ({ status, 'data-testid': testid }) => {
  switch (status) {
    case 'COMPLETE':
      return <StatusTag variant="success" label="Completed" data-testid={testid} />;

    case 'IN_PROGRESS':
    case 'REQUESTED':
      return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

    case 'FAILED':
      return <StatusTag variant="error" label="Failed" data-testid={testid} />;

    default:
      return null;
  }
};
