import { Box, Paper, Stack, Tooltip } from '@mui/material';
import {
  BarChart,
  ChartCard,
  EmptyState,
  GaugeChart,
  Icon,
  Typo,
  colors,
  floatToPercent,
  generateUuid,
  groupBy,
  useCallbackImmutable,
  useTestid,
  EmptyStateV2,
} from 'mns-components';
import type { AnalysisApi, CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGreatAnalysisRequest, useGreatAnalysisStatus, useWidget } from '../../hooks';
import { tableKpisAppStyles as useStyles } from './styles/tableKpisAppStyles';
import { GreatList } from './tableComponents/greatList';
import { getColorByValue, greatScoreRules } from './tableComponents/GreatScoringRenderer';

const notesKeyMap = {
  greatNote: ['Note Great'],
  mainNote: [
    'Responsible Governance',
    'Sustainable Management Of Resources',
    'Energy And Economic Transition',
    'Local Territories',
  ],
  responsibleGovernance: ['Powers equitable', 'Equitable remuneration', 'Business ethics'],
  sustainableManagementOfResources: [
    'Pollution/Waste',
    "Employees' fundamental rights",
    'Human Resources',
    'Biodiversity/Water',
  ],
  energyAndEconomicTransition: ['Climate Risks', 'Solutions to Energy Transition'],
  localTerritories: [
    'Responsible Practices with Communities',
    'Responsible Practices with Customers',
    'Responsible Practices with Suppliers',
    "Management of products/services' social impacts",
  ],
};

const chartCardContent = [
  {
    key: 'responsibleGovernance',
    title: 'Responsible Governance',
    tooltipContent: ['- Balance of power', '- Fair remuneration', '- Business ethics'],
    mainColor: colors.pinkColor,
  },
  {
    key: 'sustainableManagementOfResources',
    title: 'Sustainable Management Of Resources',
    tooltipContent: ['- Human resources', '- Human rights', '- Biodiversity and water Pollution and waste'],
    mainColor: colors.purpleColor,
  },
  {
    key: 'energyAndEconomicTransition',
    title: 'Energy And Economic Transition',
    tooltipContent: [
      '- Contribution to the energy transition',
      '- Offer of products and services related to the energy transition',
      '- Greenhouse gas emissions',
      '- Contribution to the economic transition',
    ],
    mainColor: colors.primaryColor,
  },
  {
    key: 'localTerritories',
    title: 'Local Territories',
    tooltipContent: [
      '- Responsible practices with communities',
      '- Responsible practices with suppliers',
      '- Responsible practices with customers',
      '- Offer of products and services contributing to the social Sustainable Development Goals (SDGs)',
    ],
    mainColor: colors.blueColor,
  },
] as Array<{ key: keyof typeof notesKeyMap; title: string; tooltipContent: Array<string>; mainColor: string }>;

const validPercent = (num: unknown) => (typeof num === 'number' ? floatToPercent(num) : 'Not calculated yet');

type CustomItemTooltipContentProps = { label?: string; value: number; color?: string };

const CustomItemTooltipContent = ({ label, value, color }: CustomItemTooltipContentProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.chartTooltipContent}>
      <Stack className={classes.tooltipLabel}>
        <Typo variant="body2" color={colors.greyFontColor}>
          {label}
        </Typo>
      </Stack>
      <Stack className={classes.tooltipValue}>
        <div className={classes.colorBox} style={{ backgroundColor: color }} />
        <Typo variant="body2" color={colors.greyFontColor}>
          {parseFloat(value.toFixed(2))}
        </Typo>
      </Stack>
    </Paper>
  );
};

export type TableKpisAppProps = {
  portfolio: CollectApi.Portfolio;
  createAnalysisRetry?: number;
  'data-testid': string;
};

export const TableKpisApp: React.FC<TableKpisAppProps> = ({
  portfolio,
  createAnalysisRetry = 10,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const { date } = useParams<{ date: string }>();
  const createTestid = useTestid(testid);
  const retryCounter = useRef(createAnalysisRetry);

  const portfolioRequestBody = useMemo(
    (): AnalysisApi.GreatAnalysisRequest => ({
      externalId: portfolio.externalId.value,
      externalIdType: portfolio.externalId.type,
      valuationDate: date,
      portfolioOriginType: portfolio.origin,
    }),
    [portfolio, date],
  );

  const { data: lastGreatAnalysis, isError: isNotFound } = useGreatAnalysisStatus(portfolioRequestBody, {
    refetchIntervalInBackground: true,
    refetchInterval: (an) => (an?.status === 'pending' ? 5000 : false),
    retry: false,
  });

  const { data: dataPresigned } = useWidget(lastGreatAnalysis?.widgetUrl);

  const { mutate: createGreatAnalysisRequest } = useGreatAnalysisRequest();

  // if there is no great analysis, create a new analysis
  useEffect(() => {
    if (isNotFound) {
      retryCounter.current -= 1;
      createGreatAnalysisRequest(portfolioRequestBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGreatAnalysisRequest, isNotFound]);

  // if last great analysis request is failed, create a new analysis
  useEffect(() => {
    if (lastGreatAnalysis?.status === 'failed' && retryCounter.current > 0) {
      retryCounter.current -= 1;
      createGreatAnalysisRequest(portfolioRequestBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGreatAnalysisRequest, lastGreatAnalysis]);

  const groupedNotes = useMemo(
    () => groupBy(dataPresigned?.aggregationData?.scoringDetails ?? [], 'header_name'),
    [dataPresigned?.aggregationData?.scoringDetails],
  );

  const notesMap = useMemo(() => {
    return Object.entries(notesKeyMap).reduce(
      (acc, [mapKey, value]) => ({
        ...acc,
        [mapKey]: value.map((key: string) => groupedNotes[key]?.at(0) ?? { header_name: key }),
      }),
      {} as Record<keyof typeof notesKeyMap, Partial<AnalysisApi.ScoringDetails>[]>,
    );
  }, [groupedNotes]);

  const isSpecificNotes = useMemo(
    () =>
      notesMap && chartCardContent.some(({ key }) => notesMap[key].some(({ aggregation_value }) => aggregation_value)),
    [notesMap],
  );

  const hasNoScoringDetails = useMemo(
    () => !dataPresigned?.aggregationData?.scoringDetails.some(({ aggregation_value }) => aggregation_value),
    [dataPresigned?.aggregationData?.scoringDetails],
  );

  const getNote = useCallbackImmutable(
    (arr: AnyArray, key: string) =>
      arr.find(({ header_name }) => header_name === key)?.aggregation_value?.toFixed(1) || 0,
  );

  const chartCardContentReversed = useMemo(() => chartCardContent.slice().reverse(), []);

  const setBarChartTooltip = useCallbackImmutable((props: CustomItemTooltipContentProps) => (
    <CustomItemTooltipContent {...props} />
  ));

  // it can be `false` when navigating to another portfolio: the last analysis is still matching the previous
  // portfolio, not the portfolio we are trying reach while navigating.
  const isAnalysisMatchingCurrentView =
    lastGreatAnalysis?.externalId === portfolio.externalId.value &&
    lastGreatAnalysis.externalIdType === portfolio.externalId.type;

  // displays
  // warn & error

  if (
    lastGreatAnalysis?.errorCode !== 'PORTFOLIO_EMPTY' &&
    (lastGreatAnalysis?.status !== 'complete' || !isAnalysisMatchingCurrentView || hasNoScoringDetails)
  ) {
    if (retryCounter.current <= 0) {
      return (
        <Stack margin="auto">
          <EmptyStateV2
            useCase="dataErrors"
            variant="error"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-msg')}
          />
        </Stack>
      );
    } else {
      return (
        <EmptyState
          iconEnable
          iconName="processing"
          title="Your portfolio is currently being analysed. Please wait a few seconds to get your portfolio scores."
          data-testid={createTestid('error-pending-analysis')}
        />
      );
    }
  }

  if (lastGreatAnalysis?.errorCode === 'PORTFOLIO_EMPTY' || hasNoScoringDetails) {
    const subtitle =
      lastGreatAnalysis?.errorCode === 'PORTFOLIO_EMPTY'
        ? 'The instruments in your portfolio are not eligible for analysis. Please check your portfolio composition or contact support team.'
        : 'The instruments in your portfolio could not be matched. Please check your portfolio composition or contact support team.';

    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle={subtitle}
          data-testid={createTestid('error-msg')}
        />
      </Stack>
    );
  }

  // success
  return (
    <div data-testid={testid}>
      <Stack className={classes.appTitleWrapper}>
        <Typo variant="h2" color={colors.blueColor}>
          GREaT
        </Typo>
        <Tooltip
          title="Our SRI philosophy focuses on four criteria: responsible Governance, sustainable management of natural and human Resources, the Energy and economic transition, and development of Local and Regional Territories. Our constantly-expanded GREaT matrix covers all asset classes and is used to analyse 9,000 issuers automatically and consistently. Thanks to GREaT, we can dissect the practices of all our investment targets from an ESG perspective, and in their regions of establishment, which is a strong marker of La Banque Postale’s corporate citizenship DNA."
          arrow
          placement="right-start"
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <Icon.Info
            size="medium"
            color="secondary"
            className={classes.infoIcon}
            data-testid={createTestid('icon-great-info')}
          />
        </Tooltip>
      </Stack>
      <Typo variant="body2" color={colors.darkGreyColor}>
        A unique and demanding approach, allows to promote responsible governance, strongly promote sustainable
        management of human and natural resources, make the economic and energy transition a factor of sustainable
        growth and favour companies actively participating in local and regional development.
      </Typo>
      <Stack className={classes.scoreContainer}>
        <Stack className={classes.coverageWrapper}>
          <Stack className={classes.coverageContainer}>
            <Stack className={classes.coverageTitleWrapper}>
              <Typo variant="body1" color={colors.greyFontColor}>
                Coverage
              </Typo>
              <Tooltip
                title="Portfolio coverage indicates the % of instruments in the portfolio covered by our GREaT Scoring engine."
                arrow
                placement="right-start"
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <Icon.Info
                  size="medium"
                  color="secondary"
                  className={classes.infoIcon}
                  data-testid={createTestid('icon-coverage-info')}
                />
              </Tooltip>
            </Stack>
            <Typo component="span" variant="h3">
              {validPercent(dataPresigned?.coverage ?? 0)}
            </Typo>
          </Stack>
          {!!notesMap?.greatNote.length && (
            <Stack className={classes.coverageScoring}>
              <Stack gap=".5rem">
                <Typo variant="body1medium">Great scoring</Typo>
                <Typo variant="body2">
                  {portfolio.externalId.value} - {portfolio.name}
                </Typo>
              </Stack>
              <p
                className={classes.greatScore}
                style={{
                  color: getColorByValue(notesMap?.greatNote?.[0].aggregation_value?.toFixed(1), greatScoreRules, {
                    color: colors.darkGreyColor,
                    limit: 0,
                  }),
                }}
              >
                {notesMap?.greatNote?.[0].aggregation_value?.toFixed(1)}
              </p>
            </Stack>
          )}
        </Stack>
        <Stack className={classes.chartsWrapper}>
          <Stack flexGrow={1}>
            <Box className={classes.scoringPillarsLabelBox}>
              <Typo variant="body1medium">Scoring pillars</Typo>
            </Box>
            <GaugeChart
              ticks
              width="100%"
              height="250px"
              viewBox="0 -100 450 300"
              data={chartCardContentReversed.map(({ title, mainColor: color }) => ({
                value: getNote(notesMap.mainNote, title),
                color,
              }))}
            />
          </Stack>
          <Stack className={classes.legendContainer}>
            {chartCardContent.map(({ key, title, mainColor: backgroundColor }) => (
              <Stack key={key} className={classes.legendWrapper}>
                <div className={classes.colorBox} style={{ backgroundColor }} />
                <Typo.Ellipsis variant="body2" key={key} title={title}>
                  {title}
                </Typo.Ellipsis>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      {isSpecificNotes && (
        <Stack className={classes.chartCardContainer}>
          {chartCardContent.map(({ key, title, tooltipContent, mainColor }) => (
            <ChartCard
              key={key}
              title={title}
              subtitle={getNote(notesMap.mainNote, title)}
              mainColor={mainColor}
              tooltipTitle={tooltipContent.map((value) => (
                <Typo variant="body2" key={generateUuid()}>
                  {value}
                </Typo>
              ))}
              data-testid={createTestid(`chartCard-${key}`)}
            >
              <Typo variant="captionmedium" color={colors.blueColor}>
                Scoring criteria
              </Typo>
              <BarChart
                height={300}
                color={mainColor}
                layout="horizontal"
                margin={{ left: 70 }}
                grid={{ vertical: true }}
                data-testid={createTestid('chartCard-barChart')}
                xAxis={[{ min: 1, max: 10, tickNumber: 10 }]}
                yAxis={[
                  {
                    scaleType: 'band',
                    data: notesMap[key].map(({ header_name }) => header_name),
                    valueFormatter: (value) => `${value?.slice(0, 7)}...`,
                  },
                ]}
                series={[{ data: notesMap[key].map(({ aggregation_value }) => aggregation_value ?? 0) }]}
                tooltip={{
                  trigger: 'item',
                  itemContent: ({ itemData: { dataIndex } }) =>
                    setBarChartTooltip({
                      label: notesMap[key][dataIndex].header_name,
                      value: notesMap[key][dataIndex].aggregation_value ?? 0,
                      color: mainColor,
                    }),
                }}
              />
            </ChartCard>
          ))}
        </Stack>
      )}
      <Typo variant="h3">Top 10 Organizations</Typo>
      <Typo variant="body1">The top organizations with the highest allocation.</Typo>
      <GreatList rowData={dataPresigned?.top10WeightedIsin ?? []} data-testid={createTestid('greatList')} />
    </div>
  );
};
