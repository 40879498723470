import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useState } from 'react';
import useStyles from './revokePermissionsStyles';

interface AccessRequestProps {
  selectedItems: CollectApi.AccessDemand[];
  closeModal(): void;
  onRevoke(comment: string): void;
  'data-testid': string;
}

export const RevokePermissions: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  onRevoke,
  'data-testid': testid,
}: AccessRequestProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const [isReplying, setReplying] = useState(false);
  const handleReply = useCallbackImmutable(() => {
    setReplying(true);
    onRevoke('');
  });
  return (
    <>
      <h3 className={classes.subTitles}>Shareclass selected</h3>
      <Table aria-label="simple table" data-testid={createTestid('table-selected')}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaders}>Shareclass ID</TableCell>
            <TableCell className={classes.tableHeaders}>Request by</TableCell>
            <TableCell className={classes.tableHeaders}>Frequency</TableCell>
            <TableCell className={classes.tableHeaders}>Deadline</TableCell>
            <TableCell className={classes.tableHeaders}>Look-through rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItems.map((row: CollectApi.AccessDemand) => (
            <TableRow key={row.dataAccessAuthorizationId}>
              <TableCell component="th" scope="row">
                {row.fundId}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.requestor}
              </TableCell>
              <TableCell className={classes.tableContent}>{row.deadline}</TableCell>
              <TableCell className={classes.tableContent}>
                <Icon.Calendar data-testid={createTestid(`${row.fundId}-icon-calendar`)} />
                {row.sharingFrequency.charAt(0).toUpperCase() + row.sharingFrequency.slice(1).toLowerCase()}
              </TableCell>
              <TableCell className={classes.tableContent}>{row.lookThroughLevel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <span className={classes.description}>
        <Icon.Warning data-testid={createTestid('icon-warning')} />
        You are about to revoke the access for {selectedItems[0] && selectedItems[0].requestor} on the selected
        shareclass. Do you confirm?
      </span>
      <div className={classes.formFooter}>
        <hr />
        <Button color="primary" outlined onClick={closeModal} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleReply} loading={isReplying} data-testid={createTestid('button-submit')}>
          Confirm
        </Button>
      </div>
    </>
  );
};
