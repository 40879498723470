import { makeStyles } from 'mns-components';

export const widgetStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: '1rem',
    '& > :not(:first-child)': {
      marginLeft: '1rem',
    },
  },
});
