import { Button, Dialog, useTestid, DefinitionsLayout } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { CollectStatusRenderer } from '../../../../components/cellRender/CollectStatusRenderer';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { collectHistoryStyles as useStyles } from './CollectHistory.styles';

type CollectDetailsProps = {
  portfolio: Required<CollectApi.FundPositionToCollect>;
  onClose?(): void;
  'data-testid': string;
};

export const CollectDetails: React.FC<CollectDetailsProps> = ({ portfolio, onClose, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <div className={classes.modalWrapper} data-testid={testid}>
      <div className={classes.modalContent}>
        <DefinitionsLayout
          variant="normal"
          definitions={[
            { key: 'externalId', label: 'Share class ID', value: portfolio.fundPosition.externalId },
            { key: 'fundName', label: 'Share class name', value: portfolio.fundPosition.name },
            { key: 'issuerName', label: 'Asset manager', value: portfolio.fundPosition.issuerName },
            {
              key: 'organisationOwnerName',
              label: 'Uploaded by',
              value: portfolio.disseminatorPortfolio.organisationName,
            },
            {
              key: 'latestVersionAsOf',
              label: 'NAV date',
              value: portfolio.disseminatorPortfolio.ingestionInfos?.latestVersionAsOf,
            },
            {
              key: 'lastRegulatoryNavDate',
              label: 'Last regulatory NAV date',
              value: portfolio.disseminatorPortfolio.lastRegulatoryNavDate,
            },
            {
              key: 'disseminatorPortfolio',
              label: 'Status',
              value: (
                <CollectStatusRenderer
                  disseminationStatus={portfolio.disseminatorPortfolio?.disseminationStatus}
                  receptionDate={portfolio.disseminatorPortfolio?.receptionDate}
                  data-testid={createTestid('status')}
                />
              ),
            },
            {
              key: 'qualities',
              label: 'Qualities',
              value: (
                <QualitiesRenderer
                  qualities={portfolio.disseminatorPortfolio?.ingestionInfos?.qualities ?? []}
                  data-testid={createTestid('qualities')}
                />
              ),
            },
            { key: 'expectedAsOf', label: 'Access start date', value: portfolio.disseminatorPortfolio?.expectedAsOf },
            { key: 'sharingFrequency', label: 'Frequency', value: portfolio.accessRequest?.sharingFrequency },
            {
              key: 'deadlineInDays',
              label: 'Delivery Deadline',
              value: portfolio.accessRequest?.deadlineInDays && `EOM + ${portfolio.accessRequest?.deadlineInDays}BD`,
            },
            {
              key: 'embargoDelayInDays',
              label: 'Embargo delay',
              value:
                portfolio.accessRequest?.embargoDelayInDays && `EOM + ${portfolio.accessRequest?.embargoDelayInDays}BD`,
            },
            { key: 'chaseUpCount', label: 'Count of chase up', value: portfolio.disseminatorPortfolio?.chaseUpCount },
          ]}
          data-testid={createTestid('definitionsLayout')}
        />
      </div>
      <div className={classes.modalFooter}>
        <Button color="primary" type="submit" onClick={onClose} data-testid={createTestid('button-close')}>
          Close
        </Button>
      </div>
    </div>
  );
};

type CollectDetailsModalProps = {
  portfolio: Required<CollectApi.FundPositionToCollect> | null;
  onClose?(): void;
  'data-testid': string;
};

export const CollectDetailsModal: React.FC<CollectDetailsModalProps> = ({
  portfolio,
  onClose,
  'data-testid': testid,
}) => (
  <Dialog open={!!portfolio} onClose={onClose} data-testid={`${testid}-dialog`}>
    {portfolio && <CollectDetails portfolio={portfolio} onClose={onClose} data-testid={testid} />}
  </Dialog>
);
