import { Stack } from '@mui/material';
import { Backdrop, convertToDateLocal, Dialog, EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { DeliveryDetailsDialogEet } from '../../../applications/eetApp/common/DeliveryDetailsDialogEet';
import { DeliveryDetailsDialogProdRange } from '../../../applications/productRange/dissemination/common/DeliveryDetailsDialogProdRange';
import { currentMonthLastDate, firstDateAvailable } from '../../../common/date';
import { useUser } from '../../../hooks/useAuth';
import type { AppCode } from '../../views/appDescriptions';
import { CreateEmailDeliveryModal } from '.././createDelivery/CreateEmailDeliveryModal';
import { DeliveryDetailsModal } from '../deliveryDetailsModal/DeliveryDetailsModal';
import { useDeliveriesRequest } from '../hooks';
import { PastDeliveriesController } from './PastDeliveriesController';
import { PastDeliveriesList } from './PastDeliveriesList';

const emailDeliveryByApps: Partial<Record<AppCode, DataDeliveryApi.DataDeliveriesRequest['type']>> = {
  data_dissemination: 'TPT',
  'manaos-eet': 'EET_GENERATED',
  'manaos-productrangedissemination': 'EET_UPLOADED',
};

type DeliveryDetailsDialogProps = {
  deliveryId: string;
  onClose(): void;
  'data-testid': string;
};

const mapDeliveryDialogByApps: Partial<Record<AppCode, React.FC<DeliveryDetailsDialogProps>>> = {
  data_dissemination: DeliveryDetailsModal,
  'manaos-eet': DeliveryDetailsDialogEet,
  'manaos-productrangedissemination': DeliveryDetailsDialogProdRange,
};

export const PastDeliveries: React.FC<{
  appCode: AppCode;
  dateFieldName: string;
  navElement?: React.ReactNode;
  'data-testid': string;
}> = ({ appCode, dateFieldName, navElement, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { organisationId: orgId } = useUser();
  const deliveryParamType = emailDeliveryByApps[appCode];
  const [period, setPeriod] = useState<[Date | null, Date | null]>([firstDateAvailable, currentMonthLastDate]);
  const [search, setSearch] = useState('');
  const start = period[0] ? convertToDateLocal(period[0]) : undefined;
  const end = period[1] ? convertToDateLocal(period[1]) : undefined;

  const CompDeliveryDialog = mapDeliveryDialogByApps[appCode];

  const {
    data: deliveries,
    error: errorDeliveries,
    refetch: refetchDeliveries,
  } = useDeliveriesRequest({
    title: search,
    type: deliveryParamType,
    organisationId: orgId,
    start,
    end,
  });

  const [isOpenCreateModal, setOpenCreateModal] = useState(false);
  const onOpenCreateModal = useCallbackImmutable(() => setOpenCreateModal(true));
  const onCloseCreateModal = useCallbackImmutable(() => setOpenCreateModal(false));

  const [isOpenDeliveryModal, setOpenDetailModal] = useState<DataDeliveryApi.DataDeliveryMetric | null>(null);
  const onOpenDetailModal = useCallbackImmutable((selectedItem: string) => {
    const found = deliveries?.find((item) => item.info.id === selectedItem);
    if (found) {
      setOpenDetailModal(found);
    }
  });
  const onCloseDetailsModal = useCallbackImmutable(() => setOpenDetailModal(null));

  useEffect(() => {
    if (!isOpenCreateModal) {
      refetchDeliveries();
    }
  }, [isOpenCreateModal, refetchDeliveries]);

  if (errorDeliveries || !CompDeliveryDialog) {
    return (
      <>
        <PastDeliveriesController
          search={search}
          period={period}
          dateFieldName={dateFieldName}
          navElement={navElement}
          onSearch={setSearch}
          onChangePeriod={setPeriod}
          onCreateDelivery={onOpenCreateModal}
          data-testid={createTestid('controller')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-server')}
          />
        </Stack>
        <CreateEmailDeliveryModal
          open={isOpenCreateModal}
          onClose={onCloseCreateModal}
          appCode={appCode}
          data-testid={createTestid('createEmailDeliveryModal')}
        />
      </>
    );
  }

  if (deliveries === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!deliveries?.length) {
    return (
      <>
        <PastDeliveriesController
          search={search}
          period={period}
          dateFieldName={dateFieldName}
          navElement={navElement}
          onSearch={setSearch}
          onChangePeriod={setPeriod}
          onCreateDelivery={onOpenCreateModal}
          data-testid={createTestid('controller')}
        />
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There are no file deliveries sent yet!"
            data-testid={createTestid('error-empty')}
          />
        </Stack>
        <CreateEmailDeliveryModal
          open={isOpenCreateModal}
          onClose={onCloseCreateModal}
          appCode={appCode}
          data-testid={createTestid('createEmailDeliveryModal')}
        />
      </>
    );
  }

  return (
    <>
      <PastDeliveriesController
        search={search}
        period={period}
        dateFieldName={dateFieldName}
        navElement={navElement}
        onSearch={setSearch}
        onChangePeriod={setPeriod}
        onCreateDelivery={onOpenCreateModal}
        data-testid={createTestid('controller')}
      />
      <PastDeliveriesList
        dateFieldName={dateFieldName}
        rows={deliveries}
        setSelected={onOpenDetailModal}
        data-testid={testid}
      />
      <CreateEmailDeliveryModal
        open={isOpenCreateModal}
        onClose={onCloseCreateModal}
        appCode={appCode}
        data-testid={createTestid('createEmailDeliveryModal')}
      />
      <Dialog
        dialogTitle="Delivery details"
        open={isOpenDeliveryModal !== null}
        onClose={onCloseDetailsModal}
        data-testid={`${testid}-dialog`}
      >
        {isOpenDeliveryModal && (
          <CompDeliveryDialog
            deliveryId={isOpenDeliveryModal.info.id}
            onClose={onCloseDetailsModal}
            data-testid={testid}
          />
        )}
      </Dialog>
    </>
  );
};
