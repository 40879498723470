import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useMemoBase,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import type { MoreDetailPastDeliveriesRendererProps } from './MoreDetailPastDeliveriesRenderer';
import MoreDetailPastDeliveriesRenderer from './MoreDetailPastDeliveriesRenderer';

const getActionColDef = makeActionColDef<DataDeliveryApi.DataDeliveryMetric>();
const plurial = (count: number) => (count === 1 ? '' : 's');

const useColumns = (
  setSelected: MoreDetailPastDeliveriesRendererProps['setSelected'],
  dateFieldName: string,
  testid: string,
) =>
  useMemoBase(
    { setSelected },
    (base): AggridTableColumn<DataDeliveryApi.DataDeliveryMetric>[] => [
      { field: 'info.title', headerName: 'Delivery name' },
      { field: 'productionDate', headerName: dateFieldName },
      {
        field: 'countScope',
        headerName: 'Scope',
        cellRenderer: ({ value }) => `${value} file${plurial(value)}`,
      },
      {
        field: 'countRecipients',
        headerName: 'Recipients',
        cellRenderer: ({ value }) => `${value} recipient${plurial(value)}`,
      },
      {
        field: 'createdAt',
        headerName: 'Created on',
        valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.info?.createdAt),
        cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.info?.createdAt),
        sort: 'desc',
      },
      { field: 'info.createdBy', headerName: 'Created by' },
      getActionColDef('info', 70, ({ data: { info } }) => (
        <MoreDetailPastDeliveriesRenderer
          emailDeliveryId={info.id}
          setSelected={base.setSelected}
          data-testid={`${testid}-${info.title}-details`}
        />
      )),
    ],
    [setSelected, dateFieldName],
  );

type PastDeliveriesListProps = {
  'data-testid': string;
  rows: DataDeliveryApi.DataDeliveryMetric[];
  dateFieldName: string;
  setSelected: MoreDetailPastDeliveriesRendererProps['setSelected'];
};

export const PastDeliveriesList: React.FC<PastDeliveriesListProps> = ({
  rows,
  dateFieldName,
  setSelected,
  'data-testid': testid,
}) => (
  <AggridTable
    columnDefs={useColumns(setSelected, dateFieldName, testid)}
    rowData={rows}
    getRowNodeId={useImmutable(
      () => (row: DataDeliveryApi.DataDeliveryMetric) => row?.info?.title + '-' + row?.info?.createdAt,
    )}
    defaultColDef={useDefaultColDef<DataDeliveryApi.DataDeliveryMetric>()}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
    data-testid={testid}
  />
);
