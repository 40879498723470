import { Stack } from '@mui/material';
import {
  AppContent,
  AppMargin,
  Backdrop,
  Button,
  EmptyStateV2,
  SearchField,
  useCallbackImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { useEetLightFilesRequest, useUploadModalOpen } from '../hooks';
import { getRoute } from '../routes';
import { EetLightFilesList } from './EetLightFilesList';

type EetLightFilesProps = { 'data-testid': string };

export const EetLightFiles: React.FC<EetLightFilesProps> = ({ 'data-testid': testid }: EetLightFilesProps) => {
  const createTestid = useTestid(testid);
  const [search, setSearch] = useState('');
  const {
    data: eetLightFiles,
    isLoading: isExecuting,
    isError,
  } = useEetLightFilesRequest({
    qualities: ['REGULATORY', 'BASIC'],
  });
  const navigate = useNavigate();
  const onUploadPortfolio = useUploadModalOpen();
  const downloadPresignedUrl = useDownloadPresignedUrl();

  const handleDownload = useCallbackImmutable(async (fileData: CollectApi.File) => {
    downloadPresignedUrl({
      fileDownloadType: 'input',
      fileDownloadTypeLabel: 'Input File',
      processId: fileData.processId,
      fileName: fileData.name,
    });
  });

  const handleGenerate = useCallbackImmutable(async (eetLightId: string) => {
    const eetLightFile = eetLightFiles?.find((file) => file.processId === eetLightId);
    if (eetLightFile?.referenceDate) {
      const fileRefDate = eetLightFile.referenceDate;
      navigate(generatePath(getRoute('generate-eet').link), {
        state: { fileRefDate, eetLightId: eetLightFile.processId },
      });
    }
  });

  if (isExecuting) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }
  if (isError) {
    return (
      <AppContent>
        <Stack direction="row" spacing="1rem" justifyContent="space-between" alignItems="center">
          <SearchField value={search} onChange={setSearch} disableMargin data-testid={createTestid('search')} />
          <Stack direction="row" spacing="1rem" alignItems="center">
            <Button color="primary" onClick={onUploadPortfolio} data-testid={createTestid('button-upload')}>
              Upload a new input file
            </Button>
          </Stack>
        </Stack>
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            useCase="dataErrors"
            variant="error"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team"
            data-testid={createTestid('error')}
          />
        </Stack>
      </AppContent>
    );
  }

  if (!eetLightFiles?.[0]) {
    return (
      <AppContent>
        <Stack direction="row" spacing="1rem" justifyContent="space-between" alignItems="center">
          <SearchField value={search} onChange={setSearch} disableMargin data-testid={createTestid('search')} />
          <Stack direction="row" spacing="1rem" alignItems="center">
            <Button color="primary" onClick={onUploadPortfolio} data-testid={createTestid('button-upload')}>
              Upload a new input file
            </Button>
          </Stack>
        </Stack>
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            useCase="upload"
            variant="info"
            title="Get started by uploading a light EET!"
            subTitle="Start your EET journey by uploading your light EET."
            buttonVariant="primary"
            buttonIcon="upload"
            buttonText="Upload light EET"
            buttonOnClick={onUploadPortfolio}
            data-testid={createTestid('error-empty')}
          />
        </Stack>
      </AppContent>
    );
  }

  return (
    <AppContent variant="expanded-noscroll">
      <AppMargin>
        <Stack direction="row" spacing="1rem" justifyContent="space-between" alignItems="center">
          <SearchField value={search} onChange={setSearch} disableMargin data-testid={createTestid('search')} />
          <Stack direction="row" spacing="1rem" alignItems="center">
            <Button color="primary" onClick={onUploadPortfolio} data-testid={createTestid('button-upload')}>
              Upload a new input file
            </Button>
          </Stack>
        </Stack>
      </AppMargin>
      <EetLightFilesList
        rows={eetLightFiles}
        search={search}
        onDownload={handleDownload}
        onGenerate={handleGenerate}
        data-testid={createTestid('list')}
      />
    </AppContent>
  );
};
