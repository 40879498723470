import { IconButton } from '@mui/material';
import type { JsonSchemaType, AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Autocomplete,
  Button,
  FormCreator,
  Icon,
  importCSV,
  Link,
  makeActionColDef,
  makeForm,
  TextField,
  Typography,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi, IsinListApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { CLOUDFRONT_DNSNAME } from '../../../app.constants';
import { getPortfolioText, splitPortfolioText } from '../../../common/utils';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';
import { modalStyles as useStyles } from './modalStyles';

type Isin = { fundId: string; fundName: string };

type UploadProps = { onUpload(fundList: Isin[]): void; 'data-testid': string };

const Upload: React.FC<UploadProps> = ({ onUpload, 'data-testid': testid }) => {
  const handleUpload = useCallbackImmutable(async (_: unknown, fileList: File[]) => {
    const data = await fileList[0].text();
    const rows = importCSV<Isin>(data, ['fundId', 'fundName'])
      .filter(({ fundId }) => !!fundId)
      .slice(1);
    onUpload(rows);
  });

  return (
    <Button.Upload
      color="primary"
      onUpload={handleUpload}
      startIcon={<Icon.Upload data-testid={'icon-remove'} />}
      data-testid={testid}
    >
      Upload your CSV file
    </Button.Upload>
  );
};

const templateBasename = 'list-template.csv';
const templateUrl = `//${CLOUDFRONT_DNSNAME}/providers/manaos/templates/${templateBasename}`;

type DeleteCellProps = { fundId: string; onDelete(fundId: string): void };

const DeleteCell = ({ fundId, onDelete }: DeleteCellProps) => (
  <IconButton
    title="Delete list"
    color="error"
    onClick={useCallbackImmutable(() => onDelete(fundId))}
    data-testid={'button-delete'}
  >
    <Icon.Delete data-testid={'icon-delete'} />
  </IconButton>
);

const getActionColDef = makeActionColDef<Isin>();

const useColumns = (selectCol: AggridTableColumn<Isin>, onDelete: (id: string) => void) =>
  useMemo(
    (): AggridTableColumn<Isin>[] => [
      selectCol,
      {
        field: 'fundId',
        headerName: 'ISIN',
        flex: 1,
        maxWidth: 150,
      },
      {
        field: 'fundName',
        headerName: 'Portfolio name',
        flex: 1,
      },
      getActionColDef('fundId', 70, ({ value }) => <DeleteCell fundId={value} onDelete={onDelete} />),
    ],
    [onDelete, selectCol],
  );

type FormData = { search: string };

const jsonSchema: JsonSchemaType = {
  properties: {
    search: { type: 'string', variant: 'search', title: 'Search...' },
  },
};

type IsinListInputsProps<
  T extends IsinListApi.List | undefined,
  D = T extends undefined ? IsinListApi.CreateListRequest : IsinListApi.UpdateListRequest,
> = {
  isinList: T;
  submitLabel: string;
  onClose(): void;
  onSave(data: D): void;
  variant?: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};

const IsinListInputs = <
  T extends IsinListApi.List | undefined,
  D = T extends undefined ? IsinListApi.CreateListRequest : IsinListApi.UpdateListRequest,
>({
  isinList,
  submitLabel,
  onSave,
  onClose,
  variant,
  'data-testid': testid,
}: IsinListInputsProps<T, D>): React.ReactElement => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [isCreating, setIsCreating] = useState(false);

  const [listName, setListName] = useState<string>(isinList?.name ?? '');
  const [autocompleteFunds, setAutocompleteFunds] = useState<string[]>([]);
  const [confirmedFunds, setConfirmedFunds] = useState<string[]>(
    () => isinList?.funds.map(({ fundId, fundName }) => getPortfolioText([fundId, fundName])) ?? [],
  );
  const [selectedRows, setSelectedRows] = useState<Isin[]>([]);

  const [state, setState] = useState<FormData>({ search: '' });
  const [search, setSearch] = useState('');

  useEffect(() => setSearch(state.search), [setSearch, state.search]);

  const handleRemoveFund = useImmutable(() => (removeFundId: string) => {
    setConfirmedFunds((current) => {
      const index = current.findIndex((item) => splitPortfolioText(item)[0] === removeFundId);
      if (index !== -1) {
        const newList = current.slice();
        newList.splice(index, 1);
        return newList;
      }
      return current;
    });
  });

  const handleRemovingManyFunds = useCallbackImmutable(() => {
    selectedRows.forEach((element) => {
      handleRemoveFund(element.fundId);
    });
  });

  const { data: portfolios } = useFetchAllPortfolios();

  const fundTextOptions = useMemo(
    (): string[] =>
      !portfolios
        ? []
        : Object.values(
            portfolios.reduce((acc, item) => {
              acc[item.externalId.value] = item;
              return acc;
            }, {} as Record<string, CollectApi.Portfolio>),
          )
            .map((ptf) => getPortfolioText(ptf))
            .filter((ptfText) => !confirmedFunds.find((fund) => fund === ptfText)),
    [portfolios, confirmedFunds],
  );

  const fundList = useMemo(
    () =>
      confirmedFunds.map((fundText): Isin => {
        const [fundId, fundName] = splitPortfolioText(fundText);
        return {
          fundId,
          fundName,
        };
      }),
    [confirmedFunds],
  );

  const handleConfirm = useCallbackImmutable(() => {
    setIsCreating(true);
    if (isinList?.id) {
      onSave({
        id: isinList.id,
        name: listName,
        funds: fundList,
      } as D);
    } else {
      onSave({
        name: listName,
        type: variant === 'ISIN' ? 'ISIN' : 'USER_SCOPE',
        funds: fundList,
      } as D);
    }
  });

  const handleAddAutocompletePortfolio = useCallbackImmutable(() => {
    setConfirmedFunds((current) => [...current, ...autocompleteFunds]);
    setAutocompleteFunds([]);
  });

  const handleUploadIsin = useImmutable(() => (uploadedIsin: Isin[]) => {
    setConfirmedFunds((current) => [
      ...current,
      ...uploadedIsin.map(({ fundId, fundName }) => getPortfolioText([fundId, fundName])),
    ]);
  });

  const [gridProps, selectCol] = useRowSelection<Isin>(({ api }) => setSelectedRows(api.getSelectedRows()), true);

  return (
    <>
      <div className={classes.scroll} data-testid={createTestid('inner-dialog')}>
        <TextField
          label="List name"
          name="name"
          type="text"
          onFieldChange={setListName}
          value={listName}
          data-testid={createTestid('input-listName')}
        />

        {/* add from autocomplete */}
        <div className={classes.addForm}>
          <Autocomplete.Multiple
            name="fundId"
            label="Choose one or several portfolios"
            className={classes.addFormInput}
            options={fundTextOptions}
            value={autocompleteFunds}
            onChange={setAutocompleteFunds}
            data-testid={createTestid('input-fundId')}
          />
          <Button
            color="primary"
            disabled={!autocompleteFunds.length}
            onClick={handleAddAutocompletePortfolio}
            data-testid={createTestid('button-add-autocomplete')}
          >
            Add
          </Button>
        </div>

        {/* add from file */}
        <div className={classes.downloadBlock}>
          <Upload onUpload={handleUploadIsin} data-testid={createTestid('input-uploadIsin')!} />
          <Typography variant="body2">
            To help you, you can use our{' '}
            <Link.Underlined
              extern
              color="secondary"
              to={templateUrl}
              data-testid={createTestid('link-download')}
              target={templateBasename}
            >
              {templateBasename}
            </Link.Underlined>
          </Typography>
        </div>

        {/* table controller */}
        <div className={classes.controllerDiv}>
          <FormCreator jsonSchema={jsonSchema} state={state} setState={setState} data-testid={createTestid('form')} />
          <Button
            color={'danger'}
            startIcon={<Icon.Delete data-testid="delete-icon" />}
            size="medium"
            onClick={handleRemovingManyFunds}
            disabled={!selectedRows.length}
            data-testid={createTestid('button-add')}
          >
            Delete
          </Button>
        </div>
        {/* displays aggregated ISIN items */}
        <div className={classes.fundList}>
          <AggridTable
            quickFilterText={search}
            rowData={fundList}
            {...gridProps}
            defaultColDef={useDefaultColDef<Isin>()}
            columnDefs={useColumns(selectCol, handleRemoveFund)}
            getRowNodeId={useImmutable(
              () =>
                ({ fundId }: Isin) =>
                  fundId,
            )}
            onGridReady={useAutoSize('fit')}
            data-testid={createTestid('ag-table-dialog')}
          />
        </div>
      </div>

      {/* Will be redone soon */}
      <div className={classes.buttonList}>
        <Button onClick={onClose} data-testid={createTestid('button-cancel')} color="primary" outlined>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={handleConfirm}
          loading={isCreating}
          disabled={!listName.trim().length}
          data-testid={createTestid('button-submit')}
        >
          {submitLabel}
        </Button>
      </div>
    </>
  );
};

export const CreateIsinListForm = makeForm(IsinListInputs<undefined>);
export const UpdateIsinListForm = makeForm(IsinListInputs<IsinListApi.List>);
