import type { FormProps } from 'mns-components';
import { Button, TextField, makeForm, useCallbackImmutable, useNavigate, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCognitoAuthError, isCognitoUser, useAuthUser, useHandleMfa } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';
import { ErrorBox } from './Forms';
import { routes as authRoutes } from './routes';

type MfaFormData = {
  username: string;
  code: string;
};

type MfaInputsProps = Omit<FormProps<MfaFormData>, 'children'> & { error?: Error };

const MfaInputs: React.FC<MfaInputsProps> = ({ defaultValues: { username } = {}, error, 'data-testid': testid }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const createTestid = useTestid(testid);

  const {
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext<MfaFormData>();

  useEffect(() => {
    if (username) {
      setValue('username', username);
    }
  }, [username, setValue]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  watch(() => setErrorMessage(''));

  return (
    <>
      <ErrorBox error={errorMessage} data-testid={createTestid('error-verification-code')} />
      <div>
        <TextField name="username" type="text" label="username" disabled data-testid={createTestid('username')} />
      </div>
      <div>
        <TextField name="code" type="text" label="code" data-testid={createTestid('code')} />
      </div>
      <div>
        <Button color="secondary" type="submit" loading={isSubmitting} data-testid={createTestid('submit')}>
          Log in with MFA
        </Button>
      </div>
    </>
  );
};

const MfaForm = makeForm<MfaFormData, MfaInputsProps>(MfaInputs);

export const Mfa: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const [authUser] = useAuthUser();
  const mfaLogin = useHandleMfa();

  useEffect(() => {
    if (!authUser) {
      navigate(authRoutes.loginUri.path);
    }
  }, [navigate, authUser]);

  const handleMfa = useCallbackImmutable(async ({ code }: MfaFormData) => mfaLogin(code));

  const handleValidation = useCallbackImmutable(({ username, code }: MfaFormData) => {
    const errors: Partial<AnyObject<keyof MfaFormData, string>> = {};
    if (!username.length) errors.username = 'Username should be filled';
    if (!code.length) errors.code = 'Code should be filled';
    return errors;
  });

  return (
    <AuthPageLayout data-testid={testid}>
      <h1 className={classes.title}>Log in with MFA</h1>
      <p className={classes.info}>
        To log in, your user should be authenticated by another device, please check you received a code.
      </p>
      <MfaForm
        defaultValues={isCognitoUser(authUser) ? { username: authUser?.getUsername() } : undefined}
        error={isCognitoAuthError(authUser) ? authUser : undefined}
        onSubmit={handleMfa}
        onValidate={handleValidation}
        className={classes.authForm}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
