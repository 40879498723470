import type { WidgetApi } from 'mns-sdk-collect';
import { iframeStyles as useStyles } from '../styles/iframeStyles';

export type IframeWidgetProps = {
  widgetDoc: WidgetApi.Document;
  'data-testid': string;
};

export const IframeWidget: React.FC<IframeWidgetProps> = ({ widgetDoc, 'data-testid': testid }) => {
  const classes = useStyles();

  return (
    <div data-testid={testid} className={classes.widgetContainer}>
      <iframe src={`${widgetDoc.widgetUrl}&background=%23f8f8f8`} title="Widget" className={classes.widget} />
    </div>
  );
};
