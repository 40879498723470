import { Checkbox } from '@mui/material';
import { Icon, useCallbackImmutable, useTestid } from 'mns-components';
import React from 'react';
import { formStyles as useStyles } from './formStyles';

type NeedCoverageDisplayProps = { companyName: string; onAgreeCoverage?(bo: boolean): void; 'data-testid': string };

export const NeedCoverageDisplay: React.FC<NeedCoverageDisplayProps> = ({
  companyName,
  onAgreeCoverage,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const handleAgreeCoverage = useCallbackImmutable((ev: React.ChangeEvent<HTMLInputElement>, bo: boolean) =>
    onAgreeCoverage?.(bo),
  );

  return (
    <div className={classes.form}>
      <h3 className={classes.formTitle}>
        <span>Portfolio needs coverage</span>
        <hr />
      </h3>

      <p className={classes.paragraph}>
        <Icon.Info data-testid={createTestid('icon-info')} />
        <span>
          In order to get your portfolio coverage rate, a subset of your portfolio data will be sent to {companyName}.
          Note that this subset of data sent to {companyName} contains only the list of ISINs and weights, with no other
          information such as your portfolio name or client name.
        </span>
      </p>

      <p className={classes.paragraph}>
        <Checkbox name="agreeCoverage" onChange={handleAgreeCoverage} />
        <span>I agree to share with {companyName} a subset of my portfolio data in order to get its coverage rate</span>
      </p>
    </div>
  );
};
