import { makeStyles, colors } from 'mns-components';

export const myPortfoliosStyles = makeStyles({
  mainComponent: {
    flex: '1 1 0',
    minHeight: 0,

    padding: '1rem 2rem 0 2rem',

    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',

    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  buttonUpload: {
    position: 'absolute',
    top: '.625rem',
    right: '1.25rem',
    zIndex: 1200,
  },
  headerLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  notificationNotice: {
    fontWeight: 'bold',
    fontSize: '.875rem',
    color: colors.infoColor,
    background: colors.whiteColor,
    width: 'fit-content',
    padding: '.75rem 2.25rem .625rem .625rem',
    borderRadius: '4px',
    boxShadow: '0 0 1.25rem 0 rgba(0,0,0,0.06)',
    '& span': {
      background: 'rgba(95,122,241,0.2)',
      color: colors.infoColor,
      borderRadius: '50%',
      position: 'relative',
      right: '-1rem',
      top: '-1px',
      padding: '1px .25rem',
    },
  },
  datePickerContainer: {
    top: '-2.75rem',
    right: 0,
    width: '21.875rem',
    position: 'relative',
    margin: '0 auto',
    '& svg': {
      color: '#5F7AF1',
    },
  },
  searchFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    top: 0,
    right: 0,
    position: 'relative',
  },
  uploadPortfoliosRelativePositionListStyle: {
    display: 'block',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 1rem 1rem 1rem',
  },
  downloadContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    '& > *': {
      marginLeft: '.625rem',
      minHeight: '0',
    },
  },
  list: {
    display: 'flex',
    flex: '1 1 0',
    minHeight: 0,
  },
  actionFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '3.5rem',
    overflow: 'hidden',
  },
  portfoliosListActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
