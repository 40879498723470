import { Button, Card, Icon, convertToDateLocal, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, MarketplaceApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { useAppBackgroundStyles } from '../../../../common/getImageContent';
import type { AppCode } from '../../../../components/views/appDescriptions';
import useStyles from './styles/widgetAppGenerateModalStyles';

export type WidgetAppGenerateModalProps = {
  remainingComplementaryReports: number;
  applicationDetails: MarketplaceApi.Application;
  currentPtf: CollectApi.Portfolio;
  valuationDate: string;
  onConfirm(): void;
  onCancel(): void;
  'data-testid': string;
};

export const WidgetAppGenerateModal: React.FC<WidgetAppGenerateModalProps> = ({
  remainingComplementaryReports,
  applicationDetails,
  currentPtf,
  valuationDate,
  'data-testid': testid,
  onConfirm,
  onCancel,
}: WidgetAppGenerateModalProps) => {
  const [loading, setLoading] = useState(false);
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const appBackgroundClasses = useAppBackgroundStyles();

  const handleConfirm = useCallbackImmutable(() => {
    setLoading(true);
    onConfirm();
  });

  return (
    <div>
      <div className={classes.cardContainerDetails} data-testid={createTestid('card-container')}>
        <div className={classes.headerContainer} data-testid={createTestid('header')}>
          <div
            className={[
              classes.applicationIcon,
              appBackgroundClasses[applicationDetails.codeApplication as AppCode],
            ].join(' ')}
            data-testid={createTestid('header-logo')}
          >
            Logo
          </div>
          <div className={classes.titleContainer}>
            <h2 className={classes.cardLogoTitle} data-testid={createTestid('header-appname')}>
              {applicationDetails.applicationName}
            </h2>
            <p className={classes.cardLogoSubtitle}>
              Powered by{' '}
              <span style={{ fontWeight: 'bold' }} data-testid={createTestid('header-company')}>
                {applicationDetails.provider.providerName}
              </span>
            </p>
          </div>
        </div>
        <Card className={classes.cardOffer}>
          <div className={classes.cardOfferHeader}>
            <Icon.Localoffer data-testid={createTestid('icon-localoffer')} />
            <div className={classes.cardHeaderDescriptionContainer}>
              <div>
                <p className={classes.selectedOfferName}>{applicationDetails?.subscriptionsDto?.offerDto?.offerName}</p>
                <p className={classes.selectedOfferFeatures}>
                  <Icon.Check size="small" data-testid={createTestid('icon-check')} />{' '}
                  <span>includes a PDF portfolio report</span>
                </p>
              </div>
              {remainingComplementaryReports >= 1 && (
                <p className={classes.remainingReports}>
                  {remainingComplementaryReports} remaining PDF reports to order
                </p>
              )}
            </div>
          </div>
        </Card>
        <div className={classes.resumeDetails}>
          <div className={classes.optionsText}>
            <h3 className={classes.cardTitles}>Review your portfolio details</h3>
            <hr />
          </div>
          <div>
            <p className={classes.inlineFlex}>
              <span>Portfolio ID:</span>
              <span data-testid={createTestid('portfolio-externalidvalue')}>{currentPtf.externalId.value}</span>
            </p>
            <p className={classes.inlineFlex}>
              <span>Portfolio name:</span>
              <span data-testid={createTestid('portfolio-name')}>{currentPtf.name}</span>
            </p>
            <p className={classes.inlineFlex}>
              <span>NAV date:</span>
              <span data-testid={createTestid('portfolio-name')}>{convertToDateLocal(valuationDate)}</span>
            </p>
            <p className={classes.inlineFlex}>
              <span>Number of lines: </span>
              <span data-testid={createTestid('portfolio-number-lines')}>{currentPtf.latestVersion.numberOfLines}</span>
            </p>
            <p className={classes.inlineFlex}>
              <span>Look-through rate:</span>
              <span data-testid={createTestid('portfolio-lookthrough-rate')}>
                {currentPtf.latestVersion.lookThroughRate}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.footerDialog}>
        <hr />
        <div className={classes.buttonContainer}>
          <Button onClick={onCancel} color="primary" outlined data-testid={createTestid('btn-cancel')}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleConfirm} loading={loading} data-testid={createTestid('btn-confirm')}>
            Confirm Report Request
          </Button>
        </div>
      </div>
    </div>
  );
};
