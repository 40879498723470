import { colors, makeStyles } from 'mns-components';

export const marketplaceFiltersStyles = makeStyles({
  myAppsCategoryBlock: {
    width: '18.5rem',
    display: 'block',
    padding: '1.25rem 1rem 0 4.5rem',
    boxSizing: 'border-box',
    '@media (max-width: 1280px)': {
      maxWidth: '20.375rem',
    },
  },
  myAppsFiltersBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  allFilters: {
    display: 'flex',
    alignItems: 'center',
  },
  selectFilters: {
    marginLeft: '24px',
  },
  appDeliverable: {
    display: 'inline-flex',
    marginLeft: '3rem',
  },
  tagsContainer: {
    margin: '0.875rem auto auto 0.5rem',
    display: 'inline',
    '& > *': {
      flexFlow: 'wrap',
      '& > *': {
        marginBottom: '.5rem',
      },
    },
  },
  partnersBlock: {
    display: 'flex',
    alignItems: 'stretch',
    border: '1px solid #edeffc',
    borderRadius: '4px',
    height: '2rem',
    width: '12rem',
  },
  partnersBlockLabel: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    background: '#edeffc',
    padding: '0 0.5rem',
    fontSize: '.8rem',
    fontWeight: 500,
  },
  partnersBlockSelect: {
    marginTop: '.2rem !important',
    fontSize: '.8rem',
    paddingLeft: '.5rem',
  },
  category: {
    marginTop: '6.25rem',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  categoriesTitle: {
    textAlign: 'left',
    color: colors.infoColor,
  },
  categoriesParagraph: {
    textAlign: 'left',
    display: 'flex',
    maxWidth: '100%',
    margin: 0,
    '& *': {
      fontSize: '.875rem',
      fontFamily: 'Rubik, sans-serif',
      '@media (max-width: 1440px)': {
        fontSize: '.75rem',
      },
    },
    '& label': {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      fontFamily: 'Rubik',
    },
    '& .MuiFormControlLabel-label': {
      position: 'relative',
      top: '-2px',
    },
  },
});
