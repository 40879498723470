import { offset } from '@floating-ui/dom';
import { GuidedStep, Image, Typography, Video, colors, makeStyles, useTestid } from 'mns-components';
import { useGuidedTour } from '../../../hooks/useGuidedTour';
import type { GuidedTourScenarioBuild } from '../types';
import { getAsset, waitForNode } from '../utils';

const logoStyles = makeStyles({
  show: {
    backgroundImage: `url(${getAsset('coverageEstimation', 'coverageEstimation-welcome-user.svg')})`,
  },
});

const testid = 'coverageEstimationScenario';

export const coverageEstimationScenario: GuidedTourScenarioBuild = () => [
  // step 1
  {
    element: function Step() {
      const {
        state: { stepIndex },
        goNext,
        goLast,
      } = useGuidedTour('coverageEstimation');
      const createTestid = useTestid(testid);
      return (
        <GuidedStep.Bottom
          title="Welcome to Portfolios Coverage Estimation by Manaos!"
          backLabel="Skip the tour"
          nextLabel="Start the tour"
          onBack={goLast}
          onNext={goNext}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Typography variant="body2">
            A quick tutorial to explain how to take the first steps on the portfolios coverage estimation.
          </Typography>
          <Image
            marginTop="2rem"
            height="15rem"
            backgroundClassname={logoStyles().show}
            data-testid={createTestid('image')}
          />
        </GuidedStep.Bottom>
      );
    },
    options: {
      arrow: false,
      version: 2401,
      beforeShowPromise: async () => {
        await waitForNode('[data-stepid="coverageEstimation-help-button"]');
      },
      attachTo: { on: 'bottom-end' },
    },
  },
  // step 2
  {
    element: function Step() {
      const {
        state: { stepIndex },
        goBack,
        goNext,
        goLast,
      } = useGuidedTour('coverageEstimation');
      const createTestid = useTestid(testid);
      return (
        <GuidedStep.Normal
          title="Set up your porfolio"
          progressionCount={2}
          progressionValue={0}
          onBack={goBack}
          onNext={goNext}
          onClose={goLast}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Typography variant="body2">
            To launch an estimation, please{' '}
            <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={500}>
              select the portfolio,
            </Typography>{' '}
            then choose{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              your NAV date.
            </Typography>
          </Typography>
          <Video
            autoPlay
            loop
            muted
            width="370px"
            sources={[
              {
                src: getAsset('coverageEstimation', 'coverageEstimation_setUpPortfolio.mp4'),
                type: 'video/mp4',
              },
            ]}
            data-testid={createTestid('video')}
          />
        </GuidedStep.Normal>
      );
    },
    options: {
      version: 2401,
      beforeShowPromise: async () => {
        await waitForNode('[data-stepid="SelectorPtfAndDate"]');
      },
      floatingUIOptions: {
        middleware: [offset({ crossAxis: 400, mainAxis: 20 })],
      },
      attachTo: { on: 'bottom-start', element: '[data-stepid="SelectorPtfAndDate"]' },
    },
  },
  // step 3
  {
    element: function Step() {
      const {
        state: { stepIndex },
        goBack,
        goNext,
        goLast,
      } = useGuidedTour('coverageEstimation');
      const createTestid = useTestid(testid);
      return (
        <GuidedStep.Normal
          title="Launch your coverage estimation"
          progressionCount={2}
          progressionValue={1}
          onBack={goBack}
          onNext={goNext}
          onClose={goLast}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Typography variant="body2">
            Calculate coverage and discover the results. You can filter by providers or applications and{' '}
            <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
              download the coverage results.
            </Typography>
          </Typography>
          <Video
            autoPlay
            loop
            muted
            width="510px"
            sources={[
              {
                src: getAsset('coverageEstimation', 'coverageEstimation_launchYourCoverageEstimation.mp4'),
                type: 'video/mp4',
              },
            ]}
            data-testid={createTestid('video')}
          />
        </GuidedStep.Normal>
      );
    },
    options: {
      version: 2401,
      beforeShowPromise: async () => {
        await waitForNode('[data-stepid="portfolioCoverageRequester-button-ready"]');
      },
      attachTo: { on: 'bottom-start', element: '[data-stepid="portfolioCoverageRequester-button-ready"]' },
    },
  },
  // step 4
  {
    element: function Step() {
      const {
        state: { stepIndex },
        goLast,
      } = useGuidedTour('coverageEstimation');
      const createTestid = useTestid(testid);
      return (
        <GuidedStep.Bottom
          title="Give it a go!"
          backLabel="Close"
          onBack={goLast}
          data-testid={testid}
          data-stepindex={stepIndex}
        >
          <Typography variant="body2">
            Now that you know the basics of{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              Portfolio coverage estimation by Manaos
            </Typography>
            , it&apos;s time to upload your first portfolio!
          </Typography>
          <Image
            marginTop="2rem"
            height="15rem"
            backgroundClassname={logoStyles().show}
            data-testid={createTestid('image')}
          />
        </GuidedStep.Bottom>
      );
    },
    options: {
      arrow: false,
      version: 2401,
      attachTo: { on: 'bottom-end' },
    },
  },
  // last step
  {
    element: function Step() {
      const {
        state: { stepIndex },
        complete,
      } = useGuidedTour('coverageEstimation');
      return (
        <GuidedStep.Tooltip title="Guided Tours" onClose={complete} data-testid={testid} data-stepindex={stepIndex}>
          Find the guided tours at any time by clicking this icon!
        </GuidedStep.Tooltip>
      );
    },
    options: {
      version: 2401,
      beforeShowPromise: async () => {
        await waitForNode('[data-stepid="coverageEstimation-help-button"]');
      },
      attachTo: { on: 'bottom', element: '[data-stepid="coverageEstimation-help-button"]' },
    },
  },
];
