import { colors, makeStyles } from 'mns-components';

const chaseUpAccessPermissionStyles = makeStyles({
  description: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '0.625rem',
    '& svg': {
      color: colors.infoColor,
      marginRight: '1.25rem',
    },
  },
  card: {
    boxShadow: 'none',
    '& div': {
      marginLeft: 0,
      paddingLeft: 0,
      '& div': {
        '& button': {
          height: '2.5rem',
          marginTop: '.5rem',
        },
      },
    },
  },
  formFooter: {
    textAlign: 'center',
    bottom: 0,
    position: 'absolute',
    width: 'calc(100% - 3rem)',
    height: '4.25rem',
    backgroundColor: `${colors.whiteColor} !important`,
    zIndex: 6666,
    '& > *': {
      margin: '1rem 0.25rem',
    },
    '& hr': {
      margin: 0,
      backgroundColor: colors.borderColor,
      height: '1px',
      border: 'none',
    },
  },
  email: {
    margin: '0.625rem',
  },
  listOrganisation: {
    padding: '1rem 0 5rem 0',
    '& > :not(:first-of-type)': {
      marginTop: '2rem',
      borderTop: `1px solid ${colors.borderColor}`,
    },
  },
  titleContainerWithIcon: {
    display: 'inline-flex',
    '& svg': {
      color: colors.infoColor,
      height: '1.5rem',
      width: '1.5rem',
      marginRight: '.875rem',
    },
  },
  cardTitles: {
    fontSize: '1rem',
    lineHeight: '1.125rem',
    color: '#11113C',
    fontWeight: 'bold',
    margin: '0 auto 1rem 0',
  },
  subTitles: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.blueColor,
    marginTop: '1rem',
  },
  tableHeaders: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '.875rem',
    lineHeight: '1rem',
    textAlign: 'left',
    color: colors.blueColor,
    opacity: '60%',
  },
  tableSelector: {
    width: '30px',
  },
  tableContent: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '.875rem',
    textAlign: 'left',
    color: colors.blueColor,
    '& svg': {
      verticalAlign: 'middle',
      color: colors.infoColor,
      marginRight: '0.25rem',
    },
  },
});

export default chaseUpAccessPermissionStyles;
