import type { AggridTableColumn, AggridTableProps, BulkProps } from 'mns-components';
import {
  AggridTable,
  Bulk,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  filterTruthy,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';

const getActionColDef = makeActionColDef<DataExtractorApi.Template.TemplateLight>();

export type ExtractorTemplatesListProps = {
  onDetailsTemplate?(row: DataExtractorApi.Template.TemplateLight): void;
  'data-testid': string;
} & Omit<AggridTableProps<DataExtractorApi.Template.TemplateLight>, 'defaultColDef' | 'columnDefs'>;

const bulkOptions =
  (onDetailsTemplate?: ExtractorTemplatesListProps['onDetailsTemplate']) =>
  (row: DataExtractorApi.Template.TemplateLight) =>
    filterTruthy([
      onDetailsTemplate && {
        key: 'details',
        label: 'View Scope Details',
        onClick: () => onDetailsTemplate(row),
      },
    ]);

const useColumns = (
  bulkOpts: (row: DataExtractorApi.Template.TemplateLight) => BulkProps['items'],
  testid: string,
): AggridTableColumn<DataExtractorApi.Template.TemplateLight>[] => {
  const createTestid = useTestid(testid);

  return [
    {
      field: 'name',
      headerName: 'Template name',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      flex: 1,
      sort: 'desc',
      sortable: true,
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 1,
    },
    getActionColDef('id', 70, ({ data }) => <Bulk items={bulkOpts(data)} data-testid={createTestid('bulk')} />),
  ];
};

export const ExtractorTemplatesList: React.FC<ExtractorTemplatesListProps> = ({
  onDetailsTemplate,
  'data-testid': testid,
  ...props
}: ExtractorTemplatesListProps) => (
  <AggridTable
    {...props}
    columnDefs={useColumns(bulkOptions(onDetailsTemplate), testid)}
    getRowNodeId={useImmutable(() => (row: DataExtractorApi.Template.TemplateLight) => row.id)}
    data-testid={'ptfList'}
    defaultColDef={useDefaultColDef<DataExtractorApi.Template.TemplateLight>()}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
  />
);
