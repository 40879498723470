import { colors, makeStyles } from 'mns-components';

export const collectHistoryStyles = makeStyles({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  modalHeader: {
    flex: '0 0 auto',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${colors.greyColor}`,
  },
  modalContent: {
    flex: '1 1 auto',
    minHeight: 0,
    overflow: 'clip auto',
  },
  modalFooter: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1rem',
    borderTop: `1px solid ${colors.greyColor}`,
    '& > *:not(:first-child)': {
      marginRight: '1rem',
    },
  },
  controller: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '1rem 0',
  },
  grey: {
    color: colors.greyColor,
  },
});
