import type { RouterInterface } from '../../../components/routing/RouterInterface';
import { ExtractionsAutomated } from './extractionsAutomated/ExtractionsAutomated';
import { ExtractionsGenerated } from './extractionsGenerated/ExtractionsGenerated';
import { Templates } from './templates/Templates';

export const esgExtractionsRoutes = [
  {
    label: 'Extractions generated',
    link: '/esg/extractions/generated',
    key: 'generated',
    component: ExtractionsGenerated,
  },
  {
    label: 'Automated extractions settings',
    link: '/esg/extractions/automated',
    key: 'automated',
    component: ExtractionsAutomated,
  },
  {
    label: 'Templates',
    link: '/esg/extractions/templates',
    key: 'templates',
    component: Templates,
  },
] as const;

export const routesExtractions: readonly RouterInterface[] = esgExtractionsRoutes;
