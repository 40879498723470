import { IconButton, Menu } from '@mui/material';
import { Icon, MenuItem, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useState } from 'react';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { downloadFilesRendererStyles as useStyles } from '../uploads/downloadFilesRendererStyles';

export const downloadPortfolioMapping: {
  dlType: CollectApi.ShareclassFileType;
  label: string;
  disabled?: boolean;
}[] = [
  {
    dlType: 'input',
    label: 'Input File',
  },
  {
    dlType: 'look_through',
    label: 'Look-through File',
    disabled: true,
  },
  {
    dlType: 'output',
    label: 'Processed File',
  },
];

type DownloadPortfolioRendererProps = {
  externalId: CollectApi.Portfolio['externalId']['value'];
  externalIdType: CollectApi.Portfolio['externalId']['type'];
  valuationDate?: CollectApi.Portfolio['latestVersion']['valuationDate'];
  processId?: CollectApi.Portfolio['latestVersion']['processId'];
  name: CollectApi.Portfolio['name'];
  isCollectedList?: boolean;
  'data-testid': string;
};

export const DownloadPortfolioRenderer: React.FC<DownloadPortfolioRendererProps> = ({
  externalId,
  externalIdType,
  valuationDate,
  processId,
  name,
  isCollectedList = false,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const downloadPresignedUrl = useDownloadPresignedUrl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  const handleDownload = useCallbackImmutable(async (dlType: CollectApi.ShareclassFileType) => {
    if (valuationDate) {
      const dlLabel = downloadPortfolioMapping.find((assoc) => assoc.dlType === dlType)!.label;
      await downloadPresignedUrl({
        fileDownloadType: dlType,
        fileDownloadTypeLabel: dlLabel,
        externalId: externalId,
        externalIdType: externalIdType,
        valuationDate: valuationDate,
        fileDate: valuationDate,
        fileName: name,
      });
    }
  });

  if (!processId || !valuationDate) {
    return null;
  }

  return (
    <>
      <div
        className={classes.bulkContainer}
        data-filename={name}
        data-processid={processId}
        data-testid={createTestid(`menubutton`)}
      >
        <IconButton color="secondary" onClick={handleClick} data-testid={createTestid(`button-menubutton`)}>
          <Icon.More data-testid={createTestid(`icon-menubutton`)} />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-filename={name}
        data-proccessid={processId}
        data-testid={`filemenu-${name}`}
      >
        <MenuItem divider disabled data-testid={createTestid('section-title')}>
          Download
        </MenuItem>
        {downloadPortfolioMapping.map((buttonData) => (
          <MenuItem.Loading
            value={buttonData.dlType}
            onClick={handleDownload}
            disabled={isCollectedList && buttonData.disabled}
            data-testid={`${buttonData.dlType}-${name}`}
            key={buttonData.dlType}
          >
            {buttonData.label}
          </MenuItem.Loading>
        ))}
      </Menu>
    </>
  );
};
