import type { RouterInterface } from '../../../components/routing/RouterInterface';
import { getViewsRoute } from '../../../components/views/routes';
import { EmailDelivery } from './EmailDelivery';
import { MyDocuments } from './myDocuments/MyDocuments';
import { Upload } from './upload/Upload';

const baseRoute = getViewsRoute('manaos-productrangedissemination');

const rawRoutes = [
  {
    key: `${baseRoute.key}-documents`,
    link: `${baseRoute.path}/documents`,
    component: MyDocuments,
    label: 'My documents',
    displayTab: true,
    autoselect: true,
    breadCrumbs: ['EET Dissemination', 'Upload tracking'],
  },
  {
    key: `${baseRoute.key}-upload`,
    link: `${baseRoute.path}/upload`,
    component: Upload,
    label: 'Upload history',
    displayTab: true,
    breadCrumbs: ['EET Dissemination', 'Upload tracking'],
  },
  {
    key: `${baseRoute.key}-email-delivery`,
    link: `${baseRoute.path}/email-delivery/past-deliveries`,
    component: EmailDelivery,
    label: 'Email delivery',
    displayTab: true,
    isDisabled: false,
    expectRole: 'emailDeliveryActive',
  },
] as const;

export const getProductRangeDisseminationRoute = (key: typeof rawRoutes[number]['key']) =>
  rawRoutes.find((route) => route.key === key) as RouterInterface;

export const routes = rawRoutes as readonly RouterInterface[];
