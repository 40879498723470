import type { GridApi } from 'ag-grid-community';
import type { DropdownProps } from 'mns-components';
import { Button, Link, convertToDateUTC, useTestid, useCallbackImmutable, Switch, Icon } from 'mns-components';
import type { CollectApi, WidgetApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { downloadFile } from '../../../../common/utils';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getViewsRoute } from '../../../../components/views/routes';
import { api } from '../../../../store/api';
import { widgetApi } from '../../../../store/apis';
import { useFetchClarityApplication, useFetchUnderlying } from '../../hooks';
import {
  allowComplementaryReport,
  WidgetAppDownloadComplementaryReport,
} from '../components/WidgetAppDownloadComplementaryReport';
import { widgetStyles as useStyles } from './styles/widgetStyles';

export type TrialRenderProps = {
  appCode: string;
  freeTrial?: boolean;
  endDate?: number | null;
  'data-testid': string;
};

export const TrialRender: React.FC<TrialRenderProps> = ({ appCode, freeTrial, endDate, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  if (freeTrial && endDate) {
    const date = new Date(endDate);
    const currentDate = new Date();
    const delta = Math.abs(+date - +currentDate) / 1000;
    const days = Math.trunc(delta / 86400);

    return (
      <div>
        <Link to={generatePath(getViewsRoute('app-subscription').path, { appCode })} data-testid={createTestid('link')}>
          <Button color="secondary" data-testid={createTestid('btn-days')}>
            {days} days left - upgrade now
          </Button>
        </Link>
      </div>
    );
  }

  return null;
};

type WidgetProps = {
  appCode: AppCode;
  currentPtf: CollectApi.Portfolio;
  gridApi?: GridApi;
  gridGroupAllOpened?: boolean;
  onRowGroupOpened?(api: GridApi): void;
  'data-testid': string;
};

export const WidgetHeader: React.FC<WidgetProps> = ({
  appCode,
  currentPtf,
  gridApi,
  gridGroupAllOpened,
  onRowGroupOpened,
  'data-testid': testid,
}: WidgetProps) => {
  const [underlyingIds, setUnderlyingIds] = useState<WidgetApi.UnderlyingData[]>([]);
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const { data: applicationDetails, refetch: refetchApplicationDetails } = useFetchClarityApplication(appCode);
  const { date } = useParams<{ date: string }>();
  const { data: loadedUnderlyingIds = [], refetch: refetchUnderlying } = useFetchUnderlying(
    {
      appCode,
      externalIdValue: currentPtf.externalId.value,
      externalIdType: currentPtf.externalId.type,
      valuationDate: date,
    },
    {
      enabled: !!applicationDetails?.subscriptionsDto,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        return data?.find((underlying) => underlying.status === 'pending') ? 5000 : false;
      },
    },
  );

  const generateUnderlying = useCallbackImmutable(async () => {
    if (!currentPtf || !applicationDetails?.subscriptionsDto) return;

    const pending = {
      createdAt: new Date().toJSON(),
      complementaryReportId: 'new',
      status: 'pending',
    } as WidgetApi.UnderlyingData;

    setUnderlyingIds((current) => [...(current ?? []), pending]);

    try {
      await widgetApi.createUnderlyingData(
        applicationDetails.codeApplication,
        currentPtf.externalId.value,
        currentPtf.externalId.type,
        date,
        currentPtf.origin,
      );

      refetchUnderlying();
      refetchApplicationDetails();
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (loadedUnderlyingIds.length > 0) {
      if (
        underlyingIds.length <= 0 ||
        loadedUnderlyingIds.find((und) => {
          const found = underlyingIds.find((loaded) => und.complementaryReportId === loaded.complementaryReportId);
          return found?.status !== und.status;
        })
      ) {
        setUnderlyingIds(loadedUnderlyingIds);
      }
    }
  }, [loadedUnderlyingIds, underlyingIds]);

  // switch expand/collapse all
  const setExpand = useCallbackImmutable(() => {
    if (gridApi && gridGroupAllOpened !== undefined) {
      if (!gridGroupAllOpened) {
        gridApi.expandAll();
        onRowGroupOpened?.(gridApi);
      } else {
        gridApi.collapseAll();
        onRowGroupOpened?.(gridApi);
      }
    }
  });

  const {
    config: { complementaryReportType },
  } = useApplicationConfig(appCode);

  const remainingReport = useMemo(
    () => applicationDetails?.subscriptionsDto?.remainingComplementaryReports,
    [applicationDetails],
  );

  const hasTrialRender = useMemo(
    () => applicationDetails?.subscriptionsDto?.freeTrial && applicationDetails?.subscriptionsDto?.endDate,
    [applicationDetails],
  );

  const hasComplementaryReport = useMemo(
    () => allowComplementaryReport(complementaryReportType),
    [complementaryReportType],
  );

  const downloadUnderlying = useCallbackImmutable(async (analysisId: string) => {
    const [{ presignedUrl, fileName }] = await api.provider.download.downloadRequest.raw({
      appCode,
      requestId: analysisId,
    });
    downloadFile(presignedUrl, fileName);
  });

  const onExport = useCallbackImmutable(() => gridApi?.exportDataAsExcel());

  underlyingIds.sort((b, a) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf());

  const pendingItems = useMemo(() => underlyingIds.filter((item) => item.status !== 'complete'), [underlyingIds]);
  const completeItems = useMemo(
    () => underlyingIds.filter((item) => item.status === 'complete').slice(0, 4),
    [underlyingIds],
  );

  const options = useMemo(
    () =>
      completeItems.map((item): DropdownProps['options'][number] => ({
        children: `Download underlying data as of ${convertToDateUTC(new Date(item.createdAt))}`,
        value: item.complementaryReportId,
      })),
    [completeItems],
  );

  if (!applicationDetails || !(gridApi || hasTrialRender || hasComplementaryReport)) {
    return null;
  }

  return (
    <div className={classes.header}>
      <TrialRender
        appCode={appCode}
        freeTrial={applicationDetails.subscriptionsDto?.freeTrial}
        endDate={applicationDetails.subscriptionsDto?.endDate}
        data-testid={createTestid('upgrade')}
      />
      {gridApi && (
        <>
          {onRowGroupOpened !== undefined && gridGroupAllOpened !== undefined && (
            <Switch
              uncontrolled
              value={gridGroupAllOpened}
              onChange={setExpand}
              label="Expand all"
              data-testid={createTestid('button-expand-all')}
            />
          )}
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
            color="primary"
            outlined
            onClick={onExport}
            size="medium"
            data-testid={createTestid('export-table')}
          >
            Export table
          </Button>
        </>
      )}

      <WidgetAppDownloadComplementaryReport
        appCode={appCode}
        dropdownOptions={options}
        remainingReport={remainingReport}
        currentPtf={currentPtf}
        valuationDate={currentPtf.latestVersion.valuationDate}
        applicationDetails={applicationDetails}
        handleDownload={downloadUnderlying}
        isGenerating={pendingItems.length > 0}
        labelGenerate={
          pendingItems.length > 0
            ? `Processing request as of ${convertToDateUTC(new Date(pendingItems[0].createdAt))}`
            : 'Request underlying data as of today'
        }
        generate={generateUnderlying}
        data-testid={createTestid('download')}
      />
    </div>
  );
};
