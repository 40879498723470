import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn, SelectFilterItem } from 'mns-components';
import {
  AggridTable,
  Button,
  Icon,
  SelectFilter,
  colors,
  convertToDateLocal,
  makeActionColDef,
  stringCompareInsensitiveCase,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { ShareclassTooltip, shareclassPlain } from '../../../components/cellRender/ShareclassTooltip';
import { ChaseUpCountRenderer } from './components/ChaseUpCountRenderer';
import { DisseminationStateRenderer, disseminationStateRendererPlain } from './components/DisseminationStateRenderer';
import { PublicationStateRenderer, getPublicationStatePlain } from './components/PublicationStateRenderer';
import useStyles from './disseminationListStyles';

type DisseminationGridData = CollectApi.Dissemination & { id: string };

const getActionColDef = makeActionColDef<DisseminationGridData>();

const useColumns = (testid: string) =>
  useMemo(
    (): AggridTableColumn<DisseminationGridData>[] => [
      {
        field: 'externalIdOwner',
        headerName: 'Shareclass ID',
        minWidth: 160,
        comparator: stringCompareInsensitiveCase,
        cellRendererFramework: ({ data: { id, fundId, externalIdOwner } }) => (
          <ShareclassTooltip
            shareclassId={externalIdOwner}
            fundId={fundId}
            data-testid={`${testid}-${id}-externalIdOwner`}
          />
        ),
        valueGetter: ({ data: { fundId, externalIdOwner } }) =>
          shareclassPlain({ shareclassId: externalIdOwner, fundId }),
      },
      {
        field: 'fundName',
        headerName: 'Shareclass name',
        tooltipField: 'fundName',
        minWidth: 170,
        comparator: stringCompareInsensitiveCase,
      },
      {
        field: 'requestedBy',
        headerName: 'Client name',
        tooltipField: 'requestedBy',
        minWidth: 150,
      },
      {
        field: 'valuationDate',
        headerName: 'NAV date',
        minWidth: 140,
        cellRenderer: ({ value }) => convertToDateLocal(value),
      },
      {
        field: 'deadLine',
        headerName: 'Deadline',
        minWidth: 140,
        sort: 'desc',
        cellRenderer: ({ value }) => convertToDateLocal(value),
      },
      {
        field: 'chaseUpCount',
        headerName: 'Nb chase up',
        minWidth: 130,
        cellRendererFramework: ({ value, data }) => (
          <ChaseUpCountRenderer value={value} data={data} data-testid={`${testid}-${data.id}-status`} />
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        valueGetter: ({ data: { disseminationState, publicationState } }) =>
          disseminationStateRendererPlain({ disseminationState, publicationState }),
        cellRendererFramework: ({ data: { publicationState, disseminationState, id } }) => (
          <DisseminationStateRenderer
            publicationState={publicationState}
            disseminationState={disseminationState}
            data-testid={`${testid}-${id}-status`}
          />
        ),
      },
      getActionColDef(
        'sharingStatus',
        220,
        ({ data: { id, sharingStatus, status } }) => (
          <PublicationStateRenderer value={{ sharingStatus, status }} data-testid={`${testid}-${id}-sharingStatus`} />
        ),
        { valueGetter: ({ data }) => getPublicationStatePlain(data) },
      ),
    ],
    [testid],
  );

const mergerAuthStatusMapping = [
  {
    label: 'Not disseminated',
    color: colors.dangerColor,
    filter: (row: CollectApi.Dissemination): boolean =>
      (!row.disseminationState && row.status.search('exceeded') === 0) ||
      (!row.disseminationState && row.status === 'due today'),
  },
  {
    label: 'Disseminated',
    color: colors.successColor,
    filter: (row: CollectApi.Dissemination): boolean => !!row.disseminationState,
  },
] as const;

type MergerLabel = typeof mergerAuthStatusMapping[number]['label'];

const mergeAuthStatus = (rowsData: DisseminationGridData[]) =>
  rowsData.reduce((acc, item) => {
    const merger = mergerAuthStatusMapping.find((mrg) => mrg.filter(item));

    if (merger) {
      const { label } = merger;
      if (!(label in acc)) {
        acc[label] = [];
      }
      acc[label].push(item);
    }

    return acc;
  }, {} as Record<MergerLabel, DisseminationGridData[]>);

type DisseminationInventoriesListProps = {
  rowData: CollectApi.Dissemination[];
  searchValue?: string;
  'data-testid': string;
};

export const DisseminationInventoriesList: React.FC<DisseminationInventoriesListProps> = ({
  rowData,
  searchValue,
  'data-testid': testid,
}: DisseminationInventoriesListProps) => {
  const createTestid = useTestid(testid);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [filterClient, setClient] = useState<string>('all');

  const rowDataWithPublishedDelays = rowData.map((obj): DisseminationGridData => {
    if (obj.publishedOn) {
      const publicationState = obj.status === 'on time' ? 'published on time' : 'published late';
      const disseminationState = obj.status === 'sent' || obj.status === 'late' ? 'disseminated' : 'not disseminated';
      return {
        ...obj,
        publicationState,
        disseminationState,
        id: `${obj.fundId}-${obj.valuationDate}`,
      };
    }
    return { ...obj, id: `${obj.fundId}-${obj.valuationDate}` };
  });

  const classes = useStyles();

  const mergedStatuses = mergeAuthStatus(rowDataWithPublishedDelays);

  const countStatuses = (Object.keys(mergedStatuses) as MergerLabel[]).map(
    (statusName): SelectFilterItem<MergerLabel | 'all'> => ({
      value: statusName,
      label: statusName,
      color: mergerAuthStatusMapping.find((status) => status.label === statusName)?.color,
      count: mergedStatuses[statusName].length,
    }),
  );

  const countClient = Object.values(
    rowDataWithPublishedDelays.reduce((acc, row) => {
      const name = row.requestedBy;
      if (acc[name] === undefined) {
        acc[name] = {
          value: name,
          label: name,
          count: 0,
        };
      }
      acc[name].count += 1;
      return acc;
    }, {} as Record<string, SelectFilterItem<string | 'all'>>),
  ).sort((a, b) => (a.label > b.label ? 1 : 0));

  const data = useMemo(() => {
    const found = filterStatus !== 'all' ? mergedStatuses[filterStatus as MergerLabel] : rowDataWithPublishedDelays;

    return filterClient !== 'all'
      ? found.filter((row) => !(filterClient !== 'all' && row.requestedBy !== filterClient))
      : found;
  }, [rowDataWithPublishedDelays, mergedStatuses, filterStatus, filterClient]);

  const onResetFilters = useCallbackImmutable((): void => {
    setFilterStatus('all');
    setClient('all');
  });

  return (
    <>
      <div className={classes.buttons}>
        <div className={classes.exportButton}>
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-export')} />}
            color="primary"
            outlined
            onClick={useCallbackImmutable(() => gridApi?.exportDataAsExcel())}
            size="small"
            data-testid={createTestid('button-export')}
          >
            Export table
          </Button>
        </div>
        <div className={classes.dlMultipleButton}>
          <SelectFilter
            label="Status"
            items={Object.values(countStatuses)}
            onClick={setFilterStatus}
            activeItem={filterStatus}
            data-testid={createTestid('select-status')}
          />
          <div style={{ zIndex: 1000 }}>
            <SelectFilter
              label="Client"
              dropdownItems={countClient}
              onClick={setClient}
              activeItem={filterClient}
              data-testid={createTestid('select-client')}
            />
          </div>
          <div style={{ zIndex: 500 }}>
            <Button
              color="primary"
              outlined
              size="small"
              startIcon={<Icon.Filter data-testid={createTestid('icon-reset')} />}
              onClick={onResetFilters}
              data-testid={createTestid('button-reset')}
            >
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <AggridTable
        quickFilterText={searchValue}
        rowData={data}
        columnDefs={useColumns(`${testid}-columns`)}
        defaultColDef={useDefaultColDef<DisseminationGridData>()}
        getRowNodeId={useImmutable(
          () =>
            ({ id }: DisseminationGridData): string =>
              id,
        )}
        onGridReady={useAutoSize('fit')}
        getGridApiRef={setGridApi}
        data-testid={testid}
        pagination
        paginationAutoPageSize
      />
    </>
  );
};
