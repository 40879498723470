import type { CsvToAggrid } from 'mns-components';
import {
  AggridTable,
  Button,
  Icon,
  SearchField,
  useCallbackImmutable,
  useDefaultColDef,
  useTestid,
} from 'mns-components';
import React, { useState } from 'react';
import { stateAppListStyle as useStyles } from '../styles/stateAppListStyle';

export type StateAppListGridProps = CsvToAggrid<'id'> & {
  searchedValue?: string;
  'data-testid': string;
};

export const StateAppListGrid: React.FC<StateAppListGridProps> = ({
  columnDefs,
  rowData,
  searchedValue,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <div className={classes.aggrid}>
      <AggridTable
        theme="alpine"
        columnDefs={columnDefs}
        defaultColDef={useDefaultColDef<AnyObject<'id', string>>()}
        rowData={rowData}
        quickFilterText={searchedValue}
        paginationPageSize={50}
        data-testid={createTestid('list')}
      />
    </div>
  );
};

export type StateAppListProps = CsvToAggrid<'id'> & {
  handleDownload(): void;
  'data-testid': string;
};

export const StateAppList: React.FC<StateAppListProps> = ({
  columnDefs,
  rowData,
  handleDownload,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [searchedValue, setSearchedValue] = useState('');
  return (
    <>
      <div className={classes.buttons}>
        <SearchField
          value={searchedValue}
          onChange={useCallbackImmutable((value) => setSearchedValue(value))}
          data-testid={createTestid('search')}
        />
        <Button
          color="primary"
          onClick={handleDownload}
          startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
          data-testid={testid}
        >
          Download View
        </Button>
      </div>
      <StateAppListGrid columnDefs={columnDefs} rowData={rowData} searchedValue={searchedValue} data-testid={testid} />
    </>
  );
};
