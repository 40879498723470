import { convertToDateUTC } from 'mns-components';
import type { AnalysisApi, CollectApi } from 'mns-sdk-collect';
import type { QueryObserverOptions, UseQueryResult } from 'react-query';
import { currentMonthLastDate, firstDateAvailable } from '../common/date';
import { useApplicationConfig } from '../common/getApplicationConfig';
import type { AppCode } from '../components/views/appDescriptions';
import { api } from '../store/api';

type PortfoliOptions = {
  startDate?: string | Date;
  endDate?: string | Date;
  portfolioOriginType: CollectApi.PortfolioOriginRequest;
};

export const usePortfoliosRequest = <TSelect = CollectApi.Portfolio[]>(
  { startDate = firstDateAvailable, endDate = currentMonthLastDate, portfolioOriginType }: PortfoliOptions,
  options?: QueryObserverOptions<CollectApi.Portfolio[], unknown, TSelect>,
) =>
  api.inventories.collect.getPortfolios.useQuery(
    { endDate: convertToDateUTC(endDate), startDate: convertToDateUTC(startDate), portfolioOriginType },
    options,
  );

export const usePrefetchAllPortfolios = ({
  staleTime = Infinity,
  ...options
}: QueryObserverOptions<CollectApi.Portfolio[]> = {}) =>
  api.inventories.collect.getPortfolios.usePrequery({ ...options, staleTime });

export const useFetchAllPortfolios = <TSelect = CollectApi.Portfolio[]>(
  options: QueryObserverOptions<CollectApi.Portfolio[], unknown, TSelect> = {},
): UseQueryResult<TSelect> =>
  api.inventories.collect.getPortfolios.useQuery(
    {
      startDate: convertToDateUTC(firstDateAvailable),
      endDate: convertToDateUTC(currentMonthLastDate),
      portfolioOriginType: 'ALL',
    },
    options,
  );

export const useInvalidatePtfCache = () => api.inventories.collect.getPortfolios.useInvalidateRootQuery();

export const useAnalysisPortfoliosRequest = (
  appCode: AppCode,
  options: QueryObserverOptions<AnalysisApi.AnalysisPortfolio<AppCode>[]> = {},
) => {
  const {
    config: { displayOnlyUploadedPortfolios },
  } = useApplicationConfig(appCode);

  const portfolioOriginType = displayOnlyUploadedPortfolios ? 'UPLOADED' : 'ALL';

  return api.provider.analysis.getAnalysisPortfolios.useQuery(appCode, portfolioOriginType, options);
};
