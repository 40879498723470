import { Button, Dialogv2, Icon, useTestid } from 'mns-components';
import type { EmailListApi } from 'mns-sdk-collect';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdateEmailList } from '../hooks';
import { UpdateEmailListForm } from './OrganisationModalForm';

type OrganisationUpdateModalProps = {
  emailList: EmailListApi.EmailList | undefined;
  open: boolean;
  onClose(): void;
  'data-testid': string;
};

export const OrganisationUpdateModal: React.FC<OrganisationUpdateModalProps> = ({
  open,
  onClose,
  emailList,
  'data-testid': testid,
}) => {
  const formName = 'form-email-list-edit';
  const createTestid = useTestid(testid);

  const { mutate: updateEmailList, isSuccess, isError, isLoading } = useUpdateEmailList();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast.success('Email list successfuly update');
    }
    if (isError) {
      onClose();
      toast.error('Failed to update email list');
    }
  }, [isSuccess, onClose, isError]);

  return (
    <Dialogv2
      open={open}
      onClose={onClose}
      dialogTitle="Edit email list"
      DialogTitleIcon={Icon.Edit}
      data-testid={createTestid('dialog')}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoading}
          type="submit"
          form={formName}
          data-testid={createTestid('confirm')}
        >
          Edit email list
        </Button>
      }
    >
      <UpdateEmailListForm emailList={emailList} onSave={updateEmailList} data-testid="edit-modal" formId={formName} />
    </Dialogv2>
  );
};
