import type { ApplicationCardStatus, CategorisedSelectionProps, FormStepErrors, StepProps } from 'mns-components';
import {
  Backdrop,
  ButtonList,
  CategorisedSelection,
  EmptyState,
  Frame,
  convertToDateLocal,
  groupBy,
  useCallbackImmutable,
  useStepperButtons,
  useTestid,
} from 'mns-components';
import type { EetApi, MarketplaceApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getApplicationConfig } from '../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useApplicationsRequest, useMarketplaceAppModal } from '../../marketplace/hooks';
import { useEetEsgAppsRequest, useUpdateEetProviders } from '../hooks';
import { ConfirmNoProviderModal } from './ConfirmNoProviderModal';
import type { AppCategory, GenerateEetFormSteps } from './types';

type Category = CategorisedSelectionProps<AppCategory, AppCode>['list'][number];
type CategoryItem = Category['list'][number];

const getSubscriptionStatus = (
  appData: MarketplaceApi.Application,
  esgApp: EetApi.EetEsgApp<AppCode>,
): ApplicationCardStatus => {
  const status = appData.subscriptionsDto?.status;
  if (appData.hasAlreadySubscribed && !appData.subscribed && status !== 'PENDING') {
    return 'EXPIRED';
  } else if (esgApp.subscribed && status) {
    return status;
  } else {
    return null;
  }
};

const getSelectionList = (
  apps: EetApi.EetEsgApp<AppCode>[],
  applicationDatas: MarketplaceApi.Application[],
  classes: ReturnType<typeof useAppBackgroundStyles>,
) =>
  apps.map((item): CategoryItem => {
    const {
      content: { title: applicationName },
      company: { name: providerName },
    } = getApplicationConfig(item.applicationCode);
    const appData = applicationDatas.find(({ codeApplication }) => codeApplication === item.applicationCode);
    const subscription = appData?.subscriptionsDto;

    return {
      appBackgroundClassName: classes[item.applicationCode],
      applicationCode: item.applicationCode,
      applicationName,
      providerName,
      subscriptionStartDate: subscription?.startDate ? convertToDateLocal(subscription.startDate) : undefined,
      subscriptionStatus: appData ? getSubscriptionStatus(appData, item) : null,
    };
  });

const filterSubscribed = (item: CategoryItem) => item.subscriptionStatus === 'ACTIVATED';

type SelectProvidersStepProps = StepProps<GenerateEetFormSteps, 3>;

export const SelectProvidersStep: React.FC<SelectProvidersStepProps> = ({
  stepValue,
  setStepValue,
  goPrevious,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid!);
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const openMarketplace = useMarketplaceAppModal();

  const { eetId } = useParams<{ eetId: string }>();
  const { data: esgApps, isLoading: loadingEsgApps, error: errorEsgApps } = useEetEsgAppsRequest(eetId);
  const {
    mutate: updateEetProviders,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: errorUpdate,
  } = useUpdateEetProviders(eetId);
  const appClasses = useAppBackgroundStyles();
  const { data: applications } = useApplicationsRequest();

  const [errors, setErrors] = useState<FormStepErrors<GenerateEetFormSteps[3]> | null>(null);

  const list = useMemo((): CategorisedSelectionProps<AppCategory, AppCode>['list'] => {
    if (esgApps && applications) {
      const {
        SFDR: sfdrApps,
        TAXONOMY: taxonomyApps,
        SHARE_OF_SUSTAINABLE_INVESTMENT: sustainApps,
      } = groupBy(esgApps, 'category');
      return [
        {
          label: 'SFDR Data',
          list: getSelectionList(sfdrApps ?? [], applications, appClasses),
          name: 'sfdrApp',
        },
        {
          label: 'EU Taxonomy Data',
          list: getSelectionList(taxonomyApps ?? [], applications, appClasses),
          name: 'taxonomyApp',
        },
        {
          label: 'Share of Sustainable Investment Data',
          list: getSelectionList(sustainApps ?? [], applications, appClasses),
          name: 'shareSustainableInvestmentApp',
        },
      ];
    }
    return [];
  }, [applications, appClasses, esgApps]);

  const handleNext = useCallbackImmutable(async () => {
    setOpenConfirm(false);
    updateEetProviders({
      sfdrApp: stepValue.sfdrApp ?? '',
      taxonomyApp: stepValue.taxonomyApp ?? '',
      shareSustainableInvestmentApp: stepValue.shareSustainableInvestmentApp ?? '',
    });
  });

  const handleConfirm = useCallbackImmutable(() => {
    if (!stepValue.sfdrApp || !stepValue.taxonomyApp || !stepValue.shareSustainableInvestmentApp) {
      setOpenConfirm(true);
    } else {
      handleNext();
    }
  });

  const handleCloseConfirmModal = useCallbackImmutable(() => setOpenConfirm(false));

  useEffect(() => {
    if (isUpdated) {
      goNext();
    }
  }, [isUpdated, goNext]);

  useEffect(() => {
    if (errorUpdate) {
      setErrors([
        {
          name: 'sfdrApp',
          message: 'Not valid application code',
        },
        {
          name: 'taxonomyApp',
          message: 'Not valid application code',
        },
        {
          name: 'shareSustainableInvestmentApp',
          message: 'Not valid application code',
        },
      ]);
    }
  }, [errorUpdate]);

  const buttons = useStepperButtons({
    onNext: handleConfirm,
    onPrevious: goPrevious,
    previousLabel: 'Back',
    nextDisabled: !!errors?.length,
    nextLoading: isUpdating,
    'data-testid': createTestid('button'),
  });

  if (errorEsgApps) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="warning"
          title="Unable to load available providers"
          firstParagraph="The request ended with error, please try to load it again or contact administrator"
          data-testid={createTestid('error-load-providers')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  if (loadingEsgApps) {
    return (
      <>
        <Backdrop data-testid={createTestid('loading')} />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </>
    );
  }

  if (!esgApps?.length) {
    return (
      <Frame variant="margined" data-testid={testid}>
        <EmptyState
          iconEnable
          iconName="info"
          title="No provider available to generate EET"
          firstParagraph="The request ended with error, please contact administrator"
          data-testid={createTestid('error-load-eet-providers')}
        />
        <Buttons>
          <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
        </Buttons>
      </Frame>
    );
  }

  return (
    <Frame variant="margined" data-testid={testid}>
      <CategorisedSelection<AppCategory, AppCode>
        list={list}
        selected={stepValue}
        onSelect={setStepValue}
        onRedirect={openMarketplace}
        filterSubscribed={filterSubscribed}
        padding={2}
        data-testid={createTestid('selection')}
      />
      <ConfirmNoProviderModal
        isSfdrEmpty={!stepValue.sfdrApp}
        isTaxonomyEmpty={!stepValue.taxonomyApp}
        isSsiEmpty={!stepValue.shareSustainableInvestmentApp}
        onClose={handleCloseConfirmModal}
        isOpen={isOpenConfirm}
        onConfirm={handleNext}
        data-testid={createTestid('confirm-modal')}
      />
      <Buttons>
        <ButtonList center buttons={buttons} data-testid={createTestid('buttons')} />
      </Buttons>
    </Frame>
  );
};
