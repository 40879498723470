import { makeStyles } from 'mns-components';

const importCreatePortfolioStyles = makeStyles({
  rootMainTabs: {
    borderBottom: 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTabSelected: {
    '& span': {
      fontWeight: 'bold',
      opacity: '1 !important',
    },
  },
  rootTabStyle: {
    width: '50%',
  },
  indicatorMainTabs: {
    background: '#fe7b70',
    height: '.25rem',
    borderRadius: '5px',
  },
  tabsPanelContainer: {
    flex: '1 1 auto',
    minHeight: 0,
    '& > div:not([hidden])': {
      display: 'flex',
      height: '100%',
      '& > div': {
        flex: '1 1 auto',
        padding: 0,
      },
    },
  },
});

export default importCreatePortfolioStyles;
