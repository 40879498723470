import { Tooltip } from '@mui/material';
import type { IconProps } from 'mns-components';
import { Icon } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';
import { useUser } from '../../hooks/useAuth';

interface AuthAccessStatusProps {
  value?: CollectApi.AccessRequest;
  data: CollectApi.FundPositionToCollect;
  'data-testid': string;
}

const mapping: Record<string, IconProps['color']> = {
  INVITATION_SENT: 'warning',
  PENDING: 'warning',
  REVOKED: 'danger',
  EXIST: 'danger',
  NO_EXISTING: 'danger',
};

const mappingTitle: Record<string, string> = {
  INVITATION_SENT:
    'You have sent an invite to the AM to request him to upload his fund. ' +
    'After that, you will be able to request access to his fund.',
  PENDING:
    'You have requested access to this fund. Once your request is approved, ' +
    'you will get access to the fund inventory and have the option to download it',
  REVOKED:
    'You are not authorized to access this fund inventory. Invite the Asset ' +
    'Manager if the fund is not on Manaos or submit an Access request if the fund is available to get its inventory',
  EXIST:
    'You are not authorized to access this fund inventory. Invite the Asset ' +
    'Manager if the fund is not on Manaos or submit an Access request if the fund is available to get its inventory',
  NO_EXISTING:
    'You are not authorized to access this fund inventory. Invite the Asset ' +
    'Manager if the fund is not on Manaos or submit an Access request if the fund is available to get its inventory',
};

const AuthAccessStatusRenderer: React.FC<AuthAccessStatusProps> = ({ value, data, 'data-testid': testid }) => {
  const orgId = useUser().organisationId;

  if (data.disseminatorPortfolio?.organisationId === orgId) {
    return null;
  }

  let status = value?.status as string;

  if (!data.fundPosition.existOnThePlatform && data.fundPosition.invitationAlreadySent) {
    status = 'INVITATION_SENT';
  } else if (data.fundPosition.existOnThePlatform && !data.accessRequest) {
    status = 'EXIST';
  } else if (!data.fundPosition.existOnThePlatform) {
    status = 'NO_EXISTING';
  }

  const color = mapping[status];
  const title: string = mappingTitle[status];
  if (!color) {
    return null;
  }

  return (
    <Tooltip title={title} placement="right">
      <Icon.Access data-testid={testid} color={color} />
    </Tooltip>
  );
};

export const AuthAccessStatus = React.memo(AuthAccessStatusRenderer);
