import { Stack } from '@mui/material';
import {
  AppContent,
  AppLayout,
  useTestid,
  AppMargin,
  Divider,
  Backdrop,
  useNavigate,
  useCallbackImmutable,
  EmptyStateV2,
} from 'mns-components';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type ApplicationConfig, getFirstApplicationConfigByProvider } from '../../../common/getApplicationConfig';
import { providerNames, type ProviderName } from '../../../components/views/appDescriptions';
import { ProviderAnalysisTable } from './components/ProviderAnalysisTable';
import { ProviderSelector } from './components/ProviderSelector';
import { useUnderlyingDataProviders } from './hooks';

type EsgDataMonitoringProps = {
  'data-testid': string;
};

export const EsgDataMonitoring: React.FC<EsgDataMonitoringProps> = ({ 'data-testid': testid }) => {
  const [providers, setProviders] = useState<ApplicationConfig['company'][] | undefined>();
  const [selectedProvider, setSelectedProvider] = useState<ApplicationConfig['company'] | undefined>();
  const { data, isLoading, isError } = useUnderlyingDataProviders();
  const { providerParam } = useParams<{ providerParam: ProviderName }>();
  const navigate = useNavigate();
  const createTestid = useTestid(testid);

  const isProviderReal = providerNames.includes(providerParam);

  useMemo(() => {
    if (isLoading || !data) return;

    const rawProviders = data
      .map((provider) => getFirstApplicationConfigByProvider(provider.module as ProviderName))
      .map((app) => app?.company);

    setProviders(rawProviders);

    if (rawProviders.length < 1) return;

    if (!providerParam) {
      setSelectedProvider(rawProviders?.[0]);
      navigate('/esg/data-monitoring/' + rawProviders?.[0].providerName);
    } else setSelectedProvider(rawProviders.find((item) => item.providerName === providerParam) ?? undefined);
  }, [data, isLoading, providerParam, navigate]);

  const onChange = useCallbackImmutable((providerName: ProviderName): void => {
    if (providers) {
      const provider = providers.find((p) => p.providerName === providerName)!;
      setSelectedProvider(provider);
      navigate('/esg/data-monitoring/' + provider.providerName);
    }
  });

  const onRedirectMarketplace = useCallbackImmutable(() => {
    navigate('/marketplace/subscribed');
  });

  if (isLoading) {
    return <Backdrop data-testid={createTestid('loading')} />;
  }

  if (isError) {
    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!providers) {
    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="info"
          useCase="noNeededApps"
          title="There is no app subscription yet!"
          subTitle="For this use case, you need to subscribe to ESG apps first."
          buttonText="Discover our app catalog"
          buttonVariant="primary"
          buttonOnClick={onRedirectMarketplace}
          data-testid={createTestid('no-providers')}
        />
      </Stack>
    );
  }

  return (
    <>
      <AppMargin>
        <ProviderSelector
          providers={providers}
          selectedProvider={selectedProvider}
          onChange={onChange}
          data-testid={createTestid('providers')}
        />
        <Divider variant="default" />
      </AppMargin>

      {selectedProvider ? (
        <ProviderAnalysisTable provider={selectedProvider.providerName} data-testid={createTestid('analysis')} />
      ) : (
        <Stack margin="auto">
          <EmptyStateV2
            variant="info"
            useCase={isProviderReal ? 'noActions' : 'noNeededApps'}
            title={isProviderReal ? 'There are no apps with this name!' : 'There are no apps subscriptions yet!'}
            subTitle={
              isProviderReal
                ? 'Select another app from the dropdown list above.'
                : `For this use case, you need to subscribe to additional apps first.`
            }
            {...(!isProviderReal && {
              buttonText: 'Discover our app catalog',
              buttonOnClick: onRedirectMarketplace,
              buttonVariant: 'primary',
            })}
            data-testid={createTestid('no-selected-provider')}
          />
        </Stack>
      )}
    </>
  );
};

export const EsgDataMonitoringLayout: React.FC<EsgDataMonitoringProps> = ({ 'data-testid': testid }) => {
  return (
    <AppLayout>
      <AppContent variant="expanded">
        <EsgDataMonitoring data-testid={testid} />
      </AppContent>
    </AppLayout>
  );
};
