import { makeOpenModal, useCallbackImmutable } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import type { UseMutationOptions } from 'react-query';
import type { AppCode } from '../../components/views/appDescriptions';
import { useUser } from '../../hooks/useAuth';
import { api } from '../../store/api';
import { getMarketplaceRoute } from './routes';

export const useApplicationsRequest = () => api.marketplace.getApplications.useQuery(useUser().organisationId);

export const useApplicationsSubscribed = (orgId?: string) =>
  api.marketplace.getApplications.useQuery(orgId!, {
    select: (list) =>
      list
        .filter((appData) => appData.currentUserAuthorized)
        .map((appData) => `APP_${appData.codeApplication.toUpperCase()}`),
    enabled: !!orgId,
  });
export const useFetchAppDetails = (appCode: AppCode) =>
  api.marketplace.getApplications.useQuery(useUser().organisationId, {
    select: useCallbackImmutable((list: MarketplaceApi.Application[]) =>
      list?.find((appData) => appData.codeApplication === appCode),
    ),
  });

export const useFetchAppOffers = (appId?: string) =>
  api.marketplace.getApplicationOffers.useQuery(appId!, { enabled: !!appId });
export const usePrefetchCardModal = () => api.marketplace.getApplicationOffers.usePrequery();

export const useAddOfferSubscription = (
  options?: UseMutationOptions<void, unknown, [MarketplaceApi.AddOfferSubscriptionRequest]>,
) => {
  const inv = api.marketplace.getApplications.useInvalidateRootQuery();
  return api.marketplace.addOfferSubscription.useMutation({
    onSuccess: inv,
    ...options,
  });
};

export const useTermsAndConditionsRequest = () => api.account.getTermsAgrement.useQuery(useUser().organisationId);
export const useAcceptTermsAndConditions = () => api.account.postTermsAgrement.useMutation();

export const [useMarketplaceAppModal, useManageMarketplaceAppModal] = makeOpenModal<void, AppCode>(
  () => getMarketplaceRoute('marketplace-all').link,
  'appModal',
);
