import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Backdrop,
  convertToDateTimeLocal,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { QualitiesRenderer } from '../../../../components/cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../../../components/headerRender/QualitiesHeaderRenderer';
import { useDataDelivery } from '../hooks';
import { deliveryDetailsDialogProdRangeStyles as useStyles } from '../styles/deliveryDetailsDialogProdRangeStyles';

const plural = (value: number) => (value > 1 ? 's' : '');

const useEmailColumns = () =>
  useMemo(
    (): AggridTableColumn<DataDeliveryApi.DataDeliveryEmail>[] => [
      {
        headerName: 'Email',
        field: 'recipient',
      },
    ],
    [],
  );

type DeliveryDetailsEmailListProps = {
  rowData: DataDeliveryApi.DataDeliveryEmail[];
  'data-testid': string;
};

const DeliveryDetailsEmailList: React.FC<DeliveryDetailsEmailListProps> = ({ rowData, 'data-testid': testid }) => (
  <AggridTable<DataDeliveryApi.DataDeliveryEmail>
    rowData={rowData}
    columnDefs={useEmailColumns()}
    defaultColDef={useDefaultColDef()}
    getRowNodeId={useImmutable(() => (row: DataDeliveryApi.DataDeliveryEmail) => row.recipient)}
    onGridReady={useAutoSize('fit')}
    data-testid={testid}
  />
);

const usePortfolioColumns = (testid: string) =>
  useMemo(
    (): AggridTableColumn<DataDeliveryApi.Scope>[] => [
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Qualities',
        field: 'qualities',
        cellRendererFramework: ({ value = [], data: { identification } }) => (
          <QualitiesRenderer qualities={value} data-testid={`${testid}-${identification}-qualities`} />
        ),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={`${testid}-qualities`} />,
        minWidth: 100,
      },
      {
        headerName: 'General reference date',
        field: 'productionDate',
      },
    ],
    [testid],
  );

type DeliveryDetailsScopeListProps = {
  rowData: DataDeliveryApi.Scope[];
  'data-testid': string;
};

const DeliveryDetailsScopeList: React.FC<DeliveryDetailsScopeListProps> = ({ rowData, 'data-testid': testid }) => (
  <AggridTable<DataDeliveryApi.Scope>
    rowData={rowData}
    defaultColDef={useDefaultColDef()}
    columnDefs={usePortfolioColumns(`${testid}-columns`)}
    getRowNodeId={useImmutable(() => (row: DataDeliveryApi.Scope) => row.identification)}
    onGridReady={useAutoSize('fit')}
    data-testid={testid}
  />
);

type DeliveryDetailsDialogProdRangeProps = {
  deliveryId: string;
  'data-testid': string;
};

export const DeliveryDetailsDialogProdRange: React.FC<DeliveryDetailsDialogProdRangeProps> = ({
  deliveryId,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const { data: deliveryDetails, isLoading: isExecutingDeliveryDetails } = useDataDelivery(deliveryId);

  if (isExecutingDeliveryDetails || !deliveryDetails) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <>
      <div className={classes.mainLabel}>{deliveryDetails.info.title}</div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created on:</span>
        <span className={classes.itemValue}>{convertToDateTimeLocal(deliveryDetails.info.createdAt)}</span>
      </div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created by:</span>
        <span className={classes.itemValue}>{deliveryDetails.info.createdBy}</span>
      </div>

      <div className={classes.mainLabel}>
        {deliveryDetails.scopes.length} file{plural(deliveryDetails.scopes.length)}
      </div>

      <DeliveryDetailsScopeList rowData={deliveryDetails.scopes} data-testid={createTestid('list-scopes')} />

      <div className={classes.mainLabel}>
        {deliveryDetails.emails.length} recipient{plural(deliveryDetails.emails.length)}
      </div>

      <DeliveryDetailsEmailList rowData={deliveryDetails.emails} data-testid={createTestid('list-emails')} />
    </>
  );
};
