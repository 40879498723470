import { offset } from '@floating-ui/dom';
import type { GuidedStepOptions } from 'mns-components';
import {
  GuidedStep,
  Image,
  Typography,
  Video,
  colors,
  filterTruthy,
  makeStyles,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { useUploadModalOpen } from '../../../applications/collectionApp/hooks';
import { useGuidedTour } from '../../../hooks/useGuidedTour';
import type { GuidedTourScenarioBuild } from '../types';
import { getAsset, waitForNode } from '../utils';
const logoStyles = makeStyles({
  show: {
    backgroundImage: `url(${getAsset('collection', 'collection-welcome-user.svg')})`,
  },
});

const testid = 'collectionScenario';

export const collectionScenario: GuidedTourScenarioBuild = ({ roles }) => {
  const displayDashboard = roles.includes('APP_BETA');
  const progressionCount = displayDashboard ? 4 : 3;
  return filterTruthy<GuidedStepOptions>([
    // step 1
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goNext,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Bottom
            title="Welcome to Collection by Manaos!"
            backLabel="Skip the tour"
            nextLabel="Start the tour"
            onBack={goLast}
            onNext={goNext}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              A quick tutorial to explain how to take the first steps on the Collection application.
            </Typography>
            <Image
              marginTop="2rem"
              height="15rem"
              backgroundClassname={logoStyles().show}
              data-testid={createTestid('image')}
            />
          </GuidedStep.Bottom>
        );
      },
      options: {
        arrow: false,
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="collection-help-button"]');
        },
        floatingUIOptions: {
          middleware: [offset({ crossAxis: 100 })],
        },
        attachTo: { on: 'bottom-end' },
      },
    },
    // step 2
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Get started by uploading your portfolios"
            progressionCount={progressionCount}
            progressionValue={0}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              To start collecting fund inventories, please{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={500}>
                upload
              </Typography>{' '}
              your investment portfolios.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="340px"
              sources={[
                {
                  src: getAsset('collection', 'collection-getStratedByUploadingYourPortfolios.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="collection-button-upload-portfolio"]');
        },
        attachTo: { on: 'bottom-end', element: '[data-stepid="collection-button-upload-portfolio"]' },
      },
    },
    // step 3
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Collect the inventories you need"
            progressionCount={progressionCount}
            progressionValue={1}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                Request access
              </Typography>{' '}
              to inventories data from your{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                Asset Managers (AMs)
              </Typography>
              . If resquested fund inventories are unvailable on the platform, invite AMs to disseminate them via email.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="340px"
              sources={[
                { src: getAsset('collection', 'collection-collectTheInventoriesYouNeed.mp4'), type: 'video/mp4' },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="tab-collection-inventories"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="tab-collection-inventories"]' },
        floatingUIOptions: {
          middleware: [offset({ crossAxis: -70, mainAxis: 20 })],
        },
      },
    },
    // step 4
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Chase AMs to collect on time"
            progressionCount={progressionCount}
            progressionValue={2}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              If inventories are not dessiminate on time, send your AMs a reminder by hitting the{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                chase up
              </Typography>{' '}
              button.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="355px"
              sources={[{ src: getAsset('collection', 'collection-chaseAMsToCollectOnTime.mp4'), type: 'video/mp4' }]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        attachTo: { on: 'bottom-end', element: '[data-stepid="tab-collection-inventories"]' },
        floatingUIOptions: {
          middleware: [offset({ crossAxis: 70, mainAxis: 20 })],
        },
      },
    },
    // step 5
    displayDashboard && {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Keep track of your collection process"
            progressionCount={progressionCount}
            progressionValue={3}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Glance at{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                your priority tasks
              </Typography>
              ,{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                the progress of your monthly
              </Typography>{' '}
              collection and the latest inventories received.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="355px"
              sources={[
                { src: getAsset('collection', 'collection-keepTrackOnYourCollectProcess.mp4'), type: 'video/mp4' },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="tab-collection-dashboard"]');
        },
        attachTo: { on: 'bottom-end', element: '[data-stepid="tab-collection-dashboard"]' },
      },
    },
    //step 6
    {
      element: function Step() {
        const {
          state: { stepIndex },
          complete,
          goLast,
        } = useGuidedTour('collection');
        const createTestid = useTestid(testid);
        const navigateUpload = useUploadModalOpen();
        const goUpload = useCallbackImmutable(() => {
          complete();
          navigateUpload();
        });
        return (
          <GuidedStep.Bottom
            title="Give it a go!"
            backLabel="Close"
            nextLabel="Upload portfolios"
            onBack={goLast}
            onNext={goUpload}
            nextIcon="upload"
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Now that you know the basics of Collection by Manaos, it&apos;s time to upload your first portfolio!
            </Typography>
            <Image
              marginTop="2rem"
              height="15rem"
              backgroundClassname={logoStyles().show}
              data-testid={createTestid('image')}
            />
          </GuidedStep.Bottom>
        );
      },
      options: {
        arrow: false,
        version: 2401,
        attachTo: { on: 'bottom-end' },
      },
    },
    // last step
    {
      element: function Step() {
        const {
          state: { stepIndex },
          complete,
        } = useGuidedTour('collection');
        return (
          <GuidedStep.Tooltip title="Guided Tours" onClose={complete} data-testid={testid} data-stepindex={stepIndex}>
            Find the guided tours at any time by clicking this icon!
          </GuidedStep.Tooltip>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="collection-help-button"]');
        },
        attachTo: { on: 'bottom', element: '[data-stepid="collection-help-button"]' },
      },
    },
  ]);
};
