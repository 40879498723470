import { useTestid } from 'mns-components';
import { ContactListSettingForm } from '../../../common/ContactListSetting';
import { settingsStyle as useStyles } from '../settingsStyle';

export const EmailApplicationSettings: React.FC<{ 'data-testid': string }> = ({
  'data-testid': testid = 'email-app-settings',
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div data-testid={testid} className={classes.settingSection}>
      <h2 className={classes.settingsCategory}>File delivery email settings</h2>
      <div data-testid={testid}>
        <div className={classes.descrColumn}>
          <div className={classes.settingsName}>Recipients</div>
          <div className={classes.settingsDescription}>
            Enter the email addresses that will be eligible to receive your files outside of the Manaos platform.
          </div>
        </div>

        <ContactListSettingForm
          className={classes.wideColumn}
          notifCode="email_out"
          data-testid={createTestid('create-list-setting')}
        />
      </div>
    </div>
  );
};
