import { floatToPercent, useTestid } from 'mns-components';
import type { ProviderApi } from 'mns-sdk-collect';
import { formStyles as useStyles } from './formStyles';

const validPercent = (num: unknown) => (typeof num === 'number' ? floatToPercent(num) : 'Not calculated yet');

type PortfolioDetailsProps = { portfolio: ProviderApi.Portfolio; 'data-testid': string };

export const PortfolioDetails: React.FC<PortfolioDetailsProps> = ({ portfolio, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div data-testid={testid} className={classes.form}>
      <h3 className={classes.formTitle}>
        <span> Portfolio details</span>
        <hr />
      </h3>
      <label>
        <span>Portfolio ID:</span>
        <span data-testid={createTestid('externalId')}>{portfolio.externalId.value}</span>
      </label>
      <label>
        <span>Portfolio name:</span>
        <span data-testid={createTestid('portfolioName')}>{portfolio.name}</span>
      </label>
      <label>
        <span>NAV Date:</span>
        <span data-testid={createTestid('valuationDate')}>{portfolio.valuationDate}</span>
      </label>
      <label>
        <span>Number of securities:</span>
        <span data-testid={createTestid('numberOfLines')}>{portfolio.numberOfLines}</span>
      </label>
      <label>
        <span>Look-through rate:</span>
        <span data-testid={createTestid('lookThroughRate')}>{validPercent(portfolio.lookThroughRate)}</span>
      </label>
      <label>
        <span>Coverage rate:</span>
        <span data-testid={createTestid('providerCoverage')}>{validPercent(portfolio.providerCoverage)}</span>
      </label>
    </div>
  );
};
