import type { CSSObject } from 'mns-components';
import { objectMap, makeStyles } from 'mns-components';
import type { AppCode, ProviderName } from '../components/views/appDescriptions';
import { appCodes, providerNames } from '../components/views/appDescriptions';
import { getApplicationConfig, getFirstApplicationConfigByProvider } from './getApplicationConfig';

export const getImageContent = (filename: string): string => {
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require, @typescript-eslint/no-var-requires
    return require(`../assets/images/application-catalog/${filename}`);
  } catch (e) {
    throw new Error(`The asset ${JSON.stringify(filename)} is not found`);
  }
};

export const providersIconPath = providerNames.reduce(
  (acc, providerName) => ({
    ...acc,
    [providerName]: getFirstApplicationConfigByProvider(providerName).company.logoUrl,
  }),
  {} as Record<ProviderName, string>,
);

export const useProvidersIconBackgroundStyles = makeStyles(
  objectMap(providersIconPath, (path) => ({ backgroundImage: `url(${getImageContent(path)})` })),
);

export const useProviderIconBackgroundStyle = (providerName: ProviderName): string =>
  useProvidersIconBackgroundStyles()[providerName];

const appCodeToBackgroundCss = appCodes.reduce((acc, appCode) => {
  const {
    company: { logoUrl },
  } = getApplicationConfig(appCode);
  const assetPath = getImageContent(logoUrl);
  acc[appCode] = { backgroundImage: `url('${assetPath}')` } as CSSObject;
  return acc;
}, {} as Record<AppCode, CSSObject>);

export const useAppBackgroundStyles = makeStyles(appCodeToBackgroundCss);

export const useAppBackgroundClassName = (appCode: AppCode) => {
  const classes = useAppBackgroundStyles();
  return classes[appCode];
};
