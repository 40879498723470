import { Stack } from '@mui/material';
import { BigKpiCard, CardLogoTitle, useTestid } from 'mns-components';
import React from 'react';
import { SelectorPtfAndDate } from '../../../applications/coverageApp/components/SelectorPtfAndDate';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import { usePtfAndDate } from '../../../hooks/useGlobalState';
import { usePortfoliosRequest } from '../../../hooks/usePortfoliosRequest';
import { useGetInventoriesToCollect } from '../hooks';

type InventoriesKpisProps = { 'data-testid': string };

export const InventoriesKpis: React.FC<InventoriesKpisProps> = ({ 'data-testid': testid }: InventoriesKpisProps) => {
  const createTestid = useTestid(testid);

  const [[portfolioId, valuationDate]] = usePtfAndDate();
  const { data: inventoriesToCollectData } = useGetInventoriesToCollect(portfolioId ?? '', valuationDate ?? '');
  const details = inventoriesToCollectData?.fundToCollectDetailsDtos;

  const { data: portfolios } = usePortfoliosRequest({ portfolioOriginType: 'UPLOADED' });

  const appBackgroundClassName = useAppBackgroundStyles()['manaos-coverageestimation'];

  return (
    <Stack direction="row" gap="1rem" alignItems="center" data-testid={testid}>
      <Stack direction="row" gap="1rem" alignItems="center">
        <CardLogoTitle
          company="Manaos"
          appName="Collection"
          appBackgroundClassName={appBackgroundClassName}
          variant="filled"
          data-testid={createTestid('card')}
        />
        <BigKpiCard
          iconName="formatListBullet"
          indicatorNumber={details?.length ?? 0}
          indicatorDescription="Shareclasses detected"
          data-testid={createTestid('kpis')}
        />
      </Stack>
      {portfolios?.[0] && <SelectorPtfAndDate portfolios={portfolios} data-testid={createTestid('picker')} />}
    </Stack>
  );
};
