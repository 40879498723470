import { Box } from '@mui/material';
import type { FormProps } from 'mns-components';
import { Button, Link, TextField, Typography, colors, makeForm, useCallbackImmutable, useTestid } from 'mns-components';
import { useFormContext } from 'react-hook-form';
import { useHandleForgotUsername } from '../../hooks/useAuth';
import { AuthPageLayout } from './AuthPageLayout';
import { authStyles as useStyles } from './authStyles';
import { useRedirectOnSuccess } from './hooks';
import { routes } from './routes';
import type { ForgotUsernameFormData } from './types';
import { getForgotUsernameErrors } from './utils';

type ForgotUsernameInputsProps = Omit<FormProps<ForgotUsernameFormData>, 'children'> & { error?: Error };

const ForgotUsernameInputs: React.FC<ForgotUsernameInputsProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);

  const {
    formState: { isSubmitting },
  } = useFormContext<ForgotUsernameFormData>();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <TextField name="email" type="text" label="email" autoComplete="email" data-testid={createTestid('email')} />
      </div>
      <Box margin="0.5rem">
        <Button color="secondary" type="submit" loading={isSubmitting} data-testid={createTestid('submit')}>
          Retrieve your username
        </Button>
      </Box>
    </>
  );
};

const ForgotUsernameForm = makeForm<ForgotUsernameFormData, ForgotUsernameInputsProps>(ForgotUsernameInputs);

export const ForgotUsername: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const forgotUsername = useHandleForgotUsername();
  const [hasSucceded, setSucceded] = useRedirectOnSuccess(routes.appEntryUri.path);

  const handleChangeUsername = useCallbackImmutable(async ({ email }: ForgotUsernameFormData) => {
    if (email) {
      await forgotUsername(email);
      setSucceded(true);
    }
  });

  const handleValidation = useCallbackImmutable(getForgotUsernameErrors);

  if (hasSucceded) {
    return (
      <AuthPageLayout data-testid={testid}>
        <Typography variant="body1">
          Provided you have a registered account for this email, your username(s) will be sent shortly. You are now
          going to be redirected.
        </Typography>
        <Link to={routes.loginUri.path} className={classes.link} data-testid={createTestid('success-redirect')}>
          Redirect to login
        </Link>
      </AuthPageLayout>
    );
  }

  return (
    <AuthPageLayout data-testid={testid}>
      <Box margin="0.5rem">
        <Typography variant="h1">Forgot username</Typography>
        <Typography variant="body1" margin="1rem 0" color={colors.disabledColor}>
          Enter the email associated with your account and we’ll send an email with your username(s)
        </Typography>
      </Box>
      <ForgotUsernameForm
        onSubmit={handleChangeUsername}
        onValidate={handleValidation}
        data-testid={createTestid('form')}
      />
    </AuthPageLayout>
  );
};
