import widgetClarity from '@clarity-ai/widget';
import type { WidgetApi } from 'mns-sdk-collect';
import { useEffect, useRef } from 'react';
import { CLARITY_WIDGET_URL } from '../../../../../app.constants';
import type { WidgetConfig } from '../WidgetApp';

const customizationObject = JSON.stringify({
  tables: {
    headerBgColor: '#ddd',
    bodyBgColor: '#fff',
    borderColor: '#ddd',
  },
  padding: '0',
});

export type ClarityWidgetProps = {
  widgetConfig: WidgetConfig;
  widgetDoc: WidgetApi.Document;
  'data-testid': string;
};

export const ClarityWidget: React.FC<ClarityWidgetProps> = ({ widgetConfig, widgetDoc, 'data-testid': testid }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (CLARITY_WIDGET_URL) {
      widgetClarity.load(CLARITY_WIDGET_URL);
    }
  }, []);

  useEffect(() => {
    if (widgetDoc?.widgetCredentials && containerRef.current) {
      widgetClarity.refresh(containerRef.current, () => {
        // do nothing
      });
    }
  }, [widgetDoc?.widgetCredentials]);

  return (
    <div
      {...widgetConfig.attributes}
      data-portfolio={widgetDoc.providerPortfolioId}
      data-token={widgetDoc.widgetCredentials}
      ref={containerRef}
      data-style-attributes-json={customizationObject}
      data-testid={testid}
    />
  );
};
