import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import {
  AppContent,
  AppLayout,
  Backdrop,
  EmptyStateV2,
  Form,
  snakeCase,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useAnalysisByAppCodes, useFetchEetFilesOld } from '../hooks';
import { getRoute } from '../routes';
import { AggregatedDetailsRequester } from './AggregatedDetailsRequester';
import type { FormData } from './AnalysisDetailsController';
import { AnalysisDetailsController } from './AnalysisDetailsController';
import { analysisDetailsStyles as useStyles } from './analysisDetailsStyles';
import { UnderlyingDetailsRequester } from './UnderlyingDetailsRequester';

export const AnalysisDetailsContent: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  // form
  const { type: typeView, appCode, externalId: ptfId, navDate } = useWatch<FormData>();

  // init data
  const {
    data: eetList,
    isLoading: isLoadingEetList,
    isError: isErrorEetList,
  } = useFetchEetFilesOld({
    select: (data) => data.filter(({ status }) => status === 'COMPLETED'),
  });

  const appCodes = useMemo(
    () =>
      !eetList
        ? undefined
        : Array.from(
            eetList.reduce((acc, { sfdrApp, shareSustainableInvestmentApp: ssiApp, taxonomyApp }) => {
              if (Array.isArray(sfdrApp)) sfdrApp.forEach((app) => acc.add(app));
              if (Array.isArray(ssiApp)) ssiApp.forEach((app) => acc.add(app));
              if (Array.isArray(taxonomyApp)) taxonomyApp.forEach((app) => acc.add(app));
              return acc;
            }, new Set<AppCode>()),
          ),
    [eetList],
  );

  const {
    data: analysisList,
    isLoading: isLoadingAnalysisQueries,
    isError: isErrorAnalysisQueries,
  } = useAnalysisByAppCodes(appCodes ?? []);

  // buttons
  const handleExport = useCallbackImmutable(() => {
    if (gridApi && typeView && ptfId && navDate && appCode) {
      const typeName = typeView === 'aggregated' ? 'aggregated_data' : 'underlying_data';
      gridApi.exportDataAsCsv({
        fileName: `${typeName}-${snakeCase(ptfId)}-${navDate}-${appCode}.csv`,
      });
    }
  });

  if (isLoadingEetList || isLoadingAnalysisQueries) {
    return <Backdrop data-testid={createTestid('loading')} />;
  }

  if (isErrorAnalysisQueries || isErrorEetList) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact support team."
          data-testid={createTestid('empty-eetList')}
        />
      </Stack>
    );
  }

  if (!analysisList.length || !appCodes?.length) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="actions"
          variant="info"
          title="Get started with your EET!"
          subTitle="Start creating your EET to analyse your portfolio(s)."
          buttonVariant="secondary"
          buttonIcon="add"
          buttonHref={generatePath(getRoute('generate-eet').link)}
          buttonText="Create EET"
          data-testid={createTestid('empty-eetList')}
        />
      </Stack>
    );
  }

  return (
    <Stack flex="1 1 0" gap="1.5rem" data-testid={testid}>
      <AnalysisDetailsController
        appCodes={appCodes}
        onExport={gridApi ? handleExport : undefined}
        data-testid={createTestid('controller')}
      />
      {typeView === 'aggregated' && (
        <AggregatedDetailsRequester
          analysisList={analysisList}
          getGridApiRef={setGridApi}
          data-testid={createTestid('aggregated')}
        />
      )}
      {typeView === 'underlying' && (
        <UnderlyingDetailsRequester
          analysisList={analysisList}
          getGridApiRef={setGridApi}
          data-testid={createTestid('underlying')}
        />
      )}
    </Stack>
  );
};

export const AnalysisDetails: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <AppLayout data-testid={testid}>
    <AppContent>
      <Form<FormData>
        className={useStyles().form}
        defaultValues={{ type: 'underlying' }}
        data-testid={`${testid}-form`}
      >
        <AnalysisDetailsContent data-testid={testid} />
      </Form>
    </AppContent>
  </AppLayout>
);
