import { Typography, useTestid } from 'mns-components';
import { ContactListSettingForm } from '../../../common/ContactListSetting';
import { chaseUpSettingsStyles as useStyles } from './chaseUpSettingsStyles';

const NOTIF_CODE = 'chase_up';

type ChaseUpSettingsProps = { 'data-testid': string };

export const ChaseUpSettings: React.FC<ChaseUpSettingsProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <>
      <Typography variant={'h2'} className={classes.settingsCategory}>
        Chase up contacts
      </Typography>
      <div data-testid={testid} className={classes.twoColumns}>
        <div className={classes.descrColumn}>
          <p className={classes.info}>
            Add contacts to notify when investors chase up your organisation to disseminate data.
          </p>
          <p className={classes.warn}>
            Please note the collectors will see these emails when they chase up inventories.
          </p>
        </div>
        <ContactListSettingForm
          className={classes.wideColumn}
          notifCode={NOTIF_CODE}
          data-testid={createTestid('form')}
        />
      </div>
    </>
  );
};
