import { colors, makeStyles } from 'mns-components';

export const uploadModalStyles = makeStyles({
  dropZone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexDirection: 'column',
    border: `4px dashed ${colors.primaryColor}`,
    borderRadius: '12px',
    margin: '2rem auto',
    padding: '2rem',
    minHeight: '12rem',
    height: '25vh',
    textAlign: 'center',
    cursor: 'pointer',

    '& p': {
      margin: 0,
      textAlign: 'center',
    },
  },
  fakeLink: {
    color: colors.primaryColor,
  },
  icon: {
    color: colors.primaryColor,
    fontSize: '6rem',
    margin: '1rem',
  },
  description: {
    opacity: '.4',
    fontSize: '.75rem',
    paddingTop: '.5rem',
  },
  failListContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    color: colors.dangerColor,
    background: colors.dangerBackColor,
    paddingBottom: '.5rem',
  },
  failListHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 .5rem',
  },
  failList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    flex: '1 1 auto',
    minWidth: 0,
  },
  failListItem: {
    margin: '.5rem 1rem',
    '& > strong': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
});

export const uploadModalFilesStyles = makeStyles({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    margin: '.25rem 0',
    '& > svg': {
      padding: '.5rem',
      width: '1.5rem',
      height: '1.5rem',
    },
    '& > span': {
      flex: '1 1 auto',
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  ready: {
    color: colors.primaryColor,
    background: colors.primaryBackColor,
  },
  successed: {
    color: colors.successColor,
    background: colors.successBackColor,
  },
  // update to add failedUpload too
  failedUpload: {
    color: colors.dangerColor,
    background: colors.dangerBackColor,
  },
  failed: {
    color: colors.dangerColor,
    background: colors.dangerBackColor,
  },

  disabled: {
    color: colors.disabledColor,
    background: colors.disabledBackColor,
  },
});

export const dialogStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  modalContent: {
    flex: '1 1 auto',
    minHeight: 0,
    overflow: 'clip auto',
    padding: '0 1.5rem 1.5rem 1.5rem',
  },
  modalButtons: {
    flex: '0 1 auto',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    borderTop: `1px solid ${colors.borderColor}`,
    padding: '1rem',
  },
  createPtfOr: {
    opacity: '.4',
    fontSize: '.75rem',
    margin: 0,
  },
  templateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
});
