import { Stack, Tooltip } from '@mui/material';
import { HeaderComponentFramework, Icon, useCallbackImmutable } from 'mns-components';
import React from 'react';
import { headerRendererStyles as useStyles } from './styles/HeaderRenderer.styles';

type HeaderRendererProps = { tooltip: string; children: string; 'data-testid': string };

export const HeaderRenderer: React.FC<HeaderRendererProps> = ({ tooltip, children, 'data-testid': testid }) => {
  const classes = useStyles();

  return (
    <HeaderComponentFramework
      render={useCallbackImmutable(({ getLabelNode }) => (
        <Stack direction="row" spacing=".5rem" alignItems="center">
          {getLabelNode(children)}
          <Tooltip
            arrow
            title={tooltip}
            placement="right-start"
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <Icon.Info size="small" color="secondary" data-testid={`${testid}-icon-info`} />
          </Tooltip>
        </Stack>
      ))}
      data-testid={testid}
    />
  );
};
