import {
  appCollectApi,
  dataDeliveryApi,
  isinListsApi,
  emailListApi,
  eetApi,
  paiApi,
  etlApi,
  looktroughApi,
  appMarketplaceApi,
  appNotification,
  analysisApi,
  appProviderApi,
  coverageApi,
  widgetApi,
  appBp2s,
  accountApi,
  appProviderClaritySfdr,
  dataExtractorApi,
  datalakeApi,
  accountNoAuthApi,
  downloadApi,
} from './apis';

export const apiStruct = {
  inventories: {
    collect: appCollectApi,
    dataDelivery: dataDeliveryApi,
    isinList: isinListsApi,
    emailList: emailListApi,
  },
  provider: {
    analysis: analysisApi,
    portfolio: appProviderApi,
    coverage: coverageApi,
    widget: widgetApi,
    bp2s: appBp2s,
    download: downloadApi,
  },
  account: accountApi,
  claritySfdr: appProviderClaritySfdr,
  dataExtractor: dataExtractorApi,
  datalake: datalakeApi,
  eet: eetApi,
  etl: etlApi,
  loggedOut: accountNoAuthApi,
  looktrough: looktroughApi,
  marketplace: appMarketplaceApi,
  notification: appNotification,
  pai: paiApi,
};
