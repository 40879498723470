import { CircularProgress, Stack } from '@mui/material';
import type { StepProps } from 'mns-components';
import {
  Checkbox,
  EmptyState,
  groupBy,
  lowerCase,
  SearchField,
  Typography,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import { useEffect, useMemo, useState } from 'react';
import { useGetPtfColumns } from '../../../../api/extractionsData';
import { useTemplateDetails } from '../../../../api/templateData';
import type { SelectCategoryCard } from '../components/SelectCategoriesList';
import { SelectCategoriesList } from '../components/SelectCategoriesList';
import { SelectCategory } from '../components/SelectCategory';
import { SelectDatapointsList } from '../components/SelectDatapointsList';
import { CreateTemplateButtons } from '../CreateTemplateButtons';
import { createTemplateStyles as useStyles } from '../createTemplateStyles';
import type { CreateTemplateSteps } from '../types';

export const PortfolioDataPointsStep: React.FC<StepProps<CreateTemplateSteps, 1>> = ({
  stepValue: { portfolioDataPoints, selectedCategory },
  stepValues: [{ templateId }],
  setStepValue,
  goPrevious,
  goNext,
  Buttons,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const [search, setSearch] = useState('');
  const { data: metadatas, isLoading: isLoadingMetadatas, isError: isErrorMetadatas } = useGetPtfColumns();
  const { data: templateDetails, isLoading: isLoadingTemplateDetails } = useTemplateDetails(templateId, {
    staleTime: Infinity,
  });

  const preSelectedDatapoints = useMemo(
    () => (!templateDetails ? [] : templateDetails.columns.map(({ formula: { column } }) => column)),
    [templateDetails],
  );

  const grouped = useMemo(() => {
    if (metadatas) {
      const lowerSearch = lowerCase(search);
      const metadatasSearch = !search
        ? metadatas
        : metadatas.filter(
            (col) => lowerCase(col.name).includes(lowerSearch) || lowerCase(col.category).includes(lowerSearch),
          );
      return groupBy(metadatasSearch, 'category');
    }
    return {};
  }, [metadatas, search]);

  const categories = useMemo(
    () =>
      Object.keys(grouped).map(
        (category): SelectCategoryCard => ({
          name: category,
          countDatapoints: grouped[category]?.length ?? 0,
          countSelectedDatapoints: portfolioDataPoints.reduce(
            (acc, dp) => (grouped[category]?.find(({ code }) => code === dp) ? acc + 1 : acc),
            0,
          ),
        }),
      ),
    [grouped, portfolioDataPoints],
  );

  const datapoints = useMemo(
    () => (selectedCategory && Array.isArray(grouped[selectedCategory]) ? grouped[selectedCategory]! : []),
    [grouped, selectedCategory],
  );

  const selectedDatapoints = useMemo(
    () =>
      selectedCategory && Array.isArray(grouped[selectedCategory])
        ? grouped[selectedCategory]!.filter((dp) => dp.required || portfolioDataPoints.includes(dp.code)).map(
            (dp) => dp.code,
          )
        : [],
    [grouped, selectedCategory, portfolioDataPoints],
  );

  const requiredDatapoints = useMemo(
    () =>
      selectedCategory && Array.isArray(grouped[selectedCategory])
        ? grouped[selectedCategory]!.filter((dp) => dp.required).map((dp) => dp.code)
        : [],
    [grouped, selectedCategory],
  );

  const setDatapoints = useCallbackImmutable((fn: (current: string[]) => string[]) => {
    setStepValue(({ portfolioDataPoints: current, selectedCategory: cat }) => ({
      portfolioDataPoints: Array.from(new Set([...fn(current), ...requiredDatapoints])),
      selectedCategory: cat,
    }));
  });

  const onCheckAll = useCallbackImmutable(() => {
    setDatapoints((current) =>
      !metadatas?.length || current.length >= metadatas.length ? [] : metadatas.map((md) => md.code),
    );
  });

  const onCategoryCheckAll = useCallbackImmutable((category: string) => {
    const categoryColumns = grouped[category];
    setDatapoints((current) =>
      !categoryColumns?.length || !categoryColumns.find((col) => !current.includes(col.code))
        ? current.filter((md) => !categoryColumns?.find((col) => col.code === md))
        : [...current, ...categoryColumns.map((md) => md.code)],
    );
  });

  const onDatapointCheck = useImmutable(() => (datapoint: string) => {
    setDatapoints((current) => {
      const list = current.slice();
      const index = list.indexOf(datapoint);
      if (index < 0) {
        list.push(datapoint);
      } else {
        list.splice(index, 1);
      }
      return list;
    });
  });

  const setSelectedCategory = useCallbackImmutable((cat: string) =>
    setStepValue((current) => ({ portfolioDataPoints: current.portfolioDataPoints, selectedCategory: cat })),
  );

  // when columns & selected template columns are loaded and empty, initialize preselection
  useEffect(() => {
    if (metadatas) {
      setDatapoints((current) =>
        current.length
          ? current
          : metadatas.reduce((acc, { required, code }) => {
              if (required || preSelectedDatapoints?.includes(code)) acc.push(code);
              return acc;
            }, [] as string[]),
      );
    }
  }, [templateId, preSelectedDatapoints, metadatas, setDatapoints]);

  // when columns are loaded, select its first category
  useEffect(() => {
    setStepValue((current) => {
      const name = metadatas?.[0]?.category;
      return current.selectedCategory || !name
        ? current
        : { portfolioDataPoints: current.portfolioDataPoints, selectedCategory: name };
    });
  }, [metadatas, setStepValue]);

  const render = useImmutable(
    () => (node: HTMLDivElement | null) =>
      node?.querySelector(`button[data-category=${JSON.stringify(selectedCategory)}]` as 'button')?.focus(),
  );

  if (isErrorMetadatas) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to load portfolio data points"
        firstParagraph="Please try again later or contact us"
        data-testid={createTestid('error-columns')}
      />
    );
  }

  if (isLoadingMetadatas || isLoadingTemplateDetails) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack height="100%" data-testid={testid} ref={render}>
        <Typography variant="body2" component="p" margin="1rem 0">
          Select the portfolio data points to extract. If you have chosen an existing template, the data points are
          already checked.
        </Typography>
        <SearchField
          uncontrolled
          fullWidth
          value={search}
          placeholder="Search portfolio data points"
          onChange={setSearch}
          className={classes.search}
          data-testid={createTestid('search')}
        />
        {selectedCategory && categories.length ? (
          <>
            <Checkbox
              uncontrolled
              name="select-all"
              label="Select all"
              className={classes.checkbox}
              labelClassName={classes.checkboxLabel}
              variant="rose"
              onFieldChange={onCheckAll}
              indeterminate={portfolioDataPoints.length > 0}
              checked={metadatas?.length === portfolioDataPoints.length}
              data-testid={createTestid('checkbox-all')}
            />
            <Stack direction="row" flexGrow={1} minHeight={0} spacing="1rem">
              <SelectCategoriesList
                categories={categories}
                selectedCategory={selectedCategory}
                ItemComponent={SelectCategory}
                onSelect={setSelectedCategory}
                onCheckAll={onCategoryCheckAll}
                data-testid={createTestid('categories')}
              />
              <SelectDatapointsList
                category={selectedCategory}
                categoryName={selectedCategory}
                datapoints={datapoints}
                onCheck={onDatapointCheck}
                onCheckAll={onCategoryCheckAll}
                selectedDatapoints={selectedDatapoints}
                data-testid={createTestid('datapoints')}
              />
            </Stack>
          </>
        ) : (
          <EmptyState
            iconEnable
            iconName="info"
            title="The search does not match a datapoint"
            data-testid={createTestid('emptyState')}
          />
        )}
      </Stack>
      <Buttons>
        <CreateTemplateButtons
          onPrevious={goPrevious}
          onNext={goNext}
          labels={[{ count: portfolioDataPoints.length, text: 'portfolio data points selected' }]}
          data-testid={createTestid('buttons')}
        />
      </Buttons>
    </>
  );
};
