import { convertToDateUTC, makeOpenModal, makeOpenModalBoolean } from 'mns-components';
import type { AnalysisApi, MarketplaceApi, ProviderApi } from 'mns-sdk-collect';
import type { MutationOptions, QueryObserverOptions, UseMutationOptions } from 'react-query';
import { generatePath } from 'react-router-dom';
import { currentMonthLastDate, firstDateAvailable } from '../../common/date';
import { useApplicationConfig } from '../../common/getApplicationConfig';
import type { AppCode } from '../../components/views/appDescriptions';
import { useUser } from '../../hooks/useAuth';
import { api } from '../../store/api';
import { getAnalysisRoute } from './routes';

export const useAnalysisPortfoliosRequest = <TSelect = AnalysisApi.AnalysisPortfolio<AppCode>[]>(
  appCode: AppCode,
  options?: QueryObserverOptions<AnalysisApi.AnalysisPortfolio<AppCode>[], unknown, TSelect>,
) => {
  const {
    config: { displayOnlyUploadedPortfolios },
  } = useApplicationConfig(appCode);
  const portfolioOriginType = displayOnlyUploadedPortfolios ? 'UPLOADED' : 'ALL';
  return api.provider.analysis.getAnalysisPortfolios.useQuery(appCode, portfolioOriginType, options);
};

export const useApplicationDetailsRequest = <TSelect = MarketplaceApi.Application>(
  appCode: string,
  options?: QueryObserverOptions<MarketplaceApi.Application, unknown, TSelect>,
) => {
  const { organisationId: orgId } = useUser();
  return api.marketplace.getApplication.useQuery(orgId, appCode, options);
};

export const usePortfoliosRequest = (
  appCode: AppCode,
  startDate = firstDateAvailable,
  endDate = currentMonthLastDate,
) => {
  const {
    config: { displayOnlyUploadedPortfolios },
  } = useApplicationConfig(appCode);

  const startDateString = convertToDateUTC(startDate);
  const endDateString = convertToDateUTC(endDate);

  return api.provider.portfolio.getPortfolios.useQuery({
    appCode,
    startDate: startDateString,
    endDate: endDateString,
    portfolioOriginType: displayOnlyUploadedPortfolios ? 'UPLOADED' : 'ALL',
  });
};

export const usePreferenceRequest = <TSelect = ProviderApi.Preference>(
  appCode: AppCode,
  options?: QueryObserverOptions<ProviderApi.Preference, unknown, TSelect>,
) => api.provider.portfolio.getPreference.useQuery(appCode, options);

export const usePricingByApplicationRequest = <TSelect = MarketplaceApi.ApplicationPricing[] | null>(
  appCode: AppCode,
  lines: number,
  options?: QueryObserverOptions<MarketplaceApi.ApplicationPricing[] | null, unknown, TSelect>,
) => api.marketplace.getPricingByApplication.useQuery(appCode, `${lines}`, options);

export const useConsumeAnalysisPortfolioRequest = (
  options?: MutationOptions<void, unknown, [AnalysisApi.ConsumeAnalysisPortfolioRequest]>,
) => {
  const inv = api.provider.analysis.getAnalysisPortfolios.useInvalidateRootQuery();
  return api.provider.analysis.consumeAnalysisPortfolio.useMutation({
    onSuccess: inv,
    ...options,
  });
};

export const usePayAnalysisPortfolioRequest = (
  options?: UseMutationOptions<void, unknown, [AnalysisApi.PayAnalysisPortfolioRequest]>,
) => {
  const inv = api.provider.analysis.getAnalysisPortfolios.useInvalidateRootQuery();
  return api.provider.analysis.payAnalysisPortfolio.useMutation({
    onSuccess: inv,
    ...options,
  });
};

export const useTriggerPortfolioCoverageRequest = (
  options?: MutationOptions<void, unknown, [ProviderApi.PortfolioAppCoverageRequest]>,
) => api.provider.portfolio.postPortfolioCoverage.useMutation(options);

export const [useOpenCreateRequestModal, useManageOpenCreateRequestModal] = makeOpenModalBoolean<{ appCode: AppCode }>(
  (params) => generatePath(getAnalysisRoute().link, params),
  'openCreateRequestModal',
);

export const [useOpenInfoModal, useManageOpenInfoModal] = makeOpenModal<
  { appCode: AppCode },
  AnalysisApi.AnalysisPortfolio<AppCode>['analysisId'][]
>((params) => generatePath(getAnalysisRoute().link, params), 'openInfoModal');
