import { Tooltip } from '@mui/material';
import { Icon, Info, useTestid } from 'mns-components';
import { computeFundPositionDetailStatus } from '../../../../common/utils';
import { DashboardStyle as useStyles } from '../Dashboard.styles';

type DeadlineChipProps = {
  deadline: number | undefined;
  organisationsName: string;
  'data-testid': string;
};

export const DeadlineChip: React.FC<DeadlineChipProps> = ({
  deadline,
  organisationsName,
  'data-testid': testid,
}: DeadlineChipProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  if (!deadline || deadline > 5) {
    return null;
  }
  const status = computeFundPositionDetailStatus(deadline);
  return (
    <Tooltip arrow placement="right" title={status}>
      <div>
        <Info color="bold" className={classes.info}>
          <div className={classes.deadlineChip}>
            <Icon name="info" color="inversed" size="small" data-testid={createTestid(`icon-${organisationsName}`)} />
            Deadline
          </div>
        </Info>
      </div>
    </Tooltip>
  );
};
