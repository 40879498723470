import { convertToDateUTC, Dialog, useCallbackImmutable, useTestid } from 'mns-components';
import { isAxiosError, type CollectApi } from 'mns-sdk-collect';
import React from 'react';
import { toast } from 'react-toastify';
import type { EmitterPermissionFormData } from '../../../../components/forms/types';
import { appCollectApi } from '../../../../store/apis';
import { CloseAccessPermission } from './CloseAccessPermission';
import { ReOpenAccessPermission } from './ReOpenAccessPermission';

const getErrorMessage = (code: number): string => {
  switch (code) {
    case 409:
      return 'A pending request already exists on this instrument. Please cancel it from the Access requests view before sending a new request';
    case 403:
      return 'You cannot open this access request with this Asset Manager as the ISIN is now managed by another AM.';
    default:
      return 'An unexpected error happened.';
  }
};

interface Props {
  selectedItems: CollectApi.AccessDemand[];
  isManagePermissionsOpen: boolean;
  onClose: () => void;
  'data-testid': string;
}

export const ManageAccessPermissions: React.FC<Props> = ({
  isManagePermissionsOpen,
  selectedItems,
  onClose,
  'data-testid': testid,
}: Props) => {
  const createTestid = useTestid(testid);
  // Form handler
  const handleReOpenAccessRequest = useCallbackImmutable(async (formData: EmitterPermissionFormData) => {
    // Merge data with formdata
    const formattedData = selectedItems.map(
      (item): CollectApi.AccessDemandRequest => ({
        // item Data
        portfolioId: item.portfolioId,
        fundName: item.fundName,
        externalId: item.externalId,
        externalIdType: item.externalIdType,
        issuerName: item.receptor,
        // Form Data
        acceptedDelayDayCount: parseInt(formData.acceptedDelayDayCount, 10),
        expectedAsOf: convertToDateUTC(formData.expectedAsOf),
        lookThroughLevel: parseInt(formData.lookThroughLevel, 10) as CollectApi.AccessDemandRequest['lookThroughLevel'],
        sharingFrequency: formData.sharingFrequency,
      }),
    );

    appCollectApi
      .saveMultipleDataAuthorizationAccess(formattedData)
      .catch((error) => {
        if (isAxiosError(error) && error?.response?.status) {
          toast(getErrorMessage(error.response.status), { type: 'error' });
        }
      })
      .finally(() => {
        onClose();
      });
  });

  const handleCloseAccessRequest = useCallbackImmutable(async () => {
    const formattedData = selectedItems.map((item) => item.dataAccessAuthorizationId);
    const revokeOrder: CollectApi.AccessAuthorizationUpdateRequest = {
      ids: formattedData,
      status: 'REVOKED',
      comment: '',
    };
    await appCollectApi.updateStatusOnMultipleDataAuthorizationAccess(revokeOrder);
    onClose();
  });

  switch (true as boolean) {
    case !selectedItems?.[0]?.status:
      return null;

    case selectedItems[0].status === 'ACCEPTED':
      return (
        <Dialog
          dialogTitle="Close access"
          open={isManagePermissionsOpen}
          onClose={onClose}
          data-testid={createTestid('dialog-close')}
        >
          <CloseAccessPermission
            selectedItems={selectedItems}
            saveCloseAccess={handleCloseAccessRequest}
            closeModal={onClose}
            data-testid={createTestid('modal-close')}
          />
        </Dialog>
      );

    case selectedItems[0].status === 'CANCEL':
    case selectedItems[0].status === 'REVOKED':
      return (
        <Dialog
          dialogTitle="Request access"
          open={isManagePermissionsOpen}
          onClose={onClose}
          data-testid={createTestid('dialog-request')}
        >
          <ReOpenAccessPermission
            savePermissionsFormHandler={handleReOpenAccessRequest}
            closeModal={onClose}
            selectedItems={selectedItems}
            data-testid={createTestid('modal-request')}
          />
        </Dialog>
      );

    default:
      return null;
  }
};
