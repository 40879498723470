import { Stack } from '@mui/material';
import { EmptyStateV2, useCallbackImmutable, useTestid } from 'mns-components';
import React from 'react';

export type ESGReportsGridType = {
  'data-testid': string;
};

export const ESGReportsGrid: React.FC<ESGReportsGridType> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);

  const doNothing = useCallbackImmutable(() => {});

  return (
    <Stack margin="auto">
      <EmptyStateV2
        variant="info"
        useCase="noActions"
        title="Get started with your report!"
        subTitle="Start creating your official reports with your portfolios. "
        buttonText="Create report"
        buttonIcon="esgsolutions"
        buttonVariant="secondary"
        buttonOnClick={doNothing}
        data-testid={createTestid('empty-reports')}
      />
    </Stack>
  );
};
