import { Tooltip } from '@mui/material';
import { colors, useTestid } from 'mns-components';
import type { Quality } from 'mns-sdk-collect';
import { qualities } from 'mns-sdk-collect';
import React from 'react';
import { qualitiesRendererStyles as useStyles } from './qualitiesRendererStyles';

export const qualityLabelMapper: Record<Quality, string> = {
  BASIC: 'Basic',
  BASIC_PLUS: 'Basic +',
  REGULATORY: 'Regulatory',
  S2: 'S2',
  S2_SCR: 'S2 SCR',
};

export const qualityLabelUnMapper: Record<string, Quality> = {
  Basic: 'BASIC',
  'Basic +': 'BASIC_PLUS',
  Regulatory: 'REGULATORY',
  S2: 'S2',
  'S2 SCR': 'S2_SCR',
};

const qualityBackgroundColorMapper: Record<Quality, string> = {
  BASIC: '#a197d5',
  BASIC_PLUS: '#8B8AD8',
  REGULATORY: '#6C67A7',
  S2: '#3E3D78',
  S2_SCR: '#0E174C',
};

const qualityColorMapper: Record<Quality, string> = {
  BASIC: colors.whiteColor,
  BASIC_PLUS: colors.whiteColor,
  REGULATORY: colors.whiteColor,
  S2: colors.whiteColor,
  S2_SCR: colors.whiteColor,
};

export const qualityTooltipMapper: Record<Quality, string> = {
  BASIC: 'Your file meets the minimum validity requirements for scenarios such as ESG analysis.',
  BASIC_PLUS:
    "Your file meets the minimum validity requirements for scenarios such as ESG analysis. Contains additional data such as issuer information's compared to the basic format.",
  REGULATORY: 'Your file meets the overall validity requirements for regulatory financial reporting scenarios.', // such as S2, L2 or others.',
  S2: 'Your file optimally meets the validity conditions for regulatory financial reporting scenarios such as S2, L2 or others.',
  S2_SCR:
    'Your file optimally meets the validity conditions for regulatory financial reporting scenarios such as S2, L2, SCR risk calculation and others.',
};

const QualityBadge: React.FC<{ quality: Quality; 'data-testid': string }> = ({ quality, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <div
      className={classes.quality}
      style={{ backgroundColor: qualityBackgroundColorMapper[quality], color: qualityColorMapper[quality] }}
      data-testid={createTestid(quality)}
    >
      <Tooltip title={qualityTooltipMapper[quality]} arrow placement="right-start">
        <div>{qualityLabelMapper[quality]}</div>
      </Tooltip>
    </div>
  );
};

interface QualitiesRendererProps {
  qualities: Quality[];
  'data-testid': string;
}

export const getQualitiesRendererText = (qualitiesFromComponent: Quality[]): string =>
  qualitiesFromComponent
    .reduce((acc, quality) => {
      if (qualities.includes(quality)) acc.push(qualityLabelMapper[quality]);
      return acc;
    }, [] as string[])
    .join(', ');

export const QualitiesRenderer: React.FC<QualitiesRendererProps> = ({
  qualities: qualitiesFromComponent,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const filteredQualities = qualitiesFromComponent.filter((quality) => qualities.includes(quality));

  return (
    <div data-testid={createTestid('qualities')} className={classes.qualities}>
      {filteredQualities.map((quality) => (
        <QualityBadge quality={quality} key={quality} data-testid={createTestid('qualities')} />
      ))}
    </div>
  );
};
