import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import {
  AppContent,
  AppLayout,
  AppMargin,
  Backdrop,
  Button,
  EmptyStateV2,
  filterTruthy,
  groupBy,
  Icon,
  SearchField,
  Switch,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDownloadPresignedUrl } from '../../../hooks/useDownloadPresignedUrl';
import { useFetchComposition } from '../hooks';
import { CompositionList } from './CompositionList';

export type CompositionProps = {
  portfolio: CollectApi.Portfolio;
  ptfList: CollectApi.Portfolio[];
  'data-testid': string;
};

export const Composition: React.FC<CompositionProps> = ({ portfolio: currentPtf, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { trackPageView } = useMatomo();
  const { date: filter } = useParams() as { date: string };

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [transparency, setTransparency] = useState(true);

  const {
    data: transparisedPortfolios,
    isLoading: isLoadingTransparisation,
    error: errorTransparisation,
  } = useFetchComposition({
    externalId: currentPtf.externalId.value,
    externalIdType: currentPtf.externalId.type,
    valuationDate: filter,
    portfolioOriginType: currentPtf.origin,
  });

  const uniqTransparisedPortfolios = useMemo(() => {
    if (!transparisedPortfolios) {
      return [];
    }

    const grouped = Object.values(groupBy(transparisedPortfolios, 'full_path'));
    if (!grouped.length) {
      return [];
    }

    return filterTruthy(grouped).map((items) =>
      items.reduce((acc, item) => ({
        ...item,
        Weight: acc.Weight + item.Weight,
        Quantity: acc.Quantity + item.Quantity,
      })),
    );
  }, [transparisedPortfolios]);

  useEffect(() => {
    trackPageView({
      documentTitle: `Portfolios Lists - Composition`, // optional
    });
  }, [trackPageView]);

  // first, expand only first level
  useEffect(() => {
    gridApi?.forEachNode((node) => {
      if (node.allChildrenCount && node.level === 0) {
        node.setExpanded(true);
      }
    });
  }, [gridApi]);

  // update "expand all" switch value
  const onRowGroupOpened = useCallbackImmutable((api: GridApi) => {
    let countCollapsed = 0;
    api.forEachNode((node) => {
      if (node.allChildrenCount && !node.expanded) {
        countCollapsed += 1;
      }
    });
    setTransparency(!countCollapsed);
  });

  // switch expand/collapse all
  const setExpand = useCallbackImmutable(() => {
    if (gridApi) {
      if (!transparency) {
        gridApi.expandAll();
        onRowGroupOpened(gridApi);
      } else {
        gridApi.collapseAll();
        onRowGroupOpened(gridApi);
      }
    }
  });

  const downloadPresignedUrl = useDownloadPresignedUrl();
  const handleDownloadLookthrough = useCallbackImmutable(() => {
    return downloadPresignedUrl({
      fileName: currentPtf.externalId.value,
      externalId: currentPtf.externalId.value,
      externalIdType: currentPtf.externalId.type,
      valuationDate: filter,
      // portfolioOriginType: currentPtf.origin,
      fileDownloadType: 'look_through',
      fileDownloadTypeLabel: 'Look through',
    });
  });

  const handleExport = useCallbackImmutable(() => gridApi?.exportDataAsExcel());

  if (isLoadingTransparisation) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (!uniqTransparisedPortfolios || !currentPtf || errorTransparisation) {
    return (
      <Stack flexGrow="1" justifyContent="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="This file is too large to be displayed. Please click here to download the look-throught file."
          buttonText="Download look-through file"
          buttonIcon="download"
          buttonVariant="primary"
          buttonOnClick={handleDownloadLookthrough}
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  return (
    <AppLayout>
      <AppContent variant="expanded-noscroll">
        <AppMargin>
          <Stack direction="row" gap="1rem" justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <SearchField
              value={searchValue}
              onChange={setSearchValue}
              disableMargin
              data-testid={createTestid('search')}
            />
            <Stack direction="row" gap="1rem" alignItems="center" justifyContent="flex-end" flexGrow={1}>
              <Switch
                uncontrolled
                value={transparency}
                onChange={setExpand}
                label="Expand all"
                data-testid={createTestid('button-expand-all')}
              />
              <Button
                startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
                color="primary"
                outlined
                onClick={handleExport}
                size="small"
                data-testid={createTestid('button-export')}
              >
                Export table
              </Button>
            </Stack>
          </Stack>
        </AppMargin>
        <CompositionList
          rowData={uniqTransparisedPortfolios}
          searchValue={searchValue}
          getGridApiRef={setGridApi}
          onRowGroupOpened={onRowGroupOpened}
          data-testid={createTestid('list')}
        />
      </AppContent>
    </AppLayout>
  );
};
