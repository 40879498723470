import { makeStyles } from 'mns-components';

export const iframeStyles = makeStyles({
  widgetContainer: {
    display: 'block',
    height: 'auto',
  },
  widget: {
    width: 'calc(100% - 5%)',
    margin: '1.625rem',
    borderRadius: '5px',
    boxSizing: 'border-box',
    border: 'solid 1px #ECEBEB',
    display: 'block',
    height: '50rem',
  },
});
