import { Button, useCallbackImmutable, useTestid } from 'mns-components';
import React from 'react';
import { useCookies } from 'react-cookie';
import { nextYearDate } from '../../common/date';
import useStyles from './styles/cookiesBannerStyles';

const CookiesBanner: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  const [cookies, setCookie] = useCookies(['manaos-cookies-accepted']);
  const handleDecline = useCallbackImmutable(() => setCookie('manaos-cookies-accepted', false));
  const handleAllow = useCallbackImmutable(() =>
    setCookie('manaos-cookies-accepted', true, {
      expires: nextYearDate,
    }),
  );

  if (cookies['manaos-cookies-accepted'] !== undefined) {
    return null;
  }

  return (
    <div className={classes.blockContainer} data-testid={testid}>
      <p>Manaos uses cookies to ensure you get the best experience on our website.</p>
      <div className={classes.cardBody}>
        <Button text color="primary" onClick={handleDecline} data-testid={createTestid('button-decline')}>
          Decline
        </Button>
        <Button color="primary" onClick={handleAllow} data-testid={createTestid('button-allow')}>
          Allow Cookies
        </Button>
      </div>
    </div>
  );
};

export default CookiesBanner;
