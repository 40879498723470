import { Stack } from '@mui/material';
import type { AggridTableProps } from 'mns-components';
import { Backdrop, EmptyStateV2, useTestid } from 'mns-components';
import type { AnalysisApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import type { AppCode } from '../../../components/views/appDescriptions';
import { useAggregatedData } from '../hooks';
import { getRoute } from '../routes';
import type { ViewType } from './AnalysisDetailsController';
import { AnalysisDetailsList } from './AnalysisDetailsList';

type AggregatedDetailsRequesterProps = {
  analysisList: AnalysisApi.AnalysisPortfolio<AppCode>[];
  getGridApiRef?: AggridTableProps<AnyObject<string, string>>['getGridApiRef'];
  'data-testid': string;
};

export const AggregatedDetailsRequester: React.FC<AggregatedDetailsRequesterProps> = ({
  analysisList,
  getGridApiRef,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  // form
  const state = useWatch<Partial<{ appCode: AppCode; externalId: string; navDate: string; type: ViewType }>>();

  // item selection
  const analysisStatus = useMemo(() => {
    if (analysisList) {
      const found = analysisList?.filter((analysis) => {
        if (analysis.applicationCode !== state.appCode) return false;
        if (analysis.externalId !== state.externalId) return false;
        if (analysis.valuationDate !== state.navDate) return false;
        return true;
      });
      if (found.length >= 1) return found.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];
    }
    return undefined;
  }, [analysisList, state]);

  // analysis
  const q = useAggregatedData(state.appCode, analysisStatus);
  const { data: analysisData, isError: isErrorAnalysisData } = q;

  if (isErrorAnalysisData) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="dataErrors"
          variant="error"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact support team."
          data-testid={createTestid('error-eetList')}
        />
      </Stack>
    );
  }

  if (!analysisData) {
    return <Backdrop data-testid={createTestid('loading')} />;
  }

  if (!analysisData.length) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          useCase="actions"
          variant="info"
          title="Get started with your EET!"
          subTitle="Start creating your EET to analyse your portfolio(s)."
          buttonVariant="secondary"
          buttonIcon="add"
          buttonHref={generatePath(getRoute('generate-eet').link)}
          buttonText="Create EET"
          data-testid={createTestid('empty-eetList')}
        />
      </Stack>
    );
  }

  return (
    <AnalysisDetailsList
      uniqueKey="header_name"
      rows={analysisData}
      getGridApiRef={getGridApiRef}
      data-testid={createTestid('list')}
    />
  );
};
