import { makeBuildFromConfig } from 'mns-components';
import React from 'react';
import { ClarityTable } from '../applications/esgApp/score/portfolioViewType/widgetComponent/ClarityTable';
import { ClarityWidget } from '../applications/esgApp/score/portfolioViewType/widgetComponent/ClarityWidget';
import { IframeWidget } from '../applications/esgApp/score/portfolioViewType/widgetComponent/IframeWidget';

export const buildFromConfig = makeBuildFromConfig(
  {
    portfolioViewComponent: {
      widgetComponent: ClarityWidget,
      iframeComponent: IframeWidget,
      tableComponent: ClarityTable,
    },
  },
  {
    fetch,
    jsx: React.createElement,
  },
);
