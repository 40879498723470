import { AppContent, AppLayout } from 'mns-components';
import { PastDeliveries } from '../../../components/emailDelivery/pastDeliveries/PastDeliveries';

export const EmailDelivery: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => (
  <AppLayout data-testid={testid}>
    <AppContent variant="expanded-noscroll">
      <PastDeliveries dateFieldName="General reference date" appCode="manaos-eet" data-testid={testid} />
    </AppContent>
  </AppLayout>
);
