import { Stack } from '@mui/material';
import {
  AppContent,
  Button,
  Divider,
  Icon,
  NotificationDot,
  SelectFilter,
  Typo,
  Typography,
  colors,
  joinClass,
  useCallbackImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath } from 'react-router-dom';
import {
  isFundAllowedToChaseUp,
  isFundAllowedToRequestAccess,
} from '../../../../components/cellRender/CollectionStatusButtonRenderer';
import { ManageInventoriesPermissions } from '../../inventories/components/ManageInventoriesPermissions';
import { getAssetManagersOptions } from '../../inventories/InventoriesController';
import { getRoute } from '../../routes';
import { DashboardStyle as useStyles } from '../Dashboard.styles';
import { DeadlineChip } from './DeadlineChip';

const generateSeeAllRoute = () => generatePath(getRoute('collection-inventories').link);

const getMapListCount = (list: Map<string, CollectApi.FundPositionToCollect[]>) => {
  let count = 0;
  Array.from(list).forEach(([, funds]) => {
    count += funds.length;
  });
  return count;
};

const getFundsEarliestDeadLine = (fundList: CollectApi.FundPositionToCollect[]) => {
  let earliestDeadline = 100;
  fundList.forEach((fund) => {
    if (
      (fund.disseminatorPortfolio?.receptionDeltaInDays && !earliestDeadline) ||
      (fund.disseminatorPortfolio &&
        earliestDeadline &&
        fund.disseminatorPortfolio.receptionDeltaInDays < earliestDeadline)
    ) {
      earliestDeadline = fund.disseminatorPortfolio.receptionDeltaInDays;
    }
  });
  return earliestDeadline;
};

type SectionsProps = {
  organisationName: string;
  inventories?: CollectApi.FundPositionToCollect[];
  handleButton: (selected: CollectApi.FundPositionToCollect[] | undefined) => void;
  deadline?: number;
  'data-testid': string;
};

/* --- SECTION CHASE UP ---*/

const ChaseUpSection: React.FC<SectionsProps> = ({
  organisationName,
  inventories,
  handleButton,
  deadline,
  'data-testid': testid,
}: SectionsProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const invLenght = inventories ? inventories.length : 0;
  const plural = invLenght > 1 ? 'ies' : 'y';

  const handleClick = useCallbackImmutable(() => {
    handleButton(inventories);
  });

  if (!inventories || invLenght == 0) return null;

  return (
    <Stack key={organisationName} direction="row" className={classes.section}>
      <Stack direction="row" alignItems="center">
        <Typography.Ellipsis
          title={organisationName}
          variant="caption"
          className={classes.line}
          data-testid={createTestid('chaseup-item-name')}
        >
          <strong>
            {invLenght} inventor{plural}
          </strong>{' '}
          expected from <strong>{organisationName}</strong>
        </Typography.Ellipsis>
        <DeadlineChip
          deadline={deadline}
          organisationsName={organisationName}
          data-testid={createTestid('icon-deadline')}
        />
      </Stack>
      <Button
        color="primary"
        outlined
        size="small"
        startIcon={<Icon name="chaseup" data-testid={createTestid('icon-chaseup')} />}
        onClick={handleClick}
        data-testid={createTestid('button-chaseup')}
      >
        Chase up
      </Button>
    </Stack>
  );
};

/* --- SECTION ACCESS REQUEST ---*/

const AccessRequestSection: React.FC<Omit<SectionsProps, 'deadline'>> = ({
  organisationName,
  inventories,
  handleButton,
  'data-testid': testid,
}: SectionsProps) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const invLenght = inventories ? inventories.length : 0;
  const plural = invLenght > 1 ? 's' : '';

  const handleClick = useCallbackImmutable(() => {
    handleButton(inventories);
  });

  if (!inventories || invLenght == 0) return null;

  return (
    <Stack key={organisationName} direction="row" className={classes.section}>
      <Typography.Ellipsis
        title={organisationName}
        variant="caption"
        className={classes.line}
        data-testid={createTestid('access-item-name')}
      >
        <strong>
          {invLenght} access request{plural}
        </strong>{' '}
        required from <strong>{organisationName}</strong>
      </Typography.Ellipsis>
      <Button
        color="primary"
        outlined
        size="small"
        startIcon={<Icon name="access" data-testid={createTestid('icon-accessopen')} />}
        onClick={handleClick}
        data-testid={createTestid('button-accessRequest')}
      >
        Request Access
      </Button>
    </Stack>
  );
};

/* --- SECTION INVITE ---*/

const InviteSection: React.FC<Omit<SectionsProps, 'organisationName' | 'deadline'>> = ({
  inventories,
  handleButton,
  'data-testid': testid,
}: Omit<SectionsProps, 'organisationName'>) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const invLenght = inventories ? inventories.length : 0;
  const plural = invLenght > 1 ? 'ies' : 'y';

  const handleClick = useCallbackImmutable(() => {
    handleButton(inventories);
  });

  if (!inventories || invLenght == 0) return null;

  return (
    <Stack alignItems="center" direction="row" className={classes.section}>
      <Typography.Ellipsis
        title={'invite'}
        variant="caption"
        className={classes.line}
        data-testid={createTestid('invite-item-name')}
      >
        <strong>
          {invLenght} inventor{plural}
        </strong>{' '}
        not found on the platform
      </Typography.Ellipsis>
      <Button
        color="primary"
        outlined
        size="small"
        startIcon={<Icon name="invite" data-testid={createTestid('icon-invite')} />}
        onClick={handleClick}
        data-testid={createTestid('button-invite')}
      >
        Invite
      </Button>
    </Stack>
  );
};

/* --- MY TASK ---*/

type MyTaskProps = {
  allInventories: CollectApi.FundPositionToCollect[];
  inventoriesInvite: CollectApi.FundPositionToCollect[] | undefined;
  'data-testid': string;
};

export const MyTask: React.FC<MyTaskProps> = ({
  allInventories,
  inventoriesInvite,
  'data-testid': testid,
}: MyTaskProps) => {
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<CollectApi.FundPositionToCollect[]>([]);
  const [organisationsName, setOrganisationsName] = useState<string[]>([]);
  const [assetManager, setAssetManager] = useState('all');

  const assetManagerOptions = useMemo(() => getAssetManagersOptions(allInventories), [allInventories]);

  // get list of issuer's name according to AM filter
  useEffect(() => {
    const issuerNameList: string[] = [];
    if (assetManager == 'all') {
      allInventories.forEach((fund) => {
        if (fund.disseminatorPortfolio && !issuerNameList.includes(fund.disseminatorPortfolio.organisationName))
          issuerNameList.push(fund.disseminatorPortfolio.organisationName);
      });
    } else {
      allInventories.forEach((fund) => {
        if (
          fund.disseminatorPortfolio &&
          fund.disseminatorPortfolio.organisationId == assetManager &&
          !issuerNameList.includes(fund.disseminatorPortfolio.organisationName)
        )
          issuerNameList.push(fund.disseminatorPortfolio.organisationName);
      });
    }
    setOrganisationsName(issuerNameList);
  }, [allInventories, assetManager]);

  // on close modal
  const queryClient = useQueryClient();
  const onClose = useCallbackImmutable((): void => {
    setSelectedItems([]);
    queryClient.invalidateQueries(['appCollectApi.getInventoriesToCollect']);
  });

  const handleFundsButton = useCallbackImmutable((selected: CollectApi.FundPositionToCollect[] | undefined) => {
    if (selected) setSelectedItems(selected);
  });

  const inventoriesChaseUp = useMemo(
    () =>
      new Map(
        organisationsName.map((organisationName) => [
          organisationName,
          allInventories.filter(
            (fund) => fund.disseminatorPortfolio?.organisationName == organisationName && isFundAllowedToChaseUp(fund),
          ),
        ]),
      ),
    [allInventories, organisationsName],
  );
  const inventoriesRequestAccess = useMemo(
    () =>
      new Map(
        organisationsName.map((organisationName) => [
          organisationName,
          allInventories.filter(
            (fund) =>
              fund.disseminatorPortfolio?.organisationName == organisationName && isFundAllowedToRequestAccess(fund),
          ),
        ]),
      ),
    [allInventories, organisationsName],
  );

  const requestAccessCount = useMemo(() => getMapListCount(inventoriesRequestAccess), [inventoriesRequestAccess]);
  const chaseUpCount = useMemo(() => getMapListCount(inventoriesChaseUp), [inventoriesChaseUp]);
  const chaseUpDeadlines = useMemo(
    () =>
      new Map(
        Array.from(inventoriesChaseUp)
          .filter(([, funds]) => funds.length)
          .map(([organisationName, funds]) => [organisationName, getFundsEarliestDeadLine(funds)]),
      ),
    [inventoriesChaseUp],
  );
  const chaseUpDeadlinesSorted = useMemo(
    () => new Map([...chaseUpDeadlines.entries()].sort((deadlineA, deadlineB) => deadlineA[1] - deadlineB[1])),
    [chaseUpDeadlines],
  );

  const seeAllOnClick = useCallbackImmutable(() => navigate(generateSeeAllRoute()));

  return (
    <AppContent data-testid={testid}>
      <Stack direction="row" className={joinClass(classes.sectionBorder, classes.containerHeader)}>
        <Typo variant="h3">My tasks</Typo>
        <SelectFilter
          label="Asset Manager"
          dropdownItems={assetManagerOptions}
          onClick={setAssetManager}
          activeItem={assetManager}
          countAll={allInventories.length}
          data-testid={createTestid('select-assetManager')}
        />
      </Stack>
      <Stack gap="1rem" className={classes.scroll}>
        {/* ---- CHASE UP ---- */}
        <Stack gap="1rem">
          <Stack
            direction="row"
            alignItems="center"
            className={classes.sectionTitle}
            data-testid={createTestid('chase-title')}
          >
            <Typo variant="body2medium" color={colors.darkGreyColor}>
              Inventories to chase up
            </Typo>
            <NotificationDot notifValue={chaseUpCount} isInTab />
          </Stack>
          {chaseUpDeadlinesSorted.size > 0 && (
            <Stack gap="0.5rem">
              {Array.from(chaseUpDeadlinesSorted).map(
                ([organisationName, deadline], index) =>
                  inventoriesChaseUp.has(organisationName) &&
                  inventoriesChaseUp.get(organisationName)!.length > 0 && (
                    <ChaseUpSection
                      organisationName={organisationName}
                      inventories={inventoriesChaseUp.get(organisationName)}
                      handleButton={handleFundsButton}
                      deadline={deadline}
                      key={organisationName}
                      data-testid={createTestid('chaseUp-' + index)}
                    />
                  ),
              )}
            </Stack>
          )}
        </Stack>

        <Divider variant="default" />

        {/* ---- ACCESS REQUEST ---- */}
        <Stack gap="1rem">
          <Stack
            direction="row"
            alignItems="center"
            className={classes.sectionTitle}
            data-testid={createTestid('access-title')}
          >
            <Typo variant="body2medium" color={colors.darkGreyColor}>
              Inventories requiring access
            </Typo>
            <NotificationDot notifValue={requestAccessCount} isInTab />
          </Stack>
          {inventoriesRequestAccess.size > 0 && (
            <Stack gap="0.5rem">
              {Array.from(inventoriesRequestAccess).map(
                ([organisationName, funds], index) =>
                  funds.length > 0 && (
                    <AccessRequestSection
                      organisationName={organisationName}
                      inventories={funds}
                      handleButton={handleFundsButton}
                      key={organisationName}
                      data-testid={createTestid('accessRequest-' + index)}
                    />
                  ),
              )}
            </Stack>
          )}
        </Stack>

        <Divider variant="default" />

        {/* ---- INVITE ---- */}
        <Stack display="row" gap="1rem">
          <Stack
            direction="row"
            alignItems="center"
            className={classes.sectionTitle}
            data-testid={createTestid('missing-title')}
          >
            <Typo variant="body2medium" color={colors.darkGreyColor}>
              Missing inventories
            </Typo>
            <NotificationDot notifValue={inventoriesInvite?.length ?? 0} isInTab />
          </Stack>
          {inventoriesInvite && (
            <InviteSection
              inventories={inventoriesInvite}
              handleButton={handleFundsButton}
              data-testid={createTestid('invite')}
            />
          )}
        </Stack>
      </Stack>

      {(chaseUpCount > 0 || requestAccessCount > 0 || (inventoriesInvite && inventoriesInvite.length > 0)) && (
        <Stack direction="column" gap="1rem">
          <Divider variant="default" />
          <Stack width="fit-content" margin="0 auto">
            <Button
              color="primary"
              outlined
              size="medium"
              onClick={seeAllOnClick}
              data-testid={createTestid('button-chaseup')}
            >
              See all
            </Button>
          </Stack>
        </Stack>
      )}
      <ManageInventoriesPermissions
        selectedItems={selectedItems}
        onClose={onClose}
        data-testid={createTestid('permissions')}
      />
    </AppContent>
  );
};
