import { colors, makeStyles } from 'mns-components';

export const cardCoverageStyle = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectContainer: {
    overflow: 'auto',

    // Scrollbar
    '&::-webkit-scrollbar': {
      height: '.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: colors.warningGreyColor,
    },
    '&::-webkit-scrollbar-track-piece:end': {
      marginBottom: '5rem',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      marginTop: '1rem',
    },
  },
  coverageBtn: {
    textAlign: 'right',
    marginTop: '8px',
    marginLeft: '1rem',
  },
  title: {
    fontSize: '1.3rem',
    fontweight: 'bold',
    marginBottom: '1rem',
  },
  coverageContainer: {
    border: '1px solid',
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: colors.primaryBackColor,
    background: colors.whiteColor,
    transition: 'all 0.4s ease',
    padding: '1rem 1.25rem 0.75rem 1.25rem',
    marginTop: '1rem',
  },
  coverageEstimation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  coverage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '.75rem',
    borderBottom: `1px solid ${colors.lightGreyColor}`,
  },
  iconCoverageContainer: {
    display: 'flex',
  },
  iconCoverage: {
    color: colors.whiteColor,
    backgroundColor: colors.borderLight,
    borderRadius: '.25rem',
    padding: '.5rem .5rem .25rem .5rem',
    boxSizing: 'border-box',
    maxHeight: '3.5rem',
    maxWidth: '3.5rem',
  },
  arrow: {
    color: colors.blueGreyColor,
  },
  tooltip: {
    maxWidth: '45rem',
    cursor: 'pointer',
    backgroundColor: colors.blueGreyColor,
    color: colors.defaultFontColor,
    fontSize: '.7rem',
    fontFamily: 'Rubik',
    whiteSpace: 'pre-wrap',
    padding: '.5rem',
  },
  titleCoverage: {
    margin: '.25rem 0',
  },
  coverageRate: {
    padding: '.5rem',
    textAlign: 'center',
    borderRadius: '7px',
    color: colors.whiteLessColor,
    fontSize: '.875rem',
  },
  darkBlueBg: {
    background: colors.defaultFontColor,
  },
  greenBg: {
    background: colors.greenBlueColor,
    margin: '.5rem 0',
  },
});
