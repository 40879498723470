import { makeStyles } from 'mns-components';

export const receiverPermissionListStyles = makeStyles({
  buttonsFiltersContainer: {
    height: '3rem',
    position: 'static',
    margin: '0 1rem 0.5rem 0',
  },
  exportButton: {
    top: '-2.875rem',
    width: '40rem',
    position: 'relative',
    margin: 'auto 0 auto auto',
    textAlign: 'right',
    '& svg': {
      color: '#5F7AF1',
      padding: '.25rem',
      borderRadius: '50%',
      transition: 'all 0.4s ease',
    },
    '& svg:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '0',
    position: 'relative',
    top: '-2rem',
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  fullWidth: { '& > *': { width: '100%' } },
});
