import { Stack } from '@mui/material';
import { useTestid, Backdrop, useCallbackImmutable, EmptyStateV2 } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useFilesRequest } from '../../../../components/views/hooks';
import { appCollectApi } from '../../../../store/apis';
import { useUploadModalOpen } from '../hooks';
import { MyDocumentsList } from './MyDocumentsList';

export type MyDocumentsFilter = {
  search: string;
};

type MyDocumentsListRequestProps = {
  viewFileTypes: CollectApi.File['dataFormat'][];
  filters: Partial<MyDocumentsFilter>;
  refreshDelay?: number;
  onDownload?(fileData: CollectApi.File): void;
  onDownloadErrorLog?(fileData: CollectApi.File): void;
  'data-testid': string;
};

export const MyDocumentsListRequest: React.FC<MyDocumentsListRequestProps> = ({
  viewFileTypes,
  filters,
  onDownload,
  onDownloadErrorLog,
  'data-testid': testid,
}) => {
  const [openModal] = useUploadModalOpen();
  const createTestid = useTestid(testid);

  const { data: filesData, error: filesError, refetch: refetchFiles } = useFilesRequest({ dataFormats: viewFileTypes });

  const handleVisible = useCallbackImmutable(async (fileData: CollectApi.File) => {
    const ids: string[] = [fileData.processId];
    if (fileData.publicDocumentId) {
      try {
        await appCollectApi.unPublishFile(ids);
        toast(`Your file "${fileData.name}" is now private`, { type: 'success' });
      } catch {
        toast(`couldn't unpublish file "${fileData.name}"`, { type: 'error' });
      }
    } else {
      try {
        await appCollectApi.publishFile(ids);
        toast(`Your file "${fileData.name}" is now public`, { type: 'success' });
      } catch {
        toast(`couldn't publish file "${fileData.name}"`, { type: 'error' });
      }
    }
    refetchFiles();
  });

  const filteredData = useMemo(() => {
    const statusFilters = ['OK', 'WARNING', 'ACCEPTED'];
    if (filesData) {
      return filesData.filter((value, index, self) => {
        return (
          self.findIndex(
            (v) =>
              v.referenceDate === value.referenceDate &&
              v.name === value.name &&
              v.dataFormat === value.dataFormat &&
              v.uploadedOn > value.uploadedOn,
          ) === -1 && statusFilters.includes(value.status)
        );
      });
    }
    return filesData;
  }, [filesData]);

  if (filesData === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (filesError) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error')}
        />
      </Stack>
    );
  }

  if (!filesData || filesData.length === 0 || filesData.length > 0 || !filteredData || filteredData.length === 0) {
    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="upload"
          title="Get started with your upload!"
          subTitle="Start your Manaos journey by uploading your file."
          buttonIcon="upload"
          buttonText="Upload file"
          buttonVariant="secondary"
          buttonOnClick={openModal}
          data-testid={createTestid('empty-files')}
        />
      </Stack>
    );
  }

  return (
    <MyDocumentsList
      rowData={filteredData}
      searchValue={filters.search}
      handleVisible={handleVisible}
      onDownload={onDownload}
      onDownloadErrorLog={onDownloadErrorLog}
      data-testid={createTestid('list')}
    />
  );
};
