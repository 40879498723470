import { AppLayout, useTestid } from 'mns-components';
import { EetLightFiles } from '../eetLightFiles/EetLightFiles';

type InputFilesProps = { 'data-testid': string };

export const InputFiles: React.FC<InputFilesProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  return (
    <AppLayout data-testid={testid}>
      <EetLightFiles data-testid={createTestid('eet-light')} />
    </AppLayout>
  );
};
