import type { ChangePasswordFormData, ForgotUsernameFormData, ResetPasswordFormData } from './types';

const missingCharsValidators: [string, (text: string) => boolean][] = [
  ['a lower-case letter', (text: string) => text.toUpperCase() !== text],
  ['an upper-case letter', (text: string) => text.toLowerCase() !== text],
  ['a number', (text: string) => !!/\d/.exec(text)],
  ['a valid symbol (@&§!?$%;:=./+_-())', (text: string) => !!/[@&§!?$%;:=./+()-]/.exec(text)],
];

const isLetter = (c: string) => c.toLowerCase() !== c.toUpperCase();
const getForbiddenChars = (text: string) =>
  text
    .split('')
    .filter((char) => !isLetter(char))
    .join('')
    .match(/[^0-9@&§!?$%;:=./+()-]/g);

export const getNewPasswordError = (password: string): string | undefined => {
  if (!password.length) return 'New password should be filled';
  if (password.length < 12) return 'The password size must be greater than 12 characters.';
  const forbidenChars = getForbiddenChars(password);
  if (forbidenChars) return `The password contains forbidden symbols (${forbidenChars.join('')}).`;

  const missing: string[] = missingCharsValidators
    .filter(([, isValid]) => !isValid(password))
    .map(([message]) => message);
  if (missing.length) return `This password is missing ${missing.join(', ')}.`;
};

export const getConfirmPasswordError = (password: string, confirmPassword: string): string | undefined => {
  if (!confirmPassword.length) return 'Password confirmation should be filled';
  if (password !== confirmPassword) return 'The password confirmation should match the new password above';
};

export const getPasswordFormErrors = ({ newPassword, confirmPassword }: ChangePasswordFormData) => {
  const newPasswordError = getNewPasswordError(newPassword);
  const confirmPasswordError = getConfirmPasswordError(newPassword, confirmPassword);
  return {
    ...(newPasswordError && { newPassword: newPasswordError }),
    ...(confirmPasswordError && { confirmPassword: confirmPasswordError }),
  };
};

export const getResetFormErrors = ({ username, code, newPassword, confirmPassword }: ResetPasswordFormData) => {
  const passwordErrors = getPasswordFormErrors({ newPassword, confirmPassword });
  return {
    ...(!username.length && { username: 'Username should be filled' }),
    ...(!code.length && { code: 'Code should be filled' }),
    ...passwordErrors,
  };
};

export const getForgotUsernameErrors = ({ email }: ForgotUsernameFormData) => {
  const emailError = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)?.length ? undefined : 'Invalid email format';
  return {
    ...(emailError && { email: emailError }),
  };
};
