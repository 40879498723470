import { Auth, Hub } from 'aws-amplify';
import type { Navigate } from 'mns-components';
import {
  isFunction,
  isObject,
  locationReload,
  makeGlobalState,
  objectEntries,
  useCallbackImmutable,
  useDebounce,
  useNavigate,
  useWindowEvent,
} from 'mns-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useApplicationsSubscribed } from '../applications/marketplace/hooks';
import { wait } from '../common/date';
import { routes as authRoutes } from '../components/auth/routes';
import { accountNoAuthApi } from '../store/apis';
import type { CognitoUser, CognitoUserSession } from '../types/cognito';
import { useAuthProviderLogoutUri } from './useAuthProvider';

export type AuthUser = {
  username: string;
  email: string;
  organisationId: string;
  apps: string[];
  isAdmin: boolean;
  ssoAuth: string | null;
};

export type CognitoRedirection = {
  url: string;
};

const ERRCODE_COGNITOLOGGEDOUT = 'ERRCODE_COGNITOLOGGEDOUT';
export const isCognitoLoggedOut = (el: CognitoUser | Error | undefined): el is Error =>
  el instanceof Error && el.message === ERRCODE_COGNITOLOGGEDOUT;
export const createCognitoLoggetOutError = () => new Error(ERRCODE_COGNITOLOGGEDOUT);

export const isCognitoUser = (el: unknown): el is CognitoUser =>
  !!(isObject(el) && isFunction(el.getUsername) && isFunction(el.getSignInUserSession));
export const isCognitoRedirection = (el: unknown): el is CognitoRedirection =>
  !!el && typeof el === 'object' && 'url' in el;
export const isCognitoAuthError = (el: CognitoUser | Error | undefined): el is Error =>
  el instanceof Error && el.message !== ERRCODE_COGNITOLOGGEDOUT;

export const getOrganisationIdByCognitoUser = (session: CognitoUserSession): string =>
  session.getIdToken().payload['custom:organisation_id'];
export const getAppsByCognitoUser = (session: CognitoUserSession): string[] =>
  session.getIdToken().payload['cognito:groups'].slice();

export const getEmailByCognitoUser = (session: CognitoUserSession): string => session.getIdToken().payload.email;
export const getUserSSOAuth = (session: CognitoUserSession): string | null => {
  const ssoAuth = session.getIdToken().payload['custom:user_sso_auth'];
  return !ssoAuth || ssoAuth === 'NO_SSO' ? null : ssoAuth;
};

export const useAuthUser = makeGlobalState<CognitoUser | Error | undefined>();

const useReloadOnAuthChange = () => {
  const [authUser] = useAuthUser();
  useWindowEvent(
    'storage',
    (event: StorageEvent) => {
      if (event.key?.includes('idToken') && event.newValue) {
        const cacheToken = isCognitoUser(authUser)
          ? authUser.getSignInUserSession()?.getIdToken().getJwtToken()
          : undefined;
        if (!cacheToken || event.newValue !== cacheToken) {
          locationReload(true);
        }
      }
    },
    [authUser],
  );
};
export const useUser = (): AuthUser => {
  const [authUser] = useAuthUser();
  const navigate = useNavigate();

  const userSession = useMemo(() => {
    if (isCognitoUser(authUser)) {
      const session = authUser.getSignInUserSession();
      if (session) {
        const apps = getAppsByCognitoUser(session);
        const email = getEmailByCognitoUser(session);
        const isAdmin = !!apps.find((app: string) => app === 'ROLE_ADMIN');
        const ssoAuth = getUserSSOAuth(session);
        return {
          username: authUser.getUsername(),
          email,
          organisationId: getOrganisationIdByCognitoUser(session),
          apps: getAppsByCognitoUser(session),
          isAdmin,
          ssoAuth,
        };
      }
    }
  }, [authUser]);

  const { data: subscribedApps = [] } = useApplicationsSubscribed(userSession?.organisationId);

  return useMemo(() => {
    if (userSession) {
      return {
        ...userSession,
        apps: [...userSession.apps, ...subscribedApps],
      };
    }
    navigate(authRoutes.logoutUri.path);
    throw new Error('Please login');
  }, [userSession, subscribedApps, navigate]);
};

export const useUserApps = (): AuthUser['apps'] | undefined => {
  const [authUser] = useAuthUser();
  const userSession = useMemo(
    () => (isCognitoUser(authUser) ? authUser.getSignInUserSession() : undefined),
    [authUser],
  );

  const orgId = userSession ? getOrganisationIdByCognitoUser(userSession) : undefined;
  const tokenApps = userSession ? getAppsByCognitoUser(userSession) : undefined;
  const { isSuccess, data } = useApplicationsSubscribed(orgId);

  return useMemo(
    () => (isSuccess && Array.isArray(data) && tokenApps ? [...tokenApps, ...data] : undefined),
    [data, isSuccess, tokenApps],
  );
};

const useRedirect = (navigate: Navigate, uri?: string) =>
  useCallbackImmutable(() => {
    if (uri) navigate(uri);
  });

const LOCALSTORAGE_PERSISTINGFIELDS = ['providerSso', 'guidedTours'];

export type UseAuthProps = {
  appEntryUri?: string;
  loginUri?: string;
  mfaUri?: string;
  forgotPasswordUri?: string;
  forgotUsernameUri?: string;
  firstLoginUri?: string;
  logoutUri?: string;
  ssoUri?: string;
};

export const useAuth = ({
  appEntryUri,
  loginUri,
  mfaUri,
  forgotPasswordUri,
  firstLoginUri,
  forgotUsernameUri,
}: UseAuthProps) => {
  const navigate = useNavigate();
  const [, setAuthUser] = useAuthUser();
  const isFirstLogin = useRef(false);

  const redirectAppEntry = useRedirect(navigate, appEntryUri);
  const redirectLogin = useRedirect(navigate, loginUri);
  const redirectMfa = useRedirect(navigate, mfaUri);
  const redirectForgotPassword = useRedirect(navigate, forgotPasswordUri);
  const redirectForgotUsername = useRedirect(navigate, forgotUsernameUri);
  const redirectFirstLogin = useRedirect(navigate, firstLoginUri);
  const afterLogoutUri = useAuthProviderLogoutUri();

  useReloadOnAuthChange();
  const getUser = useDebounce(async () => {
    try {
      const user: string | CognitoUser = await Auth.currentAuthenticatedUser();
      if (typeof user === 'object') {
        const session = user.getSignInUserSession();
        if (session) {
          const orgId = getOrganisationIdByCognitoUser(session);
          localStorage.setItem('organization-id', orgId);
          setAuthUser(user);
          return true;
        }
      }
    } catch {
      // do nothing
    }
    setAuthUser(createCognitoLoggetOutError());
    return false;
  }, [setAuthUser]);

  const logoutUser = useDebounce(() => {
    const persist = LOCALSTORAGE_PERSISTINGFIELDS.reduce((acc, field) => {
      acc[field] = window.localStorage.getItem(field);
      return acc;
    }, {} as AnyObject<string, string | null>);

    localStorage.clear();

    objectEntries(persist).forEach(([field, value]) => {
      if (value) {
        window.localStorage.setItem(field, value);
      }
    });

    window.location.assign(afterLogoutUri);
  }, [afterLogoutUri]);

  useEffect(() => {
    // data: CognitoUser or Error or errorMessage string or empty
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      // eslint-disable-next-line no-console
      // console.info(event, data);

      if (isCognitoUser(data)) {
        setAuthUser(data);
      } else if (data instanceof Error) {
        setAuthUser(new Error(data.message));
      } else if (typeof data === 'string') {
        setAuthUser(new Error(data));
      }

      // @hack: https://ifsalpha.atlassian.net/browse/MNS-5517
      // At first login, you should complete a new password. At success, AWS Hub expect to redirect you to app entry
      // with "signIn" event. But we want to display a message that user successfully changed its password with
      // "completeNewPassword" event (does not exists yet in AWS Hub), then change password page redirect to app entry.
      if (event === 'signIn' && isFirstLogin.current) {
        isFirstLogin.current = false;
        setTimeout(() =>
          Hub.dispatch('auth', { event: 'completeNewPassword', data, message: 'New password completed' }),
        );
        return;
      }

      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          if (await getUser()) {
            redirectAppEntry();
          } else {
            redirectLogin();
          }
          break;
        case 'signOut':
          logoutUser();
          break;
        // above to verify
        case 'customOAuthState':
          redirectMfa();
          break;
        case 'forgotPassword_failure':
        case 'forgotPassword':
        case 'forgotPasswordSubmit_failure':
          // case 'forgotPasswordSubmit':
          redirectForgotPassword();
          break;
        case 'forgotUsernameSubmit_failure': //
          redirectForgotUsername();
          break;
        case 'signUp_failure':
        case 'signUp':
        case 'completeNewPassword_failure':
          isFirstLogin.current = true;
          redirectFirstLogin();
          break;
        // @hack: https://ifsalpha.atlassian.net/browse/MNS-5517
        case 'completeNewPassword':
          isFirstLogin.current = false;
          redirectAppEntry();
          break;
        // case 'parsingCallbackUrl':
        //   if (isCognitoRedirection(data)) {
        //     navigate(data.url);
        //   }
        //   break;
        // THESE COGNITO STATUSES ARE NOT USED YET:
        // case 'cognitoHostedUI_failure':
        // case 'cognitoHostedUI':
        // case 'configured':
        // case 'customState_failure':
        // case 'forgotPasswordSubmit_failure':
        // case 'forgotPasswordSubmit':
        // case 'signIn_failure':
        // case 'tokenRefresh_failure':
        // case 'tokenRefresh':
        // case 'userDeleted':
        //   break;
      }
    });
    getUser();
  }, [
    getUser,
    setAuthUser,
    logoutUser,
    isFirstLogin,
    redirectMfa,
    redirectForgotPassword,
    redirectForgotUsername,
    redirectFirstLogin,
    redirectAppEntry,
    redirectLogin,
  ]);
};

export const refreshTokenAsync = () =>
  new Promise<CognitoUser>((resolve, reject) => {
    (async () => {
      try {
        const cognito: CognitoUser = await Auth.currentAuthenticatedUser();
        const cognitoSession = cognito.getSignInUserSession();
        // finally invoke isValid() method on session to check if auth tokens are valid
        // if tokens have expired, lets call "logout"
        // otherwise, dispatch AUTH_USER success action and by-pass login screen
        if (cognitoSession?.isValid() && cognitoSession.getRefreshToken()) {
          return cognito.refreshSession(cognitoSession.getRefreshToken(), (err, session) => {
            if (err) {
              if (typeof err === 'string') {
                reject(new Error(err));
              }
              if (err instanceof Error) {
                reject(new Error(err.message));
              }
            } else if (!session) {
              reject(new Error('No session'));
            }
            resolve(cognito);
            return;
          });
        }
      } catch {
        // do nothing
      }
      reject(new Error('No session'));
    })();
  });

export const getTokenAsync = () =>
  new Promise<CognitoUser>((resolve, reject) => {
    (async () => {
      try {
        const cognito: CognitoUser = await Auth.currentAuthenticatedUser();
        const cognitoSession = cognito.getSignInUserSession();
        if (cognitoSession?.isValid() && cognitoSession.getRefreshToken()) {
          return cognito.refreshSession(cognitoSession.getRefreshToken(), (err, session) => {
            if (session) {
              resolve(cognito);
            } else {
              reject(createCognitoLoggetOutError());
            }
          });
        }
      } catch {
        // do nothing
      }
      reject(createCognitoLoggetOutError());
    })();
  });

export const useRefreshToken = () =>
  useCallbackImmutable(async (): Promise<void> => {
    try {
      const authUser = await refreshTokenAsync();
      Hub.dispatch('auth', { event: 'tokenRefresh', data: authUser, message: 'Token refreshed' });
    } catch (err) {
      Hub.dispatch('auth', {
        event: 'tokenRefresh_failure',
        data: createCognitoLoggetOutError(),
        message: 'Error at refresh token',
      });
      throw err;
    }
  });

export const useHandleLogin = () =>
  useCallbackImmutable(async (username: string, password: string) => {
    try {
      const res = await Auth.signIn(username, password);
      if (isCognitoUser(res)) {
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Hub.dispatch('auth', { event: 'signUp', data: res, message: 'First login attempt' });
        }
        if (res.challengeName === 'CUSTOM_CHALLENGE' || res.preferredMFA) {
          Hub.dispatch('auth', { event: 'customOAuthState', data: res, message: 'MFA' });
        }
      }
    } catch (err) {
      Hub.dispatch('auth', { event: 'signIn_failure', data: err, message: 'Login failed' });
      throw err;
    }
  });

export const useHandleChangePassword = () => {
  const [cognitoUser] = useAuthUser();
  return useCallbackImmutable(async (newPassword: string) => {
    try {
      await Auth.completeNewPassword(cognitoUser, newPassword);
    } catch (err) {
      Hub.dispatch('auth', { event: 'completeNewPassword_failure', data: err, message: 'Change password failed' });
      throw err;
    }
  });
};

export const useHandleForgotPassword = () =>
  useCallbackImmutable(async (username: string, code: string, newPassword: string) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (err) {
      Hub.dispatch('auth', {
        event: 'forgotPasswordSubmit_failure',
        data: err,
        message: 'Change forgotten password failed',
      });
      throw err;
    }
  });

export const useHandleForgotUsername = () =>
  useCallbackImmutable(async (email: string) => {
    try {
      await accountNoAuthApi.postForgotUsername(email);
    } catch (err) {
      Hub.dispatch('auth', {
        event: 'forgotUsernameSubmit_failure',
        data: err,
        message: 'Username recovery for email failed',
      });
      throw err;
    }
  });

export const useHandleMfa = () => {
  const [cognitoUser] = useAuthUser();
  return useCallbackImmutable(async (code: string) => {
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    } catch (err) {
      Hub.dispatch('auth', { event: 'cognitoHostedUI_failure', data: err, message: 'MFA login failed' });
      throw err;
    }
  });
};

export const useSendConfirmationCodeToUserEmail = () =>
  useCallbackImmutable(async (username: string) => {
    try {
      await Auth.forgotPassword(username);
    } catch (err) {
      Hub.dispatch('auth', { event: 'forgotPassword_failure', data: err, message: 'Unable to send confirmation code' });
      throw err;
    }
  });

export const useLoggout = () => {
  useEffect(() => {
    Auth.signOut();
  }, []);
};

export const useFederatedSignIn = async () => {
  const navigate = useNavigate();
  const [, setAuthUser] = useAuthUser();

  useEffect(() => {
    (async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        if (isCognitoUser(authUser)) {
          setAuthUser(authUser);
          navigate(authRoutes.appEntryUri.path);
          return;
        }
      } catch {
        // do nothing
      }
      await Auth.federatedSignIn();
    })();
  }, [navigate, setAuthUser]);
};

// gets user session and waits for getSignInUserSession
export const useUserSession = () => {
  const [cognitoUser] = useAuthUser();
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  useEffect(() => {
    if (isCognitoUser(cognitoUser)) {
      (async () => {
        while (!cognitoUser.getSignInUserSession()) {
          await wait(10);
        }
        setSession(cognitoUser.getSignInUserSession());
      })();
    }
  }, [cognitoUser]);
  return session;
};
