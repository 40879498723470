import type { StepProps } from 'mns-components';
import { groupBy, objectEntries, objectKeys, useCallbackImmutable, useTestid } from 'mns-components';
import { useEffect, useMemo, useState } from 'react';
import { useGetPtfColumns } from '../../../../api/extractionsData';
import { AccordionFinalize } from '../components/AccordionFinalize';
import type { CreateTemplateSteps } from '../types';

export const PortfolioDataPointsTab: React.FC<StepProps<CreateTemplateSteps, 3>> = ({
  stepValues: [, { portfolioDataPoints }],
  setCurrentStep,
  setStepValues,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [accordionPanel, setAccordionPanel] = useState<string | null>(null);
  const makeAccordionChange = useCallbackImmutable(
    (newAccordionPanel: string) => (_: unknown, expanded: boolean) =>
      setAccordionPanel(expanded ? newAccordionPanel : null),
  );

  const onClickModify = useCallbackImmutable((ev: React.MouseEvent, category: string) => {
    ev.preventDefault();
    ev.stopPropagation();
    setStepValues(([setUp, ptfDps, ...steps]) => [
      setUp,
      { portfolioDataPoints: ptfDps.portfolioDataPoints, selectedCategory: category },
      ...steps,
    ]);
    setCurrentStep(1);
  });

  const { data: metadatas = [] } = useGetPtfColumns();

  const grouped = useMemo(
    () =>
      groupBy(
        metadatas.filter((md) => portfolioDataPoints.includes(md.code)),
        'category',
      ),
    [metadatas, portfolioDataPoints],
  );

  // when columns are loaded, select its first category
  useEffect(() => {
    const [name] = objectKeys(grouped);
    if (name) setAccordionPanel(name);
  }, [grouped]);

  return (
    <>
      {objectEntries(grouped).map(([category, list]) =>
        !list?.length ? null : (
          <AccordionFinalize
            key={category}
            accordionPanel={accordionPanel}
            category={category}
            categoryName={category}
            list={list.map((item) => item.code)}
            onClickModify={onClickModify}
            useAccordionChange={makeAccordionChange}
            data-testid={createTestid(category)}
          />
        ),
      )}
    </>
  );
};
