import { Chip, Stack } from '@mui/material';
import type { FormProps, OptionType, ToggleButtonGroupItem } from 'mns-components';
import {
  Select,
  colors,
  makeForm,
  floatToPercent,
  useCallbackImmutable,
  useTestid,
  SelectSection,
  filterOptionsBySearch,
  Typography,
  uniqueBy,
} from 'mns-components';
import type { CollectApi, ProviderApi } from 'mns-sdk-collect';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getPortfolioText } from '../../../../common/utils';
import { useUser } from '../../../../hooks/useAuth';
import { formStyles as useStyles } from './formStyles';

const validPercent = (num: unknown) => (typeof num === 'number' ? floatToPercent(num) : 'Not calculated yet');

const validCoverage = (
  coverage: ProviderApi.Portfolio['providerCoverage'],
  status: ProviderApi.Portfolio['coverageStatus'],
  testid: string,
) => {
  if (['requested', 'pending'].includes(status as string)) {
    return (
      <span style={{ fontWeight: 'bold', color: colors.warningColor }} data-testid={testid}>
        Requested
      </span>
    );
  }
  return <span data-testid={testid}>{validPercent(coverage)}</span>;
};

const mapOriginLabel: Record<CollectApi.PortfolioOrigin, string> = {
  UPLOADED: 'My portfolios',
  COLLECTED: 'Collected portfolios',
};

export type ReplyPortfolioPickerForm = { portfolioId: string; valuationDate: string; organisationId: string };

type PortfolioPickerInputsProps = {
  portfolios: ProviderApi.Portfolio[];
  valuationDateOptions: OptionType[];
  portfolio: ProviderApi.Portfolio;
  onPortfolioChange?(portfolioId: string): void;
  onValuationDateChange?(valuationDate: string | undefined): void;
  disablePtfAndDate?: boolean;
  defaultValues: Partial<ReplyPortfolioPickerForm>;
  'data-testid': string;
};

const PortfolioPickerInputs: React.FC<PortfolioPickerInputsProps> = ({
  portfolios: rawPortfolios,
  valuationDateOptions,
  portfolio,
  onPortfolioChange,
  onValuationDateChange,
  defaultValues,
  disablePtfAndDate,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const { setValue } = useFormContext<ReplyPortfolioPickerForm>();
  const [search, setSearch] = useState('');
  const { organisationId: orgId } = useUser();
  const [sectionPtf, setSectionPtf] = useState<CollectApi.PortfolioOrigin>(() =>
    portfolio.organisationId === orgId ? 'UPLOADED' : 'COLLECTED',
  );

  const portfolios = useMemo(() => uniqueBy(rawPortfolios, 'portfolioId'), [rawPortfolios]);

  const handlePortfolioChange = useCallbackImmutable((portfolioId: string) => {
    onPortfolioChange?.(portfolioId);
    onValuationDateChange?.(undefined);
  });

  const handleValuationDateChange = useCallbackImmutable((value: string) => {
    onValuationDateChange?.(value);
  });

  const versions = useMemo(
    (): OptionType[] => [{ label: portfolio.organisationName, value: portfolio.organisationId }],
    [portfolio],
  );

  useEffect(() => {
    if (defaultValues.portfolioId) {
      setValue('portfolioId', defaultValues.portfolioId);
    }
  }, [setValue, defaultValues.portfolioId]);

  useEffect(() => {
    if (valuationDateOptions.find((item) => item.value === portfolio.valuationDate)) {
      setValue('valuationDate', portfolio.valuationDate);
      setValue('organisationId', portfolio.organisationId);
    } else if (defaultValues.valuationDate) {
      setValue('valuationDate', defaultValues.valuationDate);
    }
  }, [defaultValues.valuationDate, portfolio.organisationId, portfolio.valuationDate, setValue, valuationDateOptions]);

  const portfolioOptions = useMemo(
    () =>
      filterOptionsBySearch(
        portfolios.reduce((acc, ptf) => {
          if ((ptf.organisationId === orgId) === (sectionPtf === 'UPLOADED')) {
            acc.push({ label: getPortfolioText(ptf), value: ptf.portfolioId });
          }
          return acc;
        }, [] as OptionType[]),
        search,
      ),
    [orgId, portfolios, search, sectionPtf],
  );

  const sectionsPtf = useMemo(
    () =>
      Array.from(
        portfolios.reduce((acc, ptf) => {
          acc.add(ptf.organisationId === orgId ? 'UPLOADED' : 'COLLECTED');
          return acc;
        }, new Set<CollectApi.PortfolioOrigin>()),
        (origin): ToggleButtonGroupItem<CollectApi.PortfolioOrigin> => ({
          value: origin,
          children: (
            <Stack direction="row" gap=".5rem" alignItems="center">
              <Typography variant="body2">{mapOriginLabel[origin]}</Typography>
              <Chip
                color="secondary"
                size="small"
                variant="badge"
                label={portfolios.reduce(
                  (count, ptf) => ((ptf.organisationId === orgId) === (origin === 'UPLOADED') ? count + 1 : count),
                  0,
                )}
              />
            </Stack>
          ),
        }),
      ),
    [orgId, portfolios],
  );

  return (
    <div className={classes.form} data-testid={testid}>
      <h3 className={classes.formTitle}>
        <span>Review your portfolio details</span>
        <hr />
      </h3>
      <label>
        <span>Portfolio ID:</span>
        <SelectSection<string, CollectApi.PortfolioOrigin>
          value={portfolio.portfolioId}
          name="portfolioId"
          options={portfolioOptions}
          onFieldChange={handlePortfolioChange}
          search={search}
          onSearch={setSearch}
          sectionOptions={sectionsPtf.length > 1 ? sectionsPtf : undefined}
          section={sectionPtf}
          onChangeSection={setSectionPtf}
          disabled={disablePtfAndDate}
          data-testid={createTestid('portfolioId')}
        />
      </label>
      <label>
        <span>Portfolio name:</span>
        <span data-testid={createTestid('portfolioName')}>{portfolio.name}</span>
      </label>
      <label>
        <span>NAV Date:</span>
        <Select
          name="valuationDate"
          options={valuationDateOptions}
          disabled={disablePtfAndDate}
          onFieldChange={handleValuationDateChange}
          data-testid={createTestid('valuationDate')}
        />
      </label>
      <label>
        <span>Provided by:</span>
        <Select name="organisationId" options={versions} disabled data-testid={createTestid('organisationId')} />
      </label>
      <label>
        <span>Number of securities:</span>
        <span data-testid={createTestid('numberOfLines')}>{portfolio.numberOfLines}</span>
      </label>
      <label>
        <span>Look-through rate:</span>
        <span data-testid={createTestid('lookThroughRate')}>{validPercent(portfolio.lookThroughRate)}</span>
      </label>
      <label>
        <span>Coverage rate:</span>
        {validCoverage(portfolio.providerCoverage, portfolio.coverageStatus, createTestid('providerCoverage'))}
      </label>
    </div>
  );
};

export const PortfolioPickerForm: React.FC<
  Omit<FormProps<ReplyPortfolioPickerForm>, 'children'> & PortfolioPickerInputsProps
> = makeForm<ReplyPortfolioPickerForm, PortfolioPickerInputsProps>(PortfolioPickerInputs);
