import { InputAdornment, Tooltip } from '@mui/material';
import type { OptionType } from 'mns-components';
import {
  ButtonList,
  DatePicker,
  Icon,
  Modal,
  Select,
  TextField,
  convertToDateUTC,
  makeForm,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { firstDateAvailable } from '../../../../../common/date';
import { settingsStyle as useStyles } from '../settingsStyle';

const lookthroughOptions = Array.from(
  { length: 8 },
  (_, id): OptionType => ({ label: `${id} level${id !== 1 ? 's' : ''}`, value: id.toString() }),
);

type Collector = CollectApi.AppDisseminationSettings['autoAcceptAccessRequestSettingDto']['collectors'][number];

type InnerCollector = Replace<Replace<Collector, 'embargo' | 'maxLookThroughLevel', string>, 'minStartPoint', Date>;

type DisseminationApplicationSettingsModalInputProps = {
  collector: Collector;
  scopesToShare: OptionType[];
  onClose(): void;
  setCollector(value: Collector | ((current: Collector) => Collector)): void;
  'data-testid': string;
};

const DisseminationApplicationSettingsModalInputs: React.FC<DisseminationApplicationSettingsModalInputProps> = ({
  collector,
  scopesToShare,
  onClose,
  setCollector,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [state, setState] = useState<InnerCollector>({
    collector: collector.collector,
    embargo: collector.embargo.toString(),
    scope: collector.scope,
    maxLookThroughLevel: collector.maxLookThroughLevel.toString(),
    minStartPoint: new Date(collector.minStartPoint),
  });

  const handleEmbargo = useCallbackImmutable((ev: React.ChangeEvent<HTMLInputElement>) =>
    setState((current) => {
      const newValue = +ev.target.value;
      if (Number.isNaN(newValue)) {
        return { ...current, embargo: '' };
      }
      const valueString = newValue.toString();
      if (valueString !== current.embargo) {
        return { ...current, embargo: valueString };
      }
      return current;
    }),
  );

  const handleScope = useCallbackImmutable((scope: string) =>
    setState((current) => {
      if (scope !== current.scope) {
        return { ...current, scope: scope };
      }
      return current;
    }),
  );

  const handleStartDate = useCallbackImmutable((date: Date) =>
    setState((current) => ({ ...current, minStartPoint: date })),
  );

  const handleLookthroughLevel = useCallbackImmutable((value: string) =>
    setState((current) => {
      if (value !== current.maxLookThroughLevel) {
        return { ...current, maxLookThroughLevel: value };
      }
      return current;
    }),
  );

  const handleSave = useCallbackImmutable(() => {
    setCollector({
      collector: state.collector,
      embargo: parseInt(state.embargo),
      scope: state.scope,
      maxLookThroughLevel: parseInt(state.maxLookThroughLevel),
      minStartPoint: convertToDateUTC(state.minStartPoint),
    });
    onClose();
  });

  return (
    <div className={classes.disseminationApplicationSettingsModal} data-testid={testid}>
      <label className={classes.label}>
        <div className={classes.field}>
          Embargo&nbsp;
          <Tooltip
            title="Number of business days after the end of month to wait before inventories are disseminated"
            arrow
          >
            <Icon.Info color="secondary" data-testid={createTestid('icon-embargo')} />
          </Tooltip>
        </div>
        <div className={classes.input}>
          <TextField
            type="text"
            name="defaultEmbargo"
            className={classes.settingsTextField}
            onChange={handleEmbargo}
            value={state.embargo}
            InputProps={{
              endAdornment: <InputAdornment position="start">days</InputAdornment>,
            }}
            data-testid={createTestid('defaultEmbargo')}
          />
        </div>
      </label>
      <label className={classes.label}>
        <span className={classes.field}>Scope to share</span>
        <div className={classes.input}>
          <Select
            fullWidth
            variant="normal"
            name="scopesToShare"
            options={scopesToShare}
            value={state.scope}
            onFieldChange={handleScope}
            data-testid={createTestid('scopesToShare')}
          />
        </div>
      </label>
      <label className={classes.label}>
        <span className={classes.field}>Look-through level</span>
        <div className={classes.input}>
          <Select
            fullWidth
            variant="normal"
            name="maxLookThroughLevel"
            onFieldChange={handleLookthroughLevel}
            value={state.maxLookThroughLevel}
            options={lookthroughOptions}
            data-testid={createTestid('maxLookThroughLevel')}
          />
        </div>
      </label>
      <label className={classes.label}>
        <span className={classes.field}>Start access date</span>
        <div className={classes.input}>
          <DatePicker
            fullWidth
            disableMargin
            variant="normal"
            minDate={firstDateAvailable}
            value={state.minStartPoint}
            onFieldChange={handleStartDate}
            data-testid={createTestid('minStartPoint')}
          />
        </div>
      </label>
      <div className={classes.buttonList}>
        <ButtonList
          center
          buttons={[
            {
              key: 'close',
              children: 'Close',
              color: 'whitePrimary',
              startIcon: <Icon.Close data-testid={`${testid}-icon-close`} />,
              onClick: onClose,
              'data-testid': `${testid}-close`,
            },
            {
              key: 'save',
              children: 'Save',
              color: 'primary',
              onClick: handleSave,
              'data-testid': `${testid}-save`,
            },
          ]}
          data-testid={createTestid('button')}
        />
      </div>
    </div>
  );
};

const DisseminationApplicationSettingsModalForm = makeForm(DisseminationApplicationSettingsModalInputs);

type DisseminationApplicationSettingsModalProps = {
  collectorName?: string;
  collector?: Collector;
  onClose(): void;
  setCollector(value: Collector | ((current: Collector) => Collector)): void;
  scopesToShare: OptionType[] | undefined;
  'data-testid': string;
};

export const DisseminationApplicationSettingsModal: React.FC<DisseminationApplicationSettingsModalProps> = ({
  collectorName,
  collector,
  scopesToShare,
  onClose,
  setCollector,
  'data-testid': testid,
}) => {
  const [persistCollectorName, setCollectorName] = useState(collectorName);
  useEffect(() => {
    if (collectorName) setCollectorName(collectorName);
  }, [collectorName]);

  const [persistCollector, setPersistCollector] = useState(collector);
  useEffect(() => {
    if (collector) setPersistCollector(collector);
  }, [collector]);

  return (
    <Modal
      modalTitle={`Edit ${persistCollectorName} settings`}
      open={!!(collectorName && collector && scopesToShare)}
      onClose={onClose}
      color="primary"
    >
      <DisseminationApplicationSettingsModalForm
        collector={persistCollector!}
        setCollector={setCollector}
        scopesToShare={scopesToShare!}
        onClose={onClose}
        data-testid={testid}
      />
    </Modal>
  );
};
