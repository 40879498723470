import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, FormStepper, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi, DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../../common/getImageContent';
import { dataDeliveryApi } from '../../../../store/apis';
import type { AppCode } from '../../../views/appDescriptions';
import { useFilesRequest } from '../../../views/hooks';
import { getViewsRoute } from '../../../views/routes';
import type { CreateEmailDeliveryFileSteps } from '../types';
import { buildSelectRecipientsFileStep } from './SelectRecipientsFileStep';
import { buildSelectScopeFileStep } from './SelectScopeFileStep';

const useSteps = () => useMemo(() => [buildSelectScopeFileStep(), buildSelectRecipientsFileStep()], []);
const viewFileTypes: CollectApi.File['dataFormat'][] = ['EET', 'EMT'];

type CreateEmailStepperFileProps = {
  onClose(): void;
  appCode: AppCode;
  'data-testid': string;
};

export const CreateEmailStepperFile: React.FC<CreateEmailStepperFileProps> = ({
  onClose,
  appCode,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const {
    company: { name: companyName },
    content: { title },
  } = useApplicationConfig(appCode);

  const icon = useAppBackgroundClassName(appCode);

  const {
    data: filesData,
    error: filesError,
    isLoading: isLoadingFiles,
  } = useFilesRequest({ dataFormats: viewFileTypes });

  const handleSubmit = useCallbackImmutable(
    async ([{ title: deliveryTitle, fileIds, referenceDate }, { emails }]: CreateEmailDeliveryFileSteps) => {
      const scopes = fileIds.map((latestVersionId): DataDeliveryApi.CreateDataDeliveryRequestScope => {
        const found = filesData?.find((file) => file.processId === latestVersionId);
        if (found && found.dataFormat) {
          return {
            dataFormat: found.dataFormat,
            fileName: found.name,
            identification: found.processId, //processed id ONLY for uploaded eet
            name: found.name,
            productionDate: referenceDate,
            type: 'EET_UPLOADED',
            qualities: found.qualities,
          };
        }
        throw new Error('Unexpected error');
      });

      await dataDeliveryApi.createDelivery({
        title: deliveryTitle,
        emails,
        scopes,
      });
      onClose();
    },
  );

  const steps = useSteps();

  if (!filesData?.length) {
    if (isLoadingFiles) {
      return <Backdrop data-testid={createTestid('backdrop')} />;
    }

    if (filesError) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            illustrationVariant="iconType"
            data-testid={createTestid('error')}
          />
        </Stack>
      );
    }

    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="upload"
          title="Get started with your upload!"
          subTitle="Start your Manaos journey by uploading your file"
          buttonText="Upload"
          buttonIcon="upload"
          buttonVariant="secondary"
          buttonHref={getViewsRoute('uploads').path}
          illustrationVariant="iconType"
          data-testid={createTestid('error-empty')}
        />
      </Stack>
    );
  }

  return (
    <FormStepper<CreateEmailDeliveryFileSteps>
      header={{ appTitle: title, providerName: companyName, appBackgroundClassName: icon }}
      displayCounter
      displayNavigation
      displayErrors
      steps={steps}
      onCancel={onClose}
      onSubmit={handleSubmit}
      buttonBackground="white"
      data-testid={testid}
    />
  );
};
