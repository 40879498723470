import { Stack } from '@mui/material';
import { AppMargin, Button, Icon, SearchField, useTestid } from 'mns-components';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type ScheduledDeliveriesControllerType = {
  search: string;
};

type ScheduledDeliveriesControllerProps = {
  navElement?: React.ReactNode;
  onSearch?(value: string): void;
  onCreateDelivery?(): void;
  'data-testid': string;
};

export const ScheduledDeliveriesController: React.FC<ScheduledDeliveriesControllerProps> = ({
  navElement,
  onSearch,
  onCreateDelivery,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const methods = useForm<ScheduledDeliveriesControllerType>();
  const formState = methods.watch();

  useEffect(() => {
    onSearch?.(formState.search);
  }, [formState.search, onSearch]);

  return (
    <AppMargin>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="1rem" flexWrap="wrap">
        <FormProvider {...methods}>
          <SearchField value={formState.search} name="search" disableMargin data-testid={createTestid('search')} />
        </FormProvider>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="1rem" flexGrow={1}>
          {navElement}
          <Button
            color="primary"
            outlined
            startIcon={<Icon.Add data-testid={createTestid('icon-sendTo')} />}
            onClick={onCreateDelivery}
            data-testid={createTestid('button-sendTo')}
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </AppMargin>
  );
};
