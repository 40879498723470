import { Button, Form, generateKey, GroupListOptions, spinalCase, TextField, useTestid } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useTemplateDetails } from '../../../api/templateData';
import { getApplicationPipeTitle } from '../../../common/getApplicationConfig';
import { isAppCode } from '../../../components/views/appDescriptions';
import { mainSourceLabel, mainSourceName, methodSourceLabel } from '../extractor.const';
import { templateStyles as useStyles } from './styles/templateStyles';

const isTemplateColumnData = (el: AnyObject): el is DataExtractorApi.Template.TemplateColumnData =>
  'type' in el && el.type === 'data_point';

type ExtractorTemplateDetailsModalProps = {
  template: DataExtractorApi.Template.TemplateLight;
  setClose(): void;
  'data-testid': string;
};

export const ExtractorTemplateDetailsModal: React.FC<ExtractorTemplateDetailsModalProps> = ({
  template,
  setClose,
  'data-testid': testid,
}: ExtractorTemplateDetailsModalProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const { data: templateDetails } = useTemplateDetails(template.id);

  const list = useMemo(
    () =>
      templateDetails?.columns.map((col) => {
        if (isTemplateColumnData(col.formula)) {
          const source = col.formula.source;
          const spinalSource = spinalCase(source);
          if (source === mainSourceName) {
            return {
              source: mainSourceLabel,
              label: col.formula.column,
              value: col.name,
            };
          } else if (isAppCode(source)) {
            return {
              source: getApplicationPipeTitle(source),
              label: col.formula.column,
              value: col.name,
            };
          } else if (isAppCode(spinalSource)) {
            return {
              source: getApplicationPipeTitle(spinalSource),
              label: col.formula.column,
              value: col.name,
            };
          } else {
            return {
              source: source,
              label: col.formula.column,
              value: col.name,
            };
          }
        }
        return {
          source: methodSourceLabel,
          value: generateKey(),
          label: col.name,
        };
      }) ?? [],
    [templateDetails],
  );

  return (
    <div className={classes.fullHeight} data-testid={testid}>
      <div className={classes.scroll}>
        <h3>Template name</h3>
        <Form data-testid={createTestid('form')}>
          <TextField
            name="name"
            type="text"
            disabled
            data-testid={createTestid('name')}
            value={template.name}
            fullWidth
            className={classes.inputWidth}
          />
        </Form>
        <h3>Data points recap</h3>
        <div>
          <GroupListOptions
            list={list}
            groupByField="source"
            groupLabelAtField="source"
            itemKeyAtField="value"
            itemLabelAtField="label"
            data-testid={createTestid('data-points')}
          />
        </div>
      </div>
      <div className={classes.buttonList}>
        <Button color="primary" outlined onClick={setClose} data-testid={createTestid('button-close')}>
          Close
        </Button>
      </div>
    </div>
  );
};
