import { getApplicationConfig } from '../../common/getApplicationConfig';
import type { RouterInterface } from '../../components/routing/RouterInterface';
import { AnalysisDetails } from './analysisDetails/AnalysisDetails';
import { EetFiles } from './eetFiles/EetFiles';
import { GenerateEet } from './generateEet/GenerateEet';
import { InputFiles } from './inputFiles/InputFiles';
import { EmailDelivery } from './views/EmailDelivery';
import { Portfolios } from './views/Portfolios';
import { Upload } from './views/Upload';

const base = getApplicationConfig('manaos-eet').config.link;

const rawRoutes = [
  {
    label: 'EET Generated',
    link: `${base}/eet-files`,
    key: 'eet-files',
    component: EetFiles,
    autoselect: true,
    displayTab: true,
    breadCrumbs: ['Manaos', 'EET', 'EET Generated'],
  },
  {
    label: 'Analysis details',
    link: `${base}/analysis-details`,
    key: 'analysis-details',
    component: AnalysisDetails,
    displayTab: true,
  },
  {
    label: 'Input files',
    link: `${base}/input-files/`,
    key: 'input-files',
    component: InputFiles,
    displayTab: true,
    breadCrumbs: ['Manaos', 'EET', 'Input files'],
  },
  {
    label: 'Email delivery',
    link: `${base}/email-delivery/past-deliveries`,
    key: 'email-delivery',
    component: EmailDelivery,
    displayTab: true,
    expectRole: 'emailDeliveryActive',
  },
  {
    label: 'Portfolios',
    link: `${base}/portfolios`,
    key: 'emt-portfolios',
    component: Portfolios,
    displayTab: true,
    breadCrumbs: ['Manaos', 'EET', 'Portfolios'],
  },
  {
    label: 'My files',
    link: `${base}/files`,
    key: 'eet-uploads',
    component: Upload,
    displayTab: true,
    breadCrumbs: ['Manaos', 'EET', 'Uploads'],
  },
  {
    label: 'Generate EET',
    link: `${base}/generate-eet/:eetId?`,
    key: 'generate-eet',
    component: GenerateEet,
    breadCrumbs: ['Manaos', 'EET', 'Generate EET'],
  },
] as const;

export const getRoute = (key: typeof rawRoutes[number]['key']) =>
  rawRoutes.find((route) => route.key === key) as RouterInterface;

export const routes = rawRoutes as unknown as RouterInterface[];
