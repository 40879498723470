import { IconButton, Menu } from '@mui/material';
import { Icon, MenuItem, Switch, useCallbackImmutable, useTestid } from 'mns-components';
import React, { useState } from 'react';
import { useOpenEditIsinList } from '../../isinList/hooks';
import { useOpenEditEmailList } from '../../settings/organisation/hooks';
import { scheduledDeliveriesStyles as useStyles } from './scheduledDeliveriesStyles';

export type MoreDetailScheduledDeliveriesRendererProps = {
  emailDeliveryId: string;
  isinListId?: string;
  emailListId: string;
  enabled: boolean;
  onToggleEnable(selectedItem: string, current: boolean): void;
  onEdit(selectedItem: string): void;
  onDelete(selectedItem: string): void;
  disabled?: boolean;
  'data-testid': string;
};

export const MoreDetailScheduledDeliveriesRenderer: React.FC<MoreDetailScheduledDeliveriesRendererProps> = ({
  emailDeliveryId,
  isinListId,
  emailListId,
  enabled,
  onToggleEnable,
  onEdit,
  onDelete,
  disabled,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickMenu = useCallbackImmutable((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  });

  const handleCloseMenu = useCallbackImmutable((): void => {
    setAnchorEl(null);
  });

  const handleClickEnable = useCallbackImmutable(() => {
    onToggleEnable(emailDeliveryId, enabled);
  });

  const handleEdit = useCallbackImmutable(() => {
    onEdit(emailDeliveryId);
    handleCloseMenu();
  });

  const handleDelete = useCallbackImmutable(() => {
    onDelete(emailDeliveryId);
    handleCloseMenu();
  });

  const navigateEditIsinList = useOpenEditIsinList();
  const handleEditIsinList = useCallbackImmutable(() => {
    if (isinListId) {
      navigateEditIsinList(isinListId);
    }
    handleCloseMenu();
  });

  const navigateEditEmailList = useOpenEditEmailList();
  const handleEditEmailList = useCallbackImmutable(() => {
    navigateEditEmailList(emailListId);
    handleCloseMenu();
  });

  return (
    <>
      <Switch
        uncontrolled
        value={enabled}
        disabled={disabled}
        onChange={handleClickEnable}
        data-testid={createTestid('toggle-enable')}
      />
      <IconButton color="secondary" onClick={handleClickMenu} data-testid={createTestid('button-menu')}>
        <Icon.More data-testid={createTestid('icon-menu')} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {isinListId && (
          <MenuItem onClick={handleEditIsinList} data-testid={createTestid('menu-button-editIsinList')}>
            Edit Scope
          </MenuItem>
        )}
        <MenuItem onClick={handleEditEmailList} data-testid={createTestid('menu-button-editEmailList')}>
          Edit Recipients
        </MenuItem>
        <hr className={classes.hr} />
        <MenuItem onClick={handleEdit} data-testid={createTestid('menu-button-edit')}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} data-testid={createTestid('menu-button-delete')}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
