import { lazy } from 'mns-components';
import { getApplicationConfig } from '../../common/getApplicationConfig';
import type { BreadcrumbGenerator } from '../layout/handler';
import type { LoggedInRouteProps } from '../routing/LoggedInRoute';
import type { AppCode } from './appDescriptions';
import { isAppCode } from './appDescriptions';

// ## targets application AppLayout
const AnalysisPage = lazy(() => import('../../applications/analysisDemands/AppLayout'));
const Bp2sLayoutApp = lazy(() => import('../../applications/positionBasedReportingApp/AppLayout'));
const CollectApp = lazy(() => import('../../applications/collectionApp/AppLayout'));
const CoverageEstimationPage = lazy(() => import('../../applications/coverageApp/AppLayout'));
const Datalake = lazy(() => import('../../applications/datalake/AppLayout'));
const DisseminationApp = lazy(() => import('../../applications/disseminationApp/AppLayout'));
const EetApp = lazy(() => import('../../applications/eetApp/AppLayout'));
const EetAppBeta = lazy(() => import('../../applications/eetApp_beta/AppLayout'));
const MarketplacePage = lazy(() => import('../../applications/marketplace/AppLayout'));
const MyPortfoliosPage = lazy(() => import('../../applications/myPortfolios/AppLayout'));
const PaiStatementApp = lazy(() => import('../../applications/paiStatementApp/AppLayout'));
const PortfolioLists = lazy(() => import('../../applications/myPortfoliosApp/AppLayout'));
const ProductRangeCollection = lazy(() => import('../../applications/productRange/collection/AppLayout'));
const ProductRangeDissemination = lazy(() => import('../../applications/productRange/dissemination/AppLayout'));
const UploadsPage = lazy(() => import('../../applications/uploads/AppLayout'));
const EsgPage = lazy(() => import('../../applications/esgApp/AppLayout'));

// @TODO should target an AppLayout
const ExtractorPage = lazy(() => import('../../applications/dataExtractorApp/ExtractorPage'));

// ## targets global pages
const SettingsPage = lazy(() => import('../settings/SettingsPage'));
const Subscription = lazy(() => import('./subscription/AppLayout'));

const getApplicationLabel = (appCode: string) => {
  if (!isAppCode(appCode)) return '';

  const {
    content: { title },
  } = getApplicationConfig(appCode as AppCode);

  return title;
};

const myAppsBreadcrumb = {
  key: 'my-apps',
  label: 'my apps',
  link: '/marketplace/subscribed',
};

const allAppsBreadcrumb = {
  key: 'all-apps',
  label: 'all apps',
  link: '/marketplace/all',
};

export const rawRoutes = [
  {
    key: 'coverage-estimation',
    path: '/apps/coverage-estimation',
    component: CoverageEstimationPage,
    label: 'Portfolio coverage estimation',
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
  {
    key: 'manaos-collection',
    path: '/apps/manaos-collection',
    component: CollectApp,
    appRole: 'APP_DATA_COLLECT',
    label: 'Collection',
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
  {
    key: 'manaos-dissemination',
    path: '/apps/manaos-dissemination',
    component: DisseminationApp,
    appRole: 'APP_DATA_DISSEMINATION',
    label: 'Dissemination',
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
  {
    key: 'manaos-eet',
    path: '/apps/manaos-eet',
    component: EetApp,
  },
  {
    key: 'manaos-eet-beta',
    path: '/apps/manaos-eet-beta',
    component: EetAppBeta,
    appRole: 'APP_EET-BETA',
    label: 'EET Generator',
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
  {
    key: 'manaos-paistatement',
    path: '/apps/manaos-paistatement',
    component: PaiStatementApp,
  },
  {
    key: 'manaos-extractor',
    path: '/apps/manaos-extractor',
    component: ExtractorPage,
    label: 'Data Extractor',
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
  {
    key: 'manaos-datalake',
    path: '/apps/manaos-datalake',
    component: Datalake,
  },
  {
    key: 'isin-list',
    path: '/portfolios/lists',
    component: MyPortfoliosPage,
    label: 'portfolios',
  },
  {
    key: 'position-based-reporting',
    path: '/apps/position-based-reporting',
    component: Bp2sLayoutApp,
  },
  {
    key: 'marketplace',
    path: '/marketplace',
    component: MarketplacePage,
    label: 'marketplace',
  },
  {
    key: 'portfolios-lists',
    path: '/portfolios/uploaded',
    component: MyPortfoliosPage,
    exact: true,
    label: 'portfolios',
  },
  {
    key: 'portfolios-collected-lists',
    path: '/portfolios/collected',
    component: MyPortfoliosPage,
    exact: true,
    label: 'portfolios',
  },
  {
    key: 'uploads',
    path: '/files',
    component: UploadsPage,
    exact: true,
    label: 'my files',
  },
  {
    key: 'esg',
    path: '/esg',
    component: EsgPage,
    label: 'ESG solutions',
  },
  {
    key: 'portfolios-lists-view',
    path: '/portfolios/uploaded/:id/:date',
    component: PortfolioLists,
    label: 'details',
  },
  {
    key: 'portfolios-lists-selected',
    path: '/portfolios/uploaded/:id',
    component: PortfolioLists,
  },
  {
    key: 'settings',
    path: '/settings',
    component: SettingsPage,
    label: 'settings',
  },
  {
    key: 'manaos-productrangedissemination',
    path: '/apps/manaos-productrangedissemination',
    component: ProductRangeDissemination,
  },
  {
    key: 'manaos-productrangecollection',
    path: '/apps/manaos-productrangecollection',
    component: ProductRangeCollection,
  },
  {
    key: 'app-subscription',
    path: '/apps/app-:appCode/subscription',
    component: Subscription,
    labelGenerator: (params: Record<string, string>) => getApplicationLabel(params.appCode),
    previousBreadcrumbs: [allAppsBreadcrumb],
  },
  {
    key: 'analysis',
    path: '/apps/:appCode',
    component: AnalysisPage,
    labelGenerator: (params: Record<string, string>) => getApplicationLabel(params.appCode),
    previousBreadcrumbs: [myAppsBreadcrumb],
  },
] as const;

type ViewRouteKey = typeof rawRoutes[number]['key'] & string;

export const getRoute = <K extends ViewRouteKey>(key: K) => rawRoutes.find((item) => item.key === key)!;

export const getViewsRoute = getRoute;

export const routes = rawRoutes as readonly (Omit<LoggedInRouteProps, 'data-testid'> & { key: ViewRouteKey })[];

// has property label
export const globalBreadcrumbParts = rawRoutes
  .map((item) => {
    const label = 'label' in item ? item.label : undefined;
    const labelGenerator = 'labelGenerator' in item ? item.labelGenerator : undefined;
    const previousBreadcrumbs = 'previousBreadcrumbs' in item ? item.previousBreadcrumbs : undefined;

    if (label || labelGenerator) {
      return {
        label,
        labelGenerator,
        previousBreadcrumbs,
        key: item.key,
        link: item.path,
      };
    }
  })
  .filter((item) => item !== undefined) as BreadcrumbGenerator[];
