import type { DataExtractorApi } from 'mns-sdk-collect';
import type { QueryObserverOptions } from 'react-query';
import { api } from '../store/api';

export const useTemplates = <TSelect = DataExtractorApi.Template.TemplateLight[]>({
  staleTime = Infinity,
  ...queryOptions
}: QueryObserverOptions<DataExtractorApi.Template.TemplateLight[], unknown, TSelect> = {}) =>
  api.dataExtractor.template.getTemplates.useQuery({
    staleTime,
    ...queryOptions,
  });

export const usePrefetchTemplates = ({
  staleTime = Infinity,
  ...queryOptions
}: QueryObserverOptions<DataExtractorApi.Template.TemplateLight[]> = {}) =>
  api.dataExtractor.template.getTemplates.usePrequery({
    staleTime,
    ...queryOptions,
  });

export const useTemplateDetails = <TSelect = DataExtractorApi.Template.Template>(
  templateId: string,
  {
    staleTime = Infinity,
    enabled = !!templateId,
    ...queryOptions
  }: QueryObserverOptions<DataExtractorApi.Template.Template, unknown, TSelect> = {},
) => api.dataExtractor.template.getTemplate.useQuery(templateId, { staleTime, enabled, ...queryOptions });

export const usePrefetchTemplateDetails = ({
  staleTime = Infinity,
  ...queryOptions
}: QueryObserverOptions<DataExtractorApi.Template.Template> = {}) =>
  api.dataExtractor.template.getTemplate.usePrequery({ staleTime, ...queryOptions });

export const useCreateTemplate = () => {
  const invTemplates = api.dataExtractor.template.getTemplates.useInvalidateQuery();
  return api.dataExtractor.template.createTemplate.useMutation({
    onSuccess: () => invTemplates(),
  });
};
