import { makeStyles, colors, keyframes } from 'mns-components';

const shakeKeyframes = keyframes`
  5%, 45% {
    transform: translate3d(-1px, 0, 0);
  }
  10%, 40% {
    transform: translate3d(2px, 0, 0);
  }
  15%, 25%, 35% {
    transform: translate3d(-4px, 0, 0);
  }
  20%, 30% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const downloadFilesRendererStyles = makeStyles({
  bulkContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      width: '2.5rem',
      minWidth: '0',
    },
  },

  warningColor: { color: colors.warningColor },

  shake: {
    display: 'inherit',
    animation: `${shakeKeyframes} 2s ease both`,
    animationIterationCount: '3',
    transform: 'translate3d(0, 0, 0)',
  },

  noShake: {
    display: 'inherit',
  },

  dropdownHead: {
    width: '80%',
    margin: '0px auto',
    color: 'gray',
  },

  separator: {
    borderTop: 'none',
    borderBottom: '1px solid gray',
    margin: '0 1rem',
  },
});
