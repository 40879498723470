import type { DataExtractorApi } from 'mns-sdk-collect';
import type { QueryObserverOptions } from 'react-query';
import { api } from '../store/api';

export const useGetExtractions = <TSelect = DataExtractorApi.Extraction.Extraction[]>(
  type?: DataExtractorApi.Extraction.ExtractionType,
  options: QueryObserverOptions<DataExtractorApi.Extraction.Extraction[], unknown, TSelect> = {},
) => api.dataExtractor.extraction.getExtractions.useQuery(type, options);

export const useDeleteExtraction = () => {
  const inv = api.dataExtractor.extraction.getExtractions.useInvalidateRootQuery();
  return api.dataExtractor.extraction.deleteExtraction.useMutation({ onSuccess: inv });
};

export const useUpdateExtractionStatus = () => {
  const inv = api.dataExtractor.extraction.getExtractions.useInvalidateRootQuery();
  return api.dataExtractor.extraction.updateExtractionStatus.useMutation({ onSuccess: inv });
};

export const useGetPtfColumns = ({
  staleTime = Infinity,
  ...props
}: QueryObserverOptions<DataExtractorApi.Metadata.ColumnPtf[]> = {}) =>
  api.dataExtractor.metadata.getColumnsPtf.useQuery({ staleTime, ...props });

export const usePrefetchPtfColumns = ({
  staleTime = Infinity,
  ...props
}: QueryObserverOptions<DataExtractorApi.Metadata.ColumnPtf[]> = {}) =>
  api.dataExtractor.metadata.getColumnsPtf.usePrequery({ staleTime, ...props });

export const useGetAppsColumns = ({
  staleTime = Infinity,
  ...props
}: QueryObserverOptions<DataExtractorApi.Metadata.ColumnApp[]> = {}) =>
  api.dataExtractor.metadata.getColumnsApps.useQuery({ staleTime, ...props });

export const usePrefetchAppsColumns = ({
  staleTime = Infinity,
  ...props
}: QueryObserverOptions<DataExtractorApi.Metadata.ColumnApp[]> = {}) =>
  api.dataExtractor.metadata.getColumnsApps.usePrequery({ staleTime, ...props });

export const useGetPreferences = <TData = undefined>({
  staleTime = Infinity,
  select,
}: QueryObserverOptions<
  DataExtractorApi.Preference.Preference[],
  Error,
  TData extends undefined ? DataExtractorApi.Preference.Preference[] : TData
> = {}) => api.dataExtractor.preference.getPreferences.useQuery({ staleTime, select });

export const useCreateTemplate = () => {
  const inv = api.dataExtractor.template.getTemplates.useInvalidateQuery();
  return api.dataExtractor.template.createTemplate.useMutation({ onSuccess: inv });
};

export const useUpdatePreferences = () => {
  const inv = api.dataExtractor.preference.getPreferences.useInvalidateQuery();
  return api.dataExtractor.preference.updatePreferenceAutomatedRequestStatus.useMutation({ onSuccess: inv });
};
