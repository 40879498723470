import { lowerCase, StatusTag } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React from 'react';

type UploadListStatusRendererProps = {
  status: CollectApi.File['status'] | undefined;
  'data-testid': string;
};

export const UploadListStatusRenderer: React.FC<UploadListStatusRendererProps> = React.memo(
  function UploadListStatusRenderer({ status, 'data-testid': testid }) {
    switch (status && lowerCase(status)) {
      case 'rejected':
        return <StatusTag variant="error" label="Rejected" data-testid={testid} />;

      case 'processing':
      case 'upload_in_progress':
      case 'on_going':
        return <StatusTag variant="processing" label="Processing" data-testid={testid} />;

      case 'ok':
      case 'warning':
        return <StatusTag variant="success" label="Accepted" data-testid={testid} />;

      case 'canceled':
      default:
        return <StatusTag variant="error" label="Failed" data-testid={testid} />;
    }
  },
);

export const uploadListStatusRendererPlain = ({ status }: Pick<UploadListStatusRendererProps, 'status'>) => {
  switch (status && lowerCase(status)) {
    case 'rejected':
      return 'Rejected';

    case 'processing':
    case 'upload_in_progress':
    case 'on_going':
      return 'Processing';

    case 'ok':
    case 'warning':
      return 'Accepted';

    case 'canceled':
    default:
      return 'Failed';
  }
};
