import { IconButton, Stack } from '@mui/material';
import { colors, useCallbackImmutable, Typography, Icon, useTestid } from 'mns-components';
import React from 'react';
import { cardStyles } from './selectTemplateStyles';

interface SelectTemplateCardProps {
  templateId: string;
  title: string;
  createdBy: string;
  createdAt: string;
  isSelected?: boolean;
  className?: string;
  onClick(templateId: string): void;
  'data-testid': string;
}

export const SelectTemplateCard: React.FC<SelectTemplateCardProps> = ({
  templateId,
  title,
  createdBy,
  createdAt,
  isSelected,
  onClick,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const handleClick = useCallbackImmutable(() => onClick(templateId));

  return (
    <Stack component="button" textAlign="left" onClick={handleClick} className={cardStyles().card} data-testid={testid}>
      <IconButton color="primary" tabIndex={-1} onClick={handleClick} data-testid={createTestid('button')}>
        <Icon name={isSelected ? 'radioChecked' : 'radio'} data-testid={createTestid('button')} />
      </IconButton>
      <Stack minWidth={0}>
        <Typography
          component="p"
          variant="body1"
          minWidth={0}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          fontWeight={600}
          title={title}
        >
          {title}
        </Typography>
        <Stack direction="row" overflow="hidden" whiteSpace="nowrap">
          <Typography variant="caption" color={colors.disabledColor}>
            by
          </Typography>
          <Typography variant="caption" marginLeft=".125rem">
            {createdBy}
          </Typography>
          <Typography variant="caption" color={colors.disabledColor} marginLeft=".5rem">
            created at
          </Typography>
          <Typography variant="caption" marginLeft=".125rem">
            {createdAt}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
