import type { GridReadyEvent } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Typo,
  convertToDateLocal,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import type { PortfolioData } from '../SelectPtfSection';

const useColumns = (
  selectCol: AggridTableColumn<PortfolioData>,
  extractionType: DataExtractorApi.Extraction.ExtractionType,
  testid: string,
) => {
  const createTestid = useTestid(testid);

  return useMemo(
    (): AggridTableColumn<PortfolioData>[] => [
      selectCol,
      {
        field: 'externalIdValue',
        headerName: 'Portfolio ID',
        tooltipField: 'externalId',
        maxWidth: 200,
        cellRendererFramework: ({ value, data: { portfolioId, valuationDate } }) => (
          <Typo variant="body2" data-testid={createTestid(`${portfolioId}-${valuationDate}-view`)}>
            {value}
          </Typo>
        ),
      },
      {
        field: 'name',
        headerName: 'Portfolio name',
        tooltipField: 'name',
        cellRendererFramework: ({ value, data: { portfolioId, valuationDate } }) => (
          <Typo variant="body2" data-testid={createTestid(`${portfolioId}-${valuationDate}-name`)}>
            {value}
          </Typo>
        ),
      },
      {
        field: 'valuationDate',
        headerName: 'NAV date',
        hide: extractionType == 'AUTOMATIC',
        cellRendererFramework: ({ value, data: { portfolioId, valuationDate } }) => (
          <Typo variant="body2" data-testid={createTestid(`${portfolioId}-${valuationDate}-valuationDate`)}>
            {convertToDateLocal(value)}
          </Typo>
        ),
      },
      {
        field: 'uploadedOn',
        headerName: 'Latest uploaded date',
        hide: extractionType == 'AUTOMATIC',
        cellRendererFramework: ({ value, data: { portfolioId, valuationDate } }) => (
          <Typo variant="body2" data-testid={createTestid(`${portfolioId}-${valuationDate}-uploadedOn`)}>
            {convertToDateLocal(value)}
          </Typo>
        ),
      },
    ],
    [selectCol, createTestid, extractionType],
  );
};

interface SelectPtfSectionGridProps {
  rowData: PortfolioData[];
  searchValue: string;

  extractionType: DataExtractorApi.Extraction.ExtractionType;
  onRowSelected(rows: PortfolioData[]): void;
  onGridReady(event: GridReadyEvent): void;
  'data-testid': string;
}

const SelectPtfSectionGrid = React.memo<SelectPtfSectionGridProps>(function SelectPtfSectionGrid({
  rowData,
  searchValue,
  extractionType,
  onRowSelected,
  onGridReady,
  'data-testid': testid,
}) {
  const [gridProps, selectCol] = useRowSelection<PortfolioData>(
    ({ api }) => onRowSelected(api.getSelectedRows()),
    true,
  );

  return (
    <AggridTable
      checkboxVariant="rose"
      quickFilterText={searchValue}
      rowData={rowData}
      {...gridProps}
      defaultColDef={useDefaultColDef<PortfolioData>()}
      columnDefs={useColumns(selectCol, extractionType, testid)}
      getRowNodeId={useImmutable(
        () =>
          ({ portfolioId, valuationDate }: PortfolioData) =>
            `${portfolioId}-${valuationDate}`,
      )}
      pagination
      paginationAutoPageSize
      onGridReady={onGridReady}
      data-testid={testid}
    />
  );
});

interface SelectPtfSectionListProps {
  rowData: PortfolioData[];
  initialSelectedData: PortfolioData[];
  searchValue: string;
  extractionType: DataExtractorApi.Extraction.ExtractionType;
  onRowSelected: (rows: PortfolioData[]) => void;
  'data-testid': string;
}

export const SelectPtfSectionList: React.FC<SelectPtfSectionListProps> = ({
  initialSelectedData,
  ...props
}: SelectPtfSectionListProps) => {
  // set checked rows
  const autoSize = useAutoSize('fit');
  const handleGridReady = useCallbackImmutable((params: GridReadyEvent) => {
    autoSize(params);
    params.api.forEachNode((node) => {
      const found = initialSelectedData.some(
        ({ portfolioId, valuationDate }) =>
          node.data.portfolioId === portfolioId && node.data.valuationDate === valuationDate,
      );
      if (found) node.setSelected(true);
    });
  });

  return <SelectPtfSectionGrid onGridReady={handleGridReady} {...props} />;
};
