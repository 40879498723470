import React from 'react';

interface SourceRendererProps {
  uploadSource?: string;
  reUpload: boolean;
}

export const SourceRenderer: React.FC<SourceRendererProps> = React.memo(function SourceRenderer({
  uploadSource,
  reUpload,
}: SourceRendererProps) {
  if (!uploadSource) {
    return null;
  }

  switch (uploadSource) {
    case 'EMAIL':
      return <span>Email</span>;
    case 'WEB':
      if (reUpload) {
        return <span>Manaos</span>;
      } else return <span>Web</span>;
    case 'SFTP':
      return <span>Sftp</span>;
    default:
      return <span>Web</span>;
  }
});

export const sourceRendererPlain = ({ uploadSource, reUpload }: SourceRendererProps) => {
  if (!uploadSource) {
    return '';
  }

  switch (uploadSource) {
    case 'EMAIL':
      return 'Email';
    case 'WEB':
      if (reUpload) {
        return 'Manaos';
      } else return 'Web';
    case 'SFTP':
      return 'Sftp';
    default:
      return 'Web';
  }
};
