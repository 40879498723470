import { colors, makeStyles } from 'mns-components';

const replyAccessRequestStyles = makeStyles({
  subTitles: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.blueColor,
    marginTop: '1rem',
  },
  description: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    marginTop: '0.625rem',
    marginBottom: '1rem',
    '& svg': {
      color: colors.infoColor,
      marginRight: '0.625rem',
    },
  },
  loader: {
    '& > div': {
      width: '4rem',
      height: '4rem',
      fontSize: '4rem',
      lineHeight: '4rem',
      color: colors.primaryPinkColor,
      margin: '2rem auto',
    },
  },
  tableHeaders: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '.875rem',
    lineHeight: '1rem',
    textAlign: 'left',
    color: colors.blueColor,
    opacity: '60%',
  },
  tableContent: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '.875rem',
    textAlign: 'left',
    color: colors.blueColor,
    '& svg': {
      verticalAlign: 'middle',
      color: colors.infoColor,
      marginRight: '0.25rem',
    },
  },
  formFooter: {
    textAlign: 'center',
    bottom: 0,
    position: 'absolute',
    width: 'calc(100% - 3rem)',
    height: '4.25rem',
    backgroundColor: `${colors.whiteColor} !important`,
    zIndex: 6666,
    '& > *': {
      margin: '1rem .25rem',
    },
    '& hr': {
      margin: 0,
      backgroundColor: 'rgba(95,122,241, 0.4)',
      height: '1px',
      border: 'none',
    },
  },
  tooltip: {
    cursor: 'pointer',
    background: 'rgba(95,122,241,0.1)',
    color: colors.infoColor,
    borderRadius: '50%',
    height: '1rem',
    width: '1rem',
    marginLeft: '0.5rem',
  },
  errorMessage: {
    color: colors.dangerColor,
    fontSize: '.75rem',
    margin: '0 0 1rem .5rem',
  },
  content: {
    padding: '0 0 3rem 0',
  },
  uniForm: {
    marginTop: '2rem',
    '& > div': {
      minWidth: '16rem',
      margin: '.5rem 0',
    },
  },
  cardTitles: {
    fontSize: '1rem',
    lineHeight: '1.125rem',
    color: '#11113C',
    fontWeight: 'bold',
    margin: '0',
    alignSelf: 'center',
  },
  verticalScrollTable: {
    overflowY: 'clip',
    overflowX: 'auto',
  },
  customSelect: {
    '& .MuiInputBase-root': {
      minHeight: '2.5rem',
    },
  },
});

export default replyAccessRequestStyles;
