import { Stack, Tooltip } from '@mui/material';
import {
  Checkbox,
  Icon,
  Typography,
  lowerCase,
  snakeCase,
  useCallbackImmutable,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { selectDatapointsStyles as useStyles } from './selectDatapointsStyles';

type SelectDatapointProps = {
  datapoint: DataExtractorApi.Metadata.ColumnApp & { description?: string };
  isChecked: boolean;
  onCheck(datapoint: string): void;
  'data-testid': string;
};

const SelectDatapoint: React.FC<SelectDatapointProps> = ({
  datapoint: { code, required, description },
  isChecked,
  onCheck,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      justifyContent="left"
      alignItems="center"
      className={classes.datapointRow}
      data-testid={testid}
      onClick={useImmutable(() => () => onCheck(code))}
    >
      <Checkbox
        uncontrolled
        variant="rose"
        name={code}
        checked={isChecked}
        disabled={required}
        data-testid={createTestid('checkbox')}
      />
      {description ? (
        <Tooltip arrow placement="right" title={description}>
          <div>
            <Icon size="small" name="info" color="secondary" data-testid={createTestid('icon-description')} />
          </div>
        </Tooltip>
      ) : (
        <Icon size="small" name="info" color="disabled" data-testid={createTestid('icon-description')} />
      )}
      <Typography.Ellipsis variant="body2" fontWeight={500} marginLeft=".5rem" title={code}>
        {code}
      </Typography.Ellipsis>
    </Stack>
  );
};

type SelectDatapointsListProps = {
  category: string;
  categoryName: string;
  datapoints: (DataExtractorApi.Metadata.ColumnApp & { description?: string })[];
  selectedDatapoints: string[];
  onCheck(datapoint: string): void;
  onCheckAll(category: string): void;
  'data-testid': string;
};

export const SelectDatapointsList: React.FC<SelectDatapointsListProps> = ({
  category,
  categoryName,
  datapoints,
  selectedDatapoints,
  onCheck,
  onCheckAll,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return (
    <Stack direction="column" width="100%" minWidth={0} data-testid={testid}>
      <Stack
        direction="row"
        justifyContent="left"
        alignItems="center"
        className={classes.datapointsHead}
        data-testid={createTestid('checkboxAll')}
        onClick={useCallbackImmutable(() => onCheckAll(category))}
      >
        <Checkbox
          uncontrolled
          variant="rose"
          name={category}
          checked={selectedDatapoints.length >= datapoints.length}
          indeterminate={selectedDatapoints.length > 0}
          data-testid={createTestid('checkbox')}
        />
        <Typography.Ellipsis variant="body2" fontWeight={500} title={categoryName}>
          {categoryName}
        </Typography.Ellipsis>
      </Stack>
      <Stack direction="column" overflow="clip auto" paddingBottom="1rem">
        {datapoints.map((datapoint) => (
          <SelectDatapoint
            key={datapoint.code}
            datapoint={datapoint}
            isChecked={selectedDatapoints.includes(datapoint.code)}
            onCheck={onCheck}
            data-testid={createTestid(lowerCase(snakeCase(datapoint.code)))}
          />
        ))}
      </Stack>
    </Stack>
  );
};
