import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { QualitiesRenderer } from '../../cellRender/QualitiesRenderer';
import { QualitiesHeaderRenderer } from '../../headerRender/QualitiesHeaderRenderer';
import { DownloadFilesRenderer } from './DownloadFilesRenderer';
import { HeaderStatusRenderer } from './HeaderStatusRenderer';
import { SourceRenderer, sourceRendererPlain } from './SourceRenderer';
import { uploadByRendererPlain, UploadedByRenderer } from './UploadedByRenderer';
import { UploadListStatusRenderer, uploadListStatusRendererPlain } from './UploadListStatusRenderer';

interface RendererProps {
  value?: string;
  data: CollectApi.File;
}
const getActionColDef = makeActionColDef<CollectApi.File>();

const useColumns = (
  selectCol: AggridTableColumn<CollectApi.File>,
  testid: string,
  openErrorLogModal: (fileName: string, processID: string) => void,
) => {
  const createTestid = useTestid(testid);
  return useMemo(
    (): AggridTableColumn<CollectApi.File>[] => [
      selectCol,
      {
        field: 'name',
        headerName: 'File name',
        tooltipField: 'name',
        minWidth: 300,
      },
      {
        field: 'dataFormat',
        headerName: 'File format',
      },
      {
        field: 'uploadedOn',
        headerName: 'Upload date',
        sortable: true,
        minWidth: 150,
        sort: 'desc',
        comparator: (a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          return +dateA > +dateB ? 1 : -1;
        },
        valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.uploadedOn),
        cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.uploadedOn),
      },
      {
        field: 'uploadSource',
        colId: 'uploadAuthor',
        headerName: 'Uploaded by',
        sortable: true,
        valueGetter: ({ data }) => uploadByRendererPlain({ data }),
        cellRendererFramework: ({ data }: RendererProps) => <UploadedByRenderer data={data} />,
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        headerComponentFramework: () => <HeaderStatusRenderer data-testid={createTestid('status')} />,
        minWidth: 140,
        valueGetter: ({ data }) => uploadListStatusRendererPlain({ status: data.status }),
        cellRendererFramework: ({ data: { processId, status } }) => (
          <UploadListStatusRenderer status={status} data-testid={createTestid(`${processId}-status`)} />
        ),
      },
      {
        field: 'uploadSource',
        headerName: 'Source',
        sortable: true,
        minWidth: 70,
        maxWidth: 120,
        valueGetter: ({ data: { uploadSource, reUpload } }) => sourceRendererPlain({ uploadSource, reUpload }),
        cellRendererFramework: ({ data: { uploadSource, reUpload } }) => (
          <SourceRenderer uploadSource={uploadSource} reUpload={reUpload} />
        ),
        headerComponentParams: { enableFilterIcon: true },
      },
      {
        field: 'qualities',
        headerName: 'Qualities',
        sortable: true,
        minWidth: 100,
        cellRendererFramework: ({ value, data: { processId } }) => (
          <QualitiesRenderer qualities={value} data-testid={createTestid(`${processId}-qualities`)} />
        ),
        headerComponentFramework: () => <QualitiesHeaderRenderer data-testid={createTestid('qualities')} />,
      },
      getActionColDef('status', 100, ({ data: { name, processId, status } }) => (
        <DownloadFilesRenderer
          name={name}
          processId={processId}
          status={status}
          openErrorLogModal={openErrorLogModal}
          data-testid={createTestid(processId)}
        />
      )),
    ],
    [selectCol, createTestid, openErrorLogModal],
  );
};

interface FilesListProps {
  rowData: CollectApi.File[];
  searchValue?: string;
  onRowSelected: (rows: CollectApi.File[]) => void;
  getGridApiref?: (api: GridApi | null) => void;
  openErrorLogModal: (fileName: string, processID: string) => void;
  'data-testid': string;
}

export const FilesList: React.FC<FilesListProps> = React.memo(function FilesList({
  rowData,
  searchValue = '',
  onRowSelected,
  getGridApiref: setGridApi,
  openErrorLogModal,
  'data-testid': testid,
}: FilesListProps) {
  const [props, selectCol] = useRowSelection<CollectApi.File>(({ api }) => onRowSelected(api.getSelectedRows()), true);

  return (
    <AggridTable
      {...props}
      quickFilterText={searchValue}
      rowData={rowData}
      defaultColDef={useDefaultColDef<CollectApi.File>()}
      columnDefs={useColumns(selectCol, testid, openErrorLogModal)}
      getRowNodeId={useImmutable(
        () =>
          ({ processId }: CollectApi.File) =>
            processId,
      )}
      getGridApiRef={setGridApi}
      pagination
      paginationAutoPageSize
      onGridReady={useAutoSize('fit')}
      data-testid={testid}
    />
  );
});
