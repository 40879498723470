import { Stack } from '@mui/material';
import type { ToggleButtonGroupItem } from 'mns-components';
import { AppContent, ToggleButtonGroup, useCallbackImmutable, useTestid } from 'mns-components';
import { useState } from 'react';
import { useUser } from '../../../hooks/useAuth';
import { IsinListComp } from '../../isinList/IsinListComp';
import { UserInformation } from './UserInformation';

const toggleList: ToggleButtonGroupItem<string>[] = [
  { value: 'information', children: 'User information' },
  { value: 'scope', children: 'Portfolio scopes' },
];

type UserSettingsPageProps = {
  'data-testid': string;
};

export const UserSettingsPage: React.FC<UserSettingsPageProps> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const { isAdmin, apps } = useUser();
  const showToggle = isAdmin && apps.includes('APP_USER_SCOPES-BETA');

  const [toggleValue, setToggleValue] = useState<string>(toggleList[0].value);

  const handleToggle = useCallbackImmutable((_, value) => {
    if (value) {
      setToggleValue(value);
    }
  });

  return (
    <AppContent variant="expanded">
      {showToggle && (
        <Stack paddingX="2rem">
          <ToggleButtonGroup.Uncontrolled
            value={toggleValue}
            exclusive
            list={toggleList}
            onChange={handleToggle}
            data-testid={createTestid('toggle-button-group')}
          />
        </Stack>
      )}
      {toggleValue.includes('information') && <UserInformation data-testid={createTestid('information')} />}
      {toggleValue.includes('scope') && <IsinListComp testId={createTestid('scope')} variant="USER_SCOPE" />}
    </AppContent>
  );
};
