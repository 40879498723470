import { Box, Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn } from 'mns-components';
import { AggridTable, Typo, makeStyles, useAutoSize, useDefaultColDef, useImmutable } from 'mns-components';
import type { AnalysisApi } from 'mns-sdk-collect';
import React from 'react';
import { GreatScoringRenderer } from './GreatScoringRenderer';

const customTableHeaderStyles = makeStyles({
  container: {
    display: 'inline-grid',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
});

const CustomTableHeader = ({ title }: { title: string }) => (
  <Box className={customTableHeaderStyles().container}>
    <Tooltip title={title}>
      <Typo.Ellipsis component="span" variant="body2" title={title}>
        {title}
      </Typo.Ellipsis>
    </Tooltip>
  </Box>
);

const columns: AggridTableColumn<AnalysisApi.WeightedIsin>[] = [
  {
    field: 'companyName',
    minWidth: 140,
    maxWidth: 200,
    headerComponentFramework: () => <CustomTableHeader title="Company Name" />,
  },
  {
    field: 'weight',
    sort: 'desc',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Weight (%)" />,
    valueFormatter: ({ value }) => (value * 100).toFixed(1),
  },
  {
    field: 'noteGreat',
    cellClass: 'ag-header-center-label',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Great scoring" />,
    cellRendererFramework: ({ value }) => <GreatScoringRenderer value={value} />,
  },
  {
    field: 'energyAndEconomicTransition',
    cellClass: 'ag-header-center-label',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Energy And Economic Transition" />,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
  {
    field: 'localTerritories',
    cellClass: 'ag-header-center-label',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Local Territories" />,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
  {
    field: 'sustainableManagementOfResources',
    cellClass: 'ag-header-center-label',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Sustainable Management Of Resources" />,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
  {
    field: 'responsibleGovernance',
    cellClass: 'ag-header-center-label',
    minWidth: 80,
    headerComponentFramework: () => <CustomTableHeader title="Responsible Governance" />,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
];

interface GreatListProps {
  rowData: AnalysisApi.WeightedIsin[];
  getGridApiRef?(api: GridApi | null): void;
  'data-testid': string;
}

export const GreatList: React.FC<GreatListProps> = ({ rowData, getGridApiRef, 'data-testid': testid }) => {
  return (
    <AggridTable
      rowData={rowData}
      columnDefs={columns}
      defaultColDef={useDefaultColDef<AnalysisApi.WeightedIsin>()}
      getRowNodeId={useImmutable(
        () =>
          ({ companyName }: AnalysisApi.WeightedIsin) =>
            companyName,
      )}
      getGridApiRef={getGridApiRef}
      onGridReady={useAutoSize('fit')}
      data-testid={testid}
    />
  );
};
