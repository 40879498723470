import { isObject, objectHas } from 'mns-components';
import type { AccountApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import type { QueryObserverOptions } from 'react-query';
import { getApplicationConfig } from '../../common/getApplicationConfig';
import { useUser } from '../../hooks/useAuth';
import { useEmailDelivery } from '../../hooks/useUserSettings';
import { api } from '../../store/api';
import { applicationsSettings as applicationsSettingsRaw } from './applications/applicationView';
import type { SettingRouterInterface } from './routes';
import { settingsRoutes } from './routes';

export const useOrganisationUsers = <TSelect = AccountApi.User[]>(
  options?: QueryObserverOptions<AccountApi.User[], unknown, TSelect>,
) => api.account.getUsers.useQuery(options);
export const useOrganisationUsersInvalidate = () => api.account.getUsers.useInvalidateQuery();

export const useCreateUser = () => api.account.postUser.useMutation({ onSuccess: useOrganisationUsersInvalidate() });
export const useDeleteUser = () => api.account.deleteUser.useMutation({ onSuccess: useOrganisationUsersInvalidate() });
export const useUpdateUser = () => api.account.putUser.useMutation({ onSuccess: useOrganisationUsersInvalidate() });

export const useUserSettingsRoutes = () => {
  const { apps: roles } = useUser();
  const [applicationsSettings] = useState(() =>
    applicationsSettingsRaw.filter(({ appCode }) => roles.includes(getApplicationConfig(appCode).config.role)),
  );
  const firstAppCode = applicationsSettings?.[0]?.appCode;
  const adminAndFirstAppCode = roles.includes('ROLE_ADMIN') && firstAppCode;
  const emailDeliveryActive = useEmailDelivery(firstAppCode);

  return useMemo(() => {
    const rules = {
      emailDeliveryActive,
      firstAppCode: firstAppCode && { appCode: firstAppCode },
      adminAndFirstAppCode: adminAndFirstAppCode && { appCode: adminAndFirstAppCode },
    };

    return settingsRoutes.reduce((acc, rawRoute) => {
      const role = rawRoute.expectRole;
      if (!role) {
        acc.push(rawRoute);
      } else {
        if (objectHas(rules, role)) {
          const value = rules[role];
          if (isObject(value)) {
            acc.push({
              ...rawRoute,
              linkDefaultArgs: value,
            });
          } else if (value) {
            acc.push(rawRoute);
          }
        } else if (roles.includes(role)) {
          acc.push(rawRoute);
        }
      }
      return acc;
    }, [] as SettingRouterInterface[]);
  }, [adminAndFirstAppCode, emailDeliveryActive, firstAppCode, roles]);
};
