import { Stack } from '@mui/material';
import { Typo } from 'mns-components';
import { SelectTemplate, type TemplateData } from '../ExtractionFunnel/SelectTemplate';

type SelectTemplateContainerProps = {
  disabled?: boolean;
  onChange(formData: TemplateData): void;
  'data-testid': string;
};

export const SelectTemplateContainer: React.FC<SelectTemplateContainerProps> = ({
  disabled,
  onChange,
  'data-testid': testid,
}) => {
  return (
    <Stack data-testid={testid} gap="1rem">
      <Typo variant="body2">
        To create your template, you can either{' '}
        <Typo variant="body2medium" component="span">
          start from scratch
        </Typo>{' '}
        (ESG apps) or{' '}
        <Typo variant="body2medium" component="span">
          from an existing template
        </Typo>{' '}
        (My templates, Manaos templates).
      </Typo>
      <SelectTemplate disabled={disabled} onChange={onChange} data-testid={testid} />
    </Stack>
  );
};
