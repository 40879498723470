import type { GridApi } from 'ag-grid-community';
import { Button, Dialogv2, Icon, useTestid } from 'mns-components';
import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateMapping } from '../hooks';
import { CreateFundMappingForm } from './FundMappingModalInputs';

type FundMappingCreateModalProps = {
  open: boolean;
  onClose(): void;
  gridApi: MutableRefObject<GridApi | null>;
  'data-testid': string;
};

export const FundMappingCreateModal: React.FC<FundMappingCreateModalProps> = ({
  open,
  onClose,
  gridApi,
  'data-testid': testid,
}) => {
  const formName = 'form-fund-mapping-create';
  const createTestid = useTestid(testid);

  const { mutate: createFundMapping, isSuccess, isError, isLoading } = useCreateMapping();

  useEffect(() => {
    if (isSuccess) {
      gridApi.current?.refreshServerSideStore({ purge: true });
      onClose();
      toast.success('Mapping successfuly created');
    }
    if (isError) {
      onClose();
      toast.error('Failed to create mapping');
    }
  }, [isSuccess, onClose, isError, gridApi]);

  return (
    <Dialogv2
      open={open}
      onClose={onClose}
      dialogTitle="Create mapping"
      DialogTitleIcon={Icon.Add}
      data-testid={createTestid('dialog')}
      otherButtons={
        <Button
          color="secondary"
          loading={isLoading}
          type="submit"
          form={formName}
          data-testid={createTestid('confirm')}
        >
          Create mapping
        </Button>
      }
    >
      <CreateFundMappingForm onSave={createFundMapping} data-testid="test" formId={formName} />
    </Dialogv2>
  );
};
