import { Amplify } from 'aws-amplify';
import { buildQueryString } from 'mns-sdk-collect';
import {
  COGNITO_REGION,
  COGNITO_POOL_ID,
  COGNITO_WEB_CLIENT_ID,
  COGNITO_SSO_DOMAIN,
  COGNITO_IDP_DOMAIN,
  CLOUDFRONT_DNSNAME,
  IS_LOCALHOST,
} from '../app.constants';
import { routes as authRoutes } from '../components/auth/routes';

const { origin } = new URL(window.location.href);

const bp2sLogin = () => {
  Amplify.configure({
    Auth: {
      region: COGNITO_REGION,
      userPoolId: COGNITO_POOL_ID,
      userPoolWebClientId: COGNITO_WEB_CLIENT_ID,
      oauth: {
        domain: COGNITO_SSO_DOMAIN,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: `${origin}/sso`,
        redirectSignOut: `${origin}/logout`,
        responseType: 'code',
        redirectUri: origin,
      },
      cookieStorage: {
        domain: CLOUDFRONT_DNSNAME,
        expires: null, // null means session cookies
        path: '/',
        secure: !IS_LOCALHOST, // for developing on localhost over http: set to false
        sameSite: 'lax',
      },
    },
  });
};

const cognitoLogin = () => {
  Amplify.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    aws_cognito_region: COGNITO_REGION,
    aws_user_pools_id: COGNITO_POOL_ID,
    aws_user_pools_web_client_id: COGNITO_WEB_CLIENT_ID,
    endpoint: 'https://' + COGNITO_IDP_DOMAIN + '/',
    cookieStorage: {
      domain: CLOUDFRONT_DNSNAME,
      expires: null, // null means session cookies
      path: '/',
      secure: !IS_LOCALHOST, // for developing on localhost over http: set to false
      sameSite: 'lax',
    },
  });
};

const providerSsoRecordRaw = {
  BP2S: bp2sLogin,
  cognito: cognitoLogin,
} as const;

export type ProviderSso = keyof typeof providerSsoRecordRaw;

export const providerSsoRecord = providerSsoRecordRaw as Record<ProviderSso, () => void>;

export const providerSsoLogoutUriRecord: Record<ProviderSso, string> = {
  BP2S: `https://ssologinrec.bnpparibas.com/SSOLogoutAction.do${buildQueryString({
    redirect_uri: origin,
    logout_uri: origin,
  })}`,
  cognito: authRoutes.loginUri.path,
};
