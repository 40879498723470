import { Dialog, Icon } from 'mns-components';
import { ImportCreatePortfolio } from './ImportCreatePortfolio';

type CurrentApp = 'collect' | 'dissemination' | 'myPortfolios' | 'other';

type DialogAddPortfolioProps = {
  currentApp?: CurrentApp;
  isOpenModal: boolean;
  onClose?(): void;
  'data-testid': string;
};

export const DialogAddPortfolio: React.FC<DialogAddPortfolioProps> = ({
  currentApp,
  isOpenModal,
  onClose,
  'data-testid': testid,
}: DialogAddPortfolioProps) => (
  <Dialog
    StartIcon={Icon.Upload}
    dialogTitle="Import your portfolio"
    open={isOpenModal}
    onClose={onClose}
    data-testid={`${testid}-dialog`}
  >
    <ImportCreatePortfolio closeDialog={onClose} currentApp={currentApp} data-testid={testid} />
  </Dialog>
);
