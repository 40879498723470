import type { ListProps } from 'mns-components';
import { List, useCallbackImmutable, useTestid } from 'mns-components';
import { useState } from 'react';

type SelectRecipientsStepProps = {
  emailListOptions: ListProps<string>['items'];
  emailList: string[];
  setEmailList: React.Dispatch<React.SetStateAction<string[]>>;
  'data-testid': string;
};

export const SelectRecipientsList: React.FC<SelectRecipientsStepProps> = ({
  emailListOptions,
  emailList,
  setEmailList,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const handleCheck = useCallbackImmutable((value: string) =>
    setEmailList((current) => {
      const index = current.indexOf(value);
      if (index >= 0) {
        const newList = current.slice();
        newList.splice(index, 1);
        return newList;
      } else {
        return [value, ...current];
      }
    }),
  );

  const [collapsed, setCollapsed] = useState<string[]>([]);
  const handleToggleCollapse = useCallbackImmutable((value: string) =>
    setCollapsed((current) => {
      const index = current.indexOf(value);
      if (index >= 0) {
        const newList = current.slice();
        newList.splice(index, 1);
        return newList;
      } else {
        return [value, ...current];
      }
    }),
  );

  if (!emailListOptions?.length) {
    return null;
  }

  return (
    <List
      items={emailListOptions}
      checked={emailList}
      onCheck={handleCheck}
      collapsed={collapsed}
      onToggleCollapse={handleToggleCollapse}
      data-testid={createTestid('emailList')}
    />
  );
};
