import { Auth } from 'aws-amplify';
import {
  buildAxios,
  AccountApi,
  AccountNoAuthApi,
  AnalysisApi,
  CollectApi,
  CoverageApi,
  DataDeliveryApi,
  DataExtractorApi,
  DownloadApi,
  EetApi,
  PaiApi,
  EtlApi,
  MarketplaceApi,
  NotificationApi,
  ProviderApi,
  WidgetApi,
  LookthroughApi,
  ClaritySfdrApi,
  Bp2sApi,
  IsinListApi,
  EmailListApi,
  DatalakeApi,
} from 'mns-sdk-collect';
import { appCodes } from '../components/views/appDescriptions';

const baseUrl = process.env.REACT_APP_URL_API_PROVIDER || 'http://localhost:8000';
const axios = buildAxios(baseUrl, Auth);

/** @deprecated please use `api` from `./store/api` */
export const analysisApi = AnalysisApi.build(axios, appCodes);
/** @deprecated please use `api` from `./store/api` */
export const appProviderApi = ProviderApi.build(axios);
/** @deprecated please use `api` from `./store/api` */
export const coverageApi = CoverageApi.build(axios);
/** @deprecated please use `api` from `./store/api` */
export const widgetApi = WidgetApi.build(axios);
/** @deprecated please use `api` from `./store/api` */
export const appBp2s = Bp2sApi.build(axios);
/** @deprecated please use `api` from `./store/api` */
export const downloadApi = DownloadApi.build(axios);

const accountUrl = process.env.REACT_APP_URL_API_ACCOUNT || 'http://localhost:8000';
const accountAxios = buildAxios(accountUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const accountApi = AccountApi.build(accountAxios);
const accountNoAuthAxios = buildAxios(accountUrl);
/** @deprecated please use `api` from `./store/api` */
export const accountNoAuthApi = AccountNoAuthApi.build(accountNoAuthAxios);

const claritySfdrUrl = process.env.REACT_APP_CLARITY_SFDR_URL || 'http://localhost:8000';
const claritySfdrAxios = buildAxios(claritySfdrUrl, Auth, { applicationCode: 'clarity-sfdr' });
/** @deprecated please use `api` from `./store/api` */
export const appProviderClaritySfdr = ClaritySfdrApi.build(claritySfdrAxios);

const collectUrl = process.env.REACT_APP_URL_API_COLLECT || 'http://localhost:8000';
const collectAxios = buildAxios(collectUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const appCollectApi = CollectApi.build(collectAxios);
/** @deprecated please use `api` from `./store/api` */
export const dataDeliveryApi = DataDeliveryApi.build(collectAxios);
/** @deprecated please use `api` from `./store/api` */
export const isinListsApi = IsinListApi.build(collectAxios);
/** @deprecated please use `api` from `./store/api` */
export const emailListApi = EmailListApi.build(collectAxios);

const dataExtractorUrl = process.env.REACT_APP_URL_API_DATA_EXTRACTOR || 'http://localhost:8000';
const dataExtractorAxios = buildAxios(dataExtractorUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const dataExtractorApi = DataExtractorApi.build(dataExtractorAxios);

const datalakeUrl = process.env.REACT_APP_URL_API_DATALAKE || 'http://localhost:8000';
const datalakeAxios = buildAxios(datalakeUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const datalakeApi = DatalakeApi.build(datalakeAxios);

const eetBaseUrl = process.env.REACT_APP_URL_API_EET || 'http://localhost:3000';
const eetAxios = buildAxios(eetBaseUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const eetApi = EetApi.build(eetAxios, appCodes);

const paiBaseUrl = process.env.REACT_APP_URL_API_PAI || 'http://localhost:3000';
const paiAxios = buildAxios(paiBaseUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const paiApi = PaiApi.build(paiAxios, appCodes);

const etlBaseUrl = process.env.REACT_APP_URL_API_ETL || 'http://localhost:8000';
const etlAxios = buildAxios(etlBaseUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const etlApi = EtlApi.build(etlAxios);

const looktroughUrl = process.env.REACT_APP_URL_API_LOOKTROUGH || 'http://localhost:8000';
const looktroughAxios = buildAxios(looktroughUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const looktroughApi = LookthroughApi.build(looktroughAxios);

const marketplaceUrl = process.env.REACT_APP_URL_API_MARKETPLACE || 'http://localhost:8000';
const marketplaceAxios = buildAxios(marketplaceUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const appMarketplaceApi = MarketplaceApi.build(marketplaceAxios);

const notificationUrl = process.env.REACT_APP_URL_API_NOTIFICATION || 'http://localhost:8000';
const notificationAxios = buildAxios(notificationUrl, Auth);
/** @deprecated please use `api` from `./store/api` */
export const appNotification = NotificationApi.build(notificationAxios);
