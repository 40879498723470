import type { AxiosError } from 'axios';
import {
  Accordeon,
  AlertMessage,
  Button,
  Dialogv2,
  Icon,
  Typography,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { isAxiosError, isErrorBodyMatch, type IsinListApi } from 'mns-sdk-collect';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDeleteIsinListRequest } from '../hooks';
import { modalStyles as useStyles } from './modalStyles';

type DeleteIsinListModalProps = {
  isinList?: IsinListApi.List;
  onClose(): void;
  variant: 'ISIN' | 'USER_SCOPE';
  'data-testid': string;
};
export const DeleteIsinListModal: React.FC<DeleteIsinListModalProps> = ({
  isinList,
  onClose,
  variant,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const [errorType, setErrorType] = useState<'ISIN' | 'USER_SCOPE' | undefined>();

  const { mutate: deleteList, isLoading: isDeleting, isSuccess: isListDeleted, error } = useDeleteIsinListRequest();
  const handleDeleteConfirmed = useCallbackImmutable(() => isinList && deleteList(isinList.id));

  const handleClose = useCallbackImmutable(() => {
    if (onClose) onClose();
    setErrorType(undefined);
  });

  useEffect(() => {
    if (isListDeleted && !errorType) {
      handleClose();
      toast.success(`List successfuly deleted`);
    }
  }, [isListDeleted, onClose, handleClose, errorType]);

  useEffect(() => {
    const checkError = (errorData?: AxiosError) => {
      if (
        isErrorBodyMatch(errorData?.response?.data, {
          field: 'isinListId',
          code: 'IN_USE',
          detail: 'manaos-email-delivery-automated-task',
        })
      )
        setErrorType('ISIN');
      if (
        isErrorBodyMatch(errorData?.response?.data, {
          field: 'isinListId',
          code: 'IN_USE',
          detail: 'manaos-user-scope',
        })
      )
        setErrorType('USER_SCOPE');
    };
    if (error && isAxiosError(error)) checkError(error);
  }, [error]);

  return (
    <Dialogv2
      open={!!isinList}
      onClose={handleClose}
      dialogTitle={variant === 'ISIN' ? 'Delete list' : 'Delete portfolio scope'}
      DialogTitleIcon={Icon.Delete}
      dialogTitleIconColor="danger"
      otherButtons={
        <Button
          color="danger"
          loading={isDeleting}
          type="submit"
          onClick={handleDeleteConfirmed}
          disabled={!!errorType}
          data-testid={`${testid}-confirm`}
        >
          {variant === 'ISIN' ? 'Delete list' : 'Delete portfolio scope'}
        </Button>
      }
      data-testid={createTestid('main')}
    >
      {isinList && (
        <div className={classes.fullHeight}>
          <Accordeon variant="outlined" title={isinList.name} data-testid={createTestid('accordeon')} defaultOpen>
            <AlertMessage
              data-testid={createTestid('alert-message')}
              variant={errorType ? 'error' : 'warning'}
              margin="1rem"
            >
              {!errorType ? (
                <>
                  {variant == 'ISIN' && (
                    <>
                      <Typography variant="body2">
                        You are about to delete the list <strong>{`"${isinList.name}"`}</strong>.
                      </Typography>
                      <Typography variant="body2">
                        This list will no longer be available to restrict portfolios access to your users
                      </Typography>
                    </>
                  )}
                  {variant == 'USER_SCOPE' && (
                    <>
                      <Typography variant="body2">
                        You are about to delete the portfolio scope <strong>{`"${isinList.name}"`}</strong>.
                      </Typography>
                      <Typography variant="body2">
                        This portfolio scope will no longer be available to restrict portfolios access to your users
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <>
                  {errorType === 'ISIN' && (
                    <>
                      <Typography variant="body2">
                        As this ISIN list <strong>{`"${isinList.name}"`}</strong> is used in scheduled email deliveries,
                        you cannot delete it.
                      </Typography>
                      <Typography variant="body2">
                        <strong>
                          Please remove this ISIN list from your scheduled email deliveries before deleting it.
                        </strong>
                      </Typography>
                    </>
                  )}
                  {errorType === 'USER_SCOPE' && (
                    <>
                      <Typography variant="body2">
                        As this portfolio scope <strong>{`"${isinList.name}"`}</strong> is currently used to restrict
                        access to users, you cannot delete it.
                      </Typography>
                      <Typography variant="body2">
                        <strong>Please remove access to your users before deleting it.</strong>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </AlertMessage>
            {!errorType && (
              <Typography variant="body2" borderBottom="0 !important" margin="0 0 1rem 1rem" fontSize="0.875rem">
                <strong>Do you confirm?</strong>
              </Typography>
            )}
          </Accordeon>
        </div>
      )}
    </Dialogv2>
  );
};
