import { Button, Icon, useCallbackImmutable, useTestid } from 'mns-components';
import type { AnalysisApi } from 'mns-sdk-collect';
import React from 'react';
import { downloadFile } from '../../../../../common/utils';
import type { AppCode } from '../../../../../components/views/appDescriptions';
import { api } from '../../../../../store/api';

export interface DownloadPdfButtonProps {
  data: AnalysisApi.AnalysisPortfolio<AppCode>;
  appCode: string;
  rendererLocation?: 'grid' | 'appHeader';
  'data-testid': string;
}

export const DownloadPdfButton: React.FC<DownloadPdfButtonProps> = ({
  data,
  appCode,
  rendererLocation,
  'data-testid': testid,
}: DownloadPdfButtonProps) => {
  const createTestid = useTestid(testid);
  const handleClick = useCallbackImmutable(async (): Promise<void> => {
    const currentAnalysedFile = await api.provider.download.downloadRequest.raw({
      appCode,
      requestId: data.analysisId,
    });
    if (!currentAnalysedFile || currentAnalysedFile.length === 0) return;
    currentAnalysedFile.forEach((file) => {
      downloadFile(file.presignedUrl, file.fileName);
    });
  });

  if (data.status !== 'complete') return null;

  if (rendererLocation === 'appHeader') {
    return (
      <Button color="primary" size="medium" onClick={handleClick} data-testid={createTestid('btn-download')}>
        Download
      </Button>
    );
  }

  return (
    <Button
      color="primary"
      outlined
      size="small"
      startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
      onClick={handleClick}
      data-testid={createTestid('btn-download-files')}
    >
      Download files
    </Button>
  );
};
