import { Grid } from '@mui/material';
import type { SubIndicators } from 'mns-components';
import { BigKpiCard, colors } from 'mns-components';
import React, { useMemo } from 'react';
import { useRecipentAccessRequests } from '../../../../hooks/useGlobalState';

type AccessesKpisProps = { 'data-testid': string };

export const AccessesKpis: React.FC<AccessesKpisProps> = ({ 'data-testid': testid }: AccessesKpisProps) => {
  const { data: authorizationList } = useRecipentAccessRequests();

  const pendingKPI = useMemo(
    () => authorizationList?.filter((a) => a.status === 'PENDING').length ?? 0,
    [authorizationList],
  );
  const activeKPI = useMemo(
    () => authorizationList?.filter((a) => a.status === 'ACCEPTED').length ?? 0,
    [authorizationList],
  );
  const inactiveKPI = useMemo(
    () => authorizationList?.filter((a) => a.status === 'REFUSED' || a.status === 'REVOKED').length ?? 0,
    [authorizationList],
  );

  if (!authorizationList) {
    return null;
  }

  const subAccessesIndicators: SubIndicators[] = [
    {
      key: 'pending',
      label: 'pending',
      value: pendingKPI,
      color: pendingKPI > 0 ? colors.orangeColor : colors.blueColor,
    },
    {
      key: 'active',
      label: 'active',
      value: activeKPI,
      color: colors.blueColor,
    },
    {
      key: 'inactive',
      label: 'inactive',
      value: inactiveKPI,
      color: colors.blueColor,
    },
  ];

  const total = authorizationList.length;

  return (
    <Grid item>
      <BigKpiCard
        iconName="vpnkey"
        indicatorNumber={total}
        indicatorDescription="Access requests"
        subIndicators={subAccessesIndicators}
        data-testid={testid}
      />
    </Grid>
  );
};
