import { Stack } from '@mui/material';
import type { OptionType } from 'mns-components';
import { Button, Icon, Select, useSetFormValues, useTestid } from 'mns-components';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { getApplicationConfig, getApplicationPipeTitleFromConfig } from '../../../common/getApplicationConfig';
import { isAppCode, type AppCode } from '../../../components/views/appDescriptions';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';

export type ViewType = 'aggregated' | 'underlying';

const viewTypeOptions: OptionType<ViewType>[] = [
  {
    label: 'Underlying data',
    value: 'underlying',
  },
  {
    label: 'Aggregated data',
    value: 'aggregated',
  },
];

export type FormData = Partial<{ appCode: AppCode; externalId: string; navDate: string; type: ViewType }>;

type AnalysisDetailsControllerProps = {
  appCodes: AppCode[];
  onExport?(): void;
  'data-testid': string;
};

export const AnalysisDetailsController: React.FC<AnalysisDetailsControllerProps> = ({
  appCodes,
  onExport,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const { watch, setValue } = useFormContext<FormData>();
  const setFormValues = useSetFormValues(setValue);
  const [selectedSource, selectedExternalId, selectedNavDate] = watch(['appCode', 'externalId', 'navDate']);

  const { data: ptfList, isSuccess: isSuccessPtfList } = useFetchAllPortfolios();

  // available sources are 'state' type apps, which are matching with underlying data schema
  const sourceOptions = useMemo(() => {
    return appCodes
      .reduce((acc, appCode) => {
        if (isAppCode(appCode)) {
          const appConfig = getApplicationConfig(appCode);
          if (appConfig.config.portfolioViewType === 'state') {
            acc.push({
              label: getApplicationPipeTitleFromConfig(appConfig),
              value: appCode,
            });
          }
        }
        return acc;
      }, [] as OptionType<AppCode>[])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [appCodes]);

  const ptfOptions = useMemo(() => {
    if (ptfList) {
      const portfolios = Array.from(
        ptfList.reduce((acc, ptf) => {
          acc.add(ptf.externalId.value);
          return acc;
        }, new Set<string>()),
      );
      return Array.from(portfolios)
        .sort()
        .map(
          (ptfId): OptionType => ({
            label: ptfId,
            value: ptfId,
          }),
        );
    }
    return [];
  }, [ptfList]);

  const navDateOptions = useMemo(() => {
    if (ptfList) {
      const navDates = ptfList.reduce((acc, ptf) => {
        if (ptf.externalId.value === selectedExternalId) {
          acc.add(ptf.latestVersion.valuationDate);
          ptf.valuationDates.forEach((navDate) => acc.add(navDate));
        }
        return acc;
      }, new Set<string>());
      return Array.from(navDates)
        .sort((a, b) => b.localeCompare(a))
        .map(
          (navDate): OptionType => ({
            label: navDate,
            value: navDate,
          }),
        );
    }
    return [];
  }, [ptfList, selectedExternalId]);

  const selectRef = useRef<{ node: HTMLInputElement } | null>(null);

  useEffect(() => {
    if (sourceOptions.length && (!selectedSource || !sourceOptions.find((opt) => opt.value === selectedSource))) {
      setFormValues({ appCode: sourceOptions[0].value });
    }
  }, [selectedSource, sourceOptions, setFormValues]);

  useEffect(() => {
    if (ptfOptions.length && (!selectedExternalId || !ptfOptions.find((opt) => opt.value === selectedExternalId))) {
      setFormValues({ externalId: ptfOptions[0].value });
    }
  }, [ptfOptions, selectedExternalId, setFormValues]);

  useEffect(() => {
    if (navDateOptions.length && (!selectedNavDate || !navDateOptions.find((opt) => opt.value === selectedNavDate))) {
      setFormValues({ navDate: navDateOptions[0].value });
    }
  }, [navDateOptions, selectedNavDate, setFormValues]);

  if (!isSuccessPtfList) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="1rem" data-testid={testid}>
      <Stack direction="row" alignItems="center" spacing="1rem">
        <Select
          size="small"
          label="Select ESG source"
          name="appCode"
          options={sourceOptions}
          disabled={sourceOptions.length <= 1}
          inputRef={selectRef}
          data-testid={createTestid('appCode')}
        />
        <Select
          size="small"
          label="Select a portfolio"
          name="externalId"
          options={ptfOptions}
          disabled={ptfOptions.length <= 1}
          data-testid={createTestid('externalId')}
        />
        <Select
          size="small"
          label="Select NAV Date"
          name="navDate"
          options={navDateOptions}
          disabled={navDateOptions.length <= 1}
          data-testid={createTestid('navDate')}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing="1rem">
        <Select
          variant="labeled"
          size="small"
          label="Type of view"
          name="type"
          options={viewTypeOptions}
          data-testid={createTestid('type')}
        />
        <Button
          color="primary"
          startIcon={<Icon.Download data-testid={createTestid('icon-export')} />}
          onClick={onExport}
          disabled={!onExport}
          data-testid={createTestid('button-export')}
        >
          Export table
        </Button>
      </Stack>
    </Stack>
  );
};
