import { Stack } from '@mui/material';
import { DialogPopup, Typography, useCallbackImmutable } from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';

type ExtractionAutomatedDeleteModalProps = {
  open: boolean;
  extractionDetails?: DataExtractorApi.Extraction.Extraction;
  onClose(): void;
  onDelete(row: DataExtractorApi.Extraction.Extraction): void;
  'data-testid': string;
};

export const ExtractionAutomatedDeletePopup: React.FC<ExtractionAutomatedDeleteModalProps> = ({
  open,
  extractionDetails,
  onClose,
  onDelete,
  'data-testid': testid,
}) => {
  const handleDelete = useCallbackImmutable(() => {
    if (extractionDetails) onDelete(extractionDetails);
  });

  return (
    <DialogPopup
      title="Delete automated extraction"
      mainButtonText="Delete"
      mainButtonColor="danger"
      onMainActionClick={handleDelete}
      open={open}
      onClose={onClose}
      closeButtonText="Cancel"
      data-testid={testid}
    >
      <Stack gap="1rem">
        <Typography variant="body2" display="-webkit-inline-box" flexWrap="wrap">
          You are about to delete the extraction
          <Typography variant="body2" fontWeight={600} whiteSpace="pre">
            {' '}
            &quot;{extractionDetails?.extractionName ?? ''}&quot;.
          </Typography>{' '}
          The extraction will not be launched anymore, but you will still be able to access the previous ones.
        </Typography>
        <Typography variant="body2" fontWeight={600}>
          Do you confirm?
        </Typography>
      </Stack>
    </DialogPopup>
  );
};
