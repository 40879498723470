import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import JSZip from 'jszip';
import type { SelectFilterItem } from 'mns-components';
import {
  Backdrop,
  Button,
  EmptyStateV2,
  Icon,
  Link,
  SearchField,
  SelectFilter,
  convertToDateUTC,
  joinCase,
  spinalCase,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastUpdateOrCreate } from '../../../../common/toast';
import { defaultDownloadTransform } from '../../../../common/utils';
import {
  getExtraction,
  getExtractionReports,
  getExtractionReportsLight,
  getReport,
  useCreateExtraction,
  useFetchExtractions,
  useFetchReports,
} from '../../hooks';
import { trimString } from '../../monitoring/components/ProviderAnalysisTable';
import { getEsgRoute } from '../../routes';
import { ExtractionGeneratedDetailsModal } from './ExtractionGeneratedDetailsModal';
import { ExtractionsGeneratedList } from './ExtractionsGeneratedList';

export const onDownloadExtraction = async (row: DataExtractorApi.Extraction.Extraction) => {
  const toastId = toast.info(`The files are being downloaded`);
  try {
    const zipBuilder = new JSZip();
    const list = await getExtractionReportsLight(row.id);
    toastUpdateOrCreate(toastId, { render: `The files are being downloaded (0/${list.length})` });

    await Promise.all(
      list.map(async (data, i) => {
        const { reportPresignedUrl } = await getReport(list[i].id);
        const {
          portfolio: { externalIdValue, valuationDate },
        } = data;

        const fileName = `${spinalCase(row.extractionName)}_${joinCase(externalIdValue)}_${spinalCase(
          valuationDate,
        )}.csv`;
        const response = await fetch(reportPresignedUrl, { method: 'GET' });
        const blob = await response.blob();
        zipBuilder.file(fileName, blob);

        toastUpdateOrCreate(toastId, {
          render: `The files are being downloaded (${i + 1}/${list.length})`,
        });
      }),
    );

    const zipBlob = await zipBuilder.generateAsync({ type: 'blob' });
    const zipName = `${row.extractionName}.zip`;
    defaultDownloadTransform(zipName, zipBlob);

    toastUpdateOrCreate(toastId, { type: 'success', render: 'The files have been downloaded successfully.' });
  } catch {
    toastUpdateOrCreate(toastId, { type: 'error', render: "The extraction file couldn't be downloaded." });
  }
};

export const onDownloadLogs = async (row: DataExtractorApi.Extraction.Extraction) => {
  const toastId = toast.info(`The files are being downloaded`);
  try {
    const zipBuilder = new JSZip();
    const list = await getExtractionReports(row.id);
    toastUpdateOrCreate(toastId, { render: `The files are being downloaded (0/${list.length})` });

    await Promise.all(
      list.map(async ({ id, portfolio: { externalIdValue, valuationDate } }, index) => {
        const { logsPresignedUrl } = await getReport(id);

        const fileName = `${joinCase(externalIdValue)}_${spinalCase(valuationDate)}_execution_log.csv`;
        const response = await fetch(logsPresignedUrl, { method: 'GET' });
        const blob = await response.blob();
        zipBuilder.file(fileName, blob);

        toastUpdateOrCreate(toastId, {
          render: `The files are being downloaded (${index + 1}/${list.length})`,
        });
      }),
    );

    const zipBlob = await zipBuilder.generateAsync({ type: 'blob' });
    const zipName = `${spinalCase(row.extractionName)}_${spinalCase(
      convertToDateUTC(row.createdAt),
    )}_execution_log.zip`;
    defaultDownloadTransform(zipName, zipBlob);

    toastUpdateOrCreate(toastId, {
      type: 'success',
      render: 'The execution log file have been downloaded successfully.',
    });
  } catch {
    toastUpdateOrCreate(toastId, { type: 'error', render: "The execution log file couldn't be downloaded." });
  }
};

export type ExtractionsGeneratedPageProps = {
  pollingDelay?: number;
  'data-testid': string;
};

export const ExtractionsGenerated: React.FC<ExtractionsGeneratedPageProps> = ({
  pollingDelay = 3000,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const gridApi = useRef<GridApi | null>(null);
  const [templateSelected, setTemplateSelected] = useState<string>('all');
  const [search, setSearch] = useState('');
  const [extractionRows, setExtractionRows] = useState<DataExtractorApi.Extraction.Extraction[]>([]);
  const [extractionDetails, setExtractionDetails] = useState<DataExtractorApi.Extraction.Extraction>();

  const { data: extractions, isLoading: isLoadingExtractions, isError: isErrorExtractions } = useFetchExtractions();
  const { data: reports = [] } = useFetchReports({
    refetchIntervalInBackground: true,
    refetchInterval: (list) =>
      list?.find((rep) => rep.status === 'IN_PROGRESS' || rep.status === 'REQUESTED') ? pollingDelay : false,
  });
  const { mutateAsync: createExtraction } = useCreateExtraction();

  const extractionsFiltered = useMemo(() => {
    // filter by template used
    return extractions && templateSelected && templateSelected != 'all'
      ? extractions.filter(({ templateId }) => templateSelected.includes(templateId))
      : extractions;
  }, [extractions, templateSelected]);

  const templateOptions = useMemo(
    (): SelectFilterItem[] | undefined =>
      extractions
        ? Array.from(
            extractions
              .reduce((acc, extr) => {
                const option = acc.get(extr.templateId);
                if (option) {
                  acc.set(extr.templateId, {
                    count: option.count + 1,
                    label: trimString(extr.templateName, 25),
                    value: extr.templateId,
                  });
                } else {
                  acc.set(extr.templateId, { count: 1, label: extr.templateName, value: extr.templateId });
                }
                return acc;
              }, new Map<string, SelectFilterItem>())
              .values(),
          )
        : [],
    [extractions],
  );

  const handleRetryExtraction = useCallbackImmutable(async (row: DataExtractorApi.Extraction.Extraction) => {
    const toastId = toast.info('The extraction is being proccessed');
    try {
      const details = await getExtraction(row.id);
      await createExtraction({
        templateId: details.templateId,
        extractionType: details.extractionType,
        extractionName: details.extractionName,
        extractionFeed: details.extractionFeed,
        extractionScope: details.extractionScope,
      });
      return toastUpdateOrCreate(toastId, {
        type: 'success',
        render: 'The extraction retry succeed',
      });
    } catch {
      // do nothing
    }
    toastUpdateOrCreate(toastId, {
      type: 'error',
      render: "The extraction couldn't be retryed",
    });
  });

  const handleDownload = useCallbackImmutable(() => {
    for (const row of extractionRows) {
      onDownloadExtraction(row);
    }
  });

  const handleRowsSelection = useCallbackImmutable((rows: DataExtractorApi.Extraction.Extraction[]) =>
    setExtractionRows(rows),
  );

  const handleResumeExtraction = useCallbackImmutable((row: DataExtractorApi.Extraction.Extraction) => {
    setExtractionDetails(row);
  });

  const handleDetailExtraction = useCallbackImmutable((row: DataExtractorApi.Extraction.Extraction) => {
    setExtractionDetails(row);
  });

  const handleDetailClose = useCallbackImmutable(() => {
    setExtractionDetails(undefined);
  });

  if (isLoadingExtractions) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (isErrorExtractions) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error-extractions')}
        />
      </Stack>
    );
  }

  if (!extractions?.length) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" flexGrow={1}>
        <EmptyStateV2
          variant="info"
          useCase="actions"
          title="Get started with an extraction!"
          subTitle="Start extracting your portfolio."
          buttonText="Create extraction"
          buttonVariant="secondary"
          buttonHref={generatePath(getEsgRoute('generate-extraction').link)}
          data-testid={createTestid('no-extractions')}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
        margin="0 2rem 1rem 2rem"
        data-testid={testid}
      >
        <Stack direction="row" alignItems="center">
          <SearchField value={search} onChange={setSearch} disableMargin data-testid={createTestid('search')} />
        </Stack>
        <Stack direction="row" alignItems="center" gap="1rem">
          <SelectFilter
            label="Template used"
            size="medium"
            activeItem={templateSelected}
            dropdownItems={templateOptions}
            onClick={setTemplateSelected}
            data-testid={createTestid('input-templates')}
          />
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-download')} />}
            color="primary"
            size="medium"
            disabled={!extractionRows.length}
            onClick={handleDownload}
            data-testid={createTestid('button-download')}
          >
            Download
          </Button>

          <Link to={generatePath(getEsgRoute('generate-extraction').link)} data-testid={createTestid('link-generate')}>
            <Button
              color="secondary"
              startIcon={<Icon.Extraction color="inversed" data-testid={createTestid('icon-createExtraction')} />}
              data-testid={createTestid('button-extraction')}
            >
              Create a new extraction
            </Button>
          </Link>
        </Stack>
      </Stack>
      <ExtractionsGeneratedList
        rowData={extractionsFiltered}
        reports={reports}
        getGridApiRef={gridApi}
        quickFilterText={search}
        onRowsSelection={handleRowsSelection}
        onDownloadExtraction={onDownloadExtraction}
        onDownloadLogs={onDownloadLogs}
        onRetryExtraction={handleRetryExtraction}
        onResumeExtraction={handleResumeExtraction}
        onDetailExtraction={handleDetailExtraction}
        data-testid={createTestid('list')}
      />
      <ExtractionGeneratedDetailsModal
        extraction={extractionDetails}
        reports={reports}
        onClose={handleDetailClose}
        data-testid={createTestid('modal-details')}
      />
    </>
  );
};
