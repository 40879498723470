import { useLocalStorage } from 'mns-components';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { providerSsoLogoutUriRecord, providerSsoRecord } from '../setup/providerSso';

const useProviderSso = () => {
  const [providerSso, setProviderSso] = useLocalStorage<(keyof typeof providerSsoRecord & string) | null>(
    'providerSso',
  );

  useEffect(() => {
    if (providerSso && providerSsoRecord[providerSso]) {
      providerSsoRecord[providerSso]();
    }
  }, [providerSso]);

  return [providerSso, setProviderSso] as const;
};

export const useAuthProvider = () => {
  const [loaded, setLoaded] = useState(false);
  const [providerSso, setProviderSso] = useProviderSso();
  const { search } = useLocation();

  useEffect(() => {
    const parsedSearch = new URLSearchParams(search);
    const newProvider = parsedSearch.get('providerSso') as keyof typeof providerSsoRecord | null;
    if (newProvider && providerSso !== newProvider && newProvider in providerSsoRecord) {
      setProviderSso(newProvider);
    } else if (!providerSso) {
      setProviderSso('cognito');
    }
    setLoaded(true);
  }, [search, setProviderSso, providerSso]);

  return loaded;
};

export const useAuthProviderLogoutUri = () => {
  const providerSso = useProviderSso()[0] ?? 'cognito';
  return providerSsoLogoutUriRecord[providerSso];
};
