import { colors, makeStyles } from 'mns-components';
import dataOnly from '../../../assets/images/iconCategoriesCards/data-only.svg';
import portfolioAnalysis from '../../../assets/images/iconCategoriesCards/portfolio.svg';

export const appSwitchStyles = makeStyles({
  widgetContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    margin: '2rem',
    borderRadius: '5px',
    padding: '1rem 2rem',
    background: colors.whiteColor,
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    minHeight: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  headerLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& > span': {
      opacity: 0.54,
      fontSize: '.75rem',
      marginBottom: '.25rem',
    },
  },
});

export const appDeliverableStyles = makeStyles({
  'Data only': {
    backgroundImage: `url(${dataOnly})`,
  },
  'Portfolio analysis': {
    backgroundImage: `url(${portfolioAnalysis})`,
  },
});
