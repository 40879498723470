import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Backdrop,
  convertToDateTimeLocal,
  useAsyncLazy,
  useAutoSize,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useEffect, useMemo } from 'react';
import { dataDeliveryApi } from '../../../store/apis';
import { deliveryDetailsDialogEetStyles as useStyles } from '../styles/deliveryDetailsDialogEetStyles';

const plural = (value: number) => (value > 1 ? 's' : '');

const usePortfolioColumns = () =>
  useMemo(
    (): AggridTableColumn<DataDeliveryApi.Scope>[] => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
      },
      {
        headerName: 'General reference date',
        field: 'productionDate',
        sortable: true,
      },
    ],
    [],
  );

type DeliveryDetailsScopeListProps = {
  scopes: DataDeliveryApi.Scope[];
  'data-testid': string;
};

const DeliveryDetailsScopeList: React.FC<DeliveryDetailsScopeListProps> = ({ scopes, 'data-testid': testid }) => (
  <AggridTable
    rowData={scopes}
    columnDefs={usePortfolioColumns()}
    defaultColDef={useDefaultColDef<DataDeliveryApi.Scope>()}
    getRowNodeId={useImmutable(() => (row: DataDeliveryApi.Scope) => row.identification)}
    onGridReady={useAutoSize('fit')}
    paginationAutoPageSize
    data-testid={testid}
  />
);

const useEmailColumns = () =>
  useMemo(
    (): AggridTableColumn<DataDeliveryApi.DataDeliveryEmail>[] => [
      {
        headerName: 'Email',
        field: 'recipient',
        sortable: true,
      },
    ],
    [],
  );

type DeliveryDetailsEmailListProps = {
  emails: DataDeliveryApi.DataDeliveryEmail[];
  'data-testid': string;
};

const DeliveryDetailsEmailList: React.FC<DeliveryDetailsEmailListProps> = ({ emails, 'data-testid': testid }) => (
  <AggridTable
    rowData={emails}
    columnDefs={useEmailColumns()}
    defaultColDef={useDefaultColDef<DataDeliveryApi.DataDeliveryEmail>()}
    getRowNodeId={useImmutable(() => (row: DataDeliveryApi.DataDeliveryEmail) => row.recipient)}
    onGridReady={useAutoSize('fit')}
    paginationAutoPageSize
    data-testid={testid}
  />
);

type DeliveryDetailsDialogEetProps = {
  deliveryId: string;
  'data-testid': string;
};

export const DeliveryDetailsDialogEet: React.FC<DeliveryDetailsDialogEetProps> = ({
  deliveryId,
  'data-testid': testid,
}) => {
  const classes = useStyles();
  const {
    execute: getDeliveryDetails,
    result: deliveryDetails,
    isExecuting: isExecutingDeliveryDetails,
  } = useAsyncLazy(() => dataDeliveryApi.getDelivery(deliveryId));
  const createTestid = useTestid(testid);

  useEffect(() => {
    getDeliveryDetails();
  }, [deliveryId, getDeliveryDetails]);

  if (isExecutingDeliveryDetails || !deliveryDetails) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  return (
    <>
      <div className={classes.mainLabel}>{deliveryDetails.info.title}</div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created on:</span>
        <span className={classes.itemValue}>{convertToDateTimeLocal(deliveryDetails.info.createdAt)}</span>
      </div>

      <div className={classes.itemRow}>
        <span className={classes.itemLabel}>Created by:</span>
        <span className={classes.itemValue}>{deliveryDetails.info.createdBy}</span>
      </div>

      <div className={classes.mainLabel}>
        {deliveryDetails.scopes.length} file{plural(deliveryDetails.scopes.length)}
      </div>

      <DeliveryDetailsScopeList scopes={deliveryDetails.scopes} data-testid={createTestid('table-ptfs')} />

      <div className={classes.mainLabel}>
        {deliveryDetails.emails.length} recipient{plural(deliveryDetails.emails.length)}
      </div>

      <DeliveryDetailsEmailList emails={deliveryDetails.emails} data-testid={createTestid('table-emails')} />
    </>
  );
};
