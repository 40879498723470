import { Stack } from '@mui/material';
import {
  Backdrop,
  Button,
  Dialog,
  EmptyState,
  Icon,
  SearchField,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { useFetchTemplates } from '../../myPortfoliosApp/hooks';
import { CreateTemplateModal } from '../createTemplateModal/CreateTemplateModal';
import { useManageOpenCreateTemplateModal, useManageOpenTemplateDetailsModal } from '../hooks';
import { ExtractorTemplateDetailsModal } from './ExtractorTemplateDetailsModal';
import { ExtractorTemplatesList } from './ExtractorTemplatesList';

export const ExtractorTemplatesPage: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const [search, setSearch] = useState('');
  const createTestid = useTestid(testid);

  const { data: templates, isLoading: isLoadingTemplates, error: errorTemplates } = useFetchTemplates();

  const [isOpenTemplateDetails, setOpenTemplateDetails, setCloseTemplateDetails] = useManageOpenTemplateDetailsModal();

  const openTemplateDetails = useMemo(
    () => templates?.find((tpl) => tpl.id === isOpenTemplateDetails),
    [isOpenTemplateDetails, templates],
  );

  const onDetailsModal = useCallbackImmutable((row: DataExtractorApi.Template.TemplateLight) => {
    setOpenTemplateDetails(row.id);
  });

  const [isOpenCreateTemplateModal, onOpenCreateTemplateModal, onCloseCreateTemplateModal] =
    useManageOpenCreateTemplateModal();

  if (isLoadingTemplates) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (errorTemplates) {
    return (
      <EmptyState
        iconEnable
        iconName="warning"
        title="Unable to load templates"
        firstParagraph="The request ended with error, please try to load it again or contact administrator"
        data-testid={createTestid('error-templates')}
      />
    );
  }

  if (!templates?.length) {
    return (
      <>
        <EmptyState
          title="No template available"
          firstParagraph="Start creating a template for building your extractions"
          firstButtonText="Create a new template"
          firstOnClickButton={onOpenCreateTemplateModal}
          firstButtonProps={{
            color: 'primary',
            startIcon: <Icon.Add data-testid={createTestid('icon-add')} />,
            'data-testid': createTestid('firstButton'),
          }}
          data-testid={createTestid('empty-templates')}
        />
        <CreateTemplateModal
          open={isOpenCreateTemplateModal}
          onClose={onCloseCreateTemplateModal}
          data-testid={createTestid('modal-createExtraction')}
        />
      </>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding="0.5rem 1rem 0.5rem 0.5rem">
        <SearchField value={search} onChange={setSearch} data-testid={createTestid('search')} />
        <Button
          color="primary"
          startIcon={<Icon.Add data-testid={createTestid('icon-add')} />}
          onClick={onOpenCreateTemplateModal}
          data-testid={createTestid('button-create')}
        >
          Create a new template
        </Button>
      </Stack>
      <ExtractorTemplatesList
        rowData={templates}
        quickFilterText={search}
        data-testid={createTestid('list')}
        onDetailsTemplate={onDetailsModal}
      />
      <Dialog
        dialogTitle="Template Details"
        StartIcon={Icon.Details}
        open={!!openTemplateDetails}
        onClose={setCloseTemplateDetails}
        data-testid={createTestid('dialog')}
      >
        {openTemplateDetails && (
          <ExtractorTemplateDetailsModal
            template={openTemplateDetails}
            setClose={setCloseTemplateDetails}
            data-testid={createTestid('modal-details')}
          />
        )}
      </Dialog>
      <CreateTemplateModal
        open={isOpenCreateTemplateModal}
        onClose={onCloseCreateTemplateModal}
        data-testid={createTestid('modal-createExtraction')}
      />
    </>
  );
};
