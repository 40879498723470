import { useNavigate } from 'mns-components';
import { useMemo } from 'react';
import { useUser } from '../../hooks/useAuth';
import { collectionScenario } from './scenarios/collectionScenario';
import { coverageEstimationScenario } from './scenarios/coverageEstimationScenario';
import { disseminationScenario } from './scenarios/disseminationScenario';
import { welcomeScenario } from './scenarios/welcomeScenario';

export const scenarios = {
  welcome: welcomeScenario,
  collection: collectionScenario,
  coverageEstimation: coverageEstimationScenario,
  dissemination: disseminationScenario,
};

export type GuidedTourScenario = keyof typeof scenarios;

export const useGuidedTourScenario = (scenarioName: GuidedTourScenario) => {
  const navigate = useNavigate();
  const { apps: roles } = useUser();
  return useMemo(() => scenarios[scenarioName]({ navigate, roles }), [scenarioName, navigate, roles]);
};
