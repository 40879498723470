import { Stack } from '@mui/material';
import { lowerCase, snakeCase, useTestid } from 'mns-components';
import type { SelectCategoryProps } from './SelectCategory';

export type SelectCategoryCard<Cat extends string = string> = {
  name: Cat;
  countDatapoints: number;
  countSelectedDatapoints: number;
};

type SelectCategoriesListProps<Cat extends string> = {
  categories: SelectCategoryCard<Cat>[];
  selectedCategory: Cat;
  ItemComponent: React.FC<SelectCategoryProps<Cat>>;
  onSelect(category: Cat): void;
  onCheckAll(category: Cat): void;
  'data-testid': string;
};

export const SelectCategoriesList = <Cat extends string>({
  categories,
  selectedCategory,
  ItemComponent,
  onSelect,
  onCheckAll,
  'data-testid': testid,
}: SelectCategoriesListProps<Cat>): React.ReactElement => {
  const createTestid = useTestid(testid);
  return (
    <Stack
      direction="column"
      spacing=".75rem"
      width="100%"
      overflow="clip auto"
      minWidth={0}
      paddingBottom="1rem"
      data-testid={testid}
    >
      {categories.map(({ name, countDatapoints, countSelectedDatapoints }) => (
        <ItemComponent
          key={name}
          category={name}
          isSelected={selectedCategory === name}
          countDatapoints={countDatapoints}
          countSelectedDatapoints={countSelectedDatapoints}
          onSelect={onSelect}
          onCheckAll={onCheckAll}
          data-testid={createTestid(snakeCase(lowerCase(name)))}
        />
      ))}
    </Stack>
  );
};
