import { makeStyles } from 'mns-components';

export const useCompilingFileStyles = makeStyles({
  compilingFileStep: {
    padding: '0 2rem 2rem 2rem',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '.5rem 2rem .5rem 2rem',
  },
});
