import type { GuidedStepOptions } from 'mns-components';
import {
  GuidedStep,
  Image,
  Typography,
  Video,
  colors,
  filterTruthy,
  makeStyles,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import { useUploadModalOpen } from '../../../applications/disseminationApp/hooks';
import { getDisseminationRoute } from '../../../applications/disseminationApp/routes';
import { useGuidedTour } from '../../../hooks/useGuidedTour';
import type { GuidedTourScenarioBuild } from '../types';
import { getAsset, waitForNode } from '../utils';

const logoStyles = makeStyles({
  show: {
    backgroundImage: `url(${getAsset('dissemination', 'dissemination-welcome-user.svg')})`,
  },
});

const testid = 'disseminationScenario';

export const disseminationScenario: GuidedTourScenarioBuild = ({ navigate }) => {
  return filterTruthy<GuidedStepOptions>([
    // step 1
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Bottom
            title="Welcome to Dissemination by Manaos!"
            backLabel="Skip the tour"
            nextLabel="Start the tour"
            onBack={goLast}
            onNext={goNext}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              A quick tutorial to explain how to take the first steps on the Dissemination application.
            </Typography>
            <Image
              marginTop="2rem"
              height="15rem"
              backgroundClassname={logoStyles().show}
              data-testid={createTestid('image')}
            />
          </GuidedStep.Bottom>
        );
      },
      options: {
        arrow: false,
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="dissemination-help-button"]');
        },
        attachTo: { on: 'bottom-end' },
      },
    },
    // step 2
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Get started by uploading your inventories"
            progressionCount={6}
            progressionValue={0}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              To start disseminating fund inventories, please upload{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={500}>
                upload
              </Typography>{' '}
              your investment portfolios.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="370px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-getStratedByUploadingYourPortfolios.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          navigate(getDisseminationRoute('dissemination-dashboard').link);
          await waitForNode('[data-stepid="dissemination-button-upload"]');
        },
        attachTo: { on: 'bottom-end', element: '[data-stepid="dissemination-button-upload"]' },
      },
    },
    // step 3
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Check your uploads"
            progressionCount={6}
            progressionValue={1}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              When you upload your files, they are{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                only visible to your organisation.
              </Typography>{' '}
              Please check the following points before granting access:
            </Typography>
            <Typography variant="body2" component="li" marginLeft="10px">
              If the inventory is rejected, you can see in the{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                Error log
              </Typography>{' '}
              what changes need to be made.
            </Typography>
            <Typography variant="body2" component="li" marginLeft="10px">
              <Typography variant="body2" component="span" fontWeight={600}>
                The quality of the inventory
              </Typography>{' '}
              to match with your investor&apos;s requirements.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="390px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-checkYourUploads.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          navigate(getDisseminationRoute('dissemination-uploads').link);
          await waitForNode('[data-stepid="tab-dissemination-uploads"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="tab-dissemination-uploads"]' },
      },
    },
    // step 4
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Grant access to investors"
            progressionCount={6}
            progressionValue={2}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Once your uploaded files are confirmed, you will able to grant access{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                as soon as your investors send you an access request.
              </Typography>{' '}
              When replying, you will be required to choose{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                the embargo
              </Typography>{' '}
              {
                'information (number of business days after the end of month to wait before inventories are disseminated)'
              }
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="450px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-grantAccessToAssetOwners.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          navigate(getDisseminationRoute('dissemination-permissions').link);
          await waitForNode('[data-stepid="tab-dissemination-permissions"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="tab-dissemination-permissions"]' },
      },
    },
    // step 5
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Keep track of your dissemination process"
            progressionCount={6}
            progressionValue={3}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                Take a glance at the inventories
              </Typography>{' '}
              to which you have granted access, as well as those awaiting access.
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="450px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-keepTrackOfYourdisseminationProcess.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          navigate(getDisseminationRoute('dissemination-dashboard').link);
          await waitForNode('[data-stepid="tab-dissemination-dashboard"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="tab-dissemination-dashboard"]' },
      },
    },
    // step 6
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Manage access requests automatically"
            progressionCount={6}
            progressionValue={4}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Once you have granted access to an investor{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                at least once
              </Typography>{' '}
              you can configure{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                automatic access authorisation{' '}
              </Typography>{' '}
              for that same asset owner in the settings panel.{' '}
              <Typography variant="body2" component="span" color={colors.pinkColor} fontWeight={600}>
                This will avoid you to have to log back in to accept future access requests from the same investor.
              </Typography>
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="460px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-manageAccessRequestsAutomatically.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="dissemination-button-settings"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="dissemination-button-settings"]' },
      },
    },
    // step 7
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goBack,
          goNext,
          goLast,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        return (
          <GuidedStep.Normal
            title="Filter your Dissemination application by date"
            progressionCount={6}
            progressionValue={5}
            onBack={goBack}
            onNext={goNext}
            onClose={goLast}
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Customize your view on the Dissemination application by focusing on the relevant dates . Please note that{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                the chosen start date is included.
              </Typography>
            </Typography>
            <Video
              autoPlay
              loop
              muted
              width="480px"
              sources={[
                {
                  src: getAsset('dissemination', 'dissemination-filterYourAccessRequestsBydate.mp4'),
                  type: 'video/mp4',
                },
              ]}
              data-testid={createTestid('video')}
            />
          </GuidedStep.Normal>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="selectInventoryDate-datepicker"]');
        },
        attachTo: { on: 'bottom-start', element: '[data-stepid="selectInventoryDate-datepicker"]' },
      },
    },
    // step 8
    {
      element: function Step() {
        const {
          state: { stepIndex },
          goLast,
          complete,
        } = useGuidedTour('dissemination');
        const createTestid = useTestid(testid);
        const navigateUpload = useUploadModalOpen();
        const goUpload = useCallbackImmutable(() => {
          complete();
          navigateUpload();
        });
        return (
          <GuidedStep.Bottom
            title="Give it a go!"
            backLabel="Close"
            nextLabel="Upload inventories"
            onBack={goLast}
            onNext={goUpload}
            nextIcon="upload"
            data-testid={testid}
            data-stepindex={stepIndex}
          >
            <Typography variant="body2">
              Now that you know the basics of{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                Dissemination by Manaos,
              </Typography>{' '}
              it&apos;s time to upload your first portfolio!
            </Typography>
            <Image
              marginTop="2rem"
              height="15rem"
              backgroundClassname={logoStyles().show}
              data-testid={createTestid('image')}
            />
          </GuidedStep.Bottom>
        );
      },
      options: {
        arrow: false,
        version: 2401,
        attachTo: { on: 'bottom-end' },
      },
    },
    // last step
    {
      element: function Step() {
        const {
          state: { stepIndex },
          complete,
        } = useGuidedTour('dissemination');
        return (
          <GuidedStep.Tooltip title="Guided Tours" onClose={complete} data-testid={testid} data-stepindex={stepIndex}>
            Find the guided tours at any time by clicking this icon!
          </GuidedStep.Tooltip>
        );
      },
      options: {
        version: 2401,
        beforeShowPromise: async () => {
          await waitForNode('[data-stepid="dissemination-help-button"]');
        },
        attachTo: { on: 'bottom', element: '[data-stepid="dissemination-help-button"]' },
      },
    },
  ]);
};
