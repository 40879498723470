import { colors, makeStyles } from 'mns-components';
import marketplaceBanner from '../../../assets/images/banner-marketplace-manaos.svg';
import coolCatchEyes from '../../../assets/images/cool-catcheyes-manaos.png';

export const marketplaceStyles = makeStyles({
  marketplaceBanner: {
    backgroundColor: colors.whiteColor,
    backgroundImage: `url(${marketplaceBanner})`,
    backgroundPosition: 'right',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
  },
  marketplaceHeader: {
    '&&': {
      margin: '1.5rem 1rem 1.25rem',
    },
    '& h2': {
      margin: 0,
      marginRight: 'auto',
      paddingRight: '24rem',
      color: colors.blackColor,
      fontWeight: 'bold',
      letterSpacing: '0',
      textAlign: 'left',
      '& span': {
        fontWeight: 'bold',
        '&::after': {
          content: '"placeholder"',
          fontSize: 0,
          backgroundImage: `url(${coolCatchEyes})`,
          display: 'inline-block',
          width: '2.875rem',
          height: '2.875rem',
          position: 'relative',
          top: '-2rem',
          left: '-1rem',
          right: '0rem',
        },
      },
    },
    '& p': {
      padding: '0 24rem 0 0',
      textAlign: 'left',
      marginTop: 0,
    },
  },
  marketplace: {
    display: 'flex',
    margin: '0 2rem 0 2.25rem',
    flexGrow: '1',
  },
  marketplaceColumn: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 0',
  },
  tabContainer: {
    '& > div': {
      paddingLeft: '2rem',
    },
  },
  termsContainer: {
    maxHeight: '25rem',
    overflow: 'scroll',
    padding: '1rem 1.5rem',
  },
  link: {
    color: 'black',
    fontWeight: 'bold',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '1rem auto',
  },
});
