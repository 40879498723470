import { Stack, Tooltip } from '@mui/material';
import {
  convertToDateUTC,
  useAsync,
  useCallbackImmutable,
  useNavigate,
  useTestid,
  Icon,
  Button,
  Divider,
  colors,
  Typo,
  EmptyStateV2,
} from 'mns-components';
import { CoverageApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { wait } from '../../../../common/date';
import { getPortfolioText, UNION } from '../../../../common/utils';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { getRoute } from '../../../../components/views/routes';
import { useUser } from '../../../../hooks/useAuth';
import { usePtfAndDate } from '../../../../hooks/useGlobalState';
import { useFetchAllPortfolios } from '../../../../hooks/usePortfoliosRequest';
import { api } from '../../../../store/api';
import { SelectorPtfAndDate } from '../../../coverageApp/components/SelectorPtfAndDate';
import { CardCoverageDetails } from './CardCoverageDetails';
import { CardCoverageMainValue } from './CardCoverageMainValue';
import { cardCoverageStyle as useStyles } from './styles/cardCoverageStyles';

export type FilledCoverage = Replace<CoverageApi.Coverage, 'coverage', number>;
type GenerateCoverage = CoverageApi.GetCoverageParams & Pick<CoverageApi.GenerateCoverageParams, 'organisationId'>;

export const generateOnce = async ({
  applicationCode,
  externalIdValue,
  externalIdType,
  valuationDate,
  organisationId,
  portfolioOriginType,
}: GenerateCoverage) => {
  const load = async () => {
    const coverage = await api.provider.coverage.getCoverage.raw({
      applicationCode,
      externalIdValue,
      externalIdType,
      valuationDate,
      portfolioOriginType,
    });
    if (coverage.status === 'pending') {
      throw new Error(coverage.status);
    }
    if (coverage.status === 'failed' && coverage.errorMessage) {
      throw new Error(coverage.errorMessage);
    }
    if (coverage.status === 'failed') {
      throw new Error('Error on getting coverage data.');
    }
    return coverage as FilledCoverage;
  };

  const generate = async () =>
    api.provider.coverage.generateCoverage.raw({
      applicationCode,
      externalIdValue,
      externalIdType,
      valuationDate,
      organisationId,
      portfolioOriginType,
      matchingLevel: CoverageApi.MatchingLevelEnum.ULTIMATE_PARENT,
    });

  const reload = async (): Promise<FilledCoverage> => {
    try {
      return await load();
    } catch (error) {
      if (error instanceof Error && error.message === 'pending') {
        await wait();
        return await reload();
      }
      throw error;
    }
  };

  try {
    return await load();
  } catch (e) {
    await generate();
    return await reload();
  }
};

//to display only if coverage is strict matching / ect...
const textTooltip =
  'Manaos has 3 levels of matching:' +
  '\n\tLevel 1 Issuer level: we match the instrument at company level only, there is no proxy.' +
  '\n\tLevel 2 Parent level: if there is no ESG rating for the company, we go back to the parent company to match the instrument.' +
  '\n\tLevel 3 Ultimate parent: if there is no ESG rating either for the company or the parent company, we go back to the Ultimate \n\tParent to match the instrument.' +
  '\nPlease note that by using a proxy or higher level, your coverage rate is likely to increase, but it will impact the quality of your data.';

const generateUploadRoute = () => generatePath(getRoute('uploads').path);

export type CardCoverageProps = {
  appCode: AppCode;
  'data-testid': string;
};

export const CardCoverage: React.FC<CardCoverageProps> = ({ appCode, 'data-testid': testid }) => {
  const classes = useStyles();
  const createTestid = useTestid(testid);
  const navigate = useNavigate();
  const { organisationId } = useUser();

  const { data: portfolios } = useFetchAllPortfolios();
  const [[portfolioId, valuationDate]] = usePtfAndDate();
  const [coverage, setCoverage] = useState<undefined | FilledCoverage>();
  const [loadCoverage, isLoadingCoverage, , errorCoverage] = useAsync(async (props: GenerateCoverage) =>
    setCoverage(await generateOnce(props)),
  );

  const selectedPortfolio = useMemo(
    () => portfolios?.find((ptf) => ptf.portfolioId === portfolioId),
    [portfolioId, portfolios],
  );

  const handleGetCoverage = useCallbackImmutable(async (): Promise<void> => {
    if (selectedPortfolio) {
      loadCoverage({
        applicationCode: appCode,
        externalIdValue: selectedPortfolio.externalId.value,
        externalIdType: selectedPortfolio.externalId.type,
        valuationDate: valuationDate ?? '',
        organisationId,
        portfolioOriginType: selectedPortfolio.origin,
      });
    }
  });

  const handleUpload = useCallbackImmutable(() => navigate(generateUploadRoute()));

  const tooltip = () => {
    if (coverage && coverage.coverageDetails && coverage.coverageDetails[0]?.name?.includes('Strict Matching')) {
      return textTooltip;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setCoverage(undefined);
  }, [selectedPortfolio, portfolioId, valuationDate]);

  if (!selectedPortfolio || !portfolios || portfolios.length == 0) {
    return (
      <EmptyStateV2
        variant="info"
        useCase="upload"
        title="Get started with your upload!"
        subTitle="Start your Manaos journey by uploading your portfolio."
        data-testid={createTestid('empty-portfolio')}
        buttonText="Upload portfolio"
        buttonVariant="primary"
        buttonIcon="upload"
        buttonOnClick={handleUpload}
      />
    );
  }
  return (
    <>
      {/* coverage form */}
      <Stack display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
        <SelectorPtfAndDate
          portfolios={portfolios}
          displayLookthrough
          smallVersion={true}
          data-testid={createTestid('picker')}
        />
        <Stack>
          <Button
            color="primary"
            type="submit"
            loading={isLoadingCoverage}
            onClick={handleGetCoverage}
            data-testid={createTestid('getCoverage')}
          >
            Get coverage
          </Button>
        </Stack>
      </Stack>
      <Stack marginTop="1rem" marginBottom="1.5rem">
        <Divider variant="default" />
      </Stack>
      {/* coverage result*/}
      {!isLoadingCoverage && !errorCoverage && coverage && (
        <div className={classes.coverageContainer}>
          <div className={classes.coverageEstimation}>
            <div className={classes.iconCoverageContainer}>
              <Tooltip
                title={tooltip()}
                arrow
                placement="bottom-start"
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <div className={classes.iconCoverage}>
                  <Icon.Coverage color="default" size="large" data-testid={createTestid('icon-info')} />
                </div>
              </Tooltip>
              <Stack display="flex" flexDirection="column" marginLeft="1rem" justifyContent="center">
                <Typo variant="body2medium" color={colors.defaultFontColor}>
                  Estimated coverage:
                </Typo>
                <Typo variant="caption" color={colors.greyFontColor} data-testid={createTestid('coverageText')}>
                  {getPortfolioText(selectedPortfolio)} {UNION} {valuationDate ? convertToDateUTC(valuationDate) : null}
                </Typo>
              </Stack>
            </div>
            <CardCoverageMainValue
              appCode={appCode}
              coverage={coverage}
              data-testid={createTestid('main')}
            ></CardCoverageMainValue>
          </div>
          <CardCoverageDetails
            appCode={appCode}
            coverage={coverage}
            data-testid={createTestid('details')}
          ></CardCoverageDetails>
        </div>
      )}
      {errorCoverage && (
        <Stack alignItems="center" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            data-testid={createTestid('error-coverage')}
          />
        </Stack>
      )}
    </>
  );
};
