import React from 'react';
import { Redirect } from 'react-router-dom';
import { getViewsRoute } from './routes';

const Home: React.FC = () => (
  <Redirect
    to={{
      pathname: getViewsRoute('marketplace').path,
    }}
  />
);

export default Home;
