import { useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo, useState } from 'react';
import { useDownloadPresignedUrl } from '../../../../hooks/useDownloadPresignedUrl';
import { uploadStyles as useStyles } from '../styles/uploadStyles';
import { UploadController } from './UploadController';
import type { UploadFilter } from './UploadListRequest';
import { UploadListRequest } from './UploadListRequest';

const useHandleDownload = (
  fileType: CollectApi.FileType,
  fileTypeLabel: string,
  downloadPresignedUrl: ReturnType<typeof useDownloadPresignedUrl>,
) =>
  useCallbackImmutable((fileData: CollectApi.File) => {
    downloadPresignedUrl({
      fileDownloadType: fileType,
      fileDownloadTypeLabel: fileTypeLabel,
      processId: fileData.processId,
      fileName: fileData.name,
    });
  });

type UploadProps = { 'data-testid': string };

export const Upload: React.FC<UploadProps> = ({ 'data-testid': testid }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({} as UploadFilter);
  const createTestid = useTestid(testid);
  const downloadPresignedUrl = useDownloadPresignedUrl();

  const [selected, setSelected] = useState<CollectApi.File[]>([]);
  const handleDownload = useHandleDownload('input', 'Input File', downloadPresignedUrl);
  const handleDownloadErrorLog = useHandleDownload('error_log', 'Error Log', downloadPresignedUrl);

  const handleDownloadAll = useMemo(
    () =>
      selected.length > 0
        ? () => {
            selected.forEach((fileData) => {
              downloadPresignedUrl({
                fileDownloadType: 'input',
                fileDownloadTypeLabel: 'Input File',
                processId: fileData.processId,
                fileName: fileData.name,
              });
            });
          }
        : undefined,
    [selected, downloadPresignedUrl],
  );

  return (
    <div className={classes.container} data-testid={testid}>
      <div className={classes.head}>
        <UploadController
          setFilters={setFilters}
          onDownload={handleDownloadAll}
          data-testid={createTestid('controller')}
        />
      </div>
      <UploadListRequest
        filters={filters}
        onRowSelected={setSelected}
        data-testid={testid}
        onDownload={handleDownload}
        onDownloadErrorLog={handleDownloadErrorLog}
      />
    </div>
  );
};
