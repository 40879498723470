import { makeStyles } from 'mns-components';

export const cardPreviewStyle = makeStyles({
  cardModal: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainContainer: {},
  screenshotsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  screenshot: {
    margin: '1rem',
  },
  center: {
    alignSelf: 'center',
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdown: {
    width: 'fit-content',
    '& > div > button': {
      marginTop: '0 !important',
    },
  },

  /* PDF */
  loadingContainer: {
    userSelect: 'none',
    margin: '5rem auto 0 auto',
    '& div.MuiBackdrop-root': {
      zIndex: '999 !important',
      position: 'relative',
    },
  },
  page: {
    '& > div > canvas': {
      width: '-webkit-fill-available !important',
      objectFit: 'contain',
    },
  },
});
