import { colors, makeStyles } from 'mns-components';

export const appLayoutStyles = makeStyles({
  tabsContainer: { background: 'white', padding: '0.8rem 0 0 2rem' },
  divider: {
    height: '1px',
    background: '#E4E4E4',
    margin: '1.5rem 0',
  },
  mainContainer: {
    width: '100%',
    minHeight: '100%',
  },
  widgetContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    margin: '2rem',
    borderRadius: '5px',
    padding: '1rem 2rem',
    background: colors.whiteColor,
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    minHeight: 0,
  },
  container: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    borderRadius: '5px',
    padding: '1rem 2rem',
    background: colors.whiteColor,
    boxShadow: '0px 0px 14px 0px rgb(0, 0, 0, 0.08)',
    minHeight: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerAction: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
  },
  headerLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& > span': {
      opacity: 0.54,
      fontSize: '.75rem',
      marginBottom: '.25rem',
    },
  },
});
