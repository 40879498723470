import { colors, makeStyles } from 'mns-components';

export const selectDatapointsStyles = makeStyles({
  categoryCard: {
    display: 'flex',
    borderRadius: '10px',
    border: `1px solid ${colors.blueGreyColor}`,
    cursor: 'pointer',
    transition: 'all .2s ease',
    background: colors.whiteColor,

    '&.selected, &:hover': {
      background: colors.borderLight,
      borderColor: colors.borderBlue,
    },
  },
  checkboxCard: {
    left: '-2px',
    margin: '.375rem',
  },
  datapointsHead: {
    borderRadius: '.5rem .5rem 0 0',
    border: `1px solid ${colors.borderLight}`,
    background: colors.borderLight,
    cursor: 'pointer',
  },
  datapointRow: {
    borderLeft: `1px solid ${colors.borderLight}`,
    borderRight: `1px solid ${colors.borderLight}`,
    borderBottom: `1px solid ${colors.borderLight}`,
    cursor: 'pointer',
  },
});
