import { Icon, useTestid } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { priceWithCurrency } from '../../../../common/utils';
import { formStyles as useStyles } from './formStyles';

type OfferDetailsProps = {
  offer: MarketplaceApi.ApplicationPricing;
  'data-testid': string;
};

export const OfferDetails: React.FC<OfferDetailsProps> = ({ offer, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  return (
    <div className={classes.form} data-testid={testid}>
      <div className={classes.flexRow}>
        <div className={classes.flexRow}>
          <div className={classes.icon}>
            <Icon.Localoffer data-testid={createTestid('icon-localoffer')} />
          </div>
          <div>
            <h3 className={classes.formTitle} data-testid={createTestid('offerName')}>
              {offer.offerName}
            </h3>
            <div className={classes.list}>
              {offer.features.map((feat, index) => (
                <span
                  className={classes.feature}
                  key={index} // NOSONAR
                >
                  <Icon.Check data-testid={createTestid('icon-check')} />
                  {feat}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.price} data-testid={createTestid('price')}>
          {priceWithCurrency(offer.pricingDto.price, offer.pricingDto.currency)}
        </div>
      </div>
    </div>
  );
};
