import { Stack, Tooltip } from '@mui/material';
import { HeaderComponentFramework, Icon, Typography, useCallbackImmutable } from 'mns-components';
import React from 'react';

const statusExplanation = [
  {
    status: 'Accepted',
    explanation: 'File has been properly integrated and a quality has been attributed.',
  },
  {
    status: 'Rejected',
    explanation:
      'There was one or more blocking error(s) detected in your upload, please download the error log to learn more about it.',
  },
  {
    status: 'Failed',
    explanation: 'File not controlled due to a technical issue. Please try again, or contact our support team.',
  },
];

export const HeaderStatusRenderer: React.FC<{ 'data-testid': string }> = React.memo(function HeaderStatusRenderer({
  'data-testid': testid,
}) {
  return (
    <HeaderComponentFramework
      render={useCallbackImmutable(({ getLabelNode }) => (
        <Stack direction="row" spacing=".5rem" alignItems="center">
          {getLabelNode('Status')}
          <Tooltip
            placement="right-start"
            title={
              <>
                {statusExplanation.map((item) => (
                  <Typography margin="0.75rem 0" key={item.status} component="p" variant={'caption'}>
                    <Typography variant={'caption'} fontWeight="bold">
                      {item.status}:
                    </Typography>{' '}
                    {item.explanation}
                  </Typography>
                ))}
              </>
            }
            arrow
          >
            <Icon.Info size="small" color="secondary" data-testid={`${testid}-icon-info`} />
          </Tooltip>
        </Stack>
      ))}
      data-testid={testid}
    />
  );
});
