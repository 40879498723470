import { Stack } from '@mui/material';
import {
  Backdrop,
  Button,
  Dialogv2,
  Icon,
  SearchField,
  useCallbackImmutable,
  useTestid,
  Link,
  EmptyStateV2,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useManageOpenTemplateDetailsModal } from '../../../dataExtractorApp/hooks';
import { useFetchTemplates } from '../../../myPortfoliosApp/hooks';
import { getEsgRoute } from '../../routes';
import { TemplateDetailsModal } from './TemplateDetailsModal';
import { TemplatesList } from './TemplatesList';

interface CreateTemplateButtonProps {
  'data-testid': string;
}

const CreateTemplateButton = React.memo<CreateTemplateButtonProps>(function CreateTemplateButton({
  'data-testid': testid,
}) {
  const createTestid = useTestid(testid);
  return (
    <Link to={generatePath(getEsgRoute('generate-template').link)} data-testid={createTestid('link-generate')}>
      <Button
        color="secondary"
        startIcon={<Icon.Add data-testid={createTestid('add')} />}
        data-testid={createTestid('button-create')}
      >
        Create a new template
      </Button>
    </Link>
  );
});

export const Templates: React.FC<{ 'data-testid': string }> = ({ 'data-testid': testid }) => {
  const [search, setSearch] = useState('');
  const createTestid = useTestid(testid);
  const generateLink = generatePath(getEsgRoute('generate-template').link);

  const { data: templates, isLoading: isLoadingTemplates, error: errorTemplates } = useFetchTemplates();

  const [isOpenTemplateDetails, setOpenTemplateDetails, setCloseTemplateDetails] = useManageOpenTemplateDetailsModal();

  const openTemplateDetails = useMemo(
    () => templates?.find((tpl) => tpl.id === isOpenTemplateDetails),
    [isOpenTemplateDetails, templates],
  );

  const onDetailsModal = useCallbackImmutable((row: DataExtractorApi.Template.TemplateLight) => {
    setOpenTemplateDetails(row.id);
  });

  if (isLoadingTemplates) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (errorTemplates) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="error"
          useCase="dataErrors"
          title="Oops, something went wrong!"
          subTitle="Please refresh your page or contact our support team."
          data-testid={createTestid('error-templates')}
        />
      </Stack>
    );
  }

  if (!templates?.length) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        <EmptyStateV2
          variant="info"
          useCase="actions"
          title="Get started with your template creation!"
          subTitle="Start creating your template."
          buttonText="Create template"
          buttonVariant="secondary"
          buttonHref={generateLink}
          data-testid={createTestid('empty-templates')}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding="0 2rem 1rem 2rem">
        <SearchField value={search} onChange={setSearch} data-testid={createTestid('search')} disableMargin />
        <CreateTemplateButton data-testid={testid} />
      </Stack>
      <TemplatesList
        rowData={templates}
        quickFilterText={search}
        data-testid={createTestid('list')}
        onDetailsTemplate={onDetailsModal}
      />
      <Dialogv2
        dialogTitle={`Template Details - ${openTemplateDetails?.name}`}
        DialogTitleIcon={Icon.Details}
        open={!!openTemplateDetails}
        onClose={setCloseTemplateDetails}
        data-testid={createTestid('dialog')}
      >
        {openTemplateDetails && (
          <TemplateDetailsModal template={openTemplateDetails} data-testid={createTestid('modal-details')} />
        )}
      </Dialogv2>
    </>
  );
};
