import { makeStyles } from 'mns-components';

export const templateStyles = makeStyles({
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  aggrid: {
    flexBasis: 0,
    minHeight: 'auto',
  },
});
