import type { RouterInterface } from '../../components/routing/RouterInterface';
import { Upload } from './view/Upload';

export const rawRoutes = [
  {
    label: 'Uploads',
    key: 'uploads',
    link: '/files',
    component: Upload,
  },
] as const;

type ViewRouteKey = typeof rawRoutes[number]['key'] & string;

export const getRoute = <K extends ViewRouteKey>(key: K): RouterInterface =>
  rawRoutes.find((item) => item.key === key)!;

export const getUploadsRoute = getRoute;

export const routes = rawRoutes as readonly RouterInterface[];
