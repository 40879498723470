import { generatePath } from 'react-router-dom';
import type { RouterInterface } from '../../components/routing/RouterInterface';
import type { AppCode } from '../../components/views/appDescriptions';
import { ExtractionFunnel as ESGFunnel } from './extractions/ExtractionFunnel/ExtractionFunnel';
import { Extractions as ESGExtractions } from './extractions/Extractions';
import { TemplateFunnel } from './extractions/templateFunnel/TemplateFunnel';
import { EsgDataMonitoringLayout } from './monitoring/EsgDataMonitoring';
import { ESGReports } from './reports/ESGReports';
import { ESGScoresOverview } from './score/ESGScoresOverview';

export const esgAppRoutes = [
  {
    link: '/esg/extractions/generate-extraction',
    key: 'generate-extraction',
    component: ESGFunnel,
  },
  {
    link: '/esg/extractions/generate-template',
    key: 'generate-template',
    component: TemplateFunnel,
  },
  {
    label: 'Extractions',
    link: '/esg/extractions/:view?',
    linkDefaultArgs: { view: 'generated' },
    key: 'esg-extraction',
    component: ESGExtractions,
    expectRole: 'APP_EXTRACTION-BETA',
  },
  {
    label: 'ESG scores',
    link: '/esg/scores/:id?/:date?/:appCode?',
    linkDefaultArgs: { id: undefined, date: undefined, appCode: undefined },
    key: 'esg-scores-overview',
    component: ESGScoresOverview,
  },
  {
    label: 'Reports',
    link: '/esg/reports',
    key: 'esg-reports',
    component: ESGReports,
    expectRole: 'APP_REPORTING-BETA',
  },
  {
    label: 'ESG data monitoring',
    link: '/esg/data-monitoring/:providerParam?',
    key: 'esg-data-monitoring',
    linkDefaultArgs: { providerParam: undefined },
    component: EsgDataMonitoringLayout,
  },
] as const;

export const routes: readonly RouterInterface[] = esgAppRoutes;

export type RouteKey = typeof esgAppRoutes[number]['key'];

export const getRoute = (key: RouteKey) => routes.find((route) => route.key === key)!;
export const getEsgRoute = getRoute;

export const getEsgScoreOverviewPage = (portfolioId: string, valuationDate: string, app: AppCode) =>
  generatePath(getEsgRoute('esg-scores-overview').link, { id: portfolioId, date: valuationDate, appCode: app });
