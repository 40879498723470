import { useCallbackImmutable, useNavigate } from 'mns-components';
import React, { useEffect } from 'react';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { isCognitoUser, useAuthUser, useUserApps } from '../../hooks/useAuth';
import { useHubspot } from '../../hooks/useGlobalState';
import { getAuthRoute } from '../auth/routes';

declare const window: ThisWindow;

export type LoggedInRouteProps<T extends { 'data-testid': string } = { 'data-testid': string }> = RouteProps & {
  component: React.FC<T>;
  childProps?: AnyObject;
  appRole?: string;
  'data-testid': string;
};

const LoggedInRoute: React.FC<LoggedInRouteProps> = ({
  component: Comp,
  appRole = '',
  childProps = {},
  'data-testid': testid,
  ...rest
}: LoggedInRouteProps) => {
  const navigate = useNavigate();
  const [authUser] = useAuthUser();
  const isLoggedIn = isCognitoUser(authUser);
  const roles = useUserApps();
  const { data: hubspot } = useHubspot({ enabled: isCognitoUser(authUser) });

  useEffect(() => {
    if (hubspot && window && window.HubSpotConversations) {
      const status = window.HubSpotConversations.widget.status();

      window.hsConversationsSettings = {
        identificationEmail: hubspot.email,
        identificationToken: hubspot.token,
      };

      window.HubSpotConversations.clear({ resetWidget: true });
      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
  }, [hubspot, authUser]);

  useEffect(() => {
    if (authUser) {
      if (!isCognitoUser(authUser)) {
        navigate(getAuthRoute('logout').path);
      } else if (appRole && roles) {
        if (!roles.includes(appRole)) {
          navigate('/unauthorized');
        }
      }
    }
  }, [authUser, navigate, appRole, roles]);

  const render = useCallbackImmutable((routeProps: RouteComponentProps) => (
    <Comp {...routeProps} {...childProps} data-testid={testid} />
  ));

  if (!isLoggedIn) {
    return null;
  }

  return <Route {...rest} render={render} />;
};

export default LoggedInRoute;
