import type { OptionType } from 'mns-components';
import {
  Button,
  TextField as TextFieldInput,
  useTestid,
  AlertMessage,
  NotificationDot,
  Icon,
  useCallbackImmutable,
  isEmail,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useEmitterAccessRequests } from '../../../../hooks/useGlobalState';
import { ShareclassSimpleTable } from '../ShareclassSimpleTable';
import useStyles from '../styles/InviteAccessPermission.styles';
import { InviteEmailsDisplay } from './InviteEmailsDisplay';
import { SelectAmContactList } from './SelectAmContactList';

type InvitationData = {
  email: string;
};

type AccessRequestProps = {
  selectedItems: CollectApi.FundPositionToCollect[];
  selectedShareclass: string[];
  setSelectedShareclass(list: string[]): void;
  closeModal: () => void;
  saveInvitationAccess: (email: string[]) => void;
  'data-testid': string;
};

export const InviteAccessPermission: React.FC<AccessRequestProps> = ({
  selectedItems: rows,
  selectedShareclass,
  setSelectedShareclass,
  closeModal,
  saveInvitationAccess,
  'data-testid': testid,
}: AccessRequestProps) => {
  const createTestid = useTestid(testid);
  const methods = useForm<InvitationData>({ mode: 'onBlur' });
  const [invitationList, setInvitationList] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  const classes = useStyles();

  // AM email Lists
  const [amIdList, setAmIdList] = useState<OptionType[]>([]);
  const { data: authorizationList } = useEmitterAccessRequests();

  // get list of options with label: AM and value: id
  useEffect(() => {
    if (authorizationList) {
      const allAmOptions = authorizationList
        .filter((autho: CollectApi.AccessDemand) => ['ACCEPTED', 'PENDING'].includes(autho.status))
        .reduce((acc: OptionType[], autho: CollectApi.AccessDemand) => {
          acc.push({ label: autho.receptor, value: autho.receptorId });
          return acc;
        }, [] as OptionType[]);
      // remove duplicates
      const amOptionsFiltered = allAmOptions.filter(
        (option: OptionType, index: number) =>
          index == allAmOptions.findIndex((elem: OptionType) => elem.value == option.value),
      );
      setAmIdList(Array.from(new Set(amOptionsFiltered)));
    }
  }, [authorizationList]);

  const handleSave = useCallbackImmutable(() => {
    setIsSaving(true);
    saveInvitationAccess(invitationList);
  });

  const addEmail = useCallbackImmutable((email: string): void => {
    if (!invitationList.find((mail) => mail === email)) setInvitationList([...invitationList, email]);
  });

  const addEmailLists = useCallbackImmutable((emails: string[]) => {
    const emailFiltered = emails
      .filter((email, index) => emails.indexOf(email) == index)
      .filter((email) => !invitationList.find((mail) => mail == email));
    emailFiltered.unshift(...invitationList);
    setInvitationList(emailFiltered);
  });

  const removeEmail = useCallbackImmutable((email: string): void => {
    setInvitationList(invitationList.filter((mail) => mail !== email));
  });

  return (
    <>
      <div className={classes.content}>
        <AlertMessage variant="info" data-testid={createTestid('alertMessage')}>
          Invite asset managers to share their inventories. They will receive an email notification inviting them to
          upload the inventories of funds you have selected.
        </AlertMessage>
        <div className={classes.sectionTitle}>
          Selected share classes
          <NotificationDot notifValue={selectedShareclass.length} isInTab />
        </div>
        <ShareclassSimpleTable
          rows={rows}
          selection={selectedShareclass}
          onSelectionChange={useCallbackImmutable((newSelection) => {
            setSelectedShareclass(newSelection);
          })}
          data-testid={createTestid('shareclassTable')}
        />
        <SelectAmContactList
          amOptionList={amIdList}
          addEmailLists={addEmailLists}
          data-testid={createTestid('selectAmList')}
        />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((params) => addEmail(params.email))} data-testid={testid}>
            <div className={classes.emailText}>
              <TextFieldInput
                name="email"
                placeholder="Enter an asset manager's email address"
                type="text"
                rules={{
                  required: 'This field is required',
                  validate: (value) => isEmail(value) || 'Email format is not correct',
                }}
                data-testid={createTestid('input-email')}
              />
              <Button
                color="primary"
                disabled={isSubmitting || !isDirty}
                type="submit"
                size="medium"
                startIcon={<Icon.Add data-testid={createTestid('icon-addEmail')} />}
                data-testid={createTestid('button-addEmail')}
                style={{ paddingRight: '1.1rem' }}
              >
                Add Email
              </Button>
            </div>
            <InviteEmailsDisplay
              invitationList={invitationList}
              removeEmail={removeEmail}
              data-testid={createTestid('inviteDisplay')}
            />
          </form>
        </FormProvider>
      </div>
      <div className={classes.formFooter}>
        <hr />
        <Button color="primary" outlined onClick={closeModal} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={selectedShareclass.length == 0 || invitationList.length == 0}
          onClick={handleSave}
          loading={isSaving}
          data-testid={createTestid('button-submit')}
        >
          Send invitation
        </Button>
      </div>
    </>
  );
};
