import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, FormStepper, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { useApplicationConfig } from '../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../common/getImageContent';
import { usePortfoliosRequest } from '../../../hooks/usePortfoliosRequest';
import { dataDeliveryApi } from '../../../store/apis';
import type { AppCode } from '../../views/appDescriptions';
import { getViewsRoute } from '../../views/routes';
import { buildSelectRecipientsStep } from './SelectRecipientsStep';
import { buildSelectScopeStep } from './SelectScopeStep';
import type { CreateEmailDeliverySteps } from './types';

const useSteps = () => useMemo(() => [buildSelectScopeStep(), buildSelectRecipientsStep()], []);

type CreateEmailStepperDisseminationProps = {
  onClose(): void;
  appCode: AppCode;
  'data-testid': string;
};

export const CreateEmailStepperDissemination: React.FC<CreateEmailStepperDisseminationProps> = ({
  onClose,
  appCode,
  'data-testid': testid,
}) => {
  const {
    company: { name: companyName },
    content: { title },
  } = useApplicationConfig(appCode);

  const createTestid = useTestid(testid);

  const icon = useAppBackgroundClassName(appCode);

  const {
    data: portfolios,
    isLoading: isLoadingPortfolios,
    error: errorPortfolios,
  } = usePortfoliosRequest({ portfolioOriginType: 'UPLOADED' });

  const handleSubmit = useCallbackImmutable(
    async ([{ title: deliveryTitle, portfolioVersionIds, navDate }, { emails }]: CreateEmailDeliverySteps) => {
      const scopes = portfolioVersionIds.map((latestVersionId): DataDeliveryApi.CreateDataDeliveryRequestScope => {
        const found = portfolios?.find((ptf) => ptf.latestVersion.id === latestVersionId);
        if (found) {
          return {
            dataFormat: found.externalId.type,
            fileName: found.latestVersion.ingestionFileName,
            identification: found.externalId.value,
            identificationType: found.externalId.type,
            name: found.name,
            productionDate: navDate,
            type: 'TPT',
            qualities: found.latestVersion.qualities,
          };
        }
        throw new Error('Unexpected error');
      });

      await dataDeliveryApi.createDelivery({
        title: deliveryTitle,
        emails,
        scopes,
      });
      onClose();
    },
  );

  const steps = useSteps();

  if (!portfolios?.length) {
    if (isLoadingPortfolios) {
      return <Backdrop data-testid={createTestid('backdrop')} />;
    }

    if (errorPortfolios) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            illustrationVariant="iconType"
            data-testid={createTestid('error-ptf')}
          />
        </Stack>
      );
    }

    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="upload"
          title="Get started with your upload!"
          subTitle="Start your Manaos journey by uploading your file."
          data-testid={createTestid('empty-ptf')}
          buttonText="Upload"
          buttonIcon="upload"
          buttonVariant="primary"
          illustrationVariant="iconType"
          buttonHref={getViewsRoute('uploads').path}
        />
      </Stack>
    );
  }

  return (
    <FormStepper<CreateEmailDeliverySteps>
      header={{ appTitle: title, providerName: companyName, appBackgroundClassName: icon }}
      displayCounter
      displayNavigation
      displayErrors
      steps={steps}
      onCancel={onClose}
      onSubmit={handleSubmit}
      buttonBackground="white"
      data-testid={testid}
    />
  );
};
