import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import type { FormResolverError } from 'mns-components';
import { Button, useTestid, LoadingCircle, Icon, useCallbackImmutable } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { etlApi } from '../../../../store/apis';
import type { ReplyFormType } from './ReplyAccessRequestForm';
import { ReplyAccessRequestForm } from './ReplyAccessRequestForm';
import useStyles from './replyAccessRequestStyles';

interface AccessRequestProps {
  selectedItems: CollectApi.AccessDemand[];
  closeModal: () => void;
  onAccept(comment: string, scopeToShare: string, embargoDelay?: number): void;
  onRefuse(comment: string): void;
  'data-testid': string;
}

type FormState = 'loading' | 'error' | 'accept' | 'refuse' | 'ready';

export const ReplyAccessRequest: React.FC<AccessRequestProps> = ({
  selectedItems,
  closeModal,
  onAccept,
  onRefuse,
  'data-testid': testid,
}: AccessRequestProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();

  const {
    refetch: loadScopes,
    isLoading: isLoadingScopes,
    status: statusScopes,
    data: scopesToShare,
  } = useQuery(['etlApi.getOutputTemplates'], () => etlApi.getOutputTemplates(), {
    select: (data) => {
      return data.reduce((acc, tpl) => {
        acc[tpl.id] = tpl.label;
        return acc;
      }, {} as Record<string, string>);
    },
  });

  const defaultValues = useMemo(
    () =>
      scopesToShare
        ? ({ comment: '', embargoDelay: '', scopeToShare: Object.keys(scopesToShare)[0] } as ReplyFormType)
        : null,
    [scopesToShare],
  );

  const [state, setState] = useState<FormState>('loading');
  const [formData, setFormData] = useState<ReplyFormType>();

  useEffect(() => {
    if (defaultValues) {
      setFormData(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!['loading', 'success'].includes(statusScopes)) {
      loadScopes();
    }
    if (statusScopes === 'success') {
      setState('ready');
    }
  }, [loadScopes, statusScopes]);

  const maxEmbargoDelay = useMemo(
    () =>
      Math.min(
        ...selectedItems.map((item) => parseInt(/\d+/.exec(item.deadline)?.[0] as string, 10)).filter(Number.isFinite),
      ),
    [selectedItems],
  );

  const validate = useCallbackImmutable((props: ReplyFormType): FormResolverError<ReplyFormType> | null => {
    if (!props.embargoDelay) {
      setState('ready');
      setFormData(props);
      return null;
    }
    const delay = parseInt(props.embargoDelay, 10);
    if (Number.isNaN(delay)) {
      setState('error');
      return { embargoDelay: 'Embargo delay is not a number' };
    }
    if (delay < 0) {
      setState('error');
      return {
        embargoDelay: 'Embargo delay should not be negative',
      };
    }
    if (delay > maxEmbargoDelay) {
      setState('error');
      return {
        embargoDelay: 'Embargo delay should be lower than delivery deadlines, otherwise please deny these requests',
      };
    }
    setState('ready');
    setFormData(props);
    return null;
  });

  const handleAccept = useCallbackImmutable(() => {
    if (formData) {
      setState('accept');
      const delay = parseInt(formData.embargoDelay, 10);
      onAccept(formData.comment, formData.scopeToShare, delay);
    }
  });

  const handleRefuse = useCallbackImmutable(() => {
    if (formData) {
      setState('refuse');
      onRefuse(formData.comment);
    }
  });

  return (
    <>
      <div className={classes.content}>
        <span className={classes.description}>
          <Icon.Info data-testid={createTestid('icon-info')} />
          <h3 className={classes.cardTitles}>
            You are about to reply on the access requested for the following shareclass
          </h3>
        </span>
        <h3 className={classes.subTitles}>Shareclass selected</h3>
        <div className={classes.verticalScrollTable}>
          <Table aria-label="simple table" data-testid={createTestid('table-selected')}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaders}>Shareclass ID</TableCell>
                <TableCell className={classes.tableHeaders}>Requested by</TableCell>
                <TableCell className={classes.tableHeaders}>Deadline</TableCell>
                <TableCell className={classes.tableHeaders}>Frequency</TableCell>
                <TableCell className={classes.tableHeaders}>Look-through rate</TableCell>
                <TableCell className={classes.tableHeaders}>
                  Access date
                  <Tooltip
                    title="Your requestor requires access from this date and all coming dates onwards."
                    className={classes.tooltip}
                  >
                    <Icon.Info size="small" data-testid={createTestid('accessDate-icon-info')} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItems.map((row: CollectApi.AccessDemand) => (
                <TableRow key={`${row.dataAccessAuthorizationId}-${row.fundId}`}>
                  <TableCell component="th" scope="row">
                    {row.fundId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.requestor}
                  </TableCell>
                  <TableCell className={classes.tableContent}>{row.deadline}</TableCell>
                  <TableCell className={classes.tableContent}>
                    <Icon.Calendar data-testid={createTestid(`${row.fundId}-icon-calendar`)} />
                    {row.sharingFrequency.charAt(0).toUpperCase() + row.sharingFrequency.slice(1).toLowerCase()}
                  </TableCell>
                  <TableCell className={classes.tableContent}>{row.lookThroughLevel}</TableCell>
                  <TableCell className={classes.tableContent}>{row.startPoint}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {isLoadingScopes || !defaultValues || !scopesToShare ? (
          <div className={classes.loader}>
            <LoadingCircle data-testid={createTestid('loading')} />
          </div>
        ) : (
          <ReplyAccessRequestForm
            scopesToShare={scopesToShare}
            defaultValues={defaultValues}
            validationAtEvent="all"
            onValidate={validate}
            data-testid={createTestid('form')}
          />
        )}
      </div>
      <div className={classes.formFooter}>
        <hr />
        <Button color="primary" outlined onClick={closeModal} data-testid={createTestid('button-cancel')}>
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={handleRefuse}
          loading={state === 'refuse'}
          disabled={state !== 'ready'}
          data-testid={createTestid('button-deny')}
        >
          Deny
        </Button>
        <Button
          color="primary"
          onClick={handleAccept}
          loading={state === 'accept'}
          disabled={state !== 'ready'}
          data-testid={createTestid('button-submit')}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};
