import { IconButton, Stack, Tooltip } from '@mui/material';
import type { AggridTableColumn } from 'mns-components';
import {
  AggridTable,
  Icon,
  makeActionColDef,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useTestid,
} from 'mns-components';
import { useMemo } from 'react';
import type { ErrorLogDataRework } from './DialogAuditErrorLog';
import { DialogAuditErrorLogListHeaderRenderer } from './DialogAuditErrorLogListHeaderRenderer';
import { DialogAuditErrorLogListLevelRenderer } from './DialogAuditErrorLogListLevelRenderer';

type DialogAuditErrorLogListProps = {
  onDetailsClick: (errorDetail: ErrorLogDataRework) => void;
  errorlogData: ErrorLogDataRework[];
  currentErrorDetailsOpen?: ErrorLogDataRework;
  searchValue: string;
  'data-testid': string;
};
type ColumnProps = {
  onDetailsClick: (errorDetail: ErrorLogDataRework) => void;
  currentErrorDetailsOpen?: ErrorLogDataRework;
  'data-testid': string;
};

const getActionColDef = makeActionColDef<ErrorLogDataRework>();

const useColumns = ({ onDetailsClick, currentErrorDetailsOpen, 'data-testid': testid }: ColumnProps) => {
  const createTestid = useTestid(testid);
  const onClickDetailsOpen = useCallbackImmutable((errorDetails: ErrorLogDataRework) => () => {
    onDetailsClick(errorDetails);
  });

  return useMemo(
    (): AggridTableColumn<ErrorLogDataRework>[] => [
      {
        field: 'errorStatus',
        headerName: 'Level',
        width: 180,
        suppressMenu: true,
        headerComponentFramework: () => (
          <DialogAuditErrorLogListHeaderRenderer data-testid={createTestid('level-header')} />
        ),
        cellRendererFramework: ({ data }) => (
          <DialogAuditErrorLogListLevelRenderer fileError={data} data-testid={createTestid('level-cell')} />
        ),
      },
      {
        field: 'errorType',
        headerName: 'Log type',
        width: 250,
      },
      {
        field: 'columnName',
        headerName: 'Column',
        flex: 1,
        suppressMenu: true,
      },
      getActionColDef('lineNumbers', 70, ({ data }) => (
        <Stack alignItems="center" justifyContent="center" position="relative">
          <IconButton onClick={onClickDetailsOpen(data)} data-testid={createTestid('open-button')}>
            <Tooltip
              title={data === currentErrorDetailsOpen ? 'Close details' : 'Show details'}
              arrow
              placement="left-start"
            >
              <Stack>
                {data === currentErrorDetailsOpen ? (
                  <Icon.Close color="secondary" data-testid={createTestid('close-button-icon')} />
                ) : (
                  <Icon.Details color="secondary" data-testid={createTestid('open-button-icon')} />
                )}
              </Stack>
            </Tooltip>
          </IconButton>
        </Stack>
      )),
    ],
    [onClickDetailsOpen, createTestid, currentErrorDetailsOpen],
  );
};

export const DialogAuditErrorLogList = ({
  'data-testid': testid,
  errorlogData,
  currentErrorDetailsOpen,
  searchValue,
  onDetailsClick,
}: DialogAuditErrorLogListProps) => {
  const createTestid = useTestid(testid);

  const columns = useColumns({ onDetailsClick, currentErrorDetailsOpen, 'data-testid': testid });

  return (
    <AggridTable
      rowData={errorlogData}
      quickFilterText={searchValue}
      getRowNodeId={useImmutable(() => (row: ErrorLogDataRework) => row.columnName + row.errorMessage)}
      columnDefs={columns}
      defaultColDef={useDefaultColDef<ErrorLogDataRework>()}
      onGridReady={useAutoSize('fit')}
      rowHeight={50}
      data-testid={createTestid('table')}
    />
  );
};
