import { Stack } from '@mui/material';
import type { StepProps, TabType } from 'mns-components';
import {
  Tabs,
  Typography,
  objectReduce,
  throttle,
  useImmutable,
  useTestid,
  objectHas,
  useCallbackImmutable,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { isAxiosError } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateTemplate } from '../../../../api/templateData';
import { toastUpdateOrCreate } from '../../../../common/toast';
import { mainSourceName } from '../../extractor.const';
import { CreateTemplateButtons } from '../CreateTemplateButtons';
import { ApplicationsDataPointsTab } from '../tabs/ApplicationsDataPointsTab';
import { FinalizeSettingsTab } from '../tabs/FinalizeSettingsTab';
import { PortfolioDataPointsTab } from '../tabs/PortfolioDataPointsTab';
import { type CreateTemplateSteps } from '../types';

type TabValue = 'finalize-settings' | 'finalize-portfolioDataPoints' | 'finalize-applicationsDataPoints';

const tabsMap = (
  portfolioDataPointsCount: number,
  applicationsDataPointsCount: number,
): (TabType<TabValue> & { component: React.FC<StepProps<CreateTemplateSteps, 3>> })[] => [
  {
    value: 'finalize-settings',
    label: 'Extraction settings',
    component: FinalizeSettingsTab,
  },
  {
    value: 'finalize-portfolioDataPoints',
    label: 'Portfolio data points',
    count: portfolioDataPointsCount,
    component: PortfolioDataPointsTab,
  },
  {
    value: 'finalize-applicationsDataPoints',
    label: 'App data points',
    count: applicationsDataPointsCount,
    component: ApplicationsDataPointsTab,
  },
];

const knownFieldConflict = {
  name: 'You cannot create a template with this name because there is already a template with the same name',
} as const;

export const FinalizeStep: React.FC<StepProps<CreateTemplateSteps, 3>> = ({ 'data-testid': testid, ...props }) => {
  const {
    stepValues: [, { portfolioDataPoints }, { applicationsDataPoints }],
    goPrevious,
    onEnd,
    Buttons,
  } = props;

  const createTestid = useTestid(testid);

  const tabs = useMemo(() => {
    const applicationsDataPointsCount = objectReduce(
      applicationsDataPoints,
      (acc, list) => (list ? acc + list.length : acc),
      0,
    );
    return tabsMap(portfolioDataPoints.length, applicationsDataPointsCount);
  }, [applicationsDataPoints, portfolioDataPoints.length]);

  const [tabIndex, setTabIndex] = useState<TabValue>(tabs[0].value);
  const onTabChange = useImmutable(() => (_: unknown, newValue: TabValue) => setTabIndex(newValue));
  const Comp = useMemo(() => tabs.find((tab) => tab.value === tabIndex)?.component, [tabIndex, tabs]);

  // submit
  const { mutateAsync: createTemplate, isLoading: isCreatingTemplate } = useCreateTemplate();

  const onSubmit = useCallbackImmutable(
    throttle(async () => {
      const toastId = toast.info('The template creation is processing');
      try {
        const columns: DataExtractorApi.Template.Pair[] = [
          ...props.stepValues[1].portfolioDataPoints.map(
            (code): DataExtractorApi.Template.Pair => ({ code, source: mainSourceName }),
          ),
          ...objectReduce(
            props.stepValues[2].applicationsDataPoints,
            (acc, list, appCode) => {
              if (list) acc.push(...list.map((code) => ({ code, source: appCode })));
              return acc;
            },
            [] as DataExtractorApi.Template.Pair[],
          ),
        ];

        await createTemplate(columns, props.stepValues[0].templateName, false);
        toastUpdateOrCreate(toastId, {
          type: 'success',
          render: 'The template was successfully created',
        });
        onEnd();
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 409) {
          const conflictField = error.response.data?.message;

          if (objectHas(knownFieldConflict, conflictField)) {
            toastUpdateOrCreate(toastId, {
              type: 'error',
              render: knownFieldConflict[conflictField],
            });
            return;
          }
        }

        toastUpdateOrCreate(toastId, {
          type: 'error',
          render: 'The template cannot be created',
        });
      }
    }, 200),
  );

  return (
    <Stack direction="column" height="100%">
      <Typography variant="body2" component="p" margin="1rem 0">
        To finalise this template creation, please check the data selected in the previous steps by navigating through
        these tabs.
      </Typography>
      <Tabs<TabValue>
        variant="fullWidth"
        tabs={tabs}
        value={tabIndex}
        onClick={onTabChange}
        data-testid={createTestid('tabs')}
      />
      <Stack minWidth={0} flexGrow={1} direction="column" overflow="clip auto">
        {Comp ? <Comp {...props} data-testid={createTestid(tabIndex)} /> : undefined}
      </Stack>
      <Buttons>
        <CreateTemplateButtons
          onPrevious={goPrevious}
          onSubmit={onSubmit}
          nextLoading={isCreatingTemplate}
          submitLabel="Create new template"
          data-testid={createTestid('buttons')}
        />
      </Buttons>
    </Stack>
  );
};
