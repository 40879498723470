import { useLocalStorage } from 'mns-components';
import { previousMonthLastDate } from '../../../common/date';

const dashboardValuationKey = 'DASHBOARD_VALUATION_DATE_KEY';
const dashboardPortfolioKey = 'DASHBOARD_PORTFOLIO_SELECTED_KEY';

export const useDashboardValuationDate = () => {
  const [valuationDateFromStorage, setValuationDateFromStorage] = useLocalStorage<Date | null>(dashboardValuationKey);
  const effectiveDate = valuationDateFromStorage ? new Date(valuationDateFromStorage) : previousMonthLastDate;

  return [effectiveDate, setValuationDateFromStorage] as const;
};

export const useDashboardPortfolioSelected = () => {
  const [portfolioSelectedFromStorage, setPortfolioSelectedFromStorage] =
    useLocalStorage<string>(dashboardPortfolioKey);
  const portfolioSelected = portfolioSelectedFromStorage ?? 'all';

  return [portfolioSelected, setPortfolioSelectedFromStorage] as const;
};
