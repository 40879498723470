import { Stack } from '@mui/material';
import {
  AppLayout,
  Button,
  FunnelHeader,
  generateKey,
  objectReduce,
  useCallbackImmutable,
  useImmutable,
  useNavigate,
  useTestid,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { Id as ToastId } from 'react-toastify';
import { toastUpdateOrCreate } from '../../../../common/toast';
import { useCreateExtraction, useCreateTemplate } from '../../hooks';
import { getEsgRoute, getRoute } from '../../routes';
import type { ConfirmCreateExtractionFormData } from './ConfirmCreateExtractionPopup';
import { ConfirmCreateExtractionPopup } from './ConfirmCreateExtractionPopup';
import { SelectPtfSection } from './SelectPtfSection';
import type { TemplateData } from './SelectTemplate';
import { SelectTemplateSection } from './SelectTemplateSection';
import { SetupSection, type SetupFormData } from './SetupSection';

type ExtractionFormData = {
  setup: SetupFormData;
  template: TemplateData;
  ptf: DataExtractorApi.Extraction.ExtractionScope;
  confirm: ConfirmCreateExtractionFormData;
};

const useSubmit = (formData: ExtractionFormData, onEnd: () => void) => {
  const toastIdRef = useRef<ToastId>(0);
  const [createWithTemplateId, setCreateWithTemplateId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: createTemplate, isError: isErrorTemplate } = useCreateTemplate({
    onSuccess: (data) => setCreateWithTemplateId(data.id),
  });
  const {
    mutateAsync: createExtraction,
    isSuccess: isCreatedExtraction,
    isError: isErrorExtraction,
  } = useCreateExtraction();

  const hiddenTemplateCreation = formData.confirm.action === 'hide';
  const templateName = formData.confirm.templateName;

  const handleCreateTemplate = useCallbackImmutable(() => {
    const columns: DataExtractorApi.Template.Pair[] = objectReduce(
      formData.template.selected,
      (acc, list, appCode) => {
        if (list) acc.push(...list.map((code) => ({ code, source: appCode })));
        return acc;
      },
      [] as DataExtractorApi.Template.Pair[],
    );

    if (hiddenTemplateCreation) {
      createTemplate(columns, `extraction-${generateKey()}`, true);
    } else {
      createTemplate(columns, templateName, false);
    }
  });

  const handleCreateExtraction = useCallbackImmutable((templateId: string) => {
    const {
      setup: { extractionName, extractionType, extractionFeed },
      ptf: extractionScope,
    } = formData;

    if (extractionName?.length && extractionType) {
      createExtraction({
        extractionName,
        extractionType,
        extractionFeed: extractionFeed ?? 'API',
        templateId: templateId,
        extractionScope,
      });
    }
  });

  const handleSubmit = useCallbackImmutable(() => {
    setIsSubmitting(true);
    toastIdRef.current = toast.info('The extraction is in progress');
    const templateId = formData.template.matchTemplateId;
    if (templateId) {
      setCreateWithTemplateId(templateId);
    } else {
      handleCreateTemplate();
    }
  });

  useEffect(() => {
    if (createWithTemplateId) handleCreateExtraction(createWithTemplateId);
  }, [handleCreateExtraction, createWithTemplateId]);

  useEffect(() => {
    if (isCreatedExtraction) {
      toastUpdateOrCreate(toastIdRef.current, {
        type: 'success',
        render: 'The extraction has been created and will be available in a few minutes',
      });
      setIsSubmitting(false);
      onEnd();
    }
  }, [isCreatedExtraction, onEnd]);

  useEffect(() => {
    if (isErrorExtraction) {
      toastUpdateOrCreate(toastIdRef.current, {
        type: 'error',
        render: 'The creation of extraction failed',
      });
      setIsSubmitting(false);
    }
  }, [isErrorExtraction]);

  useEffect(() => {
    if (isErrorTemplate) {
      toastUpdateOrCreate(toastIdRef.current, {
        type: 'error',
        render: hiddenTemplateCreation ? 'The registration of metadatas failed' : 'The creation of template failed',
      });
      setIsSubmitting(false);
    }
  }, [isErrorTemplate, hiddenTemplateCreation]);

  return [isSubmitting, handleSubmit] as const;
};

type ExtractionFunnelProps = { 'data-testid': string };

export const ExtractionFunnel: React.FC<ExtractionFunnelProps> = ({ 'data-testid': testid }) => {
  const navigate = useNavigate();
  const createTestid = useTestid(testid);
  const backRoute = useImmutable(() => generatePath(getEsgRoute('esg-extraction').link, { view: 'generated' }));
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const [formData, setFormData] = useState<ExtractionFormData>(() => ({
    setup: { extractionName: '' },
    template: { templateType: '', selected: {}, matchTemplateId: null },
    ptf: [],
    confirm: { action: 'create', templateName: '' },
  }));

  const setSetupFormData = useCallbackImmutable((setup: SetupFormData) =>
    setFormData((current) => ({ ...current, setup })),
  );

  const setTemplateFormData = useCallbackImmutable((template: TemplateData) =>
    setFormData((current) => ({ ...current, template })),
  );

  const setPtfFormData = useCallbackImmutable((ptf: DataExtractorApi.Extraction.ExtractionScope) =>
    setFormData((current) => ({ ...current, ptf })),
  );

  const setConfirmFormData = useCallbackImmutable((confirmData: ConfirmCreateExtractionFormData) =>
    setFormData((current) => ({ ...current, confirm: confirmData })),
  );

  const handleNavigateAfterSubmit = useCallbackImmutable(() => {
    navigate(generatePath(getRoute('esg-extraction').link, { view: 'generated' }));
  });

  const [isSubmitting, handleSubmit] = useSubmit(formData, handleNavigateAfterSubmit);

  const handleSubmitCheck = useCallbackImmutable(() => {
    if (!formData.template.matchTemplateId) {
      setOpenConfirmPopup(true);
    } else {
      handleSubmit();
    }
  });

  const handleSubmitConfirm = useCallbackImmutable(() => {
    handleSubmit();
    setOpenConfirmPopup(false);
  });

  const handleCloseConfirmPopup = useCallbackImmutable(() => setOpenConfirmPopup(false));

  const isSetupSectionDisabled = isSubmitting;

  const isTemplateSectionDisabled =
    isSetupSectionDisabled ||
    !formData.setup.extractionName ||
    !formData.setup.extractionType ||
    (formData.setup.extractionType === 'AUTOMATIC' && !formData.setup.extractionFeed);

  const isPtfSectionDisabled =
    isTemplateSectionDisabled ||
    objectReduce(formData.template.selected, (acc, list) => acc + (list?.length ?? 0), 0) <= 0;

  const isButtonExtractDisabled = isPtfSectionDisabled || !formData.ptf.length;

  const templateType = formData.template.templateType;

  return (
    <Stack flexGrow={1}>
      <FunnelHeader
        title="Create a new extraction"
        subtitle="An extraction allows you to enrich your portfolios with the ESG data of your choice."
        hrefBack={backRoute}
        data-testid={createTestid('header')}
      />
      <Stack overflow="clip auto" flexGrow={1}>
        <AppLayout>
          <Stack flexGrow={0} gap="1.5rem">
            <SetupSection
              onChange={setSetupFormData}
              disabled={isSetupSectionDisabled}
              data-testid={createTestid('setup')}
            />
            <SelectTemplateSection
              onChange={setTemplateFormData}
              disabled={isTemplateSectionDisabled}
              data-testid={createTestid('selectTemplate')}
            />
            <SelectPtfSection
              extractionType={formData.setup.extractionType ?? 'MANUALLY'}
              onChange={setPtfFormData}
              disabled={isPtfSectionDisabled}
              data-testid={createTestid('selectPtfSection')}
            />
          </Stack>
          <Stack alignItems="flex-end" justifyContent="flex-end" marginRight="3.5rem" flexGrow={1}>
            <Button
              size="medium"
              color="secondary"
              onClick={handleSubmitCheck}
              disabled={isButtonExtractDisabled}
              data-testid={createTestid('extract-data')}
            >
              Extract data
            </Button>
          </Stack>
        </AppLayout>
      </Stack>
      {templateType && (
        <ConfirmCreateExtractionPopup
          open={openConfirmPopup}
          onClose={handleCloseConfirmPopup}
          onChange={setConfirmFormData}
          onSubmit={handleSubmitConfirm}
          templateType={templateType}
          data-testid={createTestid('confirm')}
        />
      )}
    </Stack>
  );
};
