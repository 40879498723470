import { Stack } from '@mui/material';
import type { GridApi } from 'ag-grid-community';
import type { AggridTableColumn, SelectFilterItem } from 'mns-components';
import {
  AggridTable,
  Button,
  Icon,
  SelectFilter,
  convertToDateLocal,
  makeActionColDef,
  theme,
  useAutoSize,
  useCallbackImmutable,
  useDefaultColDef,
  useImmutable,
  useRowSelection,
  useTestid,
} from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useMemo, useState } from 'react';
import { MoreDetailsPermissionsRenderer } from '../../../components/cellRender/MoreDetailsPermissionsRenderer';
import { ShareclassTooltip, shareclassPlain } from '../../../components/cellRender/ShareclassTooltip';
import { receiverPermissionListStyles as useStyles } from './receiverPermissionListStyles';
import { ReceiverPermissionsActionsRenderer } from './ReceiverPermissionsActionsRenderer';
import {
  ReceiverPermissionsStatusRenderer,
  receiverPermissionsStatusRendererPlain,
  receiverStatusMappings,
} from './ReceiverPermissionsStatusRenderer';

const getActionColDef = makeActionColDef<CollectApi.AccessDemand>();

interface PermissionsListProps {
  rowData: CollectApi.AccessDemand[];
  searchValue: string;
  onSelectItems: (selectedItems: CollectApi.AccessDemand[]) => void;
  openManagePermissionsModal(isOpen: boolean): void;
  openPermissionsDetailsModal(isOpen: boolean): void;
  'data-testid': string;
}

type DisseminationGridAccessRequestData = CollectApi.AccessDemand & {
  mainCatStatus: 'inactive' | 'active' | 'pending';
};

const accessRequestDateSize = { minWidth: 120, maxWidth: 120 };

const useColumns = (
  colSelect: AggridTableColumn<CollectApi.AccessDemand>,
  onSelectItems: PermissionsListProps['onSelectItems'],
  openPermissionsDetailsModal: PermissionsListProps['openPermissionsDetailsModal'],
  openManagePermissionsModal: PermissionsListProps['openManagePermissionsModal'],
  testid: string,
) => {
  const classes = useStyles();
  return useMemo(
    (): AggridTableColumn<CollectApi.AccessDemand>[] => [
      colSelect,
      {
        field: 'externalOwnerId',
        headerName: 'Shareclass ID',
        minWidth: 170,
        cellRendererFramework: ({ data: { externalId, externalOwnerId, dataAccessAuthorizationId } }) => (
          <ShareclassTooltip
            shareclassId={externalOwnerId}
            fundId={externalId}
            data-testid={`${testid}-${dataAccessAuthorizationId}-externalOwnerId`}
          />
        ),
        valueGetter: ({ data: { externalId, externalOwnerId } }) =>
          shareclassPlain({ shareclassId: externalOwnerId, fundId: externalId }),
      },
      {
        field: 'requestor',
        headerName: 'Requested by',
        tooltipField: 'requestor',
        minWidth: 100,
      },
      {
        field: 'startPoint',
        headerName: 'Start date',
        valueGetter: ({ data }) => convertToDateLocal(data.startPoint),
        ...accessRequestDateSize,
      },
      {
        field: 'endPoint',
        headerName: 'End date',
        valueGetter: ({ data }) => data.endPoint && convertToDateLocal(data.endPoint),
        ...accessRequestDateSize,
      },
      {
        field: 'embargoDelay',
        headerName: 'Embargo Delay',
        cellRendererFramework: ({ value }) => {
          if (Number.isFinite(value)) return value === 1 ? '1 day' : `${value} days`;
          return '';
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        maxWidth: 170,
        valueGetter: ({ data: { status } }) =>
          receiverPermissionsStatusRendererPlain({
            status,
          }),
        cellRendererFramework: ({ data: { dataAccessAuthorizationId, status } }) => (
          <ReceiverPermissionsStatusRenderer
            status={receiverStatusMappings[status as keyof typeof receiverStatusMappings]}
            data-testid={`${testid}-${dataAccessAuthorizationId}`}
          />
        ),
      },
      getActionColDef(
        'status',
        170,
        ({ value, data }) => (
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <ReceiverPermissionsActionsRenderer
              value={value}
              data={data}
              openManagePermissionsModal={openManagePermissionsModal}
              setSelected={onSelectItems}
              data-testid={`${testid}-receiverPermission-${data.dataAccessAuthorizationId}`}
            />
            <MoreDetailsPermissionsRenderer
              data={data}
              setSelected={onSelectItems}
              openPermissionsDetailsModal={openPermissionsDetailsModal}
              data-testid={`${testid}-${data.dataAccessAuthorizationId}`}
            />
          </Stack>
        ),
        { cellClass: classes.fullWidth },
      ),
    ],
    [classes.fullWidth, colSelect, onSelectItems, openManagePermissionsModal, openPermissionsDetailsModal, testid],
  );
};

const mergerStatusMapping = [
  {
    label: 'Pending',
    color: theme.colors.warning,
    filter: (row: CollectApi.AccessDemand): boolean => row.status === 'PENDING',
  },
  {
    label: 'Active',
    color: theme.colors.success,
    filter: (row: CollectApi.AccessDemand): boolean => row.status === 'ACCEPTED',
  },
  {
    label: 'Inactive',
    color: theme.colors.danger,
    filter: (row: CollectApi.AccessDemand): boolean =>
      row.status === 'REVOKED' || row.status === 'CANCEL' || row.status === 'REFUSED',
  },
] as const;

type MergerLabel = typeof mergerStatusMapping[number]['label'];

const mergeStatuses = (rowsData: DisseminationGridAccessRequestData[]) =>
  rowsData.reduce((acc, item) => {
    const merger = mergerStatusMapping.find((elem) => elem.filter(item));

    if (merger) {
      const { label } = merger;
      if (!(label in acc)) {
        acc[label] = [];
      }
      acc[label].push(item);
    }

    return acc;
  }, {} as Record<MergerLabel, CollectApi.AccessDemand[]>);

export const ReceiverPermissionList: React.FC<PermissionsListProps> = ({
  rowData,
  onSelectItems,
  openPermissionsDetailsModal,
  openManagePermissionsModal,
  searchValue,
  'data-testid': testid,
}: PermissionsListProps) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [selectedItems, setSelectedItems] = useState<CollectApi.AccessDemand[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const disabled: boolean = useMemo(() => selectedItems?.length == 0, [selectedItems]);

  const replySelected = useCallbackImmutable((): void => {
    if (selectedItems?.length) {
      onSelectItems(selectedItems);
      openManagePermissionsModal(true);
    }
  });

  const rowDataWithStatusGrouped = rowData.map((elem): DisseminationGridAccessRequestData => {
    const groupStatus = () => {
      switch (elem.status) {
        case 'REVOKED':
        case 'CANCEL':
        case 'REFUSED':
          return 'inactive';
        case 'ACCEPTED':
          return 'active';
        default:
          return 'pending';
      }
    };
    const mainCatStatus = groupStatus();
    return {
      ...elem,
      mainCatStatus,
    };
  });

  const mergedStatuses = mergeStatuses(rowDataWithStatusGrouped);

  const countStatuses = (Object.keys(mergedStatuses) as MergerLabel[]).map(
    (statusName): SelectFilterItem<MergerLabel | 'all'> => ({
      value: statusName,
      label: statusName,
      color: mergerStatusMapping.find((status) => status.label === statusName)?.color,
      count: mergedStatuses[statusName].length,
    }),
  );

  const data = useMemo(() => {
    return filterStatus !== 'all' ? mergedStatuses[filterStatus as MergerLabel] : rowDataWithStatusGrouped;
  }, [filterStatus, mergedStatuses, rowDataWithStatusGrouped]);

  const onResetFilters = useCallbackImmutable((): void => {
    setFilterStatus('all');
  });

  const [gridSelect, colSelect] = useRowSelection<CollectApi.AccessDemand>(
    ({ api }) => setSelectedItems(api.getSelectedRows()),
    true,
  );

  return (
    <>
      <div className={classes.buttonsFiltersContainer}>
        <div className={classes.exportButton}>
          <Button
            startIcon={<Icon.Download data-testid={createTestid('icon-export')} />}
            color="primary"
            outlined
            onClick={useCallbackImmutable(() =>
              gridApi?.exportDataAsExcel({
                columnKeys: ['externalOwnerId', 'requestor', 'startPoint', 'endPoint', 'embargoDelay', 'status'],
              }),
            )}
            size="medium"
            style={{ marginRight: '1rem' }}
            data-testid={createTestid('button-export')}
          >
            Export table
          </Button>
          <Button
            color="primary"
            onClick={replySelected}
            disabled={disabled}
            size="medium"
            startIcon={
              <Icon.Reply color={disabled ? 'disabled' : 'inversed'} data-testid={createTestid('icon-reply')} />
            }
            data-testid={createTestid('button-reply')}
          >
            Reply to access requests
          </Button>
        </div>
        <div className={classes.filters}>
          <SelectFilter
            label="Status"
            items={Object.values(countStatuses)}
            onClick={setFilterStatus}
            activeItem={filterStatus}
            data-testid={createTestid('selectfilter')}
          />
          <div style={{ zIndex: 500 }}>
            <Button
              color="primary"
              outlined
              size="small"
              startIcon={<Icon.Filter data-testid={createTestid('icon-filter')} />}
              onClick={onResetFilters}
              data-testid={createTestid('btn-reset')}
            >
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <AggridTable<CollectApi.AccessDemand>
        {...gridSelect}
        quickFilterText={searchValue}
        rowData={data}
        defaultColDef={useDefaultColDef()}
        columnDefs={useColumns(
          colSelect,
          onSelectItems,
          openPermissionsDetailsModal,
          openManagePermissionsModal,
          testid,
        )}
        isRowSelectable={useCallbackImmutable(({ data: { status } }) => status === 'PENDING')}
        getRowNodeId={useImmutable(
          () =>
            ({ dataAccessAuthorizationId }: CollectApi.AccessDemand) =>
              dataAccessAuthorizationId,
        )}
        getGridApiRef={setGridApi}
        pagination
        paginationAutoPageSize
        onGridReady={useAutoSize('fit')}
        data-testid={testid}
      />
    </>
  );
};
