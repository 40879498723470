import { colors, makeStyles } from 'mns-components';

const chaseUpCountRendererStyles = makeStyles({
  tooltip: {
    cursor: 'pointer',
    background: '#eff1fe',
    color: colors.infoColor,
    fontSize: '.75rem',
    fontFamily: 'Rubik',
  },
  infoIcon: {
    marginLeft: '.625rem',
    top: '.25rem',
    position: 'relative',
  },
  arrow: {
    color: '#eff1fe',
  },
  spanStyle: {
    color: colors.blueColor,
  },
});

export default chaseUpCountRendererStyles;
