import { Stack } from '@mui/material';
import type { ApplicationCardStatus } from 'mns-components';
import {
  EmptyStateV2,
  ApplicationCard,
  NotificationDot,
  convertToDateLocal,
  objectHas,
  useCallbackImmutable,
  useTestid,
} from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getApplicationConfig, useApplicationConfig } from '../../../common/getApplicationConfig';
import { useAppBackgroundStyles } from '../../../common/getImageContent';
import type { AppCode } from '../../../components/views/appDescriptions';
import { appCodes } from '../../../components/views/appDescriptions';
import { getViewsRoute } from '../../../components/views/routes';
import { useUser } from '../../../hooks/useAuth';
import { usePtfAndDate } from '../../../hooks/useGlobalState';
import { appMarketplaceApi } from '../../../store/apis';
import { getRoute as getAnalysisRoute } from '../../analysisDemands/routes';
import { getEsgScoreOverviewPage } from '../../esgApp/routes';
import { useApplicationsRequest } from '../hooks';
import { getRoute } from '../routes';
import {
  appDeliverableLightStyles as useAppDeliverableLightStyles,
  appDeliverableStyles as useAppDeliverableStyles,
  marketplaceByUseCasesStyles as useMarketplaceByUseCasesStyles,
  marketplaceListStyles as useStyles,
} from '../styles/marketplaceListStyles';

const isApplicationSubscribed = (appData: MarketplaceApi.Application, userRoles: string[]) => {
  if (!appCodes.includes(appData.codeApplication as AppCode)) {
    return false;
  }
  const {
    config: { role, analysisRequestType, portfolioViewType, link },
  } = getApplicationConfig(appData.codeApplication as AppCode);
  return userRoles.includes(role) && (link || analysisRequestType || portfolioViewType);
};

export const useApplicationsSubscribed = () => {
  const { apps: userRoles } = useUser();
  const applicationsRequest = useApplicationsRequest();
  return useMemo(
    () =>
      applicationsRequest.data
        ?.filter((appData) => isApplicationSubscribed(appData, userRoles))
        .sort((a, b) =>
          getApplicationConfig(a.codeApplication).content.title.localeCompare(
            getApplicationConfig(b.codeApplication).content.title,
          ),
        ) ?? [],
    [applicationsRequest.data, userRoles],
  );
};
const useExecuteLink = (appCode: AppCode) => {
  const {
    config: { analysisRequestType, portfolioViewType, link },
  } = useApplicationConfig(appCode);
  const [[ptfId, navDate]] = usePtfAndDate();

  if (link) {
    return link;
  }
  if (analysisRequestType) {
    return generatePath(getAnalysisRoute().link, { appCode });
  }
  if (portfolioViewType && ptfId && navDate) {
    return getEsgScoreOverviewPage(ptfId, navDate, appCode);
  }
  return getViewsRoute('portfolios-lists').path;
};

type MarketplaceItemProps = { appData: MarketplaceApi.Application; 'data-testid': string };

const MarketplaceItemExecute: React.FC<MarketplaceItemProps> = ({ appData, 'data-testid': testid }) => {
  const appCode = appData.codeApplication as AppCode;
  const appBackgroundClasses = useAppBackgroundStyles();
  const {
    content: { title, appDeliverable },
    company: { name },
  } = useApplicationConfig(appCode);
  const appBackgroundClassName = appBackgroundClasses[appCode];
  const executeLink = useExecuteLink(appCode);

  const status = useMemo((): ApplicationCardStatus => {
    if (appCode === 'manaos-coverageestimation') {
      return 'GIVEN';
    } else if (appData.hasAlreadySubscribed && !appData.subscribed && appData.subscriptionsDto?.status !== 'PENDING') {
      return 'EXPIRED';
    } else {
      return appData.subscriptionsDto?.status ?? null;
    }
  }, [appData.hasAlreadySubscribed, appData.subscribed, appData.subscriptionsDto?.status, appCode]);

  const statusDate = useMemo(
    () => convertToDateLocal(appData.subscriptionsDto?.startDate ?? ''),
    [appData.subscriptionsDto?.startDate],
  );

  const appDelivClasses = useAppDeliverableStyles();
  const appDeliverableBackgroundClassName = useMemo(
    () => (objectHas(appDelivClasses, appDeliverable) ? appDelivClasses[appDeliverable] : undefined),
    [appDelivClasses, appDeliverable],
  );

  const appDelivLightClasses = useAppDeliverableLightStyles();
  const backgroundClassName = useMemo(
    () => (objectHas(appDelivLightClasses, appDeliverable) ? appDelivLightClasses[appDeliverable] : undefined),
    [appDelivLightClasses, appDeliverable],
  );

  const applicationsRequest = useApplicationsRequest();
  const handleFavorite = useCallbackImmutable(async (newValue: boolean) => {
    try {
      if (!newValue) {
        await appMarketplaceApi.deleteFavorite(appCode);
        toast.success(`The app ${title} has been removed from your favorites`);
      } else {
        await appMarketplaceApi.putFavorite(appCode);
        toast.success(`The app ${title} has been added to your favorites`);
      }
      applicationsRequest.refetch();
    } catch {
      toast.info("couldn't modify favorite");
    }
  });

  return (
    <ApplicationCard.MarketplaceLink
      appDeliverable={appDeliverable}
      title={title}
      companyName={name}
      to={executeLink}
      subscriptionStatus={status}
      subscriptionStartDate={statusDate}
      applicationBackgroundClassname={appBackgroundClassName}
      appDeliverableBackgroundClassName={appDeliverableBackgroundClassName}
      appDeliverableShinyBackgroundClassName={backgroundClassName}
      isFavorite={appData.favorite}
      onFavorite={handleFavorite}
      data-testid={testid}
      data-stepid={`marketplaceCard-${appCode}`}
    />
  );
};

type MarketplaceExecuteListProps = {
  applications: MarketplaceApi.Application[];
  isDescribing?: boolean;
  'data-testid': string;
};

export const MarketplaceExecuteList: React.FC<MarketplaceExecuteListProps> = ({
  applications,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const classes = useStyles();
  const classesTmp = useMarketplaceByUseCasesStyles();

  const { apps: roles } = useUser();

  const favorites = useMemo(() => applications.filter((app) => app.favorite), [applications]);
  const notFavorites = useMemo(() => applications.filter((app) => !app.favorite), [applications]);

  if (!roles.find((role) => role.includes('APP_'))) {
    const allLink = getRoute('marketplace-all').link;

    return (
      <Stack margin="auto">
        <EmptyStateV2
          variant="info"
          useCase="noApps"
          title=" There is no app subscription yet!"
          subTitle="Discover all the services available on our catalog and subscribe to your first app."
          buttonVariant="secondary"
          buttonText="Discover our app catalog"
          buttonHref={allLink}
          data-testid={createTestid('error-roles')}
        />
      </Stack>
    );
  }

  if (applications.length === 0) {
    return null;
  }

  return (
    <div data-testid={testid}>
      {favorites.length > 0 && (
        <div className={classes.sectionContainer}>
          <div className={classes.sectionsTitles}>
            <div className={classesTmp.useCaseTitle}>My favorites</div>
            <div className={classesTmp.notification}>
              <NotificationDot isInTab notifValue={favorites.length} />
            </div>
          </div>
          <div className={classes.appsContainer}>
            {favorites.map((favAppData) => (
              <MarketplaceItemExecute
                appData={favAppData}
                data-testid={createTestid(favAppData.codeApplication)}
                key={favAppData.codeApplication}
              />
            ))}
          </div>
        </div>
      )}
      {notFavorites.length > 0 && (
        <div className={classes.sectionContainer}>
          <div className={classes.sectionsTitles}>
            <div className={classesTmp.useCaseTitle}>My apps</div>
            <div className={classesTmp.notification}>
              <NotificationDot isInTab notifValue={notFavorites.length} />
            </div>
          </div>
          <div className={classes.appsContainer}>
            {notFavorites.map((appData) => (
              <MarketplaceItemExecute
                appData={appData}
                data-testid={createTestid(appData.codeApplication)}
                key={appData.codeApplication}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
