import { Stack } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { useTestid, Typo, PieChart, colors, AppContent, EmptyStateV2, LoadingCircle } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { isFundCollected, isFundNotCollected } from '../../../../components/cellRender/CollectionStatusButtonRenderer';

interface PieCenterLabelProps {
  readonly collectedInventory: number;
  readonly allInventory: number;
  readonly testid: string;
}
function PieCenterLabel({ collectedInventory, allInventory, testid }: PieCenterLabelProps) {
  const createTestid = useTestid(testid);
  const { width, height, left, top } = useDrawingArea();
  const x = left + width / 2;
  const y = top + height / 2;

  return (
    <g textAnchor={'middle'} dominantBaseline={'middle'}>
      <text
        fill={colors.primaryColor}
        x={x - 2}
        y={y - 13}
        fontSize={'1.5rem'}
        textAnchor="end"
        data-testid={createTestid('collected')}
        data-value={collectedInventory}
      >
        {collectedInventory}
      </text>
      <text
        x={x + 20}
        y={y - 10}
        fontSize={'0.9rem'}
        textAnchor="center"
        data-testid={createTestid('all')}
        data-value={allInventory}
      >
        / {allInventory}
      </text>
      <text x={x} y={y + 10} fontSize={'0.8rem'}>
        Inventories collected
      </text>
    </g>
  );
}

type CollectStatusProps = {
  allInventories?: CollectApi.FundPositionToCollect[];
  inventoriesNotFound?: number;
  'data-testid': string;
};

export const CollectStatus: React.FC<CollectStatusProps> = ({
  allInventories,
  inventoriesNotFound,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);

  const collectedInventoriesNb = useMemo(() => {
    if (allInventories) {
      return allInventories.filter((fund) => isFundCollected(fund)).length;
    }
  }, [allInventories]);

  const inventoriesToCollectNb = useMemo(() => {
    if (allInventories) {
      return allInventories.filter((fund) => isFundNotCollected(fund)).length;
    }
  }, [allInventories]);

  const seriesData = [
    {
      data: [
        { value: inventoriesToCollectNb ?? 0, label: 'Inventories to collect' },
        { value: collectedInventoriesNb ?? 0, label: 'Inventories collected' },
        { value: inventoriesNotFound ?? 0, label: 'Inventories not found in Manaos' },
      ],
      innerRadius: 75,
      paddingAngle: collectedInventoriesNb === allInventories?.length ? 0 : 5,
    },
  ];

  if (!allInventories) {
    return (
      <AppContent data-testid={testid}>
        <Typo variant="h3">Collect status</Typo>
        <Stack alignItems="center" justifyContent="center" flex="1" width="100%">
          <LoadingCircle size="medium" data-testid={createTestid('emptyState-status')} />
        </Stack>
      </AppContent>
    );
  }

  return (
    <AppContent data-testid={testid}>
      <Typo variant="h3">Collect status</Typo>
      {allInventories.length == 0 ? (
        <Stack margin="auto">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There are no inventories to collect in this portfolio yet!"
            illustrationVariant="iconType"
            data-testid={createTestid('emptyState-status')}
          />
        </Stack>
      ) : (
        <PieChart
          series={seriesData}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: -20,
            },
          }}
          margin={{
            left: -170,
          }}
          sx={{
            '.MuiChartsLegend-root': {
              transform: 'translate(15px, 0)',
            },
            '.MuiChartsLegend-series': {
              text: {
                fontSize: '0.875rem !important',
                x: '-10px',
              },
              rect: {
                width: 16,
                height: 16,
                y: '-7px',
                x: '-7px',
                rx: '3px',
              },
            },
          }}
          data-testid={createTestid('piechart')}
        >
          <PieCenterLabel
            collectedInventory={collectedInventoriesNb ?? 0}
            allInventory={allInventories.length}
            testid={createTestid('piechart-label')}
          />
        </PieChart>
      )}
    </AppContent>
  );
};
