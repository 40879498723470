import { colors, makeStyles } from 'mns-components';

export const extractionsAutomatedStyles = makeStyles({
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    '& > :not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  scroll: {
    overflowX: 'clip',
    overflowY: 'auto',
  },
  detailsDialogTitle: {
    '&&': {
      color: colors.blueColor,
    },
  },
  detailsDialogSection: {
    marginBottom: '2rem',
  },
  detailsDialogTableTitle: {
    borderBottom: `solid .01rem ${colors.borderBlue}`,
    margin: '.6rem 0',
    paddingBottom: '.4rem',
  },
  filter: {
    margin: 0,
    '& > *': {
      margin: '0 !important',
    },
  },
  aggrid: {
    flexBasis: 0,
    minHeight: 'auto',
  },
});
