import { colors, makeStyles } from 'mns-components';

const widgetAppGenerateModalStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    margin: '0',
    padding: '0',
    alignItems: 'center',
    justifyContent: 'center ',
    flexFlow: 'row ',
  },
  cardContainerDetails: {
    background: '#fbfbfd',
    boxShadow: '0 0 2px 0px rgba(0,0,0,0.14)',
    margin: '0 0 2rem 0',
    padding: '1rem 1.25rem',
    boxSizing: 'border-box',
  },
  applicationIcon: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    fontSize: 0,
    height: '3.125rem',
    width: '3.125rem',
    borderRadius: '7px',
    boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.08)',
  },
  titleContainer: {
    margin: '0px 0 0px 1.25rem',
  },
  titleContainerWithIcon: {
    display: 'inline-flex',
    '& svg': {
      color: colors.infoColor,
      height: '1.5rem',
      width: '1.5rem',
      marginRight: '.875rem',
    },
  },
  cardLogoTitle: {
    margin: '0 .25rem 0 0',
    fontWeight: 500,
    fontSize: '1.25rem',
    textAlign: 'left',
  },
  cardLogoSubtitle: {
    margin: '0 .25rem 0 0',
    fontSize: '.75rem',
    color: colors.defaultFontColor,
    opacity: 0.6,
    textAlign: 'left',
  },
  cardTitles: {
    fontSize: '1rem',
    lineHeight: '1.125rem',
    color: '#11113C',
    fontWeight: 'bold',
    margin: '0 auto 1rem 0',
  },
  resumeDetails: {
    borderRadius: '4px',
    border: `none`,
    background: colors.whiteColor,
    boxShadow: '0 0 2px 0 rgba(95,122,241,0.3)',
    transition: 'all 0.4s ease',
    padding: '1rem 1.25rem .75rem 1.25rem',
    marginTop: '1rem',
  },
  inlineFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '.75rem',
    margin: '.25rem 0',
    alignItems: 'center',
    lineHeight: '1.875rem',
    '& p': {
      minWidth: '12.5rem',
      margin: 0,
    },
  },
  optionsText: {
    display: 'inline-flex',
    alignItems: 'baseline',
    width: '100%',
    padding: 0,
    margin: '0 0 .625rem 0',
    listStyleType: 'none',
    textAlign: 'left',
    color: '#11113C',
    fontSize: '.625rem',
    lineHeight: '1.125rem',
    '& h3': {
      margin: 0,
      minWidth: 'fit-content',
    },
    '& hr': {
      width: '100%',
      marginLeft: '1rem',
      position: 'relative',
      bottom: '0',
      color: 'black',
      opacity: '60%',
    },
  },
  footerDialog: {
    position: 'absolute',
    width: 'calc(100% - 3.125rem)',
    height: '5rem',
    bottom: 0,
    background: colors.whiteColor,
    '& hr': {
      backgroundColor: 'rgba(95,122,241, 0.4)',
      height: '1px',
      border: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '.5rem',
    '& > *:not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  textContainer: {
    padding: '5rem 2rem 2rem 2rem',
  },
  cardOffer: {
    height: 'calc(100%)',
    borderRadius: '4px',
    border: 'solid 1px rgba(95,122,241,0.1)',
    boxShadow: '0 0 2px 0 rgba(95,122,241,0.3)',
    transition: 'all 0.4s ease',
    marginBottom: '0 !important',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
    marginTop: '1.25rem !important',
    background: colors.whiteColor,
  },
  selectedOfferName: {
    fontSize: '1rem',
    lineHeight: '1.125rem',
    color: colors.defaultFontColor,
    margin: '0 0 5px 0',
  },
  cardOfferHeader: {
    margin: 0,
    textAlign: 'left',
    background: 'none',
    padding: '1.25rem 0 0 1.25rem',
    boxShadow: 'none',
    display: 'flex',
    '& svg': {
      color: colors.whiteColor,
      height: '2rem',
      width: '2rem',
      background: colors.infoColor,
      borderRadius: '4px',
      padding: '1rem',
    },
  },
  selectedOfferFeatures: {
    fontSize: '.625rem',
    lineHeight: '.625rem',
    color: 'rgba(0,0,0,0.4)',
    maxWidth: '21.25rem',
    margin: '0 !important',
  },
  cardHeaderDescriptionContainer: {
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& svg': {
      color: 'rgba(0,0,0,0.4)',
      background: 'none',
      height: '1rem',
      width: '1rem',
      border: 'none',
      padding: '0',
      verticalAlign: 'middle',
    },
  },
  remainingReports: {
    padding: '0 1rem 0 2rem',
    textAlign: 'right',
    maxWidth: '10rem',
  },
});

export default widgetAppGenerateModalStyles;
