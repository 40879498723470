import { Button, Icon, Link, useCallbackImmutable, useTestid } from 'mns-components';
import type { MarketplaceApi } from 'mns-sdk-collect';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { getApplicationConfig, useApplicationConfig } from '../../../common/getApplicationConfig';
import { DownloadSampleBulk } from '../../../components/subscriptions/ButtonList/DownloadSampleBulk';
import type { AppCode } from '../../../components/views/appDescriptions';
import { appCodes } from '../../../components/views/appDescriptions';
import { getViewsRoute } from '../../../components/views/routes';
import { useFetchAllPortfolios } from '../../../hooks/usePortfoliosRequest';

const appListAvailableForCoverageEstimation = appCodes.filter(
  (appCode) => getApplicationConfig(appCode).config.coverageCalculationAvailable,
) as string[];

type AppDescriptionButtonListProps = {
  applicationDetails: MarketplaceApi.Application;
  applicationOffers: MarketplaceApi.ApplicationOffer[];
  setIsOpenDialog?(isopen: boolean): void;
  isAdmin: boolean | undefined;
  isPendingStatus: boolean | undefined;
  haveCurrentOffer: boolean | undefined;
  haveFreeTrial: boolean | undefined;
  isLoadingOffer?: boolean;
  providerName: string;
  docFactsheet?: string;
  docAnalytics?: string;
  docMethodology?: string;
  'data-testid': string;
};

export const AppDescriptionButtonList: React.FC<AppDescriptionButtonListProps> = ({
  applicationDetails,
  isAdmin,
  applicationOffers,
  isPendingStatus,
  providerName,
  isLoadingOffer,
  docFactsheet,
  docAnalytics,
  docMethodology,
  haveCurrentOffer,
  haveFreeTrial,
  setIsOpenDialog,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { data: ptfList } = useFetchAllPortfolios();
  const {
    config: { subscriptionRequestType },
  } = useApplicationConfig(applicationDetails.codeApplication as AppCode);

  const coverageAvailable = useMemo(() => {
    return appListAvailableForCoverageEstimation.includes(applicationDetails.codeApplication);
  }, [applicationDetails]);

  const openDialog = useCallbackImmutable(() => setIsOpenDialog?.(true));

  return (
    <>
      {!isLoadingOffer && isAdmin && !isPendingStatus && applicationOffers.length > 0 && !haveCurrentOffer && (
        <div>
          {subscriptionRequestType && (
            <Link
              to={generatePath(getViewsRoute('app-subscription').path, { appCode: applicationDetails.codeApplication })}
              data-testid={createTestid('link-subscribe')}
            >
              <Button
                color="secondary"
                fullwidth
                style={{ margin: '1.25rem 0 0 0' }}
                data-testid={createTestid('button-subscribe')}
              >
                {subscriptionRequestType === 'activate' ? 'Get a quote in 3 clicks' : 'Order now'}
              </Button>
            </Link>
          )}

          {subscriptionRequestType && !isLoadingOffer && haveFreeTrial && (
            <Link
              to={generatePath(getViewsRoute('app-subscription').path, { appCode: applicationDetails.codeApplication })}
              data-testid={createTestid('link-freeTrial')}
            >
              <Button
                color="whiteSecondary"
                data-testid={createTestid('button-freeTrial')}
                fullwidth
                style={{ margin: '1.25rem 0 0 0' }}
              >
                Start your free trial
              </Button>
            </Link>
          )}
        </div>
      )}
      {!isLoadingOffer && !applicationOffers.length && (
        <div>
          <p>No offer for this application</p>
          <Link
            extern
            to="https://manaos.com/book-a-demo/"
            target="_blank"
            rel="noopener noreferrer"
            data-testid={createTestid('link-contactUs')}
          >
            <Button
              size="medium"
              color="primary"
              data-testid={createTestid('button-contactUs')}
              outlined
              startIcon={<Icon.Invite data-testid={createTestid('icon-invite')} />}
              style={{ marginLeft: 'auto' }}
            >
              Contact us
            </Button>
          </Link>
        </div>
      )}
      {subscriptionRequestType && !isAdmin && !isLoadingOffer && applicationOffers.length > 0 && (
        <Link
          to={generatePath(getViewsRoute('app-subscription').path, { appCode: applicationDetails.codeApplication })}
          data-testid={createTestid('link-viewOffers')}
        >
          <Button color="secondary" data-testid={createTestid('button-viewOffers')} fullwidth>
            View offers
          </Button>
        </Link>
      )}
      {subscriptionRequestType && isPendingStatus && !isLoadingOffer && (
        <Link
          to={generatePath(getViewsRoute('app-subscription').path, { appCode: applicationDetails.codeApplication })}
          data-testid={createTestid('link-pending')}
        >
          <Button
            color="secondary"
            data-testid={createTestid('button-pending')}
            fullwidth
            style={{ margin: '1.25rem 0 0 0' }}
          >
            Pending Subscription
          </Button>
        </Link>
      )}
      {subscriptionRequestType && haveCurrentOffer && isAdmin && !isLoadingOffer && (
        <Link
          to={generatePath(getViewsRoute('app-subscription').path, { appCode: applicationDetails.codeApplication })}
          data-testid={createTestid('link-updatePlan')}
        >
          <Button
            color="secondary"
            data-testid={createTestid('button-updatePlan')}
            fullwidth
            style={{ margin: '1.25rem 0 0 0' }}
          >
            Update plan
          </Button>
        </Link>
      )}
      {!isLoadingOffer && (
        <DownloadSampleBulk
          key={providerName}
          providerName={providerName}
          docFactsheet={docFactsheet}
          docAnalytics={docAnalytics}
          docMethodology={docMethodology}
          data-testid={createTestid('download')}
        />
      )}

      {setIsOpenDialog && ptfList?.[0] && coverageAvailable && !isLoadingOffer && (
        <div>
          <Button
            color="whiteSecondary"
            fullwidth
            disabled={ptfList?.length === 0}
            style={{ margin: '1.25rem 0 0 0' }}
            data-testid={createTestid('button-getCoverage')}
            onClick={openDialog}
          >
            Get a coverage estimation
          </Button>
        </div>
      )}
      {isLoadingOffer && null}
    </>
  );
};
