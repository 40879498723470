import { Grid } from '@mui/material';
import { useTestid, CardLogoTitle } from 'mns-components';
import { useAppBackgroundStyles } from '../../../common/getImageContent';

type UploadsKpiProps = { 'data-testid': string };

export const UploadsKpi: React.FC<UploadsKpiProps> = ({ 'data-testid': testid }: UploadsKpiProps) => {
  const createTestid = useTestid(testid);
  const appBackgroundClassName = useAppBackgroundStyles()['manaos-coverageestimation'];

  return (
    <Grid container data-testid={testid}>
      <Grid item style={{ height: '3.5rem' }}>
        <CardLogoTitle
          company="Manaos"
          appName="Collection"
          appBackgroundClassName={appBackgroundClassName}
          variant="filled"
          data-testid={createTestid('card')}
        />
      </Grid>
    </Grid>
  );
};
