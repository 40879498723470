import { Stack } from '@mui/material';
import { Backdrop, EmptyStateV2, FormStepper, useCallbackImmutable, useTestid } from 'mns-components';
import type { DataDeliveryApi } from 'mns-sdk-collect';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { getRoute as getEetRoute } from '../../../../applications/eetApp/routes';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import { useAppBackgroundClassName } from '../../../../common/getImageContent';
import { dataDeliveryApi } from '../../../../store/apis';
import type { AppCode } from '../../../views/appDescriptions';
import { useFetchEetFiles } from '../../hooks';
import type { CreateEmailDeliveryEetSteps } from '../types';
import { buildSelectRecipientsEetStep } from './SelectRecipientsEetStep';
import { buildSelectScopeEetStep } from './SelectScopeEetStep';

const useSteps = () => useMemo(() => [buildSelectScopeEetStep(), buildSelectRecipientsEetStep()], []);

type CreateEmailStepperEetProps = {
  onClose(): void;
  appCode: AppCode;
  'data-testid': string;
};

export const CreateEmailStepperEet: React.FC<CreateEmailStepperEetProps> = ({
  onClose,
  appCode,
  'data-testid': testid,
}) => {
  const {
    company: { name: companyName },
    content: { title },
  } = useApplicationConfig(appCode);

  const createTestid = useTestid(testid);

  const icon = useAppBackgroundClassName(appCode);

  const { data: eetFiles, isLoading: isLoadingEetFiles, error: errorEet } = useFetchEetFiles();

  const handleSubmit = useCallbackImmutable(
    async ([{ title: deliveryTitle, eetIds, generalReferenceDate }, { emails }]: CreateEmailDeliveryEetSteps) => {
      const scopes = eetIds.map((latestVersionId): DataDeliveryApi.CreateDataDeliveryRequestScope => {
        const found = eetFiles?.find((eet) => eet.id === latestVersionId);
        if (found) {
          return {
            dataFormat: found.dataFormat,
            fileName: found.fileName + '.csv',
            identification: found.id, //processed id ONLY for uploaded
            name: found.fileName,
            productionDate: generalReferenceDate,
            type: 'EET_GENERATED', // EET_UPLOADED for productRangeDissemination's app
          };
        }
        throw new Error('Unexpected error');
      });

      await dataDeliveryApi.createDelivery({
        title: deliveryTitle,
        emails,
        scopes,
      });
      onClose();
    },
  );

  const steps = useSteps();

  if (!eetFiles?.length) {
    if (isLoadingEetFiles) {
      return <Backdrop data-testid={createTestid('backdrop')} />;
    }

    if (errorEet) {
      return (
        <Stack height="100%" justifyContent="center">
          <EmptyStateV2
            variant="error"
            useCase="dataErrors"
            title="Oops, something went wrong!"
            subTitle="Please refresh your page or contact our support team."
            illustrationVariant="iconType"
            data-testid={createTestid('error')}
          />
        </Stack>
      );
    }

    return (
      <Stack height="100%" justifyContent="center">
        <EmptyStateV2
          variant="info"
          useCase="actions"
          title="Get started with your EET!"
          subTitle="Start creating your EET to analyse your portfolio(s)."
          buttonText="Create EET"
          buttonVariant="primary"
          buttonHref={generatePath(getEetRoute('generate-eet').link)}
          illustrationVariant="iconType"
          data-testid={createTestid('empty-eet')}
        />
      </Stack>
    );
  }

  return (
    <FormStepper<CreateEmailDeliveryEetSteps>
      header={{ appTitle: title, providerName: companyName, appBackgroundClassName: icon }}
      displayCounter
      displayNavigation
      displayErrors
      steps={steps}
      onCancel={onClose}
      onSubmit={handleSubmit}
      buttonBackground="white"
      data-testid={testid}
    />
  );
};
