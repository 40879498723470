import { Bulk, filterTruthy } from 'mns-components';

type BulkDownloadButtonProps = {
  type: string;
  onDownload?(): void;
  onGenerate?(): void;
  'data-testid': string;
};

export const BulkDownloadButton: React.FC<BulkDownloadButtonProps> = ({
  type,
  onDownload,
  onGenerate,
  'data-testid': testid,
}) => (
  <Bulk
    items={filterTruthy([
      onDownload && { key: 'download', label: `Download ${type} file`, onClick: onDownload },
      onGenerate && { key: 'generate', label: `Generate EET from this file`, onClick: onGenerate },
    ])}
    data-testid={testid}
  />
);
