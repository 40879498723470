import { IconButton, Stack, Tooltip } from '@mui/material';
import type { AggridTableColumn } from 'mns-components';
import {
  StatusTag,
  AggridTable,
  Bulk,
  Icon,
  convertToDateTimeLocal,
  convertToDateTimeUTCWithTZ,
  makeActionColDef,
  useAutoSize,
  useButtonPrefetch,
  useCallbackImmutable,
  useDefaultColDef,
  useMemoBase,
  useTestid,
  useRowSelection,
  HeaderComponentFramework,
  Switch,
} from 'mns-components';
import type { EetApi } from 'mns-sdk-collect';
import type { AppCode } from '../../../components/views/appDescriptions';
import { usePrefetchEet } from '../hooks';

const getActionColDef = makeActionColDef<EetApi.EetFile<AppCode>>();

const isEetGenerating = (
  status: EetApi.EetFile<AppCode>['status'],
): status is
  | 'SELECTED_DATE'
  | 'INPUT_FILE_LINKED'
  | 'SELECTED_PORTFOLIOS'
  | 'SELECTED_ESG_APPS'
  | 'REQUESTED_ESG_APPS' =>
  ['SELECTED_DATE', 'INPUT_FILE_LINKED', 'SELECTED_PORTFOLIOS', 'SELECTED_ESG_APPS', 'REQUESTED_ESG_APPS'].includes(
    status,
  );

type EetStatusRendererProps = {
  status: EetApi.EetFile<AppCode>['status'];
  'data-testid': string;
};

const EetStatusRenderer: React.FC<EetStatusRendererProps> = ({ status, 'data-testid': testid }) => {
  if (status === 'FAILED') {
    return <StatusTag variant="error" label="Failed" data-testid={testid} />;
  }

  if (status === 'COMPLETED') {
    return <StatusTag variant="success" label="Generated" data-testid={testid} />;
  }

  if (status === 'PROCESSING') {
    return <StatusTag variant="processing" label="Processing" data-testid={testid} />;
  }

  if (isEetGenerating(status)) {
    return <StatusTag variant="warning" label="Pending" data-testid={testid} />;
  }

  return null;
};

const DisseminateHeaderRender: React.FC<{
  getLabelNode: (label: string) => React.ReactElement;
  'data-testid': string;
}> = ({ getLabelNode, 'data-testid': testid }) => (
  <Stack direction="row" gap=".5rem" alignItems="center">
    {getLabelNode('Disseminate EET')}
    <Tooltip
      placement="bottom"
      title="By activating the switch, you publish your EET on Manaos plateform and make it accessible for all Manaos users."
    >
      <Icon.Info size="small" color="secondary" data-testid={`${testid}-icon`} />
    </Tooltip>
  </Stack>
);

type UseColumnsProps = {
  onDownload(eetId: string, format: 'csv' | 'xlsx'): void;
  onResume(eetId: string): void;
  onVisible?(eetData: EetApi.EetFile<AppCode>): Promise<void>;
  selectCellProps: AggridTableColumn<EetApi.EetFile<AppCode>>;
  'data-testid': string;
};

const useColumns = ({ selectCellProps, onDownload, onResume, onVisible, 'data-testid': testid }: UseColumnsProps) =>
  useMemoBase({ onDownload, onResume, onVisible, testid }, (base): AggridTableColumn<EetApi.EetFile<AppCode>>[] => [
    selectCellProps,
    {
      headerName: 'File name',
      field: 'fileName',
      minWidth: 180,
    },
    {
      headerName: 'Created at',
      field: 'createdAt',
      minWidth: 190,
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
      sort: 'desc',
    },
    {
      headerName: 'Reference date',
      field: 'generalReferenceDate',
      minWidth: 85,
    },
    {
      headerName: 'Generated by',
      field: 'createdBy',
      minWidth: 40,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 40,
      cellRendererFramework: ({ value, data: { id } }) => (
        <EetStatusRenderer status={value} data-testid={`${id}-status`} />
      ),
    },
    {
      headerName: 'Scope',
      field: 'scope',
      minWidth: 40,
      cellRendererFramework: ({ value }) =>
        Number.isNaN(parseInt(value)) ? 'Not yet defined' : `${value} share classes`,
    },
    {
      field: 'id',
      headerName: 'Disseminate EET',
      minWidth: 60,
      headerComponentFramework: () => (
        <HeaderComponentFramework render={DisseminateHeaderRender} data-testid={`${base.testid}-header-disseminate`} />
      ),
      cellRendererFramework: function DisseminateCell({ data }) {
        return (
          <Switch
            uncontrolled
            isIconVisible
            value={!!data.documentId}
            onChange={useCallbackImmutable(() => onVisible?.(data))}
            disabled={data.status !== 'COMPLETED'}
            data-testid={`${base.testid}-${data.id}-disseminate`}
          />
        );
      },
    },
    getActionColDef('status', 90, function ActionCell({ data }) {
      const createTestid = useTestid(`${base.testid}-${data.id}`);
      const handleResume = useCallbackImmutable(() => base.onResume(data.id));
      const handleDownloadCsv = useCallbackImmutable(() => base.onDownload(data.id, 'csv'));
      const handleDownloadXlsx = useCallbackImmutable(() => base.onDownload(data.id, 'xlsx'));
      const prefetchProps = useButtonPrefetch(usePrefetchEet(data.id));

      return (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%">
          <Tooltip title="Resume EET generation">
            <IconButton
              color="secondary"
              size="small"
              onClick={handleResume}
              disabled={!isEetGenerating(data.status)}
              {...prefetchProps}
              data-testid={createTestid('button-resume')}
            >
              <Icon.Resume data-testid={createTestid('icon-resume')} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download options">
            <Bulk
              iconName="download"
              size="small"
              items={[
                {
                  key: 'download-csv',
                  label: 'Download (.csv)',
                  onClick: handleDownloadCsv,
                },
                {
                  key: 'download-xlsx',
                  label: 'Download (.xlsx)',
                  onClick: handleDownloadXlsx,
                },
              ]}
              disabled={data.status !== 'COMPLETED'}
              data-testid={`${base.testid}-${data.id}-bulk`}
            />
          </Tooltip>
        </Stack>
      );
    }),
  ]);

export type EetFilesListProps = {
  rows: EetApi.EetFile<AppCode>[];
  search?: string;
  onDownload(eetId: string, format: 'csv' | 'xlsx'): void;
  onResume(eetId: string): void;
  onVisible?(eetData: EetApi.EetFile<AppCode>): Promise<void>;
  onRowsSelect(rows: EetApi.EetFile<AppCode>[]): void;
  'data-testid': string;
};

const exportColumns = { columnKeys: ['fileName', 'createdAt', 'generalReferenceDate', 'createdBy', 'status', 'scope'] };
const getRowNodeId = (row: EetApi.EetFile<AppCode>) => row.id;

export const EetFilesList: React.FC<EetFilesListProps> = ({
  rows,
  search,
  onDownload,
  onResume,
  onVisible,
  onRowsSelect,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const [aggridProps, selectCellProps] = useRowSelection<EetApi.EetFile<AppCode>>(({ api }) => {
    onRowsSelect(api.getSelectedRows());
  }, true);
  return (
    <AggridTable
      {...aggridProps}
      rowData={rows}
      getRowNodeId={getRowNodeId}
      columnDefs={useColumns({
        selectCellProps,
        onDownload,
        onResume,
        onVisible: onVisible,
        'data-testid': testid,
      })}
      defaultColDef={useDefaultColDef<EetApi.EetFile<AppCode>>()}
      quickFilterText={search}
      defaultCsvExportParams={exportColumns}
      defaultExcelExportParams={exportColumns}
      onGridReady={useAutoSize('fit')}
      pagination
      paginationAutoPageSize
      data-testid={createTestid('table')}
    />
  );
};
