import { Stack } from '@mui/material';
import { Button, Icon, SearchField, SelectFilter, useTestid } from 'mns-components';
import React, { useState } from 'react';
import { Layout } from '../common/Layout';
import { appLayoutStyles as useEsgStyles } from '../styles/appLayoutStyles';
import { ESGReportsGrid } from './components/ESGReportsGrid';

export type ESGReportsType = {
  'data-testid': string;
};

const fakeItems = [
  {
    value: 'all',
    label: 'All',
    count: 1,
  },
  {
    value: 'another',
    label: 'Another',
    count: 1,
  },
];

export const ESGReports: React.FC<ESGReportsType> = ({ 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const classes = useEsgStyles();

  // The backend doesn't exist yet, so this is just a placeholder
  const [search, setSearch] = useState('');
  const [reportType, setReportType] = useState(fakeItems[0].value);

  return (
    <Layout padding="1.5rem 2rem" data-testid={testid}>
      <Stack className={classes.header}>
        <Stack className={classes.headerAction}>
          <SearchField value={search} onChange={setSearch} data-testid={createTestid('search')} />
          <SelectFilter
            label="Report type"
            dropdownItems={fakeItems}
            countAll={fakeItems.length}
            onClick={setReportType}
            activeItem={reportType}
            data-testid={createTestid('report-type')}
          />
        </Stack>

        <Button
          color="secondary"
          startIcon={<Icon name={'esgsolutions'} data-testid={createTestid('button-icon')} />}
          data-testid={createTestid('generate-report')}
        >
          Generate report
        </Button>
      </Stack>

      <ESGReportsGrid data-testid={createTestid('reports-grid')} />
    </Layout>
  );
};
