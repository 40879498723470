import { Stack, Tooltip } from '@mui/material';
import type { AggridTableColumn, AggridTableProps, BulkItemProps } from 'mns-components';
import {
  AggridTable,
  Bulk,
  convertToDateTimeLocal,
  filterTruthy,
  useDefaultColDef,
  makeActionColDef,
  useAutoSize,
  useImmutable,
  useTestid,
  useCallbackImmutable,
  convertToDateTimeUTCWithTZ,
  Switch,
  Icon,
} from 'mns-components';
import type { DataExtractorApi } from 'mns-sdk-collect';
import { automatedExtractionsSettingsStyles as useStyles } from './styles/automatedExtractionsSettingsStyles';

const getActionColDef = makeActionColDef<DataExtractorApi.Extraction.Extraction>();

export type BulkClickHandler = (row: DataExtractorApi.Extraction.Extraction) => void;

const bulkOptions = (
  row: DataExtractorApi.Extraction.Extraction,
  onDisplayDetails?: AutomatedExtractionsSettingsListProps['onDisplayDetails'],
  onDeleteExtraction?: AutomatedExtractionsSettingsListProps['onDeleteExtraction'],
  onDownloadExtraction?: AutomatedExtractionsSettingsListProps['onDownloadExtraction'],
) =>
  filterTruthy<BulkItemProps>([
    onDisplayDetails && {
      key: 'details',
      label: 'View Scope Details',
      onClick: () => onDisplayDetails(row),
    },
    onDeleteExtraction && {
      key: 'delete',
      label: 'Delete Extraction',
      onClick: () => onDeleteExtraction(row),
    },
    onDownloadExtraction && {
      key: 'download',
      label: 'Download Extraction',
      disabled: row.zipStatus !== 'COMPLETED',
      disabledTooltip: 'Zip file is currently processing. You will be able to download it in a few seconds',
      onClick: () => onDownloadExtraction(row),
    },
  ]);

const useColumns = (
  testid: string,
  onToggleStatus?: AutomatedExtractionsSettingsListProps['onToggleStatus'],
  onDisplayDetails?: AutomatedExtractionsSettingsListProps['onDisplayDetails'],
  onDeleteExtraction?: AutomatedExtractionsSettingsListProps['onDeleteExtraction'],
  onDownloadExtraction?: AutomatedExtractionsSettingsListProps['onDownloadExtraction'],
): AggridTableColumn<DataExtractorApi.Extraction.Extraction>[] => {
  const classes = useStyles();
  const createTestid = useTestid(testid);

  return [
    {
      field: 'extractionName',
      headerName: 'Extraction name',
    },
    {
      field: 'templateName',
      headerName: 'Template used',
    },
    {
      field: 'createdAt',
      headerName: 'Creation date',
      sort: 'desc',
      sortable: true,
      valueGetter: ({ data }) => convertToDateTimeUTCWithTZ(data.createdAt),
      cellRendererFramework: ({ data }) => convertToDateTimeLocal(data.createdAt),
    },
    {
      field: 'extractionScope',
      headerName: 'Portfolio scope',
      valueGetter: ({ data: { extractionScope: value } }) => value.map(({ name }) => name).join('; '),
      cellRendererFramework: ({ data }) => (
        <span>
          <Tooltip
            title={data.extractionScope.map(({ name, externalIdValue }) => (
              <p key={externalIdValue}>{name}</p>
            ))}
            arrow
            placement="top"
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <Icon.Info
              size="medium"
              color="secondary"
              data-testid={createTestid('icon-info')}
              className={classes.icon}
            />
          </Tooltip>
          {data.extractionScope.length}
        </span>
      ),
    },
    {
      field: 'extractionFeed',
      headerName: 'Extraction feed',
    },
    getActionColDef(
      'extractionStatus',
      120,
      function ActionCol({ data }) {
        return (
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <Stack position="relative" width="2rem">
              <Switch
                uncontrolled
                isIconVisible
                value={data.extractionStatus === 'ACTIVATED'}
                onChange={useCallbackImmutable(() => onToggleStatus?.(data))}
                data-testid={createTestid('switch-activation')}
              />
            </Stack>
            <Bulk
              items={bulkOptions(data, onDisplayDetails, onDeleteExtraction, onDownloadExtraction)}
              data-testid={createTestid('bulk')}
            />
          </Stack>
        );
      },
      { headerName: 'Activation' },
    ),
  ];
};

type AutomatedExtractionsSettingsListProps = {
  onToggleStatus?: BulkClickHandler;
  onDisplayDetails?: BulkClickHandler;
  onDeleteExtraction?: BulkClickHandler;
  onDownloadExtraction?: BulkClickHandler;
  'data-testid'?: string;
} & Omit<AggridTableProps<DataExtractorApi.Extraction.Extraction>, 'defaultColDef' | 'columnDefs'>;

export const AutomatedExtractionsSettingsList: React.FC<AutomatedExtractionsSettingsListProps> = ({
  onToggleStatus,
  onDisplayDetails,
  onDeleteExtraction,
  onDownloadExtraction,
  'data-testid': testid,
  ...props
}: AutomatedExtractionsSettingsListProps) => (
  <AggridTable
    {...props}
    columnDefs={useColumns(testid, onToggleStatus, onDisplayDetails, onDeleteExtraction, onDownloadExtraction)}
    getRowNodeId={useImmutable(() => (row: DataExtractorApi.Extraction.Extraction) => row.id)}
    data-testid={testid}
    defaultColDef={useDefaultColDef<DataExtractorApi.Extraction.Extraction>()}
    onGridReady={useAutoSize('fit')}
    pagination
    paginationAutoPageSize
  />
);
