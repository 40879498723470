import { Stack } from '@mui/material';
import type { BreadcrumbPart, IconProps } from 'mns-components';
import { Breadcrumbs, isFunction, MainHeader, useTestid } from 'mns-components';
import React, { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { isCognitoUser, useAuthUser, useUserSession } from '../../hooks/useAuth';
import { useOrganisation } from '../../hooks/useGlobalState';
import { globalBreadcrumbParts } from '../views/routes';
import { AppSideNavBar } from './AppSideNavBar';
import { CustomHeaderButtons } from './CustomHeaderButtons';
import useStyles from './styles/handlerStyles';

interface Props {
  children: React.ReactNode;
  'data-testid': string;
}

export type BreadcrumbGenerator = {
  label?: string;
  labelGenerator?: (params: Record<string, string>) => string;
  previousBreadcrumbs?: BreadcrumbPart[];
  key: string;
  link?: string;
  icon?: IconProps['name'];
};

const buildBreadcrumbs = (
  organisationName: string,
  username: string,
  pathname: string,
  routes: BreadcrumbGenerator[],
) => {
  const organisationLabel = organisationName.length > 15 ? `${organisationName.slice(0, 15)}...` : organisationName;
  const usernameLabel = username.length > 12 ? `${username.slice(0, 12)}...` : username;

  const breadcrumbs = [
    {
      label: organisationLabel + ' - ' + usernameLabel,
      key: 'organisation',
      icon: 'profile',
    },
  ] as BreadcrumbPart[];

  for (const route of routes) {
    if (!route.link) continue;

    const pathMatching = matchPath(pathname, {
      path: route.link,
      exact: false,
    });

    if (pathname.startsWith(route.link) || pathMatching) {
      const params = pathMatching?.params ?? {};
      const label = isFunction(route.labelGenerator) ? route.labelGenerator(params) : route.label;

      if (label) {
        if (route.previousBreadcrumbs) {
          breadcrumbs.push(...route.previousBreadcrumbs);
        }

        breadcrumbs.push({
          label: label,
          key: route.key,
          icon: route.icon,
          link: route.link,
        });
      }
    }
  }

  breadcrumbs[breadcrumbs.length - 1].link = undefined;

  return breadcrumbs;
};

const MainLayout: React.FC<Props> = ({ children, 'data-testid': testid }: Props) => {
  const createTestid = useTestid(testid);
  const { pathname } = useLocation();
  const classes = useStyles();
  const [authUser] = useAuthUser();
  const { data: organisation } = useOrganisation();

  const breadcrumbs = useMemo(() => {
    const routes = [...globalBreadcrumbParts];
    routes.sort((a, b) => (a.link ?? '').localeCompare(b.link ?? ''));

    const username = isCognitoUser(authUser) ? authUser.getUsername() : '';

    return buildBreadcrumbs(organisation?.name ?? '', username, pathname, routes);
  }, [organisation?.name, pathname, authUser]);

  return (
    <Stack className={classes.app} data-testid={testid}>
      <AppSideNavBar organisationName={organisation?.name} data-testid={createTestid('sideNavBar')} />
      <Stack className={classes.appWrapper}>
        <MainHeader>
          <Stack className={classes.headUserFlag}>
            <Breadcrumbs parts={breadcrumbs} data-testid={'breadcrumbs'} />
          </Stack>
          <CustomHeaderButtons data-testid={createTestid('headerButtons')} />
        </MainHeader>
        <main className={classes.content}>{children}</main>
      </Stack>
    </Stack>
  );
};

const LayoutHandler: React.FC<Props> = ({ children, 'data-testid': testid }: Props) => {
  const session = useUserSession();

  if (!session) return <main>{children}</main>;

  return <MainLayout data-testid={testid}>{children}</MainLayout>;
};

export default LayoutHandler;
