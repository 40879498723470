import { colors, makeStyles } from 'mns-components';

export const authStyles = makeStyles({
  authForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-start',
    alignContent: 'flex-start',
    '& > *': {
      '& .MuiSvgIcon-root': {
        height: '2.5rem',
        marginLeft: '1rem',
      },
      '& .MuiFormHelperText-root': {
        color: colors.dangerColor,
        background: 'rgba(127,127,127,.1)',
        padding: '.2rem .5rem',
        borderRadius: '.2rem',
      },
    },
    '& button': {
      margin: '.5rem',
    },
  },
  link: {
    textAlign: 'left',
    margin: '.5rem',
    fontSize: '.75rem',
    color: colors.warningGreyColor,
    transition: 'all 0.4s ease 0s',
    '&:hover': {
      color: colors.primaryPinkColor,
    },
  },
  title: {
    opacity: 0.87,
    fontSize: '1.5rem',
    padding: '1rem',
  },
  info: {
    opacity: 0.6,
    padding: '0 1rem',
  },
});
