import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Stack } from '@mui/material';
import { Backdrop, Dialog, EmptyStateV2, SearchField, useCallbackImmutable, useTestid } from 'mns-components';
import type { CollectApi } from 'mns-sdk-collect';
import React, { useEffect, useState } from 'react';
import { useRecipentAccessRequests } from '../../../hooks/useGlobalState';
import { DetailsPermissionsDialog } from '../../collectionApp/permissions/components/DetailsPermissionsDialog';
import { disseminationCommonStyles as useStyles } from '../disseminationCommonStyles';
import { KpiContainer } from '../hooks';
import { AccessesKpis } from './components/AccessesKpis';
import { ManagePermissions } from './components/ManagePermissions';
import { ReceiverPermissionList } from './ReceiverPermissionList';

type ReceiverPermissionsProps = { 'data-testid': string };

export const ReceiverPermissions: React.FC<ReceiverPermissionsProps> = ({
  'data-testid': testid,
}: ReceiverPermissionsProps) => {
  const createTestid = useTestid(testid);
  const { trackPageView } = useMatomo();
  const [selectedItems, setSelectedItems] = useState<CollectApi.AccessDemand[]>([]);
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
  const [isPermissionsDetailsModalOpen, setIsPermissionsDetailsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { data: authorizationList, refetch: loadRecipientAccessRequests } = useRecipentAccessRequests();

  const classes = useStyles();

  const closeDetailsPermissionsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(false);
  });

  const openPermissionsDetailsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(true);
    setIsManagePermissionsModalOpen(false);
  });

  const openManagePermissionsModal = useCallbackImmutable((): void => {
    setIsPermissionsDetailsModalOpen(false);
    setIsManagePermissionsModalOpen(true);
  });

  useEffect(() => {
    trackPageView({
      documentTitle: `Dissemination App - Receiver Permissions`, // optional
    });
  }, [trackPageView]);

  const closeManagePermissionsModal = useCallbackImmutable((): void => {
    loadRecipientAccessRequests();
    setSelectedItems([]);
  });

  if (authorizationList === undefined) {
    return <Backdrop data-testid={createTestid('backdrop')} />;
  }

  if (authorizationList.length === 0) {
    return (
      <div className={classes.gridContainer}>
        <Stack height="100%" justifyContent="center" alignItems="center">
          <EmptyStateV2
            variant="info"
            useCase="noActions"
            title="There are no access requests received yet!"
            data-testid={createTestid('error-empty')}
          />
        </Stack>
      </div>
    );
  }

  return (
    <>
      <KpiContainer>
        <AccessesKpis data-testid={createTestid('kpi')} />
      </KpiContainer>
      <div className={classes.gridContainer}>
        <div className={classes.searchContainer}>
          <SearchField value={searchValue} onChange={setSearchValue} data-testid={createTestid('search')} />
        </div>
        <ReceiverPermissionList
          searchValue={searchValue}
          rowData={authorizationList}
          onSelectItems={setSelectedItems}
          openManagePermissionsModal={openManagePermissionsModal}
          openPermissionsDetailsModal={openPermissionsDetailsModal}
          data-testid={createTestid('list')}
        />
      </div>
      <ManagePermissions
        selectedItems={selectedItems}
        isManagePermissionsOpen={isManagePermissionsModalOpen}
        onClose={closeManagePermissionsModal}
        data-testid={createTestid('permissions')}
      />
      <Dialog
        dialogTitle="Access request details"
        open={isPermissionsDetailsModalOpen}
        onClose={closeDetailsPermissionsModal}
        data-testid={createTestid('details-permissions')}
      >
        <DetailsPermissionsDialog
          selectedItems={selectedItems}
          closeModal={closeDetailsPermissionsModal}
          data-testid={createTestid('details-permissions-content')}
          viewOrigin="Dissemination"
        />
      </Dialog>
    </>
  );
};
