import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import type { StepProps } from 'mns-components';
import { Button, Icon, Typography, colors, useCallbackImmutable, useImmutable, useTestid } from 'mns-components';
import { useEffect, useState } from 'react';
import { useTemplateDetails } from '../../../../api/templateData';
import type { CreateTemplateSteps } from '../types';

export const FinalizeSettingsTab: React.FC<StepProps<CreateTemplateSteps, 3>> = ({
  stepValues: [{ templateName, templateId }],
  setCurrentStep,
  setStepValues,
  'data-testid': testid,
}) => {
  const createTestid = useTestid(testid);
  const { data: templateDetails } = useTemplateDetails(templateId);

  const [accordionPanel, setAccordionPanel] = useState<'template' | 'template' | null>(null);
  const useAccordionChange = (newAccordionPanel: 'template' | 'template') =>
    useImmutable(() => (_: unknown, expanded: boolean) => setAccordionPanel(expanded ? newAccordionPanel : null));

  const useOnClickModify = (goPanel: 'template') =>
    useCallbackImmutable((ev: React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      setStepValues(([setUp, ...steps]) => [
        {
          ...setUp,
          accordionPanel: goPanel,
        },
        ...steps,
      ]);
      setCurrentStep(0);
    });

  useEffect(() => setAccordionPanel('template'), []);

  return (
    <Accordion expanded={accordionPanel === 'template'} onChange={useAccordionChange('template')} data-testid={testid}>
      <AccordionSummary expandIcon={<Icon name="expandless" data-testid={createTestid('icon-accordion')} />}>
        <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center" marginRight="1rem">
          <Typography variant="body1" fontWeight={500}>
            Template settings
          </Typography>
          <Button
            text
            color="primary"
            startIcon={<Icon.Edit data-testid={createTestid('icon-modify')} />}
            onClick={useOnClickModify('template')}
            data-testid={createTestid('button-modify')}
          >
            Modify
          </Button>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack margin=".5rem 0">
          <Typography variant="body2" component="p" color={colors.disabledColor}>
            New template name
          </Typography>
          <Typography variant="body2" component="p" fontWeight={500}>
            {templateName}
          </Typography>
        </Stack>
        {templateDetails ? (
          <Stack margin=".5rem 0">
            <Typography variant="body2" component="p" color={colors.disabledColor}>
              Template Used
            </Typography>
            <Typography variant="body2" component="p" fontWeight={500}>
              {templateDetails.name}
            </Typography>
          </Stack>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
