import { Stack } from '@mui/material';
import { useTestid, Typo, Divider } from 'mns-components';
import type { CoverageApi } from 'mns-sdk-collect';
import React from 'react';
import { useApplicationConfig } from '../../../../common/getApplicationConfig';
import type { AppCode } from '../../../../components/views/appDescriptions';
import { shouldDisplayCoverageDetails } from '../../../coverageApp/components/CoverageCollapsible';
import CoverageRenderer from '../../../coverageApp/components/CoverageRenderer';
import { coverageDetailsMapping } from '../../../coverageApp/const';

export type CardCoverageProps = {
  appCode: AppCode;
  coverage: CoverageApi.Coverage;
  'data-testid': string;
};

export const CardCoverageDetails: React.FC<CardCoverageProps> = ({ appCode, coverage, 'data-testid': testid }) => {
  const createTestid = useTestid(testid);
  const providerCode = useApplicationConfig(appCode)?.company?.providerName;
  const formattedCoverageDetails = coverage?.coverageDetails
    ?.filter((coverageDetail) => coverageDetail.name !== 'Strict Matching')
    ?.map((coverageDetail) => {
      const mappedLabel = coverageDetailsMapping[coverageDetail.name] || coverageDetail.name;
      return { ...coverageDetail, label: mappedLabel };
    });

  if (!shouldDisplayCoverageDetails(coverage.coverageDetails, coverage.matchingLevel, coverage.errorMessage)) {
    return null;
  }

  return (
    <>
      <Stack marginTop="1rem" marginBottom="1rem">
        <Divider variant="default" />
      </Stack>
      {formattedCoverageDetails?.map(
        (coverageDetail) =>
          coverageDetail.coverage !== null && (
            <Stack
              key={coverageDetail.name}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom="1rem"
            >
              <Stack marginRight="0.75rem">
                <Typo variant="caption">{coverageDetail.label}</Typo>
              </Stack>
              <CoverageRenderer
                coverage={coverageDetail.coverage}
                error={null}
                status={'success'}
                isClarityDetails={providerCode === 'clarity'}
                data-testid={createTestid(coverageDetail.name)}
              />
            </Stack>
          ),
      )}
    </>
  );
};
